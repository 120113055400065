import styled from "styled-components";
import { fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../components/GlobalStyle";
 
export const ParticipantDataStyle = styled.div`
.wrapper {
    width: 100%;
}
.headingParticipants {
    margin-top: 10px;
}
.headingParticipants h4 {
    color: #061229;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.showApproved_toggleButton {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 19px;
}
.showApproved_toggleButton span {
    color: #232837;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
    margin-right: 28px;
}
.toggleButton {
    position: absolute;
    right: 15px;
}
.participantBlock1 {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    padding: 0 28px 0 19px;
    background-color: #fff;
}
.participantBlock1_leftSide {
    display: flex;
    align-items: center;
    gap: 32px;
}
.frameMain img {
    width: 129px;
    height: 16px;
}
.categoryHeading h3 {
    color: #061229;
font-family: ${fontFamilyMediumUber};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-bottom: 0;
}
.totalParticipants {
    display: flex;
    align-items: center;
}
.totalParticipants p {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.32px;
    margin-right: 10px;
    margin-bottom: 0;
}
.totalParticipants span {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.36px;
}
.participantBlock1_rightSide {
    display: flex;
    align-items: center;
}
.printIcon {
    margin-right: 9px;
}
.printIcon, .uploadIcon {
    width: 34px;
    height: 34px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #B6D0D7;
    background-color: #D7E6EA;
}
.participantBlock2 {
    margin-top: 10px;
}
.participantCards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 19px;
}

// shezar css card
.card {
    display: flex;
    flex-direction: row;
    // width:380px;
    width: 32%;
    height:auto;
    padding-right: 7px;
}
 .participate-img img {
    width: 62.13px;
    height: 90.37px;
}
.participate-content p {
    margin-top: 8px;
    margin-bottom: 4px;
    margin-left: 12px;
    margin-top: 0px;
    font-family: ${fontFamilyRegularUber};
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.participate-content {
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 11.683px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    margin-top:10px;
    gap: 10px;
    margin-left: 10px;
}
.participate-content-2 {
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 11.683px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px;
    padding: 9px 0px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    margin-top: 8px;
    margin-bottom: 10px;
    margin-left: auto;
    gap: 9px;
    align-items: flex-end;
}
.participate-content-2 p {
    margin-top: 8px;
    margin-bottom: 4px;
    margin-left: 12px;
    margin-top: 0px;
    font-family: ${fontFamilyRegularUber};
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.participate-content .para {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 13.63px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.444px;
    margin:0px;
    gap: 10px;
}
.flag-container {
    margin-left: 7px;
    margin-top: 4px;
}
.flag-image img {
    margin-left: 9px;
    margin-bottom: 11px;
}
// shezar css end

.totalUnapproved {
    padding: 19px 0;
    border-bottom: 1px solid #C8CCD0;
}
.totalUnapproved span {
    color: #0070E0;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.blockSpacing {
    margin-top: 20px;
}

// Pagination
.paginationMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
}
.totalPages ul {
    display:flex;
    align-items: center;
    padding: 0;
    margin: 0;
}
.totalPages ul li {
    display: flex;
    align-items: center;
    list-style-type: none;

    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.totalPages ul li span {
    display: flex;
    width: 47px;
    padding: 7px 31px 8px 9px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #F1F1F1;
    margin: 0 9px;

    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.pagination ul {
    display:flex;
    align-items: center;
    gap: 0 6px;
    padding: 0;
    margin: 0;
}
.pagination ul li {
    list-style-type: none;
}
.disabled {
    display: inline-flex;
    padding: 7px 7px 9px 6px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #FFF;
    cursor: pointer;
}
.disabled span {
    color: #BDBDBD;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page1 {
    display: flex;
    width: 32px;
    padding: 7px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    background-color: #FFF;
    cursor: pointer;
}
.page1 span, .page2 span, .page10 span, .nextPage span  {
    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page2 {
    display: flex;
    width: 32px;
    padding: 7px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    background-color: #FFF;
    cursor: pointer;
}
.active {
    display: flex;
    width: 32px;
    padding: 7px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #1A4754;
    cursor: pointer;
}
.active span {
    color: #FFF;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page4_9 {
    display: flex;
    width: 32px;
    padding: 4px 0px 12px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #FFF;
    cursor: pointer;
}
.page4_9 span {
    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page10 {
    display: inline-flex;
    padding: 8px 9px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    background-color: #FFF;
    cursor: pointer;
}
.nextPage {
    display: inline-flex;
    padding: 8px 10px 8px 11px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #FFF;
    cursor: pointer;
}

.ant-switch-handle{
    top: -6px !important;
}
.nav .showApproved_toggleButton {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 19px;
    float: right;
    position: absolute;
    right: 0;
}
.participantBlock1_rightSide .showApproved_toggleButton button {
    width: 32px;
    background-color: #C8CCD0 !important;
    height: 7px;
}
.showApproved_toggleButton span {
    color: #232837;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.14px;
    text-transform: none;
    margin-right: 15px;
    
}
.ant-switch-handle::before{
    background-color: #17374e !important;
}

`;

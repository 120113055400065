import { useNavigate } from "react-router-dom"
import { CoachInitialValues } from "../constant"
import axios from 'axios'
import { useState } from "react"
import { Coach_Create_URL, Coach_Delete_URL, Coach_List_URL, Coach_Update_URL, Coach_View_URL } from "../../../utils/api_urls"
import CustomButton from "../../../components/CustomButton/CustomButton"
import { Col, Row } from "react-bootstrap"
import { RobinEggBlue, fontFamilyRegularUber, pureDark2 } from "../../../components/GlobalStyle"
import { SchoolSuccessfulModals } from "../../../hooks/PopupModalsStyling"
import CustomModal from "../../../components/Modal/CustomModal"
import CustomMessageModal from "../../../components/Modal/CustomMessageModal"
import ic_success from '../../../assets/images/ic_success.svg'
import api from "../../../api"
import { withError } from "antd/lib/modal/confirm"

interface IModalComponent {
    modalComponent: JSX.Element
}
interface IUseCoach {
    // loading: boolean
    handleCoachCreate: (
        values: CoachInitialValues
    ) => Promise<void>
    handleCoachUpdate: (
        instructId: number,
        values: CoachInitialValues
    ) => Promise<void>
    showCoachList: (adminId: number) => Promise<any>
    getCoachView: (instructorId: number) =>  Promise<any> 
    handleCoachDelete: (instructorId: number) =>  Promise<any> 
    SuccessModal: () => IModalComponent
    WarningModal: () => IModalComponent
    deleteConfirmation: (id: number, setAcademy: any) => IModalComponent
    setIsShowModal: (showModal: true) => void
}


const useCoach = (): IUseCoach => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowSuccessModal, setIsShowSuccessModal] = useState(false)
    const [isShowErrorModal, setIsShowErrorModal] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')


    // Coach Create
    const handleCoachCreate = async (
        values: CoachInitialValues
    ): Promise<void> => {

        const payload = {
            userId: values.userId,
            instructorName: values.instructorName,
            emailAddress: values.emailAddress,
            address: values.address,
            phoneNumber: values.phoneNumber,
            defaultLanguageId: values.defaultLanguageId,
            activities: values.activities.join(','),
            specializations: values.specializations.join(','),
            description: values.description,
            rankId: values.rankId,
            experience: values.experience,
            certificationURL: values.certificationURL,
        }
        try {
            const {data} = await api("post",
                Coach_Create_URL,
                payload,
            )
            setSuccessMessage(data.message)
            setIsShowSuccessModal(true)
            setLoading(false)
            setTimeout(() => {
                setIsShowSuccessModal(false)
                navigate('/coach/list')
            }, 3000)
        } catch (error: any) {
            console.log("errror",error);
            const errorMessages = Object.keys(error.response?.data?.errors || {}).map(
            (field) =>
                `<strong>${field}</strong>: ${error.response?.data?.errors[field]}`
        );

        setLoading(false);

        if (errorMessages.length > 1) {
            setErrorMessage(`${errorMessages.join('<br />')}`);
        } else {
            setErrorMessage(error.response?.data?.message);
        }

        setIsShowErrorModal(true);

        setTimeout(() => {
            setIsShowErrorModal(false);
        }, 10000);
        }

    }

    // Coach List
    const showCoachList = async (adminId: number): Promise<any> => {
        try {
            const {data} = await api("post",
                Coach_List_URL, 
                {
                    "userId": adminId
                }
            )

            return data.result
        } catch (error) {
            console.log("error", error)
        }
    } 

    // Coach Update
    const handleCoachUpdate = async (
        instructId: number,
        values: CoachInitialValues
    ): Promise<any> => {
        const url = Coach_Update_URL
        try {
            const payload = {
                instructorId: instructId,
                instructorName: values.instructorName,
                emailAddress: values.emailAddress,
                address: values.address,
                phoneNumber: values.phoneNumber,
                defaultLanguageId: values.defaultLanguageId,
                activities: values.activities.join(','),
                specializations: values.specializations.join(','),
                description: values.description,
                rankId: values.rankId,
                experience: values.experience,
                certificationURL: values.certificationURL,
            }
            const {data} = await api("post",url, payload)
            setSuccessMessage(data.message)
            setIsShowSuccessModal(true)
            setLoading(false)
            setTimeout(() => {
                setIsShowSuccessModal(false)
                navigate('/coach/list')
            }, 3000)
        } catch (error: any) {
            console.log("errror",error.response?.datas);
            
            const errorMessages = Object.keys(error.response?.data?.errors || {}).map(
            (field) =>
                `<strong>${field}</strong>: ${error.response?.data?.errors[field]}`
        );

        setLoading(false);

        if (errorMessages.length > 1) {
            setErrorMessage(`${errorMessages.join('<br />')}`);
        } else {
            setErrorMessage(error.response?.data?.message);
        }

        setIsShowErrorModal(true);

        setTimeout(() => {
            setIsShowErrorModal(false);
        }, 10000);
        }
    }

    // Coach View
    const getCoachView = async (instructorId: number): Promise<any> => {
        try {
            const {data} = await api("post",
                Coach_View_URL,
                {"instructorId": instructorId}
            )
            return data.result
        } catch(error:any) { 
            console.log("error", error)
        }
    }


    // Coach Delete
    const handleCoachDelete = async (instructorId: number): Promise<any> => {
        try {
            const {data} = await api("post",
                Coach_Delete_URL,
                { "instructorId": instructorId}
            )
            setSuccessMessage(data.message)
            setIsShowSuccessModal(true)
            setLoading(false)
            setTimeout(() => {
                setIsShowSuccessModal(false)
                navigate('/coach/list')
            }, 3000)
            return data
        } catch (error: any) {
            console.log("err",error);
            if (error.response.data.error.code === "23503") {
            setErrorMessage("first delete all coach request connected to thos academy")
            setIsShowErrorModal(true)
            setLoading(false)
            setTimeout(() => {
                setIsShowErrorModal(false)
            }, 3000)
            }
          else { setErrorMessage(error.response.data.message)
            setIsShowErrorModal(true)
            setLoading(false)
            setTimeout(() => {
                setIsShowErrorModal(false)
            }, 3000)
            }
           return error
        }
    }

    const SuccessModal = (): IModalComponent => {
        return {
            modalComponent: (
                <CustomMessageModal
                    title="Success"
                    description={successMessage}
                    isModalVisible={isShowSuccessModal}
                    setIsModalVisible={setIsShowSuccessModal}
                    imageProp={'success'}
                />
            ),
        }
    }

    const WarningModal = (): IModalComponent => {
        return {
            modalComponent: (
                <CustomMessageModal
                    title="Warning"
                    description={errorMessage}
                    isModalVisible={isShowErrorModal}
                    setIsModalVisible={setIsShowErrorModal}
                    imageProp={'error'}
                />
            ),
        }
    }

    const deleteConfirmation = (_id: number, setCoach: any): IModalComponent => {
        const DeleteSchool = async (id: number): Promise<void> => {
            setIsShowModal(false)

            const data = await handleCoachDelete(Number(id))
            console.log("delete",data);
            
           if(data.status==="success"){ setCoach((prev: any)=>{
                prev = prev.filter((el:any)=> el.coachId !==id);
                return [...prev]
            })}
        }
        return {
            modalComponent: (
                <CustomModal
                    isModalVisible={isShowModal}
                    setIsModalVisible={setIsShowModal}
                    showCloseBtn={false}
                >
                    <SchoolSuccessfulModals>
                        <div className="mainContainer">
                            <div className='mainContainer-header'>
                                <img
                                    src={ic_success}
                                    alt="Success Icon"
                                    width={25}
                                    height={25}
                                />
                                <h3 className="mainContainer-header-heading">
                                    Delete Academy
                                </h3>
                            </div>
                            <div className='mainContainer-body'>
                                <p className="mainContainer-body-subText">
                                    You can block them directly from their profile. If you  do, they won't be able to find your profile, posts or story or send you messages on Instagram. Instagram won't let them know you blocked them.
                                </p>
                                <Row className="mt-20">
                                    <Col md="6">
                                        <CustomButton
                                            bgcolor={'#292F3F33'}
                                            textTransform="uppercase"
                                            color={pureDark2}
                                            padding="10px 12.5px"
                                            fontFamily={fontFamilyRegularUber}
                                            width="100%"
                                            type="button"
                                            title="NO!"
                                            fontSize="16px"
                                            loading={false}
                                            clicked={() => setIsShowModal(false)}
                                        />
                                    </Col>
                                    <Col md="6">
                                        <CustomButton
                                            bgcolor={RobinEggBlue}
                                            textTransform="uppercase"
                                            color={pureDark2}
                                            padding="10px 12.5px"
                                            fontFamily={fontFamilyRegularUber}
                                            width="100%"
                                            type="submit"
                                            title="YES"
                                            fontSize="16px"
                                            loading={false}
                                            clicked={() => DeleteSchool(_id)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </SchoolSuccessfulModals>
                </CustomModal >
            ),
        }
    }

    return {
        // loading,
        handleCoachCreate,
        showCoachList,
        handleCoachUpdate,
        getCoachView,
        handleCoachDelete,
        deleteConfirmation,
        WarningModal,
        SuccessModal,
        setIsShowModal,
    }
}

export default useCoach
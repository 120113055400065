import { Card, Form } from "antd"
import Head from "../../../components/Head/Head"
import { Col, Row } from "react-bootstrap"
import { ErrorMessage, Formik } from "formik"
import FormControl from "../../../components/FormControl"
import { AffiliateDataType, affiliateInitialValues } from "../constant"
import CustomPhoneInput from "../../../components/CustomPhoneInput/CustomPhoneInput"
import { Black, RobinEggBlue, fontFamilyRegularUber } from "../../../components/GlobalStyle"
import PlacesAutoCompleteInput from "../../../maps/PlacesAutocomplete"
import CheckboxesSelect from "../../../components/CustomCheckbox/CheckboxesSelect"
import { DataTypesWithIdAndMultipleLangLabel } from "../../../app/features/types"
import CustomButton from "../../../components/CustomButton/CustomButton"
import { FormPageStyle, StudentViewStyling } from "../../Academy/AcademyCreate/style"
import useAffiliate from "../Hooks/useAffiliate"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../app/store"
import OverlayImages from "../../Home/OverlayImages/OverlayImages"

const AffiliateUpdate = () => {

  const {handleAffiliateUpdate, getAffiliateView, SuccessModal, WarningModal} = useAffiliate()
  const [affiliateData, setAffiliateData] = useState<AffiliateDataType>()
  const { affiliateId } = useParams()
  const { data } = useSelector((state: RootState) => state.loginData);

  const initialValues: affiliateInitialValues = {
    academyId: 9,
    affiliationName: affiliateData ? affiliateData.affiliationname : '--',
    affiliationType: affiliateData ? affiliateData.affiliationtype : '--',
    address: affiliateData?.address,
    rank: true,
    phoneNumber: affiliateData ? affiliateData.phonenumber : '--',
    defaultLanguageId: affiliateData ? affiliateData.defaultlanguageid : 0,
    defaultCurrencyId: affiliateData ? affiliateData.defaultcurrencyid : 0,
    activities: affiliateData ? affiliateData.activities : [],
    facilities: affiliateData ? affiliateData.facilities : [],
    description: affiliateData ? affiliateData.description : '--',
   }

   const updateAffiliate = async (values: any): Promise<void> => {
    await handleAffiliateUpdate(Number(affiliateId), values)
}

useEffect(() => {
  const fetchData = async (): Promise<void> => {
      try {
          const response: any = await getAffiliateView(Number(affiliateId))
          if (response) {
            setAffiliateData(response)
          }
      } catch  {
      }
  }
  fetchData()
}, [])

  const activitiesDummyList: DataTypesWithIdAndMultipleLangLabel[] = [
  { id: "1", en: "Football", es: "Fútbol", pt: "Futebol", value: "Football", label: "Football", ar: "", ur: "", imageUrl: "" },
  { id: "2", en: "Basketball", es: "Baloncesto", pt: "Basquetebol", value: "Basketball", label: "Basketball", ar: "", ur: "", imageUrl: "" },
  { id: "3", en: "Swimming", es: "Natación", pt: "Natação", value: "Swimming", label: "Swimming", ar: "", ur: "", imageUrl: "" },
  // Add more activities as needed
];

const facilitiesDummyList: DataTypesWithIdAndMultipleLangLabel[] = [
    { id: "1", en: "Gym", es: "Gimnasio", pt: "Academia", value: "Gym", label: "Gym", ar: "", ur: "", imageUrl: "" },
    { id: "2", en: "Library", es: "Biblioteca", pt: "Biblioteca", value: "Library", label: "Library", ar: "", ur: "", imageUrl: "" },
    { id: "3", en: "Computer Lab", es: "Laboratorio de Computación", pt: "Laboratório de Informática", value: "Computer Lab", label: "Computer Lab", ar: "", ur: "", imageUrl: "" },
    // Add more facilities as needed
];
  const onsubmit = () => { }
  return (
    <>
          {SuccessModal().modalComponent}
      {WarningModal().modalComponent}
      <Head title="ULTM8 - Affiliate Update" />
      <StudentViewStyling>
      <OverlayImages
          backgroundImg={''}
          overlayImg={''}
          isEditable={true}
        />
        <Card>
          <h3>Owner Information</h3>
          <Row className="mt-20">
            <Col md="6">
              <div className="list-item">
                <div className="list-item-title">
                  Owner First Name
                </div>
                <div className="list-item-value">
                {data.user.firstName || "--"}
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="list-item">
                <div className="list-item-title">
                  Owner Last Name
                </div>
                <div className="list-item-value">
                {data.user.lastName || "--"}
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="list-item">
                <div className="list-item-title">Email</div>
                <div className="list-item-value">
                {data.user.email || "--"}
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="list-item">
                <div className="list-item-title">
                  Phone Number
                </div>
                <div className="list-item-value">
                {data.user.phoneNumber || "--"}
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </StudentViewStyling>
      <FormPageStyle>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={onsubmit}
        >
          {(formik) => {
            return (
              <Form
                name="basic"
                onFinish={formik.handleSubmit}
                autoComplete="off"
              >
                <div className="bg-white form">
                  <h3>Affiliate Update</h3>
                  <Row>
                  <Col md="4" className="mt-20">
                      <FormControl
                          control="input"
                          type="text"
                          name="affiliationName"
                          label="Affiliate Name"
                          fontSize="16px"
                          max={6}
                          placeholder="Enter Affiliate Name"
                          className={
                              formik.errors
                                  .affiliationName &&
                              formik.touched.affiliationName
                                  ? 'is-invalid'
                                  : 'customInput'
                          }
                      />
                      </Col>
                      <Col md="4" className="mt-20">
                          <FormControl
                              control="select"
                              type="text"
                              name="affiliationType"
                              label="Affiliate Type"
                              placeholder="Select Affiliate Type"
                              className={
                                  formik.errors
                                      .affiliationType &&
                                  formik.touched.affiliationType
                                      ? 'is-invalid'
                                      : 'customInput'
                              }
                              options={[
                                { value: 1, label: "School" },
                                { value: 2, label: "Academy" },
                                { value: 3, label: "Coach" },
                              ]}
                          />
                      </Col>
                      <Col md="4" className="mt-20">
                        <CustomPhoneInput
                            label="Phone Number"
                            name="phoneNumber"
                            value={
                                formik.values
                                    .phoneNumber
                            }
                            placeholder="Enter Phone Number"
                            limitMaxLength={true}
                            handleOnChange={(e: string) => {
                                formik.setFieldValue(
                                    'phoneNumber',
                                    e
                                )
                            }}
                        />
                        <ErrorMessage
                            name={'phoneNumber'}
                        >
                            {(msg) => (
                                <div
                                    className="error-message is-invalid"
                                    style={{
                                        color: 'red',
                                        textAlign: 'right',
                                        marginLeft: '3px',
                                        fontSize: '12px',
                                        letterSpacing:
                                            '1px',
                                        fontFamily:
                                            fontFamilyRegularUber,
                                    }}
                                >
                                    {msg}
                                </div>
                            )}
                        </ErrorMessage>
                      </Col>
                      <Col md="4" className="mt-20">
                          <PlacesAutoCompleteInput
                              label="Affiliate Address"
                              placeholder="Enter Affiliate Address"
                              handleChange={(val) => {
                                  formik.setFieldValue(
                                      'address',
                                      val
                                  )
                              }}
                              className={
                                  formik.errors.address &&
                                  formik.touched.address
                                      ? 'is-invalid'
                                      : 'customInput'
                              }
                              formik={formik}
                              name="address"
                              value={formik.values.address}
                          />
                      </Col>
                      <Col md="4" className="mt-20">
                          <FormControl
                              control="select"
                              type="text"
                              name="defaultLanguageId"
                              fontFamily={
                                  fontFamilyRegularUber
                              }
                              label="Default Language"
                              placeholder="Select Default Language"
                              className={
                                  formik.errors
                                      .defaultLanguageId &&
                                  formik.touched
                                      .defaultLanguageId
                                      ? 'is-invalid'
                                      : 'customInput'
                              }
                              options={[
                                { value: 1, label: "English" },
                                { value: 2, label: "Urdu" },
                                { value: 3, label: "Punjabi" },
                              ]}
                          />
                      </Col>
                      <Col md="4" className="mt-20">
                            <FormControl
                                control="select"
                                type="text"
                                name="defaultCurrencyId"
                                fontFamily={
                                    fontFamilyRegularUber
                                }
                                label="Default Currency"
                                placeholder="Select Default Currency"
                                className={
                                    formik.errors
                                        .defaultCurrencyId &&
                                    formik.touched
                                        .defaultCurrencyId
                                        ? 'is-invalid'
                                        : 'customInput'
                                }
                                options={[
                                  { value: 1, label: "USD" },
                                  { value: 2, label: "Euro" },
                                  { value: 3, label: "Rupees" },
                                ]}
                            />
                        </Col>
                        <Col md="6" className="">
                            <CheckboxesSelect
                                list={activitiesDummyList}
                                name="activities"
                                label="Activity"
                                showErrorMsgInList={false}
                                placeholder="Select Activity"
                            />
                        </Col>
                        <Col md="6" className="">
                            <CheckboxesSelect
                                list={facilitiesDummyList}
                                name="facilities"
                                label="Facilities"
                                showErrorMsgInList={false}
                                placeholder="Select Facilities"
                            />
                        </Col>
                        <Col className="mt-20">
                              <FormControl
                                  control="textarea"
                                  type="text"
                                  name="description"
                                  fontFamily={fontFamilyRegularUber}
                                  label="Description"
                                  padding="10px"
                                  placeholder="Enter Description"
                                  className={
                                      formik.errors.description &&
                                      formik.touched.description
                                          ? 'is-invalid'
                                          : 'customInput'
                                  }
                                  height="200px"
                              />
                          </Col>
                  </Row>
                </div>
                <div className="mt-20 d-flex justify-content-end">
                    <CustomButton
                        bgcolor={RobinEggBlue}
                        textTransform="uppercase"
                        color={Black}
                        padding="11px 40.50px"
                        fontFamily={fontFamilyRegularUber}
                        width="fit-content"
                        type="submit"
                        title="Update"
                        fontSize="14px"
                        loading={false}
                        clicked={() => {updateAffiliate(formik.values)}}
                    />
                </div>
              </Form>
            )
          }}
        </Formik>

      </FormPageStyle>
    </>
  )
}

export default AffiliateUpdate
import {ParticipantCardStyle} from "./style" 
import  ParticipantImage from "../../assets/images/ali-hassan.png";
import EnglandFlag from "../../assets/images/EnglandFlag.svg";

export const ParticipantCard = () => {
  return (
    <div>
        <ParticipantCardStyle>
        <div className="participateCard">
          <div className='participateImage '>
            <img src={ParticipantImage} alt="ParticipantImage" />
          </div>
          <div className="participateDetailsMain">

            <div className="participateName">
                <div className="name">
                  <p>Ali Hassan</p>
                  <div className="blueDot"></div>
                </div>
              
                <span>-30.20 kg</span>
            </div>
            <div className="participateAddress">
                <p>MNBJJ Glasgow Brazilian Jiu-jitsu</p>
                <span>Pee Wee lll (9yrs)</span>
            </div>
            <div className="ageCountry_Main">
                <div className="ageCountryFlag">
                <p>2012 . 11 years old</p>
                <img src={EnglandFlag} alt="EnglandFlag" />
                </div>
                <span>White</span>
            </div>

          </div>
        </div>

        </ParticipantCardStyle>

    </div>
  )
}

export default ParticipantCard;


import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useCaseForgetPassowrd, verify_otp_url } from "../utils/api_urls";
import { OtpPropValues } from "../screens/ForgetPassword/Otp/Otp";
import { number } from "yup";
import { useGlobalContext } from "../context/context";

// interface Otp {
// value: string | number

// }

interface OtpResponse {
  loading: boolean;
  handleSubmit: (values: { code: string }) => Promise<{ message: string }>;
  error: string;
}
const useVerifyOtp = (): OtpResponse => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const toastId = useRef<any>(null);
  const { userPhoneNumber } = useGlobalContext();
  // get Validations from redux appData
  // const {
  //   countryName: {
  //     results: { countryCode },
  //   },
  //   validations,
  // } = useAppSelector((state) => state.appData.data);

  // // user validations
  // const phoneNumber = validations[countryCode === "92" ? 23 : 4];
  // // user regExpressions
  // const phoneNumberReg = new RegExp(phoneNumber.pattern);

  // // initial values
  // const initialValues: forgetPasswordInitialTypes = {
  //   phoneNumber: "",
  // };
  // // validation schema
  // const validationSchema = Yup.object({
  //   phoneNumber: Yup.string()
  //     .required(phoneNumber.notBlankMsgEn)
  //     .matches(phoneNumberReg, phoneNumber.pattern),
  // });

  // register phone handler
  const handleSubmit = async (values: {
    code: string;
  }): Promise<{ message: string }> => {
    // setOtp(values.phoneNumber.toString());
    console.log(values.code, "otp number");
    const phoneData = {
      phoneNumber: userPhoneNumber,
      code: values.code,
    };
    try {
      setError("");
      setLoading(true);
      const { data } = await axios.post(verify_otp_url, phoneData);
      if (!!data) {
        setLoading(false);
        localStorage.setItem("resetPasswordToken", data.resetPasswordToken)
        return { message: data?.message };
      } else {
        setLoading(false);
        return { message: "failed" };
      }
    } catch (error) {
      return { message: "Something went wrong" };
    }
  };
  return {
    loading,
    handleSubmit,
    error,
  };
};

export default useVerifyOtp;

import LoginButton from "./LoginButton";
import google from "../../../assets/icons/ic_google.svg";
import { base_url, google_auth_service } from "../../../utils/api_urls";

const GoogleLogin = (): JSX.Element => {
  const handleGoogleLogin = () =>
    (window.location.href = `${base_url}${google_auth_service}`);

  return (
    <div onClick={handleGoogleLogin}>
      <LoginButton type={google as string} alt={"Google"} />
    </div>
  );
};

export default GoogleLogin;

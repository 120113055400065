import styled from "styled-components";
import { fontFamilyRegularUber, lightDark2, pureDark2 } from "../../../components/GlobalStyle";

export const AcademyProfileStyle = styled.div`
.wrapper {
    width: 100%;
    display: flex;
}
.bodyLeftSideMain {
    width: 25%;
    margin-right: 36px;
}
.introductionParagraph {
    width: 100%;
}

.introBox{
    border-radius: 5px;
    margin-top: 10px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding: 14px 12px 10px 17px;
}
.introductionParagraph h6 {
    color: ${pureDark2};
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.introBox p {
    color: ${lightDark2};
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
}
.mapFrame {
    max-width: 357px;
    width: 100%;
    border-radius: 0px 0px 9px 9px;
    border: 0px solid #E0E0E0;
    margin-top: 21px;
}
.studentsAll {
    max-width: 359px;
    width: 100%;
    margin-top: 17px;
}
.studentsTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.studentsTitle h5 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.studentsTitle span {
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.studentsCards {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 7px;
}
.bodyRightSideMain {
    width: 75%;
}
.academiesCards {
    margin-top: 29px;
}
.affiliationsMain {
    width: 100%;
    margin-top: 11px;
    // padding: 10px 0 14.38px;
}

.DynamoFightCard {
    margin-top: 12px !important;
}
.passEventsMain {
    margin-top: 37.43px;
    margin-left: 1.28px;
}
.passEventsHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.passEventsHeading h4, .uncomingEventsHeading h4 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.passEventsHeading, .uncomingEventsHeading, .sectionHeadingsMain {
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.217px;
    letter-spacing: 0.28px;
    span {
        cursor: pointer;
    }
}

.passEventCard {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15.2px;
    gap: 19px;
    margin-bottom: 85.67px;
}
.uncomingEventsMain {
    margin: 33px 0px 0px 1px;
}
.uncomingEventsHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.uncomingEventCard {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16.5px;
    gap: 18.27px;
}
.affiliationsMain {
    max-width: 354.91px;
    width: 100%;
    margin-top: 19.07px;
}

.BlocksHeadings{
    display: flex;
    align-items: center; 
    justify-content: space-between;

    h4{
        color: ${pureDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.56px;
        margin-bottom: 0;
    }
    span {
        color: ${lightDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 13.217px;
        letter-spacing: 0.28px;
        cursor: pointer;
    }
}

.academiesCards {
    margin-top: 11px;
    gap: 11.68px 10.51px;
}
.academiesCards > div {
    width: 100%;
}
.affiliationsCards {
    margin-top: 10px;
    gap: 11.68px 10.51px;
}
.affiliationsCards > div {
    width: 100%;
}
`;
import styled from "styled-components";
import {fontFamilyRegularUber, lightDark2, pureDark2 } from "../../../components/GlobalStyle";

export const ParticipantsProfileMultiAcademiesStyle = styled.div`
.wrapper {
    width: 100%;
    display: flex;
}
.bodyLeftSideMain {
    width: 25%;
    margin-right: 36px;
}
.blackBeltMain {
    max-width: 359px;
    width: 100%;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding: 14px;
    margin-top: 20px;
}
.blackBeltTitle {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.217px;
    letter-spacing: 0.4px;
    margin-bottom: 0;
}
.blackBelt_ImagePara {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.blackBelt_AchivedPromoted{
    display: flex;
    flex-direction: column;
}
.blackBelt_AchivedPromoted p {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0;
}
.blackBelt_AchivedPromoted span {
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}
.blackBeltImage img {
    width: 113px;
    height: 13.96px;
}
.winningsMain {
    max-width: 359px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background-color: #FFF;
    margin-top: 19px;
}
.winning10Logo {
    padding: 14px 0 14.19px 14.3px;
}
.winningTypesMain {
    display: flex;
    flex-direction: column;
    padding-left: 20.96px;
    padding-right: 9.69px;
}
.winningByPoints {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
}
.byPoints, .bySubmission, .byWalkover {
    display: flex;
    align-items: center;
    width: 219px;
}
.byPoints span {
    width: 6.804px;
    height: 13.953px;
    border-radius: 3.721px;
    background-color: #78BC7B;
}
.byPoints p {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 0;
    padding-left: 8.62px;
}
.winningCount span {
    color: ${pureDark2};
    text-align: right;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}
.winningBySubmission {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}
.bySubmission span {
    width: 6.804px;
    height: 13.953px;
    border-radius: 3.721px;
    background-color: #CD6767;    
}
.bySubmission p {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 0;
    padding-left: 8.62px;
}
.winningByWalkover {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}
.byWalkover span {
    width: 6.804px;
    height: 13.953px;
    border-radius: 3.721px;
    background-color: #06F;
}
.byWalkover p {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 0;
    padding-left: 8.62px;
}
.introductionParagraph {
    width: 100%;
}

.introBox{
    border-radius: 5px;
    margin-top: 10px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding: 14px 12px 10px 17px;
}
.introductionParagraph h6 {
    color: ${pureDark2};
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.introBox p {
    color: ${lightDark2};
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
}
.mapFrame {
    max-width: 357px;
    width: 100%;
    border-radius: 0px 0px 9px 9px;
    border: 0px solid #E0E0E0;
    margin-top: 21px;
}
.bodyRightSideMain {
    width: 75%;
}
.academiesMain {
    max-width: 354.91px;
    width: 100%;
    margin-top: 19px;
}
.sectionHeadingsMain {
    padding: 0px 0 0 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sectionHeadingsMain h4 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.sectionHeadingsMain {
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.217px;
    letter-spacing: 0.28px;
    span {
        cursor: pointer;
    }
}
.affiliationsMain {
    max-width: 354.91px;
    width: 100%;
    margin-top: 19.07px;
}
.BlocksHeadings {
    // padding-top: 12px !important;
    padding-left: 7px !important;
}
.UpcomingHeading, .pastEventHeading {
    padding: 0px !important;
}
.academiesCards {
    margin-top: 11px;
    gap: 11.68px 10.51px;
}
.academiesCards > div {
    width: 100%;
}
.affiliationsCards {
    margin-top: 10px;
    gap: 11.68px 10.51px;
}
.affiliationsCards > div {
    width: 100%;
}
.unComingEventsMain {
    margin-top: 26.07px;
}
.unComingEventsCards {
    display: flex;
    align:items: center;
    gap: 19.46px;
    margin-top: 13.77px;
}
.pastEventsMain {
    margin-top: 21.1px;
    margin-bottom: 40px;
} 
.pastEventsCards {
    display: flex;
    align:items: center;
    gap: 19px;
    margin-top: 13px;
}
.passEventFirstCardBorder {
    padding-bottom: 10px;
    border-bottom: 1px solid #E9E9E9;
}
`;

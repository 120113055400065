import { Card } from "antd"
import { Col, Row } from "react-bootstrap"
import Head from "../../../components/Head/Head"
import OverlayImages from "../../Home/OverlayImages/OverlayImages"
import { ViewPageStyle } from "../../Academy/AcademyView/style"
import useBranch from "../Hooks/useBranch"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { BranchDataType } from "../constant"
import { useSelector } from "react-redux"
import { RootState } from "../../../app/store"

const BranchView = () => {

    const {getBranchView} = useBranch();
    const [branchData, setbranchData] = useState<BranchDataType>()
    const { branchId } = useParams()
    const { data } = useSelector((state: RootState) => state.loginData);

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const response: any = await getBranchView(Number(branchId))
                if (response) {
                    setbranchData(response)
                }
            } catch (error) {
                console.log("error", error)
            }
        }
        fetchData()
    }, [])

  return (
    <>
    <Head title="ULTM8 - Branch View" />
    <ViewPageStyle>
        <OverlayImages
            backgroundImg={''}
            overlayImg={''}
            isEditable={true}
        />
        <h3>Owner Information</h3>
        <Card>
            <Row>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                            Owner First Name
                        </div>
                        <div className="list-item-value">
                            {data.user.firstName || "--"}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                            Owner Last Name
                        </div>
                        <div className="list-item-value">
                        {data.user.lastName || "--"}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">Email</div>
                        <div className="list-item-value">
                        {data.user.email || "--"}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item ">
                        <div className="list-item-title">
                            Phone Number
                        </div>
                        <div className="list-item-value">
                        {data.user.phoneNumber || "--"}
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
        <h3>Branch View</h3>
        <Card>
            <Row>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                        Branch Name
                        </div>
                        <div className="list-item-value">
                        {branchData?.branchName || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                        Branch Type
                        </div>
                        <div className="list-item-value">
                        {branchData?.branchType || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                          Phone Number
                        </div>
                        <div className="list-item-value">
                        {branchData?.phoneNumber || '--'}
                        </div>
                    </div>
                </Col>

                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                        Branch Address

                        </div>
                        <div className="list-item-value">
                        {branchData?.address || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="3">
                    <div className="list-item">
                        <div className="list-item-title">
                           Default Language
                        </div>

                        <div className="list-item-value">
                        {branchData?.defaultLanguageId || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="3">
                    <div className="list-item">
                        <div className="list-item-title">
                           Default Currency
                        </div>
                        <div className="list-item-value">
                        {branchData?.defaultCurrencyId || '--'}
                        </div>
                    </div>
                </Col>

                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                           Activity
                        </div>
                        <div className="list-item-value">
                        {branchData?.activities || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                           Facility
                        </div>
                        <div className="list-item-value">
                        {branchData?.facilities || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="12">
                    <div className="list-item mb-0">
                        <div className="list-item-title">
                           Description
                        </div>
                        <div className="list-item-value">
                        {branchData?.description || '--'}
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
    </ViewPageStyle>
    </>
  )
}

export default BranchView
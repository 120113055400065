import styled from "styled-components";
import { fontFamilyRegularUber, pureDark2 } from "../../../components/GlobalStyle";
export const AcademyProfilePastEventStyle = styled.div`
.wrapper {
    width: 100%;
    display: flex;
}

.pastEventsMain {
    width: 100%;
    margin-top: 21px;
}
.passEventHeading h4 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.passEventCardMain {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15.5px;
    gap: 20px;
}
.passEventCardMain > div {
    max-width: 315px;
    width: 100%;
}
.paginationContainer {
    margin: 10.43px 0 20px 0px;
}
`; 
import styled  from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../../components/GlobalStyle";

export const AffiliationsProfileOverallAcademyAdultsAfterLogStyle = styled.div`

.bodyWrapper_RightSide {
    width: 100%;
    margin-top: 15px;
}
.rankingOverAll_Title {
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.rankingOverAll_Title p {
    color: #333;
    font-family: ${fontFamilyMedium};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.34px;
    margin-bottom: 0;
}
.rankingOverAll_Title h6 {
    color: #061229;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    padding-left: 4px;
    margin-bottom: 0;
}
.lastCalculated {
    color: #061229;
    font-family: ${fontFamilyMedium};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-left: 4px;
}
.dot {
    color: #333;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    padding-left: 3px;
}
.affiliationsTeamsOverAll_MainContainer {
    width: 100%;
    display: flex;
    gap: 20px;
    margin: 12px 0 45px 0px;
    padding-left: 9px;
}
.bestAffiliationsOverallMain, .bestTeamsOverallMain {
    width: 49.07%;
}
.blockTitle {
    padding: 7px 0px 7px 11px;
    border-radius: 3.965px 3.965px 0px 0px;
    border: 1px solid #F4F4F4;
    background-color: #EFEFEF;
}
.blockTitle h5{
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    margin-bottom: 0;
}
.bestAffiliationsCardsMain {
    display: flex;
    flex-direction: column;
    gap: 15px 0;
    margin-top: 9px;
}
.block1 > div {}
.seeAll {
    border-radius: 3.965px 3.965px 0px 0px;
    border: 1px solid #F4F4F4;
    background-color: #EFEFEF;
    padding: 6px 0 7px 0;
    margin-top: 10px;
    text-align: center;
}
.seeAll span {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}
.block2_toLast {
    margin-top: 19px;
}
.newWave_AcademyCard, .pointsWins_Main {
    max-width: 100% !important;
}
`;
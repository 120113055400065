import styled from "styled-components";
import { fontFamilyMedium, fontFamilyMediumUber } from "../../../components/GlobalStyle";


export const OrganizerMessengerCenterParticipants2Style =  styled.div`
.bodyWrapper_RightSide {
    width: 100%;
    margin-top: 14px;
}
.title_submitButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.submitButton {
    padding: 11px 29px 10px 30px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
.participantsList {
    display: flex;
    flex-wrap: wrap;
    gap: 20.47px 19px;
    margin-top: 15px;
}
.participantsList > div {
    max-width: 32.14%;
    width: 100%;
}
.pagination_Container {
    margin: 20px 0 20px 0;
}


`;
import { ErrorMessage, Formik } from "formik";
import { Form } from "antd";
import CustomButton from "../../components/CustomButton/CustomButton";
import {
  LightGray,
  RobinEggBlue,
  fontFamilyEnnVisionsMedium,
  fontFamilyRegularUber,
  lightBlue3,
  pureDark2,
} from "../../components/GlobalStyle";
import ForgotPasswordStyle from "./style";
import Head from "../../components/Head/Head";
import Errormsg from "../../components/ErrorMessage";
import useGenerateOtp from "../../hooks/useGenerateOtp";
import Input from "react-phone-number-input";
import "react-phone-number-input/style.css";
import useScreenTranslation from "../../hooks/useScreenTranslation";
import { FORGOT_SCREEN_LABEL_KEYS } from "./constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../components/Modal/CustomModal";
import { toast } from "react-toastify";

// initial values types
export type forgetPasswordInitialTypes = {
  phoneNumber: string;
};

interface ForgotPasswordProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOtpModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  isModalVisible,
  setIsModalVisible,
  setIsOtpModalVisible,
}) => {
  const { loading, handleSubmit, error } = useGenerateOtp();
  const { getLabelByKey } = useScreenTranslation("forgotPassword");
  const navigate = useNavigate();

  const { selectedLanguage } = useSelector(
    (state: RootState) => state.selectedLanguage
  );

  // initialValues
  const initialValues: forgetPasswordInitialTypes = {
    phoneNumber: "",
  };

  // const phoneNumber = validationFinder("PHONE_NUMBER")!;
  // const phoneNumberReg = new RegExp(phoneNumber.pattern);

  // validation schema
  // const validationSchema = Yup.object({
  //   phoneNumber: Yup.string()
  //     .required(phoneNumber.notBlankMsgEn)
  //     .matches(phoneNumberReg, phoneNumber.patternMsgEn),
  // });

  useEffect(() => {
    const countrySelect = document.querySelector(
      ".PhoneInput .PhoneInputCountry"
    );
    const phoneNumberInput = document.querySelector(".PhoneInput input");

    if (countrySelect) {
      if (selectedLanguage === "ur" || selectedLanguage === "ar") {
        countrySelect.classList.remove("country-left-to-right-border-radius");
        countrySelect.classList.add("country-right-to-left-border-radius");
      } else {
        countrySelect.classList.add("country-left-to-right-border-radius");
        countrySelect.classList.remove("country-right-to-left-border-radius");
      }
    }
    if (phoneNumberInput) {
      if (selectedLanguage === "ur" || selectedLanguage === "ar") {
        phoneNumberInput.classList.add(
          "phone-number-right-to-left-border-radius"
        );
        phoneNumberInput.classList.remove(
          "phone-number-left-to-right-border-radius"
        );
      } else {
        phoneNumberInput.classList.add(
          "phone-number-left-to-right-border-radius"
        );
        phoneNumberInput.classList.remove(
          "phone-number-right-to-left-border-radius"
        );
      }
    }
  }, [selectedLanguage]);

  const handleFormSubmit = async (values: forgetPasswordInitialTypes) => {
    try {
      const { message } = await handleSubmit(values);
      if (message === "success") {
        setIsModalVisible(false);
        setIsOtpModalVisible(true);
      }
    } catch (error) {
      toast("Something went wrong", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <Head title="Forget Password" />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <ForgotPasswordStyle>
          <div className="forget-password-container overflow-auto">
            <div className="forget-password-container-card">
              <div className="forget-password-container-card-inner position-relative ">
                <h6 className="title text-center">Forgot password</h6>
                <p className="text-center forget-password-text mt-10">
                  Enter the mobile number associated with your account and we'll
                  send an one-time password (otp) with instructions to reset
                  your password.
                </p>
                <div className="forget-password-container-card-form w-100 mt-20">
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                  >
                    {(formik) => {
                      return (
                        <Form
                          name="basic"
                          onFinish={formik.handleSubmit}
                          autoComplete="off"
                        >
                          <div className="phone-input-fields w-100">
                            <label
                              className="custom-phone-input-label"
                              htmlFor="phoneNumber"
                            >
                              {/* {getLabelByKey(
                              FORGOT_SCREEN_LABEL_KEYS.mobileFieldTitle
                            )} */}
                              Phone Number
                            </label>
                            <Input
                              defaultCountry="GB"
                              international
                              placeholder="Enter Phone Number"
                              name="phoneNumber"
                              limitMaxLength={true}
                              value={formik.values.phoneNumber}
                              onChange={(e: any) => {
                                formik.setValues({ phoneNumber: e });
                              }}
                              withCountryCallingCode
                              countryCallingCodeEditable
                            />
                            <div className="mt-1">
                              <ErrorMessage
                                name="phoneNumber"
                                component={Errormsg}
                              />
                            </div>

                            <div className="mt-20">
                              <CustomButton
                                bgcolor={RobinEggBlue}
                                textTransform="uppercase"
                                color={pureDark2}
                                padding="12px"
                                fontFamily={`${fontFamilyRegularUber}`}
                                width="100%"
                                type="submit"
                                title="Submit"
                                fontSize="14px"
                                loading={loading}
                              />
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </ForgotPasswordStyle>
      </CustomModal>
    </>
  );
};

export default ForgotPassword;

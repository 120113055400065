import React from 'react'
import AffiliateStatusCard from '../../../components/AffiliateListCard/AffiliateStatusCard'
import { AffiliateActiveStyle } from './style'
const AffiliateActive = () => {
    return (
        <AffiliateActiveStyle>
            <div className="StudentsWrapper mt-20">
                <div className='studentHeader'>
                    <h4 className="studentTitle">Pending Affiliate</h4>
                    <div className='batchHeader'>
                        <h4>Batch Update</h4>
                        <select name="batch" id="batch" className='batchSelect'>
                            <option value="member">Member</option>
                            <option value="manager">Manager</option>
                            <option value="coach">Coach</option>
                            <option value="affiliate">Affiliate</option>
                        </select>
                    </div>
                </div>
                <AffiliateStatusCard />
                <AffiliateStatusCard />
                <AffiliateStatusCard />
                <AffiliateStatusCard />
                <AffiliateStatusCard />
                <AffiliateStatusCard />
            </div>
            </AffiliateActiveStyle>
    )
}

export default AffiliateActive
import React from 'react'
import {FormFieldStyling, OrganizerCreateAnEventTermsStyle} from "./style"

import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";
import FormControl from "../../../components/FormControl";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";

import {
  fontFamilyEnnVisionsMedium,
  fontFamilyEnnVisionsRegular,
  fontFamilyRegular,
  mediumPurple,
  whiteColor,
} from "../../../components/GlobalStyle";
import GapCurrencyIcon from "../../../assets/icons/is_GBP.svg";
import DeleteIcon from "../../../assets/icons/ic_deleteDark.svg";
import CustomButton from "../../../components/CustomButton/CustomButton";

const { Content } = Layout;

const OrganizerCreateAnEventTerms = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };

  const handleCreateSubmit = () => {};
  return (
    <div>
        <OrganizerCreateAnEventTermsStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="creatEvent-left">
                <CreatEventLeftSide />
              </Content>
              <Content className="creatEvent-right">


                <FormFieldStyling>
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={handleCreateSubmit}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className="formWrapper">
                            <h3 className="title">Capacity</h3>
                            <div className="tablelist">
                              <div className="organizerSettingForm">
                                <Row>
                                  <Col md="12" className="mb-20">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-use-ammount"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Maximum Use Amount"
                                      padding="10px"
                                      placeholder="Christmas 2023 Coupon Discount"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  
                                  <Col md="12" className='termText' >
                                    <h3>WAIVER OF PARTICIPATION</h3>
                                    <p><b>Summary:</b></p>

                                    <p><b>Acknowledgment of Risks:</b> I understand that engaging in martial arts and contact sports involves inherent risks of bodily harm, and I acknowledge the potential for serious injury or even death during the event.</p>

                                    <p><b>Assumption of Responsibility:</b> I assume full responsibility for any damages, injuries, or losses that may occur while participating in or watching the Tournament/Event. I expressly waive any claims against the organizers, board members, promoters, operators, sponsors, officials, participants, non-participants, or volunteers for injuries resulting from my participation.</p>

                                    <p><b>Medical Authorization:</b> In the event of injury, I authorize and hold harmless any medical personnel present at the tournament/event to take necessary actions for my well-being.</p>

                                    <p><b>Health Representation:</b> I confirm that I am in good health, not under the influence of drugs or medication, and that no one associated with the event has influenced my decision to participate. I declare my understanding of the waiver and affirm my legal competence to enter into this agreement.</p>

                                    <p><b>Privacy and Personal Data:</b> By using my Smoothcomp account for event registration, I grant the organizer permission to contact me for newsletters, event reminders, and promotional offers. I acknowledge the responsibility to check and verify my registration information.</p>

                                    <p><b>Media Consent:</b> I understand that my likeness may be shown, publicized, and reported on in various media forms during the event, and I waive any compensation entitlement for such use.</p>

                                    <p><b>Dispute Resolution:</b> I agree to waive the right to file a lawsuit in city, state, or federal court and opt for the organizer's discretion in resolving any dispute arising from my participation in the event.</p>

                                    <p><b>Minors Under 18:</b> If the participant is a minor, I, as the parent/legal guardian, certify that I have given express permission for the minor to compete. I assume all risks on behalf of the minor and waive any claims on their behalf.</p>

                                    <p><b>Note:</b> This rephrasing aims to maintain the content and meaning of the original waiver while presenting it in a clearer and more concise manner.</p>

                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>


                          <div className="mt-20 d-flex justify-content-end">
                            <CustomButton
                              bgcolor={mediumPurple}
                              textTransform="Captilize"
                              color={whiteColor}
                              padding="11px 40.50px"
                              fontFamily={`${fontFamilyEnnVisionsMedium}`}
                              width="fit-content"
                              type="submit"
                              title="Next"
                              fontSize="18px"
                              loading={false}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </FormFieldStyling>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
        </OrganizerCreateAnEventTermsStyle>
    </div>
  )
}

export default OrganizerCreateAnEventTerms
import styled from "styled-components";
import { fontFamilyEnnVisionsRegular } from "../../GlobalStyle";

export const OrganizerEventListStyle = styled.div`

    .organizerLeft{


    }
    .organizerLeft ul{
        margin-top: 20px;
    }
    .organizerLeft ul li{
        list-style: none;
        display: block;
        margin: 10px 0;
    }
    .organizerLeft ul li a{
        color: #7B7B7B;
        font-family: ${fontFamilyEnnVisionsRegular};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
        cursor: pointer;
    }
    .organizerLeft ul li a:hover{
        color: #626262;
    }

    


`;
import styled from "styled-components";
import {  fontFamilyRegularUber, pureDark2 } from "../../../components/GlobalStyle"; 

export const AcademyProfileUpcomingEventStyle = styled.div`
.wrapper {
    width: 100%;
    display: flex;
}
.introductionParagraph {
    max-width: 359px;
    width: 100%;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding: 14px 12px 10px 17px;
    margin-top: 18px;
}
.introductionParagraph h4 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}

.upComingEventsMain {
    width: 100%;
    margin-top: 22px;
}
.upComingEventsHeading h4 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.upComingCardsMain {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 20px;
}

.paginationContainer {
    margin: 16.67px 0 22px 0px;
}
`;
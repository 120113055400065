import styled from "styled-components";
import { fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegularUber, pureDark, whiteColor } from "../../../components/GlobalStyle";

export const OrganizerCreditHistoryAddBalanceStyle = styled.div`
.title_AffiliationButton {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 13px;
        margin: 22px 0px 10px;
    }
    .title {
        color: #000;
        font-family: ${fontFamilyMedium};
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.48px;
        margin-bottom: 0;
    }
    .newAffiliationButton {
        padding: 11px 10px 10px 15px;
        border-radius: 4px;
        border: 0.6px solid #BBE6C3;
        border-radius: 4px;
        background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
        color: ${whiteColor};
        font-family: ${fontFamilyMediumUber};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.6px;
    }

    .tablelist{
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #E0E0E0;
        background: #FFF;
        margin-top: 18px;
        margin-bottom: 21px;
    }
    .organizerSettingForm{
        padidng: 18px;
    }
    .organizerSettingForm form{
        display: flex;
        padding: 17px 20px;
        gap: 18px 21px;
        flex-wrap: wrap;
    }
    .organizerSettingForm .form-group{
        width: 32%;
    }
    .organizerSettingForm label{
        display: block;
        color: #061229;
        font-family: ${fontFamilyEnnVisionsRegular};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 7px;
    }
    // .organizerSettingForm input[type="text"]{
    //     border-radius: 10px;
    //     border: 1px solid #E0E0E0;
    //     width: 100%;
    //     padding: 16px 10px;
    //     height: 50px;
    //     color: #4F4F4F;
    //     font-family: ${fontFamilyEnnVisionsRegular};
    //     font-size: 16px;
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: normal;
    // }
    .organizerSettingForm select{
        border-radius: 10px;
        border: 1px solid #E0E0E0;
        width: 100%;
        padding: 12px 10px;
        height: 50px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url(assets/images/arrowSelectOption.svg) 96% center no-repeat;
        color: #4F4F4F;
        font-family: ${fontFamilyEnnVisionsRegular};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .organizerSettingForm select option{
        color: #4F4F4F;
        font-family: ${fontFamilyEnnVisionsRegular};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .form-group .ant-input-affix-wrapper{
        border-radius: 10px;
        border: 1px solid #E0E0E0;
        width: 100%;
        padding: 16px 10px;
        height: 50px;
        color: #4F4F4F;
        font-family: ${fontFamilyEnnVisionsRegular};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .form-group .ant-input-affix-wrapper input.ant-input::placeholder{
        color: #4F4F4F;
        font-family: ${fontFamilyRegularUber};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegularUber, pureDark } from "../../../components/GlobalStyle";

export const OrganizerCreditHistoryStyle = styled.div`
width: 100%;
margin-top: 12px;

.title_AffiliationButton {
display: flex;
align-items: center;
justify-content: space-between;
padding-left: 13px;
}
.title {
color: #000;
font-family: ${fontFamilyMedium};
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.48px;
margin-bottom: 0;
}
.newAffiliationButton {
padding: 11px 10px 10px 15px;
border-radius: 4px;
border: 0.6px solid #BBE6C3;
border-radius: 4px;
background: #DEF2E2;
color: ${pureDark};
font-family: ${fontFamilyMediumUber};
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 1.6px;
}

.tablelist{
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    margin-top: 18px;
    margin-bottom: 21px;
}
.tablelist table{
    -top: 17px;
    width: 100%;
    border-collapas: collapas; 

}
.tablelist table tr:nth-child(odd){

}
.tablelist table tr:nth-child(even){
    background-color: #FAFAFA;
}
.tablelist table tr th{
    color: #061229
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
    padding: 17px 20px;
}
.tablelist tr td{
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 17px 20px;
    position: relative;
}
table tr td:nth-child(2) label{
    color: #fff;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 13px;
    border-radius: 4px;
    position: relative;
}
table tr td:nth-child(6) label{
    color: #fff;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 26px;
    border-radius: 4px;
    position: relative;
}
table tr td:nth-child(2) img, table tr td:nth-child(6) img{
    position: absolute;
    top: 8px;
    margin-left: -10px;
}
.Registrations{
    background-color: #0070E0;
}
.Active{
    background-color: #4CAF50;
}

.actions {
    text-align: center;
}
.nextBtn {
    text-align: right;
    margin-bottom: 15px;
}
.nextBtn button{
    padding: 11px 20px;
    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    border: none;
}
`;
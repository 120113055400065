import produce from 'immer'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userDataTypes } from './admin/user/updateUserStatusSlice'
import { loginData } from './types'

type appDataTypes = {
    data: any | loginData;
    loading: boolean;
    error: any;
}

const initialState: appDataTypes = {
    data: {
        user: {},
        loggedIn: false
    },
    loading: false,
    error: '',
}

const loginDataSlice = createSlice({
    name: 'user/loginData',
    initialState,
    reducers: {
        setLoginData: (state, action: PayloadAction<loginData>) => {
            state.data = {
                user: action.payload,
                loggedIn: true
            };
        },
        removeLoginData: (state) => {
            state.data = {
                user: {},
                loggedIn: false
            }
        },
        updateUserHandler: (
            state,
            { payload: { firstName, lastName } }: PayloadAction<userDataTypes>
        ) => {
            return produce(state, (draft) => {
                if (draft.data !== null) {
                    draft.data.userDetails = {
                        ...draft.data.userDetails,
                        userFirstName: firstName,
                        userLastName: lastName,
                    }
                }
            })
        },
    },
})
export const {
    setLoginData,
    removeLoginData,
    updateUserHandler,
} = loginDataSlice.actions
export default loginDataSlice.reducer

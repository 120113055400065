import React from "react";

interface TabContentProps {
  id: string; // Adjust the type based on your requirements
  activeTab: string; // Adjust the type based on your requirements
  children: React.ReactNode;
}

const TabContent: React.FC<TabContentProps> = ({ id, activeTab, children }) => {
  return activeTab === id ? <div className="TabContent">{children}</div> : null;
};

export default TabContent;
import React from "react";
import { BuyTicketsSelectUser4Style } from "./style";
import DeleteIcon from "../../../assets/icons/delete-Icon.svg";
import ApplePayLogo from "../../../assets/icons/Apple-Pay.svg";
import DropDownImage from "../../../assets/icons/ic_drop_down.svg";
const BuyTicketsSelectUser4 = () => {
  return (
    <div>
      <BuyTicketsSelectUser4Style>
        <div className="wrapper">
          <div className="mainContainer">
            <div className="card_mainContainer">
              <div className="cardTtile">
                <p>
                  $<span>133.23</span>
                </p>
              </div>
              <div className="maleGi_mainContainer">
                <div className="maleGi">
                  <div className="maleGi_leftSide">
                    <p>Male Gi750 THB</p>
                    <span>Weight: -64 kg (Light feather)</span>
                    <span>Belt: OPEN BELT</span>
                  </div>
                  <div className="maleGi_rightSide">
                    <p>$40.00 GBP</p>
                    <div className="deleteOption">
                      <img src={DeleteIcon} alt="DeleteIcon" />
                    </div>
                  </div>
                </div>

                <div className="maleGi maleGi_spacing">
                  <div className="maleGi_leftSide">
                    <p>Male Gi750 THB</p>
                    <span>Weight: -64 kg (Light feather)</span>
                    <span>Belt: OPEN BELT</span>
                  </div>
                  <div className="maleGi_rightSide">
                    <p>$40.00 GBP</p>
                    <div className="deleteOption">
                      <img src={DeleteIcon} alt="DeleteIcon" />
                    </div>
                  </div>
                </div>

                <div className="maleGi maleGi_spacing">
                  <div className="maleGi_leftSide">
                    <p>Male Gi750 THB</p>
                    <span>Weight: -64 kg (Light feather)</span>
                    <span>Belt: OPEN BELT</span>
                  </div>
                  <div className="maleGi_rightSide">
                    <p>$40.00 GBP</p>
                    <div className="deleteOption">
                      <img src={DeleteIcon} alt="DeleteIcon" />
                    </div>
                  </div>
                </div>

                <div className="maleGi maleGi_spacing">
                  <div className="maleGi_leftSide">
                    <p>Male Gi750 THB</p>
                    <span>Weight: -64 kg (Light feather)</span>
                    <span>Belt: OPEN BELT</span>
                  </div>
                  <div className="maleGi_rightSide">
                    <p>$40.00 GBP</p>
                    <div className="deleteOption">
                      <img src={DeleteIcon} alt="DeleteIcon" />
                    </div>
                  </div>
                </div>

                <div className="maleGi maleGi_spacing">
                  <div className="maleGi_leftSide">
                    <p>Male Gi750 THB</p>
                    <span>Weight: -64 kg (Light feather)</span>
                    <span>Belt: OPEN BELT</span>
                  </div>
                  <div className="maleGi_rightSide">
                    <p>$40.00 GBP</p>
                    <div className="deleteOption">
                      <img src={DeleteIcon} alt="DeleteIcon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="payCard_mainContainer">
              <div className="payLogo">
                <img src={ApplePayLogo} alt="ApplePayLogo" />
              </div>
              <div className="CardbodyContainer">
                <div className="payWithCard">
                  <span className="line"></span>
                  <p>Or pay with card</p>
                  <span className="line"></span>
                </div>
                <div className="email">
                  <label htmlFor="email">Email</label><br />
                  <input type="email" name="" id="email" />
                </div>

                <div className="nameOnCard">
                  <label htmlFor="Name">Name on card</label><br />
                  <input type="text" id="text"/>
                </div>

                <div className="countryRegion">
                  <span>Country or region</span>
                  <div className="country">
                    <h6>United States</h6>
                    <img src={DropDownImage} alt="DropDownImage" />
                  </div>
                  <div className="zip">
                    <span>Zip</span>
                  </div>

                  <div className="payOption">
                    <span>Pay $133.23</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BuyTicketsSelectUser4Style>
    </div>
  );
};

export default BuyTicketsSelectUser4;

import React from 'react'
import {FormFieldStyling, OrganizerCreateAnEventDiscountStyle} from "./style";

import { GlobalContainer } from "../../Home/style";
import { Form, Layout, SelectProps } from "antd";
import FormControl from "../../../components/FormControl";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import {
  fontFamilyEnnVisionsMedium,
  fontFamilyEnnVisionsRegular,
  fontFamilyRegular,
  mediumPurple,
  whiteColor,
} from "../../../components/GlobalStyle";
import FieldAddIcon from "../../../assets/icons/ic_add.svg";
import GapCurrencyIcon from "../../../assets/icons/is_GBP.svg";
import CustomButton from "../../../components/CustomButton/CustomButton";

const { Content } = Layout;

const OrganizerCreateAnEventDiscount = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };

  const options: SelectProps['options'] = [];
  for (let i = 10; i < 15; i++) {
    options.push({
      value: i.toString(15) + i,
      label: i.toString(15) + i,
    });
  }
  
  const handleChange = (selectedOptions: any) => {
    // Implement your logic for handling selected options here
    console.log('Selected options:', selectedOptions);
  };



  const options2: SelectProps['options'] = [];
  for (let i = 10; i < 20; i++) {
    options2.push({
      value: i.toString(20) + i,
      label: i.toString(20) + i,
    });
  }
  
  const handleChange2 = (selectedOption2: any) => {
    // Implement your logic for handling selected options here
    console.log('Selected options:', selectedOption2);
  };

  const handleCreateSubmit = () => {};
  return (
    <div>
        <OrganizerCreateAnEventDiscountStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="creatEvent-left">
                <CreatEventLeftSide />
              </Content>
              <Content className="creatEvent-right">
                <FormFieldStyling>
                  <>
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={handleCreateSubmit}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className="formWrapper">
                            <h3 className="title">Add new discount</h3>
                            <div className="tablelist">
                              <div className="organizerSettingForm">
                                <Row>
                                  <Col md="12" className="mb-20">
                                    <FormControl
                                      control="multiSelect"
                                      type="multiSelect"
                                      name="max-use-ammount1"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="If one of these are registered"
                                      padding="10px"
                                      mode="multiple"
                                      placeholder="If one of these are registered"
                                      suffix= {<img src={FieldAddIcon} alt="" />}
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }  
                                      onChange={handleChange}
                                      options={options}
                                    />
                                  </Col>
                                  <Col md="12" className="mb-20">
                                    <FormControl
                                      control="multiSelect"
                                      type="multiSelect"
                                      name="max-use-ammount1"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="together with one of these"
                                      padding="10px"
                                      mode="multiple"
                                      placeholder="together with one of these"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }  
                                      onChange={handleChange2}
                                      options={options2}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div className="formWrapper">
                            <h3 className="title">Then give this discount</h3>
                            <div className="tablelist">
                              <div className="organizerSettingForm">
                                <Row>
                                  <Col md="3" className="mb-20">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-use-ammount1"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="During early bird"
                                      padding="10px"
                                      placeholder="23.00"
                                      suffix={<img src={GapCurrencyIcon} alt="" />}
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="3" className="mb-20">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-use-ammount1"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="During normal fee"
                                      padding="10px"
                                      placeholder="33.00"
                                      suffix={<img src={GapCurrencyIcon} alt="" />}
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="3" className="mb-20">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-use-ammount1"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="During late fee"
                                      padding="10px"
                                      placeholder="40.00"
                                      suffix={<img src={GapCurrencyIcon} alt="" />}
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="3" className="mb-20">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="max-use-ammount1"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="How"
                                      padding="10px"
                                      placeholder="In a coupon code"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div className="mt-20 d-flex justify-content-end">
                            <CustomButton
                              bgcolor={mediumPurple}
                              textTransform="Captilize"
                              color={whiteColor}
                              padding="11px 40.50px"
                              fontFamily={`${fontFamilyEnnVisionsMedium}`}
                              width="fit-content"
                              type="submit"
                              title="Next"
                              fontSize="18px"
                              loading={false}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                  </>
                </FormFieldStyling>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
        </OrganizerCreateAnEventDiscountStyle>
    </div>
  )
}

export default OrganizerCreateAnEventDiscount
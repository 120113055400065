import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface StopwatchState {
    seconds: number;
    isRunning: boolean;
    intervalId: NodeJS.Timeout | null
}

const initialState: StopwatchState = {
    seconds: 20,
    isRunning: false,
    intervalId: null
};


export const stopwatchSlice = createSlice({
    name: 'stopwatch',
    initialState,
    reducers: {
        start: (state) => {
            state.isRunning = true;
        },
        stop: (state) => {
            state.isRunning = false;
        },
        reset: (state) => {
            state.seconds = 20;
            state.isRunning = false;
        },
        tick: (state) => {
            if (state.isRunning && state.seconds > 0) {
                state.seconds -= 1;
            }
        },
        setIntervalId: (state, action: PayloadAction<NodeJS.Timeout | null>) => {
            state.intervalId = action.payload;
        },
        increaseTime: (state) => {
            state.seconds += 1;
        },

        decreaseTime: (state) => {
            if (state.seconds > 0) {
                state.seconds -= 1;
            }
        },
    },
});

export const { start, stop, reset, tick, setIntervalId, increaseTime, decreaseTime } = stopwatchSlice.actions;

export const selectSeconds = (state: RootState) => state.stopWatch.seconds;
export const selectIsRunning = (state: RootState) => state.stopWatch.isRunning;

export default stopwatchSlice.reducer;


export const startStopwatch = () => (dispatch: any, getState: () => RootState) => {
    const existingIntervalId = getState().stopWatch.intervalId;
    if (existingIntervalId) {
        clearInterval(existingIntervalId);
    }

    const intervalId = setInterval(() => {
        dispatch(tick());
    }, 1000);

    dispatch(start());
    dispatch(setIntervalId(intervalId));
};

export const stopStopwatch = () => (dispatch: any, getState: () => RootState) => {
    const intervalId = getState().stopWatch.intervalId;

    if (intervalId) {
        clearInterval(intervalId);
    }

    dispatch(stop());
    dispatch(setIntervalId(null));
};

export const resetStopwatch = () => (dispatch: any) => {
    dispatch(reset());
};






// stopwatchSlice.ts
// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from '../../store'; // Adjust the path according to your file structure

// interface StopwatchState {
//     stalling: {
//         seconds: number;
//         isRunning: boolean;
//         intervalId: NodeJS.Timeout | null;
//     };
//     medical: {
//         seconds: number;
//         isRunning: boolean;
//         intervalId: NodeJS.Timeout | null;
//     };
// }

// const initialState: StopwatchState = {
//     stalling: {
//         seconds: 20,
//         isRunning: false,
//         intervalId: null,
//     },
//     medical: {
//         seconds: 20,
//         isRunning: false,
//         intervalId: null,
//     },
// };

// export const stopwatchSlice = createSlice({
//     name: 'stopwatch',
//     initialState,
//     reducers: {
//         start: (state, action: PayloadAction<'stalling' | 'medical'>) => {
//             state[action.payload].isRunning = true;

//             // Start the timer using setInterval
//             const intervalId = setInterval(() => {
//                 dispatch(tick(action.payload));
//             }, 1000); // Update every second

//             // Save the intervalId in the state
//             state[action.payload].intervalId = intervalId;
//         },
//         stop: (state, action: PayloadAction<'stalling' | 'medical'>) => {
//             state[action.payload].isRunning = false;

//             // Clear the interval when stopping the timer
//             clearInterval(state[action.payload].intervalId!);
//             state[action.payload].intervalId = null;
//         },
//         reset: (state, action: PayloadAction<'stalling' | 'medical'>) => {
//             state[action.payload].seconds = 20;
//             state[action.payload].isRunning = false;
//         },
//         tick: (state, action: PayloadAction<'stalling' | 'medical'>) => {
//             const stopwatch = state[action.payload];
//             if (stopwatch.isRunning && stopwatch.seconds > 0) {
//                 stopwatch.seconds -= 1;
//             }
//         },
//         setIntervalId: (state, action: PayloadAction<{ id: 'stalling' | 'medical'; value: NodeJS.Timeout | null }>) => {
//             state[action.payload.id].intervalId = action.payload.value;
//         },
//         increaseTime: (state, action: PayloadAction<'stalling' | 'medical'>) => {
//             state[action.payload].seconds += 1;
//         },
//         decreaseTime: (state, action: PayloadAction<'stalling' | 'medical'>) => {
//             const stopwatch = state[action.payload];
//             if (stopwatch.seconds > 0) {
//                 stopwatch.seconds -= 1;
//             }
//         },
//     },
// });

// export const {
//     start,
//     stop,
//     reset,
//     tick,
//     setIntervalId,
//     increaseTime,
//     decreaseTime,
// } = stopwatchSlice.actions;

// export const selectSeconds = (state: RootState, stopwatch: 'stalling' | 'medical') =>
//     state.stopWatch[stopwatch].seconds;

// export const selectIsRunning = (state: RootState, stopwatch: 'stalling' | 'medical') =>
//     state.stopWatch[stopwatch].isRunning;

// export default stopwatchSlice.reducer;


export const languageReducer = createSlice({
    name: 'language',
    initialState: {selectedLanguage: "GB"},
    reducers: {
        
    }
});
import styled from "styled-components";
import { fontFamilyRegularUber, lightDark2, pureDark2 } from "../GlobalStyle";

export const ProfileBeforeLoginIntroductionTwoStyle = styled.div`

.introductionMain {
    max-width: 359px;
    width: 100%;
    display: flex;
    border-radius: 0 0 5px 5px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding-right: 8px;
    padding-bottom: 14px;
}
.cardContainer {
    width: 100%;
}
.eastLogo img {
    margin-left: 22px !important;
    margin-top: -76px !important;
}

.personName {
    padding: 9px 0 0 17px;
    h4{
        color: ${pureDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
    }
}
.address {
    padding: 8px 0 0 17px;
    p {
       color: ${pureDark2};
       font-family: ${fontFamilyRegularUber};
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
       line-height: normal;
       margin-bottom: 0;
    }
    .cityName {
        color: ${lightDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .countryName {
        color: ${lightDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.phoneNum {
    padding: 10px 0 0 17px;
    p {
       color: ${pureDark2};
       font-family: ${fontFamilyRegularUber};
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
       line-height: normal;
       margin-bottom: 0;
       span {
          color: ${lightDark2};
          font-family: ${fontFamilyRegularUber};
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal; 
      }
   }
}
.age {
    padding: 10px 0 0 17px;
    p {
       color: ${pureDark2};
       font-family: ${fontFamilyRegularUber};
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
       line-height: normal;
       margin-bottom: 0;
       span {
          color: ${lightDark2};
          font-family: ${fontFamilyRegularUber};
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
      }
   }
}
.nationality {
    padding: 10px 0 0 17px;
    p {
       color: ${pureDark2};
       font-family: ${fontFamilyRegularUber};
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
       line-height: normal;
       margin-bottom: 0;
       span {
           color: ${lightDark2};
           font-family: ${fontFamilyRegularUber};
           font-size: 14px;
           font-style: normal;
           font-weight: 400;
           line-height: normal;
       }
   }
}
.rightSide {
    width: 27%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.boxes_Main {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 9px 0 0 17px;
}

.box1, .box2, .box3 {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    padding: 2px;
}

.box1 {
    background: #CDAF49;
}
.box2 {
    background: #A8A9AD;
}
.box3 {
    background: #BC6B50;
}
.box1 p, .box2 p, .box3 p{
    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

`;
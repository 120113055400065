import styled from "styled-components";
import { fontFamilyMedium, fontFamilyMediumUber, pureDark, whiteColor } from "../../../components/GlobalStyle";

export const OrganizerEventListStyle = styled.div`

    width: 100%;
    margin-top: 12px;

.title_AffiliationButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 13px;
}
.title {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.newAffiliationButton {
    padding: 11px 10px 10px 15px;
    border-radius: 4px;
    border: 0.6px solid #BBE6C3;
    border-radius: 4px;
    background: #DEF2E2;
    color: ${pureDark};
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}

.eventLists{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    gap: 16px;
    margin: 20px 0;
}
.eventLists > div{
    max-width: 33%;
}

`;
import {
  fontFamilyBoldUpber,
  fontFamilyMedium,
  fontFamilyMediumUber,
  fontFamilyRegular,
  fontFamilyRegularUber,
  tertiaryDark,
} from "./../../components/GlobalStyle";
import React from "react";
import styled from "styled-components";

export const ResetPasswordConfirmationStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 5em 0px;
  h2 {
    font-family: ${fontFamilyMediumUber};
    font-size: 22px;
  }
  .messageBodyBG {
    background-color: #f5f5f5;
    padding: 30px 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    margin-top: 10px;
    box-shadow: rgb(230 230 230) 0px 0px 6px;
    p {
      font-size: 16px;
      font-family: ${fontFamilyRegularUber};
    }
    a {
      background-color: #f5f5f5;
      color: #000;
      text-decoration: none;
      border: 1px solid #ccc;
      width: 100%;
      display: block;
      padding: 7px 10px;
      font-size: 15px;
      border-radius: 4px;
      font-weight: 500;
    }
  }
`;


export const OtpInputsStyled = styled.div`
  display: flex;
  justify-content: space-evenly;


  .otp-input{
    border-radius: 10px !important;
    margin-bottom: 0px !important;
    width: 52px !important;
    height: 52px !important;
    text-align: center;
    
    color: ${tertiaryDark};
    font-family: ${fontFamilyRegularUber};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

`;

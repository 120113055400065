import type { MenuProps } from 'antd'
type MenuItem = Required<MenuProps>['items'][number]



export const childListOfTickets: MenuItem[] = [
    {
        key: 'create',
        label: 'Create',
    },
    {
        key: 'list',
        label: 'List',
    },
]



import React from "react";
import { OrganizerSettingCreateNewTopListStyle } from "./style";
import { Form, Layout } from "antd";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Content } from "antd/lib/layout/layout";
import { Formik } from "formik";
import { Row, Col } from "react-bootstrap";
import FormControl from "../../../components/FormControl";
import { Switch } from "antd";
import { GlobalContainer } from "../../Home/style";
import DeleteIcon from "../../../assets/icons/ic_deleteDark.svg";
import {
  fontFamilyEnnVisionsMedium,
  fontFamilyEnnVisionsRegular,
  mediumPurple,
  whiteColor,
} from "../../../components/GlobalStyle";
import CustomButton from "../../../components/CustomButton/CustomButton";
const OrganizerSettingCreateNewTopList = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };
  const handleCreateSubmit = (): void => {};
  return (
    <div>
      <OrganizerSettingCreateNewTopListStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="OrganizerSettingCreateNewTopList-left">
                <CreatEventLeftSide />
              </Content>
              <Content className="OrganizerSettingCreateNewTopList-right">
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={handleCreateSubmit}
                >
                  {(formik) => {
                    return (
                      <div className="formWrapper">
                        <Form>
                          <h3 className="title">Top List Name & Type</h3>
                          <div className="bg-white topListForm">
                            <Row>
                              <Col md="4">
                                <FormControl
                                  control="input"
                                  type="text"
                                  name="topListName"
                                  label="Top list name"
                                  padding="10px"
                                  fontSize="16px"
                                  placeholderFont="16px"
                                  placeholderFamily="fontFamilyRegularUber"
                                  height="50px"
                                  margin="0"
                                  max={6}
                                  placeholder="1"
                                  className={
                                    formik.errors.timeZone &&
                                    formik.touched.timeZone
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                />
                              </Col>
                              <Col md="4">
                                <FormControl
                                  control="select"
                                  type="select"
                                  name="type"
                                  label="Type"
                                  padding="10px"
                                  fontSize="16px"
                                  placeholderFont="16px"
                                  placeholderFamily="fontFamilyRegularUber"
                                  max={6}
                                  placeholder="Yes"
                                  className={
                                    formik.errors.timeZone &&
                                    formik.touched.timeZone
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                />
                              </Col>
                              <Col md="4">
                                <FormControl
                                  control="select"
                                  type="select"
                                  name="ranking"
                                  label="Ranking"
                                  padding="10px"
                                  fontSize="16px"
                                  placeholderFont="16px"
                                  placeholderFamily="fontFamilyRegularUber"
                                  max={6}
                                  placeholder="Yes"
                                  className={
                                    formik.errors.timeZone &&
                                    formik.touched.timeZone
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col md="4">
                                <FormControl
                                  control="select"
                                  type="select"
                                  name="visibility"
                                  label="Visibility"
                                  padding="10px"
                                  fontSize="16px"
                                  placeholderFont="16px"
                                  placeholderFamily="fontFamilyRegularUber"
                                  max={6}
                                  placeholder="Yes"
                                  className={
                                    formik.errors.timeZone &&
                                    formik.touched.timeZone
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                />
                              </Col>
                              <Col md="4">
                                <FormControl
                                  control="select"
                                  type="select"
                                  name="exceptions"
                                  label="Exceptions"
                                  padding="10px"
                                  fontSize="16px"
                                  placeholderFont="16px"
                                  placeholderFamily="fontFamilyRegularUber"
                                  max={6}
                                  placeholder="Yes"
                                  className={
                                    formik.errors.timeZone &&
                                    formik.touched.timeZone
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                />
                              </Col>
                            </Row>
                          </div>
                          <div className="d-flex align-items-end justify-content-between pe-2 title_showPublicToggle">
                            <div>
                              <h3 className="title title_Sec2">Tiebreakers</h3>
                            </div>
                            <div className="bg-white d-flex align-items-center justify-content-between toggleBtn">
                              <p>Show Public Tiebreaker Results</p>
                              <div className="showApproved_toggleButton">
                                <Switch className="" />
                              </div>
                            </div>
                          </div>
                          <div className="bg-white tiebreakersForm">
                            <Row>
                              <Col md="6">
                                <div className="">
                                  <FormControl
                                    control="select"
                                    type="select"
                                    name="scoringAthletes"
                                    label="Tiebreaker Setting 1"
                                    padding="10px"
                                    fontSize="16px"
                                    placeholderFont="16px"
                                    placeholderFamily="fontFamilyEnnVisionsRegular"
                                    max={6}
                                    placeholder="Christmas 2023 Coupon Discount"
                                    className={
                                      formik.errors.timeZone &&
                                      formik.touched.timeZone
                                        ? "is-invalid"
                                        : "customInput"
                                    }
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="">
                                  <FormControl
                                    control="select"
                                    type="select"
                                    name="crossBracketing"
                                    label="Tiebreaker Setting 2"
                                    padding="10px"
                                    fontSize="16px"
                                    placeholderFont="16px"
                                    placeholderFamily="fontFamilyEnnVisionsRegular"
                                    max={6}
                                    placeholder="Christmas 2023 Coupon Discount"
                                    className={
                                      formik.errors.timeZone &&
                                      formik.touched.timeZone
                                        ? "is-invalid"
                                        : "customInput"
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="addOpt">
                              <span>Add More Options</span>
                            </div>
                          </div>
                          <h3 className="title title_Sec3">
                            Filter Registrations
                          </h3>
                          <div className="bg-white filterRegistrationsForm">
                            <Row>
                              <Col md="4">
                                <FormControl
                                  control="select"
                                  type="select"
                                  name="category"
                                  label="Category"
                                  padding="10px"
                                  fontSize="16px"
                                  placeholderFont="16px"
                                  placeholderFamily="fontFamilyRegularUber"
                                  max={6}
                                  placeholder="Country"
                                  className={
                                    formik.errors.timeZone &&
                                    formik.touched.timeZone
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                />
                              </Col>
                              <Col md="4">
                                <FormControl
                                  control="select"
                                  type="select"
                                  name="type"
                                  label="Type"
                                  padding="10px"
                                  fontSize="16px"
                                  placeholderFont="16px"
                                  placeholderFamily="fontFamilyRegularUber"
                                  max={6}
                                  placeholder="Is"
                                  className={
                                    formik.errors.timeZone &&
                                    formik.touched.timeZone
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                />
                              </Col>
                              <Col md="4">
                                <FormControl
                                  control="select"
                                  type="select"
                                  name="country"
                                  label="Country"
                                  padding="10px"
                                  fontSize="16px"
                                  placeholderFont="16px"
                                  placeholderFamily="fontFamilyRegularUber"
                                  max={6}
                                  placeholder="United Kingdom"
                                  className={
                                    formik.errors.timeZone &&
                                    formik.touched.timeZone
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                />
                              </Col>
                            </Row>
                            {Array.from({ length: 2 }, (_, index) => (
                              <div key={index}>
                                <Row className="mt-17">
                                  <Col md="1" className="orCondition">
                                    <div className="">
                                      <p>OR</p>
                                    </div>
                                  </Col>
                                  <Col md="3" className="">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="category"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Category"
                                      padding="10px"
                                      placeholder="Country"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="4" className="">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="type"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Type"
                                      padding="10px"
                                      placeholder="Is"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="3" className="">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="category"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Category"
                                      padding="10px"
                                      placeholder="United Kingdom"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="1" className="orCondition">
                                    <div className="deleteIcon">
                                      <img src={DeleteIcon} alt="" />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                            <div className="addOpt registrationAddOpt">
                              <span>Add More Options</span>
                            </div>
                          </div>
                          <div className="mt-17 d-flex justify-content-end">
                            <CustomButton
                              bgcolor={mediumPurple}
                              textTransform="Captilize"
                              color={whiteColor}
                              padding="11px 36px"
                              fontFamily={`${fontFamilyEnnVisionsMedium}`}
                              width="fit-content"
                              type="submit"
                              title="Next"
                              fontSize="16px"
                              loading={false}
                            />
                          </div>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerSettingCreateNewTopListStyle>
    </div>
  );
};

export default OrganizerSettingCreateNewTopList;

import styled from "styled-components";
import { AmericanYellow, Dandelion, DarkGreen, SpringFrost, fontFamilyBold, fontFamilyFuturaCondenseBold, fontFamilyMediumUber, pureDark, whiteColor, fontFamilyMedium } from "../../../components/GlobalStyle";
export const TraditionalScoreBoardStyle = styled.div`
#tradScoreboard {
	font-family: ${fontFamilyBold};
	text-transform: uppercase;
	font-weight: 500;
	line-height: 1.1;
	background: #1e1f23;
	position: relative;
	overflow: hidden;
	min-width: 132vh;
	touch-action: pan-x pan-y;
}

#tradScoreboard * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}


#tradScoreboard .control {
	position: absolute;
    top: 74%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    z-index: 6;
    transform-origin: 0px 50%;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
	z-index: 9999;
}


#tradScoreboard .control .actions {
	display: flex;
	border-radius: .8vh;
	transition: box-shadow 1s;
	font-size: 3vh;
}

#tradScoreboard .control .actions td {
	position: relative;
	width: auto;
	background: rgba(0,0,0,0.4) ;
	font-size: 1em;
	padding-right: 1em;
	padding-left: 1em;
	height: 7vh;
	display: table-cell;
	white-space: nowrap;
	cursor: pointer!important;
	text-align: center;
	border-radius: 0;
	line-height: .8;
}

#tradScoreboard .control .actions td a {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

#tradScoreboard .control .actions td svg, #tradScoreboard .control .actions td img {
	height: 3vh;
	width: auto;
}

#tradScoreboard #tradScoreboardPlayers {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 70vh;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player {
	height: 50%;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 2em;
	transition: all;
	background: #25262b;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player > * {
	z-index: 1;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .playerAttr {
	flex-wrap: wrap;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .result {
	font-size: 4.3vh;
    margin-left: 3.7vh;
    flex-basis: 100%;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: bounceInDown;
    position: absolute;
    left: 0px;
    top: 0px;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .result > span {
	background: #efd51c;
	color: #000;
	padding: 0 .4em;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .result.both > span {
	background: #fff!important;
}

#tradScoreboard #tradScoreboardPlayers > .playerLeft {
	border-bottom: 2px solid #000 !important;
}

#tradScoreboard #tradScoreboardPlayers > .playerRight {
	border-top: 2px solid #000  !important;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .tradPlayerPoints {
	min-width: 0;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	position: relative;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .playerAttr {
	font-size: 14vh;
	margin-top: -.07em;
	display: flex;
	align-items: center;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .playerAttr .attrRows {
	display: flex;
	flex-direction: column;
	flex-basis: 0;
	flex-grow: 1;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .playerAttr > .clubIcon {
	height: 1.6em;
	min-width: 1.6em;
	width: 1.6em;
	border: .03em solid #757575c7;
	margin-right: .3em;
	position: relative;
	top: .04em;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .playerAttr .subheader {
	display: flex;
	font-size: .7em;
	line-height: 1;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .playerAttr .subheader .clubAndTeamContainer {
	height: 1.1em;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .playerAttr .subheader .clubAndTeamContainer img {
	height: 0.88em;
	width: 0.88em;
	min-width: .8em;
	border: .04em solid #fff;
	position: relative;
    margin-right: 0.25em;
	border-radius: 50%;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .playerAttr .subheader .countryName {
	min-width: 1.5em;
	margin-right: .35em;
	text-align: center;
}

#tradScoreboard #tradScoreboardPlayers .playerAttr .flag {
	height: .78em;
	top: -.12em;
	position: relative;
}
.playerDetails > .trad-player .playerAttr .flag {
	height: .78em;
	top: -.12em;
	position: relative;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .playerAttr .clubLogo {
	display: block;
	float: left;
}

#tradScoreboard #tradScoreboardPlayers > .trad-player .playerAttr .name.wo {
	text-decoration: line-through;
	-webkit-text-decoration-color: rgba(255,255,255,.7);
	text-decoration-color: rgba(255,255,255,.7);
}

#tradScoreboard #tradScoreboardPlayers .tradScoresContainer {
	order: 2;
	display: flex;
	height: 100%;
}

#tradScoreboard #tradScoreboardPlayers .mainScore {
	font-family: ${fontFamilyFuturaCondenseBold};
	font-weight: 700;
	font-size: 33vh;
	line-height: 1;
	width: .9em;
	text-align: center;
	order: 2;
	display: flex;
	align-items: center;
	margin-left: .1em;
	position: relative;
	// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50);
	justify-content: center;
}
#tradScoreboard #tradScoreboardPlayers .mainScore .count{
	justify-content: center !important;
	margin-right: 0vh !important;
}
#tradScoreboard #tradScoreboardPlayers .mainScore div {
	width: 100%;
	text-align: center;
}

#tradScoreboard #tradScoreboardPlayers .mainScore .count {
	width: 100%;
	position: relative;
	top: -.02em;
	font-weight: ${fontFamilyMediumUber};
}

#tradScoreboard #tradScoreboardPlayers .mainScore .count.digits-2, .digits-2 {
	font-weight: ${fontFamilyMediumUber};
	font-size: .7em;
}

#tradScoreboard #tradScoreboardPlayers .mainScore .count.digits-3, .digits-3 {
	font-weight: ${fontFamilyMediumUber};
	font-size: .46em;
}


#tradScoreboard #tradScoreboardPlayers .extraScores {
	font-size: 2vh;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: wrap-reverse;
}

#tradScoreboard #tradScoreboardPlayers .extraScores > div {
	margin-left: 0vh;
	padding: 0px 2vh;
}

#tradScoreboard #tradScoreboardPlayers .extraScores .active {
	opacity: 1;
}

#tradScoreboard #tradScoreboardPlayers .extraScores .points {
	font-size: 12vh;
	font-family: ${fontFamilyFuturaCondenseBold};
	font-weight: 700;
	line-height: 1;
    color: #fff;
}
#tradScoreboard #tradScoreboardPlayers .extraScores .adv{
	color: #eddb2e;
	// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#tradScoreboard #tradScoreboardPlayers .extraScores .pen{
    color: #ec0001;
	// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#tradScoreboard #tradScoreboardPlayers .extraScores > div  div + div{
    color: #fff;
    font-size: 2.8vh;
}

#tradScoreboard #tradScoreboardPlayers .extraScores .advantage.active {
	color: #fff;
	opacity: 1;
}

#tradScoreboard #tradScoreboardPlayers .extraScores .penalty.active {
	color: #fff;
	opacity: 1;
}

#tradScoreboard #tradScoreboardPlayers .extraScores .cards .points {
	background: #ea1933;
	border-radius: .6vh;
	padding-left: 1.2vh;
	padding-right: 1.2vh;
	padding-top: .8vh;
	padding-bottom: 1.5vh;
	margin-top: .9vh;
	font-size: 9.7vh;
	color: #fff;
	line-height: .8;
}

#tradScoreboard .tradScoreboardBottom {
	position: relative;
	height: 30vh;
	display: flex;
	background: #1e1f23;
	justify-content: space-between;
}

#tradScoreboard .tradScoreboardBottom>* {
	z-index: 1;
}

#tradScoreboard .tradScoreboardBottom .matchNumber {
	background: #0B0A08;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 2vh;
	white-space: nowrap;
	font-family: ${fontFamilyFuturaCondenseBold};
}

#tradScoreboard .tradScoreboardBottom .matchNumber span {
	font-size: 18vh;
}
.matchNumber .digits-1{
	font-size: 18vh;
}
.matchNumber .digits-2 {
	font-weight: ${fontFamilyMediumUber};
	font-size: .7em;
}

.matchNumber .digits-3 {
	font-weight: ${fontFamilyMediumUber};
	font-size: .46em;
}

#tradScoreboard .tradScoreboardBottom .tradScoreboardBottom-controls {
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	overflow-x: hidden;
	padding-left: 4vh;
}

#tradScoreboard .tradScoreboardBottom #clock {
	font-size: 28vh;
	line-height: 1;
	white-space: nowrap;
	text-align: center;
	margin-top: 1vh;
	margin-bottom: 1vh;
	margin-right: 1vh;
	cursor: pointer!important;
	max-width: 70vh;
	width: 100%;
	position: relative;
}


#tradScoreboard .tradScoreboardBottom .scoreboard-logo {
	max-height: 24vh;
	max-width: 24vh;
	margin-right: 4vh;
}

#tradScoreboard .tradScoreboardBottom .scoreboard-background {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
	pointer-events: none;
}

#tradScoreboard .tradScoreboardBottom .scoreboard-background img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

#tradScoreboard .tradScoreboardBottom #result {
	font-size: 7vh;
	text-align: center;
	color: #ffff1d;
}

#tradScoreboard .tradScoreboardBottom .matchInfoContainer {
	display: flex;
	height: 100%;
}

#tradScoreboard .tradScoreboardBottom .matchInfo {
	font-size: 7vh;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	flex-grow: 1;
	overflow: hidden;
	padding-left: 4vh;
}

#tradScoreboard .tradScoreboardBottom .matchInfo .matchInfoText {
	display: table-cell;
	vertical-align: middle;
}

#tradScoreboard .tradScoreboardBottom .matchInfo .logo svg,
#tradScoreboard .tradScoreboardBottom .matchInfo .logo img {
	height: 24vh;
	width: auto;
	max-width: 25.5vh;
	margin-right: 4vh;
}

#tradScoreboard .tradScoreboardBottom .match-controls {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 2vh;
	display: flex;
	align-items: center;
}

#tradScoreboard .tradScoreboardBottom .match-controls .control {
	position: relative;
	top: unset;
	bottom: unset;
	left: unset!important;
}

.scMarquee .marqueeContainer {
	overflow: hidden;
	display: flex;
	margin-left: -4vh;
	padding-left: 4vh;
	transition: .2s all ease;
}

.scMarquee .marqueeContainer.animate {
	-webkit-mask-image: linear-gradient(to left,transparent,black 10vh,black calc(100% - 4vh),transparent);
	mask-image: linear-gradient(to left,transparent,black 10vh,black calc(100% - 4vh),transparent);
}

.scMarquee .marqueeContainer.animate .marqueeText {
	padding-right: 20vh;
}

.scMarquee .marqueeContainer .marqueeText {
	margin: 0;
	display: inline-block;
	white-space: nowrap;
	position: relative;
}

.scMarquee .marqueeContainer.animate .marqueeText {
	-webkit-animation: marquee 15s linear infinite;
	animation: marquee 15s linear infinite;
}

@-webkit-keyframes marquee {
	0% {
		transform: translate3D(0,0,0);
	}

	20% {
		transform: translate3D(0,0,0);
	}

	100% {
		transform: translate3D(-100%,0,0);
	}
}

@keyframes marquee {
	0% {
		transform: translate3D(0,0,0);
	}

	20% {
		transform: translate3D(0,0,0);
	}

	100% {
		transform: translate3D(-100%,0,0);
	}
}

.scMarquee.primary {
	color: #d0ac13;
}


#tradScoreboardPlayers .playerName {
    color: #fff;
}

.bracketDivision .marqueeText{
    color: rgba(231, 234, 255, 0.9);
}
#tradScoreboardPlayers .playerLeft {
    background-color: #3D3E42 !important;
}
#tradScoreboardPlayers .playerRight{
    background-color: #202125 !important;
}
#tradScoreboard .tradScoreboardBottom{
    background-color: #000 !important;
}
#tradScoreboardPlayers .playerLeft .mainScore{
    background: #AF0026 ;
    color: #fff !important;
}
#tradScoreboardPlayers .playerRight .mainScore{
	background: #1e409b;
    color: #fff !important;
}
#tradScoreboard #tradScoreboardPlayers .mainScore{
    margin-left: 0 !important;
}
#tradScoreboard #tradScoreboardPlayers .extraScores{
    background-color: #333;
    padding: 1.5vh !important;
}

#tradScoreboardPlayers .playerLeft .playerName, #tradScoreboardPlayers .playerRight .playerName {
    display: flex;
    align-items: flex-end;
}
#tradScoreboardPlayers .playerLeft .playerName .name, #tradScoreboardPlayers .playerRight .playerName .name{
    margin-left: 0.2em;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.clubAndTeamContainer .clubName{
    color: #c2c2c2 !important;
}

.clubAndTeamContainer .clubName, .clubAndTeamContainer .team-name{
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
	width: 100%;
}
.countryName{
    color: #fff !important;
}

.boardScoreAdd{
	width: auto;
}
.boardScoreAdd table{
	width: 100%;
	max-width: 58vh;
}
.boardScoreAdd table tr td table {
    border-spacing: 0.5vh 0.5vh;
}
.boardScoreAdd table tr td table tr td{
	padding: 0.5vh 1vh;
	font-size: 2.5vh;
}


.score2{
    position: relative;
    margin-right: 1vw;
	display: flex;
    justify-content: flex-end;
    align-items: center;
	border-radius: 1vh;
	animation: myfirst 1.5s linear infinite;
    .sec1{
		text-align: center;
		font-size: 18vh;
		border-radius: 1vh;
		z-index: 5;
		padding: 0;
		height: 1.7em;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 41.1vh;
			h2{
				color: ${DarkGreen};
				text-align: center;
				font-family: ${fontFamilyFuturaCondenseBold};
				font-size: 15.5vh;
				font-style: normal;
				font-weight: 400;
				text-transform: uppercase;
				line-height: 16vh;
				margin-bottom: 2vh;
			}
			p{
				color: ${DarkGreen};
				text-align: center;
				font-family: ${fontFamilyMedium};
				font-size: 7vh;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: uppercase;
				line-height: 6vh;
			}
        
    }
    .boardScorePlayer2{
        border-radius: 1vh;
		position: absolute;
		right: 0vh;
		top: 0vh;
		width: 41vh;
		height: 30.65vh;
		border-radius: 1vh;
		animation: myfirst 1.5s linear infinite;
		filter: drop-shadow(0 0 0.2vh rgba(0,0,0,0.8));
        .scoreBoardTable{
            border-radius: 1vh;
            .ScoreBoardTableInner{
                .ScoreBoardTableInner1{
                    .ScoreBoardTableInner11{
                        // padding: 0.5vh 2vh;
						padding: 1.1vh 2vh;
                        p{
                            color: rgb(255, 255, 255);
                            text-align: center;
							font-family: ${fontFamilyMediumUber};
                            font-size: 2vh;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: uppercase;
                            margin-bottom: 0;
							filter: drop-shadow(0 0 0.2vh rgba(0,0,0,0.8));
                        }
						p img {
							margin-left: 0.5vh;
							margin-bottom: 1vh;	
						}
                    }
                }
                .ScoreBoardTableInner2{
					display: flex;
					justify-content: space-between;				
                    .ScoreBoardTableInner21,
					.ScoreBoardTableInner22,
					.ScoreBoardTableInner23{
                        position: relative;
                        text-align: center;
                        padding: 0.5vh 2vh;
                        img:first-child{

                        }
                        img{
                            width: 2.5vh;
                            height: 2.5vh;
							filter: drop-shadow(0 0 0.2vh rgba(0,0,0,0.8));
                        }
                        p{
                            color: rgb(255, 255, 255);
                            text-align: center;
                            font-family: "UberMove-Bold";
                            font-size: 1.2vh;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: uppercase;
                            position: absolute;
                            left: 0px;
                            right: 0px;
                            top: 11vh;
                            margin: 0px;
							filter: drop-shadow(0 0 0.2vh rgba(0,0,0,0.8));
                        }
                        button{
                            background-color: transparent;
                            border: none;
                            outline: none;
                            img{
                                width: 2.8vh;
                                width: 2.8vh;
                                object-fit: cover;
                            }
                        }
                        
                    }
                    
                }
                .ScoreBoardTableInner3{
                    .ScoreBoardTableInner31{
                        // padding: 5px 20px;
						padding: 1.3vh 2vh;
						text-align: center;
						marign: auto;
                        p{
							color: rgb(255, 255, 255);
							text-align: center;
							margin: auto;
							font-size: 2vh;
							font-family: ${fontFamilyMediumUber};
							font-style: normal;
							font-weight: 700;
							line-height: normal;
							text-transform: uppercase;
							margin-bottom: 0px;
							text-shadow: 1px 1px 4px rgba(0,0,0,1);
                        }
						img{
							text-align: center;
							margin: auto;
						}
                    }
                }
            }
        }
    }
}
// .varScore2 {
//     position: absolute !important;
//     display: block !important;
//     top: 20vh;
//     z-index: 9;
//     right: 46vh;
// }

.varScore2 {
    position: absolute !important;
    right: 46vh;
    width: 22.1vh;
    height: 20vh;
    top: 25vh;
    z-index: 2;
  }
  .varScore2 h2{
    font-size: 12.5vh !important;
    line-height: 9.5vh !important;
	font-family: ${fontFamilyMedium} !important;
  }
  .varScore2 p{
    font-size: 4vh !important;
    line-height: 3.5vh !important;
    margin-bottom: 0;
  }
  .varScore2  .sec1{
    font-size: 12vh !important;
  }

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 99%;
}
.medical{
    background-color: ${SpringFrost};
}
.stalling{
    background-color: ${AmericanYellow};
}
.timeout{
    background-color: ${AmericanYellow};
}
.varAssistant{
	// background-color: ${AmericanYellow};
	background: linear-gradient(180deg, #FFCE00 45.63%, rgba(255, 206, 0, 0) 141.45%);
	width: 41.1vh !important;
}
.playerRight .ScoreBoardTableInnerRight p img {
	transform: rotate(180deg) !important;
	-webkit-transform: rotate(180deg);
	margin-left: 0.5vh;
	margin-bottom: 1vh;	
}

@keyframes myfirst {
    0% {
      box-shadow: 0 0 2vh ${Dandelion};
    }
    70% {
      box-shadow: none;
    }
    100% {
      box-shadow: 0 0 2vh ${Dandelion};
    }
  }
  @-webkit-keyframes myfirst {
    0% {
      box-shadow: 0 0 2vh ${Dandelion};
    }
    70% {
      box-shadow: none;
    }
    100% {
      box-shadow: 0 0 2vh ${Dandelion};
    }
  }
  @-moz-keyframes myfirst {
    0% {
      box-shadow: 0 0 2vh ${Dandelion};
    }
    70% {
      box-shadow: none;
    }
    100% {
      box-shadow: 0 0 2vh ${Dandelion};
    }
  }

@media screen and (max-width: 1450px){

}
@media screen and (max-width: 34vh){
    div#tradScoreboard{
        width: 34vh;
        overflow-x: auto;
        overflow-y: hidden;
    }

}
.playerRight .scoreBoardTable table tr:first-child td:first-child img{
	transform: rotate(180deg);
	vertical-align: center;
	margin-left: 4px;
}
.playerLeft .scoreBoardTable table tr:first-child td:first-child img{
	vertical-align: center;
	margin-left: 4px;
}
.playerRight .swapArrow .ScoreBoardTableInner1 .ScoreBoardTableInner11 p img{
	transform: rotate(180deg);
}



#clock .count{
    display: flex;
	justify-content: center;
    align-items: center;
    margin-right: 3vh;
    text-align: center;
	height: 30vh;
	background: ${pureDark};
	
}
.count p{
    display: inline-block;
    color: rgb(237, 219, 46);
    font-family: ${fontFamilyFuturaCondenseBold};
	vertical-align: top;
    display: inline-table;
    text-align: center;
    font-size: 29vh;
    line-height: 1;
    white-space: nowrap;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-right: 1vh;
    letter-spacing: 1px;
    background-color: rgb(0, 0, 0);
    position: relative;
    z-index: 9999;
    cursor: pointer !important;
}
.messagesShows .both .winner-text{
	color: ${pureDark};
    font-family: ${fontFamilyBold};
    font-size: 8.1vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.31px;
    text-transform: uppercase;
    padding: 0.6vh 1.5vh;
	background: #fff !important;
}
.messagesShows .submission .winner-text{
	color: ${pureDark};
    font-family: ${fontFamilyBold};
    font-size: 8.1vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.31px;
    text-transform: uppercase;
    padding: 0.6vh 1.5vh;
	background: #fdba13!important;
}
.messagesBody{
	display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
	animation: bounce; /* referring directly to the animation's @keyframe declaration */
  	animation-duration: 2s; /* don't forget to set a duration! */
}
.messagesBody .playerAttr .attrRows{
	text-align: center;
}
.messagesBody  .playerName{
	font-size: 17vh;
	width: 100%;
	max-width: 100%;
}
.messagesBody .playerAttr .subheader {
	font-size: 9vh;
}
.messagesBody  .playerName img{
	vertical-align: middle;
}
.messagesBody .playerDetails{
	margin-top: 1vh;
}
.messagesBody .trad-header .name{
	color: ${whiteColor};
	font-family: ${fontFamilyMedium};
	font-size: 15vh;
}


.doubleShowsComp {
    position: absolute;
    right: 1.2vh;
    z-index: 9;
    top: 30.4vh;
}
.doubleShowsComp1 {
    position: absolute;
    right: 2vh;
    z-index: 9;
    top: 31vh;
}

.showScoreBoardTable{
    position: absolute;
    left: 0vh;
    top: 7vh;
    width: 100%;
}

.matchControls123 {
    position: absolute;
    top: 9vh;
	left: 2vh;
}
.endGameBottom{
    position: absolute;
    top: 9vh;
    left: 4vh;
}
.boardScorePlayer2 {
	opacity: 0;
}
.score2 .sec1:hover{
	opacity: 1;
	box-shadow: rgba(0, 0, 0, 0.6) -1px -1px 4vh 2vh inset;
	z-index: 99;
}
.score2 .sec1:hover .boardScorePlayer2{
	opacity: 1;
}

.score2 .sec1:hover table tr{
	display: table;
    margin: 0.5vh auto;
}

.wonbyLight{
	position: absolute;
	z-index: 9;
	width: 100%;
	top: 4vh;
}
.wonbyLight .scoreBoardTable table tr:nth-child(1) td{
    background: rgb(175, 0, 38);
    color: rgb(255, 255, 255) !important;
}
.wonbyDark .scoreBoardTable table tr:nth-child(1) td{
	background: rgb(30, 64, 155);
    color: rgb(255, 255, 255) !important;
}

.wonbyDark{
	position: absolute;
	z-index: 9;
	width: 100%;
	top: 4vh;	
}
.truncate{
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.scoreBoardTable table tr:nth-child(3) td{
	font-size: 2.9vh;
}

`;
import discord from '../../../assets/icons/ic_discord.svg';
import LoginButton from './LoginButton';
import { base_url, discord_auth_service } from "../../../utils/api_urls";

const DiscordLogin = (): JSX.Element => {

    const handleDiscordLogin = () => window.location.href = `${base_url}${discord_auth_service}`;

    return (
        <div onClick={handleDiscordLogin}>
            <LoginButton type={discord as string} alt="Discord" />
        </div>
    )
};

export default DiscordLogin;

import React from "react";
import { AcademyUsersProfilesStyle } from "./style";
import ParticipantCardComponent from "../../../components/ParticipantCard/ParticipantCard";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import IntroductionComponent from "../../../components/ProfileBeforeLoginIntroduction/ProfileBeforeLoginIntroduction";
import MapFrame from "../../../assets/images/AcademyProfileMapImage-p3.png";
export const AcademyUsersProfiles = () => {
  return (
    <div>
      <AcademyUsersProfilesStyle>
        {/* <div className="banner">
          <img
            src={AcademyProfileBannerImage}
            alt="AcademyProfileBannerImage"
          />
        </div> */}
        <div className="wrapper">
          <div className="bodyLeftSideMain">
            <div className="introduction">
              <IntroductionComponent />
            </div>

            <div className="introductionParagraph">
              <h6>Introduction</h6>
              <p>
                I have been on so many different mats all over the world. That
                amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                began training in Jiu-Jitsu at just four years of age. His
                father, who was a brown belt, started his journey and took him
                to classes at João Alberto Barreto’s academy. As a teenager,
                Mauricio began training with the legendary Rolls Gracie. Under
                Rolls' guidance, he became a highly successful competitor and
                won the absolute division of the Rio de Janeiro State
                Championships in 1981. ....
              </p>
            </div>

            <div className="mapFrame">
              <img src={MapFrame} alt="" />
            </div>
          </div>

          <div className="bodyRightSideMain">
            <div className="studentsMain">
              <div className="studentsHeading">
                <h4>Students</h4>
              </div>
              <div className="studentCardMain">
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
                <ParticipantCardComponent />
              </div>
            </div>

            <div className="paginationMain">
              <div className="totalPages">
                <ul>
                  <li>
                    Page<span>1</span>of 10
                  </li>
                </ul>
              </div>
              <div className="pagination">
                <ul>
                  <li className="prevPage disabled">
                    <span>Prev</span>
                  </li>
                  <li className="page1">
                    <span>1</span>
                  </li>
                  <li className="page2">
                    <span>2</span>
                  </li>
                  <li className="page3 active">
                    <span>3</span>
                  </li>
                  <li className="page4_9">
                    <span>...</span>
                  </li>
                  <li className="page10">
                    <span>10</span>
                  </li>
                  <li className="nextPage">
                    <span>Next</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </AcademyUsersProfilesStyle>
    </div>
  );
};

export default AcademyUsersProfiles;

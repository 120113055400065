import styled from "styled-components";
import { fontFamilyBoldUpber, fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../components/GlobalStyle";
export const ScheduleScoreBoardStyle = styled.div`
.scoreBoard_container {
    max-width: 589px;
    width: 100%;
    border-radius: 11px;
    border: 1px solid #D5D3D3;
    background-color: #FFFFFF;
    position: relative;
}
.title_or_X {
    display: flex;
    padding: 11px 10.41px 0 40px;
}
.title {
    padding-top: 14px;
}
.title h4 {
    color:  #061229;
    text-align: center;
    font-family: ${fontFamilyMediumUber};
    font-size: 29px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    text-transform: capitalize;
    margin-bottom: 0;
}
.Xsvg {
    width: 29.587px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F7F6FF;
    border: 0.776px solid #EAEAEA;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    top: 11px;
    right: 10.41px;
}
.Xsvg img {
    width: 12.303px;
    height: 11.643px;
}
.matchMain {
    margin-top: 20px;
    padding: 0 41px 0 40px;
    display: flex;
    justify-content: space-between;
}
.player1_imageName, .matchCancelled, .player2_imageName {
    display: flex;
    flex-direction: column;
}
.player1_imageName img {
    width: 141px;
    height: 139px;
    border-radius: 9px;
    object-fit: cover;
}
.player1_imageName p {
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.8px;
    padding-top: 10px;
    margin-bottom: 0;
}
.player1_imageName span {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px;
}
.matchCancelled {
    width: 157px;
    margin-top: 32px;
}
.matchCancelled h5 {
    color: #061229;
    text-align: center;
    font-family: ${fontFamilyBoldUpber};
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.6px;
    text-transform: capitalize;
    margin-bottom: 0;
}
.matchCancelled span {
    color: #6DB01B;
    text-align: center;
    font-family: ${fontFamilyMediumUber};
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.8px;
    padding-top: 5px;
}
.player2_imageName img {
    width: 142px;
    height: 140px;
    border-radius: 10px;
    object-fit: cover;
}
.player2_imageName p {
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.818px;
    padding-top: 8px;
    margin-bottom: 0;
}
.player2_imageName span {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.818px;
}
table {
    max-width: 508px;
    width: 100%;
    margin: 11px auto 38px;
    border-radius: 9px;
    background-color: #FFF;
    border-radius: 10px;
    outline: 1px solid #EBEBEB;
}
table thead tr {
    border-radius: 9px 9px 0px 0px !important;
    background-color: #F6F6F6;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
}
table thead tr th {
    padding: 14px 0
}
table thead tr th:first-child {
    padding-left: 16px;
}
table thead tr th {
    color: #333;
    text-align: left;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.686px;
    text-transform: capitalize;
}
table tbody tr td:nth-child(2),
table tbody tr td:nth-child(3) {
    width: 165px;
    height: 38px;
    background-color: #FFF;
    border: 0.6px solid #C0D4DC;
    border-radius: 9px;
}
table tbody tr td:first-child {
    color: #333;
    font-family: ${fontFamilyMediumUber};
    text-align: left !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.8px;
    letter-spacing: -0.154px;
    text-transform: capitalize;
}
table tbody tr td {
    color: #333;
    font-family: ${fontFamilyMediumUber};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.8px;
    letter-spacing: -0.154px;
}
.innerTable {
    border-collapse: separate;
    border-spacing: 7px 9px;
    border-radius: 0;
    border: none;
    margin-top: 8px;
    margin-bottom: 0;
    padding-bottom: 2px;
    outline: none !important;
}
.innerTable tr td:first-child {
    padding-left: 9px !important;
}
`;
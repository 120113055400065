import styled from "styled-components";
import { fontFamilyBoldUpber, fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../components/GlobalStyle";

export const TestingComponentStyle  = styled.div`
// .closeWeighIn_container {
//     max-width: 1294px;
//     width: 100%;
//     border-radius: 12px;
//     background-color: #FAFAFA;
//     padding: 19px 25px 15px 25px;
// }
// .title h4 {
//     color: #000;
//     font-family: ${fontFamilyMedium};
//     font-size: 24px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//     letter-spacing: 0.48px;
//     margin-bottom: 0;
//     padding-left: 3px;
// }
// .messageContent {
//     width: 100%;
//     padding: 15px 10px 44px 14px;
//     border-radius: 10px;
//     border: 1px solid #E0E0E0;
//     background-color: #FFF;
//     margin-top: 11px;
// }
// .messageContent p {
//     width: 70%;
//     display: block;
//     color: #000;
//     font-family: ${fontFamilyEnnVisionsRegular};
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 22px;
//     margin-bottom: 0;
// }
// .tableContainer {
//     width: 100%;
//     margin-top: 29px;
//     padding: 0 14px;
//     border-radius: 10px;
//     border: 1px solid #E0E0E0;
//     background-color: #FFF;
// }
// table {
//     width: 100%;
//     border-collapse: separate;
//     border-spacing: 0px 13px;
// }
// table thead tr th {
//     padding: 8px 0 5px 9px;
//     color: #061229;
//     font-family: ${fontFamilyEnnVisionsMedium};
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: 20px;
// }
// table tbody tr td {
//     padding-left: 9px; 
// }
// table thead tr th:first-child {
//     padding-left: 0px;
// }
// table tbody tr td:first-child {
//     padding-left: 0px;
// }
// table thead tr th:last-child {
//     padding-right: 18px;
// }
// table tbody tr td:last-child {
//     padding-right: 19px;
//     border-radius: 0px 6px 6px 0px;
// }
// table thead tr th:first-child,
// table tbody tr td:first-child {
//     width: 5%;
//     border: none;
// }
// table thead tr th:nth-child(2),
// table tbody tr td:nth-child(2) {
//     width: 15%;
// }
// table thead tr th:nth-child(3),
// table tbody tr td:nth-child(3) {
//     width: 20%;
// }
// table thead tr th:nth-child(4),
// table tbody tr td:nth-child(4) {
//     width: 20%;
// }
// table thead tr th:nth-child(5),
// table tbody tr td:nth-child(5) {
//     width: 20%;
// }
// table thead tr th:last-child,
// table tbody tr td:last-child {
//     width: 20%;
//     text-align: right;
// }
// table tbody tr td {
//     border-top: 1px solid #E9E9E9;
//     border-bottom: 1px solid #E9E9E9;
// }
// table tbody tr:nth-child(odd) {
//     background-color: #FAFAFA;
// }
// table tbody tr:nth-child(even) {
//     background-color: #FFF;
// }
// .personImage img {
//     width: 60px;
//     height: 60px;
//     border-radius: 6px 0px 0px 6px;
// }
// .name {
//     color: #061229;
//     font-family: ${fontFamilyEnnVisionsRegular};
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
// }
// .clubTeam p, .clubTeam span,
// .group p, .group span {
//     color: #061229;
//     font-family: ${fontFamilyEnnVisionsRegular};
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 22px;
//     margin-bottom: 0;
// }
// .actionImage img {
//     cursor: pointer;
// }
// .changeStatusBtn {
//     display: flex;
//     align-items: center;
//     justify-content: end;
//     margin-top: 19px;
//     padding-right: 1px;
// }
// .changeStatusBtn button {
//     padding: 10px 10px 11px 10px;
//     border-radius: 4px;
//     background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
//     color: #FFF;
//     font-family: ${fontFamilyMediumUber};
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: normal;
//     letter-spacing: 1.6px;
//     text-transform: uppercase;
// }













































`;
import styled from "styled-components";
import {
  AlizarinCrimson,
  AntiFlashWhite,
  LaurelGreen,
  fontFamilyRegularUber,
  lightDark2,
  mediaDeviceMin,
  pureDark2,
  whiteColor,
} from "../../../components/GlobalStyle";

export const ListPageStyle = styled.div`
  margin: 20px;
  h3 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    font-family: ${fontFamilyRegularUber};
    color: ${pureDark2};
    margin-bottom: 10px;
    padding-left: 10px;
  }
  // background-color: #fff;
  border-radius: 20px;

  .table-heading {
    font-size: 16px;
    font-family: ${fontFamilyRegularUber};
    color: ${pureDark2};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-table {
    background: transparent;
  }
  .ant-table table {
    border-collapse: separate;
    border-spacing: 0px 5px;
  }
  .ant-table-thead > tr > th {
    height: 50px;
    background: transparent;
    border-bottom: none;
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 14.04px;
    &::before {
      display: none;
    }
  }

  .ant-table-thead > tr > th:nth-child(2){
    border-top-left-radius: 8px; 
    width: 70px;
  }
  .ant-table-thead > tr > th:last-child{
    border-top-right-radius: 8px !important; 
    width: 70px;
  }
  .ant-table-thead > tr > th:nth-child(7){
    width: 120px;
  }

  .ant-table-thead > tr > th:nth-child(3),
  .ant-table-thead > tr > th:nth-child(4),
  .ant-table-thead > tr > th:nth-child(5),
  .ant-table-thead > tr > th:nth-child(6){
    width: 20%;
  }

  .ant-table-thead > tr > th:first-child {
    display: none;
  }
  .ant-table-tbody > tr > td:first-child {
    display: none;
  }

  .ant-table-tbody > tr > .ant-table-cell,
  .ant-table-tbody > tr > .ant-table-cell a {
    padding: 4px 14.04px;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    color: ${lightDark2};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-table-thead > tr {
    background-color: #fff;
  }
  .ant-table-tbody > tr td {
    background-color: #fff;
    
  }
  .ant-table-tbody tr td:nth-child(2){
    padding: 0;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  .ant-table-tbody tr td:nth-child(2) img{
    width: 10%;
    padding: 0px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    height: 60px;
    width: 60px;
    object-fit: cover;
    object-position: center;
  }
  .ant-table-tbody tr td:last-child{
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }


  .ant-table-tbody > tr > td:nth-child(8),
  .ant-table-thead > tr > th:nth-child(8) {
    text-align: center;
  }

  .ant-table-tbody > tr > td:nth-child(7) .Active button {
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 7px 10px;
    border-radius: 4px;
    background: ${LaurelGreen};
    width: 90px;
    height: 30px;
    color: rgb(255, 255, 255) !important;
    font-size: 14px !important;
    display: block;
    position: relative;
    text-align: center;
  }
  .ant-table-tbody > tr > td:nth-child(7) .De-Active button {
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 7px 10px;
    border-radius: 4px;
    background: ${AlizarinCrimson};
    width: 90px;
    height: 30px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    display: block;
    position: relative;
    text-align: center;
  }



  // Header
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }




  // pagination
  .ant-pagination {
    margin-top: 45px;
    padding: 0px 10px;
  }
  .ant-pagination li:first-child {
    @media screen and ${mediaDeviceMin.mobileBS} {
      position: absolute;
      left: 10px;
    }
  }
  .ant-pagination .ant-pagination-options {
    display: none;
    opacity: 0;
    visibility: hidden;
    visibility: hidden;
    z-index: -1;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    padding: 0px 10px;
    border-radius: 8px;
  }
  .ant-pagination-prev .ant-pagination-item-link span,
  .ant-pagination-next .ant-pagination-item-link span {
    position: relative;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
  }
  .ant-pagination-prev .ant-pagination-item-link span:before {
    content: "Prev";
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: block;
  }
  .ant-pagination-next .ant-pagination-item-link span:before {
    content: "Next";
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: block;
  }
  .ant-pagination-prev .ant-pagination-item-link span svg,
  .ant-pagination-next .ant-pagination-item-link span svg {
    display: none;
  }
  .ant-pagination-item {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid ${AntiFlashWhite};
  }
  .ant-pagination-item a {
    border-radius: 8px;
    background: ${whiteColor};
    line-height: normal;
    padding: 7px 0px 8px 0px;
    box-shadow: 0px 0px 5px #e1e1e1;
  }
  .ant-pagination-item-active a {
    border-radius: 8px;
    background: ${LaurelGreen};
    color: ${whiteColor};
    line-height: normal;
    padding: 7px 0px 8px 0px;
  }
  .ant-pagination-total-text {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-block;
    > span > span {
      width: 47px;
      padding: 7px 31px 8px 9px;
      border-radius: 8px;
      border: 1px solid #e1e1e1;
      display: inline-block;
      margin: 0px 10px;
    }
  }
`;

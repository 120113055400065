import React, { useEffect } from "react";
import { TraditionalScoreBoardStyle } from "./style";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import SwapArrowDown from "../../../assets/icons/ic_ArrowDownScoreBoard.svg";
import SwapArrowUp from "../../../assets/icons/is_ArrowUpScoreBoard.svg";
import ingTimeBoardLeft from "../../../assets/icons/ic_ringTimeBoardLeft.svg";
import ingTimeBoardRight from "../../../assets/icons/ic_ringTimeBoardRight.svg";
import PlayBtn from "../../../assets/icons/ic_playBtn.svg";
import PauseBtn from "../../../assets/icons/ic_pauseBtn.svg";
import PauseIcon from "../../../assets/icons/ic_pause_ScoreBoard.svg";

const TraditionalScoreBoardMonitor: React.FC = () => {
  const {
    teamOneScoreBoard,
    teamTwoScoreBoard,
    playerOne,
    playerTwo,
    matchTimer,
    endGameMessage,
    endGameMessageType,
    endGameMessageB,
  } = useSelector((state: RootState) => state.traditionalScoreBoard);

  const formatTime = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const remainingSeconds = timer % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return ` ${formattedMinutes}:${formattedSeconds} `;
  };

  useEffect(() => {
    // New page
    window.addEventListener("message", (event) => {
      // Check if the message is from a trusted source (optional)
      // if (event.origin !== 'https://sourcepage.com') return;
      console.log("checking I am into message");
      if (event.data === "goFullscreen") {
        // Request fullscreen
        document.documentElement.requestFullscreen({ navigationUI: "hide" });
      }
    });
  }, []);

  return (
    <TraditionalScoreBoardStyle>
      <div id="tradScoreboard" >

        <div id="tradScoreboardPlayers" className={(endGameMessage || endGameMessageB) ? "animate__animated animate__tada" : ""}>
          {(endGameMessage || endGameMessageB) && (
            <div className="messagesBody">
              <div className="messagesShows">
              <div
                  className={`result ${
                    !endGameMessageType ? "submission" : "both"
                  }`}
                >
                  <span className="winner-text">
                    {!!endGameMessage ? endGameMessage : endGameMessageB}
                  </span>
                </div>
              </div>
              <div className="playerDetails trad-player">
                <div className="trad-player-points">
                  <div>
                    <div className="player-attr">
                      <div className="attr-rows ">
                        <div className="trad-header ">
                          <div className="player-name ">
                            <span className="name truncate">
                              {playerOne.wonBy && (
                                <>
                                  <span className="firstname">
                                    {playerOne.playerFirstName}
                                  </span>{" "}
                                  <span className="lastname">
                                    {playerOne.playerLastName}
                                  </span>
                                </>
                              )}
                              {playerTwo.wonBy && (
                                <>
                                  <span className="firstname">
                                    {playerTwo.playerFirstName}
                                  </span>{" "}
                                  <span className="lastname">
                                    {playerTwo.playerLastName}
                                  </span>
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!endGameMessage && !endGameMessageB && (
            <div className="trad-player player playerLeft">
              <div className="tradScoresContainer">
                <div
                  className="mainScore"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    className={`count ${
                      teamOneScoreBoard.score <= 9
                        ? "digits-1"
                        : teamOneScoreBoard.score <= 99
                        ? "digits-2"
                        : "digits-3"
                    }`}
                  >
                    {" "}
                    {teamOneScoreBoard.score}{" "}
                  </div>
                </div>

                <div className="extraScores">
                  <div className="advantage ">
                    <div className="adv points">
                      {teamOneScoreBoard.advantage}
                    </div>

                    <div>
                      {teamOneScoreBoard.advantage > 0
                        ? "Advantages"
                        : "Advantage"}
                    </div>
                  </div>
                  <div className="penalty ">
                    <div className="pen points">
                      {teamOneScoreBoard.penalty}
                    </div>
                    <div>
                      {teamOneScoreBoard.penalty > 0 ? "Penalties" : "Penalty"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="tradPlayerPoints">
                <div className="playerAttr">
                  <div className="attrRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <img src={playerOne.countryFlag} className="flag" />
                        <span className="name truncate">
                          <span className="firstname">
                            {playerOne.playerFirstName}
                          </span>{" "}
                          <span className="lastname">
                            {playerOne.playerLastName}
                          </span>
                        </span>
                      </div>
                    </div>

                    <div className="subheader">
                      <span className="countryName">
                        {playerOne.countryName}
                      </span>
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate">
                          <div className="clubName truncate">
                            <img
                              src={playerOne.clubFlag}
                              className="clubIcon imgCircle"
                            />
                            <span>{playerOne.clubName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`score2`}>
                {teamOneScoreBoard.isMedicalModal && (
                  <div className={`sec1 medical`}>
                    <h2>
                      {formatTime(teamOneScoreBoard.medicalValue as number)}
                    </h2>
                    <p>Medical</p>
                  </div>
                )}

                {teamOneScoreBoard.isStallingModal && (
                  <div className={`sec1 stalling`}>
                    <div>
                      <h2>
                        {formatTime(teamOneScoreBoard.stallingValue as number)}
                      </h2>
                      <p>Stalling</p>
                    </div>
                  </div>
                )}

                {teamOneScoreBoard.isTimeoutModal && (
                  <div className={`sec1 timeout`}>
                    <h2>
                      {formatTime(teamOneScoreBoard.timeoutValue as number)}
                    </h2>
                    <p>TIMEOUT</p>
                  </div>
                )}
              </div>
            </div>
          )}
          {teamOneScoreBoard.isVarModal ? (
            <div className="score2 varScore2">
              <div className={`sec1 varAssistant`}>
                <h2>VAR</h2>
                <p>MODE ON</p>
              </div>
            </div>
          ) : teamTwoScoreBoard.isVarModal ? (
            <div className="score2 varScore2">
              <div className={`sec1 varAssistant`}>
                <h2>VAR</h2>
                <p>MODE ON</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {!endGameMessage && !endGameMessageB && (
            <div className="trad-player playerRight">
              <div className="tradScoresContainer">
                <div className="mainScore">
                  <div
                    className={`count ${
                      teamTwoScoreBoard.score <= 9
                        ? "digits-1"
                        : teamTwoScoreBoard.score <= 99
                        ? "digits-2"
                        : "digits-3"
                    }`}
                  >
                    {" "}
                    {teamTwoScoreBoard.score}{" "}
                  </div>
                </div>
                <div className="extraScores">
                  <div className="advantage ">
                    <div className="adv points">
                      {teamTwoScoreBoard.advantage}
                    </div>
                    <div>
                      {teamTwoScoreBoard.advantage > 0
                        ? "Advantages"
                        : "Advantage"}{" "}
                    </div>
                  </div>
                  <div className="penalty ">
                    <div className="pen points">
                      {teamTwoScoreBoard.penalty}
                    </div>
                    <div>
                      {teamTwoScoreBoard.penalty > 0 ? "Penalties" : "Penalty"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tradPlayerPoints">
                <div>
                  <div className="playerAttr">
                    <div className="attrRows truncate">
                      <div className="tradHeader truncate">
                        <div className="playerName truncate">
                          <img src={playerTwo.countryFlag} className="flag" />{" "}
                          <span className="name truncate">
                            <span className="firstname">
                              {playerTwo.playerFirstName}
                            </span>{" "}
                            <span className="lastname">
                              {playerTwo.playerLastName}
                            </span>
                          </span>
                        </div>
                      </div>

                      <div className="subheader">
                        <span className="countryName">
                          {playerTwo.countryName}
                        </span>
                        <div className="clubAndTeam truncate">
                          <div className="clubAndTeamContainer truncate animate">
                            <div className="clubName truncate">
                              <img
                                src={playerTwo.clubFlag}
                                className="clubIcon imgCircle"
                              />
                              <span>{playerTwo.clubName}</span>
                            </div>
                            <div className="team-name truncate"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`score2`}>
                {teamTwoScoreBoard.isMedicalModal && (
                  <div className={`sec1 medical swapArrow`}>
                    <h2>
                      {formatTime(teamTwoScoreBoard.medicalValue as number)}
                    </h2>
                    <p>Medical</p>
                  </div>
                )}

                {teamTwoScoreBoard.isStallingModal && (
                  <div className={`sec1 stalling swapArrow`}>
                    <div>
                      <h2>
                        {formatTime(teamTwoScoreBoard.stallingValue as number)}
                      </h2>
                      <p>Stalling</p>
                    </div>
                  </div>
                )}

                {teamTwoScoreBoard.isTimeoutModal && (
                  <div className={`sec1 timeout`}>
                    <h2>
                      {formatTime(teamTwoScoreBoard.timeoutValue as number)}
                    </h2>
                    <p>TIMEOUT</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="tradScoreboardBottom">
          <div
            style={{
              flexGrow: "1",
              width: "0px",
              zIndex: "2",
              position: "relative",
            }}
          >
            <div className="matchInfoContainer">
              <div className="matchNumber">
                <span>1-1</span>
              </div>
              <div className="matchInfo">
                <div className="matchInfoText">
                  <div className="bracketDivision">
                    <div className="scMarquee">
                      <div className="marqueeContainer animate">
                        <span className="marqueeText">
                          Boys Gi / Mighty Mite I (4 yrs) / -17,90 kg / Grey
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bracketRound">
                    <div className="scMarquee primary">
                      <div className="marqueeContainer">
                        <span className="marqueeText">Semifinals</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="match-controls123"></div>
          </div>

          <div id="clock">
            <div className="time running">
              <div
                className="count "
                style={{
                  color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                }}
              >
                <p
                  style={{
                    color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                  }}
                >
                  {formatTime(matchTimer.matchTimerValue)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TraditionalScoreBoardStyle>
  );
};

export default TraditionalScoreBoardMonitor;

import React from "react";
import {
  FormFieldStyling,
  OrganizerAllEntriesEditValueSettingsStyle,
} from "./style";
import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import DeleteIcon from "../../../assets/icons/ic_deleteDark.svg"
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import FormControl from "../../../components/FormControl";
import {
  fontFamilyEnnVisionsMedium,
  fontFamilyEnnVisionsRegular,
  fontFamilyRegular,
  mediumPurple,
  whiteColor,
} from "../../../components/GlobalStyle";
import PlacesAutoCompleteInput from "../../../maps/PlacesAutocomplete";
import CustomButton from "../../../components/CustomButton/CustomButton";
const { Content } = Layout;

const OrganizerAllEntriesEditValueSettings = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };
  const handleCreateSubmit = (): void => {};
  return (
    <div>
      <OrganizerAllEntriesEditValueSettingsStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="creatEvent-full">
                <FormFieldStyling>
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={handleCreateSubmit}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className="formWrapper">
                            <h3 className="title">Value Settings</h3>
                            <div className="tablelist">
                              <div className="organizerSettingForm">
                                <Row>
                                  <Col md="5" className="mb-20">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-use-ammount"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Name"
                                      padding="10px"
                                      placeholder="Jiu Jitsu"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="7" className="">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-use-ammount"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Description"
                                      padding="10px"
                                      placeholder="Jiu Jitsu"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="7" className="">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-use-ammount"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Weight From"
                                      padding="10px"
                                      placeholder="Jiu Jitsu"
                                      
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="5" className="">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="how"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Weight To"
                                      padding="10px"
                                      placeholder="Jiu Jitsu"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div className="formWrapper">
                            <h3 className="title">Rule</h3>
                            <div className="tablelist">
                              <div className="organizerSettingForm">
                                <>
                                <Row className="mt-20 mb-20">
                                        <Col md="4" className="">
                                          <FormControl
                                            control="select"
                                            type="select"
                                            name="category1"
                                            fontFamily={
                                              fontFamilyEnnVisionsRegular
                                            }
                                            label="Category"
                                            padding="10px"
                                            placeholder="Jiu Jitsu"
                                            className={
                                              formik.errors.detail &&
                                              formik.touched.detail
                                                ? "is-invalid"
                                                : "customInput"
                                            }
                                          />
                                        </Col>
                                        <Col md="4" className="">
                                          <FormControl
                                            control="select"
                                            type="select"
                                            name="category2"
                                            fontFamily={
                                              fontFamilyEnnVisionsRegular
                                            }
                                            label="Category"
                                            padding="10px"
                                            placeholder="Jiu Jitsu"
                                            className={
                                              formik.errors.detail &&
                                              formik.touched.detail
                                                ? "is-invalid"
                                                : "customInput"
                                            }
                                          />
                                        </Col>
                                        <Col md="4" className="">
                                          <FormControl
                                            control="select"
                                            type="select"
                                            name="category3"
                                            fontFamily={
                                              fontFamilyEnnVisionsRegular
                                            }
                                            label="Category"
                                            padding="10px"
                                            placeholder="Jiu Jitsu"
                                            className={
                                              formik.errors.detail &&
                                              formik.touched.detail
                                                ? "is-invalid"
                                                : "customInput"
                                            }
                                          />
                                        </Col>
                                      </Row>
                                  {Array.from({ length: 3 }, (_, index) => (
                                    <div key={index}>
                                      
                                      <Row className="mt-20">
                                      <Col md="4" className="">
                                          <FormControl
                                            control="select"
                                            type="select"
                                            name="category1"
                                            fontFamily={
                                              fontFamilyEnnVisionsRegular
                                            }
                                            label=""
                                            padding="10px"
                                            placeholder="Jiu Jitsu"
                                            className={
                                              formik.errors.detail &&
                                              formik.touched.detail
                                                ? "is-invalid"
                                                : "customInput"
                                            }
                                          />
                                        </Col>
                                        <Col md="4" className="">
                                          <FormControl
                                            control="select"
                                            type="select"
                                            name="category2"
                                            fontFamily={
                                              fontFamilyEnnVisionsRegular
                                            }
                                            label=""
                                            padding="10px"
                                            placeholder="Jiu Jitsu"
                                            className={
                                              formik.errors.detail &&
                                              formik.touched.detail
                                                ? "is-invalid"
                                                : "customInput"
                                            }
                                          />
                                        </Col>
                                        <Col md="3" className="">
                                          <FormControl
                                            control="select"
                                            type="select"
                                            name="category3"
                                            fontFamily={
                                              fontFamilyEnnVisionsRegular
                                            }
                                            label=""
                                            padding="10px"
                                            placeholder="Jiu Jitsu"
                                            className={
                                              formik.errors.detail &&
                                              formik.touched.detail
                                                ? "is-invalid"
                                                : "customInput"
                                            }
                                          />
                                        </Col>
                                        <Col md="1" className="orCondition">
                                          <div className="deleteIcon">
                                            <img src={DeleteIcon} alt="" />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                </>
                              </div>
                            </div>
                          </div>

                          <div className="formWrapper">
                            <h3 className="title">Rule</h3>
                            <div className="tablelist">
                              <div className="organizerSettingForm">
                                <>
                                <Row className="mt-20 mb-20">
                                        <Col md="4" className="">
                                          <FormControl
                                            control="select"
                                            type="select"
                                            name="category1"
                                            fontFamily={
                                              fontFamilyEnnVisionsRegular
                                            }
                                            label="Custom Rule"
                                            padding="10px"
                                            placeholder="Yes"
                                            className={
                                              formik.errors.detail &&
                                              formik.touched.detail
                                                ? "is-invalid"
                                                : "customInput"
                                            }
                                          />
                                        </Col>
                                        <Col md="8" className="">
                                          <FormControl
                                            control="select"
                                            type="select"
                                            name="category2"
                                            fontFamily={
                                              fontFamilyEnnVisionsRegular
                                            }
                                            label="Name"
                                            padding="10px"
                                            placeholder="4 years"
                                            className={
                                              formik.errors.detail &&
                                              formik.touched.detail
                                                ? "is-invalid"
                                                : "customInput"
                                            }
                                          />
                                        </Col>
                                      </Row>
                                </>
                              </div>
                            </div>
                          </div>

                          <div className="mt-20 d-flex justify-content-end">
                            <CustomButton
                              bgcolor={mediumPurple}
                              textTransform="Captilize"
                              color={whiteColor}
                              padding="11px 40.50px"
                              fontFamily={`${fontFamilyEnnVisionsMedium}`}
                              width="fit-content"
                              type="submit"
                              title="Save"
                              fontSize="18px"
                              loading={false}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </FormFieldStyling>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerAllEntriesEditValueSettingsStyle>
    </div>
  );
};
export default OrganizerAllEntriesEditValueSettings;

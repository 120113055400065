import React from "react";
import { DoubleWostyle } from "./style";
import { useAppDispatch } from "../../../app/hooks";
import {
  updateEndGameMessage,
  updateEndGameMessageB,
  updateEndGameMessageType,
} from "../../../app/reducer/scoreBoard/ibjjfScoreboardSlice";
const DoubleWoIbjjf = () => {
  const dispatch = useAppDispatch();

  const handleClick = (message: string) => {
    dispatch(
      updateEndGameMessage({
        updatedEndGameMessage: message,
      })
    );
    dispatch(
      updateEndGameMessageB({
        updatedEndGameMessageB: message,
      })
    );
    dispatch(
      updateEndGameMessageType({
        updatedEndGameMessageType: true,
      })
    );
  };

  return (
    <>
      <DoubleWostyle>
        <div className="double-show">
          <div className="double-text">
            <p className="m-0" onClick={() => handleClick("DOUBLE WO/DQ")}>
              DOUBLE WO/DQ
            </p>
          </div>
          <div className="double-text">
            <p className="m-0" onClick={() => handleClick("DOUBLE NO SHOW")}>
              DOUBLE NO SHOW
            </p>
          </div>
        </div>
      </DoubleWostyle>
    </>
  );
};

export default DoubleWoIbjjf;

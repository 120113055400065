import React from 'react'
import { OrganizerSettingsStyle } from "./style"
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import StatusActiveError from "../../../assets/images/activeBtnError.svg";

const OrganizerSettings = () => {
  return (
    <div>
      <OrganizerSettingsStyle>
        <div className="title_AffiliationButton">
          <h4 className="title">Organizer</h4>
          <button className="newAffiliationButton">Apply Changes</button>
        </div>

        <div className='tablelist'>
          <div className='organizerSettingForm'>
            <form>
              <div className='form-group'>
                <label>Name</label>
                <input type="text" placeholder="East Jiu Jitsu" />
              </div>

              <div className='form-group'>
                <label>Currency</label>
                <select>
                  <option>omclean@kaimeramedia.com</option>
                </select>

              </div>
              <div className='form-group'>
                <label>Language</label>
                <select>
                  <option>omclean@kaimeramedia.com</option>
                </select>
              </div>
            </form>

          </div>
        </div>


        <h5 className='tableTitle'>Payment Information</h5>
        <div className='tablelist'>
          <table>
            <tr>
              <th>Payment Method</th>
              <th>Account Name</th>
              <th>Country Name</th>
              <th>Default</th>
              <th>Mode</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            {Array.from({ length: 4 }, (_, index) =>
              <tr>
                <td>
                  Gocardless
                </td>
                <td>UK Martial App</td>
                <td>United Kingdom</td>
                <td><label className="Default">Default</label><img src={StatusActiveError} alt="StatusActiveError" /></td>
                <td><label className="Live">Live</label><img src={StatusActiveError} alt="StatusActiveError" /></td>
                <td><label className="Active">Active</label><img src={StatusActiveError} alt="StatusActiveError" /></td>
                <td className='actions'>
                  <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                </td>
              </tr>
            )}
            <tr>
              <td>Cash</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td><label className="Add">Add</label></td>
              <td className='actions'>
                <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
              </td>
            </tr>
          </table>
        </div>
      </OrganizerSettingsStyle>
    </div>
  )
}

export default OrganizerSettings
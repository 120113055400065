import React from 'react'
import { OrganizerSettingPlacementPointsOverRideStyle } from "./style";
const OrganizerSettingPlacementPointsOverRide = () => {
  return (
    <div>
        <OrganizerSettingPlacementPointsOverRideStyle>
            
        </OrganizerSettingPlacementPointsOverRideStyle>
    </div>
  )
}

export default OrganizerSettingPlacementPointsOverRide
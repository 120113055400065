import React from 'react'
import {DynamoFightStyle} from "./style"
import ProfileBeforeImage from "../../assets/images/profile_before.svg";
export const DynamoFight = () => {
  return (
    <div>
        <DynamoFightStyle>
        <div className="card1">
                <div className="cardLogoLeftSide">
                    <img className="avatar1" src={ProfileBeforeImage} alt="avatar"  />
                </div>
              <div className="info1">
                <h4 className="name1">Dynamo Fight Sports</h4>
                <p className="activity1">Ipswich, United Kingdom</p>
                {/* <div className="blocks">
                  <div className="block1"><span>3</span></div>
                  <div className="block2"><span>2</span></div>
                  <div className="block3"><span>12</span></div>
                </div> */}
              </div>
        </div>

        </DynamoFightStyle>
    </div>
  )
}

export default DynamoFight;
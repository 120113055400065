import styled from "styled-components";
import { fontFamilyMedium } from "../../../components/GlobalStyle";

export const OrganizerSettingPlacementPointsStyle = styled.div`
  .ant-layout > .banner + .ant-layout {
    flex-direction: row;
    gap: 10px;
  }
  .OrganizerSettingPlacementPoints-left {
    max-width: 15%;
    width: 100%;
  }
  .title {
    margin-top: 32px;
    h3 {
      color: #000;
      font-family: ${fontFamilyMedium};
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.48px;
      margin-bottom: 0px;
    }
  }
  .formTable {
    padding: 16px 24px 20px 24px;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #e0e0e0;
    margin-top: 11px;
  }
`;

import React from 'react'
import {SelectDateStyle} from "./style"
export const SelectDate = () => {
  return (
    <div>
        <SelectDateStyle>
            <div className='selectDateMain'>
                <div className='days_Date'>
                    <h6>Day1</h6>
                    <p>Nov 7  - 10:00 am</p>
                </div>
                <div className='days_Date'>
                    <h6>Day 2</h6>
                    <p>Nov 8  - 10:00 am</p>
                </div>
                <div className='days_Date'>
                    <h6>Day 3</h6>
                    <p>Nov 9  - 10:00 am</p>
                </div>
                <div className='days_Date'>
                    <h6>Day 4</h6>
                    <p>Nov 10  - 10:00 am</p>
                </div>
            </div>

        </SelectDateStyle>
    </div>
  )
}

export default SelectDate;
import styled from "styled-components";
import { fontFamilyMediumUber, fontFamilyRegularUber } from "../GlobalStyle";


export const EventRegistrationCardStyle = styled.div`

.participateCard {
    display: flex;
    max-width:400px;
    width: 100%;
    border-radius: 4.115px;
    border: 1.029px solid #F4F4F4;
    background-color: #FFF;
}
.participateImage {
    width: 16.4%;
}
.participateImage img {
    width: 64.258px;
    height: 93.46px;
}

.participateDetailsMain {
    width: 83.6%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 9.74px;
}
.partcipateName {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.name {
    display: flex;
    align-items: center;
}
.name p {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 13.63px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.444px;
    margin-bottom: 0;
}
.blueDot {
    width: 10.287px;
    height: 10.287px;
    border-radius: 30.861px;
    background-color: #0070E0; 
    margin-left: 4.23px;  
}
.weight_Checkbox {
    display: flex;
    align-items: center;
    padding: 0 8px 0 15px;
}
.weight_Checkbox p {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 11.683px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px;
    padding-right: 9.92px;
    margin-bottom: 0;
}
.partcipateAddress {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.partcipateAddress p {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 11.683px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px;
    text-align: left;
    margin-bottom: 0;
}
.partcipateAddress span {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 11.683px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px;
    text-align: right;
    padding: 0 11.89px 0 18.74px;
}
.ageCountry_Main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ageCountryFlag {
    display: flex;
    align-items: center;
}
.ageCountryFlag img {
    width: 18px;
    height: 12px;
    margin-left: 6.01px;
}
.ageCountryFlag p {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 11.683px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px;
    margin-bottom: 0;
}
.ageCountry_Main span {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 10.71px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px;
    padding: 0 12.48px 0 15px;
}

`;
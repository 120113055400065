import { Link } from "react-router-dom"
import DynamoFight from "../../../components/DynamoFight/DynamoFight"
import ProfileBeforeLoginIntroduction from "../../../components/ProfileBeforeLoginIntroduction/ProfileBeforeLoginIntroduction"
import { AffiliateAllStatusStyle } from "./style"
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import MapFrame from "../../../assets/images/AcademyProfileMapImage-p3.png";
import AffiliateStatusCard from "../../../components/AffiliateListCard/AffiliateStatusCard";


const AffiliateAllStatus = () => {
    return (
        <>
            <AffiliateAllStatusStyle>
                <div className="banner">
                    <img
                        src={AcademyProfileBannerImage}
                        alt="AcademyProfileBannerImage"
                    />
                </div>
                <div className="wrapper">
                    <div className="bodyLeftSideMain">
                        <div className="introduction">
                            <ProfileBeforeLoginIntroduction />
                        </div>
                        <div className="affiliationsMain mt-20">
                            <div className="BlocksHeadings">
                                <h4>Affiliations</h4>
                                <span><Link to="/academy/my-profile/affiliate-status">See All</Link></span>
                            </div>
                            <div className="affiliationsCards">
                                <DynamoFight />
                            </div>
                        </div>
                        <div className="mapFrame mt-20">
                            <img src={MapFrame} alt="" />
                        </div>
                    </div>
                    <div className="bodyRightSideMain">
                        <div className="introductionParagraph mt-20">
                            <h6>Introduction</h6>
                            <div className="introBox">
                                <p>I have been on so many different mats all over the world. That
                                    amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                                    began training in Jiu-Jitsu at just four years of age. His
                                    father, who was a brown belt, started his journey and took him
                                    to classes at João Alberto Barreto’s academy. As a teenager,
                                    Mauricio began training with the legendary Rolls Gracie. Under
                                    Rolls' guidance, he became a highly successful competitor and
                                    won the absolute division of the Rio de Janeiro State
                                    Championships in 1981. I have been on so many different mats all over the world. That
                                    amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                                    began training in Jiu-Jitsu at just four years of age. His
                                    father, who was a brown belt, started his journey and took him
                                    to classes at João Alberto Barreto’s academy. As a teenager,
                                    Mauricio began training with the legendary Rolls Gracie. Under
                                    Rolls' guidance, he became a highly successful competitor and
                                    won the absolute division of the Rio de Janeiro State
                                    Championships in 1981. I have been on so many different mats all over the world. That
                                    amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                                    began training in Jiu-Jitsu at just four years of age. His
                                    father, who was a brown belt, started his journey and took him
                                    to classes at João Alberto Barreto’s academy. As a teenager,
                                    Mauricio began training with the legendary Rolls Gracie. Under
                                    Rolls' guidance, he became a highly successful competitor and
                                    won the absolute division of the Rio de Janeiro State
                                    Championships in 1981 ....</p>
                            </div>
                        </div>
                        <div className="StudentsWrapper mt-20">
                            <h4 className="studentTitle">Affiliate</h4>
                            <div className="studentHeader mt-10">
                                <p>Pending Affiliate</p>
                                <span><Link to="/academy/my-profile/affiliate-status/pending">See All</Link></span>
                            </div>
                            <AffiliateStatusCard />
                            <AffiliateStatusCard />
                            <AffiliateStatusCard />
                        </div>
                        <div className="StudentsWrapper mt-20">
                            <div className="studentHeader mt-10">
                                <p>Active Affiliate</p>
                                <span><Link to="/academy/my-profile/affiliate-status/active">See All</Link></span>
                            </div>
                            <AffiliateStatusCard />
                            <AffiliateStatusCard />
                            <AffiliateStatusCard />
                     
                        </div>
                    </div>
                </div>
            </AffiliateAllStatusStyle>
        </>
    )
}

export default AffiliateAllStatus
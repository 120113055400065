import styled from "styled-components";
import { fontFamilyRegularUber, pureDark2 } from "../../../components/GlobalStyle";


export const StudentProfileStyle = styled.div`

.studentCardsMain{
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4{
            color: ${pureDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.56px;
            margin-bottom: 0;
        }
            &-buttons{
                display: flex;
                align-items: center;
                gap: 10px;
            }

        }

    .studentCards{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
    }

    
}
.paginationContainer{
    margin-bottom: 10px;
}

`
import React from 'react'
import { OrganizerCreditHistoryAddBalanceStyle } from "./style"
import { Input } from 'antd'
import GapCurrencyIcon from "../../../assets/icons/is_GBP.svg"

const OrganizerCreditHistoryAddBalance = () => {
    return (
        <div>
            <OrganizerCreditHistoryAddBalanceStyle>
                <div className="title_AffiliationButton">
                    <h4 className="title">Add Credit Balance</h4>
                    <button className="newAffiliationButton">Add Credit</button>
                </div>

                <div className='tablelist'>
                    <div className='organizerSettingForm'>
                        <form>
                            <div className='form-group'>
                                <label>Credit Amount</label>
                                <select>
                                    <option>omclean@kaimeramedia.com</option>
                                </select>
                            </div>

                            <div className='form-group'>
                                <label>Credit Card</label>
                                <select>
                                    <option>omclean@kaimeramedia.com</option>
                                </select>

                            </div>
                            <div className='form-group'>
                                <label>Auto Refill</label>
                                <select>
                                    <option>omclean@kaimeramedia.com</option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <label>Minimum Balance for Auto Refill</label>
                                <Input
                                    placeholder={"10"}
                                    suffix={<img src={GapCurrencyIcon} alt="a" />}
                                />
                            </div>
                            <div className='form-group'>
                                <label>Auto Refill Amount</label>
                                <Input
                                    placeholder={"10"}
                                    suffix={<img src={GapCurrencyIcon} alt="a" />}
                                />
                            </div>
                            

                        </form>

                    </div>
                </div>
            </OrganizerCreditHistoryAddBalanceStyle>
        </div>
    )
}

export default OrganizerCreditHistoryAddBalance
import { AcademyProfilePastEventStyle } from "./style";
import PassEvenetCard from "../../../components/PassEventsCard/PassEventsCard";
import PaginationComponent from "../../../components/ProfilesBeforeLoginPagination/ProfilesBeforeLoginPagination";

export const AcademyProfilePastEvent = () => {
  return (
    <div>
      <AcademyProfilePastEventStyle>
        <div className='wrapper'>
          <div className='bodyRightSideMain'>
            <div className='pastEventsMain'>
              <div className='passEventHeading'>
                <h4>Pass Events</h4>
              </div>
              <div className='passEventCardMain'>
                <PassEvenetCard />
                <PassEvenetCard />
                <PassEvenetCard />
                <PassEvenetCard />
                <PassEvenetCard />
                <PassEvenetCard />
                <PassEvenetCard />
                <PassEvenetCard />
                <PassEvenetCard />
              </div>
            </div>
            <div className='paginationContainer'>
              <PaginationComponent />
            </div>
          </div>
        </div>
      </AcademyProfilePastEventStyle>
    </div>
  )
}

export default AcademyProfilePastEvent;
import React from 'react'
import {UncomingEventCardStyle} from "./style"
import ParticipantsCard from "../../assets/images/ParticipantsCard.svg"
import BrazilFlag from "../../assets/images/brazilFlag.svg"
import StarRatingImage from "../../assets/icons/star-rating.svg"

export const UncomingEventCard = () => {
  return (
    <div>
        <UncomingEventCardStyle>
        <div className="uncomingEvenet_CardMain">
            <div className="cardImage">
                <img src={ParticipantsCard} alt="ParticipantsCard" />
            </div>
            <div className="cardHeading">
                <h6>Women's Martial Arts Championship - 1st Edition Jiu Jitsu</h6>
            </div>
            <div className="countryRatingMain">
                <div className="countryNameFlag">
                    <span>Rio de Janeiro, BR</span>
                    <img src={BrazilFlag} alt="BrazilFlag" />
                </div>
                <div className="rating">
                    <img src={StarRatingImage} alt="" />
                    <p>4.6 <span> (780+)</span></p>
                </div>
            </div>
            <div className="winnerName">
                <div className="win"><span>Win</span></div>
                <p>Travis Pinder<span>Won by Walkover</span></p>
            </div>

            <div className="loserName">
                <div className="loss"><span>Loss</span></div>
                <p>Travis Pinder<span>Won by Walkover</span></p>
            </div>

            <div className="dateLearneMore">
                <span>Monday, 17th October 2023.</span>
                <a href="#">Learn More</a>
            </div>

            <div className="WonBronzeMain">
                <div className="WonBronzeLeauge eventLeauge"><span>Won Bronze</span></div>
            </div>
        </div>
        </UncomingEventCardStyle>

    </div>
  )
}
export default UncomingEventCard;


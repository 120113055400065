import React from 'react'
import { OrganizerCreateAnEventEventTemplateStyle } from "./style";
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import StatusActiveIcon from "../../../assets/icons/ic_info.svg";
const OrganizerCreateAnEventEventTemplate = () => {
  return (
    <div>
        <OrganizerCreateAnEventEventTemplateStyle>
            <div className='bodywrapper_RightSide'>
                <div className='title'>
                    <h4>Event Template</h4>
                </div>
                <div className='eventTemplateMain'>
                    <div className='template'>
                        <span>Template </span>
                        <select name="Template" id="template">
                            <option value="1">Select Template</option>
                            <option value="2">Select Template 2</option>
                            <option value="3">Select Template 3</option>
                            <option value="4">Select Template 4</option>
                        </select>
                    </div>
                    <div className='duplicteAlready'>
                        <span>Duplicate Already Created </span>
                        <select name="DuplicateAlready" id="duplicateAlready">
                            <option value="1">Select Template</option>
                            <option value="2">Select Template 2</option>
                            <option value="3">Select Template 3</option>
                            <option value="4">Select Template 4</option>
                        </select>
                    </div>



                </div>

                    <div className='entriesTableTitle'>
                        <h4>Entries</h4>
                    </div>
                <div className='entriesTableContainer'>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Entries Name</th>
                                <th>Age</th>
                                <th>Early bird</th>
                                <th>Normal</th>
                                <th>Late fee</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='id'>01</td>
                                <td className='entriesName'>BOYS GI</td>
                                <td className='age'>12 to 40 Years</td>
                                <td className='earlyBird'>£ 120.00</td>
                                <td className='normal'>£ 170.00</td>
                                <td className='lateFee'>£ 200.00</td>
                                <td className='status'>
                                    <span>Active</span>
                                    <img src={StatusActiveIcon} alt="StatusActiveIcon" />
                                </td>
                                <td className='actions'>
                                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                                </td>
                            </tr>
                            <tr>
                                <td className='id'>02</td>
                                <td className='entriesName'>GIRLS GI</td>
                                <td className='age'>12 to 40 Years</td>
                                <td className='earlyBird'>£ 120.00</td>
                                <td className='normal'>£ 170.00</td>
                                <td className='lateFee'>£ 200.00</td>
                                <td className='status'>
                                    <span>Active</span>
                                    <img src={StatusActiveIcon} alt="StatusActiveIcon" />
                                </td>
                                <td className='actions'>
                                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                                </td>
                            </tr>
                            <tr>
                                <td className='id'>03</td>
                                <td className='entriesName'>GIRLS GI</td>
                                <td className='age'>12 to 40 Years</td>
                                <td className='earlyBird'>£ 120.00</td>
                                <td className='normal'>£ 170.00</td>
                                <td className='lateFee'>£ 200.00</td>
                                <td className='status'>
                                    <span>Active</span>
                                    <img src={StatusActiveIcon} alt="StatusActiveIcon" />
                                </td>
                                <td className='actions'>
                                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                                </td>
                            </tr>
                            <tr>
                                <td className='id'>04</td>
                                <td className='entriesName'>Juvenile Boys Gi</td>
                                <td className='age'>12 to 40 Years</td>
                                <td className='earlyBird'>£ 120.00</td>
                                <td className='normal'>£ 170.00</td>
                                <td className='lateFee'>£ 200.00</td>
                                <td className='status'>
                                    <span>Active</span>
                                    <img src={StatusActiveIcon} alt="StatusActiveIcon" />
                                </td>
                                <td className='actions'>
                                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                                </td>
                            </tr>
                            <tr>
                                <td className='id'>05</td>
                                <td className='entriesName'>Juvenile Girls Gi</td>
                                <td className='age'>12 to 40 Years</td>
                                <td className='earlyBird'>£ 120.00</td>
                                <td className='normal'>£ 170.00</td>
                                <td className='lateFee'>£ 200.00</td>
                                <td className='status'>
                                    <span>Active</span>
                                    <img src={StatusActiveIcon} alt="StatusActiveIcon" />
                                </td>
                                <td className='actions'>
                                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                                </td>
                            </tr>
                            <tr>
                                <td className='id'>06</td>
                                <td className='entriesName'>Male Girls Gi</td>
                                <td className='age'>12 to 40 Years</td>
                                <td className='earlyBird'>£ 120.00</td>
                                <td className='normal'>£ 170.00</td>
                                <td className='lateFee'>£ 200.00</td>
                                <td className='status'>
                                    <span>Active</span>
                                    <img src={StatusActiveIcon} alt="StatusActiveIcon" />
                                </td>
                                <td className='actions'>
                                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                                </td>
                            </tr>
                            <tr>
                                <td className='id'>07</td>
                                <td className='entriesName'>Female Girls Gi</td>
                                <td className='age'>12 to 40 Years</td>
                                <td className='earlyBird'>£ 120.00</td>
                                <td className='normal'>£ 170.00</td>
                                <td className='lateFee'>£ 200.00</td>
                                <td className='status'>
                                    <span>Active</span>
                                    <img src={StatusActiveIcon} alt="StatusActiveIcon" />
                                </td>
                                <td className='actions'>
                                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>

        </OrganizerCreateAnEventEventTemplateStyle>
    </div>
  )
}

export default OrganizerCreateAnEventEventTemplate;

import React from "react";
import { OrganizerSettingBracketsStyle } from "./style";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";
import FormControl from "../../../components/FormControl";
import { Row, Col } from "react-bootstrap";
import { Content } from "antd/lib/layout/layout";
import { Formik, FormikHelpers, FormikValues } from "formik";
import CustomButton from "../../../components/CustomButton/CustomButton";
import {
  fontFamilyEnnVisionsMedium,
  fontFamilyMediumUber,
  mediumPurple,
  whiteColor,
} from "../../../components/GlobalStyle";

const OrganizerSettingBrackets = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };
  const handleCreateSubmit = (): void => {};
  return (
    <div>
      <OrganizerSettingBracketsStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="organizerSettingBrackets-left">
                <CreatEventLeftSide />
              </Content>

              <Content className="organizerSettingBrackets-right">
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={handleCreateSubmit}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <h3 className="title">Availability</h3>
                        <div className="bg-white form">
                          <div className="availabilityForm">
                            <Row>
                              <Col md="4" className="">
                                <FormControl
                                  control="select"
                                  type="select"
                                  name="publishBrackets"
                                  label="Publish brackets"
                                  padding="10px"
                                  fontSize="16px"
                                  placeholderFont="16px"
                                  placeholderFamily="fontFamilyEnnVisionsRegular"
                                  max={6}
                                  placeholder="Christmas 2023 Coupon Discount"
                                  className={
                                    formik.errors.timeZone &&
                                    formik.touched.timeZone
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="seedOptions">
                          <p className="seedOptionTitle">
                            Seed options
                            <span> (single and double eliminations)</span>
                          </p>
                          <div className="bg-white sec2_Form">
                            <div className="seedOptionForm">
                              <Row>
                                <Col md="6">
                                  <FormControl
                                    control="select"
                                    type="select"
                                    name="SeparateSeedBy"
                                    label="Separate seed by"
                                    padding="10px"
                                    fontSize="16px"
                                    placeholderFont="16px"
                                    placeholderFamily="fontFamilyEnnVisionsRegular"
                                    max={6}
                                    placeholder="Christmas 2023 Coupon Discount"
                                    className={
                                      formik.errors.timeZone &&
                                      formik.touched.timeZone
                                        ? "is-invalid"
                                        : "customInput"
                                    }
                                  />
                                  <p className="para mb-0 separateSeedByPara">
                                    Avoid team mates and nations facing each
                                    other.
                                  </p>
                                  <span className="para">
                                    When creating brackets, athletes will be
                                    placed (seeded) automatically and random.
                                    Although, to avoid team mates or nations
                                    facing each other in the first match we can
                                    separate the seed and place athletes as far
                                    away from each other as possible.
                                  </span>
                                </Col>
                                <Col md="6">
                                  <FormControl
                                    control="select"
                                    type="select"
                                    name="seedByRank"
                                    label="Seed by rank Activate ranking positions"
                                    padding="10px"
                                    fontSize="16px"
                                    placeholderFont="16px"
                                    placeholderFamily="fontFamilyEnnVisionsRegular"
                                    max={6}
                                    placeholder="Christmas 2023 Coupon Discount"
                                    className={
                                      formik.errors.timeZone &&
                                      formik.touched.timeZone
                                        ? "is-invalid"
                                        : "customInput"
                                    }
                                  />
                                  <p className="para seedByRankPara mb-0">
                                    This option allows you to assign ranking
                                    numbers in the registrations list. Those
                                    ranking numbers will later be taken into
                                    account when creating the seeds.
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                        <Row className="scoringAthletes_crossBracketingMain">
                          <Col md="6">
                            <div className="">
                              <h3 className="title">Scoring Athletes</h3>
                              <div className="bg-white leftForm">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="scoringAthletes"
                                      label="Scoring Athletes Use Scoring Athletes"
                                      padding="10px"
                                      fontSize="16px"
                                      placeholderFont="16px"
                                      placeholderFamily="fontFamilyEnnVisionsRegular"
                                      max={6}
                                      placeholder="Yes"
                                      className={
                                        formik.errors.timeZone &&
                                        formik.touched.timeZone
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                              </div>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="">
                              <h3 className="title">Cross bracketing</h3>
                              <div className="bg-white rightForm">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="crossBracketing"
                                      label="Cross bracketing"
                                      padding="10px"
                                      fontSize="16px"
                                      placeholderFont="16px"
                                      placeholderFamily="fontFamilyEnnVisionsRegular"
                                      max={6}
                                      placeholder="Determines how the repechage places matches"
                                      className={
                                        formik.errors.timeZone &&
                                        formik.touched.timeZone
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />

                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className="me-2 mt-3 d-flex justify-content-end">
                          <CustomButton
                            bgcolor={mediumPurple}
                            textTransform="Captilize"
                            color={whiteColor}
                            padding="11px 38px"
                            fontFamily={`${fontFamilyMediumUber}`}
                            width="fit-content"
                            type="submit"
                            title="SAVE"
                            fontSize="16px"
                            loading={false}
                          />
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerSettingBracketsStyle>
    </div>
  );
};

export default OrganizerSettingBrackets;

import React from 'react'
import { OrganizerSettingBracketListStyle } from "./style"
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import StatusActiveError from "../../../assets/images/activeBtnError.svg";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import OrganizerSettingLeft from '../../../components/OrganizerSettingLeft/OrganizerSettingLeft/OrganizerSettingLeft';
import TdDragDropIcon from "../../../assets/icons/ic_DragDropIcon.svg"
import { GlobalContainer } from "../../Home/style";
import { Layout } from "antd";
const { Content } = Layout;

const OrganizerSettingBracketList = () => {
    return (
        <div>
            <OrganizerSettingBracketListStyle>
                <GlobalContainer>
                    <Layout>
                        <div className="banner">
                            <img
                                src={AcademyProfileBannerImage}
                                alt="AcademyProfileBannerImage"
                            />
                        </div>
                        <Layout>
                            <Content className="contentBeforeLogin-left">
                                <OrganizerSettingLeft />
                            </Content>
                            <Content className="contentBeforeLogin-right">
                                <h4 className='title'>Bracket Packages</h4>
                                <div className='tablelist'>
                                    <table>
                                        <tr>
                                            <th></th>
                                            <th>Name</th>
                                            <th>Amount of Athletes</th>
                                            <th>Manual Brackets</th>
                                            <th>Bracket Type</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                        {Array.from({ length: 8 }, (_, index) =>
                                            <tr>
                                                <td>
                                                <img src={TdDragDropIcon} alt="TdDragDropIcon" />
                                                </td>
                                                <td>
                                                Ibjjf Package
                                                </td>
                                                <td>2 (Two)</td>
                                                <td>Single Elimination</td>
                                                <td>
                                                Bronze match
                                                </td>
                                                
                                                <td><label className="Sent">Sent</label><img src={StatusActiveError} alt="StatusActiveError" /></td>
                                                <td className='actions'>
                                                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                                                </td>
                                            </tr>
                                        )}
                                    </table>
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </GlobalContainer>
            </OrganizerSettingBracketListStyle>
        </div>
    )
}
export default OrganizerSettingBracketList
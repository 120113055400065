import React, { useState } from "react";
import { OrganizerCreateAnEventEditBracketsStyle } from "./style";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import UnitedKingdomFlag from "../../../assets/icons/uk-flag.svg";
import BeltImage from "../../../assets/images/progressbar.svg";
const OrganizerCreateAnEventEditBrackets = () => {
    const [fileInfo, setFileInfo] = useState({ name: '', type: '', size: 0 });
    const [isMultipleFiles, setIsMultipleFiles] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
  
    const handleFileChange = (event: any) => {
      const files = event.target.files;
  
      if (files.length > 1) {
        console.log(files, 1);
        setIsMultipleFiles(true);
        return;
      }
  
      const file = files[0];
      setIsMultipleFiles(false);
  
      setFileInfo({
        name: file.name,
        type: file.type,
        size: Math.round(file.size / 1024),
      });
    };
  
    const handleFocus = () => {
      setIsFocus(true);
    };
  
    const handleBlur = () => {
      setIsFocus(false);
    };

  return (
    <div>
      <OrganizerCreateAnEventEditBracketsStyle>
        <div className="banner">
          <img
            src={AcademyProfileBannerImage}
            alt="AcademyProfileBannerImage"
          />
        </div>
        <div className="bodyWrapper">
          <table>
            <tbody>
              <tr>
                <td className="belt">
                  <img src={BeltImage} alt="BeltImage" />
                </td>
                <td className="maleGi">
                  <p>Male Gi / White / Adult / -76 kg (Light)</p>
                </td>
                <td className="title">
                  <span>Area</span>
                  <p>Mat 1</p>
                </td>
                <td className="title">
                  <span>Bracket type</span>
                  <p>Single Elimination ShortCut Bronze </p>
                </td>
                <td className="title">
                  <span>Participants</span>
                  <p>64</p>
                </td>
                <td className="title">
                  <span>Nr of matches</span>
                  <p>63</p>
                </td>
                <td className="title">
                  <span>Time per match</span>
                  <p>5 Min</p>
                </td>
                <td className="editButton">
                  <button>Edit</button>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="unplacedMainBracket_container">
            <div className="block1_mainContainer">
              <div className="unplacedContainer">
                <div className="unplacedTitle">
                  <h6>Unplaced</h6>
                </div>
                <div className="unplacedCountryName">
                  <div className="countryFlagName">
                    <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
                    <p>Jhom Smith</p>
                    <span>bmjja</span>
                  </div>
                  <div className="unplaedButton">
                    <button>Unplaced</button>
                  </div>
                </div>
                <div className="unplacedCountryName">
                  <div className="countryFlagName">
                    <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
                    <p>Jhom Smith</p>
                    <span>bmjja</span>
                  </div>
                  <div className="unplaedButton">
                    <button>Unplaced</button>
                  </div>
                </div>
                <div className="unplacedCountryName">
                  <div className="countryFlagName">
                    <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
                    <p>Jhom Smith</p>
                    <span>bmjja</span>
                  </div>
                  <div className="unplaedButton">
                    <button>Unplaced</button>
                  </div>
                </div>
                <div className="unplacedCountryName">
                  <div className="countryFlagName">
                    <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
                    <p>Jhom Smith</p>
                    <span>bmjja</span>
                  </div>
                  <div className="unplaedButton">
                    <button>Unplaced</button>
                  </div>
                </div>
                <div className="unplacedCountryName">
                  <div className="countryFlagName">
                    <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
                    <p>Jhom Smith</p>
                    <span>bmjja</span>
                  </div>
                  <div className="unplaedButton">
                    <button>Unplaced</button>
                  </div>
                </div>
                <div className="unplacedCountryName">
                  <div className="countryFlagName">
                    <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
                    <p>Jhom Smith</p>
                    <span>bmjja</span>
                  </div>
                  <div className="unplaedButton">
                    <button>Unplaced</button>
                  </div>
                </div>
              </div>

              <div className="dragDrop">
                <form
                  method="post"
                  action="/upload"
                  style={{ width: "100%", height: "100%", margin: "1rem auto" }}
                >
                  <label
                    className={`file${isFocus ? " focus" : ""}${
                      isMultipleFiles ? " error" : ""
                    }`}
                    style={{
                      position: "relative",
                      display: "block",
                      width: "100%",
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "#333",
                      fontStyle: "normal",
                      textAlign: "center",
                      fontFamily: "fontFamilyMediumUber",
                      lineHeight: "21.818px",
                      letterSpacing: "-0.132px",
                      border: "1px dashed #c3c3c3",
                      margin: "14px 0 0 0",
                      padding: "12px 0px 11px 0px",
                      cursor: "pointer",
                      background: "#F0F0F0",
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnter={handleFocus}
                    onDragLeave={handleBlur}
                    onDrop={handleFileChange}
                  >
                    {isMultipleFiles ? (
                      "Sorry, multiple files are not allowed"
                    ) : (
                      <>
                        {!fileInfo.name ? (
                          "Drop athlete here"
                        ) : (
                          <>
                            {fileInfo.name} <br />
                            <span className="sml">
                              type: {fileInfo.type}, {fileInfo.size} KB
                            </span>
                          </>
                        )}
                      </>
                    )}
                    <input
                      type="file"
                      multiple
                      onChange={handleFileChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        outline: 0,
                        border: "1px solid red",
                      }}
                    />
                  </label>
                </form>
              </div>
            </div>
            <div className="block2_mainContainer">
              <div className="mainBracketsContainer">
                <div className="mainBracketsTitle">
                  <h6>Unplaced</h6>
                  <button>Bracket Setting</button>
                </div>
                <div className="unplacedCountryName">
                  <div className="countryFlagName">
                    <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
                    <p>Jhom Smith</p>
                    <span>bmjja</span>
                  </div>
                  <div className="unplaedButton">
                    <button>Unplaced</button>
                  </div>
                </div>
                <div className="unplacedCountryName">
                  <div className="countryFlagName">
                    <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
                    <p>Jhom Smith</p>
                    <span>bmjja</span>
                  </div>
                  <div className="unplaedButton">
                    <button>Unplaced</button>
                  </div>
                </div>
                <div className="unplacedCountryName">
                  <div className="countryFlagName">
                    <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
                    <p>Jhom Smith</p>
                    <span>bmjja</span>
                  </div>
                  <div className="unplaedButton">
                    <button>Unplaced</button>
                  </div>
                </div>
                <div className="unplacedCountryName">
                  <div className="countryFlagName">
                    <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
                    <p>Jhom Smith</p>
                    <span>bmjja</span>
                  </div>
                  <div className="unplaedButton">
                    <button>Unplaced</button>
                  </div>
                </div>
                <div className="unplacedCountryName">
                  <div className="countryFlagName">
                    <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
                    <p>Jhom Smith</p>
                    <span>bmjja</span>
                  </div>
                  <div className="unplaedButton">
                    <button>Unplaced</button>
                  </div>
                </div>
                <div className="unplacedCountryName">
                  <div className="countryFlagName">
                    <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
                    <p>Jhom Smith</p>
                    <span>bmjja</span>
                  </div>
                  <div className="unplaedButton">
                    <button>Unplaced</button>
                  </div>
                </div>
              </div>

              <div className="dragDrop">
                <form
                  method="post"
                  action="/upload"
                  style={{ width: "100%", height: "100%", margin: "1rem auto" }}
                >
                  <label
                    className={`file${isFocus ? " focus" : ""}${
                      isMultipleFiles ? " error" : ""
                    }`}
                    style={{
                      position: "relative",
                      display: "block",
                      width: "100%",
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "#333",
                      fontStyle: "normal",
                      textAlign: "center",
                      fontFamily: "fontFamilyMediumUber",
                      lineHeight: "21.818px",
                      letterSpacing: "-0.132px",
                      border: "1px dashed #c3c3c3",
                      margin: "14px 0 0 0",
                      padding: "12px 0px 11px 0px",
                      cursor: "pointer",
                      background: "#F0F0F0",
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnter={handleFocus}
                    onDragLeave={handleBlur}
                    onDrop={handleFileChange}
                  >
                    {isMultipleFiles ? (
                      "Sorry, multiple files are not allowed"
                    ) : (
                      <>
                        {!fileInfo.name ? (
                          "Drop athlete here"
                        ) : (
                          <>
                            {fileInfo.name} <br />
                            <span className="sml">
                              type: {fileInfo.type}, {fileInfo.size} KB
                            </span>
                          </>
                        )}
                      </>
                    )}
                    <input
                      type="file"
                      multiple
                      onChange={handleFileChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        outline: 0,
                        border: "1px solid red",
                      }}
                    />
                  </label>
                </form>
              </div>

              <div className="deleteAutomaticRedraw_buttons">
                <button className="deleteBracketButton">Delete Bracket</button>
                <button className="automaticRedrawButton">Automatic redraw <span>(includes unplaced fighters)</span></button>
                <button className="changeTypeButton">Change Type</button>
              </div>
            </div>
          </div>
        </div>
      </OrganizerCreateAnEventEditBracketsStyle>
    </div>
  );
};

export default OrganizerCreateAnEventEditBrackets;

import React from "react";
import ForgetPasswordStyle from "../style";
import Head from "../../../components/Head/Head";
import { Formik } from "formik";
import { Form } from "antd";
import FormControl from "../../../components/FormControl";
import { useNavigate } from "react-router-dom";
import {
  RobinEggBlue,
  fontFamilyEnnVisionsMedium,
  fontFamilyMedium,
  fontFamilyRegularUber,
  pureDark,
  pureDark2,
} from "../../../components/GlobalStyle";
import { validationFinder } from "../../../utils/utilities";
import * as Yup from "yup";
import CustomButton from "../../../components/CustomButton/CustomButton";
import useCreateNewPassword from "../../../hooks/useCreateNewPassword";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../redux/store";
import CloseBtn from "../../../assets/icons/ic_back.svg";
import CustomModal from "../../../components/Modal/CustomModal";
import { toast } from "react-toastify";

export interface createNewPasswordValuesType {
  password: string;
  confirmPassword?: string;
}
interface CreatePasswordProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreatePassword: React.FC<CreatePasswordProps> = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  const navigate = useNavigate();
  // const { selectedLanguage } = useSelector(
  //   (state: RootState) => state.selectedLanguage
  // );
  const initialValues: createNewPasswordValuesType = {
    password: "",
    confirmPassword: "",
  };

  // const password = validationFinder("PASSWORD")!;
  // const passwordReg = new RegExp(password.pattern);

  // const validationSchema = Yup.object({
  //   password: Yup.string()
  //     .required(password.notBlankMsgEn)
  //     .matches(passwordReg, password.patternMsgEn),
  //   confirmPassword: Yup.string()
  //     .required("confirm password is required!")
  //     .oneOf([Yup.ref("password")], "passwords must match"),
  // });

  const handleFormSubmit = async (values: createNewPasswordValuesType) => {
    try {
      await handleSubmit(values);
      setIsModalVisible(false);
    } catch (error) {
      toast("Failed to Submit Password Request", {
        type: "error",
        autoClose: 2000,
      });
    }
  };
  const { handleSubmit, loading } = useCreateNewPassword();
  return (
    <>
      <Head title="Create New Password" />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <ForgetPasswordStyle>
          <div className="forget-password-container overflow-auto">
            <div className="forget-password-container-card">
              <div className="forget-password-container-card-inner position-relative ">
                {/* <img src={CloseBtn} alt="" className="closeButtonIcon" onClick={() => navigate("/login")} /> */}
                <h6 className="create-title text-center mt-20">
                  Create New Password
                </h6>
                <p className="text-center create-password-text mt-10">
                  Your new password must be different from previous used
                  passwords.
                </p>
                <div className="forget-password-container-card-form w-100">
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                  >
                    {(formik) => {
                      return (
                        <Form
                          name="basic"
                          onFinish={formik.handleSubmit}
                          autoComplete="off"
                        >
                          <div className="mt-20">
                            <FormControl
                              control="password"
                              type="text"
                              name="password"
                              label="New Password"
                              fontFamily={fontFamilyMedium}
                              max={6}
                              placeholder="Enter Password"
                              className={
                                formik.errors.password &&
                                formik.touched.password
                                  ? "is-invalid"
                                  : "customPasswordInput"
                              }
                            />
                          </div>
                          <div className="mt-20">
                            <FormControl
                              control="password"
                              type="text"
                              name="confirmPassword"
                              fontFamily={fontFamilyMedium}
                              label="Confirm Password"
                              placeholder="Enter Confirm Password"
                              className={
                                formik.errors.confirmPassword &&
                                formik.touched.confirmPassword
                                  ? "is-invalid"
                                  : "customPasswordInput"
                              }
                            />
                          </div>
                          <div className="mt-20">
                            <CustomButton
                              bgcolor={RobinEggBlue}
                              textTransform="uppercase"
                              color={pureDark2}
                              padding="12px"
                              fontFamily={fontFamilyRegularUber}
                              width="100%"
                              type="submit"
                              title="Submit"
                              fontSize="14px"
                              loading={loading}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </ForgetPasswordStyle>
      </CustomModal>
    </>
  );
};

export default CreatePassword;

import React from "react";
import { CoachProfileMemberStyle } from "./style";
import PendingMebersImage from "../../../assets/images/ali-hassan.png";
import NorwayFlag from "../../../assets/icons/NorwayFlag.svg";
import ProgressBar from "../../../assets/icons/ParticipantsFrame.svg";
import plusIcon from "../../../assets/icons/plusIcon.svg";
import DropDownImage from "../../../assets/icons/ic_drop_down.svg";
import FormControl from "../../../components/FormControl";
import { Progress } from "flowbite-react";

export const CoachProfileMember = () => {
  return (
    <div>
      <CoachProfileMemberStyle>
        <div className="bodyWrapper_RightSide">
          <div className="title_CreateUser">
            <div className="membersTitle">
              <h4>Members</h4>
            </div>
            <div className="createUserButtonsMain">
              <button className="labelButton">Labels</button>
              <button className="createUserButton">
                Create user / invite member
              </button>
            </div>
          </div>
          <div className="pendingMembers">
            <h4>Pending Members</h4>
            <span>Sell All</span>
          </div>

          <table>
            <tbody>
              <tr>
                <td>
                  <img
                    src={PendingMebersImage}
                    alt="PendingMebersImage"
                    className="PendingMebersImage"
                  />
                </td>
                <td>
                  <div className="pendingMembersDetails">
                    <p>Ali Hassan</p>
                    <div className="countryFlag_Name">
                      <img src={NorwayFlag} alt="NorwayFlag" />
                      <span>MNBJJ Glasgow Brazilian Jiu-jitsu</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="moreDetails">
                    <p>-30,20 kg</p>
                    <p>Pee Wee lll (9yrs)</p>
                  </div>
                </td>
                <td>
                  <div className="age_or_plusButton">
                    <span>2012 . 11 years old</span>
                    <div className="progressBar_plusButton">
                    <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                      <button className="plusButton">
                        <img src={plusIcon} alt="plusIcon" />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="viewProfileButtons">
                    <button className="viewProfileButton">View Profile</button>
                    <button className="membersButton">
                      Member
                      <img src={DropDownImage} alt="DropDownImage" />
                    </button>
                    <span className="">
                      <FormControl
                        control="checkbox"
                        type="checkbox"
                        id="terms"
                        name="terms"
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="activeMembers">
            <h4>Active Members</h4>
            <span>Sell All</span>
          </div>

          <table className="activeMembersList">
            <tbody>
              <tr>
                <td>
                  <img
                    src={PendingMebersImage}
                    alt="PendingMebersImage"
                    className="PendingMebersImage"
                  />
                </td>
                <td>
                  <div className="pendingMembersDetails">
                    <p>Ali Hassan</p>
                    <div className="countryFlag_Name">
                      <img src={NorwayFlag} alt="NorwayFlag" />
                      <span>MNBJJ Glasgow Brazilian Jiu-jitsu</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="moreDetails">
                    <p>-30,20 kg</p>
                    <p>Pee Wee lll (9yrs)</p>
                  </div>
                </td>
                <td>
                  <div className="age_or_plusButton">
                    <span>2012 . 11 years old</span>
                    <div className="progressBar_plusButton">
                    <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                      <button className="plusButton">
                        <img src={plusIcon} alt="plusIcon" />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="viewProfileButtons">
                    <button className="viewProfileButton">View Profile</button>
                    <button className="membersButton">
                      Member
                      <img src={DropDownImage} alt="DropDownImage" />
                    </button>
                    <span className="">
                      <FormControl
                        control="checkbox"
                        type="checkbox"
                        id="terms"
                        name="terms"
                      />
                    </span>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </CoachProfileMemberStyle>
    </div>
  );
};

export default CoachProfileMember;

import React, { useState } from "react";
import { CoachProfileStyle } from "./style";
import { GlobalContainer } from "../../Home/style";
import { Layout } from "antd";
import ProfileBeforeLoginTabs from "../../../components/ProfileBeforeLoginTabs/ProfileBeforeLoginTabs";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import ProfileAfterLoginTabs from "../../../components/ProfileAfterLoginTabs/ProfileAfterLoginTabs";
import CoachProfileTabs from "../../../components/CoachProfileTabs/CoachProfileTabs";
import { AfterLoginPagesLeftSide } from "../../../components/AfterLoginPagesLeftSide/AfterLoginPagesLeftSide";
const { Content } = Layout;

const CoachProfile = ({ props }: any) => {
  const [activeTab, setActiveTab] = useState("tab1");
  return (
    <CoachProfileStyle>
      <GlobalContainer>
        <Layout>
          <div className="banner">
            <img
              src={AcademyProfileBannerImage}
              alt="AcademyProfileBannerImage"
            />
          </div>
          <Layout>
            <Content className="contentBeforeLogin-left">
              <AfterLoginPagesLeftSide />
            </Content>
            <Content className="contentBeforeLogin-right">
              <CoachProfileTabs />
            </Content>
          </Layout>
        </Layout>
      </GlobalContainer>
    </CoachProfileStyle>
  );
};

export default CoachProfile;

import styled from "styled-components";
import { fontFamilyRegularUber, lightDark2, pureDark2 } from "../../../components/GlobalStyle";

export const YourUpComingMatchesStyle = styled.div`
.cardContainer {
    width: 570px;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background-color: #f8f8f8;
}
.innerCardMain {
    display: flex;
    align-items: center;

    .totalMatches {
        width: 59px;
        padding: 20px 0px;
        border-top-left-radius: 5px;
        background-color: #EFEFEF;
        p {
           color: ${pureDark2};
           text-align: center;
           font-family: ${fontFamilyRegularUber};
           font-size: 16px;
           font-style: normal;
           font-weight: 500;
           line-height: normal;
           margin-bottom: 0px;
       }
    }

}
.PlayersMain {
    display: flex;
    align-items: center;
    background-color: #FFF;
    position: relative;
    
    .firstPlayer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #F4F4F4;
        background: #FFF;
        padding-right: 10px;
    }

    .imageTitleMain {
        display: flex;
        align-items: center;
    }

    .vs {
        max-width: 28px;
        width: 100%;
        height: 28px;
        padding: 2px 4px 1px;
        border-radius: 15.5px;
        border: 1px solid #16374E;
        background-color: #16374E;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 45.5%;
        top: 9.6%;
    
        color: #FFF;
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21.818px;
        letter-spacing: -0.154px;
        text-align: center;
    }

    .PlayerImage img {
        width: 62px;
        height: 57px;
    }

}

.TitleMain {
    display: flex;
    flex-direction: column;
    padding-left: 8px;

    .firstPlayerName {
        display: flex;
        align-items: center;
        gap: 8px;
        h6 {
            color: ${pureDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 21.818px;
            letter-spacing: -0.154px;
            margin-bottom: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    span {
        color: ${lightDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.818px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .blueDot {
        width: 10px;
        height: 10px;   
        border-radius: 30px;
        background-color: #0070E0;
        padding-left: 6px;
    }

}
.firstPlayer{
    .countryFlag {
        margin-left: 12.57px;
        margin-right: 10px;
        margin-top: 18px;
    }
    .countryFlag img {
        width: 30px;
        height: 30px;
    }
}
.secondPlayer{
    .countryFlag {
        margin-right: 10.57px;
        margin-left: 12px;
        margin-top: 18px;
    }
    .countryFlag img {
        width: 30px;
        height: 30px;
    }
}
.secondPlayer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #F4F4F4;
    background: #FFF;
    padding-left: 9px;
}
.PlayerImage2 img {
    border-top-right-radius: 5px;
    margin-left: 7.3px;
    width: 62px;
    height: 57px;
}
.playersName_MatchDateTime {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;


    .bracketsPlayersName {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    
    .brackets {
        border-radius: 4px;
        background: #5C6F80;
        padding: 7px 10px;
    
        color: #FFF;
        font-family: ${fontFamilyRegularUber};
        font-size: 12px;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.4px;
        text-transform: uppercase;
    }
    
    .playerName1, .playerName2 {
        border-radius: 4px;
        background: #E4E4E4;
        padding: 7px 10px;
    
        color: ${pureDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 12px;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.4px;
        text-transform: uppercase;
    }
}
.matchDateTime {
    border-radius: 4px;
    background-color: rgba(213, 213, 213, 0.30);
    padding: 7px 10px;

    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

`;
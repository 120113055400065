import { FormFieldStyling, OrganizerCreateAnEventStyle } from "./style";
import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import FormControl from '../../../components/FormControl';
import { fontFamilyEnnVisionsMedium, fontFamilyRegular, mediumPurple, whiteColor } from '../../../components/GlobalStyle';
import PlacesAutoCompleteInput from '../../../maps/PlacesAutocomplete';
import CustomButton from "../../../components/CustomButton/CustomButton";
const { Content } = Layout;


const OrganizerCreateAnEvent = () => {
  const initialValues = {
    startDate: '',
    endDate: '',
    timeZone: '',
    country: '',
    address: '',
    shortIntroduction: '',
    detail: '',
  };
  const handleCreateSubmit = (): void => { }
  return (
    <div>
      <OrganizerCreateAnEventStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="creatEvent-left">
                <CreatEventLeftSide />
              </Content>
              <Content className="creatEvent-right">
                <FormFieldStyling>
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={handleCreateSubmit}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <h3 className='title'>Information</h3>
                          <div className="bg-white form">
                            <div className='tablelist'>
                              <div className='organizerSettingForm'>
                                <Row>
                                  <Col md="4" className="mt-20">
                                    <FormControl
                                      control="dateTime"
                                      type="dateTime"
                                      name="startDate"
                                      label='Start Date'
                                      padding="10px"
                                      fontSize="16px"
                                      max={6}
                                      placeholder='Select Start Date'
                                      className={
                                        formik.errors.startDate &&
                                          formik.touched.startDate
                                          ? 'is-invalid'
                                          : 'customInput'
                                      }
                                    />
                                  </Col>
                                  <Col md="4" className="mt-20">
                                    <FormControl
                                      control="dateTime"
                                      type="dateTime"
                                      name="endDate"
                                      label='End Date'
                                      padding="10px"
                                      fontSize="16px"
                                      max={6}
                                      placeholder='Select End Date'
                                      className={
                                        formik.errors.endDate &&
                                          formik.touched.endDate
                                          ? 'is-invalid'
                                          : 'customInput'
                                      }
                                    />
                                  </Col>
                                  <Col md="4" className="mt-20">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="timeZone"
                                      label='Time Zone'
                                      fontSize="16px"
                                      max={6}
                                      placeholder='Select Time Zone'
                                      className={
                                        formik.errors.timeZone &&
                                          formik.touched.timeZone
                                          ? 'is-invalid'
                                          : 'customInput'
                                      }
                                    />
                                  </Col>
                                  <Col md="4" className="mt-20">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="country"
                                      label='Country'
                                      fontSize="16px"
                                      max={6}
                                      placeholder='Select Country'
                                      className={
                                        formik.errors.country &&
                                          formik.touched.country
                                          ? 'is-invalid'
                                          : 'customInput'
                                      }
                                    />
                                  </Col>
                                  <Col md="8" className="mt-20">
                                    <PlacesAutoCompleteInput
                                      label='Event Location'
                                      placeholder='Search Event Location'
                                      handleChange={(val) => {
                                        formik.setFieldValue(
                                          'address',
                                          val
                                        );
                                      }}
                                      className={
                                        formik.errors.address &&
                                        formik.touched.address
                                            ? 'is-invalid'
                                            : 'customInput'
                                    }
                                      formik={formik}
                                      name="address"
                                      value={formik.values.address}
                                    />
                                  </Col>
                                  <Col md="12" className='mt-20'>
                                    <FormControl
                                      control="textarea"
                                      type="text"
                                      name="shortIntroduction"
                                      fontFamily={fontFamilyRegular}
                                      label='Short Introduction'
                                      padding="10px"
                                      placeholder='Short Introduction'
                                      className={
                                        formik.errors.shortIntroduction &&
                                          formik.touched.shortIntroduction
                                          ? 'is-invalid'
                                          : 'customInput'
                                      }
                                      height="100px"
                                    />
                                  </Col>
                                  <Col md="12" className='mt-20'>
                                    <FormControl
                                      control="textarea"
                                      type="text"
                                      name="detail"
                                      fontFamily={fontFamilyRegular}
                                      label='Detail'
                                      padding="10px"
                                      placeholder='Detail'
                                      className={
                                        formik.errors.detail &&
                                          formik.touched.detail
                                          ? 'is-invalid'
                                          : 'customInput'
                                      }
                                      height="300px"
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div className="mt-20 d-flex justify-content-end">
                                    <CustomButton
                                        bgcolor={mediumPurple}
                                        textTransform="Captilize"
                                        color={whiteColor}
                                        padding="11px 40.50px"
                                        fontFamily={`${fontFamilyEnnVisionsMedium}`}
                                        width="fit-content"
                                        type="submit"
                                        title="Next"
                                        fontSize="18px"
                                        loading={false}
                                    />
                                </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </FormFieldStyling>
              </Content>
            </Layout>

          </Layout>
        </GlobalContainer>
      </OrganizerCreateAnEventStyle>
    </div>
  )
}

export default OrganizerCreateAnEvent
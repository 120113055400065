import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium } from "../../../../components/GlobalStyle";

export const RegistrationsAgeTableStyle = styled.div`
.mainContainer {
    width: 100%;
    margin-top: 35px;
}
.registrationAge table {
    margin-top: 15px;
}
.sectionTitles h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.tableContainer {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #E0E0E0;
    background-color: #FFF;
    margin-top: 11px;
}
table {
    width: 100%;
}
table thead tr th {
    padding: 21px 0 20px 10px;
    color: #061229;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
table tbody tr td {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
table thead tr th:first-child {
    border-top-left-radius: 10px;
}
table thead tr th:last-child {
    border-top-right-radius: 10px;
}
table tbody tr td {
    padding: 15.3px 0px 14.3px 15px;
}
table thead tr th:first-child,
table tbody tr td:first-child {
    padding-left: 22px;
    width: 3.45%;
}
table thead tr th:nth-child(2),
table tbody tr td:nth-child(2) {
    width: 21.55%;
}
table thead tr th:nth-child(3),
table tbody tr td:nth-child(3) {
    width: 25%;
}
table thead tr th:nth-child(4),
table tbody tr td:nth-child(4) {
    width: 25%;
}
table thead tr th:nth-child(5),
table tbody tr td:nth-child(5)  {
    width: 15%;
}
table thead tr th:last-child,
table tbody tr td:last-child {
    padding-right: 13px;
    width: 10%;
}
table thead tr th:last-child, 
table tbody tr td:last-child {
    text-align: center;
}
table tbody tr:nth-child(odd) {
    background-color: #FAFAFA;
}
table tbody tr:nth-child(even) {
    background-color: #FFF;
}
.hamBurger {
    width: 13px;
    height: 10px;
    cursor: pointer;
}
.status span {
    padding: 3px 31px 4px 31px;
    border-radius: 4px;
    background-color: #4CAF50;
    color: #FFF;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align-center;
}
.actions img {
    cursor: pointer;
}


`;
import React, { ChangeEvent, useRef, useState, useEffect } from "react";
import { AjpADCCScoreBoardStyle } from "./style";

import EngFlag from "../../../assets/images/engflag.svg";
import BoardTitleLogo from "../../../assets/images/logoBoardTtitle.svg";
import ingTimeBoardLeft from "../../../assets/icons/ic_ringTimeBoardLeft.svg";
import ingTimeBoardRight from "../../../assets/icons/ic_ringTimeBoardRight.svg";
import PlayBtn from "../../../assets/icons/ic_playBtn.svg";
import PauseBtn from "../../../assets/icons/ic_pauseBtn.svg";
import PauseIcon from "../../../assets/icons/ic_pause_ScoreBoard.svg";
import SwapArrowUp from "../../../assets/icons/is_ArrowUpScoreBoard.svg";
import SwapArrowDown from "../../../assets/icons/ic_ArrowDownScoreBoard.svg";
import EllipseDot from "../../../assets/icons/Ellipse-dot.svg";
import { Input, InputRef, Button, List, Rate, Avatar } from "antd";
import placeHolderImage from "../../../assets/images/custom_card_placeholder.png";
import { Link } from "react-router-dom";
import { FormControl } from "react-bootstrap";
import Ticketimg from "../../../assets/images/ticket-listing.svg";
import StarRating from "../../../assets/icons/star-rating.svg";
import Ukflag from "../../../assets/icons/uk-flag.svg";
import ParticipantImg from "../../../assets/images/ali-hassan.png";
import TicketListing from "../TicketListing/TicketListing";
import Participants from "../Participants/Participants";
import ProfilesLoginBefore from "../../ProfilesLoginBefore/ProfilesLoginBefore";
import { DoubleShowstyle } from "../../../components/ScoreBoardComponents/DoubleShow/style";
import DoubleShow from "../../../components/ScoreBoardComponents/DoubleShow/DoubleShowTraditional";
import DoubleWo from "../../../components/ScoreBoardComponents/DoubleWo/DoubleWoTraditional";
import AJPPenalityDot from "../../../assets/icons/ajpPenalityDot.svg";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  clearMedicalTimer,
  clearStallingTimer,
  clearTimeoutTimer,
  handleMatchTimer,
  handleMedicalTimer,
  handleStallingTimer,
  handleTimeoutTimer,
  handleUpdateMatchTimer,
  showMedicalModalHandler,
  showStallingModalHandler,
  showTimeoutModalHandler,
  swapMedicalTimerHandler,
  swapStallingTimerHandler,
  swapTimeoutTimerHandler,
  updateEndGameMessage,
  updateEndGameMessageB,
  updateEndGameMessageType,
  updateMatchTimer,
  updateMedicalTimerHandler,
  updatePlayer,
  updateScoreBoard,
  updateScoreHistory,
  updateStallingTimerHandler,
  updateTimeoutTimerHandler,
  scoreBoardHandler,
  advantageBoardHandler,
  penaltyBoardHandler,
  showVarModalHandler,
} from "../../../app/reducer/scoreBoard/ajpAdccScoreboardSlice";
import { useAppDispatch } from "../../../app/hooks";

import Minus30Icon from "../../../assets/icons/scoreBoardIcons/minus30.svg";
import Minus1Icon from "../../../assets/icons/scoreBoardIcons/minus1.svg";
import PlayIcon from "../../../assets/icons/scoreBoardIcons/playIcon.svg";
import Plus1Icon from "../../../assets/icons/scoreBoardIcons/plus1.svg";
import Plus30Icon from "../../../assets/icons/scoreBoardIcons/plus30.svg";
import ScoreBoardTable from "../../../components/ScoreBoardComponents/ScoreBoardTable/ScoreBoardTable";
import WonByUltm from "../../../components/ScoreBoardComponents/WonBy/WonByUltm";
import DoubleWoUltm from "../../../components/ScoreBoardComponents/DoubleWo/DoubleWoUltm";
import DoubleShowUltm from "../../../components/ScoreBoardComponents/DoubleShow/DoubleShowUltm";
import EndMatchScoreBoardTrad from "../../../components/EndMatchScoreBoardTrad/EndMatchScoreBoardTrad";
import EndMatchSave from "../../../components/ScoreBoardComponents/EndMatch/EndMatch";
import WonByAjpAdcc from "../../../components/ScoreBoardComponents/WonBy/WonByAjpAdcc";

interface ScoreHistoryProps {
  playerId: number;
  score?: number;
  penalty?: number;
  advantage?: number;
}

const AjpADCCScoreBoard: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    teamOneScoreBoard,
    teamTwoScoreBoard,
    scoreHistory,
    playerOne,
    playerTwo,
    matchTimer,
    endGameMessage,
    endGameMessageType,
    endGameMessageB,
  } = useSelector((state: RootState) => state.AjpAdccScoreBoard);

  // Section 1 and 2 functionality states

  const [showScoreBoardTableOne, setShowScoreBoardTableOne] = useState(true);
  const [showScoreBoardTableTwo, setShowScoreBoardTableTwo] = useState(true);

  // Section 3 functionality states
  const [showEndGameModal, setShowEndGameModal] = useState(false);
  const [endGame, setEndGame] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const [isShowTimerActions, setIsShowTimerACtions] = useState(false);

  console.log("checking teamOneScoreBoard: ", teamOneScoreBoard);

  // common handlers
  const handlePropagation = (event: any) => {
    event.stopPropagation();
  };

  const formatTime = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const remainingSeconds = timer % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return ` ${formattedMinutes}:${formattedSeconds} `;
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data === "goFullscreen") {
        document.documentElement.requestFullscreen({ navigationUI: "hide" });
      }
    });
  }, []);

  return (
    <AjpADCCScoreBoardStyle>
      <div id="tradScoreboard">

      {/* <div className="messagesBody">
            <div className="messagesShows">
              <div className={`result submission `}>
                <span className="winner-text">Won by Winner</span>
              </div>
            </div>
            <div className="playerDetails trad-player">
              <div className="trad-player-points">
                <div>
                  <div className="player-attr">
                    <div className="attr-rows ">
                      <div className="trad-header ">
                        <div className="player-name ">
                          <span className="name truncate">
                            <span className="firstname">Jennine</span>{" "}
                            <span className="lastname">Berry</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


        <div id="tradScoreboardPlayers">
          <div className="player playerContainer playerLeft">
            <div className="playerControls">
              <div>
                <div className="playerAttributes">
                  <div className="attributeRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <img src={playerOne.countryFlag} className="flag" />
                        <span className="name truncate">
                          <span className="firstname">
                            {playerOne.playerFirstName}{" "}
                          </span>
                          <span className="lastname">
                            {playerOne.playerLastName}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="subheader">
                      <span className="countryName">
                        {playerOne.countryName}
                      </span>
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate">
                          <div className="clubName truncate">
                            <img
                              src={playerOne.clubFlag}
                              className="clubIcon imgCircle"
                            />
                            <span>{playerOne.clubName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="control control-toggable disappeared appear">
                <div className="control-wrapper control-running toggleEndView toggleTimeView">
                  <div></div>
                </div>
              </div>
            </div>
            <div className={`score2`}>
              {teamOneScoreBoard.isMedicalModal && (
                <div className={`sec1 medical`}>
                  <h2>
                    {formatTime(teamOneScoreBoard.medicalValue as number)}
                  </h2>
                  <p>Medical</p>
                </div>
              )}
              {teamOneScoreBoard.isStallingModal && (
                <div className={`sec1 stalling`}>
                  <h2>
                    {formatTime(teamOneScoreBoard.stallingValue as number)}
                  </h2>
                  <p>Stalling</p>
                </div>
              )}
              {teamOneScoreBoard.isTimeoutModal && (
                <div className={`sec1 timeout`}>
                  <h2>
                    {formatTime(teamOneScoreBoard.timeoutValue as number)}
                  </h2>
                  <p>TIMEOUT</p>
                </div>
              )}
              {teamOneScoreBoard.isVarModal && (
                <div className={`sec1 varAssistant`}>
                  <h2>VAR</h2>
                  <p>MODE ON</p>
                </div>
              )}
            </div>
          </div>
          <div className="player playerContainer playerRight">
            <div className="playerControls">
              <div>
                <div className="playerAttributes">
                  <div className="attributeRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <span className="name truncate">
                          <span className="firstname">
                            {playerTwo.playerFirstName}
                          </span>{" "}
                          <span className="lastname">
                            {playerTwo.playerLastName}
                          </span>
                        </span>
                        <img src={playerTwo.countryFlag} className="flag" />
                      </div>
                    </div>
                    <div className="subheader">
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate animate">
                          <div className="clubName truncate">
                            <span>{playerTwo.clubName}</span>
                            <img
                              src={playerTwo.clubFlag}
                              className="clubIcon imgCircle"
                            />
                          </div>
                        </div>
                      </div>
                      <span className="countryName">
                        {playerTwo.countryName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="control control-toggable disappeared appear">
                <div className="control-wrapper control-running toggleEndView toggleTimeView">
                  <div></div>
                </div>
              </div>
            </div>
            <div className={`score2`}>
              {teamTwoScoreBoard.isMedicalModal && (
                <div className={`sec1 medical swapArrow`}>
                  <h2>
                    {formatTime(teamTwoScoreBoard.medicalValue as number)}
                  </h2>
                  <p>Medical</p>
                </div>
              )}
              {teamTwoScoreBoard.isStallingModal && (
                <div className={`sec1 stalling swapArrow`}>
                  <h2>
                    {formatTime(teamTwoScoreBoard.stallingValue as number)}
                  </h2>
                  <p>Stalling</p>
                </div>
              )}
              {teamTwoScoreBoard.isTimeoutModal && (
                <div className={`sec1 timeout`}>
                  <h2>
                    {formatTime(teamTwoScoreBoard.timeoutValue as number)}
                  </h2>
                  <p>TIMEOUT</p>
                </div>
              )}

              {teamTwoScoreBoard.isVarModal && (
                <div className={`sec1 varAssistant`}>
                  <h2>VAR</h2>
                  <p>MODE ON</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="playersScoreSection" id="tradScoreboardPlayers">
          <div className="player playerContainer playerLeft   ">
            <div className="scoresContainer">
              <div
                className="mainScore"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  className={`count ${
                    teamOneScoreBoard.score <= 9
                      ? "digits-1"
                      : teamOneScoreBoard.score <= 99
                      ? "digits-1"
                      : "digits-1"
                  }`}
                >
                  {" "}
                  {teamOneScoreBoard.score}{" "}
                </div>
              </div>
              <div className="extraScores">
                {Array.from({ length: 4 }, (_, i) => (
                  <div className="penalityDot">
                    <img src={AJPPenalityDot} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="player playerContainer playerRight">
            <div className="scoresContainer">
              <div
                className="mainScore"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  className={`count ${
                    teamTwoScoreBoard.score <= 9
                      ? "digits-1"
                      : teamTwoScoreBoard.score <= 99
                      ? "digits-1"
                      : "digits-1"
                  }`}
                >
                  {" "}
                  {teamTwoScoreBoard.score}{" "}
                </div>
              </div>
              <div className="extraScores">
                {Array.from({ length: 4 }, (_, i) => (
                  <div className="penalityDot">
                    <img src={AJPPenalityDot} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="tradScoreboardBottom">
          <div style={{ flexGrow: "1", width: "0px", zIndex: "2" }}>
            <div className="matchInfoContainer">
              <div className="matchNumber">
                <span>1-1</span>
              </div>
              <div className="matchInfo">
                <div className="matchInfoText">
                  <div className="bracketDivision">
                    <div className="scMarquee">
                      <div className="marqueeContainer animate">
                        <span className="marqueeText">
                          Boys Gi / Mighty Mite I (4 yrs) / -17,90 kg / Grey
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bracketRound">
                    <div className="scMarquee primary">
                      <div className="marqueeContainer">
                        <span className="marqueeText">Semifinals</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="clock">
            <div className="time running">
              <div
                className="count "
                style={{
                  color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                }}
              >
                <p
                  style={{
                    color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                  }}
                >
                  {formatTime(matchTimer.matchTimerValue)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AjpADCCScoreBoardStyle>
  );
};
export default AjpADCCScoreBoard;

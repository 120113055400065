import styled from "styled-components";
import {
  fontFamilyRegularUber,
  lightDark2,
  pureDark2,
} from "../../../components/GlobalStyle";

const CardsStyle = styled.div`
  margin: 0 0 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0px;
    &-buttons {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  h3 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    font-family: ${fontFamilyRegularUber};
    color: ${pureDark2};
    margin-bottom: 10px;
    padding-top: 6px;
  }

  .cardsMainContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: flex-center;
    gap: 20px;
  }
  .custom_card_list {
    gap: 20px;
    width: 88%;
    margin: 60px auto 0;
  }
  .custom_card {
    width: 333px;
    background-color: #fff;
    height: 360px;
    border: 0.6px solid #eaeaea;
    border-radius: 5px;

    .ant-list-item {
      padding: 16px 12px;
      display: flex;
      align-items: center;
      &-meta {
        align-items: center;
      }
      &-meta-avatar {
        img {
          border-radius: 50%;
        }
      }
      &-meta-title {
        font-family: ${fontFamilyRegularUber};
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: ${pureDark2};
      }
      &-meta-description {
        font-family: ${fontFamilyRegularUber};
        font-size: 12px;
        font-weight: 400;
        color: ${lightDark2};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &_placeholder_img {
      width: 100%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }

    &_para {
      font-size: 12px;
      font-family: ${fontFamilyRegularUber};
      color: ${lightDark2};
      font-weight: 400;
      padding: 22px 20px;
      line-height: 14px;
      white-space: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &_footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-end;
      padding: 0 25px;

      &_rating {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        .ant-rate {
          font-size: 16px;
          line-height: normal;
          &-star {
            margin-right: 2px;
            svg {
              width: 12.64px;
              height: 12.64px;
            }
          }
        }
        .ant-rate-text span:first-child {
          color: #1c394b;
          font-family: ${fontFamilyRegularUber};
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }
        .ant-rate-text span:nth-child(2) {
          color: #1f3340;
          font-family: ${fontFamilyRegularUber};
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }
      }

      &_link {
        font-family: ${fontFamilyRegularUber};
        color: #b3b3b3;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 13.977px;
      }
    }
  }
`;
export default CardsStyle;

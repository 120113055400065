import React from "react";
import { BuyTicketsSelectUser3Style } from "./style";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";

const BuyTicketsSelectUser3 = () => {
  return (
    <div>
      <BuyTicketsSelectUser3Style>
        <div className="banner">
          <img
            src={AcademyProfileBannerImage}
            alt="AcademyProfileBannerImage"
          />
        </div>

        <div className="wrapper">
          <div className="containerMain">
            <div className="title">
              <h4>Terms of Service</h4>
            </div>

            <div className="termsOfServiceContainer">
              <div className="informationSection">
                <h6 className="innerTitles">Information From Toukon</h6>
                <p className="paragraphs">
                  By registering for this event on the Toukon platform, you will
                  become the direct customer of the event organizer and
                  merchant. Please be aware that events on the Toukon platform
                  are always hosted by event organizers directly, and Toukon
                  does not function as a middleman or provide a guarantee of the
                  organizer's quality or professionalism. It is your
                  responsibility as a customer to vet the organizer/merchant
                  before proceeding with your purchase. In the case of fraud,
                  please contact your bank to initiate the process of reclaiming
                  your payment.
                </p>
              </div>
              <div className="relentlessSection">
                <h6 className="innerTitles">
                  Relentless thailand 9 terms of service waiver of participation
                  quick overview
                </h6>

                <ul>
                  <li className="paragraphs">
                    You realise martial arts & contact sports include a risk of
                    bodily harm or worse
                  </li>
                  <li className="paragraphs">
                    You will not look to hold the organiser accountable for any
                    costs or claims related to injury or accidents that occurs
                    during the event you register for
                  </li>
                  <li className="paragraphs">
                    You accept that the organiser may use your personal data to
                    contact you about business offers and future events.
                  </li>
                </ul>
              </div>

              <div className="paragraph_3">
                <h6 className="sub_title">
                  By accepting this waiver you agree to the listed items and
                  that you read and understand them.
                </h6>
                <div className="riskOfBadlyHarm">
                  <span className="sub_title">
                    Risk of bodily harm
                  </span>
                  <p className="innerParagraphs">
                    I hereby take full responsibility and liability for all
                    damages, injuries, and losses that I may sustain or incur
                    while participating in or watching the Tournament/Event. I
                    am aware that serious injury is a common occurrence in
                    combat sport events like the one I am entering, and I
                    acknowledge the possibility of death. I understand that if I
                    am injured, it may result in disfigurement, disability,
                    and/or an inability to work again. By participating in this
                    competition, I agree to fully accept and assume liability
                    for any and all injuries I may incur, explicitly giving up
                    and waiving any claims that I or anyone acting on my behalf
                    might have against the organizer, its board members,
                    promoters, operators, sponsors, officials, participants,
                    non-participants, or volunteers, for any injury, regardless
                    of its nature, effect, or impact on me, as a result of my
                    participation and/or presence at the Tournament/Event. I
                    grant authorization and agree to hold harmless any medical
                    personnel present at the tournament/event to take any
                    necessary action should I become injured. I affirm that I am
                    in good health, not presently under the influence of any
                    drug or medication, and that no one affiliated with this
                    tournament has encouraged me to enter or made any
                    representations regarding my fitness or ability to
                    participate. I confirm that I have read and understood every
                    provision of this release, and I am legally competent to
                    freely enter into this waiver, release, and assumption of
                    risk agreement.
                  </p>
                </div>

                <div className="privacyPersolnalData">
                <span className="sub_title">Privacy and personal data</span>
                <p className="innerParagraphs">
                  By utilizing my Toukon account to enroll in this event, I
                  acknowledge that I am granting the organizer the right to
                  communicate with me via newsletters, event reminders, and
                  other general promotional materials or information.
                </p>
                </div>
                <div className="privacyPara2">
                <p className="innerParagraphs">
                  I also recognize that it is my responsibility to verify and
                  must verify my registration details for the event on the
                  website's registration list, specifically for weight, belt,
                  academy, age division, and name information, by the specified
                  registration check day.
                </p>
                </div>
                <div className="privacyPara3">
                <p className="innerParagraphs">
                  Additionally, I am aware that tickets may be available for
                  sale to spectators interested in observing the event, and that
                  news media and others may cover the tournament. Furthermore,
                  the event might be videotaped or otherwise broadcasted to
                  audiences globally. Hereby, I expressly and irrevocably
                  consent to the display, publicity, commentary, and/or
                  reporting of my likeness. I do not anticipate and, in fact,
                  waive any compensation I might otherwise be entitled to as a
                  result thereof.
                </p>
                </div>
                <div className="dipsutePara">
                <span className="sub_title">Dispute</span>
                <p className="innerParagraphs">
                  By registering for this event on the Toukon platform, you
                  acknowledge that the organizer reserves the right, at its sole
                  discretion, with or without cause, to approve or deny the
                  registration/application of a potential athlete/competitor to
                  participate in the event. The terms athlete and/or competitor
                  may be used interchangeably. If any dispute arises concerning
                  your participation in this event, you expressly waive the
                  right to file a lawsuit in city, state, or federal court and
                  to a trial by jury by entering your registration application.
                  The term "dispute" includes any claims for injury (which you
                  have already waived) that may have occurred or are claimed not
                  to have occurred at the event. In the case of minors under 18
                  years old, if the competitor is a minor, I hereby certify that
                  I am the parent and/or legal guardian of the above-named minor
                  who has been given my express permission to compete in the
                  event for which we are registering. I confirm that I have read
                  and understood every provision of the release of liability,
                  and I am legally competent to freely enter into this release
                  of liability, assuming all risk on behalf of the minor
                  (competitor) named herein and any and all successors, assigns,
                  or any otherrepresentative acting upon the minor's behalf.
                </p>
                </div>
              </div>
            </div>
            <div className="acceptDeclineButtonsMain">
              <button className="declineButton">Decline</button>
              <button className="acceptButton">Accept</button>
            </div>
          </div>
        </div>
      </BuyTicketsSelectUser3Style>
    </div>
  );
};

export default BuyTicketsSelectUser3;

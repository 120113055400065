import React, { useState } from "react";
import { CreateEventStyle } from "./style";
import { GlobalContainer } from "../../Home/style";
import { Layout } from "antd";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import OrganizerCreateAnEventCoupon from "../OrganizerCreateAnEventCoupon/OrganizerCreateAnEventCoupon";

const { Content } = Layout;

const OrganizerCreateAnEventCoupon1 = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  return (
    <CreateEventStyle>
      <GlobalContainer>
        <Layout>
          <div className="banner">
            <img
              src={AcademyProfileBannerImage}
              alt="AcademyProfileBannerImage"
            />
          </div>
          <Layout>
            <Content className="creatEvent-left">
              <CreatEventLeftSide />
            </Content>
            <Content className="creatEvent-right">
              <OrganizerCreateAnEventCoupon />
            </Content>
          </Layout>
        </Layout>
      </GlobalContainer>
    </CreateEventStyle>
  );
};

export default OrganizerCreateAnEventCoupon1;

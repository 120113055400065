import React from 'react'
import {CoachProfileEventRegistrationStyle} from "./style";
export const CoachProfileEventRegistration = () => {
  return (
    <div>
        <CoachProfileEventRegistrationStyle>
          <div className='bodyWrapper_RightSide'>
            <div className="title">
              <h4>Upcoming Event Registrations</h4>
              <span>Sell All</span>
            </div>

            <table>
              <tbody>
                
                <tr>
                  <td>
                    <span>Event Title</span>
                    <p>BJJ247 Brighton Autumn Open</p>
                  </td>
                  <td>
                    <span>Event Date </span>
                    <p>Saturday, 18 Nov 2023 .  01:30 pm</p>
                  </td>
                  <td>
                    <span>Registrations</span>
                    <p>12</p>
                  </td>
                  <td>
                    <span>Unpaid-Registrations</span>
                    <p>1</p>
                  </td>
                  <td>
                    <button className='addNewButton'>Add New</button>
                    <button className='membersButton'>Members</button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span>Event Title</span>
                    <p>BJJ247 Brighton Autumn Open</p>
                  </td>
                  <td>
                    <span>Event Date </span>
                    <p>Saturday, 18 Nov 2023 .  01:30 pm</p>
                  </td>
                  <td>
                    <span>Registrations</span>
                    <p>12</p>
                  </td>
                  <td>
                    <span>Unpaid-Registrations</span>
                    <p>1</p>
                  </td>
                  <td>
                    <button className='addNewButton'>Add New</button>
                    <button className='membersButton'>Members</button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span>Event Title</span>
                    <p>BJJ247 Brighton Autumn Open</p>
                  </td>
                  <td>
                    <span>Event Date </span>
                    <p>Saturday, 18 Nov 2023 .  01:30 pm</p>
                  </td>
                  <td>
                    <span>Registrations</span>
                    <p>12</p>
                  </td>
                  <td>
                    <span>Unpaid-Registrations</span>
                    <p>1</p>
                  </td>
                  <td>
                    <button className='addNewButton'>Add New</button>
                    <button className='membersButton'>Members</button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span>Event Title</span>
                    <p>BJJ247 Brighton Autumn Open</p>
                  </td>
                  <td>
                    <span>Event Date </span>
                    <p>Saturday, 18 Nov 2023 .  01:30 pm</p>
                  </td>
                  <td>
                    <span>Registrations</span>
                    <p>12</p>
                  </td>
                  <td>
                    <span>Unpaid-Registrations</span>
                    <p>1</p>
                  </td>
                  <td>
                    <button className='addNewButton'>Add New</button>
                    <button className='membersButton'>Members</button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span>Event Title</span>
                    <p>BJJ247 Brighton Autumn Open</p>
                  </td>
                  <td>
                    <span>Event Date </span>
                    <p>Saturday, 18 Nov 2023 .  01:30 pm</p>
                  </td>
                  <td>
                    <span>Registrations</span>
                    <p>12</p>
                  </td>
                  <td>
                    <span>Unpaid-Registrations</span>
                    <p>1</p>
                  </td>
                  <td>
                    <button className='addNewButton'>Add New</button>
                    <button className='membersButton'>Members</button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span>Event Title</span>
                    <p>BJJ247 Brighton Autumn Open</p>
                  </td>
                  <td>
                    <span>Event Date </span>
                    <p>Saturday, 18 Nov 2023 .  01:30 pm</p>
                  </td>
                  <td>
                    <span>Registrations</span>
                    <p>12</p>
                  </td>
                  <td>
                    <span>Unpaid-Registrations</span>
                    <p>1</p>
                  </td>
                  <td>
                    <button className='addNewButton'>Add New</button>
                    <button className='membersButton'>Members</button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span>Event Title</span>
                    <p>BJJ247 Brighton Autumn Open</p>
                  </td>
                  <td>
                    <span>Event Date </span>
                    <p>Saturday, 18 Nov 2023 .  01:30 pm</p>
                  </td>
                  <td>
                    <span>Registrations</span>
                    <p>12</p>
                  </td>
                  <td>
                    <span>Unpaid-Registrations</span>
                    <p>1</p>
                  </td>
                  <td>
                    <button className='addNewButton'>Add New</button>
                    <button className='membersButton'>Members</button>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

        </CoachProfileEventRegistrationStyle>
    </div>
  )
}

export default CoachProfileEventRegistration;
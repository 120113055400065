import React from "react";
import { OrganizerSettingPlacementPointsStyle } from "./style";
import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { Content } from "antd/lib/layout/layout";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Formik } from "formik";
import { Row, Col } from "react-bootstrap";
import FormControl from "../../../components/FormControl";
import DeleteBtnIcon from "../../../assets/icons/DeleteBtnIcon.svg";

const OrganizerSettingPlacementPoints = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };
  const handleCreateSubmit = (): void => {};
  return (
    <div>
      <OrganizerSettingPlacementPointsStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="OrganizerSettingPlacementPoints-left">
                <CreatEventLeftSide />
              </Content>
              <Content className="OrganizerSettingPlacementPoints-right">
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={handleCreateSubmit}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="title">
                        <h3>Placement Points</h3>
                        </div>
                        <div className="bg-white formTable"></div>
                      </Form>
                    );
                  }}
                </Formik>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerSettingPlacementPointsStyle>
    </div>
  );
};

export default OrganizerSettingPlacementPoints;

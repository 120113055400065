import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../../components/GlobalStyle"; 

export const AffiliationsProfilePastEventStyle = styled.div`
.wrapper {
    width: 100%;
    display: flex;
}
.bodyLeftSideMain {
    width: 25%;
    margin-right: 35px;
}
.introductionParagraph {
    max-width: 359px;
    width: 100%;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding: 14px 12px 10px 17px;
    margin-top: 18px;
}
.introductionParagraph h6 {
    color: #000;
    text-align: justify;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.introductionParagraph p {
    color: #4F4F4F;
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 5px;
    margin-bottom: 0;
}
.mapFrame {
    max-width: 357px;
    width: 100%;
    border-radius: 0px 0px 9px 9px;
    border: 0px solid #E0E0E0;
    margin-top: 21px;
}
.bodyRightSideMain {
    width: 75%;
}
.passEventsContainer {
    width: 100%;
    margin-top: 29px;
}
.passEventsHeading h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.passEvents_CardsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 18.93px 18.74px;
    margin-top: 14.5px;
    margin-left: 1px;
}
.passEvents_CardsContainer > div {
    max-width: 32.18%;
    width: 100%;
}
.paginationContainer {
    margin: 10.43px 0 23px 1px;
}
`;

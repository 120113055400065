import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMediumUber, fontFamilyRegularUber } from "../../../components/GlobalStyle";

export const OrganizerCreateAnEventEditBracketsStyle = styled.div`
.bodyWrapper {
    width: 100&;
}
table {
    width: 100%;
    border: 1px solid #E9E9E9;
    background-color: #F6F6F6;
}
table tbody tr td {
    padding: 10px 0 10px 12px;
}
table tbody tr td:first-child {
    width: 11%;
}
table tbody tr td:nth-child(2) {
    width: 27%;
}
table tbody tr td:nth-child(3) {
    width: 6%;
}
table tbody tr td:nth-child(4) {
    width: 18%;
}
table tbody tr td:nth-child(5){
    width: 7.5%;
}
table tbody tr td:nth-child(6) {
    width: 8.5%;
}
table tbody tr td:nth-child(7) {
    width: 16%;
}
table tbody tr td:last-child {
    width: 6%;
    text-align: right;
    padding-right: 10px;
}
.belt img {
    width: 129.473px;
    height: 16px;
}
.maleGi p {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-bottom: 0;
}
title span {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
}
.title p {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.28px;
    margin-bottom: 0;
}
.editButton button {
    padding: 12px 52px 11px 53px;
    border-radius: 6px;
    background: linear-gradient(0deg, #DEF2E2 0%, #DEF2E2 100%), rgba(0, 0, 0, 0.47);
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
.unplacedMainBracket_container {
    max-width: 1425px;
    width: 100%;
    margin: 22px auto 0px auto;
    display: flex;
    gap: 25px;
    margin-bottom: 39px;
}
.block1_mainContainer {
    max-width: 700px;
    width: 100%;
}
.unplacedContainer {
    width: 100%;
    height: 618px;
    border-radius: 6px 6px 0px 0px;
    border: 1px solid #E9E9E9;
    background-color: #FFF;
}
.block2_mainContainer {
    max-width: 700px;
    width: 100%;
}
.mainBracketsContainer {
    width: 100%;
    height: 618px;
    border-radius: 6px 6px 0px 0px;
    border: 1px solid #E9E9E9;
    background-color: #FFF;
}
.unplacedTitle {
    padding: 20px 10px 20px 17px;
    border-radius: 6px 6px 0px 0px;
    border: 1px solid #E9E9E9;
    background-color: #F6F6F6;
}
.unplacedTitle h6,
.mainBracketsTitle h6 {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.34px;
    margin-bottom: 0;
}
.mainBracketsTitle {
    padding: 9.9px 10px 9.9px 17px;
    border-radius: 6px 6px 0px 0px;
    border: 1px solid #E9E9E9;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mainBracketsTitle button {
    padding: 11px 12px 10px 13px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
.unplacedCountryName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8.4px 14px 10px 9px;
    background-color: #fff;
    border-bottom: 1px solid #E9E9E9;
}
.countryFlagName {
    display: flex;
    align-items: center;
}
.countryFlagName img {
    width: 20px;
    height: 20px;
}
.countryFlagName p {
    color: #0C0C0C;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    margin-left: 7px;
    margin-bottom: 0;
}
.countryFlagName span {
    color: #BEBEBE;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    margin-left: 21px;
}
.unplaedButton button {
    padding: 3px 8px 4px 8px;
    border-radius: 4px;
    background: linear-gradient(0deg, #DEF2E2 0%, #DEF2E2 100%), rgba(0, 0, 0, 0.47);
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.4px;
}
.deleteAutomaticRedraw_buttons {
    width: 100;
    display: flex;
    align:items: center;
    justify-content: end;
    gap: 14px;
    margin-top: 27px;
}
.deleteBracketButton {
    padding: 11px 15px 10px 15px;
    border-radius: 4px;
    background: linear-gradient(0deg, #FF7070 0%, #FF7070 100%), rgba(0, 0, 0, 0.47);
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
.automaticRedrawButton {
    padding: 11px 13px 10px 14px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
.automaticRedrawButton span {
    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    padding-left: 7px;
}
.changeTypeButton {
    padding: 11px 21px 10px 21px;
    border-radius: 4px;
    background: linear-gradient(0deg, #DEF2E2 0%, #DEF2E2 100%), rgba(0, 0, 0, 0.47);   
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
`;

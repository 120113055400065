import React from 'react'
import { FooterStyle } from "./style"
import logo from "../../assets/icons/ic_logo_ULTM8.svg";

const footer = () => {
    return (
        <FooterStyle >
            <div className='footer'>
                <div className='sb_footer section_padding'>
                    <div className='sb_footer-links'>
                        <div>
                            <div className='logo'>
                                <img src={logo} alt="" />
                            </div>
                            <div className='sb_footer-below-links'>
                                <ul>
                                    <li>
                                    <a href="/Facebook">
                                    Facebook
                                </a>
                                    </li>
                                    <li>
                                    <a href="/Instagram">
                                    Instagram
                                      </a>
                                        </li>
                                        <li>
                                        <a href="/Linkedin">
                                    Linkedin
                                      </a>
                                        </li>
                                        <li>
                                        <a href="/Pintrest">
                                        Pintrest
                                      </a>
                                        </li>
                                       
                                </ul>
                            </div>



                            <div className='sb_newsletter'>
                                <h2 className='newsletter-h2'>Subscribe for News-Letter</h2>
                                <form action="">
                                    <input type="email" placeholder='E-mail Address' required className='newsletter-input' />
                                     <input type="submit" value="submit"  />
                                 
                                </form>
                            </div>
                        </div>






                        <div className='sb_footer-links-div'>
                            <h3>Quick Links</h3>
                            <ul>
                                <li>
                                    <a href="/So gehts">
                                        <p>So gehts</p>
                                    </a>
                                </li>
                                <li>
                                    <a href="Erfahrungs">
                                        <p>Erfahrung</p>
                                    </a>
                                </li>
                                <li>
                                    <a href="Aligner">
                                        <p>Aligner</p>
                                    </a>
                                </li>
                                <li>
                                    <a href="/Preise">
                                        <p>Preises</p>
                                    </a>
                                </li>
                                <li>
                                    <a href="Standorte">
                                        <p>Standorte</p>
                                    </a>
                                </li>
                            </ul>

                        </div>

                        <div className='sb_footer-links-div'>
                            <h3>Newz</h3>

                            <ul>
                                <li>
                                    <a href="/Blog">
                                        <p>Blog</p>
                                    </a>
                                </li>
                                <li>  <a href="FAQ">
                                    <p>FAQ</p>
                                </a></li>
                                <li>  <a href="Lift Media ">
                                    <p>Lift Media </p>
                                </a></li>
                                <li>  <a href="/Offene Stellen">
                                    <p>Offene Stellen</p>
                                </a></li>
                                <li>  <a href="Presse kit">
                                    <p>Presse kit</p>
                                </a></li>

                            </ul>
                        </div>


                        <div className='sb_footer-links-div'>
                            <h3>Behandlung</h3>
                            <ul>
                                <li>  <a href="/Gratis Termin">
                                    <p>Gratis Termin</p>
                                </a></li>
                                <li>  <a href="Freunde einladen">
                                    <p>Freunde einladen</p>
                                </a></li>
                                <li>  <a href="Patienteninformationen">
                                    <p>Patienteninformationen</p>
                                </a></li>
                            </ul>
                        </div>

                    </div>

                    <hr></hr>

                    <div className='sub_footer-below'>
                        <div className='sb_footer-copyright'>
                            <p>
                                @{new Date().getFullYear()} utml8. All right Reserved
                            </p>

                        </div>

                        <div className='sb_footer-below-links'>

                            <a href="/terms"><div><p>Terms & Conditions</p></div></a>
                            <a href="/privacy"><div><p>Privacy Policy</p></div></a>
                            <a href="/cookie"><div><p>Cookie Policy</p></div></a>
                            <a href="/refund"><div><p>Refund Policy</p></div></a>
                        </div>


                    </div>

                </div>
            </div>
        </FooterStyle>

    )
}

export default footer;

import styled from "styled-components";
import { AmericanYellow, DarkGreen, SpringFrost, fontFamilyBold, fontFamilyFuturaCondenseBold, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, pureDark, whiteColor } from "../../../components/GlobalStyle";
export const TraditionalAdccStyle = styled.div`
#tradAdccScoreboard {
	font-family: ${fontFamilyBold};
	text-transform: uppercase;
	font-weight: 500;
	line-height: 1.1;
	background: #1e1f23;
}

#tradAdccScoreboard * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#tradAdccScoreboard {
	position: relative;
	overflow: hidden;
	min-width: 132vh;
	touch-action: pan-x pan-y;
}


.messagesShows .submission .winner-text{
    color: ${pureDark};
      font-family: ${fontFamilyBold};
      font-size: 8.1vh;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.31px;
      text-transform: uppercase;
      padding: 0.6vh 1.5vh;
    background: #fdba13!important;
  }
  .messagesBody{
    display: flex;
      flex-direction: column;
      align-items: center;
      height: 70vh;
      justify-content: center;
  }
  .messagesBody .playerAttr .attrRows{
    text-align: center;
  }
  .messagesBody  .playerName{
    font-size: 17vh;
    width: 100%;
    max-width: 100%;
  }
  .messagesBody .playerAttr .subheader {
    font-size: 9vh;
  }
  .messagesBody  .playerName img{
    vertical-align: middle;
  }
  .messagesBody .playerDetails{
    margin-top: 1vh;
  }
  .messagesBody .trad-header .name{
    color: ${whiteColor};
    font-family: ${fontFamilyMedium};
    font-size: 15vh;
  }




#tradAdccScoreboard #tradAdccPlayers {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 70vh;
}
#tradAdccScoreboard .tradScoreboardBottom {
	position: relative;
	height: 30vh;
	display: flex;
	background: #1e1f23;
	justify-content: space-between;
}

.tradScoreboardBottom .control {
	position: absolute;
    top: 74%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    z-index: 6;
    transform-origin: 0px 50%;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
	z-index: 99999;
}


.tradScoreboardBottom .control .actions {
	display: flex;
	border-radius: .8vh;
	transition: box-shadow 1s;
	font-size: 3vh;
}

.tradScoreboardBottom .control .actions td {
	position: relative;
	width: auto;
	background: rgba(0,0,0,0.4) ;
	font-size: 1em;
	padding-right: 1em;
	padding-left: 1em;
	height: 7vh;
	display: table-cell;
	white-space: nowrap;
	cursor: pointer!important;
	text-align: center;
	border-radius: 0;
	line-height: .8;
}

.tradScoreboardBottom .control .actions td a {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.tradScoreboardBottom .control .actions td svg, .tradScoreboardBottom .control .actions td img {
	height: 3vh;
	width: auto;
}

.tradScoreboardBottom .control .actions table {
	background: rgba(0,0,0,0.4) ;
	width: 100%;
	box-shadow: 0 0 5vh 0 #000;
	margin-right: 2vh;
	border-radius: .8vh;
	overflow: hidden;
}

.tradScoreboardBottom .control .actions table:hover {
	box-shadow: 0 0 6vh 1vh #000;
	transition: box-shadow .5s;
}

#tradAdccScoreboard #tradAdccPlayers>.player {
	height: 50%;
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	padding-left: 2em;
	transition: a
	background: #25262b;
}

#tradAdccScoreboard #tradAdccPlayers>.player>* {
	z-index: 1;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes {
	flex-wrap: wrap;
}
#tradAdccScoreboard #tradAdccPlayers>.player .result {
	// display: none;
	font-size: 4.3vh;
    margin-left: 3.7vh;
    flex-basis: 100%;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: bounceInDown;
    position: absolute;
    left: 0px;
    top: 0px;
}
#tradScoreboard #tradScoreboardPlayers > .player .result > span {
	background: #efd51c;
	color: #000;
	padding: 0 .4em;
}
#tradAdccScoreboard #tradAdccPlayers>.player .result {
	// font-size: .3em;
	// margin-left: .2em;
	// flex-basis: 100%;
	// -webkit-animation-duration: 1s;
	// animation-duration: 1s;
	// -webkit-animation-fill-mode: both;
	// animation-fill-mode: both;
	// -webkit-animation-name: bounceInDown;
	// animation-name: bounceInDown;
}

#tradAdccScoreboard #tradAdccPlayers>.player .result>span {
	background: #efd51c;
	color: #000;
	padding: 0 .4em;
}

#tradAdccScoreboard #tradAdccPlayers>.player .result.both>span {
	background: #fff!important;
}

#tradAdccScoreboard #tradAdccPlayers>.player:first-child {
	border-bottom: 4px solid #000;
}

#tradAdccScoreboard #tradAdccPlayers>.player:last-child {
	border-top: 4px solid #000;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerControls {
	min-width: 0;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 99;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes {
	font-size: 14vh;
	margin-top: -.07em;
	display: flex;
	align-items: center;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .attributeRows {
	display: flex;
	flex-direction: column;
	flex-basis: 0;
	flex-grow: 1;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes>.club-icon {
	height: 1.6em;
	min-width: 1.6em;
	width: 1.6em;
	border: .03em solid #757575c7;
	margin-right: .3em;
	position: relative;
	top: .04em;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .subheader {
	display: flex;
	font-size: .7em;
	line-height: 1;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .subheader span {
	// opacity: .5;
    // color: #fff;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .subheader .clubAndTeamContainer {
	height: 1.1em;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .subheader .clubAndTeamContainer img {
	height: .8em;
	width: .8em;
	min-width: .8em;
	border: .04em solid #fff;
	position: relative;
	// top: -.13em;
    margin-right: 0.25em;
	border-radius: 50%;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .subheader .clubAndTeamContainer.animate>div {
	transform-origin: 50% 0;
	position: relative;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .subheader .clubAndTeamContainer.animate>div:nth-child(1) {
	-webkit-animation: firstchild 20s infinite;
	animation: firstchild 20s infinite;
}



@-webkit-keyframes firstchild {
    0%,10% {
		opacity: 1;
	}
    11%,20% {
		opacity: 1;
	}
    21%,30% {
		opacity: 1;
	}
    31%,40% {
		opacity: 1;
	}
    51%,60% {
		opacity: 1;
	}
    61%,70% {
		opacity: 1;
	}
    71%,80% {
		opacity: 1;
	}
    81%,90% {
		opacity: 1;
	}
    91%,100% {
		opacity: 1;
	}
}

@keyframes firstchild {
    0%,10% {
		opacity: 1;
	}
    11%,20% {
		opacity: 1;
	}
    21%,30% {
		opacity: 1;
	}
    31%,40% {
		opacity: 1;
	}
    51%,60% {
		opacity: 1;
	}
    61%,70% {
		opacity: 1;
	}
    71%,80% {
		opacity: 1;
	}
    81%,90% {
		opacity: 1;
	}
    91%,100% {
		opacity: 1;
	}
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .subheader .clubAndTeamContainer.animate>div:nth-child(2) {
	transform-origin: 0% 100%;
	-webkit-animation: secondchild 20s infinite;
	animation: secondchild 20s infinite;
	transform: translateY(calc(-100% - 0.04em));
}

@-webkit-keyframes secondchild {
	0%,10% {
		opacity: 1;
	}
    11%,20% {
		opacity: 1;
	}
    21%,30% {
		opacity: 1;
	}
    31%,40% {
		opacity: 1;
	}
    51%,60% {
		opacity: 1;
	}
    61%,70% {
		opacity: 1;
	}
    71%,80% {
		opacity: 1;
	}
    81%,90% {
		opacity: 1;
	}
    91%,100% {
		opacity: 1;
	}
}

@keyframes secondchild {
    0%,10% {
		opacity: 1;
	}
    11%,20% {
		opacity: 1;
	}
    21%,30% {
		opacity: 1;
	}
    31%,40% {
		opacity: 1;
	}
    51%,60% {
		opacity: 1;
	}
    61%,70% {
		opacity: 1;
	}
    71%,80% {
		opacity: 1;
	}
    81%,90% {
		opacity: 1;
	}
    91%,100% {
		opacity: 1;
	}
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .subheader .countryName {
	min-width: 1.5em;
	margin-right: .35em;
	text-align: center;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .flag {
	height: .78em;
	top: -.12em;
	position: relative;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .club-logo {
	display: block;
	float: left;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .name.wo {
	text-decoration: line-through;
	-webkit-text-decoration-color: rgba(255,255,255,.7);
	text-decoration-color: rgba(255,255,255,.7);
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes .name.wo::before {
	content: "WO ";
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes.playerAttributes-centered {
	text-align: center;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes.playerAttributes-centered .user-image {
	margin-bottom: 4vh;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes.playerAttributes-centered .user-image img {
	width: 24vh;
	border: .6vh solid rgba(255,255,255,.3);
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes.playerAttributes-centered .playerName {
	line-height: 1;
	margin-bottom: 1vh;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes.playerAttributes-centered .subheader {
	display: inline-block;
	opacity: 1;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes.playerAttributes-centered .subheader .clubAndTeam {
	opacity: .3;
	margin-bottom: 2vh;
}

#tradAdccScoreboard #tradAdccPlayers>.player .playerAttributes.playerAttributes-centered .subheader .country-flag-and-name {
	display: inline-block;
}

#tradAdccScoreboard #tradAdccPlayers .scoresContainer {
	order: 2;
	display: flex;
	height: 100%;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore {
	font-family: ${fontFamilyFuturaCondenseBold};
	font-weight: 700;
	font-size: 33vh;
	line-height: 1;
	width: .9em;
	text-align: center;
	order: 2;
	display: flex;
	align-items: center;
	margin-left: .1em;
	position: relative;
	// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50);
}

#tradAdccScoreboard #tradAdccPlayers .mainScore div {
	width: 100%;
	text-align: center;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore.flair {
	width: .1em;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore .on-top {
	cursor: pointer;
	position: absolute;
	top: 1vh;
	left: 1vh;
	width: auto;
	font-size: 3.1vh;
	color: #ffffff70;
	opacity: 1;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: pulse;
	animation-name: pulse;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore .on-top svg {
	width: 2vh;
	height: auto;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore .on-top.disabled {
	opacity: .3!important;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore .on-top:hover {
	opacity: 8!important;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore .count {
	width: 100%;
	position: relative;
	top: -.02em;
	font-weight: ${fontFamilyFuturaCondenseBold};
}

#tradAdccScoreboard #tradAdccPlayers .mainScore .count.digits-2 {
	font-weight: ${fontFamilyMediumUber};
	font-size: .7em;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore .count.digits-3 {
	font-weight: ${fontFamilyMediumUber};
	font-size: .46em;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore.with-ippons {
	display: flex;
	flex-direction: column;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore.with-ippons .ippons {
	display: flex;
	width: 100%;
	align-items: center;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore.with-ippons .ippons span {
	font-size: 8vh;
	flex-grow: 1;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore.with-ippons .count {
	flex-grow: 1;
	display: flex;
	align-items: center;
	font-size: 26vh;
	position: relative;
	margin-top: -.08em;
	margin-bottom: -.02em;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore.with-ippons .count.digits-2 {
	font-size: 22vh;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore.with-ippons .count.digits-3 {
	font-size: 16vh;
}

#tradAdccScoreboard #tradAdccPlayers .mainScore.with-ippons .count div {
	width: 100%;
}

#tradAdccScoreboard #tradAdccPlayers .extraScores {
	font-size: 2vh;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap-reverse;
	padding-top: 2vh;
	padding-bottom: 2vh;
}

#tradAdccScoreboard #tradAdccPlayers .extraScores>div {
	// opacity: .6;
	margin-left: 0vh;
	padding: 0px 2vh;
}

#tradAdccScoreboard #tradAdccPlayers .extraScores .active {
	opacity: 1;
}

#tradAdccScoreboard #tradAdccPlayers .extraScores .points {
	font-size: 12vh;
	font-family: ${fontFamilyRegular};
	font-weight: 700;
	line-height: 1;
    color: #fff;
}
#tradAdccScoreboard #tradAdccPlayers .extraScores .adv{
    // color: #f5de2e;
	color: #5ec341;
	font-family: ${fontFamilyFuturaCondenseBold};
	// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#tradAdccScoreboard #tradAdccPlayers .extraScores .pen{
    color: #ec0001;
	// color: #d8363c;
	font-family: ${fontFamilyFuturaCondenseBold};
	// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#tradAdccScoreboard #tradAdccPlayers .extraScores > div  div + div{
    color: #fff;
    font-size: 2.8;
}

#tradAdccScoreboard #tradAdccPlayers .extraScores .advantage.active {
	color: #fff;
	opacity: 1;
}

#tradAdccScoreboard #tradAdccPlayers .extraScores .penalty.active {
	color: #fff;
	opacity: 1;
}


#tradAdccScoreboard .tradScoreboardBottom {
	position: relative;
	height: 30vh;
	display: flex;
	background: #1e1f23;
}

#tradAdccScoreboard .tradScoreboardBottom>* {
	z-index: 1;
}

#tradAdccScoreboard .tradScoreboardBottom .matchNumber {
	background: #0B0A08;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 2vh;
	white-space: nowrap;
}

#tradAdccScoreboard .tradScoreboardBottom .matchNumber span {
	font-size: 18vh;
}

#tradAdccScoreboard .tradScoreboardBottom .tradScoreboardBottom-controls {
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	overflow-x: hidden;
	padding-left: 4vh;
}

#tradAdccScoreboard .tradScoreboardBottom #clock {
	font-size: 28vh;
	line-height: 1;
	white-space: nowrap;
	text-align: center;
	margin-top: 1vh;
	margin-bottom: 1vh;
	margin-right: 1vh;
	cursor: pointer!important;
	max-width: 70vh;
	width: 100%;
	position: relative;
}


#tradAdccScoreboard .tradScoreboardBottom .scoreboard-logo {
	max-height: 24vh;
	max-width: 24vh;
	margin-right: 4vh;
}

#tradAdccScoreboard .tradScoreboardBottom .scoreboard-background {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
	pointer-events: none;
}

#tradAdccScoreboard .tradScoreboardBottom .scoreboard-background img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

#tradAdccScoreboard .tradScoreboardBottom #result {
	font-size: 7vh;
	text-align: center;
	color: #ffff1d;
}

#tradAdccScoreboard .tradScoreboardBottom .matchInfoContainer {
	display: flex;
	height: 100%;
}

#tradAdccScoreboard .tradScoreboardBottom .matchInfo {
	font-size: 7vh;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	flex-grow: 1;
	overflow: hidden;
	padding-left: 4vh;
}

#tradAdccScoreboard .tradScoreboardBottom .matchInfo .matchInfoText {
	display: table-cell;
	vertical-align: middle;
}

#tradAdccScoreboard .tradScoreboardBottom .matchInfo .logo svg,#tradAdccScoreboard .tradScoreboardBottom .matchInfo .logo img {
	height: 24vh;
	width: auto;
	max-width: 25.5vh;
	margin-right: 4vh;
}

#tradAdccScoreboard .tradScoreboardBottom .matchInfo .bracketInfo {
	display: block;
}

#tradAdccScoreboard .tradScoreboardBottom .matchInfo .bracketInfo>* {
	margin-right: 2vh;
}

#tradAdccScoreboard .tradScoreboardBottom .matchInfo .clock-alert {
	font-size: 28vh;
	color: #fdba13;
}

#tradAdccScoreboard .tradScoreboardBottom .matchControls {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 2vh;
	display: flex;
	align-items: center;
}

#tradAdccScoreboard .tradScoreboardBottom .matchControls .control {
	position: relative;
	top: unset;
	bottom: unset;
	left: unset!important;
}



.scMarquee .marqueeContainer {
	overflow: hidden;
	display: flex;
	margin-left: -4vh;
	padding-left: 4vh;
	transition: .2s all ease;
}

.scMarquee .marqueeContainer.animate {
	-webkit-mask-image: linear-gradient(to left,transparent,black 10vh,black calc(100% - 4vh),transparent);
	mask-image: linear-gradient(to left,transparent,black 10vh,black calc(100% - 4vh),transparent);
}

.scMarquee .marqueeContainer.animate .marqueeText {
	padding-right: 20vh;
}

.scMarquee .marqueeContainer .marqueeText {
	margin: 0;
	display: inline-block;
	white-space: nowrap;
	position: relative;
}

.scMarquee .marqueeContainer.animate .marqueeText {
	-webkit-animation: marquee 15s linear infinite;
	animation: marquee 15s linear infinite;
}

@-webkit-keyframes marquee {
	0% {
		transform: translate3D(0,0,0);
	}

	20% {
		transform: translate3D(0,0,0);
	}

	100% {
		transform: translate3D(-100%,0,0);
	}
}

@keyframes marquee {
	0% {
		transform: translate3D(0,0,0);
	}

	20% {
		transform: translate3D(0,0,0);
	}

	100% {
		transform: translate3D(-100%,0,0);
	}
}

.scMarquee.primary {
	color: #d0ac13;
}

.scMarquee.primary .muted {
	opacity: .5!important;
	font-size: .7em;
	margin-left: -.3em;
}

.scMarquee.muted {
	color: #757575;
}

.playerName {
    color: #fff;
}
.clubName, .countryName {
    color: #C2C2C2;
}
.bracketDivision .marqueeText{
    color: rgba(231, 234, 255, 0.9);
}
.bracketRound{

}
#tradAdccPlayers .playerLeft {
    background-color: #3D3E42 !important;
	height: 100%;
}
#tradAdccPlayers .playerRight{
    background-color: #202125 !important;
	height: 100%;
}
#tradAdccScoreboard .tradScoreboardBottom{
    background-color: #000 !important;
}
#tradAdccPlayers .playerLeft .mainScore{
    background: #AF0026 ;
	// background: #ba343c;
    color: #fff !important;
}
#tradAdccPlayers .playerRight .mainScore{
    // background: #1E409C ;
	background: #1e409b;
    color: #fff !important;
}
#tradAdccScoreboard #tradAdccPlayers .mainScore{
    margin-left: 0 !important;
}
#tradAdccScoreboard #tradAdccPlayers .extraScores{
    background-color: #333;
    padding: 2vh !important;
	width: 17vh
}
.playerRight .swapArrow .ScoreBoardTableInner1 .ScoreBoardTableInner11 p img{
	transform: rotate(180deg);
}

#tradAdccPlayers .playerLeft .playerName, #tradAdccPlayers .playerRight .playerName {
    display: flex;
    align-items: flex-end;
}
#tradAdccPlayers .playerLeft .playerName .name, #tradAdccPlayers .playerRight .playerName .name{
    margin-left: 0.2em
}
.clubAndTeamContainer .club-name{
    color: #c2c2c2 !important;
}

.clubAndTeamContainer .clubName, .clubAndTeamContainer .teamName{
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.countryName{
    color: #fff !important;
}
#clock .count{
    display: flex;
	justify-content: center;
    align-items: center;
    margin-right: 3vh;
    text-align: center;
	height: 30vh;
	background: ${pureDark};
	
}
.count p{
    display: inline-block;
    color: rgb(237, 219, 46);
    font-family: ${fontFamilyFuturaCondenseBold};
	vertical-align: top;
    display: inline-table;
    text-align: center;
    font-size: 29vh;
    line-height: 1;
    white-space: nowrap;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-right: 1vh;
    letter-spacing: 1px;
    background-color: rgb(0, 0, 0);
    position: relative;
    z-index: 9999;
    cursor: pointer !important;
}




.score2{
    position: relative;
    margin-right: 1vw;
	display: flex;
    justify-content: flex-end;
    align-items: center;
	border-radius: 1vh;
    .sec1{
		text-align: center;
		font-size: 18vh;
		border-radius: 1vh;
		z-index: 5;
		padding: 0;
		height: 1.7em;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 41.1vh;
			h2{
				color: ${DarkGreen};
				text-align: center;
				font-family: ${fontFamilyFuturaCondenseBold};
				font-size: 15.5vh;
				font-style: normal;
				font-weight: 400;
				text-transform: uppercase;
				line-height: 16vh;
				margin-bottom: 2vh;
			}
			p{
				color: ${DarkGreen};
				text-align: center;
				font-family: "OperaMedium";
				font-size: 7vh;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: uppercase;
				line-height: 6vh;
			}
        
    }
    .boardScorePlayer2{
        border-radius: 1vh;
		position: absolute;
		right: 0px;
		top: 0vh;
		width: 41vh;
		height: 30.65vh;
		border-radius: 1vh;
		animation: myfirst 1.5s linear infinite;
		filter: drop-shadow(0 0 0.2vh rgba(0,0,0,0.8));
        .scoreBoardTable{
            border-radius: 1vh;
            .ScoreBoardTableInner{
                .ScoreBoardTableInner1{
                    .ScoreBoardTableInner11{
                        // padding: 0.5vh 2vh;
						padding: 1.1vh 2vh;
                        p{
                            color: rgb(255, 255, 255);
                            text-align: center;
							font-family: ${fontFamilyMediumUber};
                            font-size: 2vh;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: uppercase;
                            margin-bottom: 0;
							filter: drop-shadow(0 0 0.2vh rgba(0,0,0,0.8));
                        }
						p img {
							margin-left: 0.5vh;
							margin-bottom: 1vh;	
						}
                    }
                }
                .ScoreBoardTableInner2{
					display: flex;
					justify-content: space-between;				
                    .ScoreBoardTableInner21,
					.ScoreBoardTableInner22,
					.ScoreBoardTableInner23{
                        position: relative;
                        text-align: center;
                        padding: 0.5vh 2vh;
                        img:first-child{

                        }
                        img{
                            width: 2.5vh;
                            height: 2.5vh;
							filter: drop-shadow(0 0 0.2vh rgba(0,0,0,0.8));
                        }
                        p{
                            color: rgb(255, 255, 255);
                            text-align: center;
                            font-family: "UberMove-Bold";
                            font-size: 1.2vh;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: uppercase;
                            position: absolute;
                            left: 0px;
                            right: 0px;
                            top: 11vh;
                            margin: 0px;
							filter: drop-shadow(0 0 0.2vh rgba(0,0,0,0.8));
                        }
                        button{
                            background-color: transparent;
                            border: none;
                            outline: none;
                            img{
                                width: 2.8vh;
                                width: 2.8vh;
                                object-fit: cover;
                            }
                        }
                        
                    }
                    
                }
                .ScoreBoardTableInner3{
                    .ScoreBoardTableInner31{
                        // padding: 5px 20px;
						padding: 1.3vh 2vh;
						// padding: 8px 20px 4px 20px;
						text-align: center;
						marign: auto;
                        p{
							color: rgb(255, 255, 255);
							text-align: center;
							font-family: ${fontFamilyMediumUber};
							margin: auto;
							font-size: 2vh;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
							text-transform: uppercase;
							margin-bottom: 0px;
							text-shadow: 1px 1px 4px rgba(0,0,0,1);
                        }
						img{
							text-align: center;
							margin: auto;
						}
                    }
                }
            }
        }
    }
}
.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 99%;
}
.medical{
    background-color: ${SpringFrost};
}
.stalling{
    background-color: ${AmericanYellow};
}
.timeout{
    background-color: ${AmericanYellow};
}
.varAssistant{
	// background-color: ${AmericanYellow};
	background: linear-gradient(180deg, #FFCE00 27.63%, rgba(255, 206, 0, 0) 141.45%);
	width: 41.1vh !important;
}
.playerRight .ScoreBoardTableInnerRight p img {
	transform: rotate(180deg) !important;
	-webkit-transform: rotate(180deg);
	margin-left: 0.5vh;
  margin-bottom: 1vh;						
}
#clock .count{
    display: flex;
	justify-content: center;
    align-items: center;
    margin-right: 3vh;
    text-align: center;
	height: 30vh;
	background: ${pureDark};
	
}
.count p{
    display: inline-block;
    color: rgb(237, 219, 46);
    font-family: ${fontFamilyFuturaCondenseBold};
	vertical-align: top;
    display: inline-table;
    text-align: center;
    font-size: 29vh;
    line-height: 1;
    white-space: nowrap;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-right: 1vh;
    letter-spacing: 1px;
    background-color: rgb(0, 0, 0);
    position: relative;
    z-index: 9999;
    cursor: pointer !important;
}
.messagesShows .submission .winner-text{
	color: ${pureDark};
    font-family: ${fontFamilyBold};
    font-size: 8.1vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.31px;
    text-transform: uppercase;
    padding: 0.6vh 1.5vh;
	background: #fdba13!important;
}
.messagesBody{
	display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
}
.messagesBody .playerAttr .attrRows{
	text-align: center;
}
.messagesBody  .playerName{
	font-size: 17vh;
	width: 100%;
	max-width: 100%;
}
.messagesBody .playerAttr .subheader {
	font-size: 9vh;
}
.messagesBody  .playerName img{
	vertical-align: middle;
}
.doubleShowsComp {
    position: absolute;
    right: 1.2vh;
    z-index: 9;
    top: 30.4vh;
}
.doubleShowsComp1 {
    position: absolute;
    right: 2vh;
    z-index: 9;
    top: 31vh;
}

.showScoreBoardTable{
    position: absolute;
    left: 0vh;
    top: 7vh;
    width: 100%;
}

.matchControls123 {
    position: absolute;
    top: 9vh;
	left: 2vh;
}
.boardScorePlayer2 {
	opacity: 0;
}
  .score2 .sec1:hover{
	opacity: 1;
	box-shadow: rgba(0, 0, 0, 0.6) -1px -1px 4vh 2vh inset;
	z-index: 99;
}
  .score2 .sec1:hover .boardScorePlayer2{
	opacity: 1;
}
.endGameBottom{
    position: absolute;
    top: 9vh;
    left: 4vh;
}
.wonbyLight{
	position: absolute;
	z-index: 9;
	width: 100%;
	top: 4vh;
}
.wonbyDark{
	position: absolute;
	z-index: 9;
	width: 100%;
	top: 4vh;	
}

.wonbyLight .scoreBoardTable table tr:nth-child(1) td{
    background: rgb(175, 0, 38);
    color: rgb(255, 255, 255) !important;
}
.wonbyDark .scoreBoardTable table tr:nth-child(1) td{
	background: rgb(30, 64, 155);
    color: rgb(255, 255, 255) !important;
}
.scoreBoardTable table tr:nth-child(3) td{
	font-size: 2.9vh;
}
@media screen and (max-width: 34vh){
    div#tradAdccScoreboard{
        width: 34vh;
        overflow-x: auto;
        overflow-y: hidden;
    }

}

.scoreboard-table table tr td table tr td:nth-child(5){
	display: none;
}


`;
import React from "react";
import { OrganizerSettingStatisticsRegistrationStyle } from "./style";
import RegistrationByBeltTable from "../../../screens/OrganizerSettingContent/OrganizerSettingStatisticsRegistration/RegistrationsBeltTable/RegistrationsBeltTable";
import RegistrationByAgeTable from "../../../screens/OrganizerSettingContent/OrganizerSettingStatisticsRegistration/RegistrationsAgeTable/RegistrationsAgeTable";
import RegistrationByWeightTable from "../../../screens/OrganizerSettingContent/OrganizerSettingStatisticsRegistration/RegistrationsWeightTable/RegistrationsWeightTable";
const OrganizerSettingStatisticsRegistration = () => {
  return (
    <div>
      <OrganizerSettingStatisticsRegistrationStyle>
        <div className="bodyWrapper_RightSide">
            <RegistrationByBeltTable />
            <RegistrationByAgeTable />
            <RegistrationByWeightTable />
        </div>
      </OrganizerSettingStatisticsRegistrationStyle>
    </div>
  );
};

export default OrganizerSettingStatisticsRegistration;

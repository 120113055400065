import styled from "styled-components";
import { fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../components/GlobalStyle";

export const InformationDataStyle = styled.div`
.mainConatiner {
    width: 100%;
    display: flex;
    margin-top: 20px;
}
.Information_Leftside {
    width: 72%;
}
.InformationParagraph_main {
    width: 100%;
    padding: 20px;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #E9E9E9;
    background-color: #FFF;
}
.Information_para h4 {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
}
.Information_para p {
    color: var(--Details, #4F4F4F);
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    padding-top: 10px;
    margin-bottom: 0; 
}
.Registrations_para {
    margin-top: 20px;
}
.Registrations_para h4 {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
}
.Registrations_para span {
    color: var(--Details, #4F4F4F);
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    padding-top: 10px;
}
.Registrations_para p {
    color: #4F4F4F;
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 0;
}
.Ruleset_para {
    margin-top: 20px;
}
.Ruleset_para h4 {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    text-transform: capitalize;
}
.Ruleset_para p {
    color: #4F4F4F;
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}
.Ruleset_para ul li {
    color: #4F4F4F;
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}
.spnonserBy_main {
    width: 100%;
    padding: 14px 17px 1px 17px;
    border-radius: 6px 6px 0px 0px;
    border: 1px solid #E9E9E9;
    background-color: #FFF;
    margin-top: 10px;
    padding-bottom: 17px;
    margin-bottom: 37px;
}
.spnonserBy_main h4 {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.34px;
    margin-bottom: 0;
}
.spnonserByImages {
    display: flex;
    align-items: center;
    margin-top: 17px;
    width: 100%;
}
.spnonserByImages img {
    width: 32.65%;
    height: 210px;
    object-fit: cover;
}
.image1, .image2 {
    margin-right: 9.5px;
}
.Slider {
    width: 100%;
    text-align: center;
    margin-top: 14px;
}
// RightSide

.LocationContact_RightS {
    width: 28%;
    margin-left: 22px;
}

.LocationContact_RightS .locationHeading h4{
    color: #061229;
    font-family: ${fontFamilyMedium};
    // font-size: 28px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.mapImage {
    margin-top: 10px;
}
.mapImage img {
    width: 100%;
}
.address {
    width: 100%;
    padding: 12px 16px;
    border: 0.5px solid #E9E9E9;
    background-color: #FFF;
}
.address span {
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.contactHeading {
    margin-top: 17px;
}
.LocationContact_RightS .contactHeading h4 {
    color: #061229;
    font-family: ${fontFamilyMedium};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.contactMain {
    margin-top: 12px;
}
.Website {
    dispay: flex;
    align-items: center;
    padding: 14px 0 14px 16px;
    border-radius: 8px 8px 0px 0px;
    border: 0.5px solid #E9E9E9;
    background-color: #FFF;
}
.Website img {
    width: 20px;
    height: 19.89px;
}
.Website a {
    color: var(--Subtitle, #333);
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    margin-left: 10px
}
.mail {
    dispay: flex;
    align-items: center;
    padding: 14px 0 14px 16px;
    border: 0.5px solid #E9E9E9;
    background-color: #FFF;
}
.mail img {
    width: 19px;
    height: 19px;
}
.mail a {
    color: var(--Subtitle, #333);
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    margin-left: 10px
}
.contact {
    dispay: flex;
    align-items: center;
    padding: 14px 0 14px 16px;
    border: 0.5px solid #E9E9E9;
    background-color: #FFF;
}
.contact img {
    width: 19.90px;
    height: 19.53px;
}
.contact a {
    color: var(--Subtitle, #333);
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    margin-left: 10px
}
.EntriesMain {
    border-radius: 8px 8px 0px 0px;
    background-color: rgb(255, 255, 255);
    border: 0.5px solid rgb(233, 233, 233);
    margin-top: 10px;
}
.EntriesHeading {
    margin-top: 27px;
}
.LocationContact_RightS .EntriesHeading h4 {
    color:  #061229;
    font-family: ${fontFamilyMedium};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.RegisterOpt {
    padding: 14px 16px;
    border-bottom: 0.5px solid #E9E9E9;
    background-color: #fff;
}
// .RegisterOpt:nth-child(even){
//     background-color: #f6f6f6;
// }
.RegisterOpt:nth-child(odd){
    
}

.RegisterOpt:last-child {
    border-bottom: none;
}
.boysGi {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.boysGi h6 {
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.boysGi span {
    color: #061229;
    text-align: right;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.Age_RegisterButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}
.Age_RegisterButton span {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.Age_RegisterButton button {
    // width: 74px;
    // height: 25px;
    padding: 4px 12px 4px 11px;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: #5D7081;

    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.expendSeeMore {
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #838A93;
    padding-bottom: 17px;
    margin-top: 17px;
}
.expendSeeMore p {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 0;
}
.cancelRefundHeading {
    margin-top: 27px;
}
.LocationContact_RightS .cancelRefundHeading h4{
    color: #061229;
    font-family: ${fontFamilyMedium};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.cancelRefundPolicy {
    padding: 14px 16px;
    border-radius: 8px 8px 0px 0px;
    border: 0.5px solid #E9E9E9;
    background-color: #FFF;
    margin-top: 10px;
}
.cancelRefundPolicy h4 {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
}
.cancelRefundPolicy span {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
`;

import React from 'react'
import { OrganizerAllEntriesListStyle } from "./style"
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import DragDropIcon from "../../../assets/icons/ic_DragDropIcon.svg"
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";

const OrganizerAllEntriesList = () => {

  // const tableData = [
  //   { 
  //     '': '', 
  //     'Entries': 'Row 1, Col 2', 
  //     'Normal Fees': 'Row 1, Col 3',
  //     'Late Fees': 'Row 1, Col 2',
  //     'Classes': 'Row 1, Col 2',
  //     'Settings': 'Row 1, Col 2', 
  //     'Status': 'Row 1, Col 2',
  //     'Actions': 'Row 1, Col 2',
  //   },
  //   { 
  //     '': '', 
  //     'Entries': 'Row 1, Col 2', 
  //     'Normal Fees': 'Row 1, Col 3',
  //     'Late Fees': 'Row 1, Col 2',
  //     'Classes': 'Row 1, Col 2',
  //     'Settings': 'Row 1, Col 2', 
  //     'Status': 'Row 1, Col 2',
  //     'Actions': 'Row 1, Col 2',
  // },
  // ];

  return (
    <div>

      <OrganizerAllEntriesListStyle>

        <div className="banner">
          <img
            src={AcademyProfileBannerImage}
            alt="AcademyProfileBannerImage"
          />
        </div>

        <div className='organizerAllEntriesListMain'>
          <h2>All Entries</h2>
          <div className='tablelist'>
          <table>
            <tr>
              <th></th>
              <th>Entries</th>
              <th>Normal Fees</th>
              <th>Late Fees</th>
              <th>Classes</th>
              <th>Settings</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            {Array.from({ length: 10 }, (_, index) =>
            <tr>
              <td>
                <img src={DragDropIcon} alt="DragDropIcon" />
              </td>
              <td>Boy Gi</td>
              <td>£ 20.00</td>
              <td>£ 40.00</td>
              <td><label className="Classes">Classes</label></td>
              <td><label className="Settings">Settings</label></td>
              <td><label className="Sent">Sent</label></td>
              <td className='actions'>
              <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
              </td>
            </tr>
            )}
          </table>
          </div>
          
          <div className='nextBtn'>
            <button>Next</button>
          </div>
        </div>


      </OrganizerAllEntriesListStyle></div>
  )
}

export default OrganizerAllEntriesList
import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../../components/GlobalStyle";

export const AcademyUsersProfilesStyle = styled.div`
.wrapper {
    width: 100%;
    display: flex;
}
.bodyLeftSideMain {
    width: 25%;
    margin-right: 36px;
}
.introductionParagraph {
    max-width: 359px;
    width: 100%;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding: 14px 12px 10px 17px;
    margin-top: 18px;
}
.introductionParagraph h6 {
    color: #000;
    text-align: justify;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.introductionParagraph p {
    color: #4F4F4F;
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 5px;
    margin-bottom: 0;
}
.mapFrame {
    max-width: 357px;
    width: 100%;
    border-radius: 0px 0px 9px 9px;
    border: 0px solid #E0E0E0;
    margin-top: 21px;
}
.bodyRightSideMain {
    width: 75%;
}
.studentsMain {
    width: 100%;
    margin-top: 22px;
}
.studentsHeading h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.52px;
    margin-bottom: 0;
}
.studentCardMain {
    display: flex;
    flex-wrap: wrap;
    gap: 20.47px 20px;
    margin-top: 11px;
}
.studentCardMain > div {
    max-width: 32.1%;
    width: 100%;
}

// Pagination Styling
.paginationMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12.14px 0 29px 0px;
}
.totalPages ul {
    display:flex;
    align-items: center;
    padding: 0;
    margin: 0;
}
.totalPages ul li {
    display: flex;
    align-items: center;
    list-style-type: none;

    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.totalPages ul li span {
    display: flex;
    width: 47px;
    padding: 7px 31px 8px 9px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #F1F1F1;
    margin: 0 9px;
    background-color: #fff;

    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.pagination ul {
    display:flex;
    align-items: center;
    gap: 0 6px;
    padding: 0;
    margin: 0;
}
.pagination ul li {
    list-style-type: none;
}
.disabled {
    display: inline-flex;
    padding: 7px 7px 9px 6px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #FFF;
    cursor: pointer;
}
.disabled span {
    color: #BDBDBD;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page1 {
    display: flex;
    width: 32px;
    padding: 7px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    background-color: #FFF;
    cursor: pointer;
}
.page2 {
    display: flex;
    width: 32px;
    padding: 7px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    background-color: #FFF;
    cursor: pointer;
}
.page1 span, .page2 span, .page10 span, .nextPage span  {
    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.active {
    display: flex;
    width: 32px;
    padding: 7px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #1A4754;
    cursor: pointer;
}
.active span {
    color: #FFF;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page4_9 {
    display: flex;
    width: 32px;
    padding: 4px 0px 12px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #FFF;
    cursor: pointer;
}
.page4_9 span {
    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page10 {
    display: inline-flex;
    padding: 8px 9px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    background-color: #FFF;
    cursor: pointer;
}
.nextPage {
    display: inline-flex;
    padding: 8px 10px 8px 11px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #FFF;
    cursor: pointer;
}
// Pagination Styling end
`;
import React from 'react'
import { OrganizerCreditHistoryStyle } from "./style"
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import DragDropIcon from "../../../assets/icons/ic_DragDropIcon.svg"
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import StatusActiveError from "../../../assets/images/activeBtnError.svg";
const OrganizerCreditHistory = () => {
  return (
    <div>
      <OrganizerCreditHistoryStyle>

        <div className="title_AffiliationButton">
          <h4 className="title">Credit history</h4>
          <button className="newAffiliationButton">Add Credit</button>
        </div>

        <div className='tablelist'>
          <table>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Event</th>
              <th>User</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            {Array.from({ length: 10 }, (_, index) =>
            <tr>
              <td>
              2023-08-14
              </td>
              <td><label className='Registrations'>Registrations</label><img src={StatusActiveError} alt="StatusActiveError" /></td>
              <td>Qazaq Batyry Kids Open</td>
              <td>Martin Varghult</td>
              <td>+£2000.00 GBP</td>
              <td><label className="Active">Active</label><img src={StatusActiveError} alt="StatusActiveError" /></td>
              <td className='actions'>
              <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
              </td>
            </tr>
            )}
          </table>
          </div>

      </OrganizerCreditHistoryStyle>
    </div>
  )
}

export default OrganizerCreditHistory
import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium } from "../../../components/GlobalStyle";

export const OrganizerSettingStatisticsMatch2Style = styled.div`
.bodyWrapper_RightSide {
    width: 100%;
    padding-left: 36px;
}
.mainContainer {
    width: 100%;
    margin-top: 21px;
}
.sectionTitles h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.tableContainer {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #E0E0E0;
    background-color: #FFF;
    margin-top: 18px;
}
table {
    width: 100%;
}
table thead tr th {
    padding: 14px 0 27px 10px;
    color: #061229;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
table tbody tr td {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
table thead tr th:first-child {
    padding-left: 23px;
}
table tbody tr td:first-child {
    padding-left: 22px;
}
table thead tr th:last-child {
    border-top-right-radius: 10px;
}
table tbody tr td {
    padding: 9.3px 0px 8.3px 10px;
}
table thead tr th:first-child,
table tbody tr td:first-child {
    width: 10%;
}
table thead tr th:nth-child(2),
table tbody tr td:nth-child(2) {
    width: 14%;
}
table thead tr th:nth-child(3),
table tbody tr td:nth-child(3) {
    width: 12%;
}
table thead tr th:nth-child(4),
table tbody tr td:nth-child(4)  {
    width: 9%;
}
table thead tr th:nth-child(5),
table tbody tr td:nth-child(5) {
    width: 9%;
}
table thead tr th:nth-child(6),
table tbody tr td:nth-child(6) {
    width: 18%;
}
table thead tr th:nth-child(7),
table tbody tr td:nth-child(7) {
    width: 10%;
}
table thead tr th:nth-child(8),
table tbody tr td:nth-child(8) {
    width: 11%;
}
table thead tr th:last-child,
table tbody tr td:last-child {
    padding-right: 13px;
    width: 7%;
}
table tbody tr td:last-child {
    text-align: center;
}
table tbody tr:nth-child(odd) {
    background-color: #FAFAFA;
}
table tbody tr:nth-child(even) {
    background-color: #FFF;
}
.order {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.type, .paymentMethod, .amount {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.status span {
    padding: 3px 26px 4px 26px;
    border-radius: 4px;
    background-color: #4CAF50;
    color: #FFF;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align-center;
}
.actions img {
    cursor: pointer;
}


`;
import { Card } from "antd"
import { Col, Row } from "react-bootstrap"
import Head from "../../../components/Head/Head"
import OverlayImages from "../../Home/OverlayImages/OverlayImages"
import { ViewPageStyle } from "../../Academy/AcademyView/style"
import { AffiliateDataType } from "../constant"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useAffiliate from "../Hooks/useAffiliate"
import { RootState } from "../../../app/store"
import { useSelector } from "react-redux"

const AffiliateView = () => {
    const {getAffiliateView} = useAffiliate()
    const [affiliateData, setAffiliateData] = useState<AffiliateDataType>()
    const { affiliateId } = useParams()
    const { data } = useSelector((state: RootState) => state.loginData);

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const response: any = await getAffiliateView(Number(affiliateId))
                if (response) {
                    setAffiliateData(response)
                }
            } catch (error) {
                console.log("error", error)
            }
        }
        fetchData()
    }, [])

  return (
    <>
    <Head title="ULTM8 - Affiliate View" />
    <ViewPageStyle>
        <OverlayImages
            backgroundImg={''}
            overlayImg={''}
            isEditable={true}
        />
        <h3>Owner Information</h3>
        <Card>
            <Row>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                            Owner First Name
                        </div>
                        <div className="list-item-value">
                            {data.user.firstName || "--"}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                            Owner Last Name
                        </div>
                        <div className="list-item-value">
                        {data.user.lastName || "--"}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">Email</div>
                        <div className="list-item-value">
                        {data.user.email || "--"}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item ">
                        <div className="list-item-title">
                            Phone Number
                        </div>
                        <div className="list-item-value">
                        {data.user.phoneNumber || "--"}
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
        <h3>Affiliate Academy</h3>
        <Card>
            <Row>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                            Academy Name
                        </div>
                        <div className="list-item-value">
                            {' '}
                            Umar's Academy
                        </div>
                    </div>
                </Col>
                <Col md="4">
                    <div className="list-item ">
                        <div className="list-item-title">
                            Academy Address
                        </div>
                        <div className="list-item-value">
                            Lahore, Johar Town, Lahore.
                        </div>
                    </div>
                </Col>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                            Academy Type
                        </div>
                        <div className="list-item-value">
                            School
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
        <h3>Affiliate View</h3>
        <Card>
            <Row>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                        Affiliate Name
                        </div>
                        <div className="list-item-value">
                        {affiliateData?.affiliationname || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                        Affiliate Type
                        </div>
                        <div className="list-item-value">
                        {affiliateData?.affiliationtype || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                          Phone Number
                        </div>
                        <div className="list-item-value">
                        {affiliateData?.phonenumber || '--'}
                        </div>
                    </div>
                </Col>

                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                        Affiliate Address

                        </div>
                        <div className="list-item-value">
                        {affiliateData?.address || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="3">
                    <div className="list-item">
                        <div className="list-item-title">
                           Default Language
                        </div>

                        <div className="list-item-value">
                        {affiliateData?.defaultlanguageid || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="3">
                    <div className="list-item">
                        <div className="list-item-title">
                           Default Currency
                        </div>
                        <div className="list-item-value">
                        {affiliateData?.defaultcurrencyid || '--'}
                        </div>
                    </div>
                </Col>

                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                           Activity
                        </div>
                        <div className="list-item-value">
                        {affiliateData?.activities || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                           Facility
                        </div>
                        <div className="list-item-value">
                        {affiliateData?.facilities || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="12">
                    <div className="list-item mb-0">
                        <div className="list-item-title">
                           Description
                        </div>
                        <div className="list-item-value">
                        {affiliateData?.description || '--'}
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
    </ViewPageStyle>
    </>
  )
}

export default AffiliateView
import React from "react";
import { TestingComponentStyle } from "./style";
import PersonImage from "../../assets/images/CopyRegistration-CardImage.png";
import actionMenuTogglerIcon from "../../assets/icons/ic_action_menu_toggler.svg";

const TestingComponents = () => {
  return (
    <div>
      <TestingComponentStyle>
        {/* <div className="closeWeighIn_container">
          <div className="title">
            <h4>Close weigh-in</h4>
          </div>
          <div className="messageContent">
            <p>
              These registrations are currently set as "unknown" do you want to
              set them as "no-show"? If they are already seeded in a bracket
              they will be marked as WO (Walk Over) and automatically lose all
              their matches.
            </p>
          </div>
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Club / Team</th>
                  <th>Group</th>
                  <th>Status Note</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="personImage">
                    <img src={PersonImage} alt="PersonImage" />
                  </td>
                  <td className="name">John Smith</td>
                  <td className="clubTeam">
                    <p>BJJ Back Box</p>
                    <span>Team</span>
                  </td>
                  <td className="group">
                    <p>Male Gi / Black / Adult / -82.3 kg</p>
                    <span>(Middle)</span>
                  </td>
                  <td></td>
                  <td className="actionImage">
                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                  </td>
                </tr>

                <tr>
                  <td className="personImage">
                    <img src={PersonImage} alt="PersonImage" />
                  </td>
                  <td className="name">John Smith</td>
                  <td className="clubTeam">
                    <p>BJJ Back Box</p>
                    <span>Team</span>
                  </td>
                  <td className="group">
                    <p>Male Gi / Black / Adult / -82.3 kg</p>
                    <span>(Middle)</span>
                  </td>
                  <td></td>
                  <td className="actionImage">
                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                  </td>
                </tr>

                <tr>
                  <td className="personImage">
                    <img src={PersonImage} alt="PersonImage" />
                  </td>
                  <td className="name">John Smith</td>
                  <td className="clubTeam">
                    <p>BJJ Back Box</p>
                    <span>Team</span>
                  </td>
                  <td className="group">
                    <p>Male Gi / Black / Adult / -82.3 kg</p>
                    <span>(Middle)</span>
                  </td>
                  <td></td>
                  <td className="actionImage">
                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                  </td>
                </tr>

                <tr>
                  <td className="personImage">
                    <img src={PersonImage} alt="PersonImage" />
                  </td>
                  <td className="name">John Smith</td>
                  <td className="clubTeam">
                    <p>BJJ Back Box</p>
                    <span>Team</span>
                  </td>
                  <td className="group">
                    <p>Male Gi / Black / Adult / -82.3 kg</p>
                    <span>(Middle)</span>
                  </td>
                  <td></td>
                  <td className="actionImage">
                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                  </td>
                </tr>

                <tr>
                  <td className="personImage">
                    <img src={PersonImage} alt="PersonImage" />
                  </td>
                  <td className="name">John Smith</td>
                  <td className="clubTeam">
                    <p>BJJ Back Box</p>
                    <span>Team</span>
                  </td>
                  <td className="group">
                    <p>Male Gi / Black / Adult / -82.3 kg</p>
                    <span>(Middle)</span>
                  </td>
                  <td></td>
                  <td className="actionImage">
                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                  </td>
                </tr>

                <tr>
                  <td className="personImage">
                    <img src={PersonImage} alt="PersonImage" />
                  </td>
                  <td className="name">John Smith</td>
                  <td className="clubTeam">
                    <p>BJJ Back Box</p>
                    <span>Team</span>
                  </td>
                  <td className="group">
                    <p>Male Gi / Black / Adult / -82.3 kg</p>
                    <span>(Middle)</span>
                  </td>
                  <td></td>
                  <td className="actionImage">
                    <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="changeStatusBtn">
            <button>Change Status</button>
          </div>
        </div> */}

        
      </TestingComponentStyle>
    </div>
  );
};

export default TestingComponents;

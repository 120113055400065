import React from 'react'
import {OrganizerCreateAnEventTermsStyle} from "./style"
const OrganizerCreateAnEventTerms = () => {
  return (
    <div>
        <OrganizerCreateAnEventTermsStyle>

        </OrganizerCreateAnEventTermsStyle>
    </div>
  )
}

export default OrganizerCreateAnEventTerms
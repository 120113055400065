import React from 'react'
import {OrganizerAllEntriesClassesStyle} from "./style"

const OrganizerAllEntriesClasses = () => {
  return (
    <div>
        <OrganizerAllEntriesClassesStyle>
        OrganizerAllEntriesClasses
        </OrganizerAllEntriesClassesStyle>
    </div>
  )
}

export default OrganizerAllEntriesClasses
import React from 'react'
import { OrganizerSettingLeftStyle } from "./style"

const OrganizerSettingLeft = () => {

    const listItems = [
        { text: 'Gerenal', link: '#' },
        { text: 'Settings', link: '#' },
        { text: 'Information', link: '#' },
        { text: 'Pages', link: '#' },
        { text: 'Registrations', link: '#' },
        { text: 'Registrations', link: '#' },
        { text: 'Entries', link: '#' },
        { text: 'Discounts', link: '#' },
        { text: 'Coupon codes', link: '#' },
        { text: 'Capacity rules', link: '#' },
        { text: 'Terms of serives', link: '#' },
        { text: 'Scoreboard', link: '#' },
        { text: 'Mat settings', link: '#' },
        { text: 'Schedule settings', link: '#' },
        { text: 'Livestreams', link: '#' },
        { text: 'Brackets', link: '#' },
        { text: 'Settings', link: '#' },
    ];

    return (
        <div>
            <OrganizerSettingLeftStyle>
                <div className='organizerLeft'>
                    <ul>
                        {listItems.map((item, index) => (
                            <li key={index}>
                                <a href={item.link}>{item.text}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </OrganizerSettingLeftStyle>
        </div>
    )
}

export default OrganizerSettingLeft
import React from 'react'
import { EndMatchScoreBoardTradStyle } from "./style"

interface EndMatchScoreBoardTradProps {
    handleEndGameButton: () => void
    switchSideHandler: () => void
    undoScoringActionHandler: () => void
}

const EndMatchScoreBoardTrad = ({handleEndGameButton, switchSideHandler, undoScoringActionHandler}: EndMatchScoreBoardTradProps) => {

    const handleTableClick = (event: any) => {
        event.stopPropagation();
      };
    return (
        <div>
            <EndMatchScoreBoardTradStyle>
                <div className='container-match-1' onClick={handleTableClick}>
                    <div className="match-container-1">
                        <div className="match-1" onClick={undoScoringActionHandler}><h5> UNDO SCORING ACTION</h5></div>
                        <div className="match-1" ><h5> BACK TO BRACKET</h5></div>
                        <div className="match-1" onClick={switchSideHandler} ><h5> SWITCH SIDES</h5></div>
                        <div className="match-1" ><h5> BACK TO FIGHTORDER</h5></div>
                    </div>
                    <div className='match-container-2'>
                        <div className="endgame" onClick={handleEndGameButton}><h5>END GAME</h5></div>
                    </div>
                </div>
            </EndMatchScoreBoardTradStyle>
        </div>
    )
}

export default EndMatchScoreBoardTrad
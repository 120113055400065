import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../components/GlobalStyle";

export const SelectDateStyle = styled.div`

.selectDateMain {
    max-width: 226px;
    width: 100%;
    padding: 21px 22px 20px 24px;
    background-color: #ffffff;
    border: 1px solid #F7F6FF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.days_Date {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.days_Date h6 {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.32px;
    text-transform: capitalize;
    margin-bottom: 0;
}
.days_Date p {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
    margin-bottom: 0;
}
`;
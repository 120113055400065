import "./App.css";
import "./animation.css";

import { Suspense, useLayoutEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./routes/AppRoutes";
import Head from "./components/Head/Head";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "./screens/pages";
import IntroScreen from "./components/IntroScreen";
import { SkeletonTheme } from "react-loading-skeleton";
import { lightGrey13 } from "./components/GlobalStyle";
import { local_storage_admin_key } from "./utils/axios.utils";
import { useDispatch } from "react-redux";
import { setLoginData } from "./app/features/loginDataSlice";
import { getUser_url } from "./utils/api_urls";
import api from "./api";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    const token = localStorage.getItem("token");
    if (token) getUser();
    else setLoading(false);
  }, [localStorage.getItem("token")]);

  const getUser = async () => {
    try {
      const { data } = await api("get", getUser_url);
      dispatch(setLoginData(data.result));
    } catch (error) {
      console.error("Failed to get user", error);
    } finally {
      setLoading(false);
    }
  };

  return !loading ? (
    <>
      <Head title="ULTM8" />
      <SkeletonTheme baseColor={lightGrey13} highlightColor={lightGrey13}>
        <ToastContainer />
        <ErrorBoundary
          FallbackComponent={ErrorPage}
          onError={() => console.log("Error happened")}
        >
          {false ? (
            <IntroScreen />
          ) : (
            <Suspense fallback={<h6>Loading...</h6>}>
              <AppRoutes />
            </Suspense>
          )}
        </ErrorBoundary>
      </SkeletonTheme>
    </>
  ) : (
    <></>
  );
}

export default App;
export const MAP_API = "AIzaSyC6PLT5-mrVFJcFqFixXZTW4d7Fj1EZg3Q";
export const loginData = localStorage.getItem(local_storage_admin_key) ?? null;

import styled from "styled-components";
import {fontFamilyRegularUber, lightDark2, pureDark2 } from "../../components/GlobalStyle";

export const ProfileBeforeLoginIntroductionStyle = styled.div`

.introductionMain {
    max-width: 359px;
    width: 100%;
    display: flex;
    // height: 207px;
    border-radius: 0 0 5px 5px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding-right: 8px;
    padding-bottom: 14px;
}
.cardContainer {
    width: 100%;
}
.eastLogo img {
    margin-left: 22px !important;
    margin-top: -76px !important;
}
.personName {
    padding: 9px 0 0 17px;
}
.personName h4{
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.address {
    padding: 8px 0 0 17px;
}
.address p {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    span {
        color: ${lightDark2};
    }
}

.phoneNum {
    padding: 10px 0 0 17px;
}
.phoneNum p {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    span {
        color: ${lightDark2};
    }
}
.age {
    padding: 10px 0 0 17px;
}
.age p {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    span {
        color: ${lightDark2};
    }
}
.nationality {
    padding: 10px 0 0 17px;
}
.nationality p {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    span {
        color: ${lightDark2};
    }
}

.boxes_Main {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 9px 0 0 17px;
}

.box1, .box2, .box3 {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    padding: 2px;
}

.box1 {
    background: #CDAF49;
}
.box2 {
    background: #A8A9AD;
}
.box3 {
    background: #BC6B50;
}
.box1 p, .box2 p, .box3 p{
    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

`;

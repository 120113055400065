import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Form } from "antd";
import FormControl from "../../components/FormControl";
import CustomButton from "../../components/CustomButton/CustomButton";
import {
  RobinEggBlue,
  fontFamilyRegularUber,
  pureDark2,
} from "../../components/GlobalStyle";
import LoginStyle from "./style";
import { useState } from "react";
import Head from "../../components/Head/Head";
import show_password_icon from "../../assets/icons/ic_show_passcode.svg";
import { login_url } from "../../utils/api_urls";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import logo from "../../assets/icons/NavBarLogo.svg";
import { setLoginData } from "../../app/features/loginDataSlice";
import CustomModal from "../../components/Modal/CustomModal";
import MessageModal from "../../components/Common/MessageModal/MessageModal";
import OauthLogin from "../../components/Common/OauthLogin/OauthLogin";
import { OAUTH_USECASES } from "../../components/Common/OauthLogin/constants";
import { LOGIN_YUP, loginValuesType, initialValues } from "./constants";
import api from "../../api";

interface LoginProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRegisterModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsForgotModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Login: React.FC<LoginProps> = ({
  isModalVisible,
  setIsModalVisible,
  setIsRegisterModalVisible,
  setIsForgotModalVisible,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  // login handle submit
  const handleSubmit = async (values: loginValuesType): Promise<void> => {
    try {
      setloading(true);
      const { data } = await api("post", login_url, values);
      localStorage.setItem('provider', data.result.user.provider);
      localStorage.setItem('role', data.result.user.role);
      localStorage.setItem('token', data.result.token);

      dispatch(setLoginData(data.result.user));

      toast(
        <MessageModal
          message="Successful"
          description="You are successfully logged in to your account."
          type="success"
        />,
        {
          autoClose: 1000,
        }
      );

      setloading(false);
      navigate("/profile-after-login");
    } catch (error: any) {
      setloading(false);
      toast(<MessageModal message={error?.response?.data?.message} type="error" />);
    }
  };

  return (
    <>
      <Head title="Login" />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <LoginStyle>
          <div className="login-container overflow-auto">
            <div className="login-container-card">
              <div className="logo text-center">
                <img src={logo} alt="logo" />
              </div>
              <p className="subtitle text-center">
                Login to access your account
              </p>
              <div className="login-container-card-inner">
                <div className="login-container-card-form">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={LOGIN_YUP}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => {
                      return (
                        <Form
                          name="basic"
                          onFinish={formik.handleSubmit}
                          autoComplete="off"
                        >
                          <div className="login-input-fields">
                            <div className="mt-20">
                              <FormControl
                                control="input"
                                type="text"
                                name="email"
                                color={pureDark2}
                                label="Email"
                                placeholder="Enter Email"
                                className={
                                  formik.errors.email && formik.touched.email
                                    ? "is-invalid is-invalidEmail"
                                    : "customInput"
                                }
                                textalign="end"
                              />
                            </div>
                            <div className="mt-20">
                              <FormControl
                                control="password"
                                type="text"
                                name="password"
                                color={pureDark2}
                                label={"Passwords"}
                                padding="10px"
                                placeholder="Enter Password"
                                suffix={show_password_icon}
                                className={
                                  formik.errors.password &&
                                  formik.touched.password
                                    ? "is-invalid loginInvalidPassword"
                                    : "customPasswordInput loginPassword"
                                }
                                textalign="end"
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-20">
                              <div className="d-flex align-items-center gap-2 checkBoxstyling">
                                <FormControl
                                  control="checkbox"
                                  type="checkbox"
                                  id="rememberMe"
                                  name="rememberMe"
                                />
                                <p className="remeberText">Remember Me</p>
                              </div>
                              <p
                                className="forget_password mb-0 text-end cursor-pointer"
                                onClick={() => {
                                  setIsForgotModalVisible(true);
                                  setIsModalVisible(false);
                                }}
                              >
                                Forgot Password?
                              </p>
                            </div>
                            <div className="mt-20 loginBtn">
                              <CustomButton
                                bgcolor={RobinEggBlue}
                                textTransform="uppercase"
                                color={pureDark2}
                                padding="15px"
                                fontFamily={fontFamilyRegularUber}
                                width="100%"
                                type="submit"
                                title="Login"
                                fontSize="16px"
                                loading={loading}
                              />
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>

                <div className="d-flex or-line fs-6 mt-20 align-items-center">
                  <div className="line" />
                  <p className="orText">Or</p>
                  <div className="line" />
                </div>
                <OauthLogin useCase={OAUTH_USECASES.login} />
                <div className="signup-text mt-20">
                  <p className="mb-0 text-center ">
                    If you already have an account we'll log you in. If not{" "}
                    <span
                      className={`mt-2`}
                      onClick={() => {
                        setIsModalVisible(false);
                        setIsRegisterModalVisible(true);
                      }}
                    >
                      <Link to="" className="">
                        register
                      </Link>
                    </span>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </LoginStyle>
      </CustomModal>
    </>
  );
};

export default Login;

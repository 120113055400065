import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../components/GlobalStyle";

export const EventResultStyle = styled.div`
.wrapper {
    width: 100%;
}
.scheduleHeading {
    margin-top: 20px;
}
.scheduleHeading h4 {
    color: #061229;
    font-family: ${fontFamilyMedium};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.resultSchedule_Main {
    width: 100%;
    display: flex;
    justify-content: strat;
    gap: 20px;
    margin-top: 20px;
}
.resultSchedule_MainBlock2 {
    margin-top: 10px !important;
    margin-bottom: 40px !important;

}
.resultScheduleCard_Left, .resultScheduleCard_mid,
.resultScheduleCard_right {
    width: 32.35%;
}
.currentlyFighting_beReadyBullPen {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 16px;
}
.CurrentlyFightingIcon{
    width: 18.21px !important;
    height: 12.14px !important;
}
.GoToBullPenIcon {
    width: 15px !important;
    height: 15px !important;
}
.FighterReadyClock {
    width: 14px !important;
    height: 15px !important;
}
.FighterReadyClock_LastCard {
    width: 12.14px !important;
    height: 13.35px !important;
}
.currentlyFighting {
    display: flex;
    align-items: center;
    margin-left: 14.19px;
}
.currentlyFighting span {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.571px;
    letter-spacing: 0.32px;
    margin-left: 7.79px;
}
.beReadyBullPen {
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.beReadyBullPenLastCard {
    margin-right: 15px !important;
}
.currentlyFightingLastCard {
    margin-left: 10px !important;
}
.BeReadyGroupIcon {
    width: 12px !important;
    height: 19.47.21px !important;
}
.beReadyBullPen span {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.571px;
    letter-spacing: 0.32px;
}
.beReadyBullPenLastCard span {
    color: #232837;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.539px;
    letter-spacing: 0.14px;
    margin-right: 12px;
}
.beReadyBullPenLastCard img {
    margin-right: 0 !important;
}
.beReadyBullPen img {
    margin-right: 8.79px;
}
.dropDowndIcon_lastCard {
    width: 14px;
    height: 7px;
}
// Shezar Styling ScheduleCard
.schedule-container{
    border-radius: 8px 8px 0px 0px;
    border: 1px solid var(--Fields-Border, #E9E9E9);
    background: var(--Background, #F5F5F5);
    // max-width: 518px;
    width: 100%;
    height: 59.09px;
    flex-shrink: 0;
    padding: 0px 17.77px 0px 14.75px;
    margin-top: 20px;
}
.DropDown-Img{
    height: 6px;
    flex-shrink: 0;
    fill: #000;
    margin-left: 8.39px;
}
.Mat-Schedule{
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
    letter-spacing: 0.36px;
    margin-top:15px;
    margin-bottom: 0;
}
.date-time-text{
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.28px;
    margin: 3.62px 0 0 0;
}
.section-2-container{
    background-color: #fff;
    flex-shrink: 0;
    fill: #FFF;
    border: 0.591px solid #C0D4DC;
    border-radius: 4px
}
.section-2 {
    padding: 0 9.85px 0 9.85px;
}
.tv-img{
    width: 21px;
    height: 16.634px;
    flex-shrink: 0;
    margin: 10.47px 0px 10.58px 0px
}
.tv-mode{
    color: #232837;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 17.729px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.669px;
    letter-spacing: 0.177px;
    padding-left: 10.83px;
    margin-bottom: 0;
}
// Shezar Styling ScheduleCardExpand
.sceduleCardsMain {
    background-color: #fff;
    padding-top: 6.89px;
}
.scheduleExpandCard-container{
    border: 1px solid #E9E9E9;
    background: var(--Background, #F5F5F5);
    // width: 518px;
    height: 64px;
    flex-shrink: 0;
    padding: 0px 12.84px 0px 11.82px;
}
.scheduleRow2 {
    margin-top: 9.33px;
}
.Mat-Schedule{
    font-family: ${fontFamilyMediumUber};
    color: #061229;
    font-size: 11.82px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.699px;
    margin-top:0px;
    margin-bottom:0px;
}
.progress-bar{
    flex-shrink: 0;
    border: 0.985px solid #F4F4F4;
    background: #F4F4F4;
    border: 0.985px solid #F4F4F4;
}
.progress-background{
    width: 117.212px;
    height: 5.91px;
    border: 0.985px solid #F4F4F4;
    flex-shrink: 0;
    margin-top:10.53px;
}
progress::-webkit-progress-bar {
    background-color: white;
}
 progress::-webkit-progress-value {
    background-color: #7BB037;
}
.section-time{
    margin-right:13.62px;
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 13.79px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.699px;
    letter-spacing: 0.276px;
}
.matches p{
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 13.79px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.699px;
    letter-spacing: 0.276px;
    margin-bottom:0px;
}
.section-time p {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 13.79px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.699px;
    letter-spacing: 0.276px;
    margin-bottom:0px;
}
.progbar-img {
    display: flex;
    justify-content: end;
    margin-top: 7.58px;
}
.expendForSeeMore {
    margin-top: 13.79px;
    padding-bottom: 16.74px;
    border-bottom: 0.985px solid #838A93;
} 
.expendForSeeMore h5 {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 13.79px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0;
}
`;

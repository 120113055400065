import { useNavigate } from "react-router-dom"
import axios from 'axios'
import { useState } from "react"
import { affiliateInitialValues } from "../constant"
import { Affiliate_Create_URL, Affiliate_Delete_URL, Affiliate_List_URL, Affiliate_Update_URL, Affiliate_View_URL } from "../../../utils/api_urls"
import { SchoolSuccessfulModals } from "../../../hooks/PopupModalsStyling"
import { Col, Row } from "react-bootstrap"
import { RobinEggBlue, fontFamilyRegularUber, pureDark2 } from "../../../components/GlobalStyle"
import CustomButton from "../../../components/CustomButton/CustomButton"
import ic_success from '../../../assets/images/ic_success.svg'
import CustomMessageModal from "../../../components/Modal/CustomMessageModal"
import CustomModal from "../../../components/Modal/CustomModal"

interface IModalComponent {
    modalComponent: JSX.Element
}
interface IUseAffiliate {
    // loading: boolean
    handleAffiliateCreate: (
        id: number,
        values: affiliateInitialValues
    ) => Promise<void>
    handleAffiliateUpdate: (
        AffiliateId: number,
        values: affiliateInitialValues
    ) => Promise<void>
    showAffiliateList: (adminId: number) => Promise<any>
    getAffiliateView: (adminId: number) =>  Promise<any> 
    handleAffiliateDelete: (adminId: number) =>  Promise<any>
    SuccessModal: () => IModalComponent
    WarningModal: () => IModalComponent
    deleteConfirmation: (id: number, setBranch: any) => IModalComponent
    setIsShowModal: (showModal: true) => void
}


const useAffiliate = (): IUseAffiliate => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowSuccessModal, setIsShowSuccessModal] = useState(false)
    const [isShowErrorModal, setIsShowErrorModal] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')


    // Affiliate Create
    const handleAffiliateCreate = async (
        affiliateId: number,
        values: affiliateInitialValues
    ): Promise<void> => {

        const payload = {
            affiliationId: affiliateId,
            academyId: values.academyId,
            affiliationName: values.affiliationName,
            affiliationType: values.affiliationType,
            phoneNumber: values.phoneNumber,
            address: values.address,
            rank: values.rank,
            defaultLanguageId: values.defaultLanguageId,
            defaultCurrencyId: values.defaultCurrencyId,
            activities: values.activities,
            facilities: values.facilities,
            description: values.description
        }
        try {
            setLoading(true)
            const {data} = await axios.post(
                Affiliate_Create_URL,
                payload,
            )
            setSuccessMessage(data.responseMessage)
            setLoading(false)
            setIsShowSuccessModal(true)
            setTimeout(() => {
                setIsShowSuccessModal(false)
                navigate('/affiliate/list')
            }, 3000)
        } catch (error: any) {
            setErrorMessage(error.responseMessage)
            setIsShowErrorModal(true)
            setTimeout(() => {
                setIsShowErrorModal(false)
            }, 3000)
        }

    }

    
    // Affiliate List
    const showAffiliateList = async (academyId: number): Promise<any> => {
        try {
            const {data} = await axios.post(
                Affiliate_List_URL, 
                {
                    "academyId": academyId
                }
            )
            console.log("AffiliateList", data.result)
                return data.result
        } catch (error) {
            console.log("error", error)
        }
    } 
   
    // Affiliate View
    const getAffiliateView = async (affiliationId: number): Promise<any> => {
        try {
            const {data} = await axios.post(
                Affiliate_View_URL,
                {"affiliationId": affiliationId}
            )
            return data.result
        } catch(error:any) { 
            console.log("error", error)
        }
    }
    
    // Affiliate Update
    const handleAffiliateUpdate = async (
        affiliationId: number,
        values: affiliateInitialValues
    ): Promise<any> => {
        const url = Affiliate_Update_URL
        try {
            const payload = {
                affiliationId: affiliationId,
                academyId: values.academyId,
                affiliationName: values.affiliationName,
                affiliationType: values.affiliationType,
                phoneNumber: values.phoneNumber,
                address: values.address,
                rank: values.rank,
                defaultLanguageId: values.defaultLanguageId,
                defaultCurrencyId: values.defaultCurrencyId,
                activities: values.activities,
                facilities: values.facilities,
                description: values.description
            }
            const {data} = await axios.post(url, payload)

            setSuccessMessage(data.responseMessage)
            setLoading(false)
            setIsShowSuccessModal(true)
            setTimeout(() => {
                setIsShowSuccessModal(false)
                navigate('/affiliate/list')
            }, 3000)
        } catch (error: any) {
            setErrorMessage(error.responseMessage)
            setIsShowErrorModal(true)
            setTimeout(() => {
                setIsShowErrorModal(false)
            }, 3000)
        }
    }

    // Affiliate Delete
    const handleAffiliateDelete = async (affiliateId: number): Promise<any> => {
        try {
            const {data} = await axios.post(
                Affiliate_Delete_URL,
                { "affiliateId": affiliateId}
            )
            setSuccessMessage(data.responseMessage)
            setLoading(false)
            setIsShowSuccessModal(true)
            setTimeout(() => {
                setIsShowSuccessModal(false)
                navigate('/affiliate/list')
            }, 3000)
        } catch (error: any) {
            setErrorMessage(error.responseMessage)
            setIsShowErrorModal(true)
            setTimeout(() => {
                setIsShowErrorModal(false)
            }, 3000)
        }
    }

    const SuccessModal = (): IModalComponent => {
        return {
            modalComponent: (
                <CustomMessageModal
                    title="Success"
                    description={successMessage}
                    isModalVisible={isShowSuccessModal}
                    setIsModalVisible={setIsShowSuccessModal}
                    imageProp={'success'}
                />
            ),
        }
    }

    const WarningModal = (): IModalComponent => {
        return {
            modalComponent: (
                <CustomMessageModal
                    title="Warning"
                    description={errorMessage}
                    isModalVisible={isShowErrorModal}
                    setIsModalVisible={setIsShowErrorModal}
                    imageProp={'error'}
                />
            ),
        }
    }

    const deleteConfirmation = (_id: number, setAffiliate: any): IModalComponent => {
        const DeleteSchool = async (id: number): Promise<void> => {
            setIsShowModal(false)

            await handleAffiliateDelete(Number(id))
            setAffiliate((prev: any)=>{
                prev = prev.filter((el:any)=> el.affiliateid !==id);
                return [...prev]
            })
        }
        return {
            modalComponent: (
                <CustomModal
                    isModalVisible={isShowModal}
                    setIsModalVisible={setIsShowModal}
                    showCloseBtn={false}
                >
                    <SchoolSuccessfulModals>
                        <div className="mainContainer">
                            <div className='mainContainer-header'>
                                <img
                                    src={ic_success}
                                    alt="Success Icon"
                                    width={25}
                                    height={25}
                                />
                                <h3 className="mainContainer-header-heading">
                                    Delete Academy
                                </h3>
                            </div>
                            <div className='mainContainer-body'>
                                <p className="mainContainer-body-subText">
                                    You can block them directly from their profile. If you  do, they won't be able to find your profile, posts or story or send you messages on Instagram. Instagram won't let them know you blocked them.
                                </p>
                                <Row className="mt-20">
                                    <Col md="6">
                                        <CustomButton
                                            bgcolor={'#292F3F33'}
                                            textTransform="uppercase"
                                            color={pureDark2}
                                            padding="10px 12.5px"
                                            fontFamily={fontFamilyRegularUber}
                                            width="100%"
                                            type="button"
                                            title="NO!"
                                            fontSize="16px"
                                            loading={false}
                                            clicked={() => setIsShowModal(false)}
                                        />
                                    </Col>
                                    <Col md="6">
                                        <CustomButton
                                            bgcolor={RobinEggBlue}
                                            textTransform="uppercase"
                                            color={pureDark2}
                                            padding="10px 12.5px"
                                            fontFamily={fontFamilyRegularUber}
                                            width="100%"
                                            type="submit"
                                            title="YES"
                                            fontSize="16px"
                                            loading={false}
                                            clicked={() => DeleteSchool(_id)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </SchoolSuccessfulModals>
                </CustomModal >
            ),
        }
    }

    return {
        // loading,
        handleAffiliateCreate,
        showAffiliateList,
        getAffiliateView,
        handleAffiliateUpdate,
        handleAffiliateDelete,
        SuccessModal,
        deleteConfirmation,
        WarningModal,
        setIsShowModal
    }
}

export default useAffiliate
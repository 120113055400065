import React from "react";
import { FormFieldStyling, OrganizerCreateAnEventRulesStyle } from "./style";
import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";
import FormControl from "../../../components/FormControl";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import {
  fontFamilyEnnVisionsMedium,
  fontFamilyEnnVisionsRegular,
  fontFamilyRegular,
  mediumPurple,
  whiteColor,
} from "../../../components/GlobalStyle";
import GapCurrencyIcon from "../../../assets/icons/is_GBP.svg";
import DeleteIcon from "../../../assets/icons/ic_deleteDark.svg";
import CustomButton from "../../../components/CustomButton/CustomButton";

const { Content } = Layout;

const OrganizerCreateAnEventRules = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };

  const handleCreateSubmit = () => {};

  return (
    <div>
      <OrganizerCreateAnEventRulesStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="creatEvent-left">
                <CreatEventLeftSide />
              </Content>
              <Content className="creatEvent-right">
                <FormFieldStyling>
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={handleCreateSubmit}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className="formWrapper">
                            <h3 className="title">Capacity</h3>
                            <div className="tablelist">
                              <div className="organizerSettingForm">
                                <Row>
                                  <Col md="12" className="mb-20">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-use-ammount"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Maximum Use Amount"
                                      padding="10px"
                                      placeholder="Christmas 2023 Coupon Discount"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="6" className="">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-use-ammount"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="During late fee"
                                      padding="10px"
                                      placeholder="Christmas 2023 Coupon Discount"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="3" className="">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-use-ammount"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Maximum Use Amount"
                                      padding="10px"
                                      placeholder="4000"
                                      suffix={
                                        <img src={GapCurrencyIcon} alt="" />
                                      }
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="3" className="">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="how"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="How"
                                      padding="10px"
                                      placeholder="In a coupon code"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div className="formWrapper">
                            <h3 className="title">Set Rules</h3>
                            <div className="tablelist">
                              <div className="organizerSettingForm">
                                <>
                                  <Row className="mt-20 mb-20">
                                    <Col md="4" className="">
                                      <FormControl
                                        control="select"
                                        type="select"
                                        name="category"
                                        fontFamily={fontFamilyEnnVisionsRegular}
                                        label="Category"
                                        padding="10px"
                                        placeholder="Country"
                                        className={
                                          formik.errors.detail &&
                                          formik.touched.detail
                                            ? "is-invalid"
                                            : "customInput"
                                        }
                                      />
                                    </Col>
                                    <Col md="4" className="">
                                      <FormControl
                                        control="select"
                                        type="select"
                                        name="type"
                                        fontFamily={fontFamilyEnnVisionsRegular}
                                        label="Type"
                                        padding="10px"
                                        placeholder="Is"
                                        className={
                                          formik.errors.detail &&
                                          formik.touched.detail
                                            ? "is-invalid"
                                            : "customInput"
                                        }
                                      />
                                    </Col>
                                    <Col md="4" className="">
                                      <FormControl
                                        control="select"
                                        type="select"
                                        name="category"
                                        fontFamily={fontFamilyEnnVisionsRegular}
                                        label="Category"
                                        padding="10px"
                                        placeholder="United Kingdom"
                                        className={
                                          formik.errors.detail &&
                                          formik.touched.detail
                                            ? "is-invalid"
                                            : "customInput"
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  {Array.from({ length: 3 }, (_, index) => (
                                    <div key={index}>
                                      <Row className="mt-20">
                                        <Col md="1" className="orCondition">
                                          <div className="">
                                            <p>OR</p>
                                          </div>
                                        </Col>
                                        <Col md="3" className="">
                                          <FormControl
                                            control="select"
                                            type="select"
                                            name="category"
                                            fontFamily={
                                              fontFamilyEnnVisionsRegular
                                            }
                                            label="Category"
                                            padding="10px"
                                            placeholder="Country"
                                            className={
                                              formik.errors.detail &&
                                              formik.touched.detail
                                                ? "is-invalid"
                                                : "customInput"
                                            }
                                          />
                                        </Col>
                                        <Col md="4" className="">
                                          <FormControl
                                            control="select"
                                            type="select"
                                            name="type"
                                            fontFamily={
                                              fontFamilyEnnVisionsRegular
                                            }
                                            label="Type"
                                            padding="10px"
                                            placeholder="Is"
                                            className={
                                              formik.errors.detail &&
                                              formik.touched.detail
                                                ? "is-invalid"
                                                : "customInput"
                                            }
                                          />
                                        </Col>
                                        <Col md="3" className="">
                                          <FormControl
                                            control="select"
                                            type="select"
                                            name="category"
                                            fontFamily={
                                              fontFamilyEnnVisionsRegular
                                            }
                                            label="Category"
                                            padding="10px"
                                            placeholder="United Kingdom"
                                            className={
                                              formik.errors.detail &&
                                              formik.touched.detail
                                                ? "is-invalid"
                                                : "customInput"
                                            }
                                          />
                                        </Col>
                                        <Col md="1" className="orCondition">
                                          <div className="deleteIcon">
                                            <img src={DeleteIcon} alt="" />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                </>
                              </div>
                            </div>
                          </div>

                          <div className="mt-20 d-flex justify-content-end">
                            <CustomButton
                              bgcolor={mediumPurple}
                              textTransform="Captilize"
                              color={whiteColor}
                              padding="11px 40.50px"
                              fontFamily={`${fontFamilyEnnVisionsMedium}`}
                              width="fit-content"
                              type="submit"
                              title="Next"
                              fontSize="18px"
                              loading={false}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </FormFieldStyling>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerCreateAnEventRulesStyle>
    </div>
  );
};

export default OrganizerCreateAnEventRules;

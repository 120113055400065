import React from 'react'
import StudentListCard from '../../../../components/StudentListCard/studentListCard'
import { Link } from 'react-router-dom'
import { StudentActiveStyle } from './style'

const StudentActive = () => {
    return (
        <StudentActiveStyle>
            <div className="StudentsWrapper mt-20">
                <div className='studentHeader'>
                    <h4 className="studentTitle">Active Students</h4>
                    <div className='batchHeader'>
                        <h4>Batch Update</h4>
                        <select name="batch" id="batch" className='batchSelect'>
                            <option value="member">Member</option>
                            <option value="manager">Manager</option>
                            <option value="coach">Coach</option>
                            <option value="affiliate">Affiliate</option>
                        </select>
                    </div>
                </div>
                <StudentListCard />
                <StudentListCard />
                <StudentListCard />
                <StudentListCard />
                <StudentListCard />
                <StudentListCard />
                <StudentListCard />
            </div>
        </StudentActiveStyle>
    )
}

export default StudentActive
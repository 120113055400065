import * as Yup from "yup";

export const SCREEN_LABEL_KEYS = {
    title: 'title',
    subtitle: 'subtitle',
    emailFieldTitle: 'emailFieldTitle',
    emailFieldPlaceholder: 'emailFieldPlaceholder',
    passcodeFieldTitle: 'passcodeFieldTitle',
    passcodeFieldPlaceholder: 'passcodeFieldPlaceholder',
    rememberMe: 'rememberMe',
    forgotPassword: 'forgotPassword',
    loginButton: 'loginButton',
    or: 'or',
    google: 'google',
    facebook: 'facebook',
    apple: 'apple',
    microsoft: 'microsoft',
    discord: 'discord',
    legalNote: 'legalNote',
    register: 'register',
    registerAccount: 'registerAccount',
}

// initial values types
export type loginValuesType = {
    email: string;
    password: string;
};

// initial values
export const initialValues: loginValuesType = {
    email: "",
    password: "",
};

export const LOGIN_YUP = Yup.object({
    email: Yup.string().email("Email in-valid").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be 8 characters long")
      .required("Password is required"),
  });
  
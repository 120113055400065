import React from "react";
import { AfterLoginPagesLeftSideStyle } from "./style";
import MapFrame from "../../assets/images/AcademyProfileMapImage-p3.png";
import IntroductionComponent from "../../components/ProfileBeforeLoginIntroduction/ProfileBeforeLoginIntroduction";
import ProfileBeforeImage from "../../assets/images/profile_before.svg";
import DynamoFight from "../DynamoFight/DynamoFight";

export const AfterLoginPagesLeftSide = () => {
  return (
    <div>
      <AfterLoginPagesLeftSideStyle>
        <div className="leftSideMain">
          <div className="introduction">
            <IntroductionComponent />
          </div>

          
          <div className="affiliationsMain">
              <div className="sectionHeadingsMain BlocksHeadings">
                <h4>Affiliations</h4>
                <span>See All</span>
              </div>
              <div className="affiliationsCards">
                <DynamoFight />
              </div>
            </div>

          <div className="mapFrame">
            <img src={MapFrame} alt="" />
          </div>
        </div>
      </AfterLoginPagesLeftSideStyle>
    </div>
  );
};

export default AfterLoginPagesLeftSide;

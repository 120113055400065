import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyRegularUber } from "../../../components/GlobalStyle";

export const OrganizerAllEntriesListStyle = styled.div`

    .organizerAllEntriesListMain{
        max-width: 1244px;
        margin: 0 auto;
    }
    .organizerAllEntriesListMain h2{
        font-family: ${fontFamilyMedium};
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.48px;
        margin-top: 24px;
    }
    .tablelist{
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #E0E0E0;
        background: #FFF;
        margin-bottom: 21px;
    }
    .organizerAllEntriesListMain table{
        -top: 17px;
        width: 100%;
        border-collapas: collapas; 

    }
    .organizerAllEntriesListMain table tr:nth-child(odd){

    }
    .organizerAllEntriesListMain table tr:nth-child(even){
        background-color: #FAFAFA;
    }
    .organizerAllEntriesListMain table tr th{
        color: #061229
        font-family: ${fontFamilyEnnVisionsMedium};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; 
        padding: 17px 20px;
    }
    .organizerAllEntriesListMain tr td{
        color: #061229;
        font-family: ${fontFamilyEnnVisionsRegular};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 17px 20px;
    }
    table tr td:nth-child(5) label{
        color: #000;
        font-family: ${fontFamilyEnnVisionsRegular};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 2px 21px;
        border-radius: 4px;
    }
    table tr td:nth-child(6) label{
        color: #000;
        font-family: ${fontFamilyEnnVisionsRegular};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 2px 21px;
        border-radius: 4px;
    }
    table tr td:nth-child(7) label{
        color: #FFF;
        font-family: ${fontFamilyEnnVisionsRegular};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 2px 21px;
        border-radius: 4px;
    }
    .Classes{
        background-color: #CFEFD5;
    }
    .Settings{
        background-color: #7EBEFF;
    }
    .Sent{
        background: #4CAF50;
    }
    .actions {
        text-align: center;
    }
    .nextBtn {
        text-align: right;
        margin-bottom: 15px;
    }
    .nextBtn button{
        padding: 11px 20px;
        color: #FFF;
        font-family: ${fontFamilyRegularUber};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.6px;
        border-radius: 4px;
        background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
        border: none;
    }

`;


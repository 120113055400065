import React from "react";
import { OrganizerSettingStatisticsMatchStyle } from "./style";
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import HamBurger from "../../../assets/icons/HamBurger.svg";
const OrganizerSettingStatisticsMatch = () => {
  return (
    <div>
      <OrganizerSettingStatisticsMatchStyle>
        <div className="bodyWrapper_RightSide">
          <div className="mainContainer registrationBelt">
            <div className="sectionTitle">
              <h4>Registrations by BELT</h4>
            </div>
            <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Group / Bracket</th>
                  <th>Match</th>
                  <th>Result</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                      <img
                        src={HamBurger}
                        alt="HamBurger"
                        className="hamBurger"
                      />
                  </td>
                  <td>
                    <div className="groupBracket">
                      <p>Male Gi / Blue / Adult / -76 kg (Light)</p>
                      <span>Semifinals</span>
                    </div>
                  </td>
                  <td className="match">
                    jhom smith <span>vs</span> pablo Hinson
                  </td>
                  <td>To be decided</td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>
                
                <tr>
                  <td>
                      <img
                        src={HamBurger}
                        alt="HamBurger"
                        className="hamBurger"
                      />
                  </td>
                  <td>
                    <div className="groupBracket">
                      <p>Male Gi / Blue / Adult / -76 kg (Light)</p>
                      <span>Semifinals</span>
                    </div>
                  </td>
                  <td className="match">
                    jhom smith <span>vs</span> pablo Hinson
                  </td>
                  <td>To be decided</td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                      <img
                        src={HamBurger}
                        alt="HamBurger"
                        className="hamBurger"
                      />
                  </td>
                  <td>
                    <div className="groupBracket">
                      <p>Male Gi / Blue / Adult / -76 kg (Light)</p>
                      <span>Semifinals</span>
                    </div>
                  </td>
                  <td className="match">
                    jhom smith <span>vs</span> pablo Hinson
                  </td>
                  <td>To be decided</td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                      <img
                        src={HamBurger}
                        alt="HamBurger"
                        className="hamBurger"
                      />
                  </td>
                  <td>
                    <div className="groupBracket">
                      <p>Male Gi / Blue / Adult / -76 kg (Light)</p>
                      <span>Semifinals</span>
                    </div>
                  </td>
                  <td className="match">
                    jhom smith <span>vs</span> pablo Hinson
                  </td>
                  <td>To be decided</td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            </div>
          </div>
        </div>
      </OrganizerSettingStatisticsMatchStyle>
    </div>
  );
};

export default OrganizerSettingStatisticsMatch;

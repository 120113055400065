import React from 'react'
import {CoachProfileEventRegistrationAddStudentsStyle} from "./style";
import StudentsCard from "../../../components/EventRegistrationCard/EventRegistrationCard"
import PaginationComponent from "../../../components/ProfilesBeforeLoginPagination/ProfilesBeforeLoginPagination"
export const CoachProfileEventRegistrationAddStudents = () => {
  return (
    <div>
        <CoachProfileEventRegistrationAddStudentsStyle>
            <div className='bodyWrapper_RightSide'>
                <div className='title_AddSaveButtons'>
                    <h4 className='title'>BJJ247 Brighton Autumn Open</h4>
                    <div className='AddSave_Buttons'>
                        <button className='addNewButton'>Add New User</button>
                        <button className='saveNowButton'>Save Now</button>
                    </div>
                </div>
                <div className='studentsCardContainer'>
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                    <StudentsCard />
                </div>
                <div className='pagination_Container'>
                    <PaginationComponent />
                </div>
            </div>

        </CoachProfileEventRegistrationAddStudentsStyle>
    </div>
  )
}

export default CoachProfileEventRegistrationAddStudents;
import React from "react";
import { EventLocationStyle } from "./style";
import ParticipantsFrame from "../../assets/icons/ParticipantsFrame.svg";
import PrintIcon from "../../assets/icons/PrintIcon.svg";
import UploadtIcon from "../../assets/icons/UploadIcon.svg";
import ToggleTrack from "../../assets/icons/ToggleTrack.png";
import ToggleButton from "../../assets/icons/Toggle.png";
import ParticipantImage from "../../assets/images/ali-hassan.png";
import RegistrationWeightSvg from "../../assets/icons/RegistrationWeightSVG.svg";
import UkFlag from "../../assets/icons/uk-flag.svg";
import { Switch } from "antd";
const EventLocation = () => {
  return (
    <div>
      <EventLocationStyle>
        <div className="wrapper">
          <div className="locationHeading">
            <h4>Participants</h4>
          </div>
          <div className="participantBlock1">
            <div className="participantBlock1_leftSide">
              <div className="frameMain">
                <img src={ParticipantsFrame} alt="ParticipantsFrame" />
              </div>
              <div className="categoryHeading">
                <h3>Male Gi / White / Adult / -76 kg (Light)</h3>
              </div>
              <div className="totalParticipants">
                <p>Total of Participants</p>
                <span>30</span>
              </div>
            </div>
            <div className="participantBlock1_rightSide">
            
              {/* <div className="showApproved_toggleButton">
                <span>Show Approved</span>
                <img src={ToggleTrack} alt="ToggleTrack" />
                <img
                  src={ToggleButton}
                  alt="ToggleButton"
                  className="toggleButtonApproved"
                />
              </div> */}
              <div className="showApproved_toggleButton">
                      <span>Show Unapproved</span>
                      <Switch className='' />
                    </div>
              <div className="printIcon">
                <img src={PrintIcon} alt="PrintIcon" />
              </div>
              <div className="uploadIcon">
                <img src={UploadtIcon} alt="UploadtIcon" />
              </div>
            </div>
          </div>

          <table>
            <thead>
              <tr className="tableHeaddings">
                <th className="image">Image</th>
                <th className="name">Name</th>
                <th className="birth">Birth</th>
                <th className="academy">Academy & Affiliation</th>
                <th className="registration">Registration</th>
                <th className="ranking">Ranking</th>
                <th className="showApproved">
                  <div className="showUnApproved_toggleButton">
                    {/* <span>Show Unapproved</span>
                    <img src={ToggleTrack} alt="ToggleTrack" />
                    <img
                      src={ToggleButton}
                      alt="ToggleButton"
                      className="toggleButton"
                    /> */}
                    
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="tableBody">
              <tr>
                <td className="image">
                  <img src={ParticipantImage} alt="ParticipantImage" />
                </td>
                <td className="name">
                  <p>Ali Hassan</p>
                  <div className="countryFlag">
                    <span>Georgia</span>
                    <img src={UkFlag} alt="" />
                  </div>
                </td>
                <td className="birth">
                  <p>2015</p>
                  <span>26 Years</span>
                </td>
                <td className="academy">
                  <p>Judo Gymnasia</p>
                  <span>Judo Gymnasia</span>
                </td>
                <td className="registration">
                  <p>Under 24kg</p>
                  <div className="weight">
                    <img
                      src={RegistrationWeightSvg}
                      alt="RegistrationWeightSvg"
                    />
                    <span>24.00 kg</span>
                  </div>
                </td>
                <td className="ranking">
                  <p>White Belt</p>
                </td>
                <td className="showApproved"></td>
              </tr>
              <tr>
                <td className="image">
                  <img src={ParticipantImage} alt="ParticipantImage" />
                </td>
                <td className="name">
                  <p>Ali Hassan</p>
                  <div className="countryFlag">
                    <span>Georgia</span>
                    <img src={UkFlag} alt="" />
                  </div>
                </td>
                <td className="birth">
                  <p>2015</p>
                  <span>26 Years</span>
                </td>
                <td className="academy">
                  <p>Judo Gymnasia</p>
                  <span>Judo Gymnasia</span>
                </td>
                <td className="registration">
                  <p>Under 24kg</p>
                  <div className="weight">
                    <img
                      src={RegistrationWeightSvg}
                      alt="RegistrationWeightSvg"
                    />
                    <span>24.00 kg</span>
                  </div>
                </td>
                <td className="ranking">
                  <p>White Belt</p>
                </td>
                <td className="showApproved"></td>
              </tr>
            </tbody>
          </table>

          <div className="totalUnapproved">
            <span>Total Unapproved: 08</span>
          </div>

          <div className="participantBlock1 participantBlock1_Table2 ">
            <div className="participantBlock1_leftSide">
              <div className="frameMain">
                <img src={ParticipantsFrame} alt="ParticipantsFrame" />
              </div>
              <div className="categoryHeading">
                <h3>Male Gi / White / Adult / -76 kg (Light)</h3>
              </div>
              <div className="totalParticipants">
                <p>Total of Participants</p>
                <span>30</span>
              </div>
            </div>
            <div className="participantBlock1_rightSide">
              {/* <div className="showApproved_toggleButton">
                <span>Show Approved</span>
                <img src={ToggleTrack} alt="ToggleTrack" />
                <img
                  src={ToggleButton}
                  alt="ToggleButton"
                  className="toggleButtonApproved"
                />
              </div> */}
              <div className="showApproved_toggleButton">
                      <span>Show Unapproved</span>
                      <Switch className='' />
                    </div>
              <div className="printIcon">
                <img src={PrintIcon} alt="PrintIcon" />
              </div>
              <div className="uploadIcon">
                <img src={UploadtIcon} alt="UploadtIcon" />
              </div>
            </div>
          </div>

          <table>
            <thead>
              <tr className="tableHeaddings">
                <th className="image">Image</th>
                <th className="name">Name</th>
                <th className="birth">Birth</th>
                <th className="academy">Academy & Affiliation</th>
                <th className="registration">Registration</th>
                <th className="ranking">Ranking</th>
                <th className="showApproved">
                  <div className="showUnApproved_toggleButton">
                    {/* <span>Show Unapproved</span>
                    <img src={ToggleTrack} alt="ToggleTrack" />
                    <img
                      src={ToggleButton}
                      alt="ToggleButton"
                      className="toggleButton"
                    /> */}
                    
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="tableBody">
              <tr>
                <td className="image">
                  <img src={ParticipantImage} alt="ParticipantImage" />
                </td>
                <td className="name">
                  <p>Ali Hassan</p>
                  <div className="countryFlag">
                    <span>Georgia</span>
                    <img src={UkFlag} alt="" />
                  </div>
                </td>
                <td className="birth">
                  <p>2015</p>
                  <span>26 Years</span>
                </td>
                <td className="academy">
                  <p>Judo Gymnasia</p>
                  <span>Judo Gymnasia</span>
                </td>
                <td className="registration">
                  <p>Under 24kg</p>
                  <div className="weight">
                    <img
                      src={RegistrationWeightSvg}
                      alt="RegistrationWeightSvg"
                    />
                    <span>24.00 kg</span>
                  </div>
                </td>
                <td className="ranking">
                  <p>White Belt</p>
                </td>
                <td className="showApproved"></td>
              </tr>
              <tr>
                <td className="image">
                  <img src={ParticipantImage} alt="ParticipantImage" />
                </td>
                <td className="name">
                  <p>Ali Hassan</p>
                  <div className="countryFlag">
                    <span>Georgia</span>
                    <img src={UkFlag} alt="" />
                  </div>
                </td>
                <td className="birth">
                  <p>2015</p>
                  <span>26 Years</span>
                </td>
                <td className="academy">
                  <p>Judo Gymnasia</p>
                  <span>Judo Gymnasia</span>
                </td>
                <td className="registration">
                  <p>Under 24kg</p>
                  <div className="weight">
                    <img
                      src={RegistrationWeightSvg}
                      alt="RegistrationWeightSvg"
                    />
                    <span>24.00 kg</span>
                  </div>
                </td>
                <td className="ranking">
                  <p>White Belt</p>
                </td>
                <td className="showApproved"></td>
              </tr>
              <tr>
                <td className="image">
                  <img src={ParticipantImage} alt="ParticipantImage" />
                </td>
                <td className="name">
                  <p>Ali Hassan</p>
                  <div className="countryFlag">
                    <span>Georgia</span>
                    <img src={UkFlag} alt="" />
                  </div>
                </td>
                <td className="birth">
                  <p>2015</p>
                  <span>26 Years</span>
                </td>
                <td className="academy">
                  <p>Judo Gymnasia</p>
                  <span>Judo Gymnasia</span>
                </td>
                <td className="registration">
                  <p>Under 24kg</p>
                  <div className="weight">
                    <img
                      src={RegistrationWeightSvg}
                      alt="RegistrationWeightSvg"
                    />
                    <span>24.00 kg</span>
                  </div>
                </td>
                <td className="ranking">
                  <p>White Belt</p>
                </td>
                <td className="showApproved"></td>
              </tr>
              <tr>
                <td className="image">
                  <img src={ParticipantImage} alt="ParticipantImage" />
                </td>
                <td className="name">
                  <p>Ali Hassan</p>
                  <div className="countryFlag">
                    <span>Georgia</span>
                    <img src={UkFlag} alt="" />
                  </div>
                </td>
                <td className="birth">
                  <p>2015</p>
                  <span>26 Years</span>
                </td>
                <td className="academy">
                  <p>Judo Gymnasia</p>
                  <span>Judo Gymnasia</span>
                </td>
                <td className="registration">
                  <p>Under 24kg</p>
                  <div className="weight">
                    <img
                      src={RegistrationWeightSvg}
                      alt="RegistrationWeightSvg"
                    />
                    <span>24.00 kg</span>
                  </div>
                </td>
                <td className="ranking">
                  <p>White Belt</p>
                </td>
                <td className="showApproved"></td>
              </tr>
              <tr>
                <td className="image">
                  <img src={ParticipantImage} alt="ParticipantImage" />
                </td>
                <td className="name">
                  <p>Ali Hassan</p>
                  <div className="countryFlag">
                    <span>Georgia</span>
                    <img src={UkFlag} alt="" />
                  </div>
                </td>
                <td className="birth">
                  <p>2015</p>
                  <span>26 Years</span>
                </td>
                <td className="academy">
                  <p>Judo Gymnasia</p>
                  <span>Judo Gymnasia</span>
                </td>
                <td className="registration">
                  <p>Under 24kg</p>
                  <div className="weight">
                    <img
                      src={RegistrationWeightSvg}
                      alt="RegistrationWeightSvg"
                    />
                    <span>24.00 kg</span>
                  </div>
                </td>
                <td className="ranking">
                  <p>White Belt</p>
                </td>
                <td className="showApproved"></td>
              </tr>
            </tbody>
          </table>
          <div className="totalUnapprovedTable2">
            <span>Total Unapproved: 08</span>
          </div>

          <div className='paginationMain'>
              <div className='totalPages'>
                <ul>
                  <li>Page<span>1</span>of 10</li>
                </ul>
              </div>
              <div className='pagination'>
                <ul>
                  <li className='prevPage disabled'><span>Prev</span></li>
                  <li className='page1'><span>1</span></li>
                  <li className='page2'><span>2</span></li>
                  <li className='page3 active'><span>3</span></li>
                  <li  className='page4_9'><span>...</span></li>
                  <li className='page10'><span>10</span></li>
                  <li className='nextPage'><span>Next</span></li>
                </ul>
              </div>
          </div>

        </div>
      </EventLocationStyle>
    </div>
  );
};

export default EventLocation;

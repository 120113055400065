import styled from "styled-components";

export const WonByLightStyle = styled.div`
.boardScoreWonLight{

}
.scoreBoardTable{
    margin: 3vh 0px;
}
.scoreBoardTable table{
    width: 100%;
    max-width: 70vh;
    background: #000;
    box-shadow: 0px 0px 1.6vh 0px rgba(0, 0, 0, 0.55);
    text-align: center;
    border-radius: 6vh 6vh 0px 0px;
}
.scoreBoardTable table tr:nth-child(1){

}
.scoreBoardTable table tr:nth-child(1) td{
    padding: 1.5vh 2vh;
    font-size: 3.1vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.31px;
    text-transform: uppercase;
    background: #E5C91D;
    color: #000;
    font-family: "OperaBlack";
}
.scoreBoardTable table tr:nth-child(2) td{
    padding: 1.5vh 2vh;
    color: #FFF;
    font-family: Cream Opera;
    font-size: 3.1vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.31px;
    text-transform: uppercase;
    font-family: "OperaBlack";
}
.scoreBoardTable table tr:nth-child(3) td{
    padding: 2vh;
    color: #FFF;
    font-family: "OperaRegular";
    font-size: 2.9vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.21px;
    text-transform: uppercase;
}


`;
import React from "react";
import { AcademyProfileUpcomingEventStyle } from "./style";
import UncomingEventsCards from "../../AjpScoreBoard/TicketListing/TicketListing";
import PaginationComponent from "../../../components/ProfilesBeforeLoginPagination/ProfilesBeforeLoginPagination";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import MapFrame from "../../../assets/images/AcademyProfileMapImage-p3.png";
import IntroductionComponent from "../../../components/ProfileBeforeLoginIntroduction/ProfileBeforeLoginIntroduction";
export const AcademyProfileUpcomingEvent = () => {
  return (
    <div>
      <AcademyProfileUpcomingEventStyle>
        <div className="wrapper">
          <div className="bodyRightSideMain">
            <div className="upComingEventsMain">
              <div className="upComingEventsHeading">
                <h4>Upcoming Events</h4>
              </div>
              <div className="upComingCardsMain">
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
              </div>
            </div>
            <div className="paginationContainer">
              <PaginationComponent />
            </div>
          </div>
        </div>
      </AcademyProfileUpcomingEventStyle>
    </div>
  );
};
export default AcademyProfileUpcomingEvent;

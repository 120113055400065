import styled from "styled-components";
import { fontFamilyEnnVisionsRegular } from "../GlobalStyle";

export const CreatEventLeftSideStyle = styled.div`

.bodyLeftSideContainer {
    // width: 24.062%;
    // width: 100%;
    // max-width: 200px;

}
.leftSide {
    max-width: 100%;
    width: 100%;
    background-color: #fff;
    padding: 29px 29px;
    margin-bottom: 40px;
}
ul {
    list-style-type: none;
}
ul li a {
    text-decoration: none;
}
.generalMain {
    display: flex;
    flex-direction: column;
    gap: 19.98px;
    margin-bottom: 0;
    padding-left: 0;

}
.generalMain li:first-child a,
.registrationMain li:first-child a,
.tournamentMain li:first-child a,
.bracketsMain li:first-child a {
    color: #7B7B7B;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.generalMain li:nth-child(2) a {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.generalMain li a,
.registrationMain li a,
.tournamentMain li a,
.bracketsMain li a {
    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.registrationMain,
.tournamentMain,
.bracketsMain {
    display: flex;
    flex-direction: column;
    gap: 19.98px;
    margin-top: 30px;
    margin-bottom: 0;
    padding-left: 0;
}


`;
import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyRegularUber } from "../../../components/GlobalStyle";

export const BuyTicketsSelectUser2Style = styled.div`
.banner img {
    width: 100%;
}
.bodyWrapper {
    width: 100%;
}
.mainContainer {
    max-width: 1244px;
    width: 100%;
    margin: 32px auto 0 auto;
}
.title h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0px;
    padding-left: 1px;
}
table {
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #E0E0E0;
    background-color: #FFF;
    margin-top: 16px;
    margin-bottom: 50px;
    // border-collapse: separate;
    // border-spacing: 0px 7px;
}
table thead tr th {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}
table thead tr th:first-child,
table tbody tr td:first-child {
    width: 20%;
}
table thead tr th {
    padding: 20px 0px 7px 10px;
}
table thead tr th:first-child {
    padding: 20px 10px 7px 18px;
}
table tbody tr td {
    padding: 10px 0px 9px 10px;
}
table tbody tr td:first-child {
    padding: 10px 0px 9px 18px;
}
table thead tr th:nth-child(2),
table tbody tr td:nth-child(2) {
    width: 13%;
}
table thead tr th:nth-child(3),
table tbody tr td:nth-child(3) {
    width: 20%;
}
table thead tr th:nth-child(4),
table tbody tr td:nth-child(4) {
    width: 22%;
}
table thead tr th:nth-child(5),
table tbody tr td:nth-child(5) {
    width: 11%;
}
table thead tr th:nth-child(6),
table tbody tr td:nth-child(6) {
    width: 9%;
}
table thead tr th:last-child,
table tbody tr td:last-child {
    width: 5%;
    text-align: end;
}
table tbody tr td:last-child {
    padding-right: 39.94px;
}
table tbody tr:nth-child(odd) td {
    background-color: #FAFAFA;
}
table tbody tr:nth-child(even) td {
    background-color: #FFF;
}
.entries span {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.fee span {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.beltMaine {
  
}
.belt {
    
    div {
        div{
            div{
                div{
                max-width: 129.26px;
                width: 100%;
                height: 16px;
                background: #fff;
                border: 1px solid #000;
                position: relative;
                div{
                    background: #000;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 15px; 
                }
            }
            }
        }
    }
}
.ageButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 187.697px;
    width: 100%;
    height: 34px;
    padding: 7px 8.62px 10px 11.1px;
    border-radius: 5px;
    border: 1px solid #A7A7A7;
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.ageButton img {
    width: 9px;
    height: 15.974px;
    margin-left: 3.64px;
}
.weightButtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 110px;
    width: 100%;
    height: 34px;
    border-radius: 5px;
    border: 1px solid #A7A7A7;
    padding: 8px 12.98px 9px 13.52px;
    color: var(--Title, #061229);
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.registerButton {
    padding: 3px 19.97px 4px 19.97px;
    height: 24px;
    border-radius: 4px;
    background-color: #5C6F80;
    color: #FFF;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.threeDotSvg img {
    cursor: pointer;
}
.weightButtn select{   
    width: 100%;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // appearance: none;
    // background: url(assets/images/arrowSelectOption.svg) 96% center no-repeat;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // max-width: 110px;
    // width: 100%;
    // height: 34px;
    // border-radius: 5px;
    // border: 1px solid #A7A7A7;
    // padding: 8px 12.98px 9px 13.52px;
    // color: #061229;
    // font-family: ${fontFamilyEnnVisionsRegular};
    // font-size: 14px;
    // font-style: normal;
    // font-weight: 400;
    // line-height: normal;
}
.weightButtn select option{
    color: #4F4F4F;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
`;
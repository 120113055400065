import React, { ChangeEvent, useRef, useState } from "react";
import { BannerStyle } from "./style";
import searchIcon from "../../assets/icons/ic_menu_search.svg";
import locationIcon from "../../assets/icons/icon-location-map.svg";
import { Input, InputRef, Button } from "antd";
// import CustomButton from "../CustomButton/CustomButton";
import filter from "../../assets/icons/Vector.svg";
import {
  BackgroundImage,
  ChineseWhite,
  DarkElectricBlue,
  SteelBlue,
  whiteColor,
} from "../GlobalStyle";
// import { CardViewStyled } from "./styles";
import { Avatar, Dropdown, List, Rate } from "antd";
import actionMenuTogglerIcon from "../../assets/icons/ic_action_menu_toggler.svg";
import { BranchDataType } from "../../app/features/branch/branchSlice";
import { Link, useNavigate } from "react-router-dom";
import FormControl from "../../components/FormControl";
import placeHolderImage from "../../assets/images/custom_card_placeholder.png";
import FeatureVector from "../../assets/icons/Feature-Vector.svg";
import {
  darkBlue,
  fontFamilyMedium,
  lightBlue3,
  maastrichtBlue,
  pureDark,
  tertiaryBlue2,
} from "../../components/GlobalStyle";
import CustomButton from "../../components/CustomButton/CustomButton";
import AboutBanner from "../../assets/images/about_ULTM8.png";
import Bannerview from "../../assets/images/mobile-views.png";
import Scoreboard from "../../assets/images/scoreboard-img.png";
import Stripe from "../../assets/icons/stripe.svg";
import Paypal from "../../assets/icons/paypal.svg";
import GoCard from "../../assets/icons/gocardless.svg";
import DirectDebit from "../../assets/icons/credit-debit.svg";
import Visa from "../../assets/icons/visa 1.svg";
import Master from "../../assets/icons/master.svg";
import MClean from "../../assets/icons/Mclean.png";
import VectorLeft from "../../assets/icons/Vector-left .svg";
import VectorRight from "../../assets/icons/Vector-right.svg";
// import CardIcon from "../../assets/images/card-icon-Rectangle .png";
import OrganizeArrowleft from "../../assets/icons/ic_arrow_left.svg";
import OrganizeArrowright from "../../assets/icons/ic_arrow_right.svg";
import UltmPrevIcon from "../../assets/icons/ic_ultmArrowLeft.svg";
import UltmNextIcon from "../../assets/icons/ic_ultmArrowRight.svg";

function Banner() {
  const searchRef = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState();
  const [value, setValue] = useState(3);
  const imageLs = [1, 2, 3, 4];
  const Featuredimage = [1, 2, 3, 4, 5, 6];
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(searchText);
  };
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];
  return (
    <BannerStyle>
      <div className="banner">
        <div className="wrapWidth">
          <h2>Wake Up hassle-free and happier</h2>
          <p>
            Discover a World of in-Person and Virtual Fitness, Wellness, and
            Beauty Services.
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-20 mx-auto w-100 navFields">
          <div className="headerSearchField">
            <Input
              type="search"
              ref={searchRef}
              // value={searchText}
              // onChange={(e) => setSearchText(e.target.value)}
              value={searchText || ""}
              onChange={handleChange}
              prefix={
                <img
                  src={searchIcon}
                  alt="search-icon"
                  width={21}
                  height={21}
                />
              }
              placeholder="Search for Events"
              className="custom-input left-bar"
            />
          </div>
          <div className="headerSearchField">
            <Input
              type="search"
              ref={searchRef}
              value={searchText}
              placeholder="Canning Town, ENG, GB"
              onChange={handleChange}
              prefix={
                <img
                  src={locationIcon}
                  alt="search-icon"
                  width={19}
                  height={26}
                />
              }
              className="custom-input right-bar"
            />
          </div>

          <div className="filterIcon ms-3  d-flex h-50px">
            <CustomButton
              title=""
              icon={<img src={filter} alt="vector" width={20} height={20} />}
              type="button"
              bgcolor={ChineseWhite}
              width="60px"
              color=""
              borderRadius="8px"
              margin="0 15px 0 0"
              padding="10px 0 0 -1px"
            />
          </div>
        </div>
      </div>

      <div className="Feature-Event">
        <h3>
          FEATURED <span>EVENTS</span>
        </h3>
        <p>Event organizers worldwide.</p>
        <div className="custom_card_list d-flex align-items-center">
          {imageLs.map((item) => {
            return (
              <div className="custom_card" key={item}>
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar />}
                    title={"Apex Martial Arts Academy"}
                    description="Hutton, United Kingdom"
                  />
                </List.Item>
                <div className="custom_card_placeholder_img" id="cardImg">
                  <img src={placeHolderImage} alt="CardImg" id="cardImg" />
                </div>
                <div className="custom_card_para">
                  Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </div>
                <div className="custom_card_footer">
                  <div className="custom_card_footer_rating">
                    <Rate tooltips={desc} onChange={setValue} value={value} />
                    {value ? (
                      <span className="ant-rate-text">
                        {/* {desc[value - 1]} */}
                        <span>4.6</span>
                        <span> (780+ )</span>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <Link to={"#"} className="custom_card_footer_link">
                    Learn More
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
        <div className="horizentalBreakLine"></div>
        <h4 className="exploreMoreText mt-20">Explore For More</h4>
      </div>

      <div className="Feature-Card">
        <div className="FeatureCardWrapper">
          <h3 className="text-center">
            OUR <span>EVENT</span>
          </h3>
          <p className="text-center">Event organizers worldwide.</p>
          <div className="Featured_card_list d-flex flex-wrap justify-content-center ">
            {Featuredimage.map((item) => {
              return (
                <div
                  className="Featured_card d-flex align-items-start"
                  key={item}
                >
                  <List.Item>
                    <img src={FeatureVector} alt="" width={80} height={25} />
                    <List.Item.Meta avatar={<Avatar />} />
                  </List.Item>
                  <div className="Featured_card_para">
                    <h2>CONVENIENT TIME</h2>
                    <p>
                      Norem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc vulputate libero et velit interdum, ac aliquet odio
                      mattis.
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <a className="mt-5 Explore">Explore For More</a>
        </div>
      </div>

      <div className="About-main">
        {/* <img src={AboutBanner} alt="banner" /> */}
        <div className="bg-banner">
          <div className="aboutWrapper">
            <div className="Organizer-banner-right">
              <div className="Organizer-banner-right_inner">
                <div className="aboutHeading">
                  <h1>ABOUT ULTM8</h1>
                  <p className="about-text">Event organizers worldwide.</p>
                </div>
                <div className="about-para">
                  <span>I must explain to you how all this mistaken idea</span>
                  <p>
                    Nor again is there anyone who loves or pursues or desires to
                    obtain pain of itself, because it is pain, but because
                    occasionally circumstances occur in which toil and pain can
                    procure him some great pleasure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Banner-view">
        <div className="mobaileAplic_heading">
          <h2>
            MOBILE <span>APPLICATION</span>
          </h2>
          <p>Event organizers worldwide.</p>
        </div>

        <p>
          I must explain to you how all this mistaken idea Nor again is there
          anyone who loves or pursues or desires to obtain pain of itself,
          because it is pain, but because occasionally circumzstances occur in
          which toil and pain can procure him some great pleasure.
        </p>

        <img src={Bannerview} alt="Image" className="Banner-img" />
      </div>

      <div className="Organizer-main">
        <div className="Organizer-banner">
          <h1>What Our Organization Said</h1>
          <p>Event Organizers Worldwide.</p>
        </div>
        <div className="MClean_main">
          <div className="MClean-img d-flex">
            <img src={MClean} alt="" />
            <div className="MClean-text">
              <h6 className="text-white m-0 ">O’Neil McLean</h6>
              <p className="text-white m-0">IBJJF Event</p>
            </div>
          </div>
          <div className="Organizer-content">
            <p>
              I must explain to you how all this mistaken idea Nor again is
              there anyone who loves or pursues or desires to obtain pain of
              itself, because it is pain, but because occasionally circumstances
              occur in which toil and pain can procure him some great pleasure.
            </p>
            <div className="organizeArrowBtns">
              <div className="leftIcon">
                <img src={OrganizeArrowleft} alt="" />
              </div>
              <div className="rightIcon">
                <img src={OrganizeArrowright} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="scoreboard">
        <div className="scoreboard_wrapper">
          <div className="Scoreborad-left">
            <h1>
              ULTM8 SCORE <span>BOARD</span>{" "}
            </h1>
            <p className="scoreboard-text">Event organizers worldwide.</p>
            <p className="scoreboard-para">
              I must explain to you how all this mistaken idea Nor again is
              there anyone who loves or pursues or desires to obtain pain of
              itself, because it is pain, but because occasionally
              circumzstances occur in which toil and pain can procure him some
              great pleasure.
            </p>
          </div>
          <div className="Scoreboard-right">
            <img src={Scoreboard} alt="" />
          </div>
        </div>
      </div>

      <div className="payment">
        <div className="payment-content">
          <h1>
            Various Payment <span>Method</span>{" "}
          </h1>
          <h4>Event organizers worldwide.</h4>
          <p>
            Martial App empowers academy owners with a simple user experience
            that frees up the time for them to teach, retain current memberships
            and find news customers.
          </p>
        </div>

        <div className="payment-gateway">
          <div className="Stripe">
            <img src={Stripe} alt="" />
          </div>
          <div className="Paypal">
            <img src={Paypal} alt="" />
          </div>
          <div className="GoCard">
            <img src={GoCard} alt="" />
          </div>
          <div className="DirectDebit">
            <img src={DirectDebit} alt="" />
          </div>
          <div className="Visa">
            <img src={Visa} alt="" />
          </div>
          <div className="Master">
            <img src={Master} alt="" />
          </div>
        </div>
      </div>
    </BannerStyle>
  );
}

export default Banner;

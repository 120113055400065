import React from 'react'
import {OrganizerCreateAnEventCouponStyle} from "./style"

const OrganizerCreateAnEventCoupon = () => {
  return (
    <div>
        <OrganizerCreateAnEventCouponStyle>

        </OrganizerCreateAnEventCouponStyle>
    </div>
  )
}

export default OrganizerCreateAnEventCoupon
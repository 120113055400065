import styled from "styled-components";
import {
  AlabamaCrimson,
  AmericanYellow,
  AppleGreen,
  Arsenic,
  CharlestonGreen,
  Dandelion,
  DarkCornflowerBlue,
  DarkGreen,
  IguanaGreen,
  MustardYellow,
  SilverSand,
  SpringFrost,
  TangerineYellow,
  VenetianRed,
  fontFamilyBold,
  fontFamilyBoldUpber,
  fontFamilyFuturaCondenseBold,
  fontFamilyMedium,
  fontFamilyMediumUber,
  fontFamilyRegular,
  lightColor1,
  lightDark3,
  pureDark,
  whiteColor,
} from "../../../components/GlobalStyle";
export const UltmScroreBoardstyle = styled.div`

#ultmScoreBoard-fullscreen {
	font-family: ${fontFamilyBold};
	text-transform: uppercase;
	font-weight: 500;
	line-height: 1.1;
	background: #1e1f23;
}

#ultmScoreBoard-fullscreen * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.clickable {
	cursor: pointer!important;
}


#ultmScoreBoard-fullscreen {
	position: relative;
	overflow: hidden;
	min-width: 132vh;
	touch-action: pan-x pan-y;
}





#ultmScoreBoard-fullscreen .control {
	position: absolute;
    top: 74%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    z-index: 6;
    transform-origin: 0px 50%;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
	z-index: 9999;
}

#ultmScoreBoard-fullscreen .control .actions {
	display: flex;
	border-radius: .8vh;
	transition: box-shadow 1s;
	font-size: 3vh;
}

#ultmScoreBoard-fullscreen .control .actions td {
	position: relative;
	width: auto;
	background: rgba(0,0,0,0.6) ;
	font-size: 1em;
	padding-right: 1em;
	padding-left: 1em;
	height: 7vh;
	display: table-cell;
	white-space: nowrap;
	cursor: pointer!important;
	text-align: center;
	border-radius: 0;
	line-height: .8;
}

#ultmScoreBoard-fullscreen .control .actions td a {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

#ultmScoreBoard-fullscreen .control .actions td svg, 
#ultmScoreBoard-fullscreen .control .actions td img {
	height: 3vh;
	width: auto;
}

#ultmScoreBoard-fullscreen .control .actions {
	display: flex;
	border-radius: .8vh;
	transition: box-shadow 1s;
	font-size: 3vh;
}

#ultmScoreBoard-fullscreen .control .actions td {
	position: relative;
	width: auto;
	// background: #000;
	font-size: 1em;
	padding-right: 1em;
	padding-left: 1em;
	height: 7vh;
	display: table-cell;
	white-space: nowrap;
	cursor: pointer!important;
	text-align: center;
	border-radius: 0;
	line-height: .8;
}

#ultmScoreBoard-fullscreen .control .actions td a {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

#ultmScoreBoard-fullscreen .control .actions td svg {
	height: 3vh;
	width: auto;
}

#ultmScoreBoard-fullscreen .control .actions td.btn {
	border: 0;
}

#ultmScoreBoard-fullscreen .control .actions td:hover {
	box-shadow: inset 0 0 1px 2px #3d3d3d!important;
}

#ultmScoreBoard-fullscreen .control .actions td.plus {
	color: #9ee7a3;
}

#ultmScoreBoard-fullscreen .control .actions td.minus {
	color: #c17b7b;
}

#ultmScoreBoard-fullscreen .control .actions td.active {
	background: #464646;
}

#ultmScoreBoard-fullscreen .control .actions td.inactive {
	opacity: .5;
}

#ultmScoreBoard-fullscreen .control .actions td.submenu {
	padding: 0;
}

#ultmScoreBoard-fullscreen .control .actions td.submenu:hover {
	box-shadow: none!important;
}

#ultmScoreBoard-fullscreen .control .actions td.submenu.closed tr.secondary td {
	// display: none;
}

#ultmScoreBoard-fullscreen .control .actions td.submenu.opened tr.primary td {
	font-size: .4em;
	opacity: .3;
	height: 30%;
	vertical-align: bottom;
}

#ultmScoreBoard-fullscreen .control .actions td.submenu.opened tr.primary td:hover {
	box-shadow: none!important;
}

#ultmScoreBoard-fullscreen .control .actions td.submenu.opened tr.secondary td {
	display: table-cell;
	height: 70%;
	font-size: .8em;
	padding: 0 .5em;
}

#ultmScoreBoard-fullscreen .control .actions td.submenu table {
	height: 100%;
	width: 100%;
}

#ultmScoreBoard-fullscreen .control .actions th {
	background: #303030;
	text-align: center;
	border: 0;
	padding: .7vh;
	font-size: .9em;
}

#ultmScoreBoard-fullscreen .control .actions th .close-button {
	float: left;
	margin-left: .6vh;
}

#ultmScoreBoard-fullscreen .control .actions table {
	background: rgba(0,0,0,0.6)
	width: 100%;
	box-shadow: 0 0 5vh 0 #000;
	margin-right: 2vh;
	border-radius: .8vh;
	overflow: hidden;
}

#ultmScoreBoard-fullscreen .control .actions table:hover {
	box-shadow: 0 0 6vh 1vh #000;
	transition: box-shadow .5s;
}

#ultmScoreBoard-fullscreen .control .actions table.score-inputs tr.round td {
	padding: 0;
}

#ultmScoreBoard-fullscreen .control .actions table.score-inputs tr.round td input {
	background: 0 0;
	border: 0;
	text-align: center;
	color: inherit;
	width: 100%;
	min-width: 2.4em;
	padding: .4em 0;
}

#ultmScoreBoard-fullscreen .control .actions table.score-inputs tr.round-total td span {
	font-size: 2em;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers {
	position: relative;
	display: flex;
	flex-direction: row;
	height: 35vh;
}
#ultmScoreBoard-fullscreen #ultmScorePlayersScoreSection {
	height: 35vh;    
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .playerLeft {
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	padding-left: 2em;
	transition: all;
	background: #25262b;
}
#ultmScoreBoard-fullscreen #ultmScorePlayers > .playerRight {
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	padding-right: 2em;
	transition: all;
	background: #25262b;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .player > * {
	z-index: 1;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .playerAttributes {
	flex-wrap: wrap;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .result {
    font-size: 4.3vh;
    margin-left: 3.7vh;
    flex-basis: 100%;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: bounceInDown;
    position: absolute;
    left: 0px;
    top: 0px;
}

.doubleShowsComp {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 29vh;
    z-index: 2;
    text-align: center;
    top: 0vh;
}
.doubleShowsComp .double-show{

}
.double-show{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 25vh;
	padding: 2vh 2vh;
    z-index: 9;
    text-align: center;
}

.doubleShowsComp .double-show{
	top: 1.5vh;
}
.doubleShowsComp1 .double-show{
	top: 3vh;
	width: 45vh;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .result > span {
	background: #efd51c;
	color: #000;
	padding: 0 .4em;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .result.both > span {
	background: #fff!important;
}


#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .playerControls {
	min-width: 0;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	position: relative;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .playerAttributes {
	font-size: 14vh;
	margin-top: -.07em;
	display: flex;
	align-items: center;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .playerAttributes .attributeRows {
	display: flex;
	flex-direction: column;
	flex-basis: 0;
	flex-grow: 1;
	gap: 5px;
}
#ultmScoreBoard-fullscreen #ultmScorePlayers > .playerRight .playerAttributes .attributeRows {
	align-items: flex-end;
}



#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .playerAttributes > .clubIcon, .clubIcon {
	height: 1.6em;
	min-width: 1.6em;
	width: 1.6em;
	border: .03em solid #757575c7;
	margin-right: .3em;
	position: relative;
	top: .04em;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .playerAttributes .subheader {
	display: flex;
	font-size: .5em;
	line-height: 1;
}



#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .playerAttributes .subheader .clubAndTeamContainer {
	height: 1.15em;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .playerLeft .playerAttributes .subheader .clubAndTeamContainer img {
	height: 1.0em;
	width: 1.0em;
	min-width: .8em;
	border: .04em solid #fff;
	position: relative;
	// top: -.13em;
    margin-right: 0.25em;
    border-radius: 50%;
}
#ultmScoreBoard-fullscreen #ultmScorePlayers > .playerRight .playerAttributes .subheader .clubAndTeamContainer img {
	height: 1.0em;
	width: 1.0em;
	min-width: .8em;
	border: .04em solid #fff;
	position: relative;
    border-radius: 50%;
	// top: -.13em;
    margin-left: 0.25em;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .playerAttributes .subheader .clubAndTeamContainer > div {
	transform-origin: 50% 0;
	position: relative;
}
.playerLeft .clubAndTeamContainer {
	display: flex;
	flex-direction: row;
}
.playerRight .clubAndTeamContainer {
	display: flex;
	flex-direction: row-reverse;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .playerAttributes .subheader .clubAndTeamContainer > div:nth-child(2) {
	transform-origin: 0% 100%;
	// transform: translateY(calc(-100% - 0.04em));
}


#ultmScoreBoard-fullscreen #ultmScorePlayers > .playerLeft .playerAttributes .subheader .countryName {
	min-width: 1.5em;
	margin-right: .35em;
	text-align: center;
}
#ultmScoreBoard-fullscreen #ultmScorePlayers > .playerRight .playerAttributes .subheader .countryName {
	min-width: 1.5em;
	margin-left: .35em;
	text-align: center;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers > .ultmPlayer .playerAttributes .flag {
	height: .78em;
	top: -.12em;
	position: relative;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers .scoresContainer {
	order: 2;
	display: flex;
	height: 100%;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers .mainScore {
	font-family: ${fontFamilyMediumUber};
	font-weight: 700;
	font-size: 33vh;
	line-height: 1;
	width: .9em;
	text-align: center;
	order: 2;
	display: flex;
	align-items: center;
	margin-left: .1em;
	position: relative;
	// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50);
}

#ultmScoreBoard-fullscreen #ultmScorePlayers .mainScore div {
	width: 100%;
	text-align: center;
}


#ultmScoreBoard-fullscreen #ultmScorePlayers .mainScore .count {
	width: 100%;
	position: relative;
	// top: -.02em;
	font-family: ${fontFamilyFuturaCondenseBold};
}

#ultmScoreBoard-fullscreen #ultmScorePlayers .mainScore .count.digits-2 {
	font-family: ${fontFamilyFuturaCondenseBold};
	font-size: .7em;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers .mainScore .count.digits-3 {
	font-family: ${fontFamilyFuturaCondenseBold};
	font-size: .46em;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers .extraScores {
	font-size: 2vh;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: wrap-reverse;
	padding-top: 2vh;
	padding-bottom: 2vh;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers .extraScores > div {
	// opacity: .6;
	margin-left: 0vh;
	padding: 0px 2vh;
}

#ultmScoreBoard-fullscreen #ultmScorePlayers .extraScores .points {
	font-size: 12vh;
	font-family: ${fontFamilyFuturaCondenseBold};
	font-weight: 700;
	line-height: 1;
    color: #fff;
}
#ultmScoreBoard-fullscreen #ultmScorePlayers .extraScores .adv{
    // color: #f5de2e;
	color: #eddb2e;
	font-family: ${fontFamilyFuturaCondenseBold};
	// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#ultmScoreBoard-fullscreen #ultmScorePlayers .extraScores .pen{
    color: #ec0001;
	// color: #d8363c;
	font-family: ${fontFamilyFuturaCondenseBold};
	// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
}
#ultmScoreBoard-fullscreen #ultmScorePlayers .extraScores  >  div  div + div{
    color: #fff;
    font-size: 2.8vh;
}
.messagesShows .both .winner-text{
	color: ${pureDark};
    font-family: ${fontFamilyBold};
    font-size: 8.1vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.31px;
    text-transform: uppercase;
    padding: 0.6vh 1.5vh;
	background: #fff !important;
}
.messagesShows .submission .winner-text{
	color: ${pureDark};
    font-family: ${fontFamilyBold};
    font-size: 8.1vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.31px;
    text-transform: uppercase;
    padding: 0.6vh 1.5vh;
	background: #fdba13!important;
}

.messagesBody{
	display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    justify-content: center;
}
.messagesBody .playerAttr .attrRows{
	text-align: center;
}
.messagesBody  .playerName{
	font-size: 17vh;
	width: 100%;
	max-width: 100%;
}
.messagesBody .playerAttr .subheader {
	font-size: 9vh;
}
.messagesBody  .playerName img{
	vertical-align: middle;
}
.messagesBody .playerDetails{
	margin-top: 1vh;
}
.messagesBody .trad-header .name{
	color: ${whiteColor};
	font-family: ${fontFamilyMedium};
	font-size: 15vh;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom {
	position: relative;
	height: 30vh;
	display: flex;
	background: #1e1f23;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom > * {
	z-index: 1;
}
#ultmScoreBoard-fullscreen .tradScoreboardBottom .matchNumber {
	max-width: 14.8%;
    width: 100%;
}
#ultmScoreBoard-fullscreen .tradScoreboardBottom .matchNumber {
	// background: #0B0A08;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 2vh;
	white-space: nowrap;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom .matchNumber span {
	font-size: 18vh;
}
.matchNumber .digits-1{
	font-size: 18vh;
}
.matchNumber .digits-2 {
	font-weight: ${fontFamilyMediumUber};
	font-size: .7em;
}

.matchNumber .digits-3 {
	font-weight: ${fontFamilyMediumUber};
	font-size: .46em;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom .tradScoreboardBottom-controls {
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	overflow-x: hidden;
	padding-left: 4vh;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom #clock {
	font-size: 28vh;
	line-height: 1;
	white-space: nowrap;
	text-align: right;
	margin-top: 1vh;
	margin-bottom: 1vh;
	margin-right: 1vh;
	cursor: pointer!important;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom #clock .control .actions table {
	margin-right: 0;
}
#clock .count{
    display: flex;
	justify-content: center;
    align-items: center;
    margin-right: 3vh;
    text-align: center;
	height: 30vh;
	background: ${pureDark};
	
}
.count p{
    display: inline-block;
    color: rgb(237, 219, 46);
    font-family: ${fontFamilyFuturaCondenseBold};
	vertical-align: top;
    display: inline-table;
    text-align: center;
    font-size: 29vh;
    line-height: 1;
    white-space: nowrap;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-right: 1vh;
    letter-spacing: 1px;
    background-color: rgb(0, 0, 0);
    position: relative;
    z-index: 9999;
    cursor: pointer !important;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom #clock {
	font-size: 28vh;
	line-height: 1;
	white-space: nowrap;
	text-align: center;
	margin-top: 1vh;
	margin-bottom: 1vh;
	margin-right: 1vh;
	cursor: pointer!important;
	max-width: 70vh;
	width: 100%;
	position: relative;
}


#ultmScoreBoard-fullscreen .tradScoreboardBottom .matchInfoContainer {
	display: flex;
	height: 100%;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom .matchInfo {
	font-size: 7vh;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	flex-grow: 1;
	overflow: hidden;
	padding-left: 4vh;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom .matchInfo .matchInfoText {
	display: table-cell;
	vertical-align: middle;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom .matchInfo .logo svg,#ultmScoreBoard-fullscreen .tradScoreboardBottom .matchInfo .logo img {
	height: 24vh;
	width: auto;
	max-width: 25.5vh;
	margin-right: 4vh;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom .matchInfo .bracket-info {
	display: block;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom .matchInfo .bracket-info > * {
	margin-right: 2vh;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom .matchInfo .clock-alert {
	font-size: 28vh;
	color: #fdba13;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom .match-controls {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 2vh;
	display: flex;
	align-items: center;
}

#ultmScoreBoard-fullscreen .tradScoreboardBottom .match-controls .control {
	position: relative;
	top: unset;
	bottom: unset;
	left: unset!important;
}

#ultmScoreBoard-fullscreen.hide-advantages .control .actions .actions-wrapper .btn.point[data-type=advantage] {
	// display: none;
}

#ultmScoreBoard-fullscreen.hide-advantages .extraScores .advantage {
	// display: none;
}

#ultmScoreBoard-fullscreen.whiteblack #ultmScorePlayers > .player:first-child .mainScore {
	background-color: #000;
}

#ultmScoreBoard-fullscreen.whiteblack #ultmScorePlayers > .player:last-child .mainScore {
	background-color: #fff;
}

#ultmScoreBoard-fullscreen.whiteblack .mainScore {
	color: #a2a2a2!important;
}


#ultmScoreBoard-fullscreen .control.won-by .actions table.actions-wrapper:first-child {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

#ultmScoreBoard-fullscreen .control.won-by .actions table.actions-wrapper:last-child {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.scMarquee .marqueeContainer {
	overflow: hidden;
	display: flex;
	margin-left: -4vh;
	padding-left: 4vh;
	transition: .2s all ease;
}

.scMarquee .marqueeContainer.animate {
	-webkit-mask-image: linear-gradient(to left,transparent,black 10vh,black calc(100% - 4vh),transparent);
	mask-image: linear-gradient(to left,transparent,black 10vh,black calc(100% - 4vh),transparent);
}

.scMarquee .marqueeContainer.animate .marqueeText {
	padding-right: 20vh;
}

.scMarquee .marqueeContainer .marqueeText {
	margin: 0;
	display: inline-block;
	white-space: nowrap;
	position: relative;
}

.scMarquee .marqueeContainer.animate .marqueeText {
	-webkit-animation: marquee 15s linear infinite;
	animation: marquee 15s linear infinite;
}

@-webkit-keyframes marquee {
	0% {
		transform: translate3D(0,0,0);
	}

	20% {
		transform: translate3D(0,0,0);
	}

	100% {
		transform: translate3D(-100%,0,0);
	}
}

@keyframes marquee {
	0% {
		transform: translate3D(0,0,0);
	}

	20% {
		transform: translate3D(0,0,0);
	}

	100% {
		transform: translate3D(-100%,0,0);
	}
}

.scMarquee.primary {
	color: #d0ac13;
}

.scMarquee.primary .muted {
	opacity: .5!important;
	font-size: .7em;
	margin-left: -.3em;
}

.scMarquee.muted {
	color: #757575;
}

.playerName {
    color: #fff;
}
.clubName, .countryName {
    color: #C2C2C2;
}
.bracketDivision .marqueeText{
    color: rgba(231, 234, 255, 0.9);
}
bracketRound{

}
#ultmScorePlayers .playerLeft {
    background-color: #3D3E42 !important;
}
#ultmScorePlayers .playerRight{
    background-color: #202125 !important;
}
#ultmScoreBoard-fullscreen .tradScoreboardBottom{
    background-color: #000 !important;
}
#ultmScorePlayers .playerLeft .mainScore{
    background: #AF0026 ;
	// background: #ba343c;
    color: #fff !important;
}
#ultmScorePlayers .playerRight .mainScore{
    // background: #1E409C ;
	background: #1e409b;
    color: #fff !important;
}
#ultmScoreBoard-fullscreen #ultmScorePlayers .mainScore{
    margin-left: 0 !important;
}
#ultmScoreBoard-fullscreen #ultmScorePlayers .extraScores{
    background-color: #333;
    padding: 2vh !important;
	width: 17vh
}

#ultmScorePlayers .playerLeft .playerName, #ultmScorePlayers .playerRight .playerName {
    display: flex;
    align-items: flex-end;
}
#ultmScorePlayers .playerRight .playerName{
	justify-content: flex-end;
}
#ultmScorePlayers .playerLeft .playerName .name{
	margin-left: 0.3em;
	font-size: 14vh;
}
#ultmScorePlayers .playerRight .playerName .name{
    margin-right: 0.3em;
	margin-left: 0.2em;
	text-align: right;
	font-size: 14vh;
}
.clubAndTeamContainer .clubName{
    color: #c2c2c2 !important;
}
.playerRight .clubAndTeamContainer > .clubFlag {
	width: 1.5em;
}
.playerRight .clubAndTeamContainer > .clubName {
	text-align: right;
	margin-left: 0.2em;
}

.clubAndTeamContainer .clubName, .clubAndTeamContainer .team-name{
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.countryName{
    color: #fff !important;
}

.playersSection {
    display: flex;
    height: 50%;

    position: relative;
    display: flex;
    flex-direction: column;
}
.playersSection .player{
    max-width: 50%;
    width: 100%;
}
.playersScoreSection{
    display: flex;
    height: 50%;
    flex-direction: row;
}
.playersScoreSection .player{
    max-width: 50%;
    width: 100%;
    padding-left: 0 !important;
}
.playersScoreSection .ultmPlayer .extraScores{
    width: 20% !important;
}
.playersScoreSection .playerRight .extraScores {
    order: 2;
}
.playersScoreSection .ultmPlayer  .mainScore {
    width: 80% !important;
}
#ultmScoreBoard-fullscreen .playersScoreSection .scoresContainer{
    width: 100% !important;
}
#ultmScorePlayers .playerLeft, #ultmScorePlayers .playerRight{
    max-width: 50%;
    width: 100%;
}
.playersScoreSection .playerRight{
	padding-right: 0 !important;
}


.score2{
    position: relative;
	display: flex;
    justify-content: flex-end;
    align-items: center;
	border-radius: 1vh;
	margin-top: 11vh;
	z-index: 999 !important;
	animation: myfirst 1.5s linear infinite;
    .sec1{
		text-align: center;
		font-size: 18vh;
		border-radius: 1vh;
		z-index: 5;
		padding: 0;
		// height: 1.7em;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 41.1vh;
		h2{
			color: ${DarkGreen};
			text-align: center;
			font-family: ${fontFamilyFuturaCondenseBold};
			font-size: 9.5vh;
			font-style: normal;
			font-weight: 400;
			text-transform: uppercase;
			line-height: 9vh;
			margin-bottom: 2vh;
		}
		p{
			color: ${DarkGreen};
			text-align: center;
			font-family: "OperaMedium";
			font-size: 4vh;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-transform: uppercase;
			line-height: 4vh;
		}
    }
    .boardScorePlayer2{
        border-radius: 1vh;
		position: absolute;
		right: 0px;
		top: 0vh;
		width: 25.1vh;
		height: 22vh;
		border-radius: 1vh;
		animation: myfirst 1.5s linear infinite;
		filter: drop-shadow(0 0 0.2vh rgba(0,0,0,0.8));
		flex-direction: row;
		z-index: 2 !important;
        .scoreBoardTable{
            border-radius: 1vh;
			// width: 25.1vh;
			// height: 15vh;
			flex-direction: row;
            .ScoreBoardTableInner{
				width: 25.1vh;
				height: 15vh;
				flex-direction: row;
                .ScoreBoardTableInner1{
					flex-direction: row;
                    .ScoreBoardTableInner11{
                        padding: 1vh 2vh;
                        p{
                            color: rgb(255, 255, 255);
                            text-align: center;
                            font-family: ${fontFamilyMediumUber};
                            font-size: 2vh;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: uppercase;
                            margin-bottom: 0;
							filter: drop-shadow(0 0 0.2vh rgba(0,0,0,0.8));
                        }
						p img {
							transform: rotate(270deg);
							-webkit-transform: rotate(270deg);
							margin-left: 0.5vh;
							margin-bottom: 1vh;
							position: relative;
							top: 3px;
							left: 2px;
							width: 9px;										
						}	
                    }
                }
                .ScoreBoardTableInner2{
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 12.7vh;
					min-height: 0;
                    .ScoreBoardTableInner21,
					.ScoreBoardTableInner22,
					.ScoreBoardTableInner23{
						display: inline-block;
                        position: relative;
                        text-align: center;
                        padding: 1vh 2vh;
						min-height: 0;
                        img:first-child{
                        }
                        img{
                            width: 2vh;
                            height: 2vh;
							filter: drop-shadow(0 0 0.2vh rgba(0,0,0,0.8));
                        }
                        p{
                            color: rgb(255, 255, 255);
                            text-align: center;
                            font-family: "UberMove-Bold";
                            font-size: 1.2vh;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: uppercase;
                            position: absolute;
                            left: 0px;
                            right: 0px;
                            top: 11vh;
                            margin: 0px;
							filter: drop-shadow(0 0 0.2vh rgba(0,0,0,0.8));
                        }
                        button{
                            background-color: transparent;
                            border: none;
                            outline: none;
							display: flex;
							align-items: center;
							justify-content: space-between;
							height: 5vh;
							min-height: 0;
                            img{
                                width: 2vh;
                                width: 2vh;
                                object-fit: cover;
								position: relative;
								top: 1vh;
                            }
                        }
                        
                    }
                    
                }
                .ScoreBoardTableInner3{
                    .ScoreBoardTableInner31{
                        padding: 1vh 2vh;
						text-align: center;
						marign: auto;
                        p{
							color: rgb(255, 255, 255);
							text-align: center;
							margin: auto;
							font-size: 2vh;
							font-family: ${fontFamilyMediumUber};
							font-style: normal;
							font-weight: 700;
							line-height: normal;
							text-transform: uppercase;
							margin-bottom: 0px;
							text-shadow: 1px 1px 4px rgba(0,0,0,1);
                        }
						img{
							text-align: center;
							margin: auto;
						}
                    }
                }
            }
        }
    }
}

.varScore2 {
	top: 42.5vh;
    z-index: 2 !important;
    left: 0;
    right: 0;
    margin: auto;
    text-align: -webkit-center;
    text-align: center;
    position: absolute !important;
    display: block !important;
    width: 25.1vh;
    height: 22vh;
}
.varScore2 h2{
    font-size: 12.5vh !important;
    line-height: 9.5vh !important;
    font-family: ${fontFamilyMedium} !important;
  }
  .varScore2 p{
    font-size: 4vh !important;
    line-height: 3.5vh !important;
    margin-bottom: 0;
  }

@keyframes myfirst {
    0% {
      box-shadow: 0 0 2vh ${Dandelion};
    }
    70% {
      box-shadow: none;
    }
    100% {
      box-shadow: 0 0 2vh ${Dandelion};
    }
  }
  @-webkit-keyframes myfirst {
    0% {
      box-shadow: 0 0 2vh ${Dandelion};
    }
    70% {
      box-shadow: none;
    }
    100% {
      box-shadow: 0 0 2vh ${Dandelion};
    }
  }
  @-moz-keyframes myfirst {
    0% {
      box-shadow: 0 0 2vh ${Dandelion};
    }
    70% {
      box-shadow: none;
    }
    100% {
      box-shadow: 0 0 2vh ${Dandelion};
    }
  }

.playerRight .ScoreBoardTableInnerRight p img {
	transform: rotate(90deg) !important;
	-webkit-transform: rotate(90deg);
	margin-right: 1vh;
	margin-bottom: 1.2vh !important;								
}
.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}
.medical{
    background-color: ${SpringFrost};
}
.stalling{
    background-color: ${AmericanYellow};
}
.timeout{
    background-color: ${AmericanYellow};
}
.varAssistant{
	// background-color: ${AmericanYellow};
	// background: linear-gradient(180deg, #FFCE00 27.63%, rgba(255, 206, 0, 0) 141.45%);
	background: linear-gradient(180deg, #FFCE00 75.63%, rgba(255, 206, 0, 0) 141.45%);
	width: 25.1vh;
}

#ultmScorePlayers .playerLeft .score2{
	// position: absolute !important;
	right: 0;
	bottom: 0px;
    margin-bottom: 2vh;
	margin-right: 1vw;
}
#ultmScorePlayers .playerRight .score2{
	// position: absolute !important;
	// left: 1vw;
	bottom: 0px;
    margin-bottom: 2vh;
	margin-left: 1vw;
}

.boardScorePlayer2 {
	opacity: 0;
}
.score2 .sec1:hover{
	opacity: 1;
	box-shadow: rgba(0, 0, 0, 0.6) -1px -1px 4vh 2vh inset;
	z-index: 99;
}
.score2 .sec1:hover .boardScorePlayer2{
	opacity: 1;
}
.matchControls123{
    position: absolute;
    top: 9vh;
    left: 2vh;
}
.wonbyLight {
    position: absolute;
    z-index: 9;
    width: 100%;
    top: 4vh;
}

.wonbyLight table{
	position: absolute;
	z-index: 9;
	width: 100%;
	top: 11vh;
}

.wonbyLight .scoreBoardTable table tr:nth-child(1) td{
    background: rgb(175, 0, 38);
    color: rgb(255, 255, 255) !important;
}
.wonbyDark .scoreBoardTable table tr:nth-child(1) td{
	background: rgb(30, 64, 155);
    color: rgb(255, 255, 255) !important;
}

.wonbyDark table{
	position: absolute;
	z-index: 9;
	width: 100%;
	top: 14.7vh;	
	right: 2em;
	left: auto;
}

.endGameBottom{
	position: absolute;
	top: 9vh;
	left: 4vh;
}
.table2Right .boardScoreAdd{
    left: auto;
    right: 5vh;
    display: flex;
    justify-content: flex-end;
	top: 22vh;
}
.table1Left .boardScoreAdd{
	left: 3vh;
	top: 22vh;
}

.boardScoreAdd table{
	width: 100%;
	max-width: 58vh;
}
.boardScoreAdd table tr td table {
    border-spacing: 0.5vh 0.5vh;
}
.boardScoreAdd table tr td table tr td{
	padding: 0.5vh 1vh;
	font-size: 2.5vh;
}
.score2 .sec1{
	width: 25.1vh;
    height: 22vh;
}
.score2 .sec1 h2{
	font-size: 9vh;
	line-height: 8vh;
}
.score2 .sec1 p{
	font-size: 3.5vh;
	line-height: 3vh;
	margin-bottom: 0;
	max-height: 5vh; 		
}
.score2 .boardScorePlayer2, .boardScorePlayer2 .scoreBoardTable, .boardScorePlayer2 .scoreBoardTable .ScoreBoardTableInner{
	// width: 25.1vh;
    // height: 22vh;
}
.scoreBoardTable table tr:nth-child(3) td{
	font-size: 2.9vh;
}

@media screen and (max-width: 34vh){
    div#ultmScoreBoard-fullscreen{
        width: 34vh;
        overflow-x: auto;
        overflow-y: hidden;
    }

}

    


`;

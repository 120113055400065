import React from "react";
import { OrganizerSettingEventTopListsStyle } from "./style";
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import StatusActiveError from "../../../assets/images/activeBtnError.svg";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import OrganizerSettingLeft from "../../../components/OrganizerSettingLeft/OrganizerSettingLeft/OrganizerSettingLeft";
import TdDragDropIcon from "../../../assets/icons/ic_DragDropIcon.svg";
import { GlobalContainer } from "../../Home/style";
import { Layout } from "antd";
const { Content } = Layout;

const OrganizerSettingEventTopLists = () => {
  return (
    <div>
      <OrganizerSettingEventTopListsStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="contentBeforeLogin-left">
                <OrganizerSettingLeft />
              </Content>
              <Content className="contentBeforeLogin-right">
                <h4 className="title">Event Top Lists</h4>
                <div className="tablelist">
                  <table>
                    <tr>
                      <th></th>
                      <th>List</th>
                      <th>Type / Group by</th>
                      <th>Last calculated</th>
                      <th>Published</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                    {Array.from({ length: 3 }, (_, index) => (
                      <tr>
                        <td>
                          <img src={TdDragDropIcon} alt="TdDragDropIcon" />
                        </td>
                        <td>Nations</td>
                        <td>Nations</td>
                        <td>
                          <p>
                            8 hours ago <span>Forcefully Update</span>
                          </p>
                        </td>

                        <td>
                          <label className="Published">Published</label>
                        </td>
                        <td>
                          <label className="Sent">Sent</label>
                        </td>
                        <td className="actions">
                          <img
                            src={actionMenuTogglerIcon}
                            alt="actionMenuTogglerIcon"
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <img src={TdDragDropIcon} alt="TdDragDropIcon" />
                      </td>
                      <td>Nations</td>
                      <td>Nations</td>
                      <td>
                        <label className="Update-scheduled">
                          Update scheduled
                        </label>
                      </td>

                      <td>
                        <label className="Unpublished">Unpublished</label>
                      </td>
                      <td>
                        <label className="Sent">Sent</label>
                      </td>
                      <td className="actions">
                        <img
                          src={actionMenuTogglerIcon}
                          alt="actionMenuTogglerIcon"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerSettingEventTopListsStyle>
    </div>
  );
};

export default OrganizerSettingEventTopLists;

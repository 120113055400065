import styled from "styled-components";
import { fontFamilyRegularUber, lightDark2, pureDark2 } from "../../../components/GlobalStyle";

export const AffiliateAllStatusStyle = styled.div`
.wrapper {
    width: 100%;
    display: flex;

    .bodyLeftSideMain {
        width: 25%;
        margin-right: 36px;
    }

    .affiliationsMain {
        width: 100%;
        margin-top: 11px;
        // padding: 10px 0 14.38px;
    }

    .BlocksHeadings{
        display: flex;
        align-items: center; 
        justify-content: space-between;
    
        h4{
            color: ${pureDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.56px;
            margin-bottom: 0;
        }
        span {
            color: ${lightDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 13.217px;
            letter-spacing: 0.28px;
            cursor: pointer;
        }
    }

    .affiliationsCards {
        margin-top: 10px;
        gap: 11.68px 10.51px;
    }
    .affiliationsCards > div {
        width: 100%;
    }

    .mapFrame {
        max-width: 357px;
        width: 100%;
        border-radius: 0px 0px 9px 9px;
        border: 0px solid #E0E0E0;
        margin-top: 21px;
    }

}

.bodyRightSideMain {
    width: 75%;

    .introductionParagraph {
        width: 100%;
    }
    
    .introBox{
        border-radius: 5px;
        margin-top: 10px;
        border: 0.93px solid #E0E0E0;
        background: #FFF;
        padding: 14px 12px 10px 17px;
    }
    .introductionParagraph h6 {
        color: ${pureDark2};
        text-align: justify;
        font-family: ${fontFamilyRegularUber};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
    }
    .introBox p {
        color: ${lightDark2};
        text-align: justify;
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
    }

}

.StudentsWrapper{
    .studentTitle{
        color: ${pureDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.56px;
        margin-bottom: 0;
    }

    .studentHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #EFEFEF;
        padding: 8px;

        p {
            color: ${pureDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.56px;
            margin-bottom: 0;
        }

        span {
            color: ${lightDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.56px;
            margin-bottom: 0;
            cursor: pointer;
        }

    }
}



`;
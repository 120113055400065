import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium } from "../../../components/GlobalStyle";


export const OrganizerSettingStatisticsMatchStyle = styled.div`
.bodyWrapper_RightSide {
    width: 100%;
    padding-left: 35px;
}
.mainContainer {
    width: 100%;
    margin-top: 16px;
}
.sectionTitle h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.tableContainer {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #E0E0E0;
    background-color: #FFF;
    margin-top: 23px;
    padding-left: 1px;
}
table {
    width: 100%;
}
table thead tr th {
    padding: 21px 0 20px 10px;
    color: #061229;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
table tbody tr td {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
table thead tr th:first-child {
    border-top-left-radius: 10px;
}
table thead tr th:last-child {
    border-top-right-radius: 10px;
}
table tbody tr td {
    padding: 9px 0px 11px 15px;
}
table thead tr th:first-child,
table tbody tr td:first-child {
    padding-left: 22px;
    width: 3.45%;
}
table thead tr th:nth-child(2),
table tbody tr td:nth-child(2) {
    width: 34%;
}
table thead tr th:nth-child(3),
table tbody tr td:nth-child(3) {
    width: 34%;
}
table thead tr th:nth-child(4),
table tbody tr td:nth-child(4) {
    width: 18%;
}
table thead tr th:last-child,
table tbody tr td:last-child {
    padding-right: 13px;
    width: 10.55%;
    text-align: end;
    padding-right: 17px;
}
table thead tr th:last-child, 
table tbody tr td:last-child {

}
table tbody tr td:last-child {
    padding-right: 38px;
}
table tbody tr:nth-child(odd) {
    background-color: #FAFAFA;
}
table tbody tr:nth-child(even) {
    background-color: #FFF;
}
.hamBurger {
    width: 13px;
    height: 10px;
    cursor: pointer;
}
.groupBracket {
    display: flex;
    flex-direction: column;
}
.groupBracket p {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.groupBracket span {
    color: #A8A9AD;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 2.2px;
}
.match span {
    color: #A8A9AD;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.actions img {
    cursor: pointer;
}
`;
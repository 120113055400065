import React from "react";
import { OrganizerSettingTiebreakerSettingsStyle } from "./style";
import { GlobalContainer } from "../../Home/style";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import ShowPublicResultToggleBtn from "../../../assets/icons/ShowPublicResult-ToggleBtn.svg";
import { Form, Layout } from "antd";
import { Row, Col } from "react-bootstrap";
import { Content } from "antd/lib/layout/layout";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Formik } from "formik";
import FormControl from "../../../components/FormControl";
import { Switch } from "antd";

const OrganizerSettingTiebreakerSettings = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };
  const handleCreateSubmit = (): void => {};
  return (
    <div>
      <OrganizerSettingTiebreakerSettingsStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="OrganizerSettingTiebreakerSettings-left">
                <CreatEventLeftSide />
              </Content>
              <Content className="OrganizerSettingTiebreakerSettings-right">
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={handleCreateSubmit}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="d-flex align-items-end justify-content-between pe-2 title_showPublicToggle">
                          <div className="d-flex gap-4 title">
                            <h3>Tiebreaker Settings</h3>
                            <span>for round robin</span>
                          </div>
                          <div className="bg-white d-flex align-items-center justify-content-between toggleBtn">
                            <p>Show Public Tiebreaker Results</p>
                            <div className="showApproved_toggleButton">
                              <Switch className="" />
                            </div>
                          </div>
                        </div>
                        <div className="bg-white form">
                          <Row>
                            <Col md="6">
                              <div className="">
                                <FormControl
                                  control="select"
                                  type="select"
                                  name="scoringAthletes"
                                  label="Tiebreaker Setting 1"
                                  padding="10px"
                                  fontSize="16px"
                                  placeholderFont="16px"
                                  placeholderFamily="fontFamilyEnnVisionsRegular"
                                  max={6}
                                  placeholder="Christmas 2023 Coupon Discount"
                                  className={
                                    formik.errors.timeZone &&
                                    formik.touched.timeZone
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="">
                                <FormControl
                                  control="select"
                                  type="select"
                                  name="crossBracketing"
                                  label="Tiebreaker Setting 2"
                                  padding="10px"
                                  fontSize="16px"
                                  placeholderFont="16px"
                                  placeholderFamily="fontFamilyEnnVisionsRegular"
                                  max={6}
                                  placeholder="Christmas 2023 Coupon Discount"
                                  className={
                                    formik.errors.timeZone &&
                                    formik.touched.timeZone
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="addOpt">
                            <span>Add More Options</span>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerSettingTiebreakerSettingsStyle>
    </div>
  );
};

export default OrganizerSettingTiebreakerSettings;

import { ProfileAfterLoginMainStyle } from "./style";
import { GlobalContainer } from "../../Home/style";
import { Layout } from "antd";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import ProfileAfterLoginTabs from "../../../components/ProfileAfterLoginTabs/ProfileAfterLoginTabs";
import { AfterLoginPagesLeftSide } from "../../../components/AfterLoginPagesLeftSide/AfterLoginPagesLeftSide";

const { Content } = Layout;

const ProfileAfterLoginMain = () => {

  return (
    <ProfileAfterLoginMainStyle>
      <GlobalContainer>
        <Layout>
          <div className="banner">
            <img
              src={AcademyProfileBannerImage}
              alt="AcademyProfileBannerImage"
            />
          </div>
          <Layout>
            <Content className="contentBeforeLogin-left">
              <AfterLoginPagesLeftSide />
            </Content>
            <Content className="contentBeforeLogin-right">
              <ProfileAfterLoginTabs />
            </Content>
          </Layout>
        </Layout>
      </GlobalContainer>
    </ProfileAfterLoginMainStyle>
  );
};

export default ProfileAfterLoginMain;

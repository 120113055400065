import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../components/GlobalStyle";

export const UncomingEventCardStyle  = styled.div`


.uncomingEvenet_CardMain {
    max-width: 399px;
    width: 100%;
    border-radius: 6.141px 6.141px 0px 0px;
    border: 1.024px solid #E9E9E9;
    padding-bottom: 10px;
}
.cardImage img{ 
    width: 100%;
    border-radius: 6.141px 6.141px 0px 0px;
}
.cardHeading {
    padding: 13.61px 12.41px 0 13px;
}
.cardHeading h6 {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 16.376px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
}
.countryRatingMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7.02px 9px 0 13px;
}
.countryNameFlag {
    display: flex;
    align-items: center;
}
.countryNameFlag img {
    width: 20.26px;
    height: 13.16px;
    margin-left: 7.65px;
}
.countryNameFlag span {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 14.329px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.rating {
    display: flex;
    align-items: center;
}
.rating img {
    width: 72.37px;
    height: 11.45px;
    margin-right: 7.56px;
}
.rating p {
    color: #1C394B;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 12.282px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.244px;
    margin-bottom: 0;
}
.rating p span {
    color: #1F3340;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 12.282px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.244px;
}
.winnerName {
    display: flex;
    align-items: center;
    padding-left: 13px;
    padding-top: 12px;
}
.win {
    padding: 1px 4.88px 0.53px 5px;
    border-radius: 2.058px;
    background-color: #7BB037;    
}
.win span, .loss span {
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.winnerName p {
    padding-left: 4.54px;
}
.winnerName p, .loserName p {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 14.329px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.winnerName p span, .loserName p span {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 14.329px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.loserName {
    display: flex;
    align-items: center;
    padding-left: 13px;
    padding-top: 10.47px;
}
.loserName p {
    padding-left: 6.26px;
}
.loss {
    padding: 0px 8px 3px 8px;
    border-radius: 2.058px;
    background-color: rgb(175, 0, 38);
    display: inline;
}
.dateLearneMore {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 8.85px 0 13px;
}
.dateLearneMore span {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 12.282px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.dateLearneMore a {
    color: #1C394B;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 12.282px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.244px;
    text-decoration: none;
}
.WonBronzeMain {
    padding: 22px 0 0 13px
}
.WonBronzeLeauge {
    max-width: 98px;
    width: 100%;
    padding: 1px 11px 1px 12px;
    border-radius: 2.058px;
    
}
.eventLeauge span {
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.WonBronzeLeauge{
    background-color: #BC6B50;
}
.WonPlacement{
    background-color: #669BBC;
}
.WonGoldLeauge{
    background-color: #CDAF49;
}
`;

import "react-phone-number-input/style.css";

import { ErrorMessage, Formik } from "formik";
import { Form } from "antd";
import FormControl from "../../components/FormControl";
import CustomButton from "../../components/CustomButton/CustomButton";
import CreateUserStyle from "./style";
import {
  RobinEggBlue,
  fontFamilyRegularUber,
  pureDark2,
} from "../../components/GlobalStyle";
import Head from "../../components/Head/Head";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signup_url } from "../../utils/api_urls";
import Errormsg from "../../components/ErrorMessage";
import Input from "react-phone-number-input";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import CustomModal from "../../components/Modal/CustomModal";
import OauthLogin from "../../components/Common/OauthLogin/OauthLogin";
import { OAUTH_USECASES } from "../../components/Common/OauthLogin/constants";
import { initialValues, initialValuesType, REGISTER_YUP } from "./constant";
import MessageModal from "../../components/Common/MessageModal/MessageModal";
import api from "../../api";

interface RegisterProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoginModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegisterUser: React.FC<RegisterProps> = ({
  isModalVisible,
  setIsModalVisible,
  setIsLoginModalVisible,
}) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);

  const onSubmit = async (values: initialValuesType): Promise<void> => {
    if (!terms) {
      setShowTermsError(true);
      setTimeout(() => {
        setShowTermsError(false);
      }, 2000);
      return;
    }

    const allValues = {
      ...values,
    };
    delete allValues.confirmPassword;
    
    try {
      setIsLoading(true);
      await api("post", signup_url, allValues);
      toast(
        <MessageModal
          message="Account Created Successfully!"
          description="Thank You For Joining Us And We're Excited To Have You On Board"
          type="success"
        />,
        {
          autoClose: 1000,
        }
      );
      navigate("/");
      setIsModalVisible(false);
      setIsLoginModalVisible(true);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast(<MessageModal message={error?.response?.data?.message} type="error" />);
    }
    
  };

  return (
    <>
    <Head title="Register" />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <CreateUserStyle>
          <div className="inner-container">
            <div className="inner-container-card">
              <div className="inner-container-card-inner">
                <h6 className="title mb-0 text-center">Create Your Account</h6>
                <p className="text-center message mt-10 mb-0">
                  Please fill in the required information to complete your
                  registration and join our us.
                </p>
                <div className="inner-container-card-form">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={REGISTER_YUP}
                    onSubmit={onSubmit}
                  >
                    {(formik) => {
                      return (
                        <Form
                          name="basic"
                          onFinish={formik.handleSubmit}
                          autoComplete="off"
                        >
                          <div className="register-input-fields">
                            <div className="mt-20">
                              <FormControl
                                control="input"
                                type="text"
                                name="firstName"
                                label="First Name"
                                fontSize="14px"
                                border="none"
                                placeholder="Enter First Name"
                                labelFamily={fontFamilyRegularUber}
                                className={
                                  formik.errors.firstName &&
                                  formik.touched.firstName
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </div>
                            <div className="mt-20">
                              <FormControl
                                control="input"
                                type="text"
                                name="lastName"
                                fontSize="14px"
                                label="Last Name"
                                border="none"
                                labelFamily={fontFamilyRegularUber}
                                placeholder="Enter Last Name"
                                className={
                                  formik.errors.lastName &&
                                  formik.touched.lastName
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </div>
                            <div className="mt-20">
                              <FormControl
                                control="input"
                                type="email"
                                fontSize="14px"
                                name="email"
                                border="none"
                                label="Email"
                                labelFamily={fontFamilyRegularUber}
                                placeholder="Enter Email"
                                className={
                                  formik.errors.email && formik.touched.email
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </div>
                            <div className="mt-20">
                              <label htmlFor="phoneNumber">Phone Number</label>
                              <Input
                                defaultCountry="GB"
                                international
                                placeholder="Enter Phone Number"
                                className={
                                  formik.errors.phoneNumber &&
                                  formik.touched.phoneNumber
                                    ? "is-invalid_phone"
                                    : "custom-phone-input-label"
                                }
                                limitMaxLength={true}
                                value={formik.values.phoneNumber}
                                onChange={(e: string) => {
                                  formik.setValues({
                                    ...formik.values,
                                    phoneNumber: e,
                                  });
                                }}
                                withCountryCallingCode
                                countryCallingCodeEditable
                              />

                              <div className="mt-1">
                                <ErrorMessage
                                  name="phoneNumber"
                                  component={Errormsg}
                                />
                              </div>
                            </div>
                            <div className="createUserPassword">
                              <div className="mt-20">
                                <FormControl
                                  control="password"
                                  type="text"
                                  name="password"
                                  label="Password"
                                  fontFamily={fontFamilyRegularUber}
                                  max={6}
                                  placeholder="Enter Password"
                                  className={
                                    formik.errors.password &&
                                    formik.touched.password
                                      ? "is-invalid"
                                      : "customPasswordInput"
                                  }
                                />
                              </div>
                              <div className="mt-20">
                                <FormControl
                                  control="password"
                                  type="text"
                                  name="confirmPassword"
                                  fontFamily={fontFamilyRegularUber}
                                  border="none"
                                  label="Confirm Password"
                                  placeholder="Enter Confirm Password"
                                  className={
                                    formik.errors.confirmPassword &&
                                    formik.touched.confirmPassword
                                      ? "is-invalid"
                                      : "customPasswordInput"
                                  }
                                />
                              </div>
                            </div>
                            <TermsAndConditions
                              terms={terms}
                              setTerms={setTerms}
                              showTermsError={showTermsError}
                              screen="registerScreen"
                            />

                            <div className="mt-20">
                              <CustomButton
                                bgcolor={RobinEggBlue}
                                fontFamily={fontFamilyRegularUber}
                                textTransform="uppercase"
                                color={pureDark2}
                                padding="11px"
                                width="100%"
                                type="submit"
                                title="Register"
                                fontSize="14px"
                                border=""
                                loading={isLoading}
                              />
                            </div>
                            {/* to show login using google, facebook, apple, microsoft and discord. */}
                            <div className="d-flex or-line fs-6 mt-20 align-items-center">
                              <div className="line" />
                              <p className="orText">Or</p>
                              <div className="line" />
                            </div>
                            <OauthLogin useCase={ OAUTH_USECASES.register }  />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="signup-text mt-3">
                  <p>
                    If you already have an account.
                    <span
                      className="me-1 ms-1 mb-0"
                      onClick={() => {
                        setIsModalVisible(false);
                        setIsLoginModalVisible(true);
                      }}
                    >
                      <Link to="">Login</Link>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </CreateUserStyle>
      </CustomModal>
    </>
  );
};

export default RegisterUser;

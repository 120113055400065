import styled from "styled-components";
import { fontFamilyMedium, fontFamilyMediumUber } from "../../../components/GlobalStyle";

export const CoachProfileEventRegistrationAddStudentsStyle = styled.div`

.bodyWrapper_RightSide {
    width: 100%;
    margin-top: 31px;
}
.title_AddSaveButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 11px;
}
.title {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.AddSave_Buttons {
    display: flex;
    align-items: center;
    gap: 13px;
}
.addNewButton {
    padding: 11px 14px 10px 15px;
    border-radius: 4px;
    border: 0.6px solid #BBE6C3;
    background-color: #DEF2E2;
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
.saveNowButton {
    padding: 11px 18px 10px 19px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
.studentsCardContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    padding-left: 14px;
    gap: 20px;
}
.studentsCardContainer > div {
    max-width: 32.12%;
    width: 100%;
}
.pagination_Container {
    margin: 15px 0 33px 0;
    padding-left: 16px;
}
`;
import React, { useState, useEffect } from "react";
import { TraditionalAdccStyle } from "./style";

import ScoreBoardTable from "../../../components/ScoreBoardComponents/ScoreBoardTable/ScoreBoardTable";
import WonBy from "../../../components/ScoreBoardComponents/WonBy/WonBy";
import DoubleWo from "../../../components/ScoreBoardComponents/DoubleWo/DoubleWoTraditional";
import DoubleShow from "../../../components/ScoreBoardComponents/DoubleShow/DoubleShowTraditional";
import SwapArrowUp from "../../../assets/icons/is_ArrowUpScoreBoard.svg";
import EngFlag from "../../../assets/images/engflag.svg";
import BoardTitleLogo from "../../../assets/images/logoBoardTtitle.svg";
import ingTimeBoardLeft from "../../../assets/icons/ic_ringTimeBoardLeft.svg";
import ingTimeBoardRight from "../../../assets/icons/ic_ringTimeBoardRight.svg";
import EndMatchScoreBoardTrad from "../../../components/EndMatchScoreBoardTrad/EndMatchScoreBoardTrad";
import EndMatchSave from "../../../components/ScoreBoardComponents/EndMatch/EndMatch";
import PlayBtn from "../../../assets/icons/ic_playBtn.svg";
import PauseBtn from "../../../assets/icons/ic_pauseBtn.svg";
import SwapArrowDown from "../../../assets/icons/ic_ArrowDownScoreBoard.svg";
import PauseIcon from "../../../assets/icons/ic_pause_ScoreBoard.svg";
import CrossBtnModel from "../../../assets/icons/ic_crossBtnModel.svg";
import SaveModelIcon from "../../../assets/icons/ic_ModelSaveIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  clearMedicalTimer,
  clearStallingTimer,
  clearTimeoutTimer,
  handleMatchTimer,
  handleMedicalTimer,
  handleStallingTimer,
  handleTimeoutTimer,
  handleUpdateMatchTimer,
  showMedicalModalHandler,
  showStallingModalHandler,
  showTimeoutModalHandler,
  swapMedicalTimerHandler,
  swapStallingTimerHandler,
  swapTimeoutTimerHandler,
  updateEndGameMessage,
  updateEndGameMessageB,
  updateEndGameMessageType,
  updateMatchTimer,
  updateMedicalTimerHandler,
  updatePlayer,
  updateScoreBoard,
  updateScoreHistory,
  updateStallingTimerHandler,
  updateTimeoutTimerHandler,
  scoreBoardHandler,
  advantageBoardHandler,
  penaltyBoardHandler,
  showVarModalHandler,
} from "../../../app/reducer/scoreBoard/traditionalAdccScoreboardSlice";
import { useAppDispatch } from "../../../app/hooks";

import Minus30Icon from "../../../assets/icons/scoreBoardIcons/minus30.svg";
import Minus1Icon from "../../../assets/icons/scoreBoardIcons/minus1.svg";
import PlayIcon from "../../../assets/icons/scoreBoardIcons/playIcon.svg";
import Plus1Icon from "../../../assets/icons/scoreBoardIcons/plus1.svg";
import Plus30Icon from "../../../assets/icons/scoreBoardIcons/plus30.svg";
import DoubleWoTraditionalAdcc from "../../../components/ScoreBoardComponents/DoubleWo/DoubleWoTraditionalAdcc";
import DoubleShowTraditional from "../../../components/ScoreBoardComponents/DoubleShow/DoubleShowTraditional";
import DoubleShowTraditionalAdcc from "../../../components/ScoreBoardComponents/DoubleShow/DoubleShowTraditionalAdcc";
import WonByTraditionalAdcc from "../../../components/ScoreBoardComponents/WonBy/WonByTraditionalAdcc";

interface ScoreHistoryProps {
  playerId: number;
  score?: number;
  penalty?: number;
  advantage?: number;
}

const TraditionalAdcc: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    teamOneScoreBoard,
    teamTwoScoreBoard,
    scoreHistory,
    playerOne,
    playerTwo,
    matchTimer,
    endGameMessage,
    endGameMessageType,
    endGameMessageB,
  } = useSelector((state: RootState) => state.traditionalAdccScoreboard);
  console.log(">>endGameMessage", endGameMessage);
  // Section 1 and 2 functionality states

  const [showScoreBoardTableOne, setShowScoreBoardTableOne] = useState(true);
  const [showScoreBoardTableTwo, setShowScoreBoardTableTwo] = useState(true);

  // Section 3 functionality states
  const [showEndGameModal, setShowEndGameModal] = useState(false);
  const [endGame, setEndGame] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const [isShowTimerActions, setIsShowTimerACtions] = useState(false);

  // common handlers
  const handlePropagation = (event: any) => {
    event.stopPropagation();
  };

  const formatTime = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const remainingSeconds = timer % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return ` ${formattedMinutes}:${formattedSeconds} `;
  };

  const handleScoreBoardScore = (_currentTeam: number, _score: number) => {
    dispatch(
      scoreBoardHandler({
        currentTeam: _currentTeam,
        score: _score,
      })
    );
  };
  const handleScoreBoardAdvantage = (
    _currentTeam: number,
    _advantage: number
  ) => {
    dispatch(
      advantageBoardHandler({
        currentTeam: _currentTeam,
        advantage: _advantage,
      })
    );
  };

  const handleScoreBoardPenalty = (_currentTeam: number, _penalty: number) => {
    dispatch(
      penaltyBoardHandler({
        currentTeam: _currentTeam,
        penalty: _penalty,
      })
    );
  };

  // Section 3 functionality handlers

  const endGameBackHandle = (): void => {
    setIsSave(false);
    dispatch(
      updateEndGameMessage({
        updatedEndGameMessage: "",
      })
    );
    dispatch(
      updateEndGameMessageB({
        updatedEndGameMessageB: "",
      })
    );
    dispatch(
      updateEndGameMessageType({
        updatedEndGameMessageType: false,
      })
    );
    setEndGame(false);
    setShowScoreBoardTableOne(true);
    setShowScoreBoardTableTwo(true);

    dispatch(
      updateScoreBoard({
        currentTeam: 1,
        updatedScoredBoard: {
          ...teamOneScoreBoard,
          score: 0,
          penalty: 0,
          advantage: 0,
        },
      })
    );
    dispatch(
      updateScoreBoard({
        currentTeam: 2,
        updatedScoredBoard: {
          ...teamTwoScoreBoard,
          score: 0,
          penalty: 0,
          advantage: 0,
        },
      })
    );

    dispatch(
      updateMatchTimer({
        updatedMatchTimer: {
          ...matchTimer,
          matchTimerValue: 300,
        },
      })
    );
  };

  const handleEndGameButton = (): void => {
    if (matchTimer.isMatchTimerPaying) {
      dispatch(handleMatchTimer());
    }
    clearInterval(teamOneScoreBoard.isMedicalPlaying);
    clearInterval(teamOneScoreBoard.isStallingPlaying);

    dispatch(
      updateScoreBoard({
        currentTeam: 1,
        updatedScoredBoard: {
          ...teamOneScoreBoard,

          medicalValue: 20,
          isMedicalModal: false,
          isMedicalPlaying: undefined,

          stallingValue: 20,
          isStallingModal: false,
          isStallingPlaying: undefined,

          isVarModal: false,
        },
      })
    );

    clearInterval(teamTwoScoreBoard.isMedicalPlaying);
    clearInterval(teamTwoScoreBoard.isStallingPlaying);
    dispatch(
      updateScoreBoard({
        currentTeam: 2,
        updatedScoredBoard: {
          ...teamTwoScoreBoard,

          medicalValue: 20,
          isMedicalModal: false,
          isMedicalPlaying: undefined,

          stallingValue: 20,
          isStallingModal: false,
          isStallingPlaying: undefined,

          isVarModal: false,
        },
      })
    );
    setEndGame(true);
    setShowScoreBoardTableOne(false);
    setShowScoreBoardTableTwo(false);
  };

  const switchSideHandler = () => {
    const backupPlayerOne = { ...playerOne };
    dispatch(
      updatePlayer({
        currentPlayer: 1,
        updatedPlayer: { ...playerTwo },
      })
    );
    dispatch(
      updatePlayer({
        currentPlayer: 2,
        updatedPlayer: { ...backupPlayerOne },
      })
    );
    const backupTeamOneScoreBoard = {
      ...teamOneScoreBoard,
    };

    dispatch(
      updateScoreBoard({
        currentTeam: 1,
        updatedScoredBoard: {
          ...teamTwoScoreBoard,
        },
      })
    );
    dispatch(
      updateScoreBoard({
        currentTeam: 2,
        updatedScoredBoard: {
          ...backupTeamOneScoreBoard,
        },
      })
    );
  };

  const undoScoringActionHandler = () => {
    if (!scoreHistory.length) return;
    const _scoreHistory = [...scoreHistory];

    let lastScoreAction: ScoreHistoryProps =
      _scoreHistory.pop() as ScoreHistoryProps;
    if (lastScoreAction.playerId === playerOne.id) {
      const updatedState = { ...teamOneScoreBoard };
      if (lastScoreAction.score) {
        updatedState.score = updatedState.score - lastScoreAction.score;
      }
      if (lastScoreAction.advantage) {
        updatedState.advantage =
          updatedState.advantage - lastScoreAction.advantage;
      }
      if (lastScoreAction.penalty) {
        updatedState.penalty = updatedState.penalty - lastScoreAction.penalty;
      }
      dispatch(
        updateScoreBoard({
          currentTeam: 1,
          updatedScoredBoard: updatedState,
        })
      );
    }

    if (lastScoreAction.playerId === playerTwo.id) {
      const updatedState = { ...teamTwoScoreBoard };
      if (lastScoreAction.score) {
        updatedState.score = updatedState.score - lastScoreAction.score;
      }
      if (lastScoreAction.advantage) {
        updatedState.advantage =
          updatedState.advantage - lastScoreAction.advantage;
      }
      if (lastScoreAction.penalty) {
        updatedState.penalty = updatedState.penalty - lastScoreAction.penalty;
      }
      dispatch(
        updateScoreBoard({
          currentTeam: 2,
          updatedScoredBoard: updatedState,
        })
      );
    }

    dispatch(
      updateScoreHistory({
        updatedHistory: _scoreHistory,
      })
    );
  };

  useEffect(() => {
    if (endGameMessage || endGameMessageB) {
      setEndGame(false);
      setIsSave(true);
      // setEndGameMessage('')
      // setEndGameMessageB('')
    }
  }, [endGameMessage, endGameMessageB]);

  useEffect(() => {
    function openOrFocusTab(url: string) {
      if (window.opener && !window.opener.closed) {
        window.opener.close();
      }
      const newTab = window.open(url, '_blank', 'toolbar=no,location=no,menubar=no');
      newTab?.postMessage('goFullscreen', url);
    }
    const targetURL = window.location.origin+'/score-board/traditional-adcc-score-board-monitor';
    openOrFocusTab(targetURL);
  }, []);

  return (
    <TraditionalAdccStyle>
      <div id="tradAdccScoreboard">
        <div id="tradAdccPlayers">
          <div className="player playerContainer playerLeft">
            <div>
              {endGameMessage && (
                <div
                  className={`result ${
                    !endGameMessageType ? "submission" : "both"
                  }`}
                >
                  <span>
                    <span>{endGameMessage}</span>
                  </span>
                </div>
              )}
            </div>
            <div className="scoresContainer">
              <div className="mainScore">
                <div
                  className={`count ${
                    teamOneScoreBoard.score <= 9
                      ? "digits-1"
                      : teamOneScoreBoard.score <= 99
                      ? "digits-2"
                      : "digits-3"
                  }`}
                >
                  {" "}
                  {teamOneScoreBoard.score}{" "}
                </div>
              </div>
              <div className="extraScores">
                <div className="penalty ">
                  <div className="pen points">{teamOneScoreBoard.penalty}</div>
                  <div>
                    {" "}
                    {teamOneScoreBoard.penalty > 1 ? "penalties" : "Penalty"}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="playerControls"
              onClick={() => setShowScoreBoardTableOne(!showScoreBoardTableOne)}
            >
              <div>
                <div className="playerAttributes">
                  <div className="attributeRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <img src={playerOne.countryFlag} className="flag" />
                        <span className="name truncate">
                          <span className="firstname">
                            {playerOne.playerFirstName}{" "}
                          </span>
                          <span className="lastname">
                            {playerOne.playerLastName}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="subheader">
                      <span className="countryName">
                        {playerOne.countryName}
                      </span>
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate">
                          <div className="clubName truncate">
                            <img
                              src={playerOne.clubFlag}
                              className="clubIcon img-circle"
                            />
                            <span>{playerOne.clubName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showScoreBoardTableOne && (
                      <div>
                        <ScoreBoardTable
                          currentTeam={1}
                          handleScoreBoardScore={handleScoreBoardScore}
                          handleScoreBoardAdvantage={handleScoreBoardAdvantage}
                          handleScoreBoardPenalty={handleScoreBoardPenalty}
                          showStallingModalHandler={() =>
                            dispatch(showStallingModalHandler(1))
                          }
                          showMedicalModalHandler={() =>
                            dispatch(showMedicalModalHandler(1))
                          }
                          showTimeoutModalHandler={() =>
                            dispatch(showTimeoutModalHandler(1))
                          }
                          showVarModalHandler={() =>
                            dispatch(showVarModalHandler(1))
                          }
                        />
                      </div>
                    )}

                    {endGame && (
                      <div className="wonbyLight">
                        <WonByTraditionalAdcc />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={`score2`}>
              {/* medical */}
              {teamOneScoreBoard.isMedicalModal && (
                <div className={`sec1 medical`}>
                  <h2>
                    {formatTime(teamOneScoreBoard.medicalValue as number)}
                  </h2>
                  <p>Medical</p>
                  <div className="boardScorePlayer2">
                    <div className="scoreBoardTable">
                      <div className="ScoreBoardTableInner">
                        <div className="ScoreBoardTableInner1">
                          <div
                            className="ScoreBoardTableInner11"
                            onClick={() => dispatch(swapMedicalTimerHandler(1))}
                          >
                            <p>
                              SWAP
                              <img src={SwapArrowDown} alt="swap arrow" />
                            </p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner2">
                          <div
                            className="ScoreBoardTableInner21"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateMedicalTimerHandler(
                                  teamOneScoreBoard.medicalValue - 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardLeft} alt="" />
                            <p>1</p>
                          </div>
                          <div className="ScoreBoardTableInner22">
                            {/* <img src={PlayBtn} alt="" /> */}
                            {/* <img src={PauseBtn} alt="" /> */}
                            <button
                              className="playPauseButton"
                              onClick={() => {
                                teamOneScoreBoard.isMedicalPlaying
                                  ? dispatch(showMedicalModalHandler(1))
                                  : dispatch(
                                      handleMedicalTimer(1, teamOneScoreBoard)
                                    );
                              }}
                            >
                              {teamOneScoreBoard.isMedicalPlaying ? (
                                <img src={PauseBtn} alt="Pause" />
                              ) : (
                                <img src={PlayBtn} alt="Play" />
                              )}
                            </button>
                          </div>
                          <div
                            className="ScoreBoardTableInner23"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateMedicalTimerHandler(
                                  teamOneScoreBoard.medicalValue + 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardRight} alt="" />
                            <p>1</p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner3">
                          <div
                            className="ScoreBoardTableInner31"
                            onClick={() => {
                              teamOneScoreBoard.isMedicalPlaying
                                ? dispatch(clearMedicalTimer(1))
                                : dispatch(updateMedicalTimerHandler(20, 1));
                            }}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            {/* {teamOneScoreBoard.isMedicalPlaying ? `<img src={PauseIcon} alt='pause'>` : `<p>RESET</p>`} */}
                            {teamOneScoreBoard.isMedicalPlaying ? (
                              <img src={PauseIcon} alt="pause" />
                            ) : (
                              <p>RESET</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* ************* stalling 1 **************** */}
              {/* stalling */}
              {teamOneScoreBoard.isStallingModal && (
                <div className={`sec1 stalling`}>
                  <div>
                    <h2>
                      {formatTime(teamOneScoreBoard.stallingValue as number)}
                    </h2>
                    <p>Stalling</p>
                  </div>
                  <div className="boardScorePlayer2">
                    <div className="scoreBoardTable">
                      <div className="ScoreBoardTableInner">
                        <div className="ScoreBoardTableInner1">
                          <div
                            className="ScoreBoardTableInner11"
                            onClick={() =>
                              dispatch(swapStallingTimerHandler(1))
                            }
                          >
                            <p>
                              SWAP
                              <img src={SwapArrowDown} alt="swap arrow" />
                            </p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner2">
                          <div
                            className="ScoreBoardTableInner21"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateStallingTimerHandler(
                                  teamOneScoreBoard.stallingValue - 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardLeft} alt="" />
                            <p>1</p>
                          </div>
                          <div className="ScoreBoardTableInner22">
                            <button
                              className="playPauseButton"
                              onClick={() => {
                                teamOneScoreBoard.isStallingPlaying
                                  ? dispatch(showStallingModalHandler(1))
                                  : dispatch(
                                      handleStallingTimer(1, teamOneScoreBoard)
                                    );
                              }}
                            >
                              {teamOneScoreBoard.isStallingPlaying ? (
                                <img src={PauseBtn} alt="Pause" />
                              ) : (
                                <img src={PlayBtn} alt="Play" />
                              )}
                            </button>
                          </div>
                          <div
                            className="ScoreBoardTableInner23"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateStallingTimerHandler(
                                  teamOneScoreBoard.stallingValue + 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardRight} alt="" />
                            <p>1</p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner3">
                          <div
                            className="ScoreBoardTableInner31"
                            onClick={() => {
                              teamOneScoreBoard.isStallingPlaying
                                ? dispatch(clearStallingTimer(1))
                                : dispatch(updateStallingTimerHandler(20, 1));
                            }}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            {teamOneScoreBoard.isStallingPlaying ? (
                              <img src={PauseIcon} alt="pause" />
                            ) : (
                              <p>RESET</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* time out */}
              {teamOneScoreBoard.isTimeoutModal && (
                <div className={`sec1 timeout`}>
                  <h2>
                    {formatTime(teamOneScoreBoard.timeoutValue as number)}
                  </h2>
                  <p>TIMEOUT</p>
                  <div className="boardScorePlayer2">
                    <div className="scoreBoardTable">
                      <div className="ScoreBoardTableInner">
                        <div className="ScoreBoardTableInner1">
                          <div
                            className="ScoreBoardTableInner11"
                            onClick={() => dispatch(swapTimeoutTimerHandler(1))}
                          >
                            <p>
                              SWAP
                              <img src={SwapArrowDown} alt="swap arrow" />
                            </p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner2">
                          <div
                            className="ScoreBoardTableInner21"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateTimeoutTimerHandler(
                                  teamOneScoreBoard.timeoutValue - 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardLeft} alt="" />
                            <p>1</p>
                          </div>
                          <div className="ScoreBoardTableInner22">
                            <button
                              className="playPauseButton"
                              onClick={() => {
                                teamOneScoreBoard.isTimeoutPlaying
                                  ? dispatch(showTimeoutModalHandler(1))
                                  : dispatch(
                                      handleTimeoutTimer(1, teamOneScoreBoard)
                                    );
                              }}
                            >
                              {teamOneScoreBoard.isTimeoutPlaying ? (
                                <img src={PauseBtn} alt="Pause" />
                              ) : (
                                <img src={PlayBtn} alt="Play" />
                              )}
                            </button>
                          </div>
                          <div
                            className="ScoreBoardTableInner23"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateTimeoutTimerHandler(
                                  teamOneScoreBoard.timeoutValue + 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardRight} alt="" />
                            <p>1</p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner3">
                          <div
                            className="ScoreBoardTableInner31"
                            onClick={() => {
                              teamOneScoreBoard.isTimeoutPlaying
                                ? dispatch(clearTimeoutTimer(1))
                                : dispatch(updateTimeoutTimerHandler(20, 1));
                            }}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            {teamOneScoreBoard.isTimeoutPlaying ? (
                              <img src={PauseIcon as string} alt="pause" />
                            ) : (
                              <p>RESET</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Var assitance */}
              {teamOneScoreBoard.isVarModal && (
                <div className={`sec1 varAssistant`}>
                  <h2>VAR</h2>
                  <p>MODE ON</p>
                </div>
              )}
            </div>
          </div>

          {endGame ? (
            <div className="doubleShowsComp1">
              <DoubleWoTraditionalAdcc />
            </div>
          ) : (
            <div className="doubleShowsComp">
              {!endGameMessage.length && !endGameMessageB.length ? (
                <DoubleShowTraditionalAdcc />
              ) : (
                ""
              )}
            </div>
          )}

          <div className="player playerContainer playerRight">
            {endGameMessageB && (
              <div
                className={`result ${
                  !endGameMessageType ? "submission" : "both"
                }`}
              >
                <span>
                  <span>{endGameMessageB}</span>
                </span>
              </div>
            )}
            <div className="scoresContainer">
              <div className="mainScore">
                <div
                  className={`count ${
                    teamTwoScoreBoard.score <= 9
                      ? "digits-1"
                      : teamTwoScoreBoard.score <= 99
                      ? "digits-2"
                      : "digits-3"
                  }`}
                >
                  {" "}
                  {teamTwoScoreBoard.score}{" "}
                </div>
              </div>
              <div className="extraScores">
                <div className="penalty ">
                  <div className="pen points">{teamTwoScoreBoard.penalty}</div>
                  <div>
                    {teamTwoScoreBoard.penalty > 1 ? "Penalties" : "Penalty"}
                  </div>
                </div>
              </div>
            </div>
            <div className="playerControls">
              <div>
                <div className="playerAttributes">
                  <div className="attributeRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <img src={playerTwo.countryFlag} className="flag" />{" "}
                        <span className="name truncate">
                          <span className="firstname">
                            {playerTwo.playerFirstName}
                          </span>{" "}
                          <span className="lastname">
                            {playerTwo.playerLastName}
                          </span>
                        </span>
                      </div>
                    </div>
                    {showScoreBoardTableTwo && (
                      <div style={{ paddingLeft: "0.5em" }}>
                        <ScoreBoardTable
                          currentTeam={2}
                          handleScoreBoardScore={handleScoreBoardScore}
                          handleScoreBoardAdvantage={handleScoreBoardAdvantage}
                          handleScoreBoardPenalty={handleScoreBoardPenalty}
                          showStallingModalHandler={() =>
                            dispatch(showStallingModalHandler(2))
                          }
                          showMedicalModalHandler={() =>
                            dispatch(showMedicalModalHandler(2))
                          }
                          showTimeoutModalHandler={() =>
                            dispatch(showTimeoutModalHandler(2))
                          }
                          showVarModalHandler={() =>
                            dispatch(showVarModalHandler(2))
                          }
                        />
                      </div>
                    )}
                    {endGame && (
                      <div className="wonbyDark">
                        <WonByTraditionalAdcc isDark={true} />
                      </div>
                    )}
                    <div className="subheader">
                      <span className="countryName">
                        {playerTwo.countryName}
                      </span>
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate animate">
                          <div className="clubName truncate">
                            <img
                              src={playerTwo.clubFlag}
                              className="clubIcon img-circle"
                            />
                            <span>{playerTwo.clubName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`score2`}>
              {/* medical */}
              {teamTwoScoreBoard.isMedicalModal && (
                <div className={`sec1 medical swapArrow`}>
                  <h2>
                    {formatTime(teamTwoScoreBoard.medicalValue as number)}
                  </h2>
                  <p>Medical</p>
                  <div className="boardScorePlayer2">
                    <div className="scoreBoardTable">
                      <div className="ScoreBoardTableInner">
                        <div className="ScoreBoardTableInner1">
                          <div
                            className="ScoreBoardTableInner11 ScoreBoardTableInnerRight"
                            onClick={() => dispatch(swapMedicalTimerHandler(2))}
                          >
                            <p>
                              SWAP
                              <img src={SwapArrowUp} alt="swap arrow" />
                            </p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner2">
                          <div
                            className="ScoreBoardTableInner21"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateMedicalTimerHandler(
                                  teamTwoScoreBoard.medicalValue - 1,
                                  2
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardLeft} alt="" />
                            <p>1</p>
                          </div>
                          <div className="ScoreBoardTableInner22">
                            <button
                              className="playPauseButton"
                              onClick={() => {
                                teamTwoScoreBoard.isMedicalPlaying
                                  ? dispatch(showMedicalModalHandler(2))
                                  : dispatch(
                                      handleMedicalTimer(2, teamTwoScoreBoard)
                                    );
                              }}
                            >
                              {teamTwoScoreBoard.isMedicalPlaying ? (
                                <img src={PauseBtn} alt="Pause" />
                              ) : (
                                <img src={PlayBtn} alt="Play" />
                              )}
                            </button>
                          </div>
                          <div
                            className="ScoreBoardTableInner23"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateMedicalTimerHandler(
                                  teamTwoScoreBoard.medicalValue + 1,
                                  2
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardRight} alt="" />
                            <p>1</p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner3">
                          <div
                            className="ScoreBoardTableInner31"
                            onClick={() => {
                              teamTwoScoreBoard.isMedicalPlaying
                                ? dispatch(clearMedicalTimer(2))
                                : dispatch(updateMedicalTimerHandler(20, 2));
                            }}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            {teamTwoScoreBoard.isMedicalPlaying ? (
                              <img src={PauseIcon} alt="pause" />
                            ) : (
                              <p>RESET</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* ************* stalling 2 **************** */}
              {/* stalling */}
              {teamTwoScoreBoard.isStallingModal && (
                <div className={`sec1 stalling swapArrow`}>
                  <div>
                    <h2>
                      {formatTime(teamTwoScoreBoard.stallingValue as number)}
                    </h2>
                    <p>Stalling</p>
                  </div>
                  <div className="boardScorePlayer2">
                    <div className="scoreBoardTable">
                      <div className="ScoreBoardTableInner">
                        <div className="ScoreBoardTableInner1">
                          <div
                            className="ScoreBoardTableInner11 ScoreBoardTableInnerRight"
                            onClick={() =>
                              dispatch(swapStallingTimerHandler(2))
                            }
                          >
                            <p>
                              SWAP
                              <img src={SwapArrowUp} alt="swap arrow" />
                            </p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner2">
                          <div
                            className="ScoreBoardTableInner21"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateStallingTimerHandler(
                                  teamTwoScoreBoard.stallingValue - 1,
                                  2
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardLeft} alt="" />
                            <p>1</p>
                          </div>
                          <div className="ScoreBoardTableInner22">
                            <button
                              className="playPauseButton"
                              onClick={() => {
                                teamTwoScoreBoard.isStallingPlaying
                                  ? dispatch(showStallingModalHandler(2))
                                  : dispatch(
                                      handleStallingTimer(2, teamTwoScoreBoard)
                                    );
                              }}
                            >
                              {teamTwoScoreBoard.isStallingPlaying ? (
                                <img src={PauseBtn} alt="Pause" />
                              ) : (
                                <img src={PlayBtn} alt="Play" />
                              )}
                            </button>
                          </div>
                          <div
                            className="ScoreBoardTableInner23"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateStallingTimerHandler(
                                  teamTwoScoreBoard.stallingValue + 1,
                                  2
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardRight} alt="" />
                            <p>1</p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner3">
                          <div
                            className="ScoreBoardTableInner31"
                            onClick={() => {
                              teamTwoScoreBoard.isStallingPlaying
                                ? dispatch(clearStallingTimer(2))
                                : dispatch(updateStallingTimerHandler(20, 2));
                            }}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            {teamTwoScoreBoard.isStallingPlaying ? (
                              <img src={PauseIcon} alt="pause" />
                            ) : (
                              <p>RESET</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* time out */}

              {teamTwoScoreBoard.isTimeoutModal && (
                <div className={`sec1 timeout`}>
                  <h2>
                    {formatTime(teamTwoScoreBoard.timeoutValue as number)}
                  </h2>
                  <p>TIMEOUT</p>
                  <div className="boardScorePlayer2">
                    <div className="scoreBoardTable">
                      <div className="ScoreBoardTableInner">
                        <div className="ScoreBoardTableInner1">
                          <div
                            className="ScoreBoardTableInner11 ScoreBoardTableInnerRight"
                            onClick={() => dispatch(swapTimeoutTimerHandler(2))}
                          >
                            <p>
                              SWAP
                              <img src={SwapArrowUp} alt="swap arrow" />
                            </p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner2">
                          <div
                            className="ScoreBoardTableInner21"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateTimeoutTimerHandler(
                                  teamTwoScoreBoard.timeoutValue - 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardLeft} alt="" />
                            <p>1</p>
                          </div>
                          <div className="ScoreBoardTableInner22">
                            <button
                              className="playPauseButton"
                              onClick={() => {
                                teamTwoScoreBoard.isTimeoutPlaying
                                  ? dispatch(showTimeoutModalHandler(2))
                                  : dispatch(
                                      handleTimeoutTimer(2, teamTwoScoreBoard)
                                    );
                              }}
                            >
                              {teamTwoScoreBoard.isTimeoutPlaying ? (
                                <img src={PauseBtn} alt="Pause" />
                              ) : (
                                <img src={PlayBtn} alt="Play" />
                              )}
                            </button>
                          </div>
                          <div
                            className="ScoreBoardTableInner23"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateTimeoutTimerHandler(
                                  teamTwoScoreBoard.timeoutValue + 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardRight} alt="" />
                            <p>1</p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner3">
                          <div
                            className="ScoreBoardTableInner31"
                            onClick={() => {
                              teamTwoScoreBoard.isTimeoutPlaying
                                ? dispatch(clearTimeoutTimer(2))
                                : dispatch(updateTimeoutTimerHandler(20, 2));
                            }}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            {teamTwoScoreBoard.isTimeoutPlaying ? (
                              <img src={PauseIcon} alt="pause" />
                            ) : (
                              <p>RESET</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {teamTwoScoreBoard.isVarModal && (
                <div className={`sec1 varAssistant`}>
                  <h2>VAR</h2>
                  <p>MODE ON</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="tradScoreboardBottom">
          <div
            style={{
              flexGrow: "1",
              width: "0px",
              zIndex: "2",
              position: "relative",
            }}
            onClick={() => setShowEndGameModal(!showEndGameModal)}
          >
            <div className="matchInfoContainer">
              <div className="matchNumber">
                <span>1-1</span>
              </div>
              <div className="matchInfo">
                <div className="matchInfoText">
                  <div className="bracketDivision">
                    <div className="scMarquee">
                      <div className="marqueeContainer animate">
                        <span className="marqueeText">
                          Boys Gi / Mighty Mite I (4 yrs) / -17,90 kg / Grey
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bracketRound">
                    <div className="scMarquee primary">
                      <div className="marqueeContainer">
                        <span className="marqueeText">Semifinals</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="matchControls123">
              {showEndGameModal && !endGame && !isSave && (
                <EndMatchScoreBoardTrad
                  undoScoringActionHandler={undoScoringActionHandler}
                  switchSideHandler={switchSideHandler}
                  handleEndGameButton={handleEndGameButton}
                />
              )}
            </div>

            {isSave && (
              <div className="endGameBottom">
                <EndMatchSave endGameBackHandle={endGameBackHandle} />
              </div>
            )}
            {/* <div className="modelScoresBoard">
              <ModelScoreBoard />
            </div> */}
            {/* <div className="modelScoresBoardBacktoBracket">
              <ModelScoreBoardBacktoBracket />
            </div> */}
            {/* <div className="modelScoresBoardBacktoBracket">
              <ModelScoreBoardSave />
            </div> */}
          </div>

          <div
            id="clock"
            onMouseEnter={() => setIsShowTimerACtions(true)}
            onMouseLeave={() => setIsShowTimerACtions(false)}
          >
            <div className="time running">
              <div
                className="count "
                style={{
                  color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                }}
              >
                <p
                  style={{
                    color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                  }}
                >
                  {formatTime(matchTimer.matchTimerValue)}
                </p>
              </div>
            </div>
            <div className="control">
              <div className="controlWrapper">
                {isShowTimerActions && (
                  <div className="actions" onClick={handlePropagation}>
                    <table className="actionsWrapper">
                      <tbody>
                        <tr>
                          <td
                            onClick={() =>
                              dispatch(
                                handleUpdateMatchTimer(
                                  matchTimer.matchTimerValue - 30
                                )
                              )
                            }
                            className="btn minus"
                          >
                            <img src={Minus30Icon} alt="minus_val" />
                          </td>
                          <td
                            onClick={() =>
                              dispatch(
                                handleUpdateMatchTimer(
                                  matchTimer.matchTimerValue - 1
                                )
                              )
                            }
                            className="btn minus"
                          >
                            <img src={Minus1Icon} alt="" />
                          </td>
                          {!matchTimer.isMatchTimerPaying ? (
                            <td onClick={() => dispatch(handleMatchTimer())}>
                              <img src={PlayIcon} alt="" />
                            </td>
                          ) : (
                            <td onClick={() => dispatch(handleMatchTimer())}>
                              <img src={PauseBtn} alt="Pause" />
                            </td>
                          )}

                          <td
                            onClick={() =>
                              dispatch(
                                handleUpdateMatchTimer(
                                  matchTimer.matchTimerValue + 1
                                )
                              )
                            }
                            className="btn plus"
                          >
                            <img src={Plus1Icon} alt="" />
                          </td>
                          <td
                            onClick={() =>
                              dispatch(
                                handleUpdateMatchTimer(
                                  matchTimer.matchTimerValue + 30
                                )
                              )
                            }
                            className="btn plus"
                          >
                            <img src={Plus30Icon} alt="" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </TraditionalAdccStyle>
  );
};
export default TraditionalAdcc;

import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium } from "../../../components/GlobalStyle";

export const OrganizerSettingStatisticsRegistrationStyle = styled.div`
.bodyWrapper_RightSide {
    width: 100%;
    padding-left: 35px;
}

`;

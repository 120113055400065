import React from 'react'
import { OrganizerBillingInfoStyle } from "./style"
import { Input } from 'antd'
import GapCurrencyIcon from "../../../assets/icons/is_GBP.svg"
import TextArea from 'antd/lib/input/TextArea'

const OrganizerBillingInfo = () => {
    return (
        <div>
            <OrganizerBillingInfoStyle>

                <div className="title_AffiliationButton">
                    <h4 className="title">Billing information</h4>
                    <button className="newAffiliationButton">Apply Changes</button>
                </div>

                <div className='tablelist'>
                    <div className='organizerSettingForm'>
                        <form>
                            <div className='form-group'>
                                <label>Company</label>
                                <Input
                                    placeholder={"East Jiu Jitsu"}
                                />
                            </div>
                            <div className='form-group'>
                                <label>First name</label>
                                <Input
                                    placeholder={"O’Neil"}
                                />
                            </div>
                            <div className='form-group'>
                                <label>Last name</label>
                                <Input
                                    placeholder={"McLean"}
                                />
                            </div>
                            <div className='formSec'>
                                <div className='formSec1'>
                                    <div className='formSec11'>
                                        <div className='form-group'>
                                            <label>Email</label>
                                            <Input
                                                placeholder={"omclean@kaimeramedia.com"}
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label>Mobile Number</label>
                                            {/* <Input
                                                placeholder={"McLean"}
                                            /> */}
                                            <Input
                                                // defaultCountry="GB"
                                                // international
                                                // placeholder={getLabelByKey(
                                                //     FORGOT_SCREEN_LABEL_KEYS.mobileFieldPlaceholder
                                                // )}
                                                name="phoneNumber"
                                                // value={formik.values.phoneNumber}
                                                // onChange={(e: any) => {
                                                //     formik.setValues({ phoneNumber: e });
                                                // }}
                                                // withCountryCallingCode
                                                // countryCallingCodeEditable
                                            />
                                        </div>
                                    </div>
                                    <div className='formSec12'>
                                        <div className='form-group'>
                                            <label>Address</label>
                                            <Input
                                                placeholder={"27 The Waterfront, Marina Way, Brighton Marina, Brighton and Hove, Brighton BN2 5WA"}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className='formSec2'>
                                    <div className='form-group'>
                                        <label>Short Note</label>
                                        <TextArea placeholder={"Enter Short Note"} />
                                    </div>
                                </div>
                            </div>



                        </form>

                    </div>
                </div>

            </OrganizerBillingInfoStyle>
        </div>
    )
}

export default OrganizerBillingInfo
import styled from "styled-components";
import { fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegularUber } from "../../../components/GlobalStyle";

export const BuyTicketsSelectUser3Style = styled.div`

.wrapper {
    width: 100%;
}
.containerMain {
    width: 83.379%;
    margin: 37px auto 0 auto; 
}
.title h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.termsOfServiceContainer {
    width: 100%;
    padding: 18px 28px 42px 18px;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #E0E0E0;
    background-color: #FFF;
    margin-top: 11px;
}
.innerTitles{
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 0;
}
.paragraphs {
    color: #061229;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 0;
    padding-top: 9px;
}
.relentlessSection {
    padding-top: 17px;
}
.paragraph_3 {
    padding-top: 7px;
}
.sub_title, .innerParagraphs {
    color: var(--Title, #061229);
    font-family: ${fontFamilyRegularUber};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 0;
}
.riskOfBadlyHarm {
    padding-top: 24px;
}
.privacyPersolnalData, .privacyPara2, .privacyPara3 {
    padding-top: 22px;
}
.dipsutePara {
    padding-top: 22px;
}
.acceptDeclineButtonsMain {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 13px;
    margin: 14px 0 40px 0;
}
.declineButton {
    padding: 11px 22px 10px 23px;
    border-radius: 4px;
    background: linear-gradient(0deg, #AF0026 0%, #AF0026 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
}
.acceptButton {
    padding: 11px 25px 10px 25px;
    border-radius: 4px;
    background: linear-gradient(0deg, #7BB037 0%, #7BB037 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
}
`;
import React from 'react'
import {OrganizerMessengerCenterParticipants2Style} from "./style";
import ParticipantsCard from "../../../components/EventRegistrationCard/EventRegistrationCard";
import PaginationComponent from "../../../components/ProfilesBeforeLoginPagination/ProfilesBeforeLoginPagination";
export const OrganizerMessengerCenterParticipants2 = () => {
    const itemsArray = Array.from({ length: 12 }, (_, index) => index + 1);
  return (
    <div>
        <OrganizerMessengerCenterParticipants2Style>
        <div className='bodyWrapper_RightSide'>
                <div className='title_submitButton'>
                    <h4 className='title'>Participants</h4>
                    <button className='submitButton'>Submit</button>
                </div>
                <div className='participantsList'>
                {itemsArray.map((item, index) => (
                        <ParticipantsCard key={index} />
                ))}
                </div>
                <div className='pagination_Container'>
                    <PaginationComponent />
                </div>
            </div>

        </OrganizerMessengerCenterParticipants2Style>

    </div>
  )
}

import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyRegularUber } from "../../../components/GlobalStyle";

export const CoachUpdateProfileEventRegistrationEventStyle = styled.div`
.bodyWrapper_RightSide {
    width: 100%;
    margin-top: 29px;
}
.title h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.coachProfileBanner {
    width: 100%;
    margin-top: 19px;
    padding-right: 14px;
    position: relative;
}
.bannerImageMain img{
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: none;
    object-position: top;
}
.martialSystemMain {
    position: absolute;
    bottom: 19.13px;
    left: 21px;
}
.martialLogo img {
    width: 170px;
    height: 179.782px;
    border-radius: 15px;
    border: 1px solid #C1DFDF;
}
.camImgae {
    width: 31px;
    height: 32.215px;
    border-radius: 6px;
    border: 0.5px solid #C1DFDF;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    bottom: 11.25px;
}
.CameraImage {
    width: 19px;
    height: 15.2px;
}
.coachInformationContainer {
    width: 100%;
    margin-top: 24.4px;
    margin-right: 16px;
    padding: 25px 14px 25.97px 21px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    background-color: #FFF;
}
.innerTitle h5 {
    color: var(--Title, #061229);
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.namePhoneEmailContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 19px;
    padding-right: 8.93px;
    margin-top: 24px;
}
.labelTypography label {
    color: var(--Title, #061229);
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.enterYourName, .enterYourNumber, .enterYourEmail {
    width: 32.15%;
}
.enterYourName input {
    width: 100%;
    padding: 16px 15px 15px 10.93px;
    border-radius: 10px;
    border: 1px solid var(--Border-Light, #E0E0E0);
    margin-top: 7px;
}
.enterYourName input::placeholder {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.enterYourNumber input {
    width: 100%;
    padding: 14px 15px 11px 22px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    margin-top: 7px;
}
.enterYourEmail input {
    width: 100%;
    padding: 16px 15px 15px 10.93px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    margin-top: 7px;
}
.enterYourEmail input::placeholder {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.personInchargeWebsite_AboutMainContainer {
    width: 100%;
    display: flex;
    gap: 15.93px;
    padding: 0 9px 0 2px;
    margin-top: 18.2px;
}
.personInchargeWebsite {
    width: 32.1%;
    display: flex;
    flex-direction: column;
    gap: 20px
}
.personInCharge input, .website input {
    width: 100%;
    padding: 15px 15px 16px 13.87px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    margin-top: 7px;
}
.about {
    width: 67.9%;
    height: 146px;
}
.about textarea {
    width: 100%;
    height: 100%;
    padding: 17.2px 15px 17.2px 11px;
    border-radius: 10px;
    border: 1px solid #E0E0E0; 
    margin-top: 7px;
    height: 165px;
    resize: none;
    overflow-y: auto;
}
`;
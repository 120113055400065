import React from "react";
import { AffiliationsProfileOverallAcademyResultStyle } from "./style";
import ProfileAcademyAdultsComponent from "../../../components/ProfileAcademyAdults/ProfileAcademyAdults";
import PaginationComponent from "../../../components/ProfilesBeforeLoginPagination/ProfilesBeforeLoginPagination";
export const AffiliationsProfileOverallAcademyResult = () => {
  return (
    <div>
      <AffiliationsProfileOverallAcademyResultStyle>
        <div className="bodyWrapper_RightSide">
            <div className="rankingOverAll_Title">
                <p>Ranking</p>
                <span className="dot">.</span>
                <h6>Overall Academy – Adults</h6>
                <span className="dot">.</span>
                <span className="lastCalculated">Last calculated 3 hours ago </span>
            </div>
            <div className="rankinOverallAcademyAdults">
                <p>Rankings / <span>Overall Academy – Adults</span></p>
            </div>
            <div className="academyCardsContainer">
                <div className="cardsLeftSide">
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                </div>
                <div className="cardsRightSide">
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                </div>
            </div>
            <div className="paginationContainer">
                <PaginationComponent />
            </div>
        </div>
      </AffiliationsProfileOverallAcademyResultStyle>
    </div>
  );
};

export default AffiliationsProfileOverallAcademyResult;


{/* <p>Ranking <span className='dot'>.</span>
<h6>Overall Academy – Adults</h6> <span className='dot'>.</span>
<span className='lastCalculated'>Last calculated 3 hours ago </span></p> */}
import React from "react";
import { AcademyProfileStyle } from "./style";
import PassEvenetCard from "../../../components/PassEventsCard/PassEventsCard";
import UncomingEventsCards from "../../AjpScoreBoard/TicketListing/TicketListing";
import DynamoFightCard from "../../../components/DynamoFight/DynamoFight";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import IntroductionComponent from "../../../components/ProfileBeforeLoginIntroduction/ProfileBeforeLoginIntroduction";
import MapFrame from "../../../assets/images/AcademyProfileMapImage-p3.png";
import StudentCard from "../../../components/ParticipantCard/ParticipantCard";
import { Link } from 'react-router-dom';



const AcademyProfile = () => {
  return (
    <div>
      <AcademyProfileStyle>
        <div className="banner">
          <img
            src={AcademyProfileBannerImage}
            alt="AcademyProfileBannerImage"
          />
        </div>

        <div className="wrapper">
          <br />

          <div className="bodyLeftSideMain">

            <div className="introduction">
              <IntroductionComponent />
            </div>

            <div className="affiliationsMain mt-20">
              <div className="sectionHeadingsMain BlocksHeadings">
                <h4>Affiliations</h4>
                <span><Link to='/academy/my-profile/affiliate-status'>See All</Link></span>
              </div>
              <div className="affiliationsCards">
                <DynamoFightCard />
              </div>
            </div>

            <div className="mapFrame mt-20">
              <img src={MapFrame} alt="" />
            </div>
            <div className="studentsAll mt-20">
              <div className="studentsTitle">
                <h5>Students</h5>
                <span>
                  <Link to="/academy/my-profile/students">See All</Link>
                </span>
              </div>
              <div className="studentsCards">
                <StudentCard />
                <StudentCard />
                <StudentCard />
              </div>
            </div>

          </div>

          <div className="bodyRightSideMain">
          <div className="introductionParagraph mt-20">
              <h6>Introduction</h6>
              <div className="introBox">
                <p>
                  I have been on so many different mats all over the world. That
                  amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                  began training in Jiu-Jitsu at just four years of age. His
                  father, who was a brown belt, started his journey and took him
                  to classes at João Alberto Barreto’s academy. As a teenager,
                  Mauricio began training with the legendary Rolls Gracie. Under
                  Rolls' guidance, he became a highly successful competitor and
                  won the absolute division of the Rio de Janeiro State
                  Championships in 1981. I have been on so many different mats all over the world. That
                  amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                  began training in Jiu-Jitsu at just four years of age. His
                  father, who was a brown belt, started his journey and took him
                  to classes at João Alberto Barreto’s academy. As a teenager,
                  Mauricio began training with the legendary Rolls Gracie. Under
                  Rolls' guidance, he became a highly successful competitor and
                  won the absolute division of the Rio de Janeiro State
                  Championships in 1981. I have been on so many different mats all over the world. That
                  amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                  began training in Jiu-Jitsu at just four years of age. His
                  father, who was a brown belt, started his journey and took him
                  to classes at João Alberto Barreto’s academy. As a teenager,
                  Mauricio began training with the legendary Rolls Gracie. Under
                  Rolls' guidance, he became a highly successful competitor and
                  won the absolute division of the Rio de Janeiro State
                  Championships in 1981.   ....
                </p>
              </div>
            </div>

            <div className="uncomingEventsMain">
              <div className="uncomingEventsHeading">
                <h4>Upcoming Events</h4>
                <span>
                <Link to="/academy/my-profile/upcoming-event">Sell All</Link>
                </span>
              </div>
              <div className="uncomingEventCard">
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
              </div>
            </div>

            <div className="passEventsMain">
              <div className="passEventsHeading">
                <h4>Pass Events</h4>
                <span>
                  <Link to="/academy/my-profile/past-event">Sell All</Link></span>
              </div>
              <div className="passEventCard">
                <PassEvenetCard />
                <PassEvenetCard />
                <PassEvenetCard />
              </div>
            </div>
          </div>
        </div>
      </AcademyProfileStyle>
    </div>
  );
};

export default AcademyProfile;

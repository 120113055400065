import styled from "styled-components";
import { fontFamilyMedium, fontFamilyRegularUber } from "../../../components/GlobalStyle";


export const CoachProfileEventRegistrationEventsStyle = styled.div`

.bodyWrapper_RightSide {
    width: 100%;
    margin-top: 26px;
}
.title {
    padding: 0 1px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.title span {
    color: #000;
    text-align: center;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.217px;
    letter-spacing: 0.28px;
    cursor: pointer;
}
.eventsCardsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 23.41px 20.34px;
    margin-top: 12px;
    padding-left: 12px;
}
.eventsCardsContainer > div {
    max-width: 32.09%;
    width: 100%;
}
.pagination_Container {
    margin: 80px 0 32px 0;
    padding-left: 20px;
}
`;
import React from 'react'
import {ProfileAcademyAdultsStyle} from "./style"
import AffiliationsProfileImage from "../../assets/images/AffiliationsProfile.svg"
import UnitedKingdomeFlagImage from "../../assets/icons/UnitedKingdomeFlag.svg"
import MedalCupImage from "../../assets/icons/MedalCup.svg"
export const ProfileAcademyAdults = () => {
  return (
    <div>
        <ProfileAcademyAdultsStyle>
        <div className="newWave_AcademyCard">
            <div className="LogoLefSide">
                <img src={AffiliationsProfileImage} alt="" />
            </div>
            <div className="rightSide">
                <div className="headingMain">
                    <p>1. New Wave Academy Training Centre</p>
                </div>
                <div className="countryFlagName">
                    <img src={UnitedKingdomeFlagImage} alt="" />
                    <span>United Kingdom</span>
                </div>
                <div className="pointsWins_Main">
                  <div className="pointsWinsLosses">
                    <div className="points">
                      <p>1398 <span>Points</span></p>
                    </div>
                    <div className="wins">
                      <p>72 <span>Wins</span></p>
                    </div>
                    <div className="losses">
                      <p>67 <span>Losses</span></p>
                    </div>
                  </div>
                  <div className="medalsGoldSilverBronze">

                    <div className="goldMedalsWinsCount">
                      <img src={MedalCupImage} alt="MedalCupImage" />
                      <span>3</span>
                    </div>
                    <div className="silverMedalsWinsCount">
                      <img src={MedalCupImage} alt="MedalCupImage" />
                      <span>5</span>
                    </div>
                    <div className="bronzeMedalsWinsCount">
                      <img src={MedalCupImage} alt="MedalCupImage" />
                      <span>8</span>
                    </div>
                  </div>
                </div>
            </div>
        </div>

        </ProfileAcademyAdultsStyle>

    </div>
  )
}

export default ProfileAcademyAdults;


import LoginButton from './LoginButton';
import microsoft from '../../../assets/icons/ic_microsoft.svg';
import { base_url, microsoft_auth_service} from "../../../utils/api_urls";

const MicroSoftLogin = (): JSX.Element => {

    const handleMicrosoftLogin = () => window.location.href = `${base_url}${microsoft_auth_service}`;

    return (
        <div onClick={handleMicrosoftLogin}>
            <LoginButton type={microsoft as string} alt={'Microsoft'} />
        </div>
    )
}

export default MicroSoftLogin

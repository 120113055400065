import React from "react";
import { OrganizerCreateAnEventCouponStyle } from "./style";
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import FormControl from "../../../components/FormControl";

const OrganizerCreateAnEventCoupon = () => {
  return (
    <div>
      <OrganizerCreateAnEventCouponStyle>
        <div className="bodyWrapper_RightSide">
          <div className="title_or_createCouponBtn">
            <h4>Avaliable Coupon List</h4>
            <button className="createCouponBtn">Create New Coupon</button>
          </div>
          <div className="availableCouponListContainer">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>Code</th>
                  <th>Validity</th>
                  <th>Timezone</th>
                  <th>Limit</th>
                  <th>Amount</th>
                  <th>Event</th>
                  <th>Created by</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="">
                      <FormControl
                        control="checkbox"
                        type="checkbox"
                        id="terms"
                        name="terms"
                      />
                    </span>
                  </td>
                  <td className="id">01</td>
                  <td className="code">121</td>
                  <td className="validity">
                    <p>From: 2023-12-12 00:0</p>
                    <span>To: 2023-12-12 00:00</span>
                  </td>
                  <td className="timeZone">Africa/Accra</td>
                  <td className="limit">
                    <p> Unlimited</p>
                    <span>Used: 0 times </span>
                    <p>User Ids: 4 users</p>
                  </td>
                  <td className="amount">10.00%</td>
                  <td className="event">Event test</td>
                  <td className="createdBy">Jack Hall</td>
                  <td className="status">
                    <span>Sent</span>
                  </td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className="">
                      <FormControl
                        control="checkbox"
                        type="checkbox"
                        id="terms"
                        name="terms"
                      />
                    </span>
                  </td>
                  <td className="id">01</td>
                  <td className="code">121</td>
                  <td className="validity">
                    <p>From: 2023-12-12 00:0</p>
                    <span>To: 2023-12-12 00:00</span>
                  </td>
                  <td className="timeZone">Africa/Accra</td>
                  <td className="limit">
                    <p> Unlimited</p>
                    <span>Used: 0 times </span>
                    <p>User Ids: 4 users</p>
                  </td>
                  <td className="amount">10.00%</td>
                  <td className="event">Event test</td>
                  <td className="createdBy">Jack Hall</td>
                  <td className="status">
                    <span>Sent</span>
                  </td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className="">
                      <FormControl
                        control="checkbox"
                        type="checkbox"
                        id="terms"
                        name="terms"
                      />
                    </span>
                  </td>
                  <td className="id">01</td>
                  <td className="code">121</td>
                  <td className="validity">
                    <p>From: 2023-12-12 00:0</p>
                    <span>To: 2023-12-12 00:00</span>
                  </td>
                  <td className="timeZone">Africa/Accra</td>
                  <td className="limit">
                    <p> Unlimited</p>
                    <span>Used: 0 times </span>
                    <p>User Ids: 4 users</p>
                  </td>
                  <td className="amount">10.00%</td>
                  <td className="event">Event test</td>
                  <td className="createdBy">Jack Hall</td>
                  <td className="status">
                    <span>Sent</span>
                  </td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className="">
                      <FormControl
                        control="checkbox"
                        type="checkbox"
                        id="terms"
                        name="terms"
                      />
                    </span>
                  </td>
                  <td className="id">01</td>
                  <td className="code">121</td>
                  <td className="validity">
                    <p>From: 2023-12-12 00:0</p>
                    <span>To: 2023-12-12 00:00</span>
                  </td>
                  <td className="timeZone">Africa/Accra</td>
                  <td className="limit">
                    <p> Unlimited</p>
                    <span>Used: 0 times </span>
                    <p>User Ids: 4 users</p>
                  </td>
                  <td className="amount">10.00%</td>
                  <td className="event">Event test</td>
                  <td className="createdBy">Jack Hall</td>
                  <td className="status">
                    <span>Sent</span>
                  </td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className="">
                      <FormControl
                        control="checkbox"
                        type="checkbox"
                        id="terms"
                        name="terms"
                      />
                    </span>
                  </td>
                  <td className="id">01</td>
                  <td className="code">121</td>
                  <td className="validity">
                    <p>From: 2023-12-12 00:0</p>
                    <span>To: 2023-12-12 00:00</span>
                  </td>
                  <td className="timeZone">Africa/Accra</td>
                  <td className="limit">
                    <p> Unlimited</p>
                    <span>Used: 0 times </span>
                    <p>User Ids: 4 users</p>
                  </td>
                  <td className="amount">10.00%</td>
                  <td className="event">Event test</td>
                  <td className="createdBy">Jack Hall</td>
                  <td className="status">
                    <span>Sent</span>
                  </td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="nextBtn">
            <button>Next</button>
          </div>
        </div>
      </OrganizerCreateAnEventCouponStyle>
    </div>
  );
};

export default OrganizerCreateAnEventCoupon;

import React, { useState } from "react";
import { OrganizerSettingStatisticsRegistrationTabsStyle } from "./style";
import TabNavItem from "../TabNavItem/TabNavItem";
import TabContent from "../TabContent/tabContent";
import OrganizerSettingStatisticsOverview from "../../screens/OrganizerSettingContent/OrganizerSettingStatisticsOverview/OrganizerSettingStatisticsOverview";
// import OrganizerSettingStatisticsRegistration from "../../screens/OrganizerSettingContent/OrganizerSetting/OrganizerSetting";
import OrganizerSettingStatisticsRegistration from "../../screens/OrganizerSettingContent/OrganizerSettingStatisticsRegistration/OrganizerSettingStatisticsRegistration"
import OrganizerSettingStatisticsMatch from "../../screens/OrganizerSettingContent/OrganizerSettingStatisticsMatch/OrganizerSettingStatisticsMatch";
import OrganizerSettingStatisticsMatch2 from "../../screens/OrganizerSettingContent/OrganizerSettingStatisticsMatch2/OrganizerSettingStatisticsMatch2";

const OrganizerSettingStatisticsRegistrationTabs = () => {
  const [activeTab, setActiveTab] = useState<string>("tab1");
  return (
    <div>
      <OrganizerSettingStatisticsRegistrationTabsStyle>
        <div className="Tabs">
          <ul className="nav">
            <TabNavItem
              title="OverView"
              id="tab1"
              setActiveTab={() => setActiveTab("tab1")}
              activeTab={activeTab}
            />
            <TabNavItem
              title="Registration "
              id="tab2"
              setActiveTab={() => setActiveTab("tab2")}
              activeTab={activeTab}
            />
            <TabNavItem
              title=" Match List"
              id="tab3"
              setActiveTab={() => setActiveTab("tab3")}
              activeTab={activeTab}
            />
            <TabNavItem
              title="Financial"
              id="tab4"
              setActiveTab={() => setActiveTab("tab4")}
              activeTab={activeTab}
            />
          </ul>

          <div className="outlet">
            <TabContent id="tab1" activeTab={activeTab}>
              <OrganizerSettingStatisticsOverview />
            </TabContent>

            <TabContent id="tab2" activeTab={activeTab}>
              <OrganizerSettingStatisticsRegistration />
            </TabContent>

            <TabContent id="tab3" activeTab={activeTab}>
              <OrganizerSettingStatisticsMatch />
            </TabContent>

            <TabContent id="tab4" activeTab={activeTab}>
              <OrganizerSettingStatisticsMatch2 />
            </TabContent>
          </div>
        </div>
      </OrganizerSettingStatisticsRegistrationTabsStyle>
    </div>
  );
};

export default OrganizerSettingStatisticsRegistrationTabs;

import React from 'react'
import TicketInfoBanner from '../../assets/images/TicketInformationBanner.png'

const TicketInformationBanner = () => {
  return (
    <div>
        <img src={TicketInfoBanner} alt="" className='TicketInfoBanner'/>
    </div>
  )
}

export default TicketInformationBanner
import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegularUber, whiteColor } from "../../../components/GlobalStyle";

export const OrganizerSettingsStyle = styled.div`
width: 100%;
margin-top: 12px;

.title_AffiliationButton {
display: flex;
align-items: center;
justify-content: space-between;
padding-left: 13px;
}
.title {
color: #000;
font-family: ${fontFamilyMedium};
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.48px;
margin-bottom: 0;
}
.newAffiliationButton {
padding: 11px 10px 10px 15px;
border-radius: 4px;
border: 0.6px solid #BBE6C3;
border-radius: 4px;
background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
color: ${whiteColor};
font-family: ${fontFamilyMediumUber};
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 1.6px;
}
.tableTitle{
    color: #061229;
    font-family: ${fontFamilyMedium};
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
 }

.tablelist{
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    margin-top: 18px;
    margin-bottom: 21px;
}
.tablelist table{
    -top: 17px;
    width: 100%;
    border-collapas: collapas; 

}
.tablelist table tr:nth-child(odd){

}
.tablelist table tr:nth-child(even){
    background-color: #FAFAFA;
}
.tablelist table tr th{
    color: #061229
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
    padding: 17px 20px;
}
.tablelist tr td{
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 17px 20px;
    position: relative;
}
table tr td:nth-child(4) label{
    color: #fff;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 22px;
    border-radius: 4px;
    position: relative;
}
table tr td:nth-child(5) label{
    color: #fff;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 33px;
    border-radius: 4px;
    position: relative;
}

table tr td:nth-child(6) label{
    color: #fff;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 26px;
    border-radius: 4px;
    position: relative;
}
table tr td:nth-child(4) img, table tr td:nth-child(5) img, table tr td:nth-child(6) img{
    position: absolute;
    top: 8px;
    margin-left: -10px;
}
.Default{
    background: #16374E;
}
.Optional{
    color: #A7A6A6 !important;
    background: #DADADA;
}
.Live{
    background: #4CAF50;
}
.Active{
    background-color: #4CAF50;
}
.actions {
    text-align: center;
}
.Add{
    color: #333 !important;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    background: #DEF2E2;
    padding: 4px 32px !important;
}
.nextBtn {
    text-align: right;
    margin-bottom: 15px;
}
.nextBtn button{
    padding: 11px 20px;
    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    border: none;
}

.organizerSettingForm{
    padidng: 18px;
}
.organizerSettingForm form{
    display: flex;
    padding: 17px 20px;
    gap: 22px;
}
.organizerSettingForm .form-group{
    width: 33%;
}
.organizerSettingForm label{
    display: block;
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 7px;
}
.organizerSettingForm input[type="text"]{
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    width: 100%;
    padding: 16px 10px;
    height: 50px;
    color: #4F4F4F;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.organizerSettingForm select{
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    width: 100%;
    padding: 12px 10px;
    height: 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(assets/images/arrowSelectOption.svg) 96% center no-repeat;
    color: #4F4F4F;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.organizerSettingForm select option{
    color: #4F4F4F;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
// select::-ms-expand { display: none; }

`;
import React from "react";
import { ParticipantsProfileMultiAcademiesStyle } from "./style";
import DynamoFightCard from "../../../components/DynamoFight/DynamoFight";
import UncomingEventsCards from "../../AjpScoreBoard/TicketListing/TicketListing";
import PastEventCard from "../../../components/UncomingEventCard/UncomingEventCard";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import MapFrame from "../../../assets/images/AcademyProfileMapImage-p3.png";
import IntroductionTwoComponent from "../../../components/ProfileBeforeLoginIntroductionTwo/ProfileBeforeLoginIntroductionTwo";
import BlackBeltImage from "../../../assets/icons/BlackRed-ProgressBar.svg";
import WinningLogo from "../../../assets/icons/Winnings10-Logo.svg";
export const ParticipantsProfileMultiAcademies = () => {
  return (
    <div>
      <ParticipantsProfileMultiAcademiesStyle>
        {/* <div className="banner">
          <img
            src={AcademyProfileBannerImage}
            alt="AcademyProfileBannerImage"
          />
        </div> */}
        <div className="wrapper">
          <div className="bodyLeftSideMain">
            <div className="introduction">
              <IntroductionTwoComponent />
            </div>
            <div className="blackBeltMain">
              <div className="blackBeltTitle">
                <h6>Black belt in Jiu-Jitsu (BJJ)</h6>
              </div>
              <div className="blackBelt_ImagePara">
                <div className="blackBelt_AchivedPromoted">
                  <p>Black belt</p>
                  <span>Achieved 2022, June 7th</span>
                  <span>Promoted by Mark Nixon</span>
                </div>
                <div className="blackBeltImage">
                  <img src={BlackBeltImage} alt="BlackBeltImage" />
                </div>
              </div>
            </div>
            <div className="winningsMain">
              <div className="winning10Logo">
                <img src={WinningLogo} alt="" />
              </div>
              <div className="winningTypesMain">
                <div className="winningByPoints">
                  <div className="byPoints">
                    <span></span>
                    <p>By Points</p>
                  </div>
                  <div className="winningCount">
                    <span>3</span>
                  </div>
                </div>
                <div className="winningBySubmission">
                  <div className="bySubmission">
                    <span></span>
                    <p>By Submission</p>
                  </div>
                  <div className="winningCount">
                    <span>2</span>
                  </div>
                </div>
                <div className="winningByWalkover">
                  <div className="byWalkover">
                    <span></span>
                    <p>By Walkover</p>
                  </div>
                  <div className="winningCount">
                    <span>1</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="academiesMain">
              <div className="sectionHeadingsMain">
                <h4>Academies</h4>
                <span>See All</span>
              </div>
              <div className="academiesCards">
                <DynamoFightCard />
              </div>
            </div>

            <div className="affiliationsMain">
              <div className="sectionHeadingsMain BlocksHeadings">
                <h4>Affiliations</h4>
                <span>See All</span>
              </div>
              <div className="affiliationsCards">
                <DynamoFightCard />
              </div>
            </div>


          </div>

          <div className="bodyRightSideMain mt-20">
            <div className="introductionParagraph">
              <h6>Introduction</h6>
              <div className="introBox">
                <p>
                  I have been on so many different mats all over the world. That
                  amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                  began training in Jiu-Jitsu at just four years of age. His
                  father, who was a brown belt, started his journey and took him
                  to classes at João Alberto Barreto’s academy. As a teenager,
                  Mauricio began training with the legendary Rolls Gracie. Under
                  Rolls' guidance, he became a highly successful competitor and
                  won the absolute division of the Rio de Janeiro State
                  Championships in 1981. I have been on so many different mats all over the world. That
                  amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                  began training in Jiu-Jitsu at just four years of age. His
                  father, who was a brown belt, started his journey and took him
                  to classes at João Alberto Barreto’s academy. As a teenager,
                  Mauricio began training with the legendary Rolls Gracie. Under
                  Rolls' guidance, he became a highly successful competitor and
                  won the absolute division of the Rio de Janeiro State
                  Championships in 1981. I have been on so many different mats all over the world. That
                  amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                  began training in Jiu-Jitsu at just four years of age. His
                  father, who was a brown belt, started his journey and took him
                  to classes at João Alberto Barreto’s academy. As a teenager,
                  Mauricio began training with the legendary Rolls Gracie. Under
                  Rolls' guidance, he became a highly successful competitor and
                  won the absolute division of the Rio de Janeiro State
                  Championships in 1981.   ....
                </p>
              </div>
            </div>


            <div className="unComingEventsMain">
              <div className="sectionHeadingsMain  UpcomingHeading">
                <h4>Upcoming Events</h4>
                <span className="">See All</span>
              </div>
              <div className="unComingEventsCards">
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
              </div>
            </div>

            <div className="pastEventsMain">
              <div className="sectionHeadingsMain pastEventHeading">
                <h4>Past Event</h4>
                <span>See All</span>
              </div>
              <div className="pastEventsCards">
                <div className="passEventFirstCardBorder">
                  <PastEventCard />
                </div>
                <PastEventCard />
                <PastEventCard />
              </div>
            </div>
          </div>
        </div>
      </ParticipantsProfileMultiAcademiesStyle>
    </div>
  );
};
export default ParticipantsProfileMultiAcademies;

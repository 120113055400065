import React from "react";
import { InformationDataStyle } from "./style";
import MapImage from "../../assets/images/MapImage.png";
import WebsiteIcon from "../../assets/icons/websiteIcon.svg";
import MailIcon from "../../assets/icons/gmailIcon.svg";
import PhoneIcon from "../../assets/icons/phoneIcon.svg";
import SponsorImage from "../../assets/images/SponsorImage.png";
import SponsorSlider from "../../assets/icons/SponsorSlider-Border.svg";

const InformationData = () => {
  return (
    <div>
      <InformationDataStyle>
        <div className="mainConatiner">
            <div className="Information_Leftside">
                <div className="InformationParagraph_main">
                    <div className="Information_para">
                    <h4>Information</h4>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged... Lorem
                        Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged...
                    </p>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged... Lorem
                        Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged...
                    </p>
                    </div>
                    <div className="Registrations_para">
                    <h4>Registrations</h4>
                    <span>
                        Registrations can be amended in Toukon up until March 19th.
                    </span>
                    <p>
                        For example, if you need to change weight class, that is
                        possible even if you have already paid to participate. If you
                        have trouble finding how to amend your details in Toukon, reach
                        out to info@toukon.com and we can do it for you. On the day of
                        the event, it is not possible to take new registrations or amend
                        any of the existing ones.
                    </p>
                    </div>
                    <div className="Ruleset_para">
                    <h4>Ruleset</h4>
                    <p>
                        We like to have a simple ruleset that awards the most dominant
                        player, which is the one that can either get a submission or get
                        very close to it. If you like to strategize over complex point
                        systems our rulesets may not be your cup of tea. But if you want
                        to show off how your technique allows you to dominate and submit
                        the opponent, sign up now.
                    </p>
                    <ul>
                        <li>
                        The winner is the one that submits the opponent using one of
                        the allowed techniques or the one with the most advantages.
                        </li>
                        <li>Taking the back or getting to mount awards 1 advantage.</li>
                        <li>
                        An advantage is also awarded when executing a submission the
                        opponent must do work to escape from.
                        </li>
                        <li>
                        In case of a tie, the referee's decision based on the most
                        aggressive competitor determines the winner. A bout is up to
                        six minutes without overtime.
                        </li>
                    </ul>
                    </div>
                </div>
                <div className="spnonserBy_main">
                    <h4>Sponsors By</h4>
                    <div className="spnonserByImages">
                        <img src={SponsorImage} alt="SponsorImage" className="image1"/>
                        <img src={SponsorImage} alt="SponsorImage" className="image2"/>
                        <img src={SponsorImage} alt="SponsorImage" />
                    </div>
                    <div className="Slider">
                        <img src={SponsorSlider} alt="SponsorSlider" />
                    </div>
                </div>
            </div>

            <div className="LocationContact_RightS">
                <div className="locationHeading"><h4>Location</h4></div>
                <div className="mapImage">
                    <img src={MapImage} alt="MapImage" />
                </div>
                <div className="address">
                    <span>
                        27 waterfront, Brighton marina village Brighton, BN2 5SL, UK{" "}
                    </span>
                </div>
                <div className="contactHeading"><h4>Contact</h4></div>
                
                <div className="contactMain">
                    <div className="Website">
                        <img src={WebsiteIcon} alt="WebsiteIcon" />
                        <a href="#">www.toukonup.com</a>
                    </div>
                    <div className="mail">
                        <img src={MailIcon} alt="MailIcon" />
                        <a href="#">adnan@toukonup.com</a>
                    </div>
                    <div className="contact">
                        <img src={PhoneIcon} alt="PhoneIcon" />
                        <a href="#">+4167045420</a>
                    </div>
                </div>
                <div className="EntriesHeading"><h4>Entries</h4></div>
                <div className="EntriesMain">
                    <div className="RegisterOpt">
                       <div className="boysGi">
                            <h6>Boys Gi</h6>
                            <span>£54.99</span>
                       </div>
                       <div className="Age_RegisterButton">
                            <span>4-15 years</span>
                            <button>Register</button>
                       </div>
                    </div>
                    <div className="RegisterOpt">
                       <div className="boysGi">
                            <h6>Juvenile Boys Gi</h6>
                            <span>£54.99</span>
                       </div>
                       <div className="Age_RegisterButton">
                            <span>4-15 years</span>
                            <button>Register</button>
                       </div>
                    </div>
                    <div className="RegisterOpt">
                       <div className="boysGi">
                            <h6>Juvenile Girls Gi</h6>
                            <span>£54.99</span>
                       </div>
                       <div className="Age_RegisterButton">
                            <span>4-15 years</span>
                            <button>Register</button>
                       </div>
                    </div>
                    <div className="RegisterOpt">
                       <div className="boysGi">
                            <h6>Juvenile Boys No-Gi</h6>
                            <span>£54.99</span>
                       </div>
                       <div className="Age_RegisterButton">
                            <span>4-15 years</span>
                            <button>Register</button>
                       </div>
                    </div>
                </div>
                <div className="expendSeeMore">
                    <p>Expend for See more</p>
                </div>
                <div className="cancelRefundHeading"><h4>Cancel/Refund policy</h4></div>
                <div className="cancelRefundPolicy">
                    <h4>Last chance to cancel</h4>
                    <span>In 4 weeks from now, it will be 7:59 pm on 11/16/2023.</span>
                </div>
            </div>
        </div>
      </InformationDataStyle>
    </div>
  );
};

export default InformationData;

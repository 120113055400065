import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import countryFlag1 from "../../../assets/images/countryFlag1.svg";
import countryFlag2 from "../../../assets/images/countryFlag2.svg";
import clubFlag from "../../../assets/images/logoDummyClub.png";

interface TeamScoreBoardProps {
  score: number;
  advantage: number;
  penalty: number;

  isMedicalModal: boolean;
  isMedicalPlaying: ReturnType<typeof setInterval> | undefined;
  medicalValue: number;

  isStallingModal: boolean;
  isStallingPlaying: ReturnType<typeof setInterval> | undefined;
  stallingValue: number;

  isTimeoutModal: boolean;
  isTimeoutPlaying: ReturnType<typeof setInterval> | undefined;
  timeoutValue: number;

  isVarModal: boolean;
}

interface MatchTimerProps {
  isMatchTimerPaying: ReturnType<typeof setInterval> | undefined;
  matchTimerValue: number;
}

interface PlayerDetailProps {
  id: number;
  playerFirstName: string;
  playerLastName: string;
  countryName: string;
  countryFlag: string;
  clubName: string;
  clubFlag: string;
  wonBy: boolean;
}

interface ScoreHistoryProps {
  playerId: number;
  score?: number;
  advantage?: number;
  penalty?: number;
}

interface TraditionalScoreBoardProps {
  teamOneScoreBoard: TeamScoreBoardProps;
  teamTwoScoreBoard: TeamScoreBoardProps;
  scoreHistory: ScoreHistoryProps[];
  playerOne: PlayerDetailProps;
  playerTwo: PlayerDetailProps;
  matchTimer: MatchTimerProps;
  endGameMessage: string;
  endGameMessageB: string;
  endGameMessageType: boolean;
  wonBy: boolean;
}

const teamScoreBoardInitialState = {
  score: 0,
  advantage: 0,
  penalty: 0,

  isMedicalModal: false,
  isMedicalPlaying: undefined,
  medicalValue: 20,

  isStallingModal: false,
  isStallingPlaying: undefined,
  stallingValue: 20,

  isTimeoutModal: false,
  isTimeoutPlaying: undefined,
  timeoutValue: 20,

  isVarModal: false,
};

const initialState: TraditionalScoreBoardProps = {
  teamOneScoreBoard: {
    ...teamScoreBoardInitialState,
  },
  teamTwoScoreBoard: {
    ...teamScoreBoardInitialState,
  },
  scoreHistory: [],
  playerOne: {
    id: 1,
    playerFirstName: "Jennine",
    playerLastName: "Berry",
    countryName: "ENG",
    countryFlag: countryFlag1,
    clubName: "LONDON MMA",
    clubFlag: clubFlag,
    wonBy: false,
  },
  playerTwo: {
    id: 2,
    playerFirstName: "KIMBERY",
    playerLastName: "CLARK",
    countryName: "UAE",
    countryFlag: countryFlag2,
    clubName: "Apex Kalmar Idrottsförening",
    clubFlag: clubFlag,
    wonBy: false,
  },
  matchTimer: {
    isMatchTimerPaying: undefined,
    matchTimerValue: 300,
  },
  endGameMessage: "",
  endGameMessageB: "",
  endGameMessageType: false,
  wonBy: false,
};

export const ultmScoreboardSlice = createSlice({
  name: "ultmScoreboard",
  initialState,
  reducers: {
    scoreBoardHandler: (state, action: PayloadAction<any>) => {
      const { currentTeam, score } = action.payload;
      if (currentTeam === 1 && state.teamOneScoreBoard.score + score < 0)
        return state;
      if (currentTeam === 2 && state.teamTwoScoreBoard.score + score < 0)
        return state;
      if (currentTeam === 1) {
        const updatedScore = state.teamOneScoreBoard.score + score;
        state.scoreHistory.push({
          playerId: state.playerOne.id,
          score: score,
        });
        state.teamOneScoreBoard.score = updatedScore;
      } else {
        const updatedScore = state.teamTwoScoreBoard.score + score;
        state.scoreHistory.push({
          playerId: state.playerTwo.id,
          score: score,
        });
        state.teamTwoScoreBoard.score = updatedScore;
      }
    },
    advantageBoardHandler: (state, action) => {
      const { advantage, currentTeam } = action.payload;
      if (
        currentTeam === 1 &&
        state.teamOneScoreBoard.advantage + advantage < 0
      )
        return state;
      if (
        currentTeam === 2 &&
        state.teamTwoScoreBoard.advantage + advantage < 0
      )
        return state;
      if (currentTeam === 1) {
        state.scoreHistory.push({
          playerId: state.playerOne.id,
          advantage,
        });
        state.teamOneScoreBoard.advantage += advantage;
      } else {
        state.scoreHistory.push({
          playerId: state.playerTwo.id,
          advantage: advantage,
        });
        state.teamTwoScoreBoard.advantage += advantage;
      }
    },
    penaltyBoardHandler: (state, action) => {
      const { penalty, currentTeam } = action.payload;
      if (currentTeam === 1 && state.teamOneScoreBoard.penalty + penalty < 0)
        return state;
      if (currentTeam === 2 && state.teamTwoScoreBoard.penalty + penalty < 0)
        return state;
      if (currentTeam === 1) {
        state.scoreHistory.push({
          playerId: state.playerOne.id,
          penalty,
        });
        state.teamOneScoreBoard.penalty += penalty;
      } else {
        state.scoreHistory.push({
          playerId: state.playerTwo.id,
          penalty,
        });
        state.teamTwoScoreBoard.penalty += penalty;
      }
    },
    updateScoreBoard: (
      state,
      action: PayloadAction<{
        currentTeam: number;
        updatedScoredBoard: TeamScoreBoardProps;
      }>
    ) => {
      const { updatedScoredBoard, currentTeam } = action.payload;
      if (currentTeam === 1) {
        state.teamOneScoreBoard = {
          ...updatedScoredBoard,
        };
      }
      if (currentTeam === 2) {
        state.teamTwoScoreBoard = {
          ...updatedScoredBoard,
        };
      }
    },
    updateScoreHistory: (
      state,
      action: PayloadAction<{ updatedHistory: ScoreHistoryProps[] }>
    ) => {
      const { updatedHistory } = action.payload;
      state.scoreHistory = [...updatedHistory];
    },
    updatePlayer: (
      state,
      action: PayloadAction<{
        currentPlayer: number;
        updatedPlayer: PlayerDetailProps;
      }>
    ) => {
      const { updatedPlayer, currentPlayer } = action.payload;
      if (currentPlayer === 1) {
        state.playerOne = {
          ...updatedPlayer,
        };
      }
      if (currentPlayer === 2) {
        state.playerTwo = {
          ...updatedPlayer,
        };
      }
    },
    updateMatchTimer: (
      state,
      action: PayloadAction<{ updatedMatchTimer: MatchTimerProps }>
    ) => {
      const { updatedMatchTimer } = action.payload;
      state.matchTimer = { ...updatedMatchTimer };
    },
    updateEndGameMessage: (
      state,
      action: PayloadAction<{ updatedEndGameMessage: string }>
    ) => {
      const { updatedEndGameMessage } = action.payload;
      state.endGameMessage = updatedEndGameMessage;
      state.playerOne.wonBy = true;
      state.playerTwo.wonBy = false;
    },
    updateEndGameMessageB: (
      state,
      action: PayloadAction<{ updatedEndGameMessageB: string }>
    ) => {
      const { updatedEndGameMessageB } = action.payload;
      state.endGameMessageB = updatedEndGameMessageB;
      state.playerTwo.wonBy = true;
      state.playerOne.wonBy = false;
    },
    updateEndGameMessageType: (
      state,
      action: PayloadAction<{ updatedEndGameMessageType: boolean }>
    ) => {
      const { updatedEndGameMessageType } = action.payload;
      state.endGameMessageType = updatedEndGameMessageType;
    },
  },
});

// *********************************************** //
// Medical handler
export const handleMedicalTimer = (_activeTeam: number, _teamScoreBoard: TeamScoreBoardProps) => (dispatch: any, getState: () => RootState) => {
  if (_activeTeam === 1) {
    const interval = setInterval(() => {
      let stopTimer = false;
      if (getState().ultmScoreboard.teamOneScoreBoard.medicalValue - 1 < 1) {
          clearInterval(getState().ultmScoreboard.teamOneScoreBoard.isMedicalPlaying);
          stopTimer = true;
          setTimeout(() => {
              dispatch(updateScoreBoard({
                  currentTeam: _activeTeam,
                  updatedScoredBoard: {
                      ...getState().ultmScoreboard.teamOneScoreBoard,
                      isMedicalModal: false, medicalValue: 20
                  }
              }));
          }, 1000);
        }
        dispatch(updateScoreBoard({
          currentTeam: _activeTeam,
          updatedScoredBoard: {
              ...getState().ultmScoreboard.teamOneScoreBoard,
              medicalValue: getState().ultmScoreboard.teamOneScoreBoard.medicalValue - 1,
          isMedicalPlaying: stopTimer ? undefined : getState().ultmScoreboard.teamOneScoreBoard.isMedicalPlaying,
          }
      }));
    }, 1000);
    dispatch(updateScoreBoard({
      currentTeam: _activeTeam,
      updatedScoredBoard: {
          ..._teamScoreBoard,
          isMedicalPlaying: interval
      }
    }))
  }


  if (_activeTeam === 2) {
      const interval = setInterval(() => {
        let stopTimer = false;
        if (getState().ultmScoreboard.teamTwoScoreBoard.medicalValue - 1 < 1) {
            clearInterval(getState().ultmScoreboard.teamTwoScoreBoard.isMedicalPlaying);
            stopTimer = true;
            setTimeout(() => {
                dispatch(updateScoreBoard({
                    currentTeam: _activeTeam,
                    updatedScoredBoard: {
                        ...getState().ultmScoreboard.teamTwoScoreBoard,
                        isMedicalModal: false, medicalValue: 20
                    }
                }));
            }, 1000);
          }
          dispatch(updateScoreBoard({
            currentTeam: _activeTeam,
            updatedScoredBoard: {
                ...getState().ultmScoreboard.teamTwoScoreBoard,
                medicalValue: getState().ultmScoreboard.teamTwoScoreBoard.medicalValue - 1,
            isMedicalPlaying: stopTimer ? undefined : getState().ultmScoreboard.teamTwoScoreBoard.isMedicalPlaying,
            }
        }));
      }, 1000);
      dispatch(updateScoreBoard({
        currentTeam: _activeTeam,
        updatedScoredBoard: {
            ..._teamScoreBoard,
            isMedicalPlaying: interval
        }
      }))
    }
}
// *********************************************** //
export const showMedicalModalHandler =
  (_currentTeam: number) => (dispatch: any, getState: () => RootState) => {
    const _matchTimer = getState().ultmScoreboard.matchTimer;
    if (_matchTimer.isMatchTimerPaying) {
      clearInterval(_matchTimer.isMatchTimerPaying);
      dispatch(
        updateMatchTimer({
          updatedMatchTimer: {
            ..._matchTimer,
            isMatchTimerPaying: undefined,
          },
        })
      );
    }
    if (_currentTeam === 1) {
      const _teamScoreBoard: TeamScoreBoardProps = {
        ...getState().ultmScoreboard.teamOneScoreBoard,
        isTimeoutModal: false,
        timeoutValue: 20,

        isStallingModal: false,
        stallingValue: 20,

        isVarModal: false,

        isMedicalModal:
          !getState().ultmScoreboard.teamOneScoreBoard.isMedicalModal,
        medicalValue: 20,
      };
      clearInterval(_teamScoreBoard.isStallingPlaying);
      clearInterval(_teamScoreBoard.isTimeoutPlaying);
      _teamScoreBoard.isStallingPlaying = undefined;
      _teamScoreBoard.isTimeoutPlaying = undefined;

      if (_teamScoreBoard.isMedicalModal && !_teamScoreBoard.isMedicalPlaying) {
        dispatch(
          updateScoreBoard({
            currentTeam: _currentTeam,
            updatedScoredBoard: _teamScoreBoard,
          })
        );
        dispatch(handleMedicalTimer(_currentTeam, _teamScoreBoard));
      } else {
        clearInterval(_teamScoreBoard.isMedicalPlaying);
        _teamScoreBoard.isMedicalPlaying = undefined;
        dispatch(
          updateScoreBoard({
            currentTeam: _currentTeam,
            updatedScoredBoard: _teamScoreBoard,
          })
        );
      }
    }

    if (_currentTeam === 2) {
      const _teamScoreBoard: TeamScoreBoardProps = {
        ...getState().ultmScoreboard.teamTwoScoreBoard,
        isTimeoutModal: false,
        timeoutValue: 20,

        isStallingModal: false,
        stallingValue: 20,

        isVarModal: false,

        isMedicalModal:
          !getState().ultmScoreboard.teamTwoScoreBoard.isMedicalModal,
        medicalValue: 20,
      };
      clearInterval(_teamScoreBoard.isStallingPlaying);
      clearInterval(_teamScoreBoard.isTimeoutPlaying);
      _teamScoreBoard.isStallingPlaying = undefined;
      _teamScoreBoard.isTimeoutPlaying = undefined;

      if (_teamScoreBoard.isMedicalModal && !_teamScoreBoard.isMedicalPlaying) {
        dispatch(
          updateScoreBoard({
            currentTeam: _currentTeam,
            updatedScoredBoard: _teamScoreBoard,
          })
        );
        dispatch(handleMedicalTimer(_currentTeam, _teamScoreBoard));
      } else {
        clearInterval(_teamScoreBoard.isMedicalPlaying);
        _teamScoreBoard.isMedicalPlaying = undefined;
        dispatch(
          updateScoreBoard({
            currentTeam: _currentTeam,
            updatedScoredBoard: _teamScoreBoard,
          })
        );
      }
    }
  };
// *********************************************** //
export const updateMedicalTimerHandler = (_value: number, _activeTeam: number) => (dispatch: any, getState: () => RootState) => {
  if (_activeTeam === 1 && _value >= 0) {
      const _teamScoreBoard = {...getState().ultmScoreboard.teamOneScoreBoard, 
          medicalValue: _value
      }
      dispatch(updateScoreBoard({
          currentTeam: _activeTeam,
          updatedScoredBoard: _teamScoreBoard
      }))
  }
  if (_activeTeam === 2 && _value >= 0) {
      const _teamScoreBoard = {...getState().ultmScoreboard.teamTwoScoreBoard, 
          medicalValue: _value
      }
      dispatch(updateScoreBoard({
          currentTeam: _activeTeam,
          updatedScoredBoard: _teamScoreBoard
      }))
  }
}
// *********************************************** //
export const swapMedicalTimerHandler = (_activeTeam: number) => (dispatch: any, getState: () => RootState) => {
  const _teamOneScoreBoard: TeamScoreBoardProps = {...getState().ultmScoreboard.teamOneScoreBoard, 
      isStallingModal: false,
      stallingValue: 20,

      isTimeoutModal: false,
      timeoutValue: 20,
      isVarModal: false,
  }
  const _teamTwoScoreBoard = {...getState().ultmScoreboard.teamTwoScoreBoard,
      isStallingModal: false,
      stallingValue: 20,

      isTimeoutModal: false,
      timeoutValue: 20,
      isVarModal: false,
  }
  
  clearInterval(_teamOneScoreBoard.isStallingPlaying);
  clearInterval(_teamTwoScoreBoard.isStallingPlaying);

  clearInterval(_teamOneScoreBoard.isMedicalPlaying);
  clearInterval(_teamTwoScoreBoard.isMedicalPlaying);

  clearInterval(_teamOneScoreBoard.isTimeoutPlaying);
  clearInterval(_teamTwoScoreBoard.isTimeoutPlaying);

  if (_activeTeam === 1) {
    const _teamScoreBoard = {
      ..._teamTwoScoreBoard,
      medicalValue: _teamOneScoreBoard.medicalValue,
      isMedicalPlaying: undefined,
      isMedicalModal: _teamOneScoreBoard.isMedicalModal
    }
    dispatch(updateScoreBoard({
      currentTeam: 2,
      updatedScoredBoard: _teamScoreBoard
    }));
    _teamOneScoreBoard.medicalValue = 20
    _teamOneScoreBoard.isMedicalPlaying = undefined
    _teamOneScoreBoard.isMedicalModal = false
    dispatch(updateScoreBoard({
      currentTeam: 1,
      updatedScoredBoard: _teamOneScoreBoard
    }));
    dispatch(handleMedicalTimer(2, _teamScoreBoard))
  }
  if (_activeTeam === 2) {
    const _teamScoreBoard = {
      ..._teamOneScoreBoard,
      medicalValue: _teamTwoScoreBoard.medicalValue,
      isMedicalPlaying: undefined,
      isMedicalModal: _teamTwoScoreBoard.isMedicalModal
    }
    dispatch(updateScoreBoard({
      currentTeam: 1,
      updatedScoredBoard: _teamScoreBoard
    }));
    _teamTwoScoreBoard.medicalValue = 20
    _teamTwoScoreBoard.isMedicalPlaying = undefined
    _teamTwoScoreBoard.isMedicalModal = false
    dispatch(updateScoreBoard({
      currentTeam: 2,
      updatedScoredBoard: _teamTwoScoreBoard
    }));
    dispatch(handleMedicalTimer(1, _teamScoreBoard))
  }
}
// *********************************************** //
export  const clearMedicalTimer = (_activeTeam: number) => (dispatch: any, getState: () => RootState) => {
  const _teamOneScoreBoard = {...getState().ultmScoreboard.teamOneScoreBoard}
  const _teamTwoScoreBoard = {...getState().ultmScoreboard.teamTwoScoreBoard}
  if (_activeTeam === 1 && _teamOneScoreBoard.isMedicalPlaying) {
    clearInterval(_teamOneScoreBoard.isMedicalPlaying);
    _teamOneScoreBoard.isMedicalPlaying = undefined
    dispatch(updateScoreBoard({
      currentTeam: _activeTeam,
      updatedScoredBoard: _teamOneScoreBoard
    }));
    return;
  }

  if (_activeTeam === 2 && _teamTwoScoreBoard.isMedicalPlaying) {
    clearInterval(_teamTwoScoreBoard.isMedicalPlaying);
    _teamTwoScoreBoard.isMedicalPlaying = undefined
    dispatch(updateScoreBoard({
      currentTeam: _activeTeam,
      updatedScoredBoard: _teamTwoScoreBoard
    }));
    return;
  }
}
// *********************************************** //
// Stalling handler
export const showStallingModalHandler = (_currentTeam: number) => (dispatch: any, getState: () => RootState)  => {
  if (_currentTeam === 1) {
      const _teamScoreBoard: TeamScoreBoardProps = { 
          ...getState().ultmScoreboard.teamOneScoreBoard, 
              isTimeoutModal: false,
              timeoutValue: 20,
  
              isMedicalModal: false,
              medicalValue: 20,
  
              isVarModal: false,

              isStallingModal: !getState().ultmScoreboard.teamOneScoreBoard.isStallingModal, 
              stallingValue: 20 
  
      }
        clearInterval(_teamScoreBoard.isMedicalPlaying);
        clearInterval(_teamScoreBoard.isTimeoutPlaying);
        _teamScoreBoard.isMedicalPlaying = undefined
        _teamScoreBoard.isTimeoutPlaying = undefined
      if (_teamScoreBoard.isStallingModal && !_teamScoreBoard.isStallingPlaying) {
      dispatch(updateScoreBoard({
          currentTeam: _currentTeam,
          updatedScoredBoard: _teamScoreBoard
      }));
       dispatch(handleStallingTimer(_currentTeam, _teamScoreBoard))
      } else {
      clearInterval(_teamScoreBoard.isStallingPlaying);
      _teamScoreBoard.isStallingPlaying = undefined
      dispatch(updateScoreBoard({
          currentTeam: _currentTeam,
          updatedScoredBoard: _teamScoreBoard
      }));
      }
  }

  if (_currentTeam === 2) {
      const _teamScoreBoard: TeamScoreBoardProps = { 
          ...getState().ultmScoreboard.teamTwoScoreBoard, 
              isTimeoutModal: false,
              timeoutValue: 20,
  
              isMedicalModal: false,
              medicalValue: 20,

              isVarModal: false,
  
              isStallingModal: !getState().ultmScoreboard.teamTwoScoreBoard.isStallingModal, 
              stallingValue: 20 
  
      }
        clearInterval(_teamScoreBoard.isMedicalPlaying);
        clearInterval(_teamScoreBoard.isTimeoutPlaying);
        _teamScoreBoard.isMedicalPlaying = undefined
        _teamScoreBoard.isTimeoutPlaying = undefined
      if (_teamScoreBoard.isStallingModal && !_teamScoreBoard.isStallingPlaying) {
      dispatch(updateScoreBoard({
          currentTeam: _currentTeam,
          updatedScoredBoard: _teamScoreBoard
      }));
      dispatch(handleStallingTimer(_currentTeam, _teamScoreBoard))
      } else {
      clearInterval(_teamScoreBoard.isStallingPlaying);
      _teamScoreBoard.isStallingPlaying = undefined
      dispatch(updateScoreBoard({
          currentTeam: _currentTeam,
          updatedScoredBoard: _teamScoreBoard
      }));
      }
  }
} 
// *********************************************** //
export const updateStallingTimerHandler = (_value: number, _activeTeam: number) => (dispatch: any, getState: () => RootState)  => {
      if (_activeTeam === 1 && _value >= 0) {
          const _teamScoreBoard = {...getState().ultmScoreboard.teamOneScoreBoard, 
              stallingValue: _value
          }
          dispatch(updateScoreBoard({
              currentTeam: _activeTeam,
              updatedScoredBoard: _teamScoreBoard
          }))
      }
      if (_activeTeam === 2 && _value >= 0) {
          const _teamScoreBoard = {...getState().ultmScoreboard.teamTwoScoreBoard, 
              stallingValue: _value
          }
          dispatch(updateScoreBoard({
              currentTeam: _activeTeam,
              updatedScoredBoard: _teamScoreBoard
          }))
      }
}
// *********************************************** //
export  const swapStallingTimerHandler = (_activeTeam: number) => (dispatch: any, getState: () => RootState)  => {
  const _teamOneScoreBoard: TeamScoreBoardProps = {...getState().ultmScoreboard.teamOneScoreBoard,
      isMedicalModal: false,
      medicalValue: 20,

      isTimeoutModal: false,
      timeoutValue: 20,
      isVarModal: false,
  }
  const _teamTwoScoreBoard = {...getState().ultmScoreboard.teamTwoScoreBoard,
      isMedicalModal: false,
      medicalValue: 20,

      isTimeoutModal: false,
      timeoutValue: 20,
      isVarModal: false
  }
  if(_teamOneScoreBoard.stallingValue === _teamTwoScoreBoard.stallingValue){
      return
  }
  clearInterval(_teamOneScoreBoard.isStallingPlaying);
  clearInterval(_teamTwoScoreBoard.isStallingPlaying);

  clearInterval(_teamOneScoreBoard.isMedicalPlaying);
  clearInterval(_teamTwoScoreBoard.isMedicalPlaying);

  clearInterval(_teamOneScoreBoard.isTimeoutPlaying);
  clearInterval(_teamTwoScoreBoard.isTimeoutPlaying);

  if (_activeTeam === 1) {
      const _teamScoreBoard = {
      ..._teamTwoScoreBoard,
      stallingValue: _teamOneScoreBoard.stallingValue,
      isStallingPlaying: undefined,
      isStallingModal: _teamOneScoreBoard.isStallingModal,

      }
      dispatch(updateScoreBoard({
          currentTeam: 2,
          updatedScoredBoard: _teamScoreBoard
        }));
        _teamOneScoreBoard.stallingValue = 20
        _teamOneScoreBoard.isStallingPlaying = undefined
        _teamOneScoreBoard.isStallingModal = false
        
        
        dispatch(updateScoreBoard({
          currentTeam: 1,
          updatedScoredBoard: _teamOneScoreBoard
        }));
        dispatch(handleStallingTimer(2, _teamScoreBoard))
  }
  if (_activeTeam === 2) {
      const _teamScoreBoard = {
      ..._teamOneScoreBoard,
      stallingValue: _teamTwoScoreBoard.stallingValue,
      isStallingPlaying: undefined,
      isStallingModal: _teamTwoScoreBoard.isStallingModal,
      }
      dispatch(updateScoreBoard({
          currentTeam: 1,
          updatedScoredBoard: _teamScoreBoard
        }));
        _teamTwoScoreBoard.stallingValue = 20
        _teamTwoScoreBoard.isStallingPlaying = undefined
        _teamTwoScoreBoard.isStallingModal = false


        dispatch(updateScoreBoard({
          currentTeam: 2,
          updatedScoredBoard: _teamTwoScoreBoard
        }));
        dispatch(handleStallingTimer(1, _teamScoreBoard))
  }
} 
// *********************************************** //
export const clearStallingTimer = (_activeTeam: number) => (dispatch: any, getState: () => RootState)  => {
  const _teamOneScoreBoard = {...getState().ultmScoreboard.teamOneScoreBoard}
  const _teamTwoScoreBoard = {...getState().ultmScoreboard.teamTwoScoreBoard}
  if (_activeTeam === 1 && _teamOneScoreBoard.isStallingPlaying) {
  clearInterval(_teamOneScoreBoard.isStallingPlaying);
  _teamOneScoreBoard.isStallingPlaying = undefined
  dispatch(updateScoreBoard({
    currentTeam: _activeTeam,
    updatedScoredBoard: _teamOneScoreBoard
  }));
  return;
}

if (_activeTeam === 2 && _teamTwoScoreBoard.isStallingPlaying) {
  clearInterval(_teamTwoScoreBoard.isStallingPlaying);
  _teamTwoScoreBoard.isStallingPlaying = undefined
  dispatch(updateScoreBoard({
    currentTeam: _activeTeam,
    updatedScoredBoard: _teamTwoScoreBoard
  }));
  return;
}
}   
// *********************************************** //
export const handleStallingTimer = (_activeTeam: number, _teamScoreBoard: TeamScoreBoardProps) => (dispatch: any, getState: () => RootState)  => {

  if (_activeTeam === 1) {
      const interval = setInterval(() => {
      let stopTimer = false;
      if (getState().ultmScoreboard.teamOneScoreBoard.stallingValue - 1 < 1) {
          clearInterval(getState().ultmScoreboard.teamOneScoreBoard.isStallingPlaying);
          stopTimer = true;
          setTimeout(() => {
              dispatch(updateScoreBoard({
                  currentTeam: _activeTeam,
                  updatedScoredBoard: {
                      ...getState().ultmScoreboard.teamOneScoreBoard,
                      isStallingModal: false, stallingValue: 20
                  }
              }));
          }, 1000);
          }
          dispatch(updateScoreBoard({
          currentTeam: _activeTeam,
          updatedScoredBoard: {
              ...getState().ultmScoreboard.teamOneScoreBoard,
              stallingValue: getState().ultmScoreboard.teamOneScoreBoard.stallingValue - 1,
              isStallingPlaying: stopTimer ? undefined : getState().ultmScoreboard.teamOneScoreBoard.isStallingPlaying,
          }
      }));
      }, 1000);
      dispatch(updateScoreBoard({
      currentTeam: _activeTeam,
      updatedScoredBoard: {
          ..._teamScoreBoard,
          isStallingPlaying: interval
      }
      }))
  }
  if (_activeTeam === 2) {
      const interval = setInterval(() => {
        let stopTimer = false;
        if (getState().ultmScoreboard.teamTwoScoreBoard.stallingValue - 1 < 1) {
            clearInterval(getState().ultmScoreboard.teamTwoScoreBoard.isStallingPlaying);
            stopTimer = true;
            setTimeout(() => {
                dispatch(updateScoreBoard({
                    currentTeam: _activeTeam,
                    updatedScoredBoard: {
                        ...getState().ultmScoreboard.teamTwoScoreBoard,
                        isStallingModal: false, stallingValue: 20
                    }
                }));
            }, 1000);
          }
          dispatch(updateScoreBoard({
            currentTeam: _activeTeam,
            updatedScoredBoard: {
                ...getState().ultmScoreboard.teamTwoScoreBoard,
                stallingValue: getState().ultmScoreboard.teamTwoScoreBoard.stallingValue - 1,
                isStallingPlaying: stopTimer ? undefined : getState().ultmScoreboard.teamTwoScoreBoard.isStallingPlaying,
            }
        }));
      }, 1000);
      dispatch(updateScoreBoard({
        currentTeam: _activeTeam,
        updatedScoredBoard: {
            ..._teamScoreBoard,
            isStallingPlaying: interval
        }
      }))
    }
}
// *********************************************** //
// Double Stalling handler
export const doubleStallingHandler = () => (dispatch: any, getState: () => RootState) => {
  let _team1ScoreBoard = { 
      ...getState().ultmScoreboard.teamOneScoreBoard, 
      isMedicalModal: false,
      isTimeoutModel: false,
      isVarModel: false,

  }
  let _team2ScoreBoard = { 
      ...getState().ultmScoreboard.teamTwoScoreBoard, 
      isMedicalModal: false,
      isTimeoutModel: false,
      isVarModel: false, 
  }

  if (_team1ScoreBoard.isStallingModal && 
      _team1ScoreBoard.isStallingPlaying && 
      _team2ScoreBoard.isStallingModal && 
      _team2ScoreBoard.isStallingPlaying) {
    clearInterval(_team1ScoreBoard.isStallingPlaying);
    clearInterval(_team2ScoreBoard.isStallingPlaying);

    dispatch(updateScoreBoard({
      currentTeam: 1,
      updatedScoredBoard: {
          ..._team1ScoreBoard,
          isStallingModal: false,
          isStallingPlaying: undefined
      }
    }))
    dispatch(updateScoreBoard({
      currentTeam: 2,
      updatedScoredBoard: {
          ..._team2ScoreBoard,
          isStallingModal: false,
          isStallingPlaying: undefined
      }
    }))
    return
  }

  if (_team1ScoreBoard.isStallingModal && _team1ScoreBoard.isStallingPlaying) {
    clearInterval(_team1ScoreBoard.isStallingPlaying);

  }

  if (_team2ScoreBoard.isStallingModal && _team2ScoreBoard.isStallingPlaying) {
    clearInterval(_team2ScoreBoard.isStallingPlaying);

  }

  _team1ScoreBoard = {
    ..._team1ScoreBoard,
    isStallingModal: true,
    isStallingPlaying: undefined,
    stallingValue: 20,
    isMedicalModal: false,
    isTimeoutModal: false,
    isVarModal: false,
  }

  _team2ScoreBoard = {
    ..._team2ScoreBoard,
    isStallingModal: true,
    isStallingPlaying: undefined,
    stallingValue: 20,
    isMedicalModal: false,
    isTimeoutModal: false,
    isVarModal: false,
  }


  dispatch(updateScoreBoard({
      currentTeam: 1,
      updatedScoredBoard: _team1ScoreBoard
    }))
  dispatch(handleStallingTimer(1, _team1ScoreBoard));

  dispatch(updateScoreBoard({
      currentTeam: 2,
      updatedScoredBoard: _team2ScoreBoard
    }))
  dispatch(handleStallingTimer(2, _team2ScoreBoard));

}
// *********************************************** //
// timeout handler
export const showTimeoutModalHandler = (_currentTeam: number) => (dispatch: any, getState: () => RootState)  => {
  if (_currentTeam === 1) {
      const _teamScoreBoard: TeamScoreBoardProps = { 
          ...getState().ultmScoreboard.teamOneScoreBoard, 
          isMedicalModal: false,
          medicalValue: 20,

          isStallingModal: false,
          stallingValue: 20,

          isVarModal: false,

          isTimeoutModal: !getState().ultmScoreboard.teamOneScoreBoard.isTimeoutModal, 
          timeoutValue: 20 
      }
      clearInterval(_teamScoreBoard.isMedicalPlaying);
      clearInterval(_teamScoreBoard.isStallingPlaying);
      _teamScoreBoard.isMedicalPlaying = undefined;
      _teamScoreBoard.isStallingPlaying = undefined;
      if (_teamScoreBoard.isTimeoutModal && !_teamScoreBoard.isTimeoutPlaying) {
      dispatch(updateScoreBoard({
          currentTeam: _currentTeam,
          updatedScoredBoard: _teamScoreBoard
      }));
          dispatch(handleTimeoutTimer(_currentTeam, _teamScoreBoard))
      } else {
      clearInterval(_teamScoreBoard.isTimeoutPlaying);
      _teamScoreBoard.isTimeoutPlaying = undefined
      dispatch(updateScoreBoard({
          currentTeam: _currentTeam,
          updatedScoredBoard: _teamScoreBoard
      }));
      }
  }

  if (_currentTeam === 2) {
      const _teamScoreBoard: TeamScoreBoardProps = { 
          ...getState().ultmScoreboard.teamTwoScoreBoard, 
          isMedicalModal: false,
          medicalValue: 20,

          isStallingModal: false,
          stallingValue: 20,

          isVarModal: false,

          isTimeoutModal: !getState().ultmScoreboard.teamTwoScoreBoard.isTimeoutModal, 
          timeoutValue: 20 
      }
      clearInterval(_teamScoreBoard.isMedicalPlaying);
      clearInterval(_teamScoreBoard.isStallingPlaying);
      _teamScoreBoard.isMedicalPlaying = undefined;
      _teamScoreBoard.isStallingPlaying = undefined;
      if (_teamScoreBoard.isTimeoutModal && !_teamScoreBoard.isTimeoutPlaying) {
      dispatch(updateScoreBoard({
          currentTeam: _currentTeam,
          updatedScoredBoard: _teamScoreBoard
      }));
      dispatch(handleTimeoutTimer(_currentTeam, _teamScoreBoard))
      } else {
      clearInterval(_teamScoreBoard.isTimeoutPlaying);
      _teamScoreBoard.isTimeoutPlaying = undefined
      dispatch(updateScoreBoard({
          currentTeam: _currentTeam,
          updatedScoredBoard: _teamScoreBoard
      }));
      }
  }
}
// *********************************************** //
export const handleTimeoutTimer = (_activeTeam: number, _teamScoreBoard: TeamScoreBoardProps) => (dispatch: any, getState: () => RootState)  => {

  if (_activeTeam === 1) {
      const interval = setInterval(() => {
      let stopTimer = false;
      if (getState().ultmScoreboard.teamOneScoreBoard.timeoutValue - 1 < 1) {
          clearInterval(getState().ultmScoreboard.teamOneScoreBoard.isTimeoutPlaying);
          stopTimer = true;
          setTimeout(() => {
              dispatch(updateScoreBoard({
                  currentTeam: _activeTeam,
                  updatedScoredBoard: {
                      ...getState().ultmScoreboard.teamOneScoreBoard,
                      isTimeoutModal: false, 
                      timeoutValue: 20
                  }
              }));
          }, 1000);
          }
          dispatch(updateScoreBoard({
          currentTeam: _activeTeam,
          updatedScoredBoard: {
              ...getState().ultmScoreboard.teamOneScoreBoard,
              timeoutValue: getState().ultmScoreboard.teamOneScoreBoard.timeoutValue - 1,
              isTimeoutPlaying: stopTimer ? undefined : getState().ultmScoreboard.teamOneScoreBoard.isTimeoutPlaying,
          }
      }));
      }, 1000);
      dispatch(updateScoreBoard({
      currentTeam: _activeTeam,
      updatedScoredBoard: {
          ..._teamScoreBoard,
          isTimeoutPlaying: interval
      }
      }))
  }

  if (_activeTeam === 2) {
      const interval = setInterval(() => {
          let stopTimer = false;
          if (getState().ultmScoreboard.teamTwoScoreBoard.timeoutValue - 1 < 1) {
              clearInterval(getState().ultmScoreboard.teamTwoScoreBoard.isTimeoutPlaying);
              stopTimer = true;
              setTimeout(() => {
                  dispatch(updateScoreBoard({
                      currentTeam: _activeTeam,
                      updatedScoredBoard: {
                          ...getState().ultmScoreboard.teamTwoScoreBoard,
                          isTimeoutModal: false, 
                          timeoutValue: 20
                      }
                  }));
              }, 1000);
          }
          dispatch(updateScoreBoard({
              currentTeam: _activeTeam,
              updatedScoredBoard: {
                  ...getState().ultmScoreboard.teamTwoScoreBoard,
                  timeoutValue: getState().ultmScoreboard.teamTwoScoreBoard.timeoutValue - 1,
                  isTimeoutPlaying: stopTimer ? undefined : getState().ultmScoreboard.teamTwoScoreBoard.isTimeoutPlaying,
              }
          }));
      }, 1000);
      dispatch(updateScoreBoard({
          currentTeam: _activeTeam,
          updatedScoredBoard: {
              ..._teamScoreBoard,
              isTimeoutPlaying: interval
          }
      }))
      }
}
// *********************************************** //
export const updateTimeoutTimerHandler = (_value: number, _activeTeam: number) => (dispatch: any, getState: () => RootState)  => {
  if (_activeTeam === 1 && _value >= 0) {
      const _teamScoreBoard: TeamScoreBoardProps = {...getState().ultmScoreboard.teamOneScoreBoard, 
          timeoutValue: _value
      }
      dispatch(updateScoreBoard({
          currentTeam: _activeTeam,
          updatedScoredBoard: _teamScoreBoard
      }))
  }
  if (_activeTeam === 2 && _value >= 0) {
      const _teamScoreBoard = {...getState().ultmScoreboard.teamTwoScoreBoard, 
          timeoutValue: _value
      }
      dispatch(updateScoreBoard({
          currentTeam: _activeTeam,
          updatedScoredBoard: _teamScoreBoard
      }))
  }
}
// *********************************************** // 
export  const swapTimeoutTimerHandler = (_activeTeam: number) => (dispatch: any, getState: () => RootState)  => {
  const _teamOneScoreBoard: TeamScoreBoardProps = {...getState().ultmScoreboard.teamOneScoreBoard, 
      isMedicalModal: false,
      medicalValue: 20,

      isStallingModal: false,
      stallingValue: 20,
      isVarModal: false,
  }
  const _teamTwoScoreBoard: TeamScoreBoardProps= {...getState().ultmScoreboard.teamTwoScoreBoard,
      isMedicalModal: false,
      medicalValue: 20,

      isStallingModal: false,
      stallingValue: 20,
      isVarModal: false,
  }
  clearInterval(_teamOneScoreBoard.isStallingPlaying);
  clearInterval(_teamTwoScoreBoard.isStallingPlaying);

  clearInterval(_teamOneScoreBoard.isMedicalPlaying);
  clearInterval(_teamTwoScoreBoard.isMedicalPlaying);

  clearInterval(_teamOneScoreBoard.isTimeoutPlaying);
  clearInterval(_teamTwoScoreBoard.isTimeoutPlaying);

  if (_activeTeam === 1) {
      const _teamScoreBoard: TeamScoreBoardProps = {
      ..._teamTwoScoreBoard,
      timeoutValue: _teamOneScoreBoard.timeoutValue,
      isTimeoutPlaying: undefined,
      isTimeoutModal: _teamOneScoreBoard.isTimeoutModal
  }
      dispatch(updateScoreBoard({
      currentTeam: 2,
      updatedScoredBoard: _teamScoreBoard
      }));
      _teamOneScoreBoard.timeoutValue = 20
      _teamOneScoreBoard.isTimeoutPlaying = undefined
      _teamOneScoreBoard.isTimeoutModal = false
      dispatch(updateScoreBoard({
      currentTeam: 1,
      updatedScoredBoard: _teamOneScoreBoard
      }));
      dispatch(handleTimeoutTimer(2, _teamScoreBoard))
  }
  if (_activeTeam === 2) {
      const _teamScoreBoard = {
      ..._teamOneScoreBoard,
      timeoutValue: _teamTwoScoreBoard.timeoutValue,
      isTimeoutPlaying: undefined,
      isTimeoutModal: _teamTwoScoreBoard.isTimeoutModal
      }
      dispatch(updateScoreBoard({
          currentTeam: 1,
          updatedScoredBoard: _teamScoreBoard
          }));
          _teamTwoScoreBoard.timeoutValue = 20
          _teamTwoScoreBoard.isTimeoutPlaying = undefined
          _teamTwoScoreBoard.isTimeoutModal = false
          dispatch(updateScoreBoard({
          currentTeam: 2,
          updatedScoredBoard: _teamTwoScoreBoard
          }));
          dispatch(handleTimeoutTimer(1, _teamScoreBoard))
  }
}
// *********************************************** // 
export const clearTimeoutTimer = (_activeTeam: number) => (dispatch: any, getState: () => RootState)  => {
  const _teamOneScoreBoard = {...getState().ultmScoreboard.teamOneScoreBoard}
  const _teamTwoScoreBoard = {...getState().ultmScoreboard.teamTwoScoreBoard}
  if (_activeTeam === 1 && _teamOneScoreBoard.isTimeoutPlaying) {
  clearInterval(_teamOneScoreBoard.isTimeoutPlaying);
  _teamOneScoreBoard.isTimeoutPlaying = undefined
  dispatch(updateScoreBoard({
      currentTeam: _activeTeam,
      updatedScoredBoard: _teamOneScoreBoard
  }));
  return;
}

if (_activeTeam === 2 && _teamTwoScoreBoard.isTimeoutPlaying) {
  clearInterval(_teamTwoScoreBoard.isTimeoutPlaying);
  _teamTwoScoreBoard.isTimeoutPlaying = undefined
  dispatch(updateScoreBoard({
      currentTeam: _activeTeam,
      updatedScoredBoard: _teamTwoScoreBoard
  }));
  return;
}
}
// *********************************************** //
// var handler
export const showVarModalHandler =
  (_currentTeam: number) => (dispatch: any, getState: () => RootState) => {
    const _matchTimer = getState().ultmScoreboard.matchTimer;
    if (_matchTimer.isMatchTimerPaying) {
      clearInterval(_matchTimer.isMatchTimerPaying);
      dispatch(
        updateMatchTimer({
          updatedMatchTimer: {
            ..._matchTimer,
            isMatchTimerPaying: undefined,
          },
        })
      );
    }
    if (_currentTeam === 1) {
      const _teamScoreBoard: TeamScoreBoardProps = {
        ...getState().ultmScoreboard.teamOneScoreBoard,
        isMedicalModal: false,
        medicalValue: 20,

        isStallingModal: false,
        stallingValue: 20,

        isTimeoutModal: false,
        timeoutValue: 20,

        isVarModal:
          !getState().ultmScoreboard.teamOneScoreBoard.isVarModal,
      };
      clearInterval(_teamScoreBoard.isMedicalPlaying);
      clearInterval(_teamScoreBoard.isStallingPlaying);
      clearInterval(_teamScoreBoard.isTimeoutPlaying);
      _teamScoreBoard.isMedicalPlaying = undefined;
      _teamScoreBoard.isStallingPlaying = undefined;
      _teamScoreBoard.isTimeoutPlaying = undefined;

      dispatch(
        updateScoreBoard({
          currentTeam: _currentTeam,
          updatedScoredBoard: _teamScoreBoard,
        })
      );
    }

    if (_currentTeam === 2) {
      const _teamScoreBoard: TeamScoreBoardProps = {
        ...getState().ultmScoreboard.teamTwoScoreBoard,
        isMedicalModal: false,
        medicalValue: 20,

        isStallingModal: false,
        stallingValue: 20,

        isTimeoutModal: false,
        timeoutValue: 20,

        isVarModal:
          !getState().ultmScoreboard.teamTwoScoreBoard.isVarModal,
      };
      clearInterval(_teamScoreBoard.isMedicalPlaying);
      clearInterval(_teamScoreBoard.isStallingPlaying);
      clearInterval(_teamScoreBoard.isTimeoutPlaying);
      _teamScoreBoard.isMedicalPlaying = undefined;
      _teamScoreBoard.isStallingPlaying = undefined;
      _teamScoreBoard.isTimeoutPlaying = undefined;

      dispatch(
        updateScoreBoard({
          currentTeam: _currentTeam,
          updatedScoredBoard: _teamScoreBoard,
        })
      );
    }
  };
// *********************************************** //
export const handleMatchTimer =
  () => (dispatch: any, getState: () => RootState) => {
    const _matchTimer = getState().ultmScoreboard.matchTimer;
    const _teamOneScoreBoard =
      getState().ultmScoreboard.teamOneScoreBoard;
    const _teamTwoScoreBoard =
      getState().ultmScoreboard.teamTwoScoreBoard;
    if (
      _teamOneScoreBoard.isMedicalPlaying ||
      _teamOneScoreBoard.isVarModal ||
      _teamTwoScoreBoard.isMedicalPlaying ||
      _teamTwoScoreBoard.isVarModal
    ) {
      return;
    }
    if (_matchTimer.isMatchTimerPaying) {
      clearInterval(_matchTimer.isMatchTimerPaying);
      dispatch(
        updateMatchTimer({
          updatedMatchTimer: {
            ..._matchTimer,
            isMatchTimerPaying: undefined,
          },
        })
      );
      return;
    }

    const interval = setInterval(() => {
      let stopTimer = false;
      const updatedMatchTimerValue =
        getState().ultmScoreboard.matchTimer.matchTimerValue - 1;
      if (updatedMatchTimerValue - 1 < 1) {
        clearInterval(
          getState().ultmScoreboard.matchTimer.isMatchTimerPaying
        );
        stopTimer = true;
      }
      dispatch(
        updateMatchTimer({
          updatedMatchTimer: {
            ...getState().ultmScoreboard.matchTimer,
            matchTimerValue: updatedMatchTimerValue,
            isMatchTimerPaying: stopTimer
              ? undefined
              : getState().ultmScoreboard.matchTimer.isMatchTimerPaying,
          },
        })
      );
    }, 1000);
    dispatch(
      updateMatchTimer({
        updatedMatchTimer: {
          ...getState().ultmScoreboard.matchTimer,
          isMatchTimerPaying: interval,
        },
      })
    );
  };
// *********************************************** //
export const handleUpdateMatchTimer =
  (_updatedMatchTimerValue: number) =>
  (dispatch: any, getState: () => RootState) => {
    if (_updatedMatchTimerValue < 0) return;
    dispatch(
      updateMatchTimer({
        updatedMatchTimer: {
          ...getState().ultmScoreboard.matchTimer,
          matchTimerValue: _updatedMatchTimerValue,
        },
      })
    );
  };
// *********************************************** //

export const {
  scoreBoardHandler,
  advantageBoardHandler,
  penaltyBoardHandler,
  updateScoreBoard,
  updateScoreHistory,
  updatePlayer,
  updateMatchTimer,
  updateEndGameMessage,
  updateEndGameMessageB,
  updateEndGameMessageType,
} = ultmScoreboardSlice.actions;

export default ultmScoreboardSlice.reducer;

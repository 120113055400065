import styled from "styled-components";
import {
  fontFamilyEnnVisionsRegular,
  fontFamilyMedium,
  fontFamilyMediumUber,
  fontFamilyRegular,
  fontFamilyRegularUber,
} from "../../../components/GlobalStyle";

export const OrganizerSettingTiebreakerSettingsStyle = styled.div`
  .ant-layout > .banner + .ant-layout {
    flex-direction: row;
    gap: 10px;
  }
  .OrganizerSettingTiebreakerSettings-left {
    max-width: 15%;
    width: 100%;
  }
  .title_showPublicToggle {
    margin-top: 13px;
  }
  .title h3 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
  }
  .title span {
    color: #525a66;
    font-family: ${fontFamilyRegular};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.34px;
    padding-top: 10px;
  }
  .toggleBtn {
    max-width: 328px;
    width: 100%;
    height: 42px;
    padding: 10px;
    border: 0.6px solid #c0d4dc;
    border-radius: 10px;
  }
  .toggleBtn p {
    color: #232837;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
    margin-bottom: 0;
  }
  .form {
    padding: 15px 14px 20px 15px;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #e0e0e0;
    margin-top: 14px;
  }
  .addOpt {
    max-width: 25%;
    width: 100%;
    margin-top: 13px;
  }
  .addOpt span {
    color: #0070e0;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }

  .showApproved_toggleButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    margin-right: 19.50px;
}
.showApproved_toggleButton span {
    color: #000;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
    margin-right: 15px;
}
.showApproved_toggleButton button {
  width: 32px;
  background-color: #C8CCD0 !important;
  height: 7px;
}
.showApproved_toggleButton span {
  color: #000;
  font-family: ${fontFamilyRegularUber};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  text-transform: none;
  margin-right: 15px;
}
.showApproved_toggleButton .ant-switch-handle::before{
  background-color: #005b50;
}
.showApproved_toggleButton .ant-switch-handle{
  top: -5px;
}

`;

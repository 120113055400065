import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../components/GlobalStyle";
export const EventBracketsStyle = styled.div`

.wrapper {
    width: 100%;
}
.bracketsHeading {
    margin-top: 20px;
}
.bracketsHeading h4 {
    color: #061229;
    font-family: ${fontFamilyMedium};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.participantBlock1 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 25px;
    padding: 0 59px 0 16px;
    background-color: #fff;
}
.frameMain img {
    width: 129px;
    height: 16px;
}
.categoryHeading {
    padding: 25px 0;
    margin-left: 32px;
}
.categoryHeading h3 {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-bottom: 0;
}

.participantBlock1_rightSide {
    display: flex;
    align-items: center;
    margin-left: 37px;
}
.printIcon {
    margin-right: 9px;
}
.printIcon, .uploadIcon {
    width: 34px;
    height: 34px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #B6D0D7;
    background-color: #D7E6EA;
}
.areaBracket_Type {
    display: flex;
    align-items: center;
    margin-left: 32px;
}
.areaMat1, .bracketType, .participants,
.nrOfMatches, .timePerMatch {
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.areaMat1 p, .bracketType p,
.participants p, .nrOfMatches p,
.timePerMatch p {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
    margin-bottom: 0;
}
.areaMat1 span, .bracketType span,
.participants span, .nrOfMatches span,
.timePerMatch span {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.28px;
    padding-top: 10px;
}
.bracketsBlock2_Main {
    max-width: 94%;
    width: 100%;
    margin: 10px auto 0 auto;
    padding: 10px;
}
.finals32Th_Main  {
    width: 20%;
}
.heading_32TH h4 {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 18px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    margin-bottom: 0;
}


// Shezar CSS Styling

.card {
    display: flex;
    // flex-direction: row;
    max-width:215.708px;
    width:100%;
    height:auto;
    justify-content: space-between;
    padding-right: 7px;
    margin-top: 10px;
    // margin: 14px 11px 10px 7px;
}

 .participant-img {
    width: 64.258px;
    height: 100.466px;
    border-radius: 6px 0px 0px 0px;
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
    width: 62.16px;
    height: 58.182px;
}
    

.participate-content{
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 11.683px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px;
}


.participate-content .para{
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 13.63px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.444px;
}

.flag-container{
    margin-left: 7px;
    margin-top: 4px;
  }

.flag-image img{
    margin-left: 9px;
    margin-bottom: 11px;
  }


.country-flag{
    display: flex;
    align-items: end;
    margin-bottom:8.63px;
}


.para{
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.818px;
}

.para-text{
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.818px;
}


hr {
     margin: 0px; 
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: .25;
}

.vs {
    width: 27px;
    height: 18px;
    flex-shrink: 0;
    border-radius: 9.45px;
    border: 0.36px solid #FFF;
    background: #1C1C1D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 48px;
    left: -7px;
}

.vs-text{
    font-family: ${fontFamilyRegularUber};
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin:2px 3.5px 2px 5.5px;
}

.dot{
    width: 11px;
    height: 11px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #7BB037;
    right: -11px;
    top: 18px;
}

.dot-2{
    width: 11px;
    height: 11px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #7BB037;
    right: -5px;
    top: 75px;
}

`;

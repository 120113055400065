import React, { ChangeEvent, useRef, useState, useEffect } from "react";
import { AjpTraditionalScoreBoardStyle } from "./style";
import AJPPenalityDot from "../../../assets/icons/ajpPenalityDot.svg";

import { RootState } from "../../../app/store";
import { useAppDispatch } from "../../../app/hooks";
import { useSelector } from "react-redux";

interface ScoreHistoryProps {
  playerId: number;
  score?: number;
  penalty?: number;
  advantage?: number;
}

const AjpTraditionalScoreBoard: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    teamOneScoreBoard,
    teamTwoScoreBoard,
    scoreHistory,
    playerOne,
    playerTwo,
    matchTimer,
    endGameMessage,
    endGameMessageType,
    endGameMessageB,
  } = useSelector((state: RootState) => state.AjpTraditionalScoreBoard);

  // Section 1 and 2 functionality states

  const [showScoreBoardTableOne, setShowScoreBoardTableOne] = useState(true);
  const [showScoreBoardTableTwo, setShowScoreBoardTableTwo] = useState(true);

  // Section 3 functionality states
  const [showEndGameModal, setShowEndGameModal] = useState(false);
  const [endGame, setEndGame] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const [isShowTimerActions, setIsShowTimerACtions] = useState(false);

  console.log("checking teamOneScoreBoard: ", teamOneScoreBoard);

  // common handlers
  const handlePropagation = (event: any) => {
    event.stopPropagation();
  };

  const formatTime = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const remainingSeconds = timer % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return ` ${formattedMinutes}:${formattedSeconds} `;
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data === "goFullscreen") {
        document.documentElement.requestFullscreen({ navigationUI: "hide" });
      }
    });
  }, []);

  return (
    <AjpTraditionalScoreBoardStyle>
      <div id="tradScoreboard">
        <div className="control both-players"></div>
        <div id="tradScoreboardPlayers">
          {/* <div className="messagesBody">
            <div className="messagesShows">
              <div className={`result submission `}>
                <span className="winner-text">Won by Winner</span>
              </div>
            </div>
            <div className="playerDetails trad-player">
              <div className="trad-player-points">
                <div>
                  <div className="player-attr">
                    <div className="attr-rows ">
                      <div className="trad-header ">
                        <div className="player-name ">
                          <span className="name truncate">
                            <span className="firstname">Jennine</span>{" "}
                            <span className="lastname">Berry</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="player playerContainer playerLeft">
            <div className="scoresContainer">
              <div className="mainScore">
                <div className="count digits-1"> 2 </div>
              </div>
              <div className="extra-scores">
                {Array.from({ length: 4 }, (_, i) => (
                  <div className="penalityDot">
                    <img src={AJPPenalityDot} alt="" />
                  </div>
                ))}
              </div>
            </div>
            <div className="playerControls">
              <div>
                <div className="playerAttributes">
                  <div className="attributeRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <img src={playerOne.countryFlag} className="flag" />
                        <span className="name truncate">
                          <span className="firstname">
                            {playerOne.playerFirstName}{" "}
                          </span>
                          <span className="lastname">
                            {playerOne.playerLastName}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="subheader">
                      <span className="countryName">
                        {playerOne.countryName}
                      </span>
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate">
                          <div className="clubName truncate">
                            <img
                              src={playerOne.clubFlag}
                              className="clubIcon imgCircle"
                            />
                            <span>{playerOne.clubName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="player playerContainer playerRight">
            <div className="scoresContainer">
              <div className="mainScore">
                <div className="count digits-1"> 2 </div>
              </div>
              <div className="extra-scores">
                {Array.from({ length: 4 }, (_, i) => (
                  <div className="penalityDot">
                    <img src={AJPPenalityDot} alt="" />
                  </div>
                ))}
              </div>
            </div>
            <div className="playerControls">
              <div>
                <div className="playerAttributes">
                  <div className="attributeRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <img src={playerTwo.countryFlag} className="flag" />{" "}
                        <span className="name truncate">
                          <span className="firstname">
                            {playerTwo.playerFirstName}
                          </span>{" "}
                          <span className="lastname">
                            {playerTwo.playerLastName}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="subheader">
                      <span className="countryName">
                        {playerTwo.countryName}
                      </span>
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate animate">
                          <div className="clubName truncate">
                            <img
                              src={playerTwo.clubFlag}
                              className="clubIcon imgCircle"
                            />
                            <span>{playerTwo.clubName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tradScoreboardBottom">
          <div style={{ flexGrow: "1", width: "0px", zIndex: "2" }}>
            <div className="matchInfoContainer">
              <div className="matchNumber">
                <span>1-1</span>
              </div>
              <div className="matchInfo">
                <div className="matchInfoText">
                  <div className="bracketDivision">
                    <div className="scMarquee">
                      <div className="marqueeContainer animate">
                        <span className="marqueeText">
                          Boys Gi / Mighty Mite I (4 yrs) / -17,90 kg / Grey
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bracketRound">
                    <div className="scMarquee primary">
                      <div className="marqueeContainer">
                        <span className="marqueeText">Semifinals</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="clock">
            <div className="">
              <div className="count">
                <p>05:00</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AjpTraditionalScoreBoardStyle>
  );
};
export default AjpTraditionalScoreBoard;

import React from "react";
import { OrganizerSettingCategoriesStyle } from "./style";
import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Content } from "antd/lib/layout/layout";
import { Formik } from "formik";
import { Row, Col } from "react-bootstrap";
import FormControl from "../../../components/FormControl";
import CustomButton from "../../../components/CustomButton/CustomButton";
import {
  fontFamilyMediumUber,
  tertiaryGrey25,
  whiteColor,
} from "../../../components/GlobalStyle";
const OrganizerSettingCategories = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };
  const handleCreateSubmit = (): void => {};
  return (
    <div>
      <OrganizerSettingCategoriesStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="OrganizerSettingCategories-left">
                <CreatEventLeftSide />
              </Content>
              <Content className="OrganizerSettingCategories-right">
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={handleCreateSubmit}
                >
                  {(fromik) => {
                    return (
                      <div className="formWrapper">
                        <Form>
                          <h3 className="title">Categories</h3>
                          <div className="bg-white categories">
                            <ul className="tree">
                              <li id="" className="has-children showChildren">
                                <span className="text">Categories</span>
                                <ul className="child">
                                  <li
                                    id="8587509198220874655a1s8"
                                    className="has-children showChildren"
                                  >
                                    <span className="text">EnnVisions</span>
                                    <ul className="child">
                                      <li
                                        id="8587496127195835630dojv"
                                        className="has-children showChildren"
                                      >
                                        <span className="text">TechFista</span>
                                      </li>
                                    </ul>
                                    <ul>
                                      <li id="8587496127026996853ltmu">
                                        <div className="mt-17">
                                          <CustomButton
                                            bgcolor={tertiaryGrey25}
                                            textTransform="Captilize"
                                            color={whiteColor}
                                            padding="3px 11px"
                                            borderRadius="20px"
                                            fontFamily={`${fontFamilyMediumUber}`}
                                            width="fit-content"
                                            type="submit"
                                            title="+  Add"
                                            fontSize="16px"
                                            loading={false}
                                          />
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                                <ul>
                                  <li id="8587496127026996853ltmu">
                                    <div className="mt-17 ms-2">
                                      <CustomButton
                                        bgcolor={tertiaryGrey25}
                                        textTransform="Captilize"
                                        color={whiteColor}
                                        padding="3px 11px"
                                        borderRadius="20px"
                                        fontFamily={`${fontFamilyMediumUber}`}
                                        width="fit-content"
                                        type="submit"
                                        title="+  Add"
                                        fontSize="16px"
                                        loading={false}
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>

                            {/* <ul>
                              <li>
                                <ul>
                                  <li>Categories</li>
                                </ul>
                                <ul>
                                  <li>EnnVisions</li>
                                </ul>
                                <ul>
                                  <li>EnnVisions</li>
                                  <li>
                                    <div className="mt-17">
                                      <CustomButton
                                        bgcolor={tertiaryGrey25}
                                        textTransform="Captilize"
                                        color={whiteColor}
                                        padding="3px 11px"
                                        borderRadius="20px"
                                        fontFamily={`${fontFamilyMediumUber}`}
                                        width="fit-content"
                                        type="submit"
                                        title="+  Add"
                                        fontSize="16px"
                                        loading={false}
                                      />
                                    </div>
                                  </li>
                                </ul>
                                <ul>
                                  <li>
                                    <div className="mt-17">
                                      <CustomButton
                                        bgcolor={tertiaryGrey25}
                                        textTransform="Captilize"
                                        color={whiteColor}
                                        padding="3px 11px"
                                        borderRadius="20px"
                                        fontFamily={`${fontFamilyMediumUber}`}
                                        width="fit-content"
                                        type="submit"
                                        title="+  Add"
                                        fontSize="16px"
                                        loading={false}
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul> */}
                          </div>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerSettingCategoriesStyle>
    </div>
  );
};

export default OrganizerSettingCategories;

import { Dropdown, Menu, Space, Table } from "antd"
import Head from "../../../components/Head/Head"
import { useNavigate } from "react-router-dom"
import actionMenuTogglerIcon from '../../../assets/icons/ic_action_menu_toggler.svg'
import type { ColumnsType } from 'antd/es/table'
import { ListPageStyle } from "../../Academy/AcademyList/style"
import BlueBelt from "../../../assets/images/BlueBelt.svg"
import CustomButton from "../../../components/CustomButton/CustomButton"
import { Black, RobinEggBlue, fontFamilyRegularUber } from "../../../components/GlobalStyle"
import { CoachDataType } from "../constant"
import { useEffect, useState } from "react"
import useCoach from "../Hooks/useCoach"
import defaultPic from '../../../assets/images/create_school_user_profile.svg'
import { RootState } from "../../../app/store"
import { useSelector } from "react-redux"


const AffiliateList = () => {
    const navigate = useNavigate()
    const {showCoachList, setIsShowModal, SuccessModal, WarningModal, deleteConfirmation} = useCoach()
const { data } = useSelector((state: RootState) => state.loginData);

    const [id, setId] = useState(0)
    const [allCoach, setAllCoach] = useState(undefined)
    useEffect(() => {
        const fetchData = async (): Promise<any> => {
            try {
                const response = await showCoachList(data.user.id)
                setAllCoach(response)
            } catch (error) {
                console.log("error", error)
            }
        }
        fetchData()
    }, [])

    const navigation = (record: CoachDataType, redirectTo: string): void => {
        switch (redirectTo) {
            case 'update':
                navigate(`/coach/update/${record.coachId}`)
                break

            case 'view':
                navigate(`/coach/view/${record.coachId}`)
                break
            case 'payment':
                navigate(`/`)
                break

            case 'delete':
                // navigate(`/`)
                break

            
            // case 'report':
            //     handleReportModalVisible()
            //     break
        }
    }
    const columns: ColumnsType<CoachDataType>  = [
        {
            title: 'Id',
            dataIndex: 'coachId',
            key: 'coachId',
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image: any) => (
                <img src={defaultPic} alt="profile" width={60} height={60} />
            )
  
        },
        {
            title: 'Name',
            dataIndex: 'instructorName',
            key: 'instructorName',
            // render: (text) => (
            //     <p>{text.length > 10 ? `${text.slice(0, 10)}...` : text}</p>
            // ),
        },
        {
            title: 'Specialization',
            dataIndex: 'specializations',
            key: 'specializations',
            // render: (DummyData) => {
            //     return <p className="sub-title">{showActivities(DummyData)}</p>
            // },
        },
        {
            title: 'Ranking',
            dataIndex: 'ranking',
            key: 'ranking',
            render: () => (
                <img src={BlueBelt} alt="BlueBelt" />
            )
        },
        {
            title: 'Experience',
            dataIndex: 'experience',
            key: 'experience',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: () => {
                return (
                    <div className={'Active'}>
                        <button>
                            Active
                        </button>
                    </div>
                )
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                const items = [
                    {
                        key: '1',
                        label: 'View',
                        onClick: () => navigation(record, 'view'),

                    },
                    {
                        key: '2',
                        label: 'Update',
                        onClick: () => navigation(record, 'update'),

                    },
                    {
                        key: '3',
                        label: 'Activity',
                        onClick: () => navigation(record, 'activity'),

                    },
                    {
                        key: '4',
                        label: 'Delete',
                        onClick: () => {
                            setId(record.coachId)
                            setIsShowModal(true)
                        },
                    },
                    {
                        key: 'divider1',
                        type: 'divider',
                    },
                    {
                        key: '5',
                        label: 'Reports',
                        // onClick: () => navigation(record, 'report'),
                    },
                ]
                const menu = (
                    <Menu>
                        {items.map((item) => {
                            if (item.type === 'divider') {
                                return <Menu.Divider key={item.key} />
                            }

                            return (
                                <Menu.Item
                                    key={item.key}
                                    onClick={item.onClick}
                                >
                                    {item.label}
                                </Menu.Item>
                            )
                        })}
                    </Menu>
                )

                return (
                    <Space size="middle">
                        <Dropdown overlay={menu}>
                            <img
                                src={actionMenuTogglerIcon}
                                alt="action menu"
                                style={{ cursor: 'pointer' }}
                            />
                        </Dropdown>
                    </Space>
                )
            },
        },
    ]
    return (
        <>
                  {SuccessModal().modalComponent}
            {WarningModal().modalComponent}
            {deleteConfirmation(Number(id), setAllCoach).modalComponent}
            <Head title="ULTM8 - Coach List" />
            {/* {loading && <LoadingOverlay message="" />} */}
            <ListPageStyle>
            <div className="header">
                <h3>Coach List</h3>
                <CustomButton
                    bgcolor={RobinEggBlue}
                    textTransform="uppercase"
                    color={Black}
                    padding="8px 20.50px"
                    fontFamily={fontFamilyRegularUber}
                    width="fit-content"
                    type="submit"
                    title="Create"
                    fontSize="14px"
                    loading={false}
                    clicked={() => {
                        navigate(`/coach/create`)
                    }}
                />
            </div>
            <Table
                    columns={columns}
                    dataSource={allCoach ? allCoach : []}
                    pagination={{
                        showTotal: (total, range) => (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: `Page <span className='paginationVal'>${range[0]}</span> of ${range[1]}`,
                                }}
                            />
                        ),
                    }}
                />
                
            </ListPageStyle>
        </>
    )
}


export default AffiliateList
import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyRegularUber } from "../../../components/GlobalStyle";

export const BuyTicketsSelectUserStyle = styled.div`
.banner img {
    width: 100%;
}
.bodyWrapper {
    width: 100%;
}
.mainContainer {
    max-width: 1244px;
    width: 100%;
    margin: 26px auto 0 auto;
}
.title h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0px;
    padding-left: 4px;
}
.tableContainer {
    with: 100%;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #E0E0E0;
    background-color:  #FFF;
    padding: 0 7px 20px 7px;
    margin-top: 20px;
    margin-bottom: 48px !important;
}
table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 7px;
}

table tbody tr{
    border: 7px solid #fff;
    border-top: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
    border-radius: 6px;
}
table thead tr th:first-child,
table tbody tr td:first-child {
    width: 5%;
    padding-left: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
table thead tr th:nth-child(2),
table tbody tr td:nth-child(2) {
    width: 20%;
    padding-left: 27px;
}
table thead tr th:nth-child(3),
table tbody tr td:nth-child(3) {
    width: 20%;
}
table thead tr th:nth-child(4),
table tbody tr td:nth-child(4) {
    width: 13%;
}
table tbody tr td:nth-child(4) {
    background-color: #FAFAFA;
}
table thead tr th:nth-child(5),
table tbody tr td:nth-child(5) {
    width: 13%;
}
table thead tr th:nth-child(6),
table tbody tr td:nth-child(6) {
    width: 18%;
}
table thead tr th:nth-child(7),
table tbody tr td:nth-child(7) {
    width:6%;
}
table thead tr th:last-child,
table tbody tr td:last-child {
    width: 5%;
    padding-right: 18px;
    text-align: end;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
table thead tr th {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    padding: 39px 0px 0px 10px;
}
table tbody tr td {
    padding: 0px 0px 0px 10px;
}
table tbody tr td {
    border-top: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
}
table tbody tr:nth-child(odd) td {
    border-top: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
    background-color: #FAFAFA;
}
table tbody tr:nth-child(even) td {
    border-top: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
    background-color: #FFF;
}
.profileImaage img {
    width: 60px;
    height: 60px;
    border-radius: 6px 0px 0px 6px;
}
.name span, .academy span,
.affiliations span, .nationality span {
    color: #061229;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.affiliations span {
    font-family: ${fontFamilyEnnVisionsRegular} !important;
    color: #061229 !important;
    background: transparent !important;
    padding: 0 !important;
}
.nationality span {
    color: #000 !important;
    margin-left: 10px; 
    vertical-align: middle;
}
.nationality img {
    width: 30.002px;
    height: 20.323px;
}
.belt {
    
    div {
        div{
            div{
                max-width: 129.26px;
                width: 100%;
                height: 16px;
                background: #fff;
                border: 1px solid #000;
                position: relative;
                div{
                    background: #000;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 15px; 
                }
            }
        }
    }
}
.activeButton {
    padding: 4px 26px 3px 26px;
    border-radius: 4px;
    background-color: #4CAF50;
    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.threeDotSvg img {
    cursor: pointer;
}
`;
import React from "react";
import { ProfileBeforeLoginIntroductionTwoStyle } from "./style";
import EastLaLogo from "../../assets/images/EAST-LA-LOGO.png";

export const ProfileBeforeLoginIntroductionTwo = () => {
  return (
    <div>
      <ProfileBeforeLoginIntroductionTwoStyle>
        <div className="introductionMain">
          <div className="cardContainer">
            <div className="eastLogo">
              <img src={EastLaLogo} alt="EastLaLogo" />
            </div>
            <div className="personName">
              <h4>Adnan Qureshi</h4>
            </div>
            <div className="address">
              <p>
                Location: <span className="cityName">Islamabad,</span>{" "}
                <span className="countryName">Pakistan</span>
              </p>
            </div>
            <div className="phoneNum">
              <p>
                Phone: <span> +966 55 684 3833</span>
              </p>
            </div>
            <div className="age">
              <p>
                Age :<span> 32 Years</span>
              </p>
            </div>
            <div className="nationality">
              <p>
                Nationality: <span>Pakistan</span>
              </p>
            </div>
          </div>
        </div>
      </ProfileBeforeLoginIntroductionTwoStyle>
    </div>
  );
};

export default ProfileBeforeLoginIntroductionTwo;

import { AcademyProfileUpcomingMatchesStyle } from "./AcademyProfileUpcomingMatchesStyle"



const AcademyProfileUpcomingMatches = () => {
  return (
    <AcademyProfileUpcomingMatchesStyle>
    <div className="mainWrapper">
        <h2 className="title">Your upcoming matches</h2>
        <div className="upcomingMatchesCards">
            
        </div>
    </div>
    </AcademyProfileUpcomingMatchesStyle>
  )
}

export default AcademyProfileUpcomingMatches
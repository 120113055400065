import React from 'react'
import { DoubleShowstyle } from "./style"
import { useAppDispatch } from '../../../app/hooks';
import { doubleStallingHandler } from '../../../app/reducer/scoreBoard/SubOnlySlice';
const DoubleShowSubOnly = () => {
    const dispatch = useAppDispatch();

    return (
        <>
            <DoubleShowstyle>
                <div className='double-show' onClick={() => dispatch(doubleStallingHandler()) }>
                    <div className='double-text'>
                        <p className='m-0'>DOUBLE Stalling</p>
                    </div>
                </div>
            </DoubleShowstyle>
        </>

    )
}

export default DoubleShowSubOnly
import styled from "styled-components";
import { fontFamilyBoldUpber, fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../components/GlobalStyle";

export const EventSingleMatchScheduleStyle = styled.div`
.wrapper {
    width: 80%;
    margin: 0 auto;
}
.scheduleHeading {
    margin-top: 14px;
}
.scheduleHeading h4 {
    color: #061229;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.EventSchedule_cardsMain {
    width: 100%;
    display: flex;
    margin-top: 19px;
    gap: 16px;
    margin-bottom: 40px;
}
.matchScheduleCard_Left {
    width: 50%;
}
// Shezar Styling ScheduleCard
.schedule_container_LeftCard{
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #E9E9E9;
    background-color: #F5F5F5;
    // max-width: 518px;
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    padding: 0px 18px 0px 15px;
}
.DropDown-Img{
    width: 12px
    height: 6px;
    fill: #000;
    margin-left: 30px;
}
.Match1_Schedule{
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.36px;
    margin-bottom: 0;
}
.date-time-text{
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.28px;
    margin: 5px 0 0 0;
}
.completeMatches_section2{
    background-color: #fff;
    flex-shrink: 0;
    fill: #FFF;
    border: 0.591px solid #C0D4DC;
    border-radius: 4px
}
.innerSection {
    padding: 0 11px 0 8px;
    height: 42px;
}
.toggleContainer {
    position: relative;
}
.ToggleButton {
    position: absolute;
    top: 5px;
    right: 0px;
}
.section-2-container {
    background-color: rgb(255, 255, 255);
    fill: rgb(255, 255, 255);
    border: 0.591px solid rgb(192, 212, 220);
    border-radius: 4px;
}
.section-2 {
    padding: 0px 10px;
}
.tv-img{
    width: 21px;
    height: 16.634px;
    flex-shrink: 0;
    margin: 10.47px 0px 10.58px 0px
}
.tv-mode{
    color: #232837;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 17.729px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.669px;
    letter-spacing: 0.177px;
    padding-left: 11px;
    margin-bottom: 0;
}
.schedulecontainer_midCard {
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #E9E9E9;
    background: #F5F5F5;
    // max-width: 518px;
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    padding: 0px 18px 0px 15px;
}
.uncomingMatches {
    color: #000;
    text-align: center;
    font-family: ${fontFamilyMediumUber};
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.34px;
    margin-bottom: 0;
}
.completeMatches {
    color: #232837;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
    padding-right: 14px;
    margin-bottom: 0;
}
// Shezar Styling ScheduleCardExpand
.sceduleCardsMain {
    background-color: #fff;
    padding-top: 7px;
}
.scheduleExpendCard-container{
    border: 1px solid var(--Fields-Border, #E9E9E9);
    background: var(--Background, #F5F5F5);
    // width: 518px;
    // height: 65px;
    flex-shrink: 0;
    padding: 10px 12.63px;
}
.expendCard1 {
    margin-top: 10px !important;
}
.Match_Schedule{
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
}
.progress-bar{
    border: 0.985px solid #F4F4F4;
    background: #F4F4F4;
    border: 0.985px solid #F4F4F4;
}
.progress-background{
    width: 117.212px;
    height: 5.91px;
    border: 0.985px solid #F4F4F4;
    flex-shrink: 0;
    margin-top:10.53px;
}
progress::-webkit-progress-bar {
    background-color: white;
}
progress::-webkit-progress-value {
    background-color: #7BB037;
}
.section-time p{
    margin-right:13.62px;
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 13.79px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.699px;
    letter-spacing: 0.276px;
}
.sectionTtime p {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.28px;
    margin-bottom: 0;
}
.matches p{
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 13.79px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.699px;
    letter-spacing: 0.276px;
    margin-bottom:0px; 
}
.section-time p{
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 13.79px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.699px;
    letter-spacing: 0.276px;
    margin-bottom:0px;
}
.progbar-img {
    display: flex;
    justify-content: end;
    margin-top: 8px;
}
// Shezar styling MidCard
.matchScheduleCard_left {
    width: 50%;
    display: flex;
    flex-direction: column;
}
.schedule-container{
    border: 1px solid #E9E9E9;
    background-color: #F5F5F5;
    // width: 518px;
    padding: 0px 14px 0px 14px;
}
.uncomingMatchesCards {
    padding-top: 9px;
    background-color: #fff !important;
}
.uncomingSecondMatch {
    background-color: #fff !important;
}
.Schedule {
    padding-top: 8px;
}
.Mat-Schedule{
    color: #061229;
    font-family: ${fontFamilyRegularUber};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.36px;
    margin-bottom: 0;
}
.progress-bar{
    flex-shrink: 0;
    border: 0.985px solid #F4F4F4;
    background: #F4F4F4;
    border: 0.985px solid #F4F4F4;
}
.progress-background{
    width: 117.212px;
    height: 5.91px;
    border: 0.985px solid #F4F4F4;
    margin-top:10.53px;
}
progress::-webkit-progress-bar {
    background-color: white;
}
progress::-webkit-progress-value {
    background-color: #7BB037;
}
.section-time{
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 13.79px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.699px;
    letter-spacing: 0.276px;
}
.section-time p{ 
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 13.79px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.699px;
    letter-spacing: 0.276px;
    margin-bottom:0px;
    text-align: right;
}
.dot{
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #0070E0;
}
/////////////////////////////////////////////////////////PARTICIPANTS///////////////////////////////////////////////////////////
.card {
    display: flex;
    max-width: 301.002px;
    width:100%;
    height:auto;
    justify-content: space-between;
    padding-right: 9px;
    margin: 13px 11px 10px 14px;
}
 .participant-img {
    width: 64.258px;
    height: 100.466px;
    border-radius: 6px 0px 0px 0px;
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
    width: 62.16px;
    height: 58.182px;
}
.participate-content{
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 11.683px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px; /* 192.109% */
}
.participate-content .para{
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 13.63px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.444px;
}
.flag-container{
    margin-left: 7px;
    margin-top: 4px;
}
.flag-image img{
    margin-left: 9px;
    margin-bottom: 11px;
}
.country-flag{
    display: flex;
    align-items: end;
    margin-bottom:8.63px;
}
.para{
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.818px; /* 136.364% */
}
.para-text{
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.818px;
}
hr {
     margin: 0px; 
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: .25;
}
.sandbox-section{
    width: 162px;
    flex-shrink: 0;
    border-radius: 5.45px;
    border: 1px solid #E5E3F6;
    background: #F5F5F9;
    margin-top: 9px;
}
.sandbox-img {
    width: 24.609px;
    height: 35px;
    margin:20px 21.39px 19px 27px;
}
.sandbox-text{
    color: #000;
    text-align: center;
    font-family: ${fontFamilyRegularUber};
    font-size: 33.461px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin:17px 26px 17px 0px;
}
.tick-section{
    width: 71px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #C2DEC8;
    margin-left: auto;
    margin-top: 5px;
}
.tick-img{
    width: 12px;
    height: 11px;
    margin: 5px 9px 6px 8px;
}
.tick-text{
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 3px 7px 2px 0px;
}
.vs {
    border-radius: 15.5px;
    border: 1px solid #16374E;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    width: 28px;
    height: 28px;
    background:  #16374E;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 45px;
    left: -11px;
}
.vs-text{
    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.818px;
    letter-spacing: -0.154px;
    margin-bottom: 0;
}
.matchScheduleCard_Right {
    width: 28%;
    margin-left: 1%;
}
.liveMatchHeading h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.scoreBoard {
    margin-top: 12px;
}
`;
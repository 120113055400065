import { Dropdown, Menu } from "antd";
import Head from "../../../components/Head/Head";
import { Link, useNavigate } from "react-router-dom";
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import CustomButton from "../../../components/CustomButton/CustomButton";
import {
  Black,
  RobinEggBlue,
  fontFamilyRegularUber,
} from "../../../components/GlobalStyle";
import { useEffect, useState } from "react";
import defaultPic from "../../../assets/images/create_school_user_profile.svg";
import { List, Rate } from "antd";
import placeHolderImage from "../../../assets/images/custom_card_placeholder.png";
import CardsStyle from "../../Academy/AcademyList/CardsStyle";
import useBranch from "../Hooks/useBranch";
import api from "../../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

const desc = ["terrible", "bad", "normal", "good", "wonderful"];

const menuProps = (items: any) => (
  <Menu>
    {items.map((item: any) => {
      if (item.type === "divider") {
        return <Menu.Divider key={item.key} />;
      }

      return (
        <Menu.Item key={item.key} onClick={() => item.onClick()}>
          {item.label}
        </Menu.Item>
      );
    })}
  </Menu>
);

const BranchListCards = () => {
  const navigate = useNavigate();
  const {
    showBranchList,
    setIsShowModal,
    SuccessModal,
    WarningModal,
    deleteConfirmation,
    AcademyWarningModal,
    setIsWarningModal,
  } = useBranch();

  const [value, setValue] = useState(3);
  const [redirectTo, setRedirectTo] = useState("");
  const [branchId, setbranchId] = useState(-1);
  const [id, setId] = useState(0);
  const [academyId, setAcademyId] = useState<any>(null);
  const [allBranch, setAllBranch] = useState<any>([]);
  const { data } = useSelector((state: RootState) => state.loginData);
  const adminId = Number(data.user.id);

  const items: any = [
    {
      key: "1",
      label: "View",
      onClick: () => setRedirectTo("view"),
    },
    {
      key: "2",
      label: "Update",
      onClick: () => setRedirectTo("update"),
    },
    {
      key: "4",
      label: "Delete",
      onClick: () => {
        setId(branchId);
        setIsShowModal(true);
      },
    },
    {
      key: "divider1",
      type: "divider",
      onClick: () => setRedirectTo(""),
    },
    {
      key: "5",
      label: "Reports",
      onClick: () => setRedirectTo("reports"),
    },
  ];

  useEffect(() => {
    if (redirectTo !== "" && branchId !== -1)
      navigate(`/branch/${redirectTo}/${branchId}`);
  }, [redirectTo]);

  useEffect(() => {
    const fetchData = async (): Promise<any> => {
      try {
        const response = await showBranchList(academyId);
        setAllBranch(response);
      } catch (error) {
        console.log("Failed to fetch data:", error);
      }
    };

    if (academyId) fetchData();
  }, [academyId]);

  useEffect(() => {
    const getAcademy = async () => {
      try {
        const { data } = await api("post", `/api/academy/findallbyuser`, {
          adminId,
        });

        if (data && data.result && data.result.length > 0) {
          const firstAcademy = data.result[0];
          const { academyid } = firstAcademy;
          setAcademyId(academyid);
        }
      } catch (error: any) {
        if (error.response.data.message === "Academy not found") {
          setIsWarningModal(true);
        }

        console.error("Error fetching academy data:", error?.response?.data);
      }
    };

    getAcademy();
  }, []);

  return (
    <>
      {SuccessModal().modalComponent}
      {WarningModal().modalComponent}
      {deleteConfirmation(Number(id), setAllBranch).modalComponent}
      {AcademyWarningModal().modalComponent}
      <Head title="ULTM8 - Branch List" />

      <CardsStyle>
        <div className="header">
          <h3>Branch List</h3>
          <CustomButton
            bgcolor={RobinEggBlue}
            textTransform="uppercase"
            color={Black}
            padding="8px 20.50px"
            fontFamily={fontFamilyRegularUber}
            width="fit-content"
            type="submit"
            title="Create"
            fontSize="14px"
            loading={false}
            clicked={() => {
              navigate(`/branch/create`);
            }}
          />
        </div>
        <div className="cardsMainContainer">
          {allBranch?.map((item: any, i: number) => {
            return (
              <div className="custom_card" key={i}>
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <img
                        src={defaultPic}
                        alt="profile"
                        width={40}
                        height={40}
                      />
                    }
                    title={item.branchName}
                    description={item.address}
                  />
                  {/* <Space size="middle"> */}
                  <Dropdown trigger={["click"]} overlay={menuProps(items)}>
                    <img
                      src={actionMenuTogglerIcon}
                      alt="action menu"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setbranchId(item.branchId);
                      }}
                    />
                  </Dropdown>
                  {/* </Space> */}
                </List.Item>
                <div className="custom_card_placeholder_img" id="cardImg">
                  <img src={placeHolderImage} alt="CardImg" id="cardImg" />
                </div>
                <div className="custom_card_para">
                  {`${item?.description.slice(0, 120)}...`}
                </div>
                <div className="custom_card_footer">
                  <div className="custom_card_footer_rating">
                    <Rate tooltips={desc} onChange={setValue} value={value} />
                    {value ? (
                      <span className="ant-rate-text">
                        {/* {desc[value - 1]} */}
                        <span>4.6</span>
                        <span> (780+ )</span>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <Link to={"#"} className="custom_card_footer_link">
                    Learn More
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </CardsStyle>
    </>
  );
};

export default BranchListCards;

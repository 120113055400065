import React, { useState } from "react";
import { OrganizerProfileStyle } from "./style";
import { GlobalContainer } from "../Home/style";
import { Layout } from "antd";
import AcademyProfileBannerImage from "../../assets/images/AcademyProfileBanner.png";
import OrganizerProfileTabs from "../../components/OrganizerProfileTabs/OrganizerProfileTabs";
import OrganizerProfileLeft from "../../components/OrganizerProfileLeft/organizerEventList/OrganizerEventList";

const { Content } = Layout;

const OrganizerProfile = ({ props }: any) => {
  const [activeTab, setActiveTab] = useState("tab1");
  return (
    <OrganizerProfileStyle>
      <GlobalContainer>
        <Layout>
          <div className="banner">
            <img
              src={AcademyProfileBannerImage}
              alt="AcademyProfileBannerImage"
            />
          </div>
          <Layout>
            <Content className="contentBeforeLogin-left">
              <OrganizerProfileLeft />
            </Content>
            <Content className="contentBeforeLogin-right">
              <OrganizerProfileTabs />
            </Content>
          </Layout>

        </Layout>
      </GlobalContainer>
    </OrganizerProfileStyle>
  );
};

export default OrganizerProfile;

import React, { useState, useEffect } from "react";
import { UltmScroreBoardstyle } from "./style";

import ingTimeBoardLeft from "../../../assets/icons/ic_ringTimeBoardLeft.svg";
import ingTimeBoardRight from "../../../assets/icons/ic_ringTimeBoardRight.svg";
import PlayBtn from "../../../assets/icons/ic_playBtn.svg";
import PauseBtn from "../../../assets/icons/ic_pauseBtn.svg";
import PauseIcon from "../../../assets/icons/ic_pause_ScoreBoard.svg";
import ScoreBoardTable from "../../../components/ScoreBoardComponents/ScoreBoardTable/ScoreBoardTable";
import EndMatchScoreBoardTrad from "../../../components/EndMatchScoreBoardTrad/EndMatchScoreBoardTrad";
import EndMatchSave from "../../../components/ScoreBoardComponents/EndMatch/EndMatch";
import SwapArrowDown from "../../../assets/icons/ic_ArrowDownScoreBoard.svg";
import SwapArrowUp from "../../../assets/icons/is_ArrowUpScoreBoard.svg";
import Minus30Icon from "../../../assets/icons/scoreBoardIcons/minus30.svg";
import Minus1Icon from "../../../assets/icons/scoreBoardIcons/minus1.svg";
import PlayIcon from "../../../assets/icons/scoreBoardIcons/playIcon.svg";
import Plus1Icon from "../../../assets/icons/scoreBoardIcons/plus1.svg";
import Plus30Icon from "../../../assets/icons/scoreBoardIcons/plus30.svg";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  clearMedicalTimer,
  clearStallingTimer,
  clearTimeoutTimer,
  handleMatchTimer,
  handleMedicalTimer,
  handleStallingTimer,
  handleTimeoutTimer,
  handleUpdateMatchTimer,
  showMedicalModalHandler,
  showStallingModalHandler,
  showTimeoutModalHandler,
  swapMedicalTimerHandler,
  swapStallingTimerHandler,
  swapTimeoutTimerHandler,
  updateEndGameMessage,
  updateEndGameMessageB,
  updateEndGameMessageType,
  updateMatchTimer,
  updateMedicalTimerHandler,
  updatePlayer,
  updateScoreBoard,
  updateScoreHistory,
  updateStallingTimerHandler,
  updateTimeoutTimerHandler,
  scoreBoardHandler,
  advantageBoardHandler,
  penaltyBoardHandler,
  showVarModalHandler,
} from "../../../app/reducer/scoreBoard/UltmScoreboardSlice";
import { useAppDispatch } from "../../../app/hooks";
import DoubleShowUltm from "../../../components/ScoreBoardComponents/DoubleShow/DoubleShowUltm";
import DoubleWoUltm from "../../../components/ScoreBoardComponents/DoubleWo/DoubleWoUltm";
import WonByUltm from "../../../components/ScoreBoardComponents/WonBy/WonByUltm";
import ModelScoreBoardMedical from "../../../components/ModelScoreBoardMedical/ModelScoreBoardMedical";
import ModelScoreBoardVar from "../../../components/ModelScoreBoardVar/ModelScoreBoardVar";
// import ModelScoreBoardVar from "../../../components/ModelScoreBoardVar/ModelScoreBoardVar";
// import ModelScoreBoardMedical from "../../../components/ModelScoreBoardMedical/ModelScoreBoardMedical";
// import ModelScoreBoardBacktoBracket from "../../../components/ModelScoreBoardBacktoBracket/ModelScoreBoardBacktoBracket";
// import DoubleShow from "../../../components/ScoreBoardComponents/DoubleShow/DoubleShowTraditional";
// import ModelScoreBoard from "../../../components/ModelScoreBoard/ModelScoreBoard";
// import ModelScoreBoardBacktoBracketStyle from "../../../components/ModelScoreBoardBacktoBracket/ModelScoreBoardBacktoBracket";
// import ModelScoreBoardSave from "../../../components/ModelScoreBoardSave/ModelScoreBoardSave";
// import WonBy from "../../../components/ScoreBoardComponents/WonBy/WonBy";
// import DoubleWo from "../../../components/ScoreBoardComponents/DoubleWo/DoubleWoTraditional";

interface ScoreHistoryProps {
  playerId: number;
  score?: number;
  penalty?: number;
  advantage?: number;
}

const UltmScoreBoard: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    teamOneScoreBoard,
    teamTwoScoreBoard,
    scoreHistory,
    playerOne,
    playerTwo,
    matchTimer,
    endGameMessage,
    endGameMessageType,
    endGameMessageB,
  } = useSelector((state: RootState) => state.ultmScoreboard);

  // Section 1 and 2 functionality states

  const [showScoreBoardTableOne, setShowScoreBoardTableOne] = useState(true);
  const [showScoreBoardTableTwo, setShowScoreBoardTableTwo] = useState(true);

  // Section 3 functionality states
  const [showEndGameModal, setShowEndGameModal] = useState(false);
  const [endGame, setEndGame] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [showVarWarningModal, setShowVarWarningModal] = useState(false);
  const [showMedicalWarningModal, setShowMedicalWarningModal] = useState(false);

  const [isShowTimerActions, setIsShowTimerACtions] = useState(false);

  console.log("checking teamOneScoreBoard: ", teamOneScoreBoard);

  // common handlers
  const handlePropagation = (event: any) => {
    event.stopPropagation();
  };

  const formatTime = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const remainingSeconds = timer % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return ` ${formattedMinutes}:${formattedSeconds} `;
  };

  const handleScoreBoardScore = (_currentTeam: number, _score: number) => {
    dispatch(
      scoreBoardHandler({
        currentTeam: _currentTeam,
        score: _score,
      })
    );
  };
  const handleScoreBoardAdvantage = (
    _currentTeam: number,
    _advantage: number
  ) => {
    dispatch(
      advantageBoardHandler({
        currentTeam: _currentTeam,
        advantage: _advantage,
      })
    );
  };

  const handleScoreBoardPenalty = (_currentTeam: number, _penalty: number) => {
    dispatch(
      penaltyBoardHandler({
        currentTeam: _currentTeam,
        penalty: _penalty,
      })
    );
  };

  // Section 3 functionality handlers

  const endGameBackHandle = (): void => {
    setIsSave(false);
    dispatch(
      updateEndGameMessage({
        updatedEndGameMessage: "",
      })
    );
    dispatch(
      updateEndGameMessageB({
        updatedEndGameMessageB: "",
      })
    );
    dispatch(
      updateEndGameMessageType({
        updatedEndGameMessageType: false,
      })
    );
    setEndGame(false);
    setShowScoreBoardTableOne(true);
    setShowScoreBoardTableTwo(true);
    dispatch(
      updateScoreBoard({
        currentTeam: 1,
        updatedScoredBoard: {
          ...teamOneScoreBoard,
          score: 0,
          penalty: 0,
          advantage: 0,
        },
      })
    );
    dispatch(
      updateScoreBoard({
        currentTeam: 2,
        updatedScoredBoard: {
          ...teamTwoScoreBoard,
          score: 0,
          penalty: 0,
          advantage: 0,
        },
      })
    );
    dispatch(
      updateMatchTimer({
        updatedMatchTimer: {
          ...matchTimer,
          matchTimerValue: 300,
        },
      })
    );
  };

  const handleEndGameButton = (): void => {
    if (matchTimer.isMatchTimerPaying) {
      dispatch(handleMatchTimer());
    }
    clearInterval(teamOneScoreBoard.isMedicalPlaying);
    clearInterval(teamOneScoreBoard.isStallingPlaying);
    clearInterval(teamOneScoreBoard.isTimeoutPlaying);
    dispatch(
      updateScoreBoard({
        currentTeam: 1,
        updatedScoredBoard: {
          ...teamOneScoreBoard,

          medicalValue: 20,
          isMedicalModal: false,
          isMedicalPlaying: undefined,

          stallingValue: 20,
          isStallingModal: false,
          isStallingPlaying: undefined,

          timeoutValue: 20,
          isTimeoutModal: false,
          isTimeoutPlaying: undefined,

          isVarModal: false,
        },
      })
    );
    clearInterval(teamTwoScoreBoard.isMedicalPlaying);
    clearInterval(teamTwoScoreBoard.isStallingPlaying);
    clearInterval(teamTwoScoreBoard.isTimeoutPlaying);
    dispatch(
      updateScoreBoard({
        currentTeam: 2,
        updatedScoredBoard: {
          ...teamTwoScoreBoard,

          medicalValue: 20,
          isMedicalModal: false,
          isMedicalPlaying: undefined,

          stallingValue: 20,
          isStallingModal: false,
          isStallingPlaying: undefined,

          timeoutValue: 20,
          isTimeoutModal: false,
          isTimeoutPlaying: undefined,

          isVarModal: false,
        },
      })
    );
    setEndGame(true);
    setShowScoreBoardTableOne(false);
    setShowScoreBoardTableTwo(false);
  };

  const switchSideHandler = () => {
    console.log("checking i am here");

    const backupPlayerOne = { ...playerOne };
    dispatch(
      updatePlayer({
        currentPlayer: 1,
        updatedPlayer: { ...playerTwo },
      })
    );
    dispatch(
      updatePlayer({
        currentPlayer: 2,
        updatedPlayer: { ...backupPlayerOne },
      })
    );
    const backupTeamOneScoreBoard = {
      ...teamOneScoreBoard,
    };

    dispatch(
      updateScoreBoard({
        currentTeam: 1,
        updatedScoredBoard: {
          ...teamTwoScoreBoard,
        },
      })
    );
    dispatch(
      updateScoreBoard({
        currentTeam: 2,
        updatedScoredBoard: {
          ...backupTeamOneScoreBoard,
        },
      })
    );
  };

  const undoScoringActionHandler = () => {
    if (!scoreHistory.length) return;
    const _scoreHistory = [...scoreHistory];

    let lastScoreAction: ScoreHistoryProps =
      _scoreHistory.pop() as ScoreHistoryProps;
    if (lastScoreAction.playerId === playerOne.id) {
      const updatedState = { ...teamOneScoreBoard };
      if (lastScoreAction.score) {
        updatedState.score = updatedState.score - lastScoreAction.score;
      }
      if (lastScoreAction.advantage) {
        updatedState.advantage =
          updatedState.advantage - lastScoreAction.advantage;
      }
      if (lastScoreAction.penalty) {
        updatedState.penalty = updatedState.penalty - lastScoreAction.penalty;
      }
      dispatch(
        updateScoreBoard({
          currentTeam: 1,
          updatedScoredBoard: updatedState,
        })
      );
    }

    if (lastScoreAction.playerId === playerTwo.id) {
      const updatedState = { ...teamTwoScoreBoard };
      if (lastScoreAction.score) {
        updatedState.score = updatedState.score - lastScoreAction.score;
      }
      if (lastScoreAction.advantage) {
        updatedState.advantage =
          updatedState.advantage - lastScoreAction.advantage;
      }
      if (lastScoreAction.penalty) {
        updatedState.penalty = updatedState.penalty - lastScoreAction.penalty;
      }
      dispatch(
        updateScoreBoard({
          currentTeam: 2,
          updatedScoredBoard: updatedState,
        })
      );
    }

    dispatch(
      updateScoreHistory({
        updatedHistory: _scoreHistory,
      })
    );
  };

  const isVarWarningModal = () => {
    if (showVarWarningModal) return;
    if (teamOneScoreBoard.isVarModal || teamTwoScoreBoard.isVarModal) {
      setShowVarWarningModal(true);
      return true;
    }

    return false;
  };

  const isMedicalWarningModal = () => {
    if (showMedicalWarningModal) return;
    if (teamOneScoreBoard.isMedicalModal || teamTwoScoreBoard.isMedicalModal) {
      setShowMedicalWarningModal(true);
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (endGameMessage || endGameMessageB) {
      setEndGame(false);
      setIsSave(true);
      // setEndGameMessage('')
      // setEndGameMessageB('')
    }
  }, [endGameMessage, endGameMessageB]);

  useEffect(() => {
    function openOrFocusTab(url: string) {
      if (window.opener && !window.opener.closed) {
        window.opener.close();
      }
      const newTab = window.open(
        url,
        "_blank",
        "toolbar=no,location=no,menubar=no"
      );
      newTab?.postMessage("goFullscreen", url);
    }
    const targetURL =
      window.location.origin + "/score-board/ultm-score-board-monitor";
    openOrFocusTab(targetURL);
  }, []);

  return (
    <UltmScroreBoardstyle>
      <div id="ultmScoreBoard-fullscreen">
        <div id="ultmScorePlayers">
          <div className="ultmPlayer player playerLeft ">
            <div>
              {endGameMessage && (
                <div
                  className={`result ${
                    !endGameMessageType ? "submission" : "both"
                  }`}
                >
                  <span>
                    <span className="winner-text">{endGameMessage}</span>
                  </span>
                </div>
              )}
            </div>
            <div
              className="playerControls"
              onClick={() =>
                !endGame && setShowScoreBoardTableOne(!showScoreBoardTableOne)
              }
            >
              <div>
                <div className="playerAttributes">
                  <div className="attributeRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <img src={playerOne.countryFlag} className="flag" />
                        <span className="name truncate">
                          <span className="firstname">
                            {playerOne.playerFirstName}{" "}
                          </span>
                          <span className="lastname">
                            {playerOne.playerLastName}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="subheader truncate">
                      <span className="countryName">
                        {playerOne.countryName}
                      </span>
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate">
                          <div className="clubName truncate">
                            <img
                              src={playerOne.clubFlag}
                              className="clubIcon imgCircle"
                            />
                            <span>{playerOne.clubName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showScoreBoardTableOne && (
              <div className="table1Left">
                <ScoreBoardTable
                  currentTeam={1}
                  handleScoreBoardScore={handleScoreBoardScore}
                  handleScoreBoardAdvantage={handleScoreBoardAdvantage}
                  handleScoreBoardPenalty={handleScoreBoardPenalty}
                  showStallingModalHandler={() =>
                    dispatch(showStallingModalHandler(1))
                  }
                  showMedicalModalHandler={() =>
                    dispatch(showMedicalModalHandler(1))
                  }
                  showTimeoutModalHandler={() =>
                    dispatch(showTimeoutModalHandler(1))
                  }
                  showVarModalHandler={() => dispatch(showVarModalHandler(1))}
                />
              </div>
            )}
            {endGame && (
              <div className="wonbyLight">
                <WonByUltm />
              </div>
            )}

            <div className={`score2`}>
              {/* medical */}
              {teamOneScoreBoard.isMedicalModal && (
                <div className={`sec1 medical`}>
                  <h2>
                    {formatTime(teamOneScoreBoard.medicalValue as number)}
                  </h2>
                  <p>Medical</p>
                  <div className="boardScorePlayer2">
                    <div className="scoreBoardTable">
                      <div className="ScoreBoardTableInner">
                        <div className="ScoreBoardTableInner1">
                          <div
                            className="ScoreBoardTableInner11"
                            onClick={() => dispatch(swapMedicalTimerHandler(1))}
                          >
                            <p>
                              SWAP
                              <img src={SwapArrowDown} alt="swap arrow" />
                            </p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner2">
                          <div
                            className="ScoreBoardTableInner21"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateMedicalTimerHandler(
                                  teamOneScoreBoard.medicalValue - 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardLeft} alt="" />
                            <p>1</p>
                          </div>
                          <div className="ScoreBoardTableInner22">
                            {/* <img src={PlayBtn} alt="" /> */}
                            {/* <img src={PauseBtn} alt="" /> */}
                            <button
                              className="playPauseButton"
                              onClick={() => {
                                teamOneScoreBoard.isMedicalPlaying
                                  ? dispatch(showMedicalModalHandler(1))
                                  : dispatch(
                                      handleMedicalTimer(1, teamOneScoreBoard)
                                    );
                              }}
                            >
                              {teamOneScoreBoard.isMedicalPlaying ? (
                                <img src={PauseBtn} alt="Pause" />
                              ) : (
                                <img src={PlayBtn} alt="Play" />
                              )}
                            </button>
                          </div>
                          <div
                            className="ScoreBoardTableInner23"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateMedicalTimerHandler(
                                  teamOneScoreBoard.medicalValue + 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardRight} alt="" />
                            <p>1</p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner3">
                          <div
                            className="ScoreBoardTableInner31"
                            onClick={() => {
                              teamOneScoreBoard.isMedicalPlaying
                                ? dispatch(clearMedicalTimer(1))
                                : dispatch(updateMedicalTimerHandler(20, 1));
                            }}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            {/* {teamOneScoreBoard.isMedicalPlaying ? `<img src={PauseIcon} alt='pause'>` : `<p>RESET</p>`} */}
                            {teamOneScoreBoard.isMedicalPlaying ? (
                              <img src={PauseIcon} alt="pause" />
                            ) : (
                              <p>RESET</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* ************* stalling 1 **************** */}
              {/* stalling */}
              {teamOneScoreBoard.isStallingModal && (
                <div className={`sec1 stalling`}>
                  <h2>
                    {formatTime(teamOneScoreBoard.stallingValue as number)}
                  </h2>
                  <p>Stalling</p>
                  <div className="boardScorePlayer2">
                    <div className="scoreBoardTable">
                      <div className="ScoreBoardTableInner">
                        <div className="ScoreBoardTableInner1">
                          <div
                            className="ScoreBoardTableInner11"
                            onClick={() =>
                              dispatch(swapStallingTimerHandler(1))
                            }
                          >
                            <p>
                              SWAP
                              <img src={SwapArrowDown} alt="swap arrow" />
                            </p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner2">
                          <div
                            className="ScoreBoardTableInner21"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateStallingTimerHandler(
                                  teamOneScoreBoard.stallingValue - 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardLeft} alt="" />
                            <p>1</p>
                          </div>
                          <div className="ScoreBoardTableInner22">
                            <button
                              className="playPauseButton"
                              onClick={() => {
                                teamOneScoreBoard.isStallingPlaying
                                  ? dispatch(showStallingModalHandler(1))
                                  : dispatch(
                                      handleStallingTimer(1, teamOneScoreBoard)
                                    );
                              }}
                            >
                              {teamOneScoreBoard.isStallingPlaying ? (
                                <img src={PauseBtn} alt="Pause" />
                              ) : (
                                <img src={PlayBtn} alt="Play" />
                              )}
                            </button>
                          </div>
                          <div
                            className="ScoreBoardTableInner23"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateStallingTimerHandler(
                                  teamOneScoreBoard.stallingValue + 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardRight} alt="" />
                            <p>1</p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner3">
                          <div
                            className="ScoreBoardTableInner31"
                            onClick={() => {
                              teamOneScoreBoard.isStallingPlaying
                                ? dispatch(clearStallingTimer(1))
                                : dispatch(updateStallingTimerHandler(20, 1));
                            }}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            {teamOneScoreBoard.isStallingPlaying ? (
                              <img src={PauseIcon} alt="pause" />
                            ) : (
                              <p>RESET</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* time out */}
              {teamOneScoreBoard.isTimeoutModal && (
                <div className={`sec1 timeout`}>
                  <h2>
                    {formatTime(teamOneScoreBoard.timeoutValue as number)}
                  </h2>
                  <p>TIMEOUT</p>
                  <div className="boardScorePlayer2">
                    <div className="scoreBoardTable">
                      <div className="ScoreBoardTableInner">
                        <div className="ScoreBoardTableInner1">
                          <div
                            className="ScoreBoardTableInner11"
                            onClick={() => dispatch(swapTimeoutTimerHandler(1))}
                          >
                            <p>
                              SWAP
                              <img src={SwapArrowDown} alt="swap arrow" />
                            </p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner2">
                          <div
                            className="ScoreBoardTableInner21"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateTimeoutTimerHandler(
                                  teamOneScoreBoard.timeoutValue - 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardLeft} alt="" />
                            <p>1</p>
                          </div>
                          <div className="ScoreBoardTableInner22">
                            <button
                              className="playPauseButton"
                              onClick={() => {
                                teamOneScoreBoard.isTimeoutPlaying
                                  ? dispatch(showTimeoutModalHandler(1))
                                  : dispatch(
                                      handleTimeoutTimer(1, teamOneScoreBoard)
                                    );
                              }}
                            >
                              {teamOneScoreBoard.isTimeoutPlaying ? (
                                <img src={PauseBtn} alt="Pause" />
                              ) : (
                                <img src={PlayBtn} alt="Play" />
                              )}
                            </button>
                          </div>
                          <div
                            className="ScoreBoardTableInner23"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateTimeoutTimerHandler(
                                  teamOneScoreBoard.timeoutValue + 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardRight} alt="" />
                            <p>1</p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner3">
                          <div
                            className="ScoreBoardTableInner31"
                            onClick={() => {
                              teamOneScoreBoard.isTimeoutPlaying
                                ? dispatch(clearTimeoutTimer(1))
                                : dispatch(updateTimeoutTimerHandler(20, 1));
                            }}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            {teamOneScoreBoard.isTimeoutPlaying ? (
                              <img src={PauseIcon as string} alt="pause" />
                            ) : (
                              <p>RESET</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Var assitance */}
              {/* {teamOneScoreBoard.isVarModal && (
                <div className={`sec1 varAssistant`}>
                  <h2>VAR</h2>
                  <p>MODE ON</p>
                </div>
              )} */}
            </div>
          </div>
          {endGame ? (
            <div className="doubleShowsComp1">
              <DoubleWoUltm />
            </div>
          ) : (
            <div className="doubleShowsComp">
              {!endGameMessage.length && !endGameMessageB.length ? (
                <DoubleShowUltm />
              ) : (
                ""
              )}
            </div>
          )}
          <div className="ultmPlayer player playerRight">
            <div>
              {endGameMessageB && (
                <div
                  className={`result ${
                    !endGameMessageType ? "submission" : "both"
                  }`}
                >
                  <span>
                    <span className="winner-text">{endGameMessageB}</span>
                  </span>
                </div>
              )}
            </div>
            {/* <div className="playerControls"> */}
            <div
              className="playerControls"
              onClick={() =>
                !endGame && setShowScoreBoardTableTwo(!showScoreBoardTableTwo)
              }
            >
              <div>
                <div className="playerAttributes">
                  <div className="attributeRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <span className="name truncate">
                          <span className="firstname">
                            {playerTwo.playerFirstName}{" "}
                          </span>
                          <span className="lastname">
                            {playerTwo.playerLastName}
                          </span>
                        </span>
                        <img src={playerTwo.countryFlag} className="flag" />
                      </div>
                    </div>
                    <div className="subheader truncate">
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate">
                          <div
                            className="truncate clubFlag"
                            style={{ width: "auto !important" }}
                          >
                            <img
                              src={playerTwo.clubFlag}
                              className="clubIcon imgCircle"
                            />
                          </div>
                          <div className="clubName truncate">
                            <span>{playerTwo.clubName}</span>
                          </div>
                        </div>
                      </div>
                      <span className="countryName">
                        {playerTwo.countryName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {showScoreBoardTableTwo && (
              <div className="table2Right">
                <ScoreBoardTable
                  currentTeam={2}
                  handleScoreBoardScore={handleScoreBoardScore}
                  handleScoreBoardAdvantage={handleScoreBoardAdvantage}
                  handleScoreBoardPenalty={handleScoreBoardPenalty}
                  showStallingModalHandler={() =>
                    dispatch(showStallingModalHandler(2))
                  }
                  showMedicalModalHandler={() =>
                    dispatch(showMedicalModalHandler(2))
                  }
                  showTimeoutModalHandler={() =>
                    dispatch(showTimeoutModalHandler(2))
                  }
                  showVarModalHandler={() => dispatch(showVarModalHandler(2))}
                />
              </div>
            )}
            {endGame && (
              <div className="wonbyDark">
                <WonByUltm isDark={true} />
              </div>
            )}
            <div className={`score2`}>
              {/* medical */}
              {teamTwoScoreBoard.isMedicalModal && (
                <div className={`sec1 medical swapArrow`}>
                  <h2>
                    {formatTime(teamTwoScoreBoard.medicalValue as number)}
                  </h2>
                  <p>Medical</p>
                  <div className="boardScorePlayer2">
                    <div className="scoreBoardTable">
                      <div className="ScoreBoardTableInner">
                        <div className="ScoreBoardTableInner1">
                          <div
                            className="ScoreBoardTableInner11 ScoreBoardTableInnerRight"
                            onClick={() => dispatch(swapMedicalTimerHandler(2))}
                          >
                            <p>
                              <img src={SwapArrowUp} alt="swap arrow" />
                              SWAP
                            </p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner2">
                          <div
                            className="ScoreBoardTableInner21"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateMedicalTimerHandler(
                                  teamTwoScoreBoard.medicalValue - 1,
                                  2
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardLeft} alt="" />
                            <p>1</p>
                          </div>
                          <div className="ScoreBoardTableInner22">
                            <button
                              className="playPauseButton"
                              onClick={() => {
                                teamTwoScoreBoard.isMedicalPlaying
                                  ? dispatch(showMedicalModalHandler(2))
                                  : dispatch(
                                      handleMedicalTimer(2, teamTwoScoreBoard)
                                    );
                              }}
                            >
                              {teamTwoScoreBoard.isMedicalPlaying ? (
                                <img src={PauseBtn} alt="Pause" />
                              ) : (
                                <img src={PlayBtn} alt="Play" />
                              )}
                            </button>
                          </div>
                          <div
                            className="ScoreBoardTableInner23"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateMedicalTimerHandler(
                                  teamTwoScoreBoard.medicalValue + 1,
                                  2
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardRight} alt="" />
                            <p>1</p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner3">
                          <div
                            className="ScoreBoardTableInner31"
                            onClick={() => {
                              teamTwoScoreBoard.isMedicalPlaying
                                ? dispatch(clearMedicalTimer(2))
                                : dispatch(updateMedicalTimerHandler(20, 2));
                            }}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            {teamTwoScoreBoard.isMedicalPlaying ? (
                              <img src={PauseIcon} alt="pause" />
                            ) : (
                              <p>RESET</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* ************* stalling 2 **************** */}
              {/* stalling */}
              {teamTwoScoreBoard.isStallingModal && (
                <div className={`sec1 stalling swapArrow`}>
                  <h2>
                    {formatTime(teamTwoScoreBoard.stallingValue as number)}
                  </h2>
                  <p>Stalling</p>
                  <div className="boardScorePlayer2">
                    <div className="scoreBoardTable">
                      <div className="ScoreBoardTableInner">
                        <div className="ScoreBoardTableInner1">
                          <div
                            className="ScoreBoardTableInner11 ScoreBoardTableInnerRight"
                            onClick={() =>
                              dispatch(swapStallingTimerHandler(2))
                            }
                          >
                            <p>
                              <img src={SwapArrowUp} alt="swap arrow" />
                              SWAP
                            </p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner2">
                          <div
                            className="ScoreBoardTableInner21"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateStallingTimerHandler(
                                  teamTwoScoreBoard.stallingValue - 1,
                                  2
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardLeft} alt="" />
                            <p>1</p>
                          </div>
                          <div className="ScoreBoardTableInner22">
                            <button
                              className="playPauseButton"
                              onClick={() => {
                                teamTwoScoreBoard.isStallingPlaying
                                  ? dispatch(showStallingModalHandler(2))
                                  : dispatch(
                                      handleStallingTimer(2, teamTwoScoreBoard)
                                    );
                              }}
                            >
                              {teamTwoScoreBoard.isStallingPlaying ? (
                                <img src={PauseBtn} alt="Pause" />
                              ) : (
                                <img src={PlayBtn} alt="Play" />
                              )}
                            </button>
                          </div>
                          <div
                            className="ScoreBoardTableInner23"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateStallingTimerHandler(
                                  teamTwoScoreBoard.stallingValue + 1,
                                  2
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardRight} alt="" />
                            <p>1</p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner3">
                          <div
                            className="ScoreBoardTableInner31"
                            onClick={() => {
                              teamTwoScoreBoard.isStallingPlaying
                                ? dispatch(clearStallingTimer(2))
                                : dispatch(updateStallingTimerHandler(20, 2));
                            }}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            {teamTwoScoreBoard.isStallingPlaying ? (
                              <img src={PauseIcon} alt="pause" />
                            ) : (
                              <p>RESET</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* time out */}

              {teamTwoScoreBoard.isTimeoutModal && (
                <div className={`sec1 timeout`}>
                  <h2>
                    {formatTime(teamTwoScoreBoard.timeoutValue as number)}
                  </h2>
                  <p>TIMEOUT</p>

                  <div className="boardScorePlayer2">
                    <div className="scoreBoardTable">
                      <div className="ScoreBoardTableInner">
                        <div className="ScoreBoardTableInner1">
                          <div
                            className="ScoreBoardTableInner11 ScoreBoardTableInnerRight"
                            onClick={() => dispatch(swapTimeoutTimerHandler(2))}
                          >
                            <p>
                              <img src={SwapArrowUp} alt="swap arrow" />
                              SWAP
                            </p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner2">
                          <div
                            className="ScoreBoardTableInner21"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateTimeoutTimerHandler(
                                  teamTwoScoreBoard.timeoutValue - 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardLeft} alt="" />
                            <p>1</p>
                          </div>
                          <div className="ScoreBoardTableInner22">
                            <button
                              className="playPauseButton"
                              onClick={() => {
                                teamTwoScoreBoard.isTimeoutPlaying
                                  ? dispatch(showTimeoutModalHandler(2))
                                  : dispatch(
                                      handleTimeoutTimer(2, teamTwoScoreBoard)
                                    );
                              }}
                            >
                              {teamTwoScoreBoard.isTimeoutPlaying ? (
                                <img src={PauseBtn} alt="Pause" />
                              ) : (
                                <img src={PlayBtn} alt="Play" />
                              )}
                            </button>
                          </div>
                          <div
                            className="ScoreBoardTableInner23"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                updateTimeoutTimerHandler(
                                  teamTwoScoreBoard.timeoutValue + 1,
                                  1
                                )
                              )
                            }
                          >
                            <img src={ingTimeBoardRight} alt="" />
                            <p>1</p>
                          </div>
                        </div>
                        <div className="ScoreBoardTableInner3">
                          <div
                            className="ScoreBoardTableInner31"
                            onClick={() => {
                              teamTwoScoreBoard.isTimeoutPlaying
                                ? dispatch(clearTimeoutTimer(2))
                                : dispatch(updateTimeoutTimerHandler(20, 2));
                            }}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            {teamTwoScoreBoard.isTimeoutPlaying ? (
                              <img src={PauseIcon} alt="pause" />
                            ) : (
                              <p>RESET</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* {teamTwoScoreBoard.isVarModal && (
                <div className={`sec1 varAssistant`}>
                  <h2>VAR</h2>
                  <p>MODE ON</p>
                </div>
              )} */}
            </div>
          </div>
        </div>

        {teamOneScoreBoard.isVarModal ? (
            <div className="score2 varScore2">
              <div className={`sec1 varAssistant`}>
                <h2>VAR</h2>
                <p>MODE ON</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {teamTwoScoreBoard.isVarModal ? (
            <div className="score2 varScore2">
              <div className={`sec1 varAssistant`}>
                <h2>VAR</h2>
                <p>MODE ON</p>
              </div>
            </div>
          ) : (
            ""
          )}

        <div className="playersScoreSection" id="ultmScorePlayers">
          <div className="ultmPlayer player playerLeft">
            <div className="scoresContainer">
              <div
                className="mainScore"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  className={`count ${
                    teamOneScoreBoard.score <= 9
                      ? "digits-1"
                      : teamOneScoreBoard.score <= 99
                      ? "digits-1"
                      : "digits-1"
                  }`}
                >
                  {" "}
                  {teamOneScoreBoard.score}{" "}
                </div>
              </div>
              <div className="extraScores">
                <div className="advantage ">
                  <div className="adv points">
                    {teamOneScoreBoard.advantage}
                  </div>
                  <div>
                    {teamOneScoreBoard.advantage > 1
                      ? "Advantages"
                      : "Advantage"}
                  </div>
                </div>
                <div className="penalty ">
                  <div className="pen points">{teamOneScoreBoard.penalty}</div>
                  <div>
                    {" "}
                    {teamOneScoreBoard.penalty > 1 ? "penalties" : "Penalty"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ultmPlayer player playerRight">
            <div className="scoresContainer">
              <div
                className="mainScore"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  className={`count ${
                    teamTwoScoreBoard.score <= 9
                      ? "digits-1"
                      : teamTwoScoreBoard.score <= 99
                      ? "digits-1"
                      : "digits-1"
                  }`}
                >
                  {" "}
                  {teamTwoScoreBoard.score}{" "}
                </div>
              </div>
              <div className="extraScores">
                <div className="advantage ">
                  <div className="adv points">
                    {teamTwoScoreBoard.advantage}
                  </div>
                  <div>
                    {teamTwoScoreBoard.advantage > 1
                      ? "Advantages"
                      : "Advantage"}
                  </div>
                </div>
                <div className="penalty ">
                  <div className="pen points">{teamTwoScoreBoard.penalty}</div>
                  <div>
                    {" "}
                    {teamTwoScoreBoard.penalty > 1 ? "penalties" : "Penalty"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tradScoreboardBottom">
          <div
            style={{ flexGrow: "1", width: "0px", zIndex: "2" }}
            onClick={() => setShowEndGameModal(!showEndGameModal)}
          >
            <div className="matchInfoContainer">
              <div className="matchNumber">
                <span style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className={`count ${
                      teamOneScoreBoard.score <= 9
                        ? "digits-1"
                        : teamOneScoreBoard.score <= 99
                        ? "digits-2"
                        : "digits-3"
                    }`}
                  >
                    1
                  </div>
                  -
                  <div
                    className={`count ${
                      teamOneScoreBoard.score <= 9
                        ? "digits-1"
                        : teamOneScoreBoard.score <= 99
                        ? "digits-2"
                        : "digits-3"
                    }`}
                  >
                    1
                  </div>
                </span>
              </div>
              <div className="matchInfo">
                <div className="matchInfoText">
                  <div className="bracketDivision">
                    <div className="scMarquee">
                      <div className="marqueeContainer animate">
                        <span className="marqueeText">
                          Boys Gi / Mighty Mite I (4 yrs) / -17,90 kg / Grey
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bracketRound">
                    <div className="scMarquee primary">
                      <div className="marqueeContainer">
                        <span className="marqueeText">Semifinals</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="matchControls123">
              {showEndGameModal && !endGame && !isSave && (
                <EndMatchScoreBoardTrad
                  undoScoringActionHandler={undoScoringActionHandler}
                  switchSideHandler={switchSideHandler}
                  handleEndGameButton={handleEndGameButton}
                />
              )}
            </div>

            {isSave && (
              <div className="endGameBottom">
                <EndMatchSave endGameBackHandle={endGameBackHandle} />
              </div>
            )}

            {/* <div className="modelScoresBoard">
              <ModelScoreBoard />
            </div> 
            <div className="modelScoresBoardBacktoBracket">
              <ModelScoreBoardBacktoBracket />
            </div>
            <div className="modelScoresBoardBacktoBracket">
              <ModelScoreBoardSave />
            </div>*/}
            {showMedicalWarningModal && (
              <div className="ModelScoreBoardMedical">
                <ModelScoreBoardMedical
                  closeMedicalModalHandler={() =>
                    setShowMedicalWarningModal(false)
                  }
                />
              </div>
            )}
            {showVarWarningModal && (
              <div className="ModelScoreBoardMedical">
                <ModelScoreBoardVar
                  closeVarModalHandler={() => setShowVarWarningModal(false)}
                />
              </div>
            )}
          </div>

          <div
            id="clock"
            onMouseEnter={() => setIsShowTimerACtions(true)}
            onMouseLeave={() => setIsShowTimerACtions(false)}
          >
            <div className="time running">
              <div
                className="count "
                style={{
                  color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                }}
              >
                <p
                  style={{
                    color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                  }}
                >
                  {formatTime(matchTimer.matchTimerValue)}
                </p>
              </div>
            </div>
            <div className="control">
              <div className="controlWrapper">
                {isShowTimerActions && (
                  <div className="actions" onClick={handlePropagation}>
                    <table className="actionsWrapper">
                      <tbody>
                        <tr>
                          <td
                            onClick={() =>
                              !isVarWarningModal() &&
                              !isMedicalWarningModal() &&
                              dispatch(
                                handleUpdateMatchTimer(
                                  matchTimer.matchTimerValue - 30
                                )
                              )
                            }
                            className="btn minus"
                          >
                            <img src={Minus30Icon} alt="minus_val" />
                          </td>
                          <td
                            onClick={() =>
                              !isVarWarningModal() &&
                              !isMedicalWarningModal() &&
                              dispatch(
                                handleUpdateMatchTimer(
                                  matchTimer.matchTimerValue - 1
                                )
                              )
                            }
                            className="btn minus"
                          >
                            <img src={Minus1Icon} alt="" />
                          </td>
                          {!matchTimer.isMatchTimerPaying ? (
                            <td
                              onClick={() =>
                                !isVarWarningModal() &&
                                !isMedicalWarningModal() &&
                                dispatch(handleMatchTimer())
                              }
                            >
                              <img src={PlayIcon} alt="" />
                            </td>
                          ) : (
                            <td onClick={() => dispatch(handleMatchTimer())}>
                              <img src={PauseBtn} alt="Pause" />
                            </td>
                          )}

                          <td
                            onClick={() =>
                              !isVarWarningModal() &&
                              !isMedicalWarningModal() &&
                              dispatch(
                                handleUpdateMatchTimer(
                                  matchTimer.matchTimerValue + 1
                                )
                              )
                            }
                            className="btn plus"
                          >
                            <img src={Plus1Icon} alt="" />
                          </td>
                          <td
                            onClick={() =>
                              !isVarWarningModal() &&
                              !isMedicalWarningModal() &&
                              dispatch(
                                handleUpdateMatchTimer(
                                  matchTimer.matchTimerValue + 30
                                )
                              )
                            }
                            className="btn plus"
                          >
                            <img src={Plus30Icon} alt="" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </UltmScroreBoardstyle>
  );
};
export default UltmScoreBoard;

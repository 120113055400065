import styled from "styled-components";
import {fontFamilyRegularUber, lightDark2, mediaDeviceMax, pureDark2, tertiaryGrey24 } from "../../../components/GlobalStyle";

export const FormPageStyle = styled.div`
padding: 16px;
margin-bottom: 20px;
h3 {
    font-weight: 400;
    font-size: 16px;
    font-family: ${fontFamilyRegularUber};
    color: ${pureDark2};
    margin-bottom: 2px;
}
.form {
    padding: 20px;
    border-radius: 12px;
    @media screen and ${mediaDeviceMax.mobileBS} {
        padding: 20px 5px;
    }
}
@media screen and ${mediaDeviceMax.mobileBS} {
    .bVkyTH {
        width: 100% !important;
    }
}
.mt-20 {
    .row {
        @media screen and ${mediaDeviceMax.tablet} {
            gap: 20px;
        }
    }
}
.checkBoxPara {
    margin-bottom: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: ${fontFamilyRegularUber};
}

.payment_card {
    border: 1px solid #d9d9d9;
    padding: 16px;
    border-radius: 20px;
}

.ant-input {
    input::placeholder {
        color: ${lightDark2} !important;
    }

    @media screen and (${mediaDeviceMax.laptop}) {
        padding: 10px !important;
    }
}


div > label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
        color: #4f4f4f;
        font-family: ${fontFamilyRegularUber};
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
`

export const StudentViewStyling = styled.div`
    padding: 16px;
    .ant-card-bordered {
        border: none;
    }
    .ant-card-body {
        margin-top: 30px;
        padding: 20px;
    }
    h3 {
        color: ${pureDark2};
        font-size: 16px;
        font-family: ${fontFamilyRegularUber};
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    @media screen and ${mediaDeviceMax.mobileBS} {
        .gZsrvq {
            width: 100%;
        }
    }

    .ant-card.ant-card-bordered {
        border-radius: 12px;

        .list-item {
            border-bottom: 1px solid ${tertiaryGrey24};
            padding-bottom: 8px;
            margin-bottom: 20px;

            &-title {
                color: ${lightDark2};
                font-family: ${fontFamilyRegularUber};
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media screen and ${mediaDeviceMax.laptop} {
                    font-size: 12px;
                }
            }

            &-value {
                margin-top: 6px;
                font-size: 14px;
                color: ${pureDark2};
                font-weight: 400;
                font-family: ${fontFamilyRegularUber};
                word-break: break-word;

                @media screen and ${mediaDeviceMax.laptop} {
                    font-size: 15px;
                }
            }
        }
    }
    @media screen and ${mediaDeviceMax.mobileBS} {
        .image_section {
            margin-bottom: 90px;
        }
        .profileImg > .img {
            width: 120px;
            height: 120px;
            bottom: -60px;
            left: 0;
            right: 0;
            margin: auto;
        }
        .bannerImg {
            height: 200px;
        }
    }
`

import { useEffect, useState } from "react";
import { Drawer } from "antd";
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import NavbarStyle from "./style";
import CustomButton from "../CustomButton/CustomButton";
import {
  ChineseWhite,
  JapaneseIndigo,
  fontFamilyRegularUber,
  pureDark2,
  whiteColor,
} from "../GlobalStyle";
import { Link, useNavigate } from "react-router-dom";
import HomePageLogo from "../../assets/images/ULTM8.svg";
import Login from "../../screens/Login/Login";
import RegisterUser from "../../screens/Register/Register";
import ForgotPassword from "../../screens/ForgotPassword/ForgotPasword";
import Otp from "../../screens/ForgotPassword/Otp/Otp";
import CreatePassword from "../../screens/ForgotPassword/CreatePassword/CreatePassword";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

function Navbar() {
  const navigate = useNavigate();

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isForgotModalVisible, setIsForgotModalVisible] = useState(false);
  const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
  const [isCreatePassModalVisible, setIsCreatePassModalVisible] = useState(false);
  const [isRegisterModal, setIsRegisterModal] = useState(false);
  const { data } = useSelector((state: RootState) => state.loginData);

  const handleLoginButtonClick = () => {
    setIsLoginModalVisible(true);
  };
  const handleRegisterButtonClick = () => {
    setIsRegisterModal(true);
  };

  useEffect(()=>{
    if(data.loggedIn) navigate("/profile-after-login");
  }, [data])

  return (
    <>
      <NavbarStyle>
        <Drawer
          visible={drawerVisible}
          placement="left"
          onClick={() => setDrawerVisible(false)}
          onClose={() => setDrawerVisible(false)}
          width={300}
        >
          <NavigationMenu />
        </Drawer>
        <div className="top-side d-flex justify-content-between">
          <div className="logoNavHeader">
            <Link to="/">
              <img src={HomePageLogo} alt="" />
            </Link>
          </div>
          <div className="navBar-AuthBtns d-flex align-items-center gap-3">
            <div onClick={handleLoginButtonClick}>
              <CustomButton
                type="submit"
                title="LOGIN"
                fontSize="14px"
                textTransform="uppercase"
                color={whiteColor}
                bgcolor={JapaneseIndigo}
                fontFamily={fontFamilyRegularUber}
                padding="12px"
                width="127px"
              />
            </div>
            {isLoginModalVisible && (
              <Login
                isModalVisible={isLoginModalVisible}
                setIsModalVisible={setIsLoginModalVisible}
                setIsRegisterModalVisible={setIsRegisterModal}
                setIsForgotModalVisible={setIsForgotModalVisible}
              />
            )}
            <div onClick={handleRegisterButtonClick}>
              <CustomButton
                bgcolor={ChineseWhite}
                color={pureDark2}
                type="submit"
                title="Sign Up"
                fontSize="14px"
                textTransform="uppercase"
                fontFamily={fontFamilyRegularUber}
                padding="12px"
                width="127px"
              />
            </div>
            {isRegisterModal && (
              <RegisterUser
                isModalVisible={isRegisterModal}
                setIsModalVisible={setIsRegisterModal}
                setIsLoginModalVisible={setIsLoginModalVisible}
              />
            )}
          </div>
        </div>
      </NavbarStyle>
      {isForgotModalVisible && (
        <ForgotPassword
          isModalVisible={isForgotModalVisible}
          setIsModalVisible={setIsForgotModalVisible}
          setIsOtpModalVisible={setIsOtpModalVisible}

        />
      )}
      {isOtpModalVisible && (
        <Otp
          isModalVisible={isOtpModalVisible}
          setIsModalVisible={setIsOtpModalVisible}
          setIsCreatePassModalVisible={setIsCreatePassModalVisible}
        />
      )}
      {isCreatePassModalVisible && (
        <CreatePassword
          isModalVisible={isCreatePassModalVisible}
          setIsModalVisible={setIsCreatePassModalVisible}
        />
      )}
    </>
  );
}

export default Navbar;

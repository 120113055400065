import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../../components/GlobalStyle"; 

export const AffiliationsProfileStyle = styled.div`
.wrapper {
    width: 100%;
    display: flex;
}
.bodyLeftSideMain {
    width: 25%;
    margin-right: 34px;
}
.introductionParagraph {
    max-width: 359px;
    width: 100%;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding: 14px 12px 10px 17px;
    margin-top: 18px;
}
.introductionParagraph h6 {
    color: #000;
    text-align: justify;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.introductionParagraph p {
    color: #4F4F4F;
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 5px;
    margin-bottom: 0;
}
.mapFrame {
    max-width: 357px;
    width: 100%;
    border-radius: 0px 0px 9px 9px;
    border: 0px solid #E0E0E0;
    margin-top: 21px;
}
.bodyRightSideMain {
    width: 75%;
}
.blockContainerMain {
    width: 100%;
    margin-top: 18px;
}
.Headings_SeeAll {
    display: flex; 
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
}
.Headings_SeeAll h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
    padding: 5px 0 0 1px;
}
.Headings_SeeAll span {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.217px;
    letter-spacing: 0.28px;
    cursor: pointer;
}
.CardsComponentsMain {
    display: flex;
    flex-wrap: wrap;
    gap: 11.68px 10.51px;
    margin-top: 17px;
    margin-left: 2.28px;
}
.CardsComponentsMain > div {
    max-width: 24.27%;
    width: 100%;
}
.block2_container {
    margin: 23.07px 0 0 0px !important;
}
.block2_CardsComponent {
    gap: 19px !important;
    margin-top: 11.2px !important;
}
.block2_CardsComponent > div {
    max-width: 32.17%;
    width: 100%;
}
.block3_container {
    margin: 28.67px 0 26.43px 0px !important;
}
.block3_CardsComponent {
    gap: 18.74px !important;
    margin-top: 13.5px !important;
}
.block3_CardsComponent > div {
    max-width: 32.18%;
    width: 100%;
}

`;
import styled from "styled-components";
import {
  fontFamilyMedium,
  fontFamilyMediumUber,
} from "../../../components/GlobalStyle";

export const OrganizeSettingEditBracketStyle = styled.div`
  .ant-layout > .banner + .ant-layout {
    flex-direction: row;
    gap: 10px;
  }
  .title {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-top: 30px;
  }
  .organizeSettingEditBracket-left {
    max-width: 15%;
    width: 100%;
  }
  .form {
    padding: 18px 24px 20px 17px;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #e0e0e0;
    margin-top: 15px;
  }
  .deleteBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 50px;
    padding: 11px;
    border-radius: 4px;
    background: linear-gradient(0deg, #eac1ca 0%, #eac1ca 100%),
      rgba(0, 0, 0, 0.47);
  }
  .SNUsD {
    margin-bottom: 0px;
  }
  .addOpt {
    max-width: 25%;
    width: 100%;
    margin-top: 13px;
  }
  .addOpt span {
    color: #0070e0;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
  .form Row Col FormControl {
    height: 47px;
  }
`;

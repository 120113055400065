import React from "react";
import { EventBracketsStyle } from "./style";
import ParticipantsFrame from "../../assets/icons/ParticipantsFrame.svg";
import PrintIcon from "../../assets/icons/PrintIcon.svg";
import UploadtIcon from "../../assets/icons/UploadIcon.svg";
import SpainFlag from "../../assets/images/spanish_flag.svg";
import japanFlag from "../../assets/images/japan_flag.svg";
import yahya_img from "../../assets/images/yahya_ahmad.svg";
import Nico_img from "../../assets/images/Nico_porter.svg";

export const EventBrackets = () => {
  return (
    <div>
      <EventBracketsStyle>
        <div className="wrapper">
          <div className="bracketsHeading">
            <h4>Brackets</h4>
          </div>

          <div className="participantBlock1">
            <div className="">
              <div className="frameMain">
                <img src={ParticipantsFrame} alt="ParticipantsFrame" />
              </div>
            </div>
            <div className="categoryHeading">
              <h3>Male Gi / White / Adult / -76 kg (Light)</h3>
            </div>
            <div className="areaBracket_Type">
              <div className="areaMat1">
                <p>Area</p>
                <span>Mat 1</span>
              </div>
              <div className="bracketType">
                <p>Bracket type</p>
                <span>Single Elimination ShortCut Bronze</span>
              </div>
              <div className="participants">
                <p>Participants</p>
                <span>64</span>
              </div>
              <div className="nrOfMatches">
                <p>Nr of matches</p>
                <span>63</span>
              </div>
              <div className="timePerMatch">
                <p>Time per match</p>
                <span>5 Min</span>
              </div>
            </div>
            <div className="participantBlock1_rightSide">
              <div className="printIcon">
                <img src={PrintIcon} alt="PrintIcon" />
              </div>
              <div className="uploadIcon">
                <img src={UploadtIcon} alt="UploadtIcon" />
              </div>
            </div>
          </div>

          <div className="bracketsBlock2_Main">
            <div className="finals32Th_Main">
              <div className="heading_32TH">
                <h4>32TH-FINALS</h4>
              </div>

              <div className="schedule-container  align-items-center justify-content-between">
                <div className="d-flex  align-items-center justify-content-between">
                  <div className="card d-flex position-relative">
                    <div className="d-flex justify-content-between position-relative">
                      <div className="d-flex align-items-center">
                        <img
                          src={yahya_img}
                          alt=""
                          className="participant-img"
                        />
                        <div className="participate-img d-flex flex-column">
                          <div className="d-flex align-items-center ms-2">
                            {" "}
                            <p className="para m-0">Yahya Ahmad</p>{" "}
                          </div>
                          <p className="para-text m-0 ms-2"> New Wave Aca...</p>
                        </div>
                      </div>
                      <div className="country-flag">
                        <img src={SpainFlag} alt="" />
                      </div>

                      <div className="position-absolute dot"> </div>
                    </div>

                    <hr />
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          src={Nico_img}
                          alt=""
                          className="participant-img"
                        />
                        <div className="participate-img d-flex flex-column">
                          <div className="d-flex align-items-center ms-1">
                            {" "}
                            <p className="para m-0">Nico Porter</p>
                          </div>
                          <p className=" para-text m-0 ms-1">Impact Gym...</p>
                        </div>
                      </div>
                      <div className="country-flag">
                        <img src={japanFlag} alt="" />
                      </div>
                    </div>

                    <div className="vs position-absolute">
                      <p className="vs-text">1-1</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="schedule-container  align-items-center justify-content-between">
                <div className="d-flex  align-items-center justify-content-between">
                  <div className="card d-flex position-relative">
                    <div className="d-flex justify-content-between position-relative">
                      <div className="d-flex align-items-center">
                        <img
                          src={yahya_img}
                          alt=""
                          className="participant-img"
                        />
                        <div className="participate-img d-flex flex-column">
                          <div className="d-flex align-items-center ms-2">
                            {" "}
                            <p className="para m-0">Yahya Ahmad</p>{" "}
                          </div>
                          <p className="para-text m-0 ms-2"> New Wave Aca...</p>
                        </div>
                      </div>
                      <div className="country-flag">
                        <img src={SpainFlag} alt="" />
                      </div>
                    </div>

                    <hr />
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          src={Nico_img}
                          alt=""
                          className="participant-img"
                        />
                        <div className="participate-img d-flex flex-column">
                          <div className="d-flex align-items-center ms-1">
                            {" "}
                            <p className="para m-0">Nico Porter</p>
                          </div>
                          <p className=" para-text m-0 ms-1">Impact Gym...</p>
                        </div>
                      </div>
                      <div className="country-flag">
                        <img src={japanFlag} alt="" />
                      </div>
                      <div className="position-absolute dot-2"> </div>
                    </div>

                    <div className="vs position-absolute">
                      <p className="vs-text">1-2</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="schedule-container  align-items-center justify-content-between">
                <div className="d-flex  align-items-center justify-content-between">
                  <div className="card d-flex position-relative">
                    <div className="d-flex justify-content-between position-relative">
                      <div className="d-flex align-items-center">
                        <img
                          src={yahya_img}
                          alt=""
                          className="participant-img"
                        />
                        <div className="participate-img d-flex flex-column">
                          <div className="d-flex align-items-center ms-2">
                            {" "}
                            <p className="para m-0">Yahya Ahmad</p>{" "}
                          </div>
                          <p className="para-text m-0 ms-1"> New Wave Aca...</p>
                        </div>
                      </div>
                      <div className="country-flag">
                        <img src={SpainFlag} alt="" />
                      </div>

                      <div className="position-absolute dot"> </div>
                    </div>

                    <hr />
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          src={Nico_img}
                          alt=""
                          className="participant-img"
                        />
                        <div className="participate-img d-flex flex-column">
                          <div className="d-flex align-items-center ms-1">
                            {" "}
                            <p className="para m-0">Nico Porter</p>
                          </div>
                          <p className=" para-text m-0 ms-1">Impact Gym...</p>
                        </div>
                      </div>
                      <div className="country-flag">
                        <img src={japanFlag} alt="" />
                      </div>
                    </div>

                    <div className="vs position-absolute">
                      <p className="vs-text">1-1</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="schedule-container  align-items-center justify-content-between">
                <div className="d-flex  align-items-center justify-content-between">
                  <div className="card d-flex position-relative">
                    <div className="d-flex justify-content-between position-relative">
                      <div className="d-flex align-items-center">
                        <img
                          src={yahya_img}
                          alt=""
                          className="participant-img"
                        />
                        <div className="participate-img d-flex flex-column">
                          <div className="d-flex align-items-center ms-2">
                            {" "}
                            <p className="para m-0">Yahya Ahmad</p>{" "}
                          </div>
                          <p className="para-text m-0 ms-2"> New Wave Aca...</p>
                        </div>
                      </div>
                      <div className="country-flag">
                        <img src={SpainFlag} alt="" />
                      </div>
                    </div>

                    <hr />
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          src={Nico_img}
                          alt=""
                          className="participant-img"
                        />
                        <div className="participate-img d-flex flex-column">
                          <div className="d-flex align-items-center ms-1">
                            {" "}
                            <p className="para m-0">Nico Porter</p>
                          </div>
                          <p className=" para-text m-0 ms-1">Impact Gym...</p>
                        </div>
                      </div>
                      <div className="country-flag">
                        <img src={japanFlag} alt="" />
                      </div>
                      <div className="position-absolute dot-2"> </div>
                    </div>

                    <div className="vs position-absolute">
                      <p className="vs-text">1-2</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </EventBracketsStyle>
    </div>
  );
};
export default EventBrackets;

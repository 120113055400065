import styled from "styled-components";
import { fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegularUber } from "../../../components/GlobalStyle";
export const CoachProfileMemberStyle = styled.div`
.bodyWrapper_RightSide {
    width: 100%;
    margin-top: 20px;
}
.title_CreateUser {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
}
.membersTitle h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.createUserButtonsMain {
    display: flex;
    align-items: center;
    gap: 8px;
}
.labelButton {
    padding: 7px 20px 6px 20px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.4px;
    text-align: center;
}
.createUserButton {
    padding: 7px 10px 6px 10px;
    border-radius: 4px;
    background: linear-gradient(0deg, #E4E4E4 0%, #E4E4E4 100%), rgba(0, 0, 0, 0.47);
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.4px;
}
.pendingMembers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 14px 9px 12px;
    border: 1px solid #F4F4F4;
    background-color: #EFEFEF;
    margin-top: 16px;
    margin-left: 12px;
}
.pendingMembers h4, .activeMembers h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.pendingMembers span, .activeMembers span{
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.217px;
    letter-spacing: 0.28px;
    cursor: pointer;
}
table {
    width: 100%;
    padding-left: 12px;
    border-collapse: separate;
    border-spacing: 0px 20px;
}
table tbody tr td {
    // width: fit-content !important;
    // max-width: 100%;
    // padding: 0px 34px !important;
    height: 69px;
    border-top: 1.029px solid #F4F4F4;
    border-bottom: 1.029px solid #F4F4F4;
}
table tbody tr:nth-child(even) td {
    border-top: 1.029px solid #E9E9E9;
    border-bottom: 1.029px solid #E9E9E9;
}
table tbody tr td:first-child {
    width: 5.9% !important;
    padding: 0 !important;
    border-top-left-radius: 4.11px;
    border-bottom-left-radius: 4.11px;
}
table tbody tr td:nth-child(2) {
    width: 25%;
    padding: 0 15px 0 10px !important;
}
table tbody tr td:nth-child(3) {
    width: 14.1%;
    padding: 0 15px 0 10px !important;
}
table tbody tr td:nth-child(4) {
    width: 20%;
    padding: 0 15px 0 10px !important;
    text-align: right;
}
table tbody tr td:nth-child(5) {
    width: 35%;
    text-align: right;
    padding: 0 12px 0 10px !important;
    border-top-right-radius: 4.11px;
    border-bottom-right-radius: 4.11px;
}
table tbody tr:nth-child(odd) {
    background-color: #FFF
}
table tbody tr:nth-child(even) {
    background-color: #F5F5F5;
}
.PendingMebersImage {
    width: 64px;
    height: 69px;
}
.pendingMembersDetails {
    display: flex;
    flex-direction: column;
}
.pendingMembersDetails p {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.444px; /* 140.278% */
    margin-bottom: 0;
}
.countryFlag_Name {
    display: flex;
    align-items: center;
    margin-top: 4px;
}
.countryFlag_Name img {
    width: 27px;
    height: 27px;
}
.countryFlag_Name span {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px;
    margin-left: 7px;
}
.moreDetails {
    display: flex;
    flex-direction: column;
}
.moreDetails p {
    color: #333;
    text-align: right;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px;
    margin-bottom: 0;
}
.moreDetails p:first-child {
    padding-bottom: 6px !important;
}
.age_or_plusButton {
    display: flex;
    flex-direction: column;

    .flex.flex-col.gap-2 {
        div{
            position: relative;
            div{
                // max-width: 129.26px;
                width: 89px;
                height: 11px;
                background: #fff;
                border: 1px solid #000;
                position: relative;
                div{
                    background: #000;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 15px; 
                }
            }
        }
    }    
}
.age_or_plusButton span {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px;
}
.progressBar_plusButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6.73px;
    margin-top: 6px;
}
.progressBar_plusButton img {
    width: 91.266px;
    height: 11.278px;
}
.plusButton {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background: linear-gradient(0deg, rgb(92, 111, 128) 0%, rgb(92, 111, 128) 100%), rgba(0, 0, 0, 0.47);
    display: flex;
    align-items: center;
    justify-content: center;
}
.viewProfileButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
}
.viewProfileButton {
    padding: 6px 9px 7px 10px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.4px;
}
.membersButton {
    padding: 6px 12px 7px 9px;
    border-radius: 4px;
    border: 0.6px solid #959595;
    background-color: #EFEFEF;
    display: flex;
    align-items: center;
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.4px;
}
.membersButton img {
    width: 7px;
    height: 13px;
    margin-left: 9px;
}
.activeMembers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 34px 14px 0 24px;
}
.activeMembersList {
    margin: 0px 0 41px 0px !important;
    padding-left: 13px !important;
}
`;
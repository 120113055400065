import styled from "styled-components";
import {
  AlabamaCrimson,
  AmericanYellow,
  Dandelion,
  DarkCornflowerBlue,
  DarkGreen,
  MustardYellow,
  SilverSand,
  SpringFrost,
  fontFamilyBold,
  fontFamilyBoldUpber,
  fontFamilyFuturaCondenseBold,
  fontFamilyMedium,
  fontFamilyMediumUber,
  fontFamilyRegular,
  lightColor1,
  lightDark3,
  pureDark,
  whiteColor,
} from "../../../components/GlobalStyle";
export const AdccUltmScoreBoardStyle = styled.div`
  #tradScoreboard {
    font-family: ${fontFamilyBold};
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.1;
    background: #1e1f23;
  }

  #tradScoreboard * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  #tradScoreboard {
    position: relative;
    overflow: hidden;
    min-width: 132vh;
    touch-action: pan-x pan-y;
  }

  .messagesShows .submission .winner-text {
    color: ${pureDark};
    font-family: ${fontFamilyBold};
    font-size: 8.1vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.31px;
    text-transform: uppercase;
    padding: 0.6vh 1.5vh;
    background: #fdba13 !important;
  }

  .messagesBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    justify-content: center;
  }
  .messagesBody .playerAttr .attrRows {
    text-align: center;
  }
  .messagesBody .playerName {
    font-size: 17vh;
    width: 100%;
    max-width: 100%;
  }
  .messagesBody .playerAttr .subheader {
    font-size: 9vh;
  }
  .messagesBody .playerName img {
    vertical-align: middle;
  }
  .messagesBody .playerDetails {
    margin-top: 1vh;
  }
  .messagesBody .trad-header .name {
    color: ${whiteColor};
    font-family: ${fontFamilyMedium};
    font-size: 15vh;
  }

  #tradScoreboard .control {
    position: absolute;
    top: 74%;
    display: flex;
    align-items: center;
    z-index: 6;
    transform-origin: 0 50%;
    z-index: 9999;
    left: auto;
    right: 15vh;
    margin: auto;
    text-align: center;
    display: block;
  }
  #tradScoreboard .control .actions {
    display: flex;
    border-radius: 0.8vh;
    transition: box-shadow 1s;
    font-size: 3vh;
  }

  #tradScoreboard .control .actions td {
    position: relative;
    width: auto;
    background: rgba(0, 0, 0, 0.4);
    font-size: 1em;
    padding-right: 1em;
    padding-left: 1em;
    height: 7vh;
    display: table-cell;
    white-space: nowrap;
    cursor: pointer !important;
    text-align: center;
    border-radius: 0;
    line-height: 0.8;
  }

  #tradScoreboard .control .actions td svg,
  #tradScoreboard .control .actions td img {
    height: 3vh;
    width: auto;
  }

  #tradScoreboard .control .actions td.btn {
    border: 0;
  }

  #tradScoreboard .control .actions td:hover {
    box-shadow: inset 0 0 1px 2px #3d3d3d !important;
  }

  #tradScoreboard .control .actions td.submenu table {
    height: 100%;
    width: 100%;
  }

  #tradScoreboard .control .actions table {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    box-shadow: 0 0 5vh 0 #000;
    margin-right: 2vh;
    border-radius: 0.8vh;
    overflow: hidden;
  }

  #tradScoreboard .control .actions table:hover {
    box-shadow: 0 0 6vh 1vh #000;
    transition: box-shadow 0.5s;
  }
  .boardScorePlayer2 {
    opacity: 0;
  }
  .score2 .sec1:hover {
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.6) -1px -1px 4vh 2vh inset;
    z-index: 99;
  }
  .score2 .sec1:hover .boardScorePlayer2 {
    opacity: 1;
  }
  #tradScoreboard #tradScoreboardPlayers {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 35vh;
  }
  #tradScoreboard #tradScoreboardPlayersScoreSection {
    height: 35vh;
  }

  #tradScoreboard #tradScoreboardPlayers > .player {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    // padding-left: 2em;
    transition: all;
    background: #25262b;
  }

  #tradScoreboard #tradScoreboardPlayers > .playerRight {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    transition: all 0s ease 0s;
    background: rgb(37, 38, 43);
    flex-direction: row-reverse;
  }
  #tradScoreboardPlayers .playerLeft .score2 {
    right: 0px;
    bottom: 0px;
    margin-bottom: 2vh;
    margin-right: 1vw;
  }
  #tradScoreboardPlayers .playerRight .score2 {
    bottom: 0px;
    margin-bottom: 2vh;
    margin-left: 1vw;
  }
  #tradScoreboard #tradScoreboardPlayers > .player > * {
    z-index: 1;
  }

  #tradScoreboard #tradScoreboardPlayers > .player .playerAttributes {
    flex-wrap: wrap;
  }

  #tradScoreboard #tradScoreboardPlayers > .player.loser .result {
    // display: none;
  }

  #tradScoreboard #tradScoreboardPlayers > .player .result {
    font-size: 4.3vh;
    margin-left: 3.7vh;
    flex-basis: 100%;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
    position: absolute;
    left: 0px;
    top: 0px;
  }

  #tradScoreboard #tradScoreboardPlayers > .player .result > span {
    background: #efd51c;
    color: #000;
    padding: 0 0.4em;
  }

  #tradScoreboard #tradScoreboardPlayers > .player .result.both > span {
    background: #fff !important;
  }

  #tradScoreboard #tradScoreboardPlayers > .player .playerControls {
    min-width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 99;
  }

  #tradScoreboard #tradScoreboardPlayers > .player .playerAttributes {
    font-size: 9vh;
    margin-top: -0.07em;
    display: flex;
    align-items: center;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .player
    .playerAttributes
    .attributeRows {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    gap: 5px 0px;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .player
    .playerAttributes
    .user-image
    img {
    border-radius: 100%;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .player
    .playerAttributes
    .flex-wrapper {
    overflow: hidden;
    width: 100%;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .player
    .playerAttributes
    > .clubIcon {
    height: 1.6em;
    min-width: 1.6em;
    width: 1.6em;
    border: 0.03em solid #757575c7;
    margin-right: 0.3em;
    position: relative;
    top: 0.04em;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .player
    .playerAttributes
    .subheader {
    display: flex;
    font-size: 0.7em;
    line-height: 1;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .player
    .playerAttributes
    .subheader
    span {
    // opacity: .5;
    // color: #fff;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .player
    .playerAttributes
    .subheader
    .clubAndTeamContainer {
    height: 1.15em;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .player
    .playerAttributes
    .subheader
    .clubAndTeamContainer
    img {
    // height: .8em;
    // width: .8em;
    // min-width: .8em;
    // border: .04em solid #fff;
    // position: relative;
    // // top: -.13em;
    // margin-right: 0.25em;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .player
    .playerAttributes
    .subheader
    .clubAndTeamContainer.animate
    > div {
    transform-origin: 50% 0;
    position: relative;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .player
    .playerAttributes
    .subheader
    .countryName {
    min-width: 1.5em;
    margin-right: 0.35em;
    text-align: center;
  }

  #tradScoreboard #tradScoreboardPlayers > .player .playerAttributes .flag {
    height: 0.78em;
    top: -0.12em;
    position: relative;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .player
    .playerAttributes
    .club-logo {
    display: block;
    float: left;
  }

  #tradScoreboard #tradScoreboardPlayers .scoresContainer {
    order: 2;
    display: flex;
    height: 100%;
    width: 100%;
  }

  #tradScoreboard #tradScoreboardPlayers .mainScore {
    font-family: ${fontFamilyMediumUber};
    font-weight: 700;
    font-size: 33vh;
    line-height: 1;
    width: 0.9em;
    text-align: center;
    order: 2;
    display: flex;
    align-items: center;
    margin-left: 0.1em;
    position: relative;
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    max-width: 80%;
    width: 100%;
  }
  #tradScoreboard
    .tradScoreboardPlayersScoreSection
    .playerRight
    .scoresContainer {
    flex-direction: row-reverse;
  }
  #tradScoreboard #tradScoreboardPlayers .mainScore div {
    width: 100%;
    text-align: center;
    font-family: ${fontFamilyFuturaCondenseBold};
  }

  #tradScoreboard #tradScoreboardPlayers .mainScore .count {
    width: 100%;
    position: relative;
    top: -0.02em;
    font-weight: ${fontFamilyFuturaCondenseBold};
  }

  #tradScoreboard #tradScoreboardPlayers .mainScore .count.digits-2 {
    font-weight: ${fontFamilyFuturaCondenseBold};
    font-size: 0.7em;
    padding-top: 5vh;
  }

  #tradScoreboard #tradScoreboardPlayers .mainScore .count.digits-3 {
    font-weight: ${fontFamilyFuturaCondenseBold};
    font-size: 0.46em;
  }

  #tradScoreboard #tradScoreboardPlayers .extra-scores {
    font-size: 2vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap-reverse;
    padding-top: 2vh;
    padding-bottom: 2vh;
    max-width: 20%;
    width: 100%;
  }

  #tradScoreboard #tradScoreboardPlayers .extra-scores > div {
    margin-left: 0vh;
    padding: 0px 2vh;
  }

  #tradScoreboard #tradScoreboardPlayers .extra-scores .active {
    opacity: 1;
  }

  #tradScoreboard #tradScoreboardPlayers .extra-scores .points {
    font-size: 12vh;
    font-family: ${fontFamilyFuturaCondenseBold};
    font-weight: 700;
    line-height: 1;
    color: #fff;
  }
  #tradScoreboard #tradScoreboardPlayers .extra-scores .adv {
    color: #5ec341;
    font-family: ${fontFamilyFuturaCondenseBold};
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  #tradScoreboard #tradScoreboardPlayers .extra-scores .pen {
    color: #ec0001;
    font-family: ${fontFamilyFuturaCondenseBold};
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  #tradScoreboard #tradScoreboardPlayers .extra-scores > div div + div {
    color: #fff;
    font-size: 2.8;
  }

  #tradScoreboard #tradScoreboardPlayers .extra-scores .advantage.active {
    color: #fff;
    opacity: 1;
  }

  #tradScoreboard #tradScoreboardPlayers .extra-scores .penalty.active {
    color: #fff;
    opacity: 1;
  }

  #tradScoreboard #tradScoreboardPlayers .extra-scores .bullets {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #tradScoreboard .tradScoreboardBottom {
    position: relative;
    height: 30vh;
    display: flex;
    background: #1e1f23;
  }

  #tradScoreboard .tradScoreboardBottom > * {
    z-index: 1;
  }

  #tradScoreboard .tradScoreboardBottom .matchNumber {
    background: #0b0a08;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2vh;
    white-space: nowrap;
  }

  #tradScoreboard .tradScoreboardBottom .matchNumber span {
    font-size: 18vh;
  }

  #tradScoreboard .tradScoreboardBottom .tradScoreboardBottom-controls {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow-x: hidden;
    padding-left: 4vh;
  }

  #tradScoreboard .tradScoreboardBottom #clock {
    font-size: 28vh;
    line-height: 1;
    white-space: nowrap;
    text-align: right;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-right: 1vh;
    cursor: pointer !important;
  }

  #tradScoreboard .tradScoreboardBottom #clock .control .actions table {
    margin-right: 0;
  }

  #tradScoreboard .tradScoreboardBottom .scoreboard-logo {
    max-height: 24vh;
    max-width: 24vh;
    margin-right: 4vh;
  }

  #tradScoreboard .tradScoreboardBottom .scoreboard-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    pointer-events: none;
  }

  #tradScoreboard .tradScoreboardBottom .scoreboard-background img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  #tradScoreboard .tradScoreboardBottom #result {
    font-size: 7vh;
    text-align: center;
    color: #ffff1d;
  }

  #tradScoreboard .tradScoreboardBottom .matchInfoContainer {
    display: flex;
    height: 100%;
  }

  #tradScoreboard .tradScoreboardBottom .matchInfo {
    font-size: 7vh;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;
    padding-left: 4vh;
  }

  #tradScoreboard .tradScoreboardBottom .matchInfo .matchInfo-text {
    display: table-cell;
    vertical-align: middle;
  }

  #tradScoreboard .tradScoreboardBottom .matchInfo .logo svg,
  #tradScoreboard .tradScoreboardBottom .matchInfo .logo img {
    height: 24vh;
    width: auto;
    max-width: 25.5vh;
    margin-right: 4vh;
  }

  #tradScoreboard .tradScoreboardBottom .matchInfo .bracket-info {
    display: block;
  }

  #tradScoreboard .tradScoreboardBottom .matchInfo .bracket-info > * {
    margin-right: 2vh;
  }

  #tradScoreboard .tradScoreboardBottom .matchInfo .clock-alert {
    font-size: 28vh;
    color: #fdba13;
  }

  #tradScoreboard .tradScoreboardBottom .match-controls {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 2vh;
    display: flex;
    align-items: center;
  }

  #tradScoreboard .tradScoreboardBottom .match-controls .control {
    position: relative;
    top: unset;
    bottom: unset;
    left: unset !important;
  }

  #tradScoreboard.whiteblack
    #tradScoreboardPlayers
    > .player:first-child
    .mainScore {
    background-color: #000;
  }

  #tradScoreboard.whiteblack
    #tradScoreboardPlayers
    > .player:last-child
    .mainScore {
    background-color: #fff;
  }

  #tradScoreboard.whiteblack .mainScore {
    color: #a2a2a2 !important;
  }

  #tradScoreboard .control.won-by .actions table.actions-wrapper:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  #tradScoreboard .control.won-by .actions table.actions-wrapper:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .scMarquee .marqueeContainer {
    overflow: hidden;
    display: flex;
    margin-left: -4vh;
    padding-left: 4vh;
    transition: 0.2s all ease;
  }

  .scMarquee marqueeContainer.animate {
    -webkit-mask-image: linear-gradient(
      to left,
      transparent,
      black 10vh,
      black calc(100% - 4vh),
      transparent
    );
    mask-image: linear-gradient(
      to left,
      transparent,
      black 10vh,
      black calc(100% - 4vh),
      transparent
    );
  }

  .scMarquee marqueeContainer.animate .marqueeText {
    padding-right: 20vh;
  }

  .scMarquee .marqueeContainer .marqueeText {
    margin: 0;
    display: inline-block;
    white-space: nowrap;
    position: relative;
  }

  .scMarquee marqueeContainer.animate .marqueeText {
    -webkit-animation: marquee 15s linear infinite;
    animation: marquee 15s linear infinite;
  }

  @-webkit-keyframes marquee {
    0% {
      transform: translate3D(0, 0, 0);
    }

    20% {
      transform: translate3D(0, 0, 0);
    }

    100% {
      transform: translate3D(-100%, 0, 0);
    }
  }

  @keyframes marquee {
    0% {
      transform: translate3D(0, 0, 0);
    }

    20% {
      transform: translate3D(0, 0, 0);
    }

    100% {
      transform: translate3D(-100%, 0, 0);
    }
  }

  .scMarquee.primary {
    color: #d0ac13;
  }

  .scMarquee.primary .muted {
    opacity: 0.5 !important;
    font-size: 0.7em;
    margin-left: -0.3em;
  }

  .scMarquee.muted {
    color: #757575;
  }

  .playerName {
    color: #fff;
  }
  .clubName,
  .countryName {
    color: #c2c2c2;
  }
  .bracketDivision .marqueeText {
    color: rgba(231, 234, 255, 0.9);
  }
  bracketRound {
  }
  #tradScoreboardPlayers .playerLeft {
    background-color: #3d3e42 !important;
  }
  #tradScoreboardPlayers .playerRight {
    background-color: #202125 !important;
  }
  #tradScoreboard .tradScoreboardBottom {
    background-color: #000 !important;
  }
  #tradScoreboardPlayers .playerLeft .mainScore {
    background: #af0026;
    // background: #ba343c;
    color: #fff !important;
  }
  #tradScoreboardPlayers .playerRight .mainScore {
    // background: #1E409C ;
    background: #1e409b;
    color: #fff !important;
  }
  #tradScoreboard #tradScoreboardPlayers .mainScore {
    margin-left: 0 !important;
  }
  #tradScoreboard #tradScoreboardPlayers .extra-scores {
    background-color: #333;
    padding: 2vh !important;
    // width: 17vh;
  }

  #tradScoreboardPlayers .playerLeft .playerName,
  #tradScoreboardPlayers .playerRight .playerName {
    display: flex;
    align-items: flex-end;
  }
  .clubAndTeamContainer .clubName {
    color: #c2c2c2 !important;
  }
  .playerRight .clubAndTeamContainer {
    display: flex;
    flex-direction: row-reverse;
  }
  .playerRight .clubAndTeamContainer > .clubFlag {
    width: 1.5em;
  }
  .playerRight .clubAndTeamContainer > .clubName {
    text-align: right;
  }

  .clubAndTeamContainer .clubName,
  .clubAndTeamContainer .team-name {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .countryName {
    color: #fff !important;
  }

  .score2 {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 1vh;
    padding-top: 11vh;
    .sec1 {
      text-align: center;
      font-size: 18vh;
      border-radius: 1vh;
      z-index: 5;
      padding: 0;
      // height: 1.7em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 41.1vh;
      h2 {
        color: ${DarkGreen};
        text-align: center;
        font-family: ${fontFamilyFuturaCondenseBold};
        font-size: 9.5vh;
        font-style: normal;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 9vh;
        margin-bottom: 2vh;
      }
      p {
        color: ${DarkGreen};
        text-align: center;
        font-family: "OperaMedium";
        font-size: 4vh;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        line-height: 4vh;
      }
    }
    .boardScorePlayer2 {
      border-radius: 1vh;
      position: absolute;
      right: 0px;
      top: 11vh;
      width: 25.1vh;
      height: 22vh;
      border-radius: 1vh;
      animation: myfirst 1.5s linear infinite;
      filter: drop-shadow(0 0 0.2vh rgba(0, 0, 0, 0.8));
      flex-direction: row;
      .scoreBoardTable {
        border-radius: 1vh;
        // width: 25.1vh;
        // height: 15vh;
        flex-direction: row;
        .ScoreBoardTableInner {
          width: 25.1vh;
          height: 15vh;
          flex-direction: row;
          .ScoreBoardTableInner1 {
            flex-direction: row;
            .ScoreBoardTableInner11 {
              padding: 1vh 2vh;
              p {
                color: rgb(255, 255, 255);
                text-align: center;
                font-family: ${fontFamilyMediumUber};
                font-size: 2vh;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                margin-bottom: 0;
                filter: drop-shadow(0 0 0.2vh rgba(0, 0, 0, 0.8));
              }
              p img {
                transform: rotate(270deg);
                -webkit-transform: rotate(270deg);
                margin-left: 0.5vh;
                margin-bottom: 1vh;
              }
            }
          }
          .ScoreBoardTableInner2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 14vh;
            min-height: 0;
            .ScoreBoardTableInner21,
            .ScoreBoardTableInner22,
            .ScoreBoardTableInner23 {
              display: inline-block;
              position: relative;
              text-align: center;
              padding: 0.5vh 2vh;
              min-height: 0;
              img:first-child {
              }
              img {
                width: 2vh;
                height: 2vh;
                filter: drop-shadow(0 0 0.2vh rgba(0, 0, 0, 0.8));
              }
              p {
                color: rgb(255, 255, 255);
                text-align: center;
                font-family: "UberMove-Bold";
                font-size: 1.2vh;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                position: absolute;
                left: 0px;
                right: 0px;
                top: 11vh;
                margin: 0px;
                filter: drop-shadow(0 0 0.2vh rgba(0, 0, 0, 0.8));
              }
              button {
                background-color: transparent;
                border: none;
                outline: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 5vh;
                min-height: 0;
                img {
                  width: 2vh;
                  width: 2vh;
                  object-fit: cover;
                  position: relative;
                  top: 1vh;
                }
              }
            }
          }
          .ScoreBoardTableInner3 {
            .ScoreBoardTableInner31 {
              padding: 0px 20px;
              text-align: center;
              marign: auto;
              p {
                color: rgb(255, 255, 255);
                text-align: center;
                font-family: ${fontFamilyMediumUber};
                margin: auto;
                font-size: 2vh;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                margin-bottom: 0px;
                text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
              }
              img {
                text-align: center;
                margin: auto;
              }
            }
          }
        }
      }
    }
  }
  .score2 .sec1 {
    width: 25.1vh;
    height: 22vh;
  }
  .score2 .sec1 h2 {
    font-size: 9vh;
    line-height: 8vh;
  }
  .score2 .sec1 p {
    font-size: 3.5vh;
    line-height: 3vh;
    margin-bottom: 0;
    max-height: 5vh;
  }
  .playerRight .ScoreBoardTableInnerRight p img {
    transform: rotate(90deg) !important;
    -webkit-transform: rotate(90deg);
    margin-right: 0.5vh;
    margin-bottom: 1vh;
  }
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 99%;
  }

  .medical {
    background-color: ${SpringFrost};
  }
  .stalling {
    background-color: ${AmericanYellow};
  }
  .timeout {
    background-color: ${AmericanYellow};
  }
  .varAssistant {
    // background-color: ${AmericanYellow};
    background: linear-gradient(
      180deg,
      #ffce00 27.63%,
      rgba(255, 206, 0, 0) 141.45%
    );
    width: 25.1vh !important;
  }

  .playersSection {
    display: flex;
    height: 50%;

    position: relative;
    display: flex;
    flex-direction: column;
  }
  .playersSection .player {
    max-width: 50%;
    width: 100%;
  }
  .playersScoreSection {
    display: flex;
    height: 50%;
    flex-direction: row;
  }
  .playersScoreSection .player {
    max-width: 50%;
    width: 100%;
    padding-left: 0 !important;
  }
  .playersScoreSection .player .extra-scores {
    width: 20% !important;
  }
  .playersScoreSection .playerRight .extra-scores {
    order: 2;
  }
  .playersScoreSection .player .mainScore {
    width: 80% !important;
  }
  #tradScoreboard .playersScoreSection .scoresContainer {
    width: 100% !important;
  }
  #tradScoreboardPlayers .playerLeft,
  #tradScoreboardPlayers .playerRight {
    max-width: 50%;
    width: 100%;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .playerRight
    .playerAttributes
    .subheader
    .clubAndTeamContainer {
    text-align: right;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .playerLeft
    .playerAttributes
    .subheader
    .clubAndTeamContainer
    img {
    height: 1em;
    width: 1em;
    min-width: 0.8em;
    border: 0.04em solid #fff;
    position: relative;
    // top: -.13em;
    margin-right: 0.25em;
    border-radius: 50%;
  }
  #tradScoreboard
    #tradScoreboardPlayers
    > .playerRight
    .playerAttributes
    .subheader
    .clubAndTeamContainer
    img {
    height: 1em;
    width: 1em;
    min-width: 0.8em;
    border: 0.04em solid #fff;
    position: relative;
    border-radius: 50%;
    // top: -.13em;
    margin-left: 0.25em;
  }
  #tradScoreboardPlayers .playerRight .playerName {
    justify-content: flex-end;
  }

  #tradScoreboard
    #tradScoreboardPlayers
    > .playerLeft
    .playerAttributes
    .subheader
    .countryName {
    min-width: 1.5em;
    margin-right: 0.35em;
    text-align: center;
  }
  #tradScoreboard
    #tradScoreboardPlayers
    > .playerRight
    .playerAttributes
    .subheader
    .countryName {
    min-width: 1.5em;
    margin-left: 0.35em;
    margin-right: 0;
    text-align: center;
  }

  #tradScoreboard #tradScoreboardPlayers > .player .playerAttributes .flag {
    height: 0.78em;
    top: -0.12em;
    position: relative;
  }
  #tradScoreboard #tradScoreboardPlayers > .playerLeft {
    padding-left: 0;
  }
  #tradScoreboard
    #tradScoreboardPlayers
    > .playerLeft
    .playerAttributes
    .attributeRows {
    padding-left: 0.8em;
  }
  #tradScoreboard
    #tradScoreboardPlayers
    > .playerRight
    .playerAttributes
    .attributeRows {
    padding-right: 0.8em;
    align-items: flex-end;
  }
  #tradScoreboardPlayers .playerLeft .playerName .name {
    margin-left: 0.3em;
  }
  #tradScoreboardPlayers .playerRight .playerName .name {
    margin-right: 0.3em;
    text-align: right;
  }
  #clock .count {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3vh;
    text-align: center;
    height: 30vh;
    background: ${pureDark};
  }
  .count p {
    display: inline-block;
    color: rgb(237, 219, 46);
    font-family: ${fontFamilyFuturaCondenseBold};
    vertical-align: top;
    display: inline-table;
    text-align: center;
    font-size: 29vh;
    line-height: 1;
    white-space: nowrap;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-right: 1vh;
    letter-spacing: 1px;
    background-color: rgb(0, 0, 0);
    position: relative;
    z-index: 9999;
    cursor: pointer !important;
  }
  .wonbyLight {
    position: absolute;
    z-index: 9;
    width: 100%;
    top: 4vh;
  }
  .wonbyDark {
    position: absolute;
    z-index: 9;
    width: 100%;
    top: 4vh;
  }

  .wonbyLight .scoreBoardTable table tr:nth-child(1) td {
    background: rgb(175, 0, 38);
    color: rgb(255, 255, 255) !important;
  }
  .wonbyDark .scoreBoardTable table tr:nth-child(1) td {
    background: rgb(30, 64, 155);
    color: rgb(255, 255, 255) !important;
  }
  .endGameBottom {
    position: absolute;
    top: 9vh;
    left: 4vh;
  }
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .doubleShowsComp {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 29vh;
    z-index: 9;
    text-align: center;
    top: 15vh;
  }

  .table2Right .boardScoreAdd {
    left: auto;
    right: 5vh;
    display: flex;
    justify-content: flex-end;
    top: 13vh;
  }
  .table1Left .boardScoreAdd {
    left: 3vh;
    top: 13vh;
  }
  .boardScoreAdd table {
    width: 58vh;
  }
  .matchControls123 {
    position: absolute;
    top: 9vh;
    left: 2vh;
  }
  .wonbyLight table {
    position: absolute;
    z-index: 9;
    width: 100%;
    top: 2vh;
  }
  .wonbyDark table {
    position: absolute;
    z-index: 9;
    width: 100%;
    top: 2vh;
    right: 0em;
    left: auto;
  }
  .doubleShowsComp {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 29vh;
    z-index: 9;
    text-align: center;
    top: 20vh;
  }
  .double-show {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 25vh;
    padding: 2vh;
    z-index: 9;
    text-align: center;
    top: -6vh;
  }
  .scoreBoardTable table tr:nth-child(3) td {
    font-size: 2.9vh;
  }
  @media screen and (max-width: 34vh) {
    div#tradScoreboard {
      width: 34vh;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
`;

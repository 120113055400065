import styled from "styled-components";
import { fontFamilyMedium, fontFamilyRegularUber, tertiaryDark } from "../../../components/GlobalStyle";


const OtpInputsStyled = styled.div`
  display: flex;
  justify-content: space-evenly;


  .otp-input{
    border-radius: 10px !important;
    margin-bottom: 0px !important;
    width: 46px !important;
    height: 46px !important;
    text-align: center;
    
    color: ${tertiaryDark};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

`

export default OtpInputsStyled
import React from "react";
import { OrganizerSettingStatisticsOverviewStyle } from "./style";
const OrganizerSettingStatisticsOverview = () => {
  return (
    <div>
      <OrganizerSettingStatisticsOverviewStyle>
        <div className="bodyWrapper_RightSide">
          <div className="maineContainer">
            <div className="cardsMainContainerSec1">
              <div className="dataCards">
                <h4 className="titles">Approved Registrations By Country</h4>
                <div className="innerrCard">
                  <p>
                    4 . GB <span>— United Kingdom</span>
                  </p>
                  <p>
                    1 . AE <span>— United Arab Emirates</span>
                  </p>
                  <p>
                    1 . SE <span>— Sweden</span>
                  </p>
                  <p>
                    1 . GL <span>— Greenland</span>
                  </p>
                </div>
              </div>
              <div className="dataCards">
                <h4 className="titles">Totals</h4>
                <div className="innerrCard">
                  <p>
                    4 . <span>Country</span>
                  </p>
                  <p>
                    5 . <span>Academy</span>
                  </p>
                  <p>
                    5 . <span>Team</span>
                  </p>
                </div>
              </div>

              <div className="dataCards">
                <h4 className="titles">Approved Registrations By Academy</h4>
                <div className="innerrCard">
                  <p>
                    3 . <span>Apex Kalmar Idrottsförening</span>
                  </p>
                  <p>
                    1 . <span>bmjja</span>
                  </p>
                  <p>
                    15 . <span>Elements Gracie Jiujitsu</span>
                  </p>
                  <p>
                    1 . <span>Alliance Los Angeles</span>
                  </p>
                  <p>
                    1 . <span>. Alingsås Ju-jutsu Klubb</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="cardsMainContainerSec2">
              <div className="dataCards">
                <h4 className="titles">Total Registrations</h4>
                <div className="innerrCard">
                  <p>
                    7 . <span> Total registrations</span>
                  </p>
                  <p>
                    2 . <span>Not approved</span>
                  </p>
                  <p>
                    0 . <span>Canceled</span>
                  </p>
                  <p>
                    0 . <span>Trashed</span>
                  </p>
                </div>
              </div>
              <div className="dataCards">
                <h4 className="titles">Approved Registrations By Team</h4>
                <div className="innerrCard">
                  <p>
                    3 . <span>Checkmat</span>
                  </p>
                  <p>
                    1 . <span>bmjja</span>
                  </p>
                  <p>
                    15 . <span>Elements Gracie Jiujitsu</span>
                  </p>
                  <p>
                    1 . <span>82 JIU JITSU</span>
                  </p>
                  <p>
                    1 . <span>Alingsås Ju-jutsu Klubb</span>
                  </p>
                </div>
              </div>

              <div className="dataCards">
                <h4 className="titles">Credits Breakdown</h4>
                <div className="innerrCard">
                  <p>
                    7 . <span>Currently Approved Users</span>
                  </p>
                  <p>
                    1 . <span>Previously approved users</span>
                  </p>
                  <p>
                    0 . <span>Refunded users</span>
                  </p>
                  <p>
                    8 . <span>Total credits drawn from wallet</span>
                  </p>
                  <div className="details">
                    <a href="#">Details</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OrganizerSettingStatisticsOverviewStyle>
    </div>
  );
};

export default OrganizerSettingStatisticsOverview;

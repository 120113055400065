// Init
import axios from "axios";
import { base_url } from "../utils/api_urls";

const api = async (
  method: string = "get",
  uri: string,
  body?: any
): Promise<any> => {
  const token = localStorage.getItem("token");
  if (token) axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  // API Call
  const url = base_url + uri;
  return new Promise((resolve, reject) => {
    (axios as any)
      [method](url, body)
      .then((res: any) => resolve(res))
      .catch((error: Error | unknown) => {
        console.error("Failed to call HTTP request: ", error);
        reject(error);
      });
  });
};

// Export
export default api;

import React, { useState, useEffect } from "react";
import { UltmScroreBoardstyle } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useAppDispatch } from "../../../app/hooks";

interface ScoreHistoryProps {
  playerId: number;
  score?: number;
  penalty?: number;
  advantage?: number;
}

const IbjjfScoreBoard: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    teamOneScoreBoard,
    teamTwoScoreBoard,
    scoreHistory,
    playerOne,
    playerTwo,
    matchTimer,
    endGameMessage,
    endGameMessageType,
    endGameMessageB,
  } = useSelector((state: RootState) => state.ibjjfScoreboard);

  // Section 1 and 2 functionality states

  // Section 3 functionality states
  const [showEndGameModal, setShowEndGameModal] = useState(false);
  const [endGame, setEndGame] = useState(false);
  const [isSave, setIsSave] = useState(false);


  console.log("checking teamOneScoreBoard: ", teamOneScoreBoard);

  // common handlers
  const handlePropagation = (event: any) => {
    event.stopPropagation();
  };

  const formatTime = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const remainingSeconds = timer % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return ` ${formattedMinutes}:${formattedSeconds} `;
  };

  // Section 3 functionality handlers



  useEffect(() => {
    if (endGameMessage || endGameMessageB) {
      setEndGame(false);
      setIsSave(true);
      // setEndGameMessage('')
      // setEndGameMessageB('')
    }
  }, [endGameMessage, endGameMessageB]);

  useEffect(() => {
    // New page
     window.addEventListener('message', (event) => {
       // Check if the message is from a trusted source (optional)
       // if (event.origin !== 'https://sourcepage.com') return;
       console.log("checking I am into message");
       if (event.data === 'goFullscreen') {
         // Request fullscreen
         document.documentElement.requestFullscreen({ navigationUI: "hide" });
       }
     });
   }, [])

  return (
    <UltmScroreBoardstyle>
      <div id="tradScoreboard">
        <div id="tradScoreboardPlayers" className={
            endGameMessage || endGameMessageB
              ? "animate__animated animate__tada"
              : ""
          }>
        {(endGameMessage || endGameMessageB) && (
            <div className="messagesBody">
              <div className="messagesShows">
                <div className={`result submission `}>
                  <span className="winner-text">
                    {!!endGameMessage ? endGameMessage : endGameMessageB}
                  </span>
                </div>
              </div>
              <div className="playerDetails trad-player">
                <div className="trad-player-points">
                  <div>
                    <div className="player-attr">
                      <div className="attr-rows ">
                        <div className="trad-header ">
                          <div className="player-name ">
                            <span className="name truncate">
                              {playerOne.wonBy && (
                                <>
                                  <span className="firstname">
                                    {playerOne.playerFirstName}
                                  </span>{" "}
                                  <span className="lastname">
                                    {playerOne.playerLastName}
                                  </span>
                                </>
                              )}
                              {playerTwo.wonBy && (
                                <>
                                  <span className="firstname">
                                    {playerTwo.playerFirstName}
                                  </span>{" "}
                                  <span className="lastname">
                                    {playerTwo.playerLastName}
                                  </span>
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!endGameMessage && !endGameMessageB && (
            <div className="player playerContainer playerLeft">
            <div>
              {endGameMessage && (
                <div
                className={`result ${
                  !endGameMessageType ? "submission" : "both"
                }`}
              >
                  <span>
                    <span>{endGameMessage}</span>
                  </span>
                </div>
              )}
            </div>
            <div className="scoresContainer">
              <div
                className="mainScore points"
                style={{ flexDirection: "column" }}
              >
                <div
                  className="mainScore count"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    className={` ${
                      teamOneScoreBoard.score <= 9
                        ? "digits-1 "
                        : teamOneScoreBoard.score <= 99
                        ? "digits-2"
                        : "digits-3"
                    }`}
                  >
                    {teamOneScoreBoard.score}
                  </div>
                </div>
                <div className="mainScorePoint mainScoreText">
                  {teamOneScoreBoard.score > 1 ? "Points" : "Point"}
                </div>
              </div>
              <div className="mainScore advantage" style={{ flexDirection: "column-reverse" }}>
                <div className="count adv points">
                  <div
                    className={` ${
                      teamOneScoreBoard.advantage <= 9
                        ? "digits-1 "
                        : teamOneScoreBoard.advantage <= 99
                        ? "digits-2"
                        : "digits-3"
                    }`}
                  >
                    {teamOneScoreBoard.advantage}
                  </div>
                </div>
                <div className="mainScoreAdvantage mainScoreText">
                  {teamOneScoreBoard.advantage > 1 ? "Advantages" : "Advantage"}
                </div>
              </div>
              <div className="mainScore penalty" style={{ flexDirection: "column-reverse" }}>
                <div className="count pen points">
                  <div
                    className={` ${
                      teamOneScoreBoard.penalty <= 9
                        ? "digits-1 "
                        : teamOneScoreBoard.penalty <= 99
                        ? "digits-2"
                        : "digits-3"
                    }`}
                  >
                    {teamOneScoreBoard.penalty}
                  </div>
                </div>
                <div className="mainScorePenality mainScoreText">
                  {teamOneScoreBoard.penalty > 1 ? "penalties" : "Penalty"}
                </div>
              </div>
            </div>
            <div
              className="playerControls"
            >
              <div>
                <div className="playerAttributes">
                  <div className="attributeRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <img src={playerOne.countryFlag} className="flag" />
                        <span className="name truncate">
                          <span className="firstname">
                            {playerOne.playerFirstName}{" "}
                          </span>
                          <span className="lastname">
                            {playerOne.playerLastName}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="subheader">
                      <span className="countryName">
                        {playerOne.countryName}
                      </span>
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate">
                          <div className="clubName truncate">
                            <img
                              src={playerOne.clubFlag}
                              className="clubIcon imgCircle"
                            />
                            <span>{playerOne.clubName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`score2`}>
              {teamOneScoreBoard.isMedicalModal && (
                <div className={`sec1 medical`}>
                  <h2>
                    {formatTime(teamOneScoreBoard.medicalValue as number)}
                  </h2>
                  <p>Medical</p>

                </div>
              )}
              {teamOneScoreBoard.isStallingModal && (
                <div className={`sec1 stalling`}>
                  <div>
                    <h2>
                      {formatTime(teamOneScoreBoard.stallingValue as number)}
                    </h2>
                    <p>Stalling</p>
                  </div>

                </div>
              )}
              {teamOneScoreBoard.isTimeoutModal && (
                <div className={`sec1 timeout`}>
                  <h2>
                    {formatTime(teamOneScoreBoard.timeoutValue as number)}
                  </h2>
                  <p>TIMEOUT</p>

                </div>
              )}
            </div>
          </div>
          )}
          

          {teamOneScoreBoard.isVarModal ? (
            <div className="score2 varScore2">
              <div className={`sec1 varAssistant`}>
                <h2>VAR</h2>
                <p>MODE ON</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {teamTwoScoreBoard.isVarModal ? (
            <div className="score2 varScore2">
              <div className={`sec1 varAssistant`}>
                <h2>VAR</h2>
                <p>MODE ON</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {!endGameMessage && !endGameMessageB && (
            <div className="player playerContainer playerRight">

            <div className="scoresContainer">
              <div className="mainScore points">
                <div className="mainScorePoint mainScoreText">
                  {teamTwoScoreBoard.score > 1 ? "Points" : "Point"}
                </div>
                <div
                  className="mainScore count"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    className={` ${
                      teamTwoScoreBoard.score <= 9
                        ? "digits-1 "
                        : teamTwoScoreBoard.score <= 99
                        ? "digits-2"
                        : "digits-3"
                    }`}
                  >
                    {teamTwoScoreBoard.score}
                  </div>
                </div>
              </div>
              <div className="mainScore advantage">
                <div className="mainScoreAdvantage mainScoreText">
                  {teamTwoScoreBoard.advantage > 1 ? "Advantages" : "Advantage"}
                </div>
                <div className="count adv points">
                  <div
                    className={` ${
                      teamTwoScoreBoard.advantage <= 9
                        ? "digits-1 "
                        : teamTwoScoreBoard.advantage <= 99
                        ? "digits-2"
                        : "digits-3"
                    }`}
                  >
                    {teamTwoScoreBoard.advantage}
                  </div>
                </div>
              </div>
              <div className="mainScore penalty">
                <div className="mainScorePenality mainScoreText">
                  {teamTwoScoreBoard.penalty > 1 ? "penalties" : "Penalty"}
                </div>
                <div className="count pen points">
                  <div
                    className={` ${
                      teamTwoScoreBoard.penalty <= 9
                        ? "digits-1 "
                        : teamTwoScoreBoard.penalty <= 99
                        ? "digits-2"
                        : "digits-3"
                    }`}
                  >
                    {teamTwoScoreBoard.penalty}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="playerControls"
            >
              <div>
                <div className="playerAttributes">
                  <div className="attributeRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <img src={playerTwo.countryFlag} className="flag" />{" "}
                        <span className="name truncate">
                          <span className="firstname">
                            {playerTwo.playerFirstName}
                          </span>{" "}
                          <span className="lastname">
                            {playerTwo.playerLastName}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="subheader">
                      <span className="countryName">
                        {playerTwo.countryName}
                      </span>
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate animate">
                          <div className="clubName truncate">
                            <img
                              src={playerTwo.clubFlag}
                              className="clubIcon imgCircle"
                            />
                            <span>{playerTwo.clubName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`score2`}>
              {teamTwoScoreBoard.isMedicalModal && (
                <div className={`sec1 medical swapArrow`}>
                  <h2>
                    {formatTime(teamTwoScoreBoard.medicalValue as number)}
                  </h2>
                  <p>Medical</p>

                </div>
              )}
              {teamTwoScoreBoard.isStallingModal && (
                <div className={`sec1 stalling swapArrow`}>
                  <div>
                    <h2>
                      {formatTime(teamTwoScoreBoard.stallingValue as number)}
                    </h2>
                    <p>Stalling</p>
                  </div>
                </div>
              )}
              {teamTwoScoreBoard.isTimeoutModal && (
                <div className={`sec1 timeout`}>
                  <h2>
                    {formatTime(teamTwoScoreBoard.timeoutValue as number)}
                  </h2>
                  <p>TIMEOUT</p>

                </div>
              )}
            </div>
          </div>
          )}
          
        </div>
        <div className="tradScoreboardBottom">
          <div
            style={{ flexGrow: "1", width: "0px", zIndex: "2" }}
            onClick={() => setShowEndGameModal(!showEndGameModal)}
          >
            <div className="matchInfoContainer">
              <div className="matchNumber">
                <span>1-1</span>
              </div>
              <div className="matchInfo">
                <div className="matchInfoText">
                  <div className="bracketDivision">
                    <div className="scMarquee">
                      <div className="marqueeContainer animate">
                        <span className="marqueeText">
                          Boys Gi / Mighty Mite I (4 yrs) / -17,90 kg / Grey
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bracketRound">
                    <div className="scMarquee primary">
                      <div className="marqueeContainer">
                        <span className="marqueeText">Semifinals</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div
            id="clock"
          >
            <div className="time running">
              <div
                className="count "
                style={{
                  color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                }}
              >
                <p
                  style={{
                    color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                  }}
                >
                  {formatTime(matchTimer.matchTimerValue)}
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </UltmScroreBoardstyle>
  );
};
export default IbjjfScoreBoard;

import { List } from "antd";
import { Link } from "react-router-dom";
import Ticketimg from "../../../assets/images/ticket-listing.svg";
import StarRating from "../../../assets/icons/ic_singleStart.svg";
import Ukflag from "../../../assets/icons/uk-flag.svg";
import { TicketListingstyle } from "./style";

const TicketListing = () => {
  return (
    <>
      <TicketListingstyle>
            <div className="custom_card">
              <div className="custom_card_placeholder_img" id="cardImg">
                <img src={Ticketimg} alt="CardImg" id="cardImg" />
              </div>
              <div className="custom_card-content">
                <h2>Manchester International Open Jiu Jitsu Gi and NoGi championship</h2>
                <div className="d-flex justify-content-between mt-10">
                  <div className="countryImg ">
                    <div className="uk-text">Manchester, UK</div>
                    <img src={Ukflag} alt="" className="flagImg" />
                  </div>
                  <span className="ant-rate-text">
                    <div className="star-rating">
                      <img src={StarRating} alt="StarRating" />
                    </div>
                    <span>4.6</span>
                    <span> (780+ )</span>
                  </span>
                </div>
                <div className="custom_card_para mt-10">
                  This is the first team event in India. And we are excited to
                  bring in the famous Quintet format. Each team will have...
                </div>
                <div className="learnMore_dateTime mt-10">
                  <div className="date-time">Monday, 17th October 2023</div>
                  <Link to="#" className="custom_card_footer_link">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
      </TicketListingstyle>
    </>
  );
};

export default TicketListing;

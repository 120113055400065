import styled from "styled-components";
import { fontFamilyRegularUber, lightDark2, mediaDeviceMax, pureDark2 } from "../../../components/GlobalStyle";

export const UserProfileAcadmyEventAffiliationStyle = styled.div`

.bodyWrapper_RightSide {
    width: 100%;
}
.card1 {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1.087px solid #F0F0F0;
    margin-top: 17px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.cardLogoLeftSide {
    display: flex;
    flex-direction: column;
} 
.cardLogoLeftSide p {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 13.217px;
    letter-spacing: 0.32px;
    margin-bottom: 0;
    padding: 14px 0 0px 17px;
} 
.avatar1 {
    width: 81.766px;
    height: 81.766px;
    object-fit: cover;
    border-radius: 44.387px;
    margin: 9.18px 0 12.06px 10.51px;
}
.info1 {
    margin-left: 11.68px;
    display: flex;
    flex-direction: column;
    padding: 9.34px 17px 10px 0;
}  
.name1 {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 16.353px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.activity1 {
    letter-spacing: -.5px;
    margin: 0;
    padding-top: 9.2px;
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 16.353px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}
.blocks {
    display: flex;
    align-items: center;
    padding-top: 5.45px;
    gap: 8px;
}
.block1 {
    padding: 2px 7px 2px 8px;
    background-color: #CDAF49;
    border-radius: 5px;
}
.block2 {
    padding: 2px 8px 2px 9px;
    background-color: #A8A9AD;
    border-radius: 5px;
}
.block3 {
    padding: 2px 4px 0px 8px;
    background-color: #BC6B50;
    border-radius: 5px;
}
.block1 span, .block2 span, .block3 span {
    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 18.689px;
    font-style: normal;
    font-weight: 500;
    line-height: 23.362px;
}
.mapFrame {
    max-width: 357px;
    width: 100%;
    border-radius: 0px 0px 9px 9px;
    border: 0px solid #E0E0E0;
    margin-top: 17px;
}
.rightSideMain {
    width: 100%;
    margin-top: 20px;
}
.EventsTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    h4 {
        color: ${pureDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.48px;
        margin-bottom: 0;
    }
    span {
        color: ${pureDark2};
        text-align: center;
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 13.217px;
        letter-spacing: 0.28px;
        cursor: pointer
    }
}

.passEvents_cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.upComingEvents_cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}


.subTittle p {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 4px;
}
.upComingMatch_cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

}
.yourRegistrations {
    margin: 230px 0 0 13px !important;
}
.yourRegistrations_cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

table {
    width: 100%;
}
table tbody tr:nth-child(odd) {
    background-color: #ffffff;
}
table tbody tr:nth-child(even) {
    background-color: #f6f6f6;
}
table thead tr {
    border-radius: 3.965px 3.965px 0px 0px;
    background-color: #EFEFEF;
}
table thead tr th {
    padding: 10px 0px 11px 0px;
    border-radius: 3.965px 3.965px 0px 0px;
}
table thead tr th {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
}
table thead tr th:first-child,
table tbody tr td:first-child {
    padding-left: 14px;
    width: 10%;
}
table tbody tr td:first-child {
    width: 10%;
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
table thead tr th:nth-child(2),
table tbody tr td:nth-child(2) {
    width: 13%;
}
table thead tr th:nth-child(3),
table tbody tr td:nth-child(3) {
    width: 14%;
}
table tbody tr td:nth-child(2),
table tbody tr td:nth-child(3) {
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    p {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px;
    }
}
table thead tr th:nth-child(4), 
table tbody tr td:nth-child(4){
    width: 17%;
    text-align: right;
}
table tbody tr td:nth-child(4) {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.amountMain p {
    margin-bottom: 0;
}
table tbody tr td {
    padding: 17px 0px;
}

.amountMain span {
    padding: 2px 8px 2px 9px;
    border-radius: 3px;
    background-color: #D4D4D4;
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-right: 8px;
    margin-bottom: 0;
}
table thead tr th:last-child {
    padding-right: 22px;
    width: 13%;
    text-align: right;
}
table tbody tr td:last-child {
    padding-right: 18px;
    width: 18%;
}
.confirmationButton {
    text-align: right;
}
.confirmationButton button {
    padding: 8px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);   
    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.introductionParagraph {
    width: 100%;
}

.introBox{
    border-radius: 5px;
    margin-top: 10px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding: 14px 12px 10px 17px;
}
.introductionParagraph h6 {
    color: ${pureDark2};
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.introBox p {
    color: ${lightDark2};
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
}



// Responsive
@media ${mediaDeviceMax.laptop} {
    table thead tr th:nth-child(2), 
    table tbody tr td:nth-child(2){ 
        display: none;
    }
}

@media ${mediaDeviceMax.laptop} {
    table thead tr th:nth-child(2), 
    table tbody tr td:nth-child(2){ 
        display: none;
    }
}



`;
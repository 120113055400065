import react, {useState} from 'react';
import { GlobalContainer } from "../../screens/Home/style";
import HeaderInnerPages from "../HeaderInnerPages/HeaderInnerPages";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/footer";

import styled from "styled-components";

import { Layout } from "antd";
const { Header, Content } = Layout;

const LayoutSideBar =  styled.div`
  .content-left-width{
    margin-left: 0;
    transition: all 0.7s ease-in-out;
    background-color: #f5f5f5 !important;
  }
  .ant-layout-sider-light + .content-left-width{
    width: 100%;
    transition: all 0.7s ease-in-out;
  }
  .ant-layout-sider-light{
    transition: all 0.7s ease-in-out;
  }
  
`;

const LayoutCommon = (props: any) => {
  // const {
  //   token: { colorBgContainer },
  // } = theme?.useToken();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <GlobalContainer>
      <LayoutSideBar>
        <Layout
          className="sideBarHandler"
          style={{ backgroundColor: "#f5f5f5" }}
        >

            <Header
              style={{
                // padding: "16px",
                background: "white",
                marginBottom: "12px",
                // position: "sticky",
                top: 0,
                zIndex: 1,
                width: "100%",
                lineHeight: "0",
                padding: "0",
                // height: 90,
              }}
              className="navbar-styles"
              >
              {/* <Navbar /> */}
              {/* <NavbarSmallScreen /> */}
              <HeaderInnerPages setIsOpen={setIsOpen} isOpen={isOpen} />
            </Header>

            <Layout className="content-left-width">
              {isOpen && <Sidebar />}
            <Content className="content-styles">
              <Layout style={{ flex: 3 }}>{props.children}</Layout>
            </Content>
            </Layout>
            <Footer />
        </Layout>
      </LayoutSideBar>
    </GlobalContainer>
  );
};

export default LayoutCommon;

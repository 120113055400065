import React from "react";
import { OrganizerCreateAnEventRegistrationStyle } from "./style";
import FormControl from "../../../components/FormControl";
import MaleGiImage from "../../../assets/images/create_school_user_profile.svg";
import WeighFirstIcon from "../../../assets/icons/Weigh-Icon1.svg";
import WeighSecondIcon from "../../../assets/icons/Weigh-Icon2.svg";
import WeightCheckedIcon from "../../../assets/icons/Weigh-checkedIcon3.svg";
import WeighforthIcon from "../../../assets/icons/Weigh-Icon4.svg";
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";

const { Content } = Layout;

const OrganizerCreateAnEventRegistration = () => {
  return (
    <div>
      <OrganizerCreateAnEventRegistrationStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="creatEvent-left">
                <CreatEventLeftSide />
              </Content>
              <Content className="creatEvent-right">
                <div className="bodyWrapper_RightSide">
                  <div className="title">
                    <h4>Registration</h4>
                  </div>
                  <div className="sec1_container">
                    <div className="maleGiSec1_viewBracketsMain">
                      <div className="checkbox_MaleGiTitle">
                        <span className="">
                          <FormControl
                            control="checkbox"
                            type="checkbox"
                            id="terms"
                            name="terms"
                          />
                        </span>
                        <p className="maliGi_subTitle">
                          Male Gi / Blue / Adult / -76 kg (Light)
                        </p>
                      </div>
                      <div className="viewBracketsButton">
                        <button>View Brackets</button>
                      </div>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th>Name</th>
                          <th>Birth</th>
                          <th>Academy/Affiliation</th>
                          <th>Entry</th>
                          <th>Age</th>
                          <th>Sort</th>
                          <th>Placement</th>
                          <th>Weigh-in</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <span className="">
                              <FormControl
                                control="checkbox"
                                type="checkbox"
                                id="terms"
                                name="terms"
                              />
                            </span>
                          </td>
                          <td className="personImage">
                            <img src={MaleGiImage} alt="MaleGiImage" />
                          </td>
                          <td>John Smith</td>
                          <td>12/12/78</td>
                          <td>bmjja</td>
                          <td>Boys Gi</td>
                          <td>Adult</td>
                          <td>-76 kg (Light)</td>
                          <td></td>
                          <td>
                            <div className="weighMain">
                              <div className="weigh_firstIcon">
                                <img
                                  src={WeighFirstIcon}
                                  alt="WeighFirstIcon"
                                />
                              </div>
                              <div className="weigh_secondIcon">
                                <img
                                  src={WeighSecondIcon}
                                  alt="WeighSecondIcon"
                                />
                                <span>76</span>
                              </div>
                              <div className="weigh_checkedThirdIcon">
                                <span>
                                  <img
                                    src={WeightCheckedIcon}
                                    alt="WeightCheckedIcon"
                                  />
                                </span>
                              </div>
                              <div className="weigh_fourthIcon">
                                <img
                                  src={WeighforthIcon}
                                  alt="WeighforthIcon"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <img
                              src={actionMenuTogglerIcon}
                              alt="actionMenuTogglerIcon"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <span className="">
                              <FormControl
                                control="checkbox"
                                type="checkbox"
                                id="terms"
                                name="terms"
                              />
                            </span>
                          </td>
                          <td className="personImage">
                            <img src={MaleGiImage} alt="MaleGiImage" />
                          </td>
                          <td>John Smith</td>
                          <td>12/12/78</td>
                          <td>bmjja</td>
                          <td>Boys Gi</td>
                          <td>Adult</td>
                          <td>-76 kg (Light)</td>
                          <td></td>
                          <td>
                            <div className="weighMain">
                              <div className="weigh_firstIcon">
                                <img
                                  src={WeighFirstIcon}
                                  alt="WeighFirstIcon"
                                />
                              </div>
                              <div className="weigh_secondIcon">
                                <img
                                  src={WeighSecondIcon}
                                  alt="WeighSecondIcon"
                                />
                                <span>76</span>
                              </div>
                              <div className="weigh_checkedThirdIcon">
                                <span>
                                  <img
                                    src={WeightCheckedIcon}
                                    alt="WeightCheckedIcon"
                                  />
                                </span>
                              </div>
                              <div className="weigh_fourthIcon">
                                <img
                                  src={WeighforthIcon}
                                  alt="WeighforthIcon"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <img
                              src={actionMenuTogglerIcon}
                              alt="actionMenuTogglerIcon"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <span className="">
                              <FormControl
                                control="checkbox"
                                type="checkbox"
                                id="terms"
                                name="terms"
                              />
                            </span>
                          </td>
                          <td className="personImage">
                            <img src={MaleGiImage} alt="MaleGiImage" />
                          </td>
                          <td>John Smith</td>
                          <td>12/12/78</td>
                          <td>bmjja</td>
                          <td>Boys Gi</td>
                          <td>Adult</td>
                          <td>-76 kg (Light)</td>
                          <td></td>
                          <td>
                            <div className="weighMain">
                              <div className="weigh_firstIcon">
                                <img
                                  src={WeighFirstIcon}
                                  alt="WeighFirstIcon"
                                />
                              </div>
                              <div className="weigh_secondIcon">
                                <img
                                  src={WeighSecondIcon}
                                  alt="WeighSecondIcon"
                                />
                                <span>76</span>
                              </div>
                              <div className="weigh_checkedThirdIcon">
                                <span>
                                  <img
                                    src={WeightCheckedIcon}
                                    alt="WeightCheckedIcon"
                                  />
                                </span>
                              </div>
                              <div className="weigh_fourthIcon">
                                <img
                                  src={WeighforthIcon}
                                  alt="WeighforthIcon"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <img
                              src={actionMenuTogglerIcon}
                              alt="actionMenuTogglerIcon"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="sec2_container">
                    <div className="maleGiSec2_viewBracketsMain">
                      <div className="checkbox_MaleGiTitle">
                        <span className="">
                          <FormControl
                            control="checkbox"
                            type="checkbox"
                            id="terms"
                            name="terms"
                          />
                        </span>
                        <p className="maliGi_subTitle">
                          Female Gi / Blue / Adult / -76 kg (Light)
                        </p>
                      </div>
                      <div className="addBracketsButton">
                        <button>Add Brackets</button>
                      </div>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th>Name</th>
                          <th>Birth</th>
                          <th>Academy/Affiliation</th>
                          <th>Entry</th>
                          <th>Age</th>
                          <th>Sort</th>
                          <th>Placement</th>
                          <th>Weigh-in</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <span className="">
                              <FormControl
                                control="checkbox"
                                type="checkbox"
                                id="terms"
                                name="terms"
                              />
                            </span>
                          </td>
                          <td className="personImage">
                            <img src={MaleGiImage} alt="MaleGiImage" />
                          </td>
                          <td>John Smith</td>
                          <td>12/12/78</td>
                          <td>bmjja</td>
                          <td>Boys Gi</td>
                          <td>Adult</td>
                          <td>-76 kg (Light)</td>
                          <td></td>
                          <td>
                            <div className="weighMain">
                              <div className="weigh_firstIcon">
                                <img
                                  src={WeighFirstIcon}
                                  alt="WeighFirstIcon"
                                />
                              </div>
                              <div className="weigh_secondIcon">
                                <img
                                  src={WeighSecondIcon}
                                  alt="WeighSecondIcon"
                                />
                                <span>76</span>
                              </div>
                              <div className="weigh_checkedThirdIcon">
                                <span>
                                  <img
                                    src={WeightCheckedIcon}
                                    alt="WeightCheckedIcon"
                                  />
                                </span>
                              </div>
                              <div className="weigh_fourthIcon">
                                <img
                                  src={WeighforthIcon}
                                  alt="WeighforthIcon"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <img
                              src={actionMenuTogglerIcon}
                              alt="actionMenuTogglerIcon"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <span className="">
                              <FormControl
                                control="checkbox"
                                type="checkbox"
                                id="terms"
                                name="terms"
                              />
                            </span>
                          </td>
                          <td className="personImage">
                            <img src={MaleGiImage} alt="MaleGiImage" />
                          </td>
                          <td>John Smith</td>
                          <td>12/12/78</td>
                          <td>bmjja</td>
                          <td>Boys Gi</td>
                          <td>Adult</td>
                          <td>-76 kg (Light)</td>
                          <td></td>
                          <td>
                            <div className="weighMain">
                              <div className="weigh_firstIcon">
                                <img
                                  src={WeighFirstIcon}
                                  alt="WeighFirstIcon"
                                />
                              </div>
                              <div className="weigh_secondIcon">
                                <img
                                  src={WeighSecondIcon}
                                  alt="WeighSecondIcon"
                                />
                                <span>76</span>
                              </div>
                              <div className="weigh_checkedThirdIcon">
                                <span>
                                  <img
                                    src={WeightCheckedIcon}
                                    alt="WeightCheckedIcon"
                                  />
                                </span>
                              </div>
                              <div className="weigh_fourthIcon">
                                <img
                                  src={WeighforthIcon}
                                  alt="WeighforthIcon"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <img
                              src={actionMenuTogglerIcon}
                              alt="actionMenuTogglerIcon"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <span className="">
                              <FormControl
                                control="checkbox"
                                type="checkbox"
                                id="terms"
                                name="terms"
                              />
                            </span>
                          </td>
                          <td className="personImage">
                            <img src={MaleGiImage} alt="MaleGiImage" />
                          </td>
                          <td>John Smith</td>
                          <td>12/12/78</td>
                          <td>bmjja</td>
                          <td>Boys Gi</td>
                          <td>Adult</td>
                          <td>-76 kg (Light)</td>
                          <td></td>
                          <td>
                            <div className="weighMain">
                              <div className="weigh_firstIcon">
                                <img
                                  src={WeighFirstIcon}
                                  alt="WeighFirstIcon"
                                />
                              </div>
                              <div className="weigh_secondIcon">
                                <img
                                  src={WeighSecondIcon}
                                  alt="WeighSecondIcon"
                                />
                                <span>76</span>
                              </div>
                              <div className="weigh_checkedThirdIcon">
                                <span>
                                  <img
                                    src={WeightCheckedIcon}
                                    alt="WeightCheckedIcon"
                                  />
                                </span>
                              </div>
                              <div className="weigh_fourthIcon">
                                <img
                                  src={WeighforthIcon}
                                  alt="WeighforthIcon"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <img
                              src={actionMenuTogglerIcon}
                              alt="actionMenuTogglerIcon"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="nextButton">
                    <button>Next</button>
                  </div>
                </div>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerCreateAnEventRegistrationStyle>
    </div>
  );
};

export default OrganizerCreateAnEventRegistration;

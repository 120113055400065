import { Card, Form } from "antd";
import Head from "../../../components/Head/Head";
import { FormPageStyle, StudentViewStyling } from "./style";
import { Col, Row } from "react-bootstrap";
import { ErrorMessage, Formik } from "formik";
import FormControl from "../../../components/FormControl";
import { AcademyInitialValues } from "../constant";
import CustomPhoneInput from "../../../components/CustomPhoneInput/CustomPhoneInput";
import {
  Black,
  RobinEggBlue,
  fontFamilyEnnVisionsRegular,
  fontFamilyRegularUber,
} from "../../../components/GlobalStyle";
import PlacesAutoCompleteInput from "../../../maps/PlacesAutocomplete";
import CheckboxesSelect from "../../../components/CustomCheckbox/CheckboxesSelect";
import { DataTypesWithIdAndMultipleLangLabel } from "../../../app/features/types";
import CustomButton from "../../../components/CustomButton/CustomButton";
import useAcademy from "../Hooks/useAcademy";
import useNavigator from "../../../components/useNavigator";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { base_url } from "../../../utils/api_urls";
import api from "../../../api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import OverlayImages from "../../Home/OverlayImages/OverlayImages";

const validationSchema = Yup.object().shape({
  businessName: Yup.string().required('Business name is required'),
  businessType: Yup.number().required('businessType is required'),
  phoneNumber: Yup.string().required('phoneNumber is required'),
  address: Yup.string().required('address is required'),
  rank: Yup.string().required('rank is required'),
  defaultLanguageId: Yup.string().required(
    'Please select default language'
  ),
  defaultCurrencyId: Yup.string().required(
    'Please select default currency'
  ),
  description: Yup.string().required('Please enter description'),
  activities: Yup.array()
    .of(Yup.string().required('Select at least one activities'))
    .min(1, 'Select at least one facility'),
  facilities: Yup.array()
    .of(Yup.string().required('Select at least one facility'))
    .min(1, 'Select at least one facility'),

  // Add validation rules for other fields here
});

const AcademyCreate = () => {
  const [academyValues, setAcademyValues] = useState<AcademyInitialValues>()
  const { affiliateConfirmation, SuccessModal, WarningModal, setIsShowModal, academycreatedModal, setisAcademyModal } = useAcademy();
  const [academyId, setAcademyId] = useState<any>()
  const navigate = useNavigate()
  const { data } = useSelector((state: RootState) => state.loginData);
  const initialValues: AcademyInitialValues = {
    userId: data.user.id,
    businessName: "",
    businessType: "",
    phoneNumber: "",
    address: "",
    rank: true,
    defaultLanguageId: "",
    defaultCurrencyId: "",
    description: "",
    activities: [],
    facilities: [],
  };

  const onsubmit = async (
    values: AcademyInitialValues
  ): Promise<void> => {
    setAcademyValues(values)
    setIsShowModal(true)
    // await handleAcademyCreate(values)
  }
  const adminId = Number(data.user.id)

  useEffect(() => {
    const getAcademy = async () => {
      try {
        const { data } = await axios.post(`${base_url}/api/academy/findallbyuser`, { adminId: adminId });
        if (data && data.result && data.result.length > 0) {

          const firstAcademy = data.result[0];
          const { academyid } = firstAcademy;
          setAcademyId(academyid);

          if (data.status === "success") {
            setisAcademyModal(true)

            setTimeout(() => {
              navigate(`/academy/list`)

            }, 3000);
          }
        }
      } catch (error: any) {
        console.error("Error fetching academy data:", error?.response?.data?.message);
      }
    };

    getAcademy();
  }, []);


  const activitiesDummyList: DataTypesWithIdAndMultipleLangLabel[] = [
    {
      id: "1",
      en: "Football",
      es: "Fútbol",
      pt: "Futebol",
      value: "Football",
      label: "Football",
      ar: "",
      ur: "",
      imageUrl: "",
    },
    {
      id: "2",
      en: "Basketball",
      es: "Baloncesto",
      pt: "Basquetebol",
      value: "Basketball",
      label: "Basketball",
      ar: "",
      ur: "",
      imageUrl: "",
    },
    {
      id: "3",
      en: "Swimming",
      es: "Natación",
      pt: "Natação",
      value: "Swimming",
      label: "Swimming",
      ar: "",
      ur: "",
      imageUrl: "",
    },
  ];

  const facilitiesDummyList: DataTypesWithIdAndMultipleLangLabel[] = [
    {
      id: "1",
      en: "Gym",
      es: "Gimnasio",
      pt: "Academia",
      value: "Gym",
      label: "Gym",
      ar: "",
      ur: "",
      imageUrl: "",
    },
    {
      id: "2",
      en: "Library",
      es: "Biblioteca",
      pt: "Biblioteca",
      value: "Library",
      label: "Library",
      ar: "",
      ur: "",
      imageUrl: "",
    },
    {
      id: "3",
      en: "Computer Lab",
      es: "Laboratorio de Computación",
      pt: "Laboratório de Informática",
      value: "Computer Lab",
      label: "Computer Lab",
      ar: "",
      ur: "",
      imageUrl: "",
    },
  ];
  const onsubmitFromFormik = () => { }

  return (
    <>
      {SuccessModal().modalComponent}
      {WarningModal().modalComponent}
      {academycreatedModal().modalComponent}

      {academyValues && affiliateConfirmation(academyValues).modalComponent}
      <Head title="ULTM8 - Academy Create" />
      <StudentViewStyling>
          <OverlayImages
            backgroundImg={''}
            overlayImg={''}
            isEditable={true}
          />
        <Card>
          <h3>Owner Information</h3>
          <Row className="mt-20">
            <Col md="6">
              <div className="list-item">
                <div className="list-item-title">
                  Owner First Name
                </div>
                <div className="list-item-value">
                  {data.user.firstName || "--"}
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="list-item">
                <div className="list-item-title">
                  Owner Last Name
                </div>
                <div className="list-item-value">
                  {data.user.lastName || "--"}
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="list-item">
                <div className="list-item-title">Email</div>
                <div className="list-item-value">
                  {data.user.email || "--"}
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="list-item">
                <div className="list-item-title">
                  Phone Number
                </div>
                <div className="list-item-value">
                  {data.user.phoneNumber || "--"}
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </StudentViewStyling>
      <FormPageStyle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onsubmitFromFormik}
        >
          {(formik) => {
            return (
              <Form
                name="basic"
                onFinish={formik.handleSubmit}
                autoComplete="off"
              >
                <div className="bg-white form">
                  <h3>Academy Create</h3>
                  <Row>
                    <Col md="4" className="mt-20">
                      <FormControl
                        control="input"
                        type="text"
                        name="businessName"
                        label="Academy Name"
                        fontSize="16px"
                        max={6}
                        placeholder="Enter Academy Name"
                        className={
                          formik.errors.businessName &&
                            formik.touched.businessName
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                    </Col>
                    <Col md="4" className="mt-20">
                      <FormControl
                        control="select"
                        type="text"
                        name="businessType"
                        label="Academy Type"
                        placeholder="Select Academy Type"
                        className={
                          formik.errors.businessType &&
                            formik.touched.businessType
                            ? "is-invalid"
                            : "customInput"
                        }
                        options={[
                          { value: 1, label: "School" },
                          { value: 2, label: "Academy" },
                          { value: 3, label: "Coach" },
                        ]}
                      />
                    </Col>
                    <Col md="4" className="mt-20">
                      <CustomPhoneInput
                        label="Phone Number"
                        name="phoneNumber"
                        value={formik.values.phoneNumber}
                        placeholder="Enter Phone Number"
                        limitMaxLength={true}
                        handleOnChange={(e: string) => {
                          formik.setFieldValue("phoneNumber", e);
                        }}
                      />
                      <ErrorMessage name={"phoneNumber"}>
                        {(msg) => (
                          <div
                            className="error-message is-invalid"
                            style={{
                              color: "red",
                              textAlign: "right",
                              marginLeft: "3px",
                              fontSize: "12px",
                              letterSpacing: "1px",
                              fontFamily: fontFamilyEnnVisionsRegular,
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col md="4" className="mt-20">
                      <PlacesAutoCompleteInput
                        label="Academy Address"
                        placeholder="Enter Academy Address"
                        handleChange={(val) => {
                          formik.setFieldValue("address", val);
                        }}
                        className={
                          formik.errors.address &&
                            formik.touched.address
                            ? "is-invalid"
                            : "customInput"
                        }
                        formik={formik}
                        name="address"
                        value={formik.values.address}
                      />
                    </Col>
                    <Col md="4" className="mt-20">
                      <FormControl
                        control="select"
                        type="text"
                        name="defaultLanguageId"
                        fontFamily={fontFamilyEnnVisionsRegular}
                        label="Default Language"
                        placeholder="Select Default Language"
                        className={
                          formik.errors.defaultLanguageId &&
                            formik.touched.defaultLanguageId
                            ? "is-invalid"
                            : "customInput"
                        }
                        options={[
                          { value: 1, label: "English" },
                          { value: 2, label: "Urdu" },
                          { value: 3, label: "Punjabi" },
                        ]}
                      />
                    </Col>
                    <Col md="4" className="mt-20">
                      <FormControl
                        control="select"
                        type="text"
                        name="defaultCurrencyId"
                        fontFamily={fontFamilyEnnVisionsRegular}
                        label="Default Currency"
                        placeholder="Select Default Currency"
                        className={
                          formik.errors.defaultCurrencyId &&
                            formik.touched.defaultCurrencyId
                            ? "is-invalid"
                            : "customInput"
                        }
                        options={[
                          { value: 1, label: "USD" },
                          { value: 2, label: "Euro" },
                          { value: 3, label: "Rupees" },
                        ]}
                      />
                    </Col>
                    <Col md="6" className="">
                      <CheckboxesSelect
                        list={activitiesDummyList}
                        name="activities"
                        label="Activity"
                        showErrorMsgInList={false}
                        placeholder="Select Activity"
                      />
                    </Col>
                    <Col md="6" className="">
                      <CheckboxesSelect
                        list={facilitiesDummyList}
                        name="facilities"
                        label="Facilities"
                        showErrorMsgInList={false}
                        placeholder="Select Facilities"
                      />
                    </Col>
                    <Col className="mt-20">
                      <FormControl
                        control="textarea"
                        type="text"
                        name="description"
                        fontFamily={fontFamilyEnnVisionsRegular}
                        label="Description"
                        padding="10px"
                        placeholder="Enter Description"
                        className={
                          formik.errors.description &&
                            formik.touched.description
                            ? "is-invalid"
                            : "customInput"
                        }
                        height="200px"
                      />
                    </Col>
                  </Row>
                </div>
                <div className="mt-20 d-flex justify-content-end">
                  <CustomButton
                    bgcolor={RobinEggBlue}
                    textTransform="uppercase"
                    color={Black}
                    padding="11px 40.50px"
                    fontFamily={fontFamilyRegularUber}
                    width="fit-content"
                    type="submit"
                    title="Submit"
                    fontSize="14px"
                    loading={false}
                    // disabled={!formik.isValid || !formik.touched}
                    clicked={() => { onsubmit(formik.values) }}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </FormPageStyle>
    </>
  );
};

export default AcademyCreate;

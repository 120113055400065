import React from "react";
import { ProfileBeforeLoginIntroductionStyle } from "./style";
import EastLaLogo from "../../assets/images/EAST-LA-LOGO.png";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";
export const ProfileBeforeLoginIntroduction = () => {
  const { data } = useSelector((state: RootState) => state.loginData);
  console.log("data", data)

  return (
    <div>
      <ProfileBeforeLoginIntroductionStyle>
        <div className="introductionMain">
          <div className="cardContainer">
            <div className="eastLogo">
              <img src={EastLaLogo} alt="EastLaLogo" />
            </div>
            <div className="personName">
              <h4>{data.user.firstName +' '+ data.user.lastName || "--"}</h4>
            </div>
            <div className="address">
              <p>
                Location:{' '}<span>Islamabad Pakistan</span>
              </p>
            </div>
            <div className="phoneNum">
              <p>
                Phone: <span> {data.user.phoneNumber || "--"}</span>
              </p>
            </div>
            <div className="age">
              <p>
                Age :<span> 32 Years</span>
              </p>
            </div>
            <div className="nationality">
              <p>
                Nationality: <span>Pakistan</span>
              </p>
            </div>
          </div>

          {/* <div className="rightSide">
          </div> */}
        </div>
      </ProfileBeforeLoginIntroductionStyle>
    </div>
  );
};

export default ProfileBeforeLoginIntroduction;

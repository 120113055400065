import styled from "styled-components";
import { fontFamilyEnnVisionsRegular, fontFamilyMedium } from "../../../components/GlobalStyle";

export const OrganizerSettingCategoriesStyle = styled.div`
.ant-layout > .banner + .ant-layout {
    flex-direction: row;
    gap: 10px;
  }
  .title {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-top: 29px;
    margin-bottom: 0;
  }
  .OrganizerSettingCategories-left {
    max-width: 15%;
    width: 100%;
  }
  .categories {
    padding: 15px 20px 20px 0px;
    border: 1px solid #E0E0E0;
    border-radius 10px;
    margin-top: 13px;

  }
  ul.tree {
    height: 64%;
    overflow-y: auto;
  }
  ul.tree li {
    position: relative;
    list-style: none;
    padding-left: 5px;
    margin-bottom: 5px;
  }
  ul.tree li .text {
    margin-left: 20px;
    color: #0070E0;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  ul.tree li::before {
    content: '';
    position: absolute;
    height: 1px;
    background: #D7D9DD;
    top: 11px;
    width: 30px;
    left: -15px;
  }
  ul.tree > li:before {
    display: none;
  }
  ul.tree li > ul.child {
    margin-top: 3px;
    position: relative;
    padding-left: 4px;
  }
  ul.tree ul.child::before {
    content: '';
    position: absolute;
    width: 1px;
    background: #D7D9DD;
    top: -9px;
    bottom: 10px;
    left: -14px;
  }
  ul.tree li.has-children > ul {
    display: none;
  }
  ul.tree li.showChildren > ul {
    display: block;
  }
  ul.child > li:last-child li:after {
    content: '';
    position: absolute;
    width: 1px;
    background: #D7D9DD;
    top: -9px;
    bottom: 4px;
    left: -27px;
  }
  



`;
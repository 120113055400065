import styled from "styled-components";
import { fontFamilyMediumUber, fontFamilyRegularUber } from "../../../components/GlobalStyle";

export const CreateEventStyle = styled.div`
.banner img {
    width: 100%;
}
.banner + .ant-layout{
    flex-direction: row;
}
.creatEvent-left{
    width: 24.062%;
}
.creatEvent-right {
    width: 75.938%;
    margin-left: auto;
}
.nav {
    display: flex;
    align-items: center;
    border: 1px solid #E9E9E9;
    background: #EBEBEB;
}
.nav li{
    padding: 10px 20px;
    cursor: pointer;

    color: #525A66;
    text-align: center;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.nav li.active{
    color: #18273B;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration-line: underline;
}


`;
import React from 'react'
import { ModelScoreBoardVarStyle } from "./style"
import ModelCrossIcon from "../../assets/icons/ic_crossBtnModel.svg"
import WarningIcon from "../../assets/icons/ic_Warning.svg"

type ModelScoreBoardVarProps = {
    closeVarModalHandler: () => void
}


const ModelScoreBoardVar: React.FC<ModelScoreBoardVarProps> = ({
    closeVarModalHandler
}) => {
    return (
        <div>
            <ModelScoreBoardVarStyle>
                <div className='overlay'></div>
                <div className='modelScoreBoard'>
                    <span className='crossBtns' onClick={closeVarModalHandler}>
                        <img src={ModelCrossIcon} alt="ModelCrossIcon" />
                    </span>
                    <div className='modelConetnt'>
                        <div className="swal2-icon swal2-question">
                            <img src={WarningIcon} alt="" />
                        </div>
                        <h2>VAR FUNCTION ACTIVATED</h2>
                        <p>Kindly disable the VAR function to enable the clock's activation.</p>
                    </div>
                </div>
            </ModelScoreBoardVarStyle>
        </div>
    )
}

export default ModelScoreBoardVar
import styled from "styled-components";
import { fontFamilyMedium, fontFamilyRegular, whiteColor } from "../../GlobalStyle";

export const EndGameStyle = styled.div`
.endGameBottomInner{}

.endGameBottomInner table{
    width: 100%;
    max-width: 72vh;
    border-radius: 8px;
    background: #000;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.55);
    text-align: center;
}
.endGameBottomInner table tr:nth-child(1){

}
.endGameBottomInner table tr:nth-child(1) td{
    // padding: 2vh;
    // color: #78B329;
    // font-family: ${fontFamilyRegular};
    // font-size: 3.4vh;
    // font-style: normal;
    // font-weight: 400;
    // line-height: normal;
    // letter-spacing: -0.34px;
    // text-transform: uppercase;
    // text-align: center;

    padding: 2vh;
    color: ${whiteColor};
    font-family: ${fontFamilyMedium};
    font-size: 3.5vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.25px;
    text-transform: uppercase;
}

.endGameBottomInner table tr:nth-child(2){

}
.endGameBottomInner table tr:nth-child(2) td{
    padding: 2vh;
    color: ${whiteColor};
    font-family: ${fontFamilyMedium};
    font-size: 3.0vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.25px;
    text-transform: uppercase;
}

`;

import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber } from "../../../components/GlobalStyle";


export const OrganizerCreateAnEventCouponStyle = styled.div`

.bodyWrapper_RightSide {
    width: 100%;
    padding-left: 35px;
}
.title_or_createCouponBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    margin-top: 16px;
}
.title_or_createCouponBtn h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.createCouponBtn {
    padding: 10px 12px 11px 15px;
    border-radius: 4px;
    border: 0.6px solid #BBE6C3;
    background-color: #DEF2E2;
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
.availableCouponListContainer {
    width: 100%;
    margin-top: 16px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    background-color: #FFF;
    padding-bottom: 235px;
    padding-left: 1px;
}
table {
    width: 100%;}
table thead tr th {
    padding: 17px 0 24px 10px;
    color: #061229;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
table thead tr th:nth-child(2) {
    padding-left: 12.27px;
}
table tbody tr td {
    padding: 3px 0px 2px 10px;
}
table tbody tr td:nth-child(2) {
    padding-left: 16.27px;
}
table thead tr th:first-child,
table tbody tr td:first-child {
    padding-left: 9px !important;
    width: 2%;
}
table thead tr th:nth-child(2),
table tbody tr td:nth-child(2) {
    width: 6%;
}
table thead tr th:nth-child(3),
table tbody tr td:nth-child(3) {
    width: 8%;
}
table thead tr th:nth-child(4),
table tbody tr td:nth-child(4) {
    width: 18%;
}
table thead tr th:nth-child(5),
table tbody tr td:nth-child(5) {
    width: 10%;
}
table thead tr th:nth-child(6),
table tbody tr td:nth-child(6) {
    width: 13%;
}
table thead tr th:nth-child(7),
table tbody tr td:nth-child(7) {
    width: 10%;
}
table thead tr th:nth-child(8),
table tbody tr td:nth-child(8) {
    width: 10%;
}
table thead tr th:nth-child(9),
table tbody tr td:nth-child(9) {
    width: 12%;
}
table thead tr th:nth-child(10),
table tbody tr td:nth-child(10) {
    width: 12%;
}
table thead tr th:last-child,
table tbody tr td:last-child {
    padding-right: 16px;
    width: 9%;
}
table tbody tr td:last-child {
    text-align: center;
}
table tbody tr:nth-child(odd) {
    background-color: #FAFAFA;
}
table tbody tr:nth-child(even) {
    background-color: #FFF;
}
.id, .code {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.validity p, .validity span,
.timeZone, .amount, .event, .createdBy {
    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 0;
}
.limit span, .limit p {
    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
}
.status span {
    padding: 4px 31px 3px 31px;
    border-radius: 4px;
    background-color: #4CAF50;
    color: #FFF;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align-center;
}
.actions img {
    cursor: pointer;
}
.nextBtn {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: end;
}
.nextBtn button {
    padding: 11px 39px 10px 40px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;   
}
`;
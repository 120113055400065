import { useNavigate } from "react-router-dom";
import {
  Academy_Create_URL,
  Academy_Delete_URL,
  Academy_Find_URL,
  Academy_List_URL,
  Academy_Update_URL,
} from "../../../utils/api_urls";
import { AcademyInitialValues } from "../constant";
import { useState } from "react";
import CustomButton from "../../../components/CustomButton/CustomButton";
import {
  RobinEggBlue,
  fontFamilyRegularUber,
  pureDark2,
} from "../../../components/GlobalStyle";
import { Col, Row } from "react-bootstrap";
import { SchoolSuccessfulModals } from "../../../hooks/PopupModalsStyling";
import CustomModal from "../../../components/Modal/CustomModal";
import CustomMessageModal from "../../../components/Modal/CustomMessageModal";
import ic_success from "../../../assets/images/ic_success.svg";
import api from "../../../api";

interface IModalComponent {
  modalComponent: JSX.Element;
}

interface IUseAcademy {
  loading: boolean;
  handleAcademyCreate: (values: AcademyInitialValues) => Promise<void>;
  handleAcademyUpdate: (
    AcademyId: number,
    values: AcademyInitialValues
  ) => Promise<void>;
  showAcademyList: (academyId: number) => Promise<any>;
  getAcademyView: (academyId: number) => Promise<any>;
  handleAcademyDelete: (academyId: number) => Promise<any>;
  SuccessModal: () => IModalComponent;
  WarningModal: () => IModalComponent;
    academycreatedModal: () => IModalComponent;

  deleteConfirmation: (id: number, setAcademy: any) => IModalComponent;
  affiliateConfirmation: (values: AcademyInitialValues) => IModalComponent;
  setIsShowModal: (showModal: true) => void;
  setisAcademyModal: (showModal: true) => void;
}

const useAcademy = (): IUseAcademy => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  
  const [isAcademyModal, setisAcademyModal] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState<any>();

  // Academy Create
  const handleAcademyCreate = async (
    values: AcademyInitialValues
  ): Promise<void> => {
    const payload = {
      userId: values.userId,
      businessName: values.businessName,
      businessType: values.businessType,
      phoneNumber: values.phoneNumber,
      address: values.address,
      rank: values.rank,
      defaultLanguageId: values.defaultLanguageId,
      defaultCurrencyId: values.defaultCurrencyId,
      activities: values.activities.join(","),
      facilities: values.facilities.join(","),
      description: values.description,
    };
    try {
      setLoading(true);
      const { data } = await api("post", Academy_Create_URL, payload);
      setSuccessMessage(data.responseMessage);
      setIsShowSuccessModal(true);
      setLoading(false);
      setTimeout(() => {
        setIsShowSuccessModal(false);
        navigate("/academy/list");
      }, 3000);
    } catch (error: any) {
      setLoading(false);

      let errorMessages: string[] = [];

      if (error.response?.data?.message) {
        // If error message is an object, convert it to an array of strings
        errorMessages = Object.values(error.response?.data?.message);
      } else {
        // If error message is not available, set a generic error message
        errorMessages = ["An error occurred while processing your request."];
      }

      setLoading(false);
      setErrorMessage(errorMessages);
      setIsShowErrorModal(true);

      setTimeout(() => {
        setIsShowErrorModal(false);
      }, 10000);
    }
  };

  // Academy List
  const showAcademyList = async (adminId: number): Promise<any> => {
    try {
      const { data } = await api("post", Academy_List_URL);
      console.log("AcademyList", data.result);
      return data.result;
    } catch (error) {
      console.log("error", error);
    }
  };

  // Academy Update
  const handleAcademyUpdate = async (
    academyId: number,
    values: AcademyInitialValues
  ): Promise<any> => {
    const url = Academy_Update_URL;
    try {
      const payload = {
        academyId: academyId,
        userId: values?.userId,
        businessName: values.businessName,
        businessType: values.businessType,
        phoneNumber: values.phoneNumber,
        address: values.address,
        rank: values.rank,
        defaultLanguageId: values.defaultLanguageId,
        defaultCurrencyId: values.defaultCurrencyId,
        activities: values.activities,
        facilities: values.facilities,
        description: values.description,
      };

      const { data } = await api("post", url, payload);
      setLoading(false);
      setSuccessMessage(data.message)
      setIsShowSuccessModal(true);
      setTimeout(() => {
        setIsShowSuccessModal(false);
        navigate("/academy/list");
      }, 3000);
    } catch (error: any) {
      setLoading(false);

      setErrorMessage(error.response?.data?.message);
      setIsShowErrorModal(true);

      setTimeout(() => {
        setIsShowErrorModal(false);
      }, 10000);
    }
  };

  // Academy View
  const getAcademyView = async (academyId: number): Promise<any> => {
    try {
      const { data } = await api("post", Academy_Find_URL, {
        academyId: academyId,
      });
      return data.result;
    } catch (error: any) {
      console.log("error", error);
    }
  };

  // Academy Delete
  const handleAcademyDelete = async (academyId: number): Promise<any> => {
    try {
      const { data } = await api("post", Academy_Delete_URL, {
        academyId: academyId,
      });
      setLoading(false);
      setSuccessMessage(data.message)
      setIsShowSuccessModal(true);
      setTimeout(() => {
        setIsShowSuccessModal(false);
        navigate("/academy/list");
      }, 3000);
      return data
    } catch (error: any) {
       if (error.response.data.error.code === "23503") {
            setErrorMessage("first delete all branches and coach request connected to this academy")
            setIsShowErrorModal(true)
            setLoading(false)
            setTimeout(() => {
                setIsShowErrorModal(false)
            }, 3000)
            }
          else { setErrorMessage(error.response.data.message)
            setIsShowErrorModal(true)
            setLoading(false)
            setTimeout(() => {
                setIsShowErrorModal(false)
            }, 3000)
            }
           return error
    }
  };

  const SuccessModal = (): IModalComponent => {
    return {
      modalComponent: (
        <CustomMessageModal
          title="Success"
          description={successMessage}
          isModalVisible={isShowSuccessModal}
          setIsModalVisible={setIsShowSuccessModal}
          imageProp={"success"}
        />
      ),
    };
  };
    const academycreatedModal = (): IModalComponent => {
    return {
      modalComponent: (
        <CustomMessageModal
          title="Warning"
          description={"You have already created academy. (you can only create 1 academy)"}
          isModalVisible={isAcademyModal}
          setIsModalVisible={setisAcademyModal}
          imageProp={"error"}
        />
      ),
    };
  };

  const WarningModal = (): IModalComponent => {
    return {
      modalComponent: (
        <CustomMessageModal
          title="Warning"
          description={errorMessage}
          isModalVisible={isShowErrorModal}
          setIsModalVisible={setIsShowErrorModal}
          imageProp={"error"}
        />
      ),
    };
  };

  const deleteConfirmation = (
    _id: number,
    setAcademy: any
  ): IModalComponent => {
    const DeleteAcademy = async (id: number): Promise<void> => {
      setIsShowModal(false);

     const data= await handleAcademyDelete(Number(id));
      if (data.status === "success") {
        setAcademy((prev: any) => {
          prev = prev.filter((el: any) => el.academyId !== id);
          return [...prev];
        });
      }
    };
    return {
      modalComponent: (
        <CustomModal
          isModalVisible={isShowModal}
          setIsModalVisible={setIsShowModal}
          showCloseBtn={false}
        >
          <SchoolSuccessfulModals>
            <div className="mainContainer">
              <div className="mainContainer-header">
                <img
                  src={ic_success}
                  alt="Success Icon"
                  width={25}
                  height={25}
                />
                <h3 className="mainContainer-header-heading">Delete Academy</h3>
              </div>
              <div className="mainContainer-body">
                <p className="mainContainer-body-subText">
                  You can block them directly from their profile. If you do,
                  they won't be able to find your profile, posts or story or
                  send you messages on Instagram. Instagram won't let them know
                  you blocked them.
                </p>
                <Row className="mt-20">
                  <Col md="6">
                    <CustomButton
                      bgcolor={"#292F3F33"}
                      textTransform="uppercase"
                      color={pureDark2}
                      padding="10px 12.5px"
                      fontFamily={fontFamilyRegularUber}
                      width="100%"
                      type="button"
                      title="NO!"
                      fontSize="16px"
                      loading={false}
                      clicked={() => setIsShowModal(false)}
                    />
                  </Col>
                  <Col md="6">
                    <CustomButton
                      bgcolor={RobinEggBlue}
                      textTransform="uppercase"
                      color={pureDark2}
                      padding="10px 12.5px"
                      fontFamily={fontFamilyRegularUber}
                      width="100%"
                      type="submit"
                      title="YES"
                      fontSize="16px"
                      loading={false}
                      clicked={() => DeleteAcademy(_id)}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </SchoolSuccessfulModals>
        </CustomModal>
      ),
    };
  };

  const affiliateConfirmation = (
    values: AcademyInitialValues
  ): IModalComponent => {
    const academyCreate = async (
      values: AcademyInitialValues
    ): Promise<void> => {
      await handleAcademyCreate(values);
      setIsShowModal(false);
    };
    return {
      modalComponent: (
        <CustomModal
          isModalVisible={isShowModal}
          setIsModalVisible={setIsShowModal}
          showCloseBtn={false}
        >
          <SchoolSuccessfulModals>
            <div className="mainContainer">
              <div className="mainContainer-header">
                <img
                  src={ic_success}
                  alt="Success Icon"
                  width={25}
                  height={25}
                />
                <h3 className="mainContainer-header-heading">
                  Academy Affiliation
                </h3>
              </div>
              <div className="mainContainer-body">
                <p className="mainContainer-body-subText">
                  Do you want to affiliate your academy with another academy?
                </p>
                <Row className="mt-20">
                  <Col md="6">
                    <CustomButton
                      bgcolor={"#292F3F33"}
                      textTransform="uppercase"
                      color={pureDark2}
                      padding="10px 12.5px"
                      fontFamily={fontFamilyRegularUber}
                      width="100%"
                      type="button"
                      title="NO!"
                      fontSize="16px"
                      loading={false}
                      clicked={() => academyCreate(values)}
                    />
                  </Col>
                  <Col md="6">
                    <CustomButton
                      bgcolor={RobinEggBlue}
                      textTransform="uppercase"
                      color={pureDark2}
                      padding="10px 12.5px"
                      fontFamily={fontFamilyRegularUber}
                      width="100%"
                      type="submit"
                      title="YES"
                      fontSize="16px"
                      loading={false}
                      clicked={() => (
                        navigate("/academy/for-affiliation"),
                        academyCreate(values)
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </SchoolSuccessfulModals>
        </CustomModal>
      ),
    };
  };

  return {
    loading,
    handleAcademyCreate,
    showAcademyList,
    handleAcademyUpdate,
    getAcademyView,
    handleAcademyDelete,
    SuccessModal,
    deleteConfirmation,
    affiliateConfirmation,
    WarningModal,
    setIsShowModal,
    setisAcademyModal,
    academycreatedModal
    
  };
};

export default useAcademy;

import styled from "styled-components";
import { fontFamilyRegularUber, lightDark2, pureDark2 } from "../../../../components/GlobalStyle";


export const StudentActiveStyle = styled.div`
.StudentsWrapper{
    .studentTitle{
        color: ${pureDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.56px;
        margin-bottom: 0;
    }

    .studentHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            color: ${pureDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.56px;
            margin-bottom: 0;
        }

        .batchHeader{
            display: flex;
            align-items: center;
            gap: 10px;
            h4{
                color: ${lightDark2};
                font-family: ${fontFamilyRegularUber};
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.56px;
                margin-bottom: 0;
            }
            .batchSelect{
                border: 1px solid #959595;
                height: 30px;
                border-radius: 4px;
                padding: 0px 4px;
                background-color: #EFEFEF;
            }
        }

    }
}
`
import React from "react";
import {
  FormFieldStyling,
  OrganizerAllEntriesValueSettingsStyle,
} from "./style";
import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import DeleteIcon from "../../../assets/icons/ic_deleteDark.svg";
import TdDragDropIcon from "../../../assets/icons/ic_DragDropIcon.svg"
import StatusActiveError from "../../../assets/images/activeBtnError.svg";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import FormControl from "../../../components/FormControl";
import {
  fontFamilyEnnVisionsMedium,
  fontFamilyEnnVisionsRegular,
  fontFamilyRegular,
  mediumPurple,
  whiteColor,
} from "../../../components/GlobalStyle";
import PlacesAutoCompleteInput from "../../../maps/PlacesAutocomplete";
import CustomButton from "../../../components/CustomButton/CustomButton";
const { Content } = Layout;

const OrganizerAllEntriesValueSettings = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };
  const handleCreateSubmit = (): void => {};
  return (
    <div>
      <OrganizerAllEntriesValueSettingsStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="creatEvent-full">
                <FormFieldStyling>
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={handleCreateSubmit}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className="formWrapper">
                            <h3 className="title">Value Settings</h3>
                            <div className="tablelist">
                              <div className="organizerSettingForm">
                                <Row>
                                  <Col md="6" className="mb-20">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-use-ammount"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Name"
                                      padding="10px"
                                      placeholder="Jiu Jitsu"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="6" className="">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-use-ammount"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Description"
                                      padding="10px"
                                      placeholder="4 year olds"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div className="formWrapper">
                            <h3 className="title">Rule</h3>
                            <div className="tablelist">
                              <table
                                width="100%"
                                cellPadding={0}
                                cellSpacing={0}
                              >
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Category</th>
                                    <th>Value</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.from({ length: 8 }, (_, index) => (
                                    <tr>
                                      <td>
                                        <img
                                          src={TdDragDropIcon}
                                          alt="TdDragDropIcon"
                                        />
                                      </td>
                                      <td>User: Age year only</td>
                                      <td>Less or Eq</td>
                                      <td>15</td>
                                      <td>
                                        <label className="Active">Active</label>
                                        <img
                                          src={StatusActiveError}
                                          alt="StatusActiveError"
                                        />
                                      </td>
                                      <td className="actions">
                                        <img
                                          src={actionMenuTogglerIcon}
                                          alt="actionMenuTogglerIcon"
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="formWrapper mt-20">
                            {/* <h3 className="title">Rule</h3> */}
                            <div className="tablelist">
                              <div className="organizerSettingForm">
                                <>
                                  <Row className="mt-20 mb-20">
                                    <Col md="6" className="">
                                      <FormControl
                                        control="input"
                                        type="text"
                                        name="category1"
                                        fontFamily={fontFamilyEnnVisionsRegular}
                                        label="Custom Rule"
                                        padding="10px"
                                        placeholder="Yes"
                                        className={
                                          formik.errors.detail &&
                                          formik.touched.detail
                                            ? "is-invalid"
                                            : "customInput"
                                        }
                                      />
                                    </Col>
                                    <Col md="6" className="">
                                      <FormControl
                                        control="select"
                                        type="select"
                                        name="category2"
                                        fontFamily={fontFamilyEnnVisionsRegular}
                                        label="Name"
                                        padding="10px"
                                        placeholder="4 years"
                                        className={
                                          formik.errors.detail &&
                                          formik.touched.detail
                                            ? "is-invalid"
                                            : "customInput"
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </>
                              </div>
                            </div>
                          </div>

                          <div className="mt-20 d-flex justify-content-end">
                            <CustomButton
                              bgcolor={mediumPurple}
                              textTransform="Captilize"
                              color={whiteColor}
                              padding="11px 40.50px"
                              fontFamily={`${fontFamilyEnnVisionsMedium}`}
                              width="fit-content"
                              type="submit"
                              title="Save"
                              fontSize="18px"
                              loading={false}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </FormFieldStyling>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerAllEntriesValueSettingsStyle>
    </div>
  );
};

export default OrganizerAllEntriesValueSettings;

import React from "react";
import { OrganizerAllEntriesValueSettings2Style } from "./style";
import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { Row, Col } from "react-bootstrap";
import { Content } from "antd/lib/layout/layout";
import { Formik } from "formik";
import FormControl from "../../../components/FormControl";
import { Switch } from "antd";

const OrganizerAllEntriesValueSettings2 = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };
  const handleCreateSubmit = (): void => {};
  return (
    <div>
      <OrganizerAllEntriesValueSettings2Style>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="organizerAllEntriesValueSettings2_page">
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={handleCreateSubmit}
                >
                  {(formik) => {
                    return (
                      <div className="formsWrapper">
                        <Form>
                          <div className="title">
                            <h3>Entries Settings</h3>
                          </div>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerAllEntriesValueSettings2Style>
    </div>
  );
};

export default OrganizerAllEntriesValueSettings2;

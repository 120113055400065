import React from 'react'
import {OrganizerMessengerCenterParticipants3Style} from "./style";
import ParticipantsCard from "../../../components/EventRegistrationCard/EventRegistrationCard";
import PaginationComponent from "../../../components/ProfilesBeforeLoginPagination/ProfilesBeforeLoginPagination";
import { DatePicker } from 'antd';
import FromDateIcon from "../../../assets/icons/ic_FromDateIcon.svg"

export const OrganizerMessengerCenterParticipants3 = () => {
    const itemsArray = Array.from({ length: 12 }, (_, index) => index + 1);
  return (
    <div>
        <OrganizerMessengerCenterParticipants3Style>
        <div className='bodyWrapper_RightSide'>
                <div className='title_submitButton'>
                    <h4 className='title'>Message Center</h4>
                    <button className='sendNowButton'>Send Now</button>
                </div>
                <div className='tablelist'>
          <div className='organizerSettingForm'>
            <form>
              <div className='form-group'>
                <label>Activity</label>
                <select>
                  <option>omclean@kaimeramedia.com</option>
                </select>
              </div>

              <div className='form-group'>
                <label>Event</label>
                <select>
                  <option>omclean@kaimeramedia.com</option>
                </select>

              </div>
              <div className='form-group'>
                <label>Start Date</label>
                    <DatePicker
                        suffixIcon={<img src={FromDateIcon} alt="calender-icon" />}
                    />
              </div>
            </form>

          </div>
        </div>

                <div className='participantTitle'>
                    <h4>Participants</h4>
                </div>
                <div className='participantsList'>
                {itemsArray.map((item, index) => (
                        <ParticipantsCard key={index} />
                ))}
                </div>
                <div className='pagination_Container'>
                    <PaginationComponent />
                </div>
            </div>

        </OrganizerMessengerCenterParticipants3Style>
    </div>
  )
}

import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../../components/GlobalStyle"; 

export const AffiliationsProfileOverallAcademyAdultsStyle = styled.div`

.wrapper {
    width: 100%;
    display: flex;
}
.bodyLeftSideMain {
    width: 25%;
    margin-right: 35px;
}
.introductionParagraph {
    max-width: 359px;
    width: 100%;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding: 14px 12px 10px 17px;
    margin-top: 18px;
}
.introductionParagraph h6 {
    color: #000;
    text-align: justify;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.introductionParagraph p {
    color: #4F4F4F;
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 5px;
    margin-bottom: 0;
}
.mapFrame {
    max-width: 357px;
    width: 100%;
    border-radius: 0px 0px 9px 9px;
    border: 0px solid #E0E0E0;
    margin-top: 21px;
}
.bodyRightSideMain {
    width: 75%;
}
.rankingAdultsContainer {
    width: 100%;
    margin-top: 27px;
}
.rankingAdultsHeading h4{
    color: #061229;
    font-family: ${fontFamilyMedium};
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.categoriesContainer {
    background-color: #EFEFEF; 
}
.categories_AdultsKids {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 14px 15px 13px 12px;
    margin-top: 11px;
}
.active {
    padding: 9px 26px 10px 26px;
    border-radius: 6px;
    background: linear-gradient(0deg, #16374E 0%, #16374E 100%), rgba(0, 0, 0, 0.47);

    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
    text-decoration: none;
}
.kids {
    padding: 9px 35px 10px 36px;
    border-radius: 6px;
    background: linear-gradient(0deg, #CDCDCD 0%, #CDCDCD 100%), rgba(0, 0, 0, 0.47);

    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
    text-decoration: none;
}
.n0_Gi {
    padding: 9px 30px 10px 31px;
    border-radius: 6px;
    background: linear-gradient(0deg, #CDCDCD 0%, #CDCDCD 100%), rgba(0, 0, 0, 0.47);

    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
    text-decoration: none;
}
.gi {
    padding: 9px 44px 10px 44px;
    border-radius: 6px;
    background: linear-gradient(0deg, #CDCDCD 0%, #CDCDCD 100%), rgba(0, 0, 0, 0.47);

    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
    text-decoration: none;
}
.affiliationsTeamsOverAll_MainContainer {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    gap: 20px;
    margin-top: 19px;
    margin-bottom: 37px;
}
.bestAffiliationsOverallMain, .bestTeamsOverallMain {
    width: 49.07%;
}
.lastCalculated {
    padding: 8px 0px 8px 11px;
    border-radius: 3.965px 3.965px 0px 0px;
    border: 1px solid #F4F4F4;
    background-color: #EFEFEF;
    display: flex;
    flex-direction: column;
}
.lastCalculated h5{
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    margin-bottom: 0;
}
.lastCalculated span {
    color: #5A5A5C;
    font-family: ${fontFamilyRegularUber};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 129.5%;
    padding-top: 2px;
}
.bestAffiliationsCardsMain {
    display: flex;
    flex-direction: column;
    gap: 15px 0;
    margin-top: 7px;
}
.seeAll {
    border-radius: 3.965px 3.965px 0px 0px;
    border: 1px solid #F4F4F4;
    background-color: #EFEFEF;
    padding: 6px 0 7px 0;
    margin-top: 6px;
    text-align: center;
}
.seeAll span {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}
.block2_toLast {
    margin-top: 19px;
}
.newWave_AcademyCard, .pointsWins_Main {
    max-width: 100% !important;
}
`;

import { SidebarStyle } from "./style";
import { Layout } from "antd";
import CustomButton from "../CustomButton/CustomButton";
import {
  RobinEggBlue,
  fontFamilyRegularUber,
  pureDark,
} from "../GlobalStyle";
import { removeLoginData } from "../../app/features/loginDataSlice";
import { useDispatch } from "react-redux";
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MessageModal from "../Common/MessageModal/MessageModal";
const { Sider } = Layout;

const Sidebar = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutHandler = (): void => {
    localStorage.removeItem("provider");
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    dispatch(removeLoginData());

    toast(
      <MessageModal
        message="Successful"
        description="You are successfully logged out to your account."
        type="success"
      />,
      {
        autoClose: 1000,
      }
    );

    navigate("/");
  };

  return (
    <Sider
      breakpoint="lg"
      theme="light"
      trigger={null}
      width={"280px"}
      style={{
        overflow: "auto",
        height: "100%",
      }}
    >
      <SidebarStyle>
        <div
          style={{
            background: "white",
            height: "100%",
            paddingBottom: 16,
          }}
        >
          <NavigationMenu />
          <div className="logout-btn-container">
            <CustomButton
              bgcolor={RobinEggBlue}
              textTransform="Captilize"
              color={pureDark}
              padding="8px"
              fontFamily={`${fontFamilyRegularUber}`}
              width="100%"
              type="submit"
              title={"Logout"}
              fontSize="16px"
              clicked={logoutHandler}
            />
          </div>
        </div>
      </SidebarStyle>
    </Sider>
  );
};

export default Sidebar;

import styled from "styled-components";
import { fontFamilyMediumUber, fontFamilyRegularUber } from "../../../components/GlobalStyle";

export const OrganizerCreateAnEventBracketsStyle = styled.div`
.banner {
    width: 100%;
}
.bodyWrapper {
    width: 100%;
}
table {
    width: 100%;
    border: 1px solid #E9E9E9;
    background-color: #F6F6F6;
}
table tbody tr td {
    padding: 10px 0 10px 12px;
}
table tbody tr td:first-child {
    width: 11%;
}
table tbody tr td:nth-child(2) {
    width: 27%;
}
table tbody tr td:nth-child(3) {
    width: 6%;
}
table tbody tr td:nth-child(4) {
    width: 18%;
}
table tbody tr td:nth-child(5){
    width: 7.5%;
}
table tbody tr td:nth-child(6) {
    width: 8.5%;
}
table tbody tr td:nth-child(7) {
    width: 16%;
}
table tbody tr td:last-child {
    width: 6%;
    text-align: right;
    padding-right: 10px;
}
.belt img {
    width: 129.473px;
    height: 16px;
}
.maleGi p {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-bottom: 0;
}
title span {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
}
.title p {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.28px;
    margin-bottom: 0;
}
.editButton button {
    padding: 12px 52px 11px 53px;
    border-radius: 6px;
    background: linear-gradient(0deg, #DEF2E2 0%, #DEF2E2 100%), rgba(0, 0, 0, 0.47);
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
.dragDrop {
    margin-top: 19px;
    padding-left: 17px;
}
.cardsContainer {
    margin-top: 23px;
    padding-left: 30px;
    margin-bottom: 47px;
    display: flex;
    gap: 59px;
    align-items: center;
}
.schedule-container {
    max-width: 245px;
    width:100%;
}
.card {
    display: flex;
    // max-width:215.708px;
    max-width: 245px;
    width:100%;
    height:auto;
    justify-content: space-between;
    padding-right: 7px;
    margin-top: 10.36px;
}
.participant-img {
    width: 64.258px;
    height: 100.466px;
    border-radius: 6px 0px 0px 0px;
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
    width: 62.16px;
    height: 58.182px;
}
.participate-content .para{
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 13.63px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.444px;
}
.para{
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.818px;
}
.para-text{
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.818px;
}
.country-flag{
    display: flex;
    align-items: end;
    margin-bottom:8.63px;
}
hr {
    margin: 0;
}
.dot{
    width: 11px;
    height: 11px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #7BB037;
    right: -11px;
    top: 18px;
}
.dot_singleCard {
    width: 11px;
    height: 11px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #7BB037;
    right: -5px;
    top: 18px;
}

.dot-2{
    width: 11px;
    height: 11px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #7BB037;
    right: -5px;
    top: 75px;
}
.vs {
    width: 27px;
    height: 18px;
    flex-shrink: 0;
    border-radius: 9.45px;
    border: 0.36px solid #FFF;
    background: #1C1C1D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 48px;
    left: -7px;
}

.vs-text{
    font-family: ${fontFamilyRegularUber};
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin:2px 3.5px 2px 5.5px;
}

.singleCard {
    max-width: 245px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #e8e2e2;
    display: flex;
    align-items: center;
    paading-right: 9px;
    position: relative;
    border-radius: 6px;
    height: 58.18px;
}
.personImage {
    width: 59.091px;
    height: 58.182px;
    border-radius: 6px 0px 0px 0px;
}
.playerDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.playerName {
    display: flex;
    flex-direction: column;
    margin-left: 6.91px;
}
.playerName p {
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.818px;
    letter-spacing: -0.132px;
    margin-bottom: 0;
}
.playerName span {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.818px;
}
.countryFlag {
    position: absolute;
    right: 8px;
    bottom: 4px;
}
.countryFlag img {
    width: 20px;
    height: 14px;
}
.score span {
    padding: 2px 5px;
    border-radius: 9.45px;
    border: 0.36px solid #FFF;
    background-color: #1C1C1D;
    color: #FFF;
    text-align: center;
    font-family: ${fontFamilyMediumUber};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: absolute;
    left: -10px;
    bottom: -3px;
}
.greenDot span {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: rgb(123, 176, 55);
    position: absolute;
    top: 18px;
    right: -6px;
}
`;
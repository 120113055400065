import React, { useState } from 'react'
import { ProfileBeforeLoginTabsStyle } from "./style"
import TabNavItem from "../../components/TabNavItem/TabNavItem";
import TabContent from "../../components/TabContent/tabContent";
import AcademyProfile from '../../screens/ProfileBeforeLogin/AcademyProfile/AcademyProfile';
import ParticipantsProfileMultiAcademies from '../../screens/ProfileBeforeLogin/ParticipantsProfileMultiAcademies/ParticipantsProfileMultiAcademies';
import { ParticipantsProfileSingleAcademy } from '../../screens/ProfileBeforeLogin/ParticipantsProfileSingleAcademy/ParticipantsProfileSingleAcademy';
import AcademyUsersProfiles from '../../screens/ProfileBeforeLogin/AcademyUsersProfiles/AcademyUsersProfiles';
import AffiliationsProfileSeeAllAssociatedAcademies from '../../screens/ProfileBeforeLogin/AffiliationsProfileSeeAllAssociatedAcademies/AffiliationsProfileSeeAllAssociatedAcademies';
import { AcademyProfilePastEvent } from '../../screens/ProfileBeforeLogin/AcademyProfilePastEvent/AcademyProfilePastEvent';
import AcademyProfileUpcomingEvent from '../../screens/ProfileBeforeLogin/AcademyProfileUpcomingEvent/AcademyProfileUpcomingEvent';
import AffiliationsProfile from '../../screens/ProfileBeforeLogin/AffiliationsProfile/AffiliationsProfile';
import AffiliationsProfilePastEvent from '../../screens/ProfileBeforeLogin/AffiliationsProfilePastEvent/AffiliationsProfilePastEvent';
import AffiliationsProfileUpcomingEvent from '../../screens/ProfileBeforeLogin/AffiliationsProfileUpcomingEvent/AffiliationsProfileUpcomingEvent';
import AffiliationsProfileOverallAcademyAdults from '../../screens/ProfileBeforeLogin/AffiliationsProfileOverallAcademyAdults/AffiliationsProfileOverallAcademyAdults';

const ProfileBeforeLoginTabs = () => {
    const [activeTab, setActiveTab] = useState<string>("tab1");
    const propsToPass={
        message: "none"
    }
    return (
        <div>
            <ProfileBeforeLoginTabsStyle>

                <div className="Tabs">
                    <ul className="nav">
                        <TabNavItem
                            title="Informtion"
                            id="tab1"
                            setActiveTab={() => setActiveTab("tab1")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="Academies & Affiliations"
                            id="tab2"
                            setActiveTab={() => setActiveTab("tab2")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="Brackets"
                            id="tab3"
                            setActiveTab={() => setActiveTab("tab3")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="Results"
                            id="tab4"
                            setActiveTab={() => setActiveTab("tab4")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="Location"
                            id="tab5"
                            setActiveTab={() => setActiveTab("tab5")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="Weigh-in"
                            id="tab6"
                            setActiveTab={() => setActiveTab("tab6")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="Rules"
                            id="tab7"
                            setActiveTab={() => setActiveTab("tab7")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="Under 16's"
                            id="tab8"
                            setActiveTab={() => setActiveTab("tab8")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="Spectators"
                            id="tab9"
                            setActiveTab={() => setActiveTab("tab9")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="FAQ"
                            id="tab10"
                            setActiveTab={() => setActiveTab("tab10")}
                            activeTab={activeTab}
                        />

                        <TabNavItem
                            title="Page-11"
                            id="tab11"
                            setActiveTab={() => setActiveTab("tab11")}
                            activeTab={activeTab}
                        />
                    </ul>

                    <div className="outlet">
                        <TabContent id="tab1" activeTab={activeTab}>
                        <ParticipantsProfileMultiAcademies />
                           
                        </TabContent>

                        <TabContent id="tab2" activeTab={activeTab}>
                        <ParticipantsProfileSingleAcademy />
                        </TabContent>

                        <TabContent id="tab3" activeTab={activeTab}>
                            <AcademyProfile/>
                        </TabContent>

                        <TabContent id="tab4" activeTab={activeTab}>
                            <AcademyUsersProfiles />
                        </TabContent>

                        <TabContent id="tab5" activeTab={activeTab}>
                            <AcademyProfilePastEvent />
                        </TabContent>

                        <TabContent id="tab6" activeTab={activeTab}>
                            <AcademyProfileUpcomingEvent />
                        </TabContent>

                        <TabContent id="tab7" activeTab={activeTab}>
                            <AffiliationsProfile />
                        </TabContent>

                        <TabContent id="tab8" activeTab={activeTab}>
                            <AffiliationsProfileSeeAllAssociatedAcademies />
                        </TabContent>

                        <TabContent id="tab9" activeTab={activeTab}>
                            <AffiliationsProfilePastEvent />
                        </TabContent>

                        <TabContent id="tab10" activeTab={activeTab}>
                            <AffiliationsProfileUpcomingEvent />
                        </TabContent>

                        <TabContent id="tab11" activeTab={activeTab}>
                            <AffiliationsProfileOverallAcademyAdults />
                        </TabContent>
                    </div>
                </div>

            </ProfileBeforeLoginTabsStyle>
        </div>
    )
}

export default ProfileBeforeLoginTabs
import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../components/GlobalStyle";
export const AllMatTvModeStyle = styled.div`
.wrapper {
    width: 94%;
    margin: 0 auto;
    padding-top: 50px;
}
.headingMain h2 {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.68px;
    text-transform: capitalize;
    margin-bottom: 0;
}
.dateTime_main {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.dateTime_main {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.DateTime h6 {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.RatingPoints {
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.starImg {
    width: 95px;
    height: 15px;
}
.rating {
    color: #1C394B;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.698px;
    margin-bottom: 0;
    margin-left: 10px;
}
.rating span {
    color: #1F3340;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.698px;
    padding-left: 4px;
}
.resultSchedule_Main {
    width: 100%;
    display: flex;
    justify-content: strat;
    gap: 20px;
    margin-top: 20px;
}
.resultSchedule_MainBlock2 {
    margin-top: 38.26px !important;
    margin-bottom: 83.49px !important;
}
.resultScheduleCard_Left, .resultScheduleCard_mid,
.resultScheduleCard_right {
    width: 32.35%;
    padding-bottom: 13.27px;
    border-bottom: 1px solid #838A93;
}
.dropDowndIcon_lastCard {
    width: 14px;
    height: 7px;
}
// Shezar Styling ScheduleCard
.schedule-container{
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #E9E9E9;
    background-color: #F5F5F5;
    // max-width: 518px;
    width: 100%;
    height: 59.09px;
    flex-shrink: 0;
    padding: 0px 17.77px 0px 14.75px;
}
.DropDown-Img{
    height: 6px;
    flex-shrink: 0;
    fill: #000;
    margin-left: 7.39px;
}
.Mat1-Schedule{
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 17.729px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.699px;
    letter-spacing: 0.355px;
    margin-bottom: 0;
}
.date-time-text{
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
    margin: 2.62px 0 0 0;
}
// Shezar Styling ScheduleCardExpand
.sceduleCardsMain {
    background-color: #fff;
    padding-top: 6.89px;
}
.scheduleExpandCard-container{
    border-bottom: 0.985px solid  #E9E9E9;
    background-color: #F5F5F5;
    // width: 518px;
    height: 64px;
    padding: 0px 12.84px 0px 11.82px;
}
.scheduleExpand_Row2{
    margin-top: 9.33px !important;
}
.Mat-Schedule{
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-family: Uber Move;
    font-size: 15.76px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.699px;
    margin-bottom: 0;
}
.progress-bar{
    flex-shrink: 0;
    border: 0.985px solid #F4F4F4;
    background: #F4F4F4;
    border: 0.985px solid #F4F4F4;
}
.progress-background{
    width: 117.212px;
    height: 5.91px;
    border: 0.985px solid #F4F4F4;
    flex-shrink: 0;
    margin-top:10.53px;
}
progress::-webkit-progress-bar {
    background-color: white;
}
 progress::-webkit-progress-value {
    background-color: #7BB037;
}
.section2-ExpandCard {
    gap: 13.62px;
}
.matches p{
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 13.79px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.699px;
    letter-spacing: 0.276px;
    margin-bottom:0px;
}
.section-time p{
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 13.79px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.699px;
    letter-spacing: 0.276px;
    margin-bottom:0px;
}
.progbar-img {
    display: flex;
    justify-content: end;
    margin-top: 7.58px;
}
.progbar-img img {
    width: 99.845px;
    height: 12.339px;
}
`;
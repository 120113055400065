import React from 'react'
import { EndGameStyle } from "./style"

const EndMatchSave = ({ endGameBackHandle }: { endGameBackHandle: ()=> void }) => {

    return (
        <div>
            <EndGameStyle>
                <div className='endGameBottomInner'>
                    <div className="">
                        <table width="100%">
                            <tr>
                                {/* <td colSpan={3}>
                                    SAVE
                                </td> */}
                            </tr>
                            <tr>
                                <td onClick={endGameBackHandle}>BACK</td>
                                <td>BACK TO FIGHTORDER</td>
                                <td>BACk to Bracket</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </EndGameStyle>
        </div>
    )
}

export default EndMatchSave
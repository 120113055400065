import React from "react";
import { AffiliationsProfileOverallAcademyAdultsStyle } from "./style";
import ProfileAcademyAdultsComponent from "../../../components/ProfileAcademyAdults/ProfileAcademyAdults";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import MapFrame from "../../../assets/images/AcademyProfileMapImage-p3.png";
import IntroductionComponent from "../../../components/ProfileBeforeLoginIntroduction/ProfileBeforeLoginIntroduction";
export const AffiliationsProfileOverallAcademyAdults = () => {
  return (
    <div>
      <AffiliationsProfileOverallAcademyAdultsStyle>
        {/* <div className="banner">
          <img
            src={AcademyProfileBannerImage}
            alt="AcademyProfileBannerImage"
          />
        </div> */}
        <div className="wrapper">
          <div className="bodyLeftSideMain">
            <div className="introduction">
              <IntroductionComponent />
            </div>

            <div className="introductionParagraph">
              <h6>Introduction</h6>
              <p>
                I have been on so many different mats all over the world. That
                amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                began training in Jiu-Jitsu at just four years of age. His
                father, who was a brown belt, started his journey and took him
                to classes at João Alberto Barreto’s academy. As a teenager,
                Mauricio began training with the legendary Rolls Gracie. Under
                Rolls' guidance, he became a highly successful competitor and
                won the absolute division of the Rio de Janeiro State
                Championships in 1981. ....
              </p>
            </div>

            <div className="mapFrame">
              <img src={MapFrame} alt="" />
            </div>
          </div>
          <div className="bodyRightSideMain">
            <div className="rankingAdultsContainer">
              <div className="rankingAdultsHeading">
                <h4>Ranking – Adults</h4>
              </div>
              <div className="categoriesContainer">
                <div className="categories_AdultsKids">
                  <a href="#" className="adults active" id="adults active">
                    Adults
                  </a>
                  <a href="#" className="kids" id="kids">
                    Kids
                  </a>
                  <a href="#" className="n0_Gi" id="n0_Gi">
                    NOGI
                  </a>
                  <a href="#" className="gi" id="gi">
                    GI
                  </a>
                </div>
              </div>

              <div className="affiliationsTeamsOverAll_MainContainer">
                <div className="bestAffiliationsOverallMain">
                  {" "}
                  {/*left side */}
                  <div className="block1">
                    <div className="lastCalculated">
                      <h5>Best Affiliations / Overall</h5>
                      <span>Last calculated 3 hours ago</span>
                    </div>
                    <div className="bestAffiliationsCardsMain">
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                    </div>
                    <div className="seeAll">
                      <span>See All</span>
                    </div>
                  </div>
                  <div className="block2_toLast">
                    <div className="lastCalculated">
                      <h5>Best Teams / Overall</h5>
                      <span>Last calculated 3 hours ago</span>
                    </div>
                    <div className="bestAffiliationsCardsMain">
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                    </div>
                    <div className="seeAll">
                      <span>See All</span>
                    </div>
                  </div>
                  <div className="block2_toLast">
                    <div className="lastCalculated">
                      <h5>Best Adult / Overall</h5>
                      <span>Last calculated 3 hours ago</span>
                    </div>
                    <div className="bestAffiliationsCardsMain">
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                    </div>
                    <div className="seeAll">
                      <span>See All</span>
                    </div>
                  </div>
                  <div className="block2_toLast">
                    <div className="lastCalculated">
                      <h5>Best Adult / Gi</h5>
                      <span>Last calculated 3 hours ago</span>
                    </div>
                    <div className="bestAffiliationsCardsMain">
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                    </div>
                    <div className="seeAll">
                      <span>See All</span>
                    </div>
                  </div>
                  <div className="block2_toLast">
                    <div className="lastCalculated">
                      <h5>Best Under 18 / Gi</h5>
                      <span>Last calculated 3 hours ago</span>
                    </div>
                    <div className="bestAffiliationsCardsMain">
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                    </div>
                    <div className="seeAll">
                      <span>See All</span>
                    </div>
                  </div>
                  <div className="block2_toLast">
                    <div className="lastCalculated">
                      <h5>Male Gi / Adult / White</h5>
                      <span>Last calculated 3 hours ago</span>
                    </div>
                    <div className="bestAffiliationsCardsMain">
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                    </div>
                    <div className="seeAll">
                      <span>See All</span>
                    </div>
                  </div>
                </div>

                <div className="bestTeamsOverallMain">
                  {" "}
                  {/*right side */}
                  <div className="block1">
                    <div className="lastCalculated">
                      <h5>Best Teams / Overall</h5>
                      <span>Last calculated 3 hours ago</span>
                    </div>
                    <div className="bestAffiliationsCardsMain">
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                    </div>
                    <div className="seeAll">
                      <span>See All</span>
                    </div>
                  </div>
                  <div className="block2_toLast">
                    <div className="lastCalculated">
                      <h5>Best Teams / No Gi / Under 18</h5>
                      <span>Last calculated 3 hours ago</span>
                    </div>
                    <div className="bestAffiliationsCardsMain">
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                    </div>
                    <div className="seeAll">
                      <span>See All</span>
                    </div>
                  </div>
                  <div className="block2_toLast">
                    <div className="lastCalculated">
                      <h5>Best Under 18 / Overall</h5>
                      <span>Last calculated 3 hours ago</span>
                    </div>
                    <div className="bestAffiliationsCardsMain">
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                    </div>
                    <div className="seeAll">
                      <span>See All</span>
                    </div>
                  </div>
                  <div className="block2_toLast">
                    <div className="lastCalculated">
                      <h5>Best Adult / No Gi</h5>
                      <span>Last calculated 3 hours ago</span>
                    </div>
                    <div className="bestAffiliationsCardsMain">
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                    </div>
                    <div className="seeAll">
                      <span>See All</span>
                    </div>
                  </div>
                  <div className="block2_toLast">
                    <div className="lastCalculated">
                      <h5>Best Under 18 / No Gi</h5>
                      <span>Last calculated 3 hours ago</span>
                    </div>
                    <div className="bestAffiliationsCardsMain">
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                    </div>
                    <div className="seeAll">
                      <span>See All</span>
                    </div>
                  </div>
                  <div className="block2_toLast">
                    <div className="lastCalculated">
                      <h5>Male Gi / Adult / Blue</h5>
                      <span>Last calculated 3 hours ago</span>
                    </div>
                    <div className="bestAffiliationsCardsMain">
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                      <ProfileAcademyAdultsComponent />
                    </div>
                    <div className="seeAll">
                      <span>See All</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AffiliationsProfileOverallAcademyAdultsStyle>
    </div>
  );
};

export default AffiliationsProfileOverallAcademyAdults;

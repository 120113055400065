import React, { ChangeEvent, SetStateAction, useRef, useState, useEffect } from "react";
import { HeaderStyle, DropDownStyling } from "./style";
import NavHameBurger from "../../assets/icons/HeaderInnerPage-HameBurger.svg";
import { Input, InputRef, Button } from "antd";
import CustomButton from "../CustomButton/CustomButton";
import {
  RobinEggBlue,
} from "../GlobalStyle";
import filter from "../../assets/icons/Vector.svg";
import searchIcon from "../../assets/icons/ic_menu_search.svg";
import NavBarLogo from "../../assets/icons/NavBarLogo.svg";
import ukIcon from "../../assets/icons/ic_uk_flag.svg";
import dropDownArrow from "../../assets/icons/ic_drop_down.svg";
import { Avatar, Badge, Dropdown, MenuProps } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import profileIcon from "../../assets/icons/ic_profile_avatar.svg";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";
import CrossIcon from "../../assets/icons/ic_crossIcon1.svg";
import { toast } from "react-toastify";
import MessageModal from "../Common/MessageModal/MessageModal";

const items: MenuProps["items"] = [
  {
    key: "1",
    label: (
      <Link to={"#"} style={{ textDecoration: "none" }}>
        Profile
      </Link>
    ),
  },
  {
    key: "2",
    label: (
      <Link to={`/school/edit/`} style={{ textDecoration: "none" }}>
        Edit
      </Link>
    ),
  },
  {
    key: "3",
    label: (
      <Link
        to={`/school/add-payment-information/`}
        style={{ textDecoration: "none" }}
      >
        Payment
      </Link>
    ),
  },
];

const HeaderInnerPages = ({
  setIsOpen,
  isOpen,
}: {
  setIsOpen?: React.Dispatch<SetStateAction<boolean>>;
  isOpen?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchRef = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState("");
  const { data } = useSelector((state: RootState) => state.loginData);
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value);

  useEffect(()=>{
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) {
      const provider = params.get("provider");
      const role = params.get("role");
      provider && localStorage.setItem("provider", provider);
      role && localStorage.setItem("role", role);
      token && localStorage.setItem("token", token);

      toast(
        <MessageModal
          message="Login Successfully"
          description="You are successfully logged in to your account."
          type="success"
        />,
        {
          autoClose: 1000,
        }
      );
    } else if (!data.loggedIn) { 
      const token = localStorage.getItem("token");
      if(!token) navigate("/");
    }
  }, [data, location.search]);

  return (
    <HeaderStyle>
      <nav className="Navbar_Main">
        <div className="navBar_wrapper">
          <div className="navBarL">
            <img
              src={isOpen ? CrossIcon : NavHameBurger}
              alt="HamBurger"
              id="HamBurgerImg"
              className={isOpen ? "hamBurgerIcon" : "hamBurgerIcon"}
              {...(setIsOpen && { onClick: () => setIsOpen(!isOpen) })}
            />
            <Link to="/">
              <img
                src={NavBarLogo}
                alt="NavBarLogo"
                id="NavBarLogo"
                className="navBarLogo"
              />
            </Link>
          </div>

          <div className="navBarR">
            <div className="inputsFilterButton">
              <div className="d-flex align-items-center navFields">
                <div className="headerSearchField">
                  <Input
                    type="search"
                    ref={searchRef}
                    value={searchText || ""}
                    onChange={handleSearchChange}
                    prefix={
                      <img
                        src={searchIcon}
                        alt="search-icon"
                        width={21}
                        height={21}
                      />
                    }
                    placeholder="Search for Events"
                    className="custom-input left-bar"
                  />
                </div>
              </div>
              <div className="filterButton me-3">
                <div className="filterIcon d-flex h-50px">
                  <CustomButton
                    title=""
                    icon={
                      <img src={filter} alt="vector" width={25} height={20} />
                    }
                    type="button"
                    bgcolor={RobinEggBlue}
                    width="53px"
                    color=""
                    borderRadius="8px"
                    padding="13px 14px"
                  />
                </div>
              </div>
            </div>
            <div className="profile-area">
              <Badge
                dot
                color="green"
                className="profile-area-badge"
                style={{
                  width: "12px",
                  height: "12px",
                  top: "10px",
                }}
              >
                <Avatar
                  src={profileIcon}
                  shape="square"
                  style={{ width: "45px", height: "45px" }}
                />
              </Badge>
              {/* <h3 className="profileName">Adnan Quarshi</h3> */}
              <h3 className="profileName">{data.user.firstName}{" "}{data.user.lastName}</h3>
            </div>

            <div className="d-flex align-items-center ms-2 ">
              <div className="language-area">
                <Badge>
                  <Avatar
                    src={ukIcon}
                    shape="square"
                    style={{
                      width: "26px",
                      height: "16px",
                      borderRadius: "3px",
                    }}
                  />
                </Badge>
              </div>
              <DropDownStyling>
                <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                  <Button
                    icon={
                      <img
                        src={dropDownArrow}
                        alt="dropdown"
                        width={10}
                        height={7}
                        style={{ marginBottom: "4px" }}
                      />
                    }
                    style={{ border: "none" }}
                  />
                </Dropdown>
              </DropDownStyling>
            </div>
          </div>
        </div>
      </nav>
    </HeaderStyle>
  );
};

export default HeaderInnerPages;

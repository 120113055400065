import { Modal } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import close from '../../assets/icons/ic_close.svg'
import { CustomMessageModalStyle } from './style'
import ic_success from '../../assets/images/ic_success.svg'
import ic_error from '../../assets/icons/ic_error.svg'
type CustomMessageModalPropsTypes = {
    title: string
    description: string
    isModalVisible: boolean
    onCancel?: () => void
    setIsModalVisible: Dispatch<SetStateAction<boolean>>
    width?: string
    showCloseBtn?: boolean
    imageProp: string
}

const CustomMessageModal: React.FC<CustomMessageModalPropsTypes> = ({
    title,
    imageProp,
    description,
    isModalVisible,
    setIsModalVisible,
    onCancel,
    width = '550px',
    showCloseBtn = false,
}) => {
    const handleCancel = (): void => {
        setIsModalVisible(false)
        if (onCancel) {
            onCancel()
        }
    }

    return (
        <Modal
            open={isModalVisible}
            footer={null}
            onCancel={handleCancel}
            centered={true}
            closable={false}
            width={width}
            className="position-relative"
        >
            <CustomMessageModalStyle>
                {showCloseBtn && (
                    <img
                        className="close-icon"
                        onClick={handleCancel}
                        src={close}
                        alt="close"
                        width={25}
                        height={25}
                    />
                )}
                <div className="mainContainer">
                    <div className='mainContainer-header'>
                        {imageProp === 'error' ? (
                            <img
                                src={ic_error}
                                alt="Error Icon"
                                width={25}
                                height={25}
                            />
                        ) : (
                            <img
                                src={ic_success}
                                alt="Success Icon"
                                width={25}
                                height={25}
                            />
                        )}
                        {title && (
                            <h6 className="mainContainer-header-heading">
                                {title}
                            </h6>
                        )}
                    </div>
                    <div className='mainContainer-body'>
                        {description && (
                            <p
                                className="mainContainer-body-subText"
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                            // Use dangerouslySetInnerHTML for rendering HTML in the description
                        )}
                    </div>
                </div>
            </CustomMessageModalStyle>
        </Modal>
    )
}

export default CustomMessageModal

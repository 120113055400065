import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMediumUber, fontFamilyRegularUber } from "../../components/GlobalStyle";

export const TicketInformationStyle = styled.div`

.wrapper {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
}
.headinMain h2 {
    color: var(--Title, #061229);
    font-family: ${fontFamilyMediumUber};
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.68px;
    text-transform: capitalize;
    margin-bottom: 0;
}
.dateTime_main {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.DateTime h6 {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.RatingPoints {
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.starImg {
    width: 95px;
    height: 15px;
}
.rating {
    color: #1C394B;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.698px;
    margin-bottom: 0;
    margin-left: 10px;
}
.rating span {
    color: #1F3340;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.698px;
    padding-left: 4px;
}
.TicketInfoBanner {
    width: 100%;
    margin-top: 20px;
}
.nav {
    display: flex;
    align-items: center;
    border: 1px solid #E9E9E9;
    background: #EBEBEB;
    position: relative;
}
.nav li{
    padding: 10px 20px;
    cursor: pointer;

    color: #525A66;
    text-align: center;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.nav li.active{
    color: #18273B;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration-line: underline;
}
.ant-switch-handle{
    top: -6px !important;
}
.nav .showApproved_toggleButton {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 19px;
    float: right;
    position: absolute;
    right: 0;
}
.nav .showApproved_toggleButton button {
    width: 32px;
    background-color: #C8CCD0 !important;
    height: 7px;
}
.showApproved_toggleButton span {
    color: #232837;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.14px;
    text-transform: uppercase;
    margin-right: 28px;
    
}
.ant-switch-handle::before{
    background-color: #17374e !important;
}
`;


import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../../components/GlobalStyle";

export const AffiliationsProfileOverallAcademyResultStyle = styled.div`

.bodyWrapper_RightSide {
    width: 100%;
    margin-top: 22px;
}
.rankingOverAll_Title {
    display: flex;
    align-items: center;
    margin-left: 15px;
}
.rankingOverAll_Title p {
    color: #333;
    font-family: ${fontFamilyMedium};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.34px;
    margin-bottom: 0;
}
.rankingOverAll_Title h6 {
    color: #061229;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    padding-left: 4px;
    margin-bottom: 0;
}
.lastCalculated {
    color: #061229;
    font-family: ${fontFamilyMedium};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-left: 4px;
}
.dot {
    color: #333;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    padding-left: 3px;
}
.rankinOverallAcademyAdults {
    max-width: 293px;
    width: 100%;
    margin-top: 11px;
    margin-left: 15px;
    padding: 6px 0px 5px 8px;
    border-radius: 4px;
    border: 0.93px solid #E0E0E0;
    background-color: #FFF;
}
.rankinOverallAcademyAdults p {
    color: #333;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 129.5%;
    margin-bottom: 0;
}
.rankinOverallAcademyAdults p span {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 129.5%;
}
.academyCardsContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 12px 0 0 15px;
}
.cardsLeftSide, .cardsRightSide {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 49.06%;
    width: 100%;
}
.paginationContainer {
    margin: 12px 0 126px 14px;
}
.newWave_AcademyCard, .pointsWins_Main {
    max-width: 100% !important;
}

`;
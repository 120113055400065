import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../components/GlobalStyle";

export const ProfileAcademyAdultsStyle = styled.div`

.newWave_AcademyCard {
    max-width: 608px;
    width: 100%;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background-color: #FFF;
    display: flex;
    align-items: center;
    padding-right: 12px;
}
.LogoLefSide {
    margin: 30px 0 28px 22px;
    max-width: 11%;
    width: 100%;
}
.LogoLefSide img {
    width: 65px;
    height: 65px;
}
.rightSide {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    max-width: 89%;
    width: 100%;
}
.headingMain p {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.countryFlagName {
    display: flex;
    align-items: center;
    margin-top: 6px;
}
.countryFlagName img {
    width: 20px;
    height: 21px;
}
.countryFlagName span {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 8px;
}
.pointsWins_Main {
    max-width: 469px;
    width: 100%;
    border-radius: 5px;
    border: 0.93px solid #EFEFEF;
    background-color: #FAFAFA;
    padding: 6px 7px 5px 7px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.pointsWinsLosses {
    display: flex;
    align-items: center;
    gap: 6px;
}
.points {
    border-radius: 4px;
    background-color: rgba(0, 112, 224, 0.30);
    padding: 3px 8px 4px 8px;
}
.points p {
    color: #0070E0;
    font-family: ${fontFamilyMediumUber};
    // font-size: 16px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.points p span {
    color: #0070E0;
    font-family: ${fontFamilyRegularUber};
    // font-size: 15.556px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.wins {
    border-radius: 4px;
    background-color: rgba(123, 176, 55, 0.30);
    padding: 4px 6px 3px 7px;
}
.wins p {
    color: #5F941C;
    font-family: ${fontFamilyMediumUber};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.wins p span {
    color: #5F941C;
    font-family: ${fontFamilyRegularUber};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.losses {
    border-radius: 4px;
    background-color: rgba(194, 68, 68, 0.30);
    padding: 5px 7px 4px 7px;
}
.losses p {
    color: #C24444;
    font-family: ${fontFamilyMediumUber};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.losses p span {
    color: #C24444;
    font-family: ${fontFamilyRegularUber};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.medalsGoldSilverBronze {
    display: flex;
    align-items: center;
    gap: 6px;
}
.goldMedalsWinsCount {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #CDAF49;
    padding: 5px 7px 4.52px 7px;
}
.goldMedalsWinsCount span,
.silverMedalsWinsCount span,
.bronzeMedalsWinsCount span {
    color: #FFF;
    text-align: center;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5.61px;
}
.silverMedalsWinsCount {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #A8A9AD;
    padding: 5px 8px 4.52px 7px;
}
.silverMedalsWinsCount span {
    margin-left: 3.61px;
}
.bronzeMedalsWinsCount {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #BC6B50;
    padding: 5px 7px 4.52px 7px;
}
.bronzeMedalsWinsCount span {
    margin-left: 4.61px;
}


`;
import styled from "styled-components";
import {
  fontFamilyEnnVisionsRegular,
  fontFamilyMedium,
  fontFamilyRegular,
  mediaDeviceMax,
} from "../../../components/GlobalStyle";

export const OrganizerSettingBracketsStyle = styled.div`
  .ant-layout > .banner + .ant-layout {
    flex-direction: row;
    gap: 10px;
  }
  .title {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin: 32px 0px 15px 0;
  }
  .organizerSettingBrackets-left {
    max-width: 15%;
    width: 100%;
  }
  .form {
    padding: 21px 24px 23px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
  }
  .seedOptionTitle {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
  }
  .seedOptionTitle span {
    color: #a8a9ad;
    font-family: ${fontFamilyRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
  }
  .seedOptions {
    margin-top: 36px;
  }
  .sec2_Form {
    padding: 16px 19px 15px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-top: 15px;
  }
  .separateSeedByPara,
  .seedByRankPara {
    margin-top: 14px;
  }
  .para {
    color: #4f4f4f;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .scoringAthletes_crossBracketingMain {
    padding-top: 9px;
  }
  .leftForm,
  .rightForm {
    padding: 17px 10px 16px 15px;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    margin-top: 6px;
  }
  .scoringAthletes_Leftside,
  .scoringAthletes_RightSide {
    width: 50%;
  }
`;

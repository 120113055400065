import styled from "styled-components";
import { fontFamilyRegularUber, lightDark, lightDark2, pureDark2 } from "../../components/GlobalStyle";

export const PassEventsCardStyle = styled.div`


.passEventsCard {
    max-width: 310px;
    width: 100%;
    border-radius: 5.536px 5.536px 0px 0px;
    border: 0.923px solid #E9E9E9;
    background-color: #FFF;
}
.headingMain {
    padding: 8px 14px;
}
.headingMain p {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.239px;
    text-transform: capitalize;
    margin-bottom: 0;
}
.eventAddress p {
    color: ${lightDark};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.34px;
    text-transform: capitalize;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.odesaImage img {
    width: 100%;
}
.placedOverallTeams {
    display: flex;
    align-items: center;
    padding: 8px 14px; 
}

.placedOverallTeams h6 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
}
.place_9 {
    padding: 1.5px 7px 0.50px 8px;
    border-radius: 1.856px;
    background-color: #061229;

    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
    margin-left: 7px
}
.place9_in {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-left: 6.49px
}
.overAllTeam {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    margin-left: 4.84px
}
.overAllTeam span {
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.MedalsTableMain {
    width: 100%;
    padding-top: 10px;
}
.tableHeadings {
    display: flex;
    align-items: center;
    padding: 10px 8.48px 10px 11.25px;
    background-color: #f8f8f8;
}
.tableHeadingsLeft h6 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
}
.tableHeadingsRight, .tableDataRight {
    display: flex;
    align-items: center;
    gap: 10px;
}
.tableHeadingsRight h6 {
    color: #061229;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
}
.tableData {
    display: flex;
    align-items: center;
    padding-top: 5.45px;
    padding: 10px 14px;
    background-color: #fff;
    border-bottom: 1px solid #F2F0F0;
}
.totalMedalsCount {
    border-bottom: none !important;
}
.tableHeadingsLeft, .tableDataLeft {
    width: 58%;
}
.tableDataLeft h6 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.tableHeadingsRight, .tableDataRight {
    width: 33%;
}

.goldMedalsCount, .silverMedalsCount, .bronzeMedalsCount {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    padding: 4px;
    p {
        color: #FFF;
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18.557px;
        text-align: center;
    }
}

.goldMedalsCount {
    background-color: #CDAF49;
}
.silverMedalsCount {
    background-color: #A8A9AD;
}
.bronzeMedalsCount {
    background-color: #BC6B50;
}
.goldMedalsCount span, .silverMedalsCount span,
.bronzeMedalsCount span {
    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 14.845px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.557px;
}
.tableDataLeft h5 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.557px;
    margin-bottom: 0;
}
.tableDataRightBottom {
    display: flex;
    align-items: center;
    gap: 28px;
    p {
        color: ${pureDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18.557px;
        margin-bottom: 0;
    }
}

`;
import React, { useEffect, useState } from "react";
import Head from "../../../components/Head/Head";
import ForgetPasswordStyle from "../style";
import { Field, FieldProps, Formik } from "formik";
import * as Yup from "yup";
import { Form, Input } from "antd";
import {
  RobinEggBlue,
  fontFamilyEnnVisionsMedium,
  fontFamilyRegularUber,
  pureDark,
  pureDark2,
} from "../../../components/GlobalStyle";
import CustomButton from "../../../components/CustomButton/CustomButton";
import OtpInputsStyled from "./style";
import useVerifyOtp from "../../../hooks/useVerifyOtp";
import useScreenTranslation from "../../../hooks/useScreenTranslation";
import { useGlobalContext } from "../../../context/context";
import CustomModal from "../../../components/Modal/CustomModal";
import { toast } from "react-toastify";
export interface OtpPropValues {
  input0: string;
  input1: string;
  input2: string;
  input3: string;
  input4: string;
  input5: string;
}
interface OtpProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreatePassModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const Otp: React.FC<OtpProps> = ({
  isModalVisible,
  setIsModalVisible,
  setIsCreatePassModalVisible,
}) => {
  const initialValues: OtpPropValues = {
    input0: "",
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
  };
  const { userPhoneNumber } = useGlobalContext();

  const validationSchema = Yup.object().shape({
    input0: Yup.string()
      .matches(/^\d$/, "Enter a single digit")
      .required("Otp is required!"),
    input1: Yup.string()
      .matches(/^\d$/, "Enter a single digit")
      .required("Otp is required!"),
    input2: Yup.string()
      .matches(/^\d$/, "Enter a single digit")
      .required("Otp is required!"),
    input3: Yup.string()
      .matches(/^\d$/, "Enter a single digit")
      .required("Otp is required!"),
    input4: Yup.string()
      .matches(/^\d$/, "Enter a single digit")
      .required("Otp is required!"),
    input5: Yup.string()
      .matches(/^\d$/, "Enter a single digit")
      .required("Otp is required!"),
  });

  const initialTimer = { minutes: 10, seconds: 0 };
  const [timer, setTimer] = useState(initialTimer);

  const { handleSubmit, loading } = useVerifyOtp();
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer.seconds === 0) {
        if (timer.minutes === 0) {
          // Handle OTP expiration here
          console.log("OTP has expired");
          setTimer(initialTimer); // Reset timer to 2 minutes
        } else {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 });
        }
      } else {
        setTimer({ minutes: timer.minutes, seconds: timer.seconds - 1 });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  const handleFormSubmit = async (values: OtpPropValues) => {
    try {
      console.log(Object.values(values).join(""));
      const { message } = await handleSubmit({
        code: Object.values(values).join(""),
      });
      if (message === "approved") {
        toast("OTP validated Successfully", {
          type: "success",
          autoClose: 2000,
        });
        setIsModalVisible(false);
        setIsCreatePassModalVisible(true);
      } else {
        toast("Invalid OTP Code", {
          type: "error",
          autoClose: 2000,
        });
        setIsModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatTimer = () => {
    const { minutes, seconds } = timer;
    let timerText = "";
    if (minutes > 0) {
      timerText += `The OTP will expire in ${minutes} minute${
        minutes > 1 ? "s" : ""
      }`;
    }
    if (seconds > 0) {
      if (minutes > 0) {
        timerText += " and ";
      }
      timerText += `${seconds} second${seconds > 1 ? "s" : ""}`;
    }
    return timerText;
  };

  return (
    <>
      <Head title="Veriﬁcation One Time Pin" />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <ForgetPasswordStyle>
          <div>
            <div className="forget-password-container-card">
              <div className="forget-password-container-card-inner">
                <h6 className="title text-center">Veriﬁcation One Time Pin</h6>
                <p className="text-center forget-password-text mt-10">
                  A verification code has been sent to your mobile number.
                  Please enter the OTP below to confirm your identity.
                </p>
                {/* <h6 className="forget-password-phoneNumber text-center">{userPhoneNumber}</h6> */}
                <h6 className="forget-password-phoneNumber text-center">
                  {userPhoneNumber}{" "}
                </h6>
                <div className="forget-password-container-card-form w-100 mt-20">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                  >
                    {({ handleSubmit, errors, touched, values }) => (
                      <Form onFinish={handleSubmit}>
                        <OtpInputsStyled>
                          {[0, 1, 2, 3, 4, 5].map((index) => {
                            return (
                              <>
                                <Field name={`input${index}`} key={index}>
                                  {({ field, meta }: FieldProps<string>) => (
                                    <Input
                                      {...field}
                                      className="customInput otp-input"
                                      placeholder="-"
                                      maxLength={1}
                                      onKeyUp={(e) => {
                                        if (e.key.match(/^\d$/)) {
                                          const nextIndex =
                                            index < 5 ? index + 1 : index;
                                          if (
                                            (values as any)[
                                              `input${nextIndex}`
                                            ] === ""
                                          ) {
                                            const nextField =
                                              document.getElementsByName(
                                                `input${nextIndex}`
                                              )[0];
                                            nextField?.focus();
                                          }
                                        }
                                      }}
                                    />
                                  )}
                                </Field>
                              </>
                            );
                          })}
                        </OtpInputsStyled>

                        <div className="mt-20">
                          <CustomButton
                            bgcolor={RobinEggBlue}
                            textTransform="uppercase"
                            color={pureDark2}
                            padding="12px"
                            fontFamily={`${fontFamilyRegularUber}`}
                            width="100%"
                            type="submit"
                            title="Submit"
                            fontSize="14px"
                            loading={loading}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <p className="text-center forget-password-OTPtext">
              {formatTimer()}
            </p>
          </div>
        </ForgetPasswordStyle>
      </CustomModal>
    </>
  );
};

export default Otp;

import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium } from "../../../components/GlobalStyle";

export const OrganizerCreateAnEventEventTemplateStyle = styled.div`
.bodywrapper_RightSide {
    width: 100%;
    padding-left: 35px;
}
.title {
    margin-top: 26px;
}
.title h4, .entriesTableTitle h4{
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0
}
.eventTemplateMain {
    width: 100%;
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 27px;
}
.template, .duplicteAlready {
    width: 50%;
}
.template span, .duplicteAlready span {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.duplicteAlready span {
    padding-left: 2px;
}
select {
    width: 100%;
    padding: 14px 15.26px 12px 11px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    background-color: #FFF;
    margin-top: 7px;
}
.entriesTableTitle {
    margin-top: 31px;
}
.entriesTableContainer {
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    background-color: #FFF;
    padding-bottom: 121px;
    margin-bottom: 88px;
    margin-top: 21px;
}
table {
    width: 100%;
}
table thead tr th {
    padding: 17px 0 24px 10px;
    color: #061229;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
table tbody tr td {
    padding: 11px 0px 16.1px 10px;
}
table thead tr th:first-child,
table tbody tr td:first-child {
    padding-left: 20px;
    width: 10%;
}
table thead tr th:nth-child(2),
table tbody tr td:nth-child(2) {
    width: 17%;
}
table thead tr th:nth-child(3),
table tbody tr td:nth-child(3) {
    width: 12%;
}
table thead tr th:nth-child(4),
table tbody tr td:nth-child(4),
table thead tr th:nth-child(5),
table tbody tr td:nth-child(5)  {
    width: 12%;
}
table thead tr th:nth-child(6),
table tbody tr td:nth-child(6) {
    width: 12%;
}
table thead tr th:nth-child(7),
table tbody tr td:nth-child(7) {
    width: 16%;
}
table thead tr th:last-child,
table tbody tr td:last-child {
    padding-right: 13px;
    width: 9%;
}
table tbody tr td:last-child {
    text-align: center;
}
table tbody tr:nth-child(odd) {
    background-color: #FAFAFA;
}
table tbody tr:nth-child(even) {
    background-color: #FFF;
}
.id, .entriesName {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.age, .earlyBird, .normal, .lateFee {
    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}
.status {
    position: relative;
}
.status span {
    padding: 4px 26px 3px 26px;
    border-radius: 4px;
    background-color: #4CAF50;
    color: #FFF;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align-center;
}
.status img {
    position: absolute;
    top: 5px;
    left: 88px;
}
.actions img {
    cursor: pointer;
}
`;

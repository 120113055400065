import React from 'react'
import {YourRegistrationsStyle} from "./style";
import ProgressBar from "../../../assets/icons/BlackRed-ProgressBar.svg";

export const YourRegistrations = () => {
  return (
    <div> 
        <YourRegistrationsStyle>
        <div className="cardContainer">
          <div className="titleDate">
            <h5>BJJ247 Brighton Autumn Open</h5>
            <span>Nov 19, 2023</span>
          </div>

          <div className="innerCard">
            <div className="innerCardTitle">
              <h5>Male Gi / Black / Adult / -88,3 kg (Medium heavy)</h5>
            </div>
            <div className="beltAge_WeightMain mt-10">
              <div className="beltAge">
                <div className="belt">
                  <p>Belt :</p>
                  <img src={ProgressBar} alt="ProgressBar" />
                </div>
                <div className="age">
                  <p>Age :</p>
                  <span>Adult</span>
                </div>
              </div>
              <div className="weight">
                <p>Weight :</p>
                <span>-88,3 kg (Medium Heavy)</span>
              </div>
            </div>
            <div className="lastChance_EventsButton mt-10">
              <div className="lastChanceToEdit">
                <p>Last chance to edit: <span>Nov 14, 2023 - 8:00 pm</span></p>
              </div>
              <div className="GoTo_EventsButton">
                <button className="GoToEventsButton">Go to Events</button>
                <button className="cancelButton">Cancel</button>
              </div>
            </div>
          </div>
        </div>
        </YourRegistrationsStyle>
    </div>
  )
}

export default YourRegistrations;
import React from "react";
import { BuyTicketsSelectUserStyle } from "./style";
import ProfileImage from "../../../assets/images/SelectProfileIMage.png";
import EngFlag from "../../../assets/images/EnglandFlag.svg";
import StatusSvg from "../../../assets/icons/Status-ThreeDotSVG.svg";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { Progress } from "flowbite-react";

const BuyTicketsSelectUser = () => {
  return (
    <div>
      <BuyTicketsSelectUserStyle>
      <div className="banner">
          <img
            src={AcademyProfileBannerImage}
            alt="AcademyProfileBannerImage"
          />
        </div>
        <div className="bodyWrapper">
          <div className="mainContainer">
            <div className="title">
              <h4>Select Profile</h4>
            </div>
            <div className="tableContainer">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Academy</th>
                    <th>Affiliations</th>
                    <th>Nationality</th>
                    <th>Belt</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="profileImaage">
                      <img src={ProfileImage} alt="ProfileImage" />
                    </td>
                    <td className="name">
                      <span>Adnan Ahsan Qureshi</span>
                    </td>
                    <td className="academy">
                      <span>EnnVision Martial</span>
                    </td>
                    <td className="affiliations">
                      <span>Fista Academy</span>
                    </td>
                    <td className="nationality">
                      <img src={EngFlag} alt="EngFlag" />
                      <span>ENG</span>
                    </td>
                    <td className="belt">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </td>
                    <td className="status">
                      <button className="activeButton">Active</button>
                    </td>
                    <td className="threeDotSvg">
                      <img src={StatusSvg} alt="StatusSvg" />
                    </td>
                  </tr>

                  <tr>
                    <td className="profileImaage">
                      <img src={ProfileImage} alt="ProfileImage" />
                    </td>
                    <td className="name">
                      <span>Adnan Ahsan Qureshi</span>
                    </td>
                    <td className="academy">
                      <span>EnnVision Martial</span>
                    </td>
                    <td className="affiliations">
                      <span>Fista Academy</span>
                    </td>
                    <td className="nationality">
                      <img src={EngFlag} alt="EngFlag" />
                      <span>ENG</span>
                    </td>
                    <td className="belt">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </td>
                    <td className="status">
                      <button className="activeButton">Active</button>
                    </td>
                    <td className="threeDotSvg">
                      <img src={StatusSvg} alt="StatusSvg" />
                    </td>
                  </tr>

                  <tr>
                    <td className="profileImaage">
                      <img src={ProfileImage} alt="ProfileImage" />
                    </td>
                    <td className="name">
                      <span>Adnan Ahsan Qureshi</span>
                    </td>
                    <td className="academy">
                      <span>EnnVision Martial</span>
                    </td>
                    <td className="affiliations">
                      <span>Fista Academy</span>
                    </td>
                    <td className="nationality">
                      <img src={EngFlag} alt="EngFlag" />
                      <span>ENG</span>
                    </td>
                    <td className="belt">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </td>
                    <td className="status">
                      <button className="activeButton">Active</button>
                    </td>
                    <td className="threeDotSvg">
                      <img src={StatusSvg} alt="StatusSvg" />
                    </td>
                  </tr>

                  <tr>
                    <td className="profileImaage">
                      <img src={ProfileImage} alt="ProfileImage" />
                    </td>
                    <td className="name">
                      <span>Adnan Ahsan Qureshi</span>
                    </td>
                    <td className="academy">
                      <span>EnnVision Martial</span>
                    </td>
                    <td className="affiliations">
                      <span>Fista Academy</span>
                    </td>
                    <td className="nationality">
                      <img src={EngFlag} alt="EngFlag" />
                      <span>ENG</span>
                    </td>
                    <td className="belt">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>                   
                    </td>
                    <td className="status">
                      <button className="activeButton">Active</button>
                    </td>
                    <td className="threeDotSvg">
                      <img src={StatusSvg} alt="StatusSvg" />
                    </td>
                  </tr>

                  <tr>
                    <td className="profileImaage">
                      <img src={ProfileImage} alt="ProfileImage" />
                    </td>
                    <td className="name">
                      <span>Adnan Ahsan Qureshi</span>
                    </td>
                    <td className="academy">
                      <span>EnnVision Martial</span>
                    </td>
                    <td className="affiliations">
                      <span>Fista Academy</span>
                    </td>
                    <td className="nationality">
                      <img src={EngFlag} alt="EngFlag" />
                      <span>ENG</span>
                    </td>
                    <td className="belt">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>                    
                    </td>
                    <td className="status">
                      <button className="activeButton">Active</button>
                    </td>
                    <td className="threeDotSvg">
                      <img src={StatusSvg} alt="StatusSvg" />
                    </td>
                  </tr>

                  <tr>
                    <td className="profileImaage">
                      <img src={ProfileImage} alt="ProfileImage" />
                    </td>
                    <td className="name">
                      <span>Adnan Ahsan Qureshi</span>
                    </td>
                    <td className="academy">
                      <span>EnnVision Martial</span>
                    </td>
                    <td className="affiliations">
                      <span>Fista Academy</span>
                    </td>
                    <td className="nationality">
                      <img src={EngFlag} alt="EngFlag" />
                      <span>ENG</span>
                    </td>
                    <td className="belt">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </td>
                    <td className="status">
                      <button className="activeButton">Active</button>
                    </td>
                    <td className="threeDotSvg">
                      <img src={StatusSvg} alt="StatusSvg" />
                    </td>
                  </tr>

                  <tr>
                    <td className="profileImaage">
                      <img src={ProfileImage} alt="ProfileImage" />
                    </td>
                    <td className="name">
                      <span>Adnan Ahsan Qureshi</span>
                    </td>
                    <td className="academy">
                      <span>EnnVision Martial</span>
                    </td>
                    <td className="affiliations">
                      <span>Fista Academy</span>
                    </td>
                    <td className="nationality">
                      <img src={EngFlag} alt="EngFlag" />
                      <span>ENG</span>
                    </td>
                    <td className="belt">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </td>
                    <td className="status">
                      <button className="activeButton">Active</button>
                    </td>
                    <td className="threeDotSvg">
                      <img src={StatusSvg} alt="StatusSvg" />
                    </td>
                  </tr>

                  <tr>
                    <td className="profileImaage">
                      <img src={ProfileImage} alt="ProfileImage" />
                    </td>
                    <td className="name">
                      <span>Adnan Ahsan Qureshi</span>
                    </td>
                    <td className="academy">
                      <span>EnnVision Martial</span>
                    </td>
                    <td className="affiliations">
                      <span>Fista Academy</span>
                    </td>
                    <td className="nationality">
                      <img src={EngFlag} alt="EngFlag" />
                      <span>ENG</span>
                    </td>
                    <td className="belt">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </td>
                    <td className="status">
                      <button className="activeButton">Active</button>
                    </td>
                    <td className="threeDotSvg">
                      <img src={StatusSvg} alt="StatusSvg" />
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </BuyTicketsSelectUserStyle>
    </div>
  );
};

export default BuyTicketsSelectUser;

import React from "react";
import { CoachProfileEventRegistrationEventsStyle } from "./style";
import UncomingEventsCards from "../../AjpScoreBoard/TicketListing/TicketListing";
import PaginationComponent from "../../../components/ProfilesBeforeLoginPagination/ProfilesBeforeLoginPagination";
import Card from "../../../components/EventRegistrationCard/EventRegistrationCard"
export const CoachProfileEventRegistrationEvents = () => {
  return (
    <div>
      <CoachProfileEventRegistrationEventsStyle>
        <div className="bodyWrapper_RightSide">
          <div className="title">
            <h4>Upcoming Event Registrations</h4>
            <span>Sell All</span>
          </div>
          <div className="eventsCardsContainer">
            <UncomingEventsCards />
            <UncomingEventsCards />
            <UncomingEventsCards />
            <UncomingEventsCards />
            <UncomingEventsCards />
            <UncomingEventsCards />
          </div>
          <div className="pagination_Container">
            <PaginationComponent />
          </div>
        </div>
      </CoachProfileEventRegistrationEventsStyle>
    </div>
  );
};

export default CoachProfileEventRegistrationEvents;

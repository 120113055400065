import ParticipantCard from '../../../components/ParticipantCard/ParticipantCard'
import { StudentProfileStyle } from './style'
import PaginationComponent from "../../../components/ProfilesBeforeLoginPagination/ProfilesBeforeLoginPagination"
import CustomButton from '../../../components/CustomButton/CustomButton'
import { Black, fontFamilyRegularUber } from '../../../components/GlobalStyle'
import { useNavigate } from 'react-router-dom'


const StudentsAllCards = () => {
    const navigate = useNavigate()

    
    return (
        <StudentProfileStyle>
            <div className='wrapper'>
                <div className='studentCardsMain'>
                    <div className='header mt-20'>
                        <h4>Students</h4>
                        <div className="header-buttons">
                            <CustomButton
                                bgcolor="#A3B18A"
                                textTransform="uppercase"
                                color={Black}
                                padding="8px 20.50px"
                                fontFamily={fontFamilyRegularUber}
                                width="fit-content"
                                type="submit"
                                title="Affiliate"
                                fontSize="14px"
                                loading={false}
                                clicked={() => {
                                    navigate(`/academy/my-profile/students/affiliation`)
                                }}
                            />
                            <CustomButton
                                bgcolor="#CADFCE"
                                textTransform="uppercase"
                                color={Black}
                                padding="8px 20.50px"
                                fontFamily={fontFamilyRegularUber}
                                width="fit-content"
                                type="submit"
                                title="Invite"
                                fontSize="14px"
                                loading={false}
                            />
                        </div>
                    </div>
                    <div className='studentCards mt-10'>
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                        <ParticipantCard />
                    </div>
                </div>
                <div className='paginationContainer mt-20'>
                    <PaginationComponent />
                </div>
            </div>
        </StudentProfileStyle>
    )
}

export default StudentsAllCards;
import styled from "styled-components";
import { fontFamilyRegularUber, lightDark2, pureDark2 } from "../GlobalStyle";

export const AfterLoginPagesLeftSideStyle = styled.div`

.leftSideMain {
    width: 100%;
    margin-bottom: 34px;
}
.introductionParagraph {
    max-width: 359px;
    width: 100%;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding: 14px 12px 10px 17px;
    margin-top: 17px;
}
.introductionParagraph h6 {
    color: ${pureDark2};
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.introductionParagraph p {
    color: ${lightDark2};
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 5px;
    margin-bottom: 0;
}
.card1 {
    max-width: 359px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1.087px solid #F0F0F0;
    margin-top: 17px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.cardLogoLeftSide {
    display: flex;
    flex-direction: column;
} 
.cardLogoLeftSide p {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.217px;
    letter-spacing: 0.32px;
    margin-bottom: 0;
    padding: 14px 0 0px 17px;
} 
.avatar1 {
    width: 81.766px;
    height: 81.766px;
    object-fit: cover;
    border-radius: 44.387px;
    margin: 9.18px 0 12.06px 10.51px;
}
.info1 {
    margin-left: 11.68px;
    display: flex;
    flex-direction: column;
    padding: 9.34px 17px 10px 0;
}  
.name1 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.activity1 {
    letter-spacing: -.5px;
    margin: 0;
    padding-top: 9.2px;
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}
.blocks {
    display: flex;
    align-items: center;
    padding-top: 5.45px;
    gap: 8px;
}

.block1, .block2, .block3 {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    padding: 2px;
}

.block1 {

    background-color: #CDAF49;
}
.block2 {
    background-color: #A8A9AD;
}
.block3 {
    background-color: #BC6B50;
}
.block1 p, .block2 p, .block3 p {
    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.362px;
    text-align: center;
}
.mapFrame {
    max-width: 357px;
    width: 100%;
    margin-top: 17px;
}
.mapFrame img {
    border-radius: 0px 0px 9px 9px;
    border: 0px solid #E0E0E0;
}

.affiliationsMain {
    max-width: 354.91px;
    width: 100%;
    margin-top: 19.07px;
}

.sectionHeadingsMain {
    padding: 0px 0 0 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sectionHeadingsMain h4 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.sectionHeadingsMain {
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.217px;
    letter-spacing: 0.28px;
    span {
        cursor: pointer;
    }
}

`;
import React, { useEffect, useState } from "react";
import { SubUltmStyle } from "./style";
import PauseBtn from "../../../assets/icons/ic_pauseBtn.svg";
import PauseIcon from "../../../assets/icons/ic_pause_ScoreBoard.svg";
import SwapArrowUp from "../../../assets/icons/is_ArrowUpScoreBoard.svg";
import SwapArrowDown from "../../../assets/icons/ic_ArrowDownScoreBoard.svg";
import ingTimeBoardLeft from "../../../assets/icons/ic_ringTimeBoardLeft.svg";
import ingTimeBoardRight from "../../../assets/icons/ic_ringTimeBoardRight.svg";
import PlayBtn from "../../../assets/icons/ic_playBtn.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  clearMedicalTimer,
  clearStallingTimer,
  clearTimeoutTimer,
  handleMatchTimer,
  handleMedicalTimer,
  handleStallingTimer,
  handleTimeoutTimer,
  handleUpdateMatchTimer,
  showMedicalModalHandler,
  showStallingModalHandler,
  showTimeoutModalHandler,
  swapMedicalTimerHandler,
  swapStallingTimerHandler,
  swapTimeoutTimerHandler,
  updateEndGameMessage,
  updateEndGameMessageB,
  updateEndGameMessageType,
  updateMatchTimer,
  updateMedicalTimerHandler,
  updatePlayer,
  updateScoreBoard,
  updateScoreHistory,
  updateStallingTimerHandler,
  updateTimeoutTimerHandler,
} from "../../../app/reducer/scoreBoard/SubUltmSlice";
import { useAppDispatch } from "../../../app/hooks";

import Minus30Icon from "../../../assets/icons/scoreBoardIcons/minus30.svg";
import Minus1Icon from "../../../assets/icons/scoreBoardIcons/minus1.svg";
import PlayIcon from "../../../assets/icons/scoreBoardIcons/playIcon.svg";
import Plus1Icon from "../../../assets/icons/scoreBoardIcons/plus1.svg";
import Plus30Icon from "../../../assets/icons/scoreBoardIcons/plus30.svg";
import DoubleWoTraditional from "../../../components/ScoreBoardComponents/DoubleWo/DoubleWoTraditional";
import EndMatchScoreBoardTrad from "../../../components/EndMatchScoreBoardTrad/EndMatchScoreBoardTrad";
import EndMatchSave from "../../../components/ScoreBoardComponents/EndMatch/EndMatch";
import DoubleShowTraditional from "../../../components/ScoreBoardComponents/DoubleShow/DoubleShowTraditional";
import ScoreBoardTable from "../../../components/ScoreBoardComponents/ScoreBoardTable/ScoreBoardTable";
import { showVarModalHandler } from "../../../app/reducer/scoreBoard/SubUltmSlice";
import WonByUltm from "../../../components/ScoreBoardComponents/WonBy/WonByUltm";

interface ScoreHistoryProps {
  playerId: number;
  score?: number;
  penalty?: number;
  advantage?: number;
}
const SubUltm: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    teamOneScoreBoard,
    teamTwoScoreBoard,
    scoreHistory,
    playerOne,
    playerTwo,
    matchTimer,
    endGameMessage,
    endGameMessageType,
    endGameMessageB,
  } = useSelector((state: RootState) => state.SubUltm);

  // Section 1 and 2 functionality states

  const [showScoreBoardTableOne, setShowScoreBoardTableOne] = useState(true);
  const [showScoreBoardTableTwo, setShowScoreBoardTableTwo] = useState(true);

  // Section 3 functionality states
  const [showEndGameModal, setShowEndGameModal] = useState(false);
  const [endGame, setEndGame] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const [isShowTimerActions, setIsShowTimerACtions] = useState(false);

  const handlePropagation = (event: any) => {
    event.stopPropagation();
  };

  const formatTime = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const remainingSeconds = timer % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return ` ${formattedMinutes}:${formattedSeconds} `;
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data === "goFullscreen") {
        document.documentElement.requestFullscreen({ navigationUI: "hide" });
      }
    });
  }, []);

  return (
    <SubUltmStyle>
      <div id="tradScoreboard">
        <div id="tradScoreboardPlayers">
          {/* <div className="messagesBody">
            <div className="messagesShows">
              <div className={`result submission `}>
                <span className="winner-text">Won by Winner</span>
              </div>
            </div>
            <div className="playerDetails trad-player">
              <div className="trad-player-points">
                <div>
                  <div className="player-attr">
                    <div className="attr-rows ">
                      <div className="trad-header ">
                        <div className="player-name ">
                          <span className="name truncate">
                            <span className="firstname">Jennine</span>{" "}
                            <span className="lastname">Berry</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="player playerContainer playerLeft   ">
            <div className="scoresContainer">
              <div className="mainScore">
                <div className="count digits-1"> </div>
              </div>
            </div>
            <div className="playerControls">
              <div>
                <div className="playerAttributes truncate">
                  <div className="attributeRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <img
                          src={playerOne.countryFlag}
                          className="flag"
                        />
                        <span className="name truncate">
                          <span className="firstname">{playerOne.playerFirstName}{" "}</span>
                          <span className="lastname">{playerOne.playerLastName}</span>
                        </span>
                      </div>
                    </div>
                    <div className="subheader">
                      <span className="countryName">{playerOne.countryName}</span>
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate">
                          <div className="clubName truncate">
                            <img
                              src={playerOne.clubFlag}
                              className="clubIcon imgCircle"
                            />
                            <span>{playerOne.clubName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`score2`}>
              {teamOneScoreBoard.isMedicalModal && (
                <div className={`sec1 medical`}>
                  <h2>
                    {formatTime(teamOneScoreBoard.medicalValue as number)}
                  </h2>
                  <p>Medical</p>
                </div>
              )}
              {teamOneScoreBoard.isStallingModal && (
                <div className={`sec1 stalling`}>
                  <div>
                    <h2>
                      {formatTime(teamOneScoreBoard.stallingValue as number)}
                    </h2>
                    <p>Stalling</p>
                  </div>
                </div>
              )}
              {teamOneScoreBoard.isTimeoutModal && (
                <div className={`sec1 timeout`}>
                  <h2>
                    {formatTime(teamOneScoreBoard.timeoutValue as number)}
                  </h2>
                  <p>TIMEOUT</p>
                </div>
              )}
              {teamOneScoreBoard.isVarModal && (
                <div className={`sec1 varAssistant`}>
                  <h2>VAR</h2>
                  <p>MODE ON</p>
                </div>
              )}
            </div>
          </div>
          <div className="player playerContainer playerRight">
            <div className="playerControls">
              <div>
                <div className="playerAttributes ">
                  <div className="attributeRows truncate">
                    <div className="header truncate">
                      <div className="playerName truncate">
                        <span className="name truncate">
                          <span className="firstname">
                            {playerTwo.playerFirstName}
                          </span>{" "}
                          <span className="lastname">
                            {playerTwo.playerLastName}
                          </span>
                        </span>
                        <img src={playerTwo.countryFlag} className="flag" />
                      </div>
                    </div>
                    <div className="subheader truncate">
                      <span className="countryName">
                        {playerTwo.countryName}
                      </span>
                      <div className="clubAndTeam truncate">
                        <div className="clubAndTeamContainer truncate">
                          <div
                            className="truncate clubFlag"
                            style={{ width: "auto !important" }}
                          >
                            <img
                              src={playerTwo.clubFlag}
                              className="clubIcon imgCircle"
                            />
                          </div>
                          <div className="clubName truncate">
                            <span>{playerTwo.clubName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`score2`}>
              {teamTwoScoreBoard.isMedicalModal && (
                <div className={`sec1 medical swapArrow`}>
                  <h2>
                    {formatTime(teamTwoScoreBoard.medicalValue as number)}
                  </h2>
                  <p>Medical</p>
                </div>
              )}
              {teamTwoScoreBoard.isStallingModal && (
                <div className={`sec1 stalling swapArrow`}>
                  <div>
                    <h2>
                      {formatTime(teamTwoScoreBoard.stallingValue as number)}
                    </h2>
                    <p>Stalling</p>
                  </div>
                </div>
              )}
              {teamTwoScoreBoard.isTimeoutModal && (
                <div className={`sec1 timeout swapArrow`}>
                  <h2>
                    {formatTime(teamTwoScoreBoard.timeoutValue as number)}
                  </h2>
                  <p>TIMEOUT</p>
                </div>
              )}
              {teamTwoScoreBoard.isVarModal && (
                <div className={`sec1 varAssistant`}>
                  <h2>VAR</h2>
                  <p>MODE ON</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="tradScoreboardBottom">
          <div style={{ flexGrow: "1", width: "0px", zIndex: "2" }}>
            <div className="matchInfoContainer">
              <div className="matchNumber">
                <span>1-1</span>
              </div>
              <div className="matchInfo">
                <div className="matchInfoText">
                  <div className="bracketDivision">
                    <div className="scMarquee">
                      <div className="marqueeContainer animate">
                        <span className="marqueeText">
                          Boys Gi / Mighty Mite I (4 yrs) / -17,90 kg / Grey
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bracketRound">
                    <div className="scMarquee primary">
                      <div className="marqueeContainer">
                        <span className="marqueeText">Semifinals</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="clock">
              <div className="time running">
                <div
                  className="count "
                  style={{
                    color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                  }}
                >
                  <p
                    style={{
                      color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                    }}
                  >
                    {formatTime(matchTimer.matchTimerValue)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SubUltmStyle>
  );
};

export default SubUltm;

import React from "react";
import { PassEventsCardStyle } from "./style";
import AcadmeyProfileImage from "../../assets/images/acadmeyProfile.svg";

export const PassEventsCard = () => {
  return (
    <div>
      <PassEventsCardStyle>
        <div className="passEventsCard">
          <div className="headingMain">
            <p>
              San Jose International Open Jiu Jitsu Gi
            </p>
            <div className="eventAddress">
              <p>2641 Abdulrahman Ibn Tahir, Ghirnatah, 7863, Riyadh 13242</p>
            </div>
          </div>
          <div className="odesaImage">
            <img src={AcadmeyProfileImage} alt="AcadmeyProfileImage" />
          </div>
          <div className="placedOverallTeams">
            <h6>Placed</h6>
            <p className="place_9">#9</p>
            <span className="place9_in">in</span>
            <p className="overAllTeam">
              Overall Team<span> - Event top list</span>
            </p>
          </div>
          <div className="placedOverallTeams placedOverallTeamsBlock2">
            <h6>Placed</h6>
            <p className="place_9">#9</p>
            <span className="place9_in">in</span>
            <p className="overAllTeam">
              Overall Team<span> - Event top list</span>
            </p>
          </div>

          <div className="MedalsTableMain">
            <div className="tableHeadings">
              <div className="tableHeadingsLeft">
                <h6>Medals</h6>
              </div>
              <div className="tableHeadingsRight">
                <h6 className="goldMedals">Gold</h6>
                <h6 className="silverMedals">Silver</h6>
                <h6>Bronze</h6>
              </div>
            </div>

            <div className="tableData">
              <div className="tableDataLeft">
                <h6>Male Gi</h6>
              </div>
              <div className="tableDataRight">
                <div className="goldMedalsCount">
                  <p>1</p>
                </div>
                <div className="silverMedalsCount">
                  <p>1</p>
                </div>
                <div className="bronzeMedalsCount">
                  <p>12</p>
                </div>
              </div>
            </div>

            <div className="tableData">
              <div className="tableDataLeft">
                <h6>Male Gi</h6>
              </div>

              <div className="tableDataRight">
                <div className="goldMedalsCount">
                  <p>3</p>
                </div>
              </div>
            </div>
            <div className="tableData totalMedalsCount">
              <div className="tableDataLeft">
                <h5>Total</h5>
              </div>
              <div className="tableDataRightBottom">
                <p>4</p>
                <p>1</p>
                <p>12</p>
              </div>
            </div>
          </div>
        </div>
      </PassEventsCardStyle>
    </div>
  );
};
export default PassEventsCard;

import { Menu, MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/icons/ic_logo_ULTM8.svg";
// import { SidebarStyle } from "../Sidebar/style";
import { NavigationMenuStyled } from "./styles";
import { useEffect, useState } from "react";
import { childListOfTickets } from "../Sidebar/constants";
type MenuItem = Required<MenuProps>["items"][number];


const menuLinks: any = {
  dashboard: "",
  organizer: "",
  academy: "/academy/list",
  affiliation: "/affiliate/list",
  branch: "/branch/list",
  coach: "/coach/list",
  athlete: "",
  tickets: "",
  myAccount: "",
};

const menuLinksKeys: any = {
  dashboard: "dashboard",
  academy: "academy",
  organizer: "organizer",
  affiliation: "affiliation",
  branch: "branch",
  coach: "coach",
  athlete: "athlete",
  tickets: "tickets",
  myAccount: "myAccount",
  
};



const NavigationMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();


  const navigation = (link: string, key: string) => {
    navigate(link);
  };

  const getLabel = (label: string, link: string, key: string) => (
    <div onClick={() => (link ? navigation(link, key) : "")}>{label}</div>
  );

  const sidebarData: MenuItem[] = [
    {
      key: menuLinksKeys.dashboard,
      label: getLabel(
        "Dashboard",
      menuLinks.dashboard,
      menuLinksKeys.dashboard,
      )
    },
    {
      key: menuLinksKeys.organizer,
      label: getLabel(
        "Organizer",
      menuLinks.organizer,
      menuLinksKeys.organizer,
      )
    },
    {
      key: menuLinksKeys.academy,
      label: getLabel(
        "Academy",
      menuLinks.academy,
      menuLinksKeys.academy,
      )
    },
    // {
    //   key: menuLinksKeys.affiliation,
    //   label: getLabel(
    //     "Affiliation",
    //   menuLinks.affiliation,
    //   menuLinksKeys.affiliation,
    //   )
    // },
    // {
    //   key: menuLinksKeys.branch,
    //   label: getLabel(
    //     "Branch",
    //   menuLinks.branch,
    //   menuLinksKeys.branch,
    //   )
    // },
    {
      key: menuLinksKeys.coach,
      label: getLabel(
        "Coach",
      menuLinks.coach,
      menuLinksKeys.coach,
      )
    },
    {
      key: menuLinksKeys.athlete,
      label: getLabel(
        "Athlete",
      menuLinks.athlete,
      menuLinksKeys.athlete,
      )
    },
    {
      key: menuLinksKeys.tickets,
      label: getLabel(
        "Tickets",
      menuLinks.tickets,
      menuLinksKeys.tickets,
      ),
      children: childListOfTickets,
    },
    {
      key: menuLinksKeys.myAccount,
      label: getLabel(
        "My Account",
      menuLinks.myAccount,
      menuLinksKeys.myAccount,
      )
    },
    {
      key: menuLinksKeys.supportCenter,
      label: getLabel(
        "Support Center",
      menuLinks.supportCenter,
      menuLinksKeys.supportCenter,
      )
    },
    {
      key: menuLinksKeys.supportCenter,
      label: getLabel(
        "Terms of Services",
      menuLinks.supportCenter,
      menuLinksKeys.supportCenter,
      )
    },
    {
      key: menuLinksKeys.supportCenter,
      label: getLabel(
        "Privacy Policy",
      menuLinks.supportCenter,
      menuLinksKeys.supportCenter,
      )
    },
    
    
  ];

  const [selectedKey, setSelectedKey] = useState("");

  useEffect(() => {
    if (location.pathname !== "/") {
      let tempSidebar = [...sidebarData];
      let listWithNoDashboard = tempSidebar.filter(
        (item: any) => item.key !== "dashboard"
      );
      const key =
        listWithNoDashboard.find((item: any) => {
          return location.pathname.startsWith(menuLinks[item.key]);
        })?.key || "";
      setSelectedKey(key.toString());
    }
  }, [location.pathname]);
  return (
    <NavigationMenuStyled>
      <Menu
        defaultSelectedKeys={[selectedKey]}
        mode="inline"
        items={sidebarData}
      />
    </NavigationMenuStyled>
  );
};

export default NavigationMenu;

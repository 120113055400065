import styled from "styled-components";
import { fontFamilyRegularUber, pureDark2 } from "../../../components/GlobalStyle";



export const AcademyProfileUpcomingMatchesStyle = styled.div`
    .mainWrapper{
        margin-top: 20px;
        .title{
            color: ${pureDark2};
            font-size: 16px;
            font-family: ${fontFamilyRegularUber};
            font-weight: 400;
        }
        .
    }

`
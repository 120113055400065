import React from 'react'
import { ModelScoreBoardMedicalStyle } from "./style"
import ModelCrossIcon from "../../assets/icons/ic_crossBtnModel.svg"
import WarningIcon from "../../assets/icons/ic_Warning.svg"

type ModelScoreBoardMedicalProps = {
    closeMedicalModalHandler: () => void
}

const ModelScoreBoardMedical: React.FC<ModelScoreBoardMedicalProps> = ({
    closeMedicalModalHandler
}) => {
    return (
        <div>
            <ModelScoreBoardMedicalStyle>
                <div className='overlay'></div>
                <div className='modelScoreBoard'>
                    <span className='crossBtns' onClick={closeMedicalModalHandler}>
                        <img src={ModelCrossIcon} alt="ModelCrossIcon" />
                    </span>
                    <div className='modelConetnt'>
                        <div className="swal2-icon swal2-question">
                            <img src={WarningIcon} alt="" />
                        </div>
                        <h2>MEDICAL FUNCTION ACTIVATED</h2>
                        <p>Kindly disable the medical function to enable the clock's activation.</p>
                    </div>
                </div>
            </ModelScoreBoardMedicalStyle>
        </div>
    )
}

export default ModelScoreBoardMedical
import React from 'react'
import {ParticipantDataStyle} from  "./style"
import ToggleTrack from "../../assets/icons/ToggleTrack.png";
import ToggleButton from "../../assets/icons/Toggle.png";
import ParticipantsFrame from '../../assets/icons/ParticipantsFrame.svg';
import PrintIcon from "../../assets/icons/PrintIcon.svg";
import UploadtIcon from "../../assets/icons/UploadIcon.svg";
import  ParticipantImage from "../../assets/images/ali-hassan.png";
import UkFlag from "../../assets/icons/uk-flag.svg";
import { Switch } from 'antd';
const ParticipantData = () => {

  const renderParticipants = () => {
    const participants = [];

    for (let i = 0; i < 8; i++) {
      participants.push(
        <div key={i} className="card">
          <div className='participate-img '>
            <img src={ParticipantImage} alt="ParticipantImage" />
          </div>
          <div className='participate-content d-flex'>
            <p className="para m-0">Ali Hassan</p>
            <p className="m-0">MNBJJ Glasgow Brazilian Jiu-jitsu</p>
            <div className="d-flex">
              <div>
                <p className="m-0">2012 . 11 years old</p>
              </div>
              <div className="flag-image">
                <img src={UkFlag} alt="" />
              </div>
            </div>
          </div>

          <div className='participate-content-2 '>
            <p className="m-0">-30.20 kg</p>
            <p className="m-0">Pee Wee lll (9yrs)</p>
            <p className="m-0">White</p>
          </div>
        </div>
      );
    }

    return participants;
  };


  return (
    <div>
        <ParticipantDataStyle>
          <div className='wrapper'>
            <div className='headingParticipants'>
                <h4>Participants</h4>
            </div>
            <div className='participantBlock1'>
              <div className='participantBlock1_leftSide'>
                <div className='frameMain'>
                  <img src={ParticipantsFrame} alt="ParticipantsFrame" />
                </div>
                <div className='categoryHeading'>
                  <h3>Male Gi / White / Adult / -76 kg (Light)</h3>
                </div>
               <div className='totalParticipants'> 
                  <p>Total of Participants</p>
                  <span>30</span>
                </div>
              </div>
              <div className='participantBlock1_rightSide'>


              <div className="showApproved_toggleButton">
                <span>Show Unapproved</span>
                <Switch className='' />
              </div>

              {/* <div className='showApproved_toggleButton'>
                <span>Show Approved</span>
                <img src={ToggleTrack} alt="ToggleTrack" />
                <img src={ToggleButton} alt="ToggleButton" className='toggleButton' />
              </div> */}
                <div className='printIcon'>
                  <img src={PrintIcon} alt="PrintIcon" />
                </div>
                <div className='uploadIcon'>
                  <img src={UploadtIcon} alt="UploadtIcon" />
                </div>
              </div>
            </div>

            <div className='participantBlock2'>
              <div className='participantCards'>
              {renderParticipants()}
              </div>
              <div className='totalUnapproved'>
                <span>Total Unapproved: 08</span>
              </div>
            </div>

            <div className='participantBlock1 blockSpacing'>
              <div className='participantBlock1_leftSide'>
                <div className='frameMain'>
                  <img src={ParticipantsFrame} alt="ParticipantsFrame" />
                </div>
                <div className='categoryHeading'>
                  <h3>Male Gi / White / Adult / -76 kg (Light)</h3>
                </div>
               <div className='totalParticipants'> 
                  <p>Total of Participants</p>
                  <span>30</span>
                </div>
              </div>
              <div className='participantBlock1_rightSide'>
              <div className="showApproved_toggleButton">
                <span>Show Unapproved</span>
                <Switch className='' />
              </div>
              {/* <div className='showApproved_toggleButton'>
                <span>Show Approved</span>
                <img src={ToggleTrack} alt="ToggleTrack" />
                <img src={ToggleButton} alt="ToggleButton" className='toggleButton' />
              </div> */}
                <div className='printIcon'>
                  <img src={PrintIcon} alt="PrintIcon" />
                </div>
                <div className='uploadIcon'>
                  <img src={UploadtIcon} alt="UploadtIcon" />
                </div>
              </div>
            </div>

            <div className='participantBlock2'>
              <div className='participantCards'>
              {renderParticipants()}
              </div>
              <div className='totalUnapproved'>
                <span>Total Unapproved: 08</span>
              </div>
            </div>

            <div className='participantBlock1 blockSpacing'>
              <div className='participantBlock1_leftSide'>
                <div className='frameMain'>
                  <img src={ParticipantsFrame} alt="ParticipantsFrame" />
                </div>
                <div className='categoryHeading'>
                  <h3>Male Gi / White / Adult / -76 kg (Light)</h3>
                </div>
               <div className='totalParticipants'> 
                  <p>Total of Participants</p>
                  <span>30</span>
                </div>
              </div>
              <div className='participantBlock1_rightSide'>
              <div className="showApproved_toggleButton">
                <span>Show Unapproved</span>
                <Switch className='' />
              </div>
              {/* <div className='showApproved_toggleButton'>
                <span>Show Approved</span>
                <img src={ToggleTrack} alt="ToggleTrack" />
                <img src={ToggleButton} alt="ToggleButton" className='toggleButton' />
              </div> */}
                <div className='printIcon'>
                  <img src={PrintIcon} alt="PrintIcon" />
                </div>
                <div className='uploadIcon'>
                  <img src={UploadtIcon} alt="UploadtIcon" />
                </div>
              </div>
            </div>

            <div className='participantBlock2'>
              <div className='participantCards'>
              {renderParticipants()}
              </div>
              <div className='totalUnapproved'>
                <span>Total Unapproved: 08</span>
              </div>
            </div>

            <div className='paginationMain'>
              <div className='totalPages'>
                <ul>
                  <li>Page<span>1</span>of 10</li>
                </ul>
              </div>
              <div className='pagination'>
                <ul>
                  <li className='prevPage disabled'><span>Prev</span></li>
                  <li className='page1'><span>1</span></li>
                  <li className='page2'><span>2</span></li>
                  <li className='page3 active'><span>3</span></li>
                  <li  className='page4_9'><span>...</span></li>
                  <li className='page10'><span>10</span></li>
                  <li className='nextPage'><span>Next</span></li>
                </ul>
              </div>
            </div>

          </div>  {/* wrapper end  */}
         

        </ParticipantDataStyle>
    </div>
  )
}

export default ParticipantData
import styled from "styled-components";
import { fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegularUber } from "../../../components/GlobalStyle";


export const CoachProfileEventRegistrationStyle = styled.div`
.bodyWrapper_RightSide {
    width: 100%;
    margin-top: 23px;
}
.title {
    padding: 0 7px 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.title span {
    color: #000;
    text-align: center;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.217px;
    letter-spacing: 0.28px;
    cursor: pointer;
}
table {
    width: 100%;
    margin-top: -6px;
    padding-left: 13px !important;
    border-collapse: separate;
    border-spacing: 0 20px;
}
table tbody tr td {
    border-top: 1.029px solid #F4F4F4;
    border-bottom: 1.029px solid #F4F4F4;
    background-color: #FFFFFF;
}
table tbody tr td {
    padding: 9px 0 10px 0;
}
table tbody tr td:first-child {
    border-top-left-radius: 4.115px;
    border-bottom-left-radius: 4.115px;
    padding: 0 10px 0 15px;
    width: 25%;
}
table tbody tr td:nth-child(2) {
    width: 25%;
    padding-right: 10px
}
table tbody tr td:nth-child(3) {
    width: 10%;
    padding-right: 10px
}
table tbody tr td:nth-child(4) {
    width: 15%;
    padding-right: 10px
}
table tbody tr td:last-child {
    border-top-right-radius: 4.115px;
    border-bottom-right-radius: 4.115px;
    padding-right: 29px;
    width: 25% !important;
    text-align: right;
}
table tbody tr td span {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.444px;
}
table tbody tr td p {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.444px;
    padding-top: 2px;
    margin-bottom: 0;
}
.addNewButton {
    padding: 6px 20px 7px 20px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.4px;
    margin-right: 23px;
}
.membersButton {
    padding: 7px 12px 6px 21px;
    border-radius: 4px;
    border: 0.6px solid #959595;
    background-color: #EFEFEF;
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.4px;
}
`;

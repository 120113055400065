import React from "react";
import { OrganizerSettingCreateCategoryStyle } from "./style";
import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Content } from "antd/lib/layout/layout";
import { Formik } from "formik";
import { Row, Col } from "react-bootstrap";
import FormControl from "../../../components/FormControl";
import {
    fontFamilyEnnVisionsMedium,
  fontFamilyEnnVisionsRegular,
  fontFamilyMediumUber,
  fontFamilyRegular,
  mediumPurple,
  whiteColor,
} from "../../../components/GlobalStyle";
import CustomButton from "../../../components/CustomButton/CustomButton";
const OrganizerSettingCreateCategory = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };
  const handleCreateSubmit = (): void => {};
  return (
    <div>
      <OrganizerSettingCreateCategoryStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="OrganizerSettingCreateCategory-left">
                <CreatEventLeftSide />
              </Content>
              <Content className="OrganizerSettingCreateCategory-right">
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={handleCreateSubmit}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="wrapper">
                          <h3 className="title">Top List Name & Type</h3>
                          <div className="bg-white form">
                            <Row>
                              <Col md="12">
                                <FormControl
                                  control="input"
                                  type="text"
                                  name="topListName"
                                  label="Top list name"
                                  padding="10px"
                                  fontSize="16px"
                                  fontFamily={fontFamilyEnnVisionsRegular}
                                  placeholderFont="16px"
                                  placeholderFamily="fontFamilyRegularUber"
                                  height="50px"
                                  margin="0"
                                  max={6}
                                  placeholder="1"
                                  className={
                                    formik.errors.timeZone &&
                                    formik.touched.timeZone
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                />
                              </Col>
                              <Col md="12" className="mt-20">
                                <FormControl
                                  control="textarea"
                                  type="text"
                                  name="visibility"
                                  fontFamily={fontFamilyEnnVisionsRegular}
                                  label="Visibility"
                                  padding="10px"
                                  placeholder="Yes"
                                  className={
                                    formik.errors.detail &&
                                    formik.touched.detail
                                      ? "is-invalid"
                                      : "customInput"
                                  }
                                  height="363px"
                                />
                              </Col>
                            </Row>
                          </div>
                            <div className="mt-11 d-flex justify-content-end">
                              <CustomButton
                                bgcolor={mediumPurple}
                                textTransform="Captilize"
                                color={whiteColor}
                                padding="11px 81px"
                                fontFamily={`${fontFamilyMediumUber}`}
                                width="fit-content"
                                type="submit"
                                title="SAVE"
                                fontSize="16px"
                                loading={false}
                              />
                            </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerSettingCreateCategoryStyle>
    </div>
  );
};

export default OrganizerSettingCreateCategory;

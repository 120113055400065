import { useNavigate } from "react-router-dom"
import { Branch_Create_URL, Branch_Delete_URL, Branch_List_URL, Branch_Update_URL, Branch_View_URL } from "../../../utils/api_urls"
import { BranchInitialValues } from "../constant"
import { useState } from "react"
import CustomButton from "../../../components/CustomButton/CustomButton"
import { Col, Row } from "react-bootstrap"
import { RobinEggBlue, fontFamilyRegularUber, pureDark2 } from "../../../components/GlobalStyle"
import { SchoolSuccessfulModals } from "../../../hooks/PopupModalsStyling"
import CustomModal from "../../../components/Modal/CustomModal"
import CustomMessageModal from "../../../components/Modal/CustomMessageModal"
import ic_success from '../../../assets/images/ic_success.svg'
import api from "../../../api"

interface IModalComponent {
    modalComponent: JSX.Element
}

interface IUseBranch {
    // loading: boolean
    handleBranchCreate: (
        values: BranchInitialValues
    ) => Promise<void>
    handleBranchUpdate: (
        BranchId: number,
        values: BranchInitialValues
    ) => Promise<void>
    showBranchList: (academyId: number) => Promise<any>
    getBranchView: (adminId: number) => Promise<any>
    handleBranchDelete: (adminId: number) => Promise<any>
    SuccessModal: () => IModalComponent
    WarningModal: () => IModalComponent
    AcademyWarningModal: () => IModalComponent
    deleteConfirmation: (id: number, setBranch: any) => IModalComponent
    setIsShowModal: (showModal: true) => void
    setIsWarningModal: (showModal: true) => void
}


const useBranch = (): IUseBranch => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [isShowModal, setIsShowModal] = useState(false)
    const [isWarningModal, setIsWarningModal] = useState(false)
    const [isShowSuccessModal, setIsShowSuccessModal] = useState(false)
    const [isShowErrorModal, setIsShowErrorModal] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')



    // Branch Create
    const handleBranchCreate = async (
        values: BranchInitialValues
    ): Promise<void> => {

        const payload = {
            academyId: values.academyId,
            branchName: values.branchName,
            branchType: values.branchType,
            phoneNumber: values.phoneNumber,
            address: values.address,
            rank: values.rank,
            defaultLanguageId: values.defaultLanguageId,
            defaultCurrencyId: values.defaultCurrencyId,
            activities: values?.activities.join(','),
            facilities: values?.facilities.join(','),
            description: values.description,
            academyStripeMethod: values.academyStripeMethod,
            academyGclMethod: values.academyGclMethod,
            academyPaypalMethod: values.academyPaypalMethod,
            academyBankAccountMethod: values.academyBankAccountMethod,
            academyCashMethod: values.academyCashMethod
        }
        try {
            setLoading(true)
            const { data } = await api("post",
                Branch_Create_URL,
                payload,
            )
            setSuccessMessage(data.message)
            setIsShowSuccessModal(true)
            setLoading(false)
            setTimeout(() => {
                setIsShowSuccessModal(false)
                navigate('/branch/list')
            }, 3000)
        } catch (error: any) {
            const errorMessages = Object.keys(error.response?.data?.errors || {}).map(
            (field) =>
                `<strong>${field}</strong>: ${error.response?.data?.errors[field]}`
        );

        setLoading(false);

        if (errorMessages.length > 1) {
            setErrorMessage(`${errorMessages.join('<br />')}`);
        } else {
            setErrorMessage(error.response?.data?.message);
        }

        setIsShowErrorModal(true);

        setTimeout(() => {
            setIsShowErrorModal(false);
        }, 10000);
        }
    }

    // Branch List
    const showBranchList = async (academyId: number): Promise<any> => {
        try {
            const { data } = await api("post",
                Branch_List_URL,
                { "academyId": academyId }
            )
            console.log("BranchList", data.result)
            return data.result
        } catch (error) {
            console.log("error", error)
        }
    }

    // Branch Update
    const handleBranchUpdate = async (
        branchId: number,
        values: BranchInitialValues
    ): Promise<any> => {
        const url = Branch_Update_URL
        try {
            const payload = {
                branchId: branchId,
                academyId: values.academyId,
                branchName: values.branchName,
                branchType: values.branchType,
                phoneNumber: values.phoneNumber,
                address: values.address,
                rank: values.rank,
                defaultLanguageId: values.defaultLanguageId,
                defaultCurrencyId: values.defaultCurrencyId,
                activities: values.activities.join(','),
                facilities: values.facilities.join(','),
                description: values.description,
                academyStripeMethod: values.academyStripeMethod,
                academyGclMethod: values.academyGclMethod,
                academyPaypalMethod: values.academyPaypalMethod,
                academyBankAccountMethod: values.academyBankAccountMethod,
                academyCashMethod: values.academyCashMethod
            }

            const { data } = await api("post",url, payload)
            setSuccessMessage(data.message)
            setIsShowSuccessModal(true)
            setLoading(false)
            setTimeout(() => {
                setIsShowSuccessModal(false)
                navigate('/branch/list')
            }, 3000)
        } catch (error: any) {
             const errorMessages = Object.keys(error.response?.data?.errors || {}).map(
            (field) =>
                `<strong>${field}</strong>: ${error.response?.data?.errors[field]}`
        );

        setLoading(false);

        if (errorMessages.length > 1) {
            setErrorMessage(`${errorMessages.join('<br />')}`);
        } else {
            setErrorMessage(error.response?.data?.message);
        }

        setIsShowErrorModal(true);

        setTimeout(() => {
            setIsShowErrorModal(false);
        }, 10000);
        }
    }

    // Branch View
    const getBranchView = async (branchId: number): Promise<any> => {
        try {
            const { data } = await api("post",
                Branch_View_URL,
                { "branchId": branchId }
            )
            return data.result
        } catch (error: any) {
            console.log("error", error)
        }
    }


    // Branch Delete
    const handleBranchDelete = async (branchId: number): Promise<any> => {
        try {
            const { data } = await api("post",
                Branch_Delete_URL,
                { "branchId": branchId }
            )
            setLoading(false)
            setSuccessMessage(data.message)
            setIsShowSuccessModal(true)
            setTimeout(() => {
                setIsShowSuccessModal(false)
                navigate('/branch/list')
            }, 3000)
        } catch (error: any) {
            setErrorMessage(error.message)
            setIsShowErrorModal(true)
            setLoading(false)
            setTimeout(() => {
                setIsShowErrorModal(false)
            }, 3000)
        }
    }


    const AcademyWarningModal = (): IModalComponent => {
        return {
            modalComponent: (
                <CustomMessageModal
                    title="Warning"
                    description={"you have to create academy first"}
                    isModalVisible={isWarningModal}
                    setIsModalVisible={setIsWarningModal}
                    imageProp={'error'}
                />
            ),
        }
    }

    const SuccessModal = (): IModalComponent => {
        return {
            modalComponent: (
                <CustomMessageModal
                    title="Success"
                    description={successMessage}
                    isModalVisible={isShowSuccessModal}
                    setIsModalVisible={setIsShowSuccessModal}
                    imageProp={'success'}
                />
            ),
        }
    }

    const WarningModal = (): IModalComponent => {
        return {
            modalComponent: (
                <CustomMessageModal
                    title="Warning"
                    description={errorMessage}
                    isModalVisible={isShowErrorModal}
                    setIsModalVisible={setIsShowErrorModal}
                    imageProp={'error'}
                />
            ),
        }
    }

    const deleteConfirmation = (_id: number, setBranch: any): IModalComponent => {
        const DeleteSchool = async (id: number): Promise<void> => {
            setIsShowModal(false)

            await handleBranchDelete(Number(id))
            setBranch((prev: any) => {
                prev = prev.filter((el: any) => el.branchId !== id);
                return [...prev]
            })
        }
        return {
            modalComponent: (
                <CustomModal
                    isModalVisible={isShowModal}
                    setIsModalVisible={setIsShowModal}
                    showCloseBtn={false}
                >
                    <SchoolSuccessfulModals>
                        <div className="mainContainer">
                            <div className='mainContainer-header'>
                                <img
                                    src={ic_success}
                                    alt="Success Icon"
                                    width={25}
                                    height={25}
                                />
                                <h3 className="mainContainer-header-heading">
                                    Delete Academy
                                </h3>
                            </div>
                            <div className='mainContainer-body'>
                                <p className="mainContainer-body-subText">
                                    You can block them directly from their profile. If you  do, they won't be able to find your profile, posts or story or send you messages on Instagram. Instagram won't let them know you blocked them.
                                </p>
                                <Row className="mt-20">
                                    <Col md="6">
                                        <CustomButton
                                            bgcolor={'#292F3F33'}
                                            textTransform="uppercase"
                                            color={pureDark2}
                                            padding="10px 12.5px"
                                            fontFamily={fontFamilyRegularUber}
                                            width="100%"
                                            type="button"
                                            title="NO!"
                                            fontSize="16px"
                                            loading={false}
                                            clicked={() => setIsShowModal(false)}
                                        />
                                    </Col>
                                    <Col md="6">
                                        <CustomButton
                                            bgcolor={RobinEggBlue}
                                            textTransform="uppercase"
                                            color={pureDark2}
                                            padding="10px 12.5px"
                                            fontFamily={fontFamilyRegularUber}
                                            width="100%"
                                            type="submit"
                                            title="YES"
                                            fontSize="16px"
                                            loading={false}
                                            clicked={() => DeleteSchool(_id)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </SchoolSuccessfulModals>
                </CustomModal >
            ),
        }
    }

    return {
        // loading,
        handleBranchCreate,
        showBranchList,
        handleBranchUpdate,
        getBranchView,
        handleBranchDelete,
        SuccessModal,
        deleteConfirmation,
        WarningModal,
        setIsShowModal,
        AcademyWarningModal,
        setIsWarningModal,

    }
}

export default useBranch
import React from 'react'
import { WonByLightStyle } from "./style"
import { useAppDispatch } from '../../../app/hooks'
import { updateEndGameMessage, updateEndGameMessageB } from '../../../app/reducer/scoreBoard/AdccIbjjfScoreBoardSlice'

const WonByAdccIbjjf = ({ isDark = false}: any) => {
    const dispatch = useAppDispatch()
    const handleClick=(e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>, message: string) => {
        e.stopPropagation();
        if( isDark ){
            dispatch(updateEndGameMessageB({
                updatedEndGameMessageB: message
            }))
        }else{
            dispatch(updateEndGameMessage({
                updatedEndGameMessage: message
            }))
        }
    }

    return (
        <div>
            <WonByLightStyle className={isDark ? 'dark' : 'light'}>
                <div className='boardScoreWonLight'>
                    <div className="scoreBoardTable">
                        <table width="100%">
                            <tr>
                                <td colSpan={4}>
                                    WON BY
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} onClick={(e) => handleClick(e,"Winner by Points")}>
                                    Points
                                </td>
                                <td colSpan={2} onClick={(e) => handleClick(e,"Winner by Submission")}>
                                    Submission
                                </td>
                            </tr>
                            <tr>
                                <td onClick={(e) => handleClick(e,"Winner by Disqualification")}>Disqualification</td>
                                <td onClick={(e) => handleClick(e,"Winner by Walkover")}>Walkover</td>
                                <td onClick={(e) => handleClick(e,"Winner by NoShow")}>NoShow</td>
                                <td onClick={(e) => handleClick(e,"Winner by Decision")}>Decision</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </WonByLightStyle>
        </div>
    )
}

export default WonByAdccIbjjf
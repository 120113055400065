import LoginButton from './LoginButton';
import facebook from '../../../assets/icons/ic_facebook.svg';
import { base_url, facebook_auth_service } from '../../../utils/api_urls';

const FacebookLogin = (): JSX.Element => {

    const handleFacebookLogin = () => window.location.href = `${base_url}${facebook_auth_service}`;

    return (
        <div onClick={handleFacebookLogin}>
            <LoginButton type={facebook as string} alt="Facebook" />
        </div>
    )
};
export default FacebookLogin;

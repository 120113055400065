import styled from "styled-components";
import { fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber } from "../../../components/GlobalStyle";


export const OrganizerMessengerCenterParticipants3Style = styled.div`


.bodyWrapper_RightSide {
    width: 100%;
    margin-top: 14px;
}
.title_submitButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title, .participantTitle h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.sendNowButton {
    padding: 11px 16px 10px 17px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
.participantTitle {
    margin-top: 16px;
}
.participantsList {
    display: flex;
    flex-wrap: wrap;
    gap: 20.47px 19px;
    margin-top: 16px;
}
.participantsList > div {
    max-width: 32.14%;
    width: 100%;
}
.pagination_Container {
    margin: 20px 0 20px 0;
}

.tablelist{
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    margin-top: 18px;
    margin-bottom: 21px;
}
.organizerSettingForm{
    padidng: 18px;
}
.organizerSettingForm form{
    display: flex;
    padding: 17px 20px;
    gap: 22px;
}
.organizerSettingForm .form-group{
    width: 33%;
}
.organizerSettingForm label{
    display: block;
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 7px;
}
.organizerSettingForm input[type="text"]{
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    width: 100%;
    padding: 16px 10px;
    height: 50px;
    color: #4F4F4F;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.organizerSettingForm select{
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    width: 100%;
    padding: 12px 10px;
    height: 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(assets/images/arrowSelectOption.svg) 96% center no-repeat;
    color: #4F4F4F;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.organizerSettingForm select option{
    color: #4F4F4F;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.ant-picker{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
}
.ant-picker-focused{
    box-shadow: 0 0 0 2px transparent !important;
    input{
        color: #4F4F4F;
        font-family: ${fontFamilyEnnVisionsRegular};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    input:placeholder{
        color: #4F4F4F;
    }
}
`;
import styled from "styled-components";
import { fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../components/GlobalStyle";

export const EventLocationStyle = styled.div`

.wrapper {
    width: 100%;
    background-color: #f5f5f5;
}
.locationHeading {
    margin-top: 10px;
}
.locationHeading h4 {
    color: #061229;
    font-family: ${fontFamilyMedium};
    // font-size: 28px;
    font-sizde: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}
.participantBlock1 {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0px 28px 0px 19px;
    background-color: #fff;
}
.participantBlock1_Table2 {
    margin-top: 20px !important;
}
.participantBlock1_leftSide {
    display: flex;
    align-items: center;
    gap: 32px;
}
.frameMain img {
    width: 129px;
    height: 16px;
}
.categoryHeading h3 {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-bottom: 0;
}
.totalParticipants {
    display: flex;
    align-items: center;
}
.totalParticipants p {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.32px;
    margin-right: 10px;
    margin-bottom: 0;
}
.totalParticipants span {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.36px;
}
.participantBlock1_rightSide {
    display: flex;
    align-items: center;
}
.showApproved_toggleButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    margin-right: 19.50px;
}
.showApproved_toggleButton span {
    color: #000;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
    margin-right: 15px;
}
.toggleButtonApproved {
    position: absolute;
    right: 15px;
    z-index: 999;
}
.printIcon {
    margin-right: 9px;
}
.printIcon, .uploadIcon {
    width: 34px;
    height: 34px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #B6D0D7;
    background-color: #D7E6EA;
}
table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
}
thead tr th {
    padding: 20px 0;
}
.showUnApproved_toggleButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}
.showUnApproved_toggleButton span {
    color: #232837;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
    margin-right: 10px;
}
.toggleButton {
    position: absolute;
    right: 15px;
}
.showApproved {
    padding-right: 21px;
}
.tableHeaddings th {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.image {
    width: 7.85%;
    padding-left: 10px;
}
.birth {
    width: 11%;
}
.name, .ranking, .showApproved {
    width: 17%;
}
.name {
    padding-left: 25px;
}
.academy {
    width: 14.8%;
}
.registration {
    width: 15%;
}
.tableBody tr {
    border-radius: 6px;
    border: 1px solid #E9E9E9;
}
.tableBody tr td {
    background-color: #fafafa;
}
.image img {
    width: 90px;
    height: 90px;
    border-radius: 3.979px 0px 0px 3.979px;
    object-fit: cover;
    object-position: top;
}
.name p, .birth p, .academy p,
.registration p, .ranking p {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 13.63px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.702px;
    margin-bottom: 0;
}
.birth span  {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    // font-size: 16px;
    font-size: 13.63px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.birth span {
    padding-top: 9px;
}
.academy span {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    // font-size: 16px;
    font-size: 13.63px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.702px;
    padding-top: 6px;
}
.weight {
    display: flex;
    align-items: center;
    padding-top: 7px;
}
.weight img {
    width: 12px;
    height: 12px;
}
.weight span {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    // font-size: 16px;
    font-size: 13.63px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.702px;
    margin-left: 5px;
} 
.countryFlag {
    display: flex;
    align-items: center;
    padding-top: 10px;
}
.countryFlag img {
    width: 18px;
    height: 12px;
    vertical-align: middle;
    margin-left: 3px;
}
.countryFlag span {
    color: #333;
    font-family: ${fontFamilyRegularUber};
    // font-size: 16px;
    font-size: 13.63px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.totalUnapproved {
    margin: 24px 0 20px 0; 
    display: inline-block;
    width: 100%;
}
.totalUnapproved span, .totalUnapprovedTable2 span  {
    color: #0070E0;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.totalUnapprovedTable2 {
    margin: 19px 0 19px 0; 
}

// Pagination
.paginationMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    margin-bottom: 13px;
}
.totalPages ul {
    display:flex;
    align-items: center;
    padding: 0;
    margin: 0;
}
.totalPages ul li {
    display: flex;
    align-items: center;
    list-style-type: none;

    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.totalPages ul li span {
    display: flex;
    width: 47px;
    padding: 7px 31px 8px 9px;
    align-items: center;
    border-radius: 6px;
    background-color: #f5f5f5;
    border: 1px solid #F1F1F1;
    margin: 0 9px;

    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.pagination ul {
    display:flex;
    align-items: center;
    gap: 0 6px;
    padding: 0;
    margin: 0;
}
.pagination ul li {
    list-style-type: none;
}
.disabled {
    display: inline-flex;
    padding: 7px 7px 9px 6px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #FFF;
    cursor: pointer;
}
.disabled span {
    color: #BDBDBD;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page1 {
    display: flex;
    width: 32px;
    padding: 7px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    background-color: #FFF;
    cursor: pointer;
}
.page2 {
    display: flex;
    width: 32px;
    padding: 7px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    background-color: #FFF;
    cursor: pointer;
}
.page1 span, .page2 span, .page10 span, .nextPage span  {
    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.active {
    display: flex;
    width: 32px;
    padding: 7px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #1A4754;
    cursor: pointer;
}
.active span {
    color: #FFF;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page4_9 {
    display: flex;
    width: 32px;
    padding: 4px 0px 12px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #FFF;
    cursor: pointer;
}
.page4_9 span {
    color: #333;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page10 {
    display: inline-flex;
    padding: 8px 9px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    background-color: #FFF;
    cursor: pointer;
}
.nextPage {
    display: inline-flex;
    padding: 8px 10px 8px 11px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #FFF;
    cursor: pointer;
}

.showApproved_toggleButton button {
    width: 32px;
    background-color: #C8CCD0 !important;
    height: 7px;
}
.showApproved_toggleButton span {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.14px;
    text-transform: none;
    margin-right: 15px;
    
}
.ant-switch-handle::before{
    background-color: #17374e !important;
}

`;

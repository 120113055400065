import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber } from "../../../components/GlobalStyle";

export const OrganizerCreateAnEventRegistrationStyle = styled.div`

.creatEvent-left{
    width: 15%;
}
.creatEvent-right{
    width: 85%;
}

.ant-layout > .banner + .ant-layout{
    flex-direction: row;
    gap: 10px;
}

.bodyWrapper_RightSide {
    width: 100%;
}
.title {
    margin-top: 33px;
    padding-left: 34px;
}
.title h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.sec1_container {
    padding-left: 35px;
}
.sec2_container {
    padding-left: 35px;
    margin-top: 30px;
}
.maleGiSec1_viewBracketsMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 7px 11px;
    border-radius: 8px 8px 0px 0px;
    border: 0.878px solid #E4E4E4;
    background-color: #F3F3F3;
    margin-top: 15px;
}
.maleGiSec2_viewBracketsMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 7px 11px;
    border-radius: 8px 8px 0px 0px;
    border: 0.878px solid #E4E4E4;
    background-color: #F3F3F3;
}
.checkbox_MaleGiTitle {
    display: flex;
    align-items: center;
}
.maliGi_subTitle {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-left: 15px;
    margin-bottom: 0;
}
.viewBracketsButton button {
    padding: 11px 10px 10px 11px;
    border-radius: 4px;
    background: linear-gradient(0deg, #4CAF50 0%, #4CAF50 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
.addBracketsButton button {
    padding: 11px 13px 10px 14px;
    border-radius: 4px;
    background: linear-gradient(0deg, #0070E0 0%, #0070E0 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
table {
    width: 100%;
    background-color: #fff;
    border-collapse: separate;
    border-spacing: 0px 7px;
    border-radius: 0  0 10px 10px;
    border: 1px solid #E0E0E0;
    border-top: none !important
}
table thead tr th {
    padding: 16px 0 15px 10px;
    color: #06122);
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.table tbody tr td {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
table thead tr th:last-child {
    padding-right: 12px;
}
table tbody tr td:last-child {
    padding-right: 17.3px;
    text-align: right;
}
table tbody tr td {
    padding: 0 0 0 10px;
}
table thead tr th:first-child,
table tbody tr td:first-child {
    width: 2.2%;
    padding-left: 7px;
    padding-right: 5px;
    border: none !important;
    background-color: #fff !important;
}
table tbody tr td:last-child {
    border-radius: 0px 6px 0 6px !important;
} 
table thead tr th:nth-child(2),
table tbody tr td:nth-child(2) {
    width: 5%;
    padding: 0;
    border: none;
}
table thead tr th:nth-child(3),
table tbody tr td:nth-child(3) {
    width: 11%;
    padding-left: 11px;
}
table thead tr th:nth-child(4),
table tbody tr td:nth-child(4) {
    width: 9.25%;
}
table thead tr th:nth-child(5),
table tbody tr td:nth-child(5) {
    width: 15%;
}
table thead tr th:nth-child(6),
table tbody tr td:nth-child(6) {
    width: 10%;
}
table thead tr th:nth-child(7),
table tbody tr td:nth-child(7) {
    width: 8%;
}
table thead tr th:nth-child(8),
table tbody tr td:nth-child(8) {
    width: 11%;
}
table thead tr th:nth-child(9),
table tbody tr td:nth-child(9) {
    width: 9%;
}
table thead tr th:nth-child(10),
table tbody tr td:nth-child(10) {
    width: 16%;
}
table thead tr th:last-child,
table tbody tr td:last-child {
    width: 3.55%;
}
table tbody tr td {
    border-top: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
}
table tbody tr:nth-child(odd) {
    background-color: #FAFAFA;
}
table tbody tr:nth-child(even) {
    background-color: #fff;
}
table tbody tr td:last-child {
    margin: 0 10px 0 0 !important;
} 
table tbody {
    padding: 40px;
}
.personImage img {
    width: 50px;
    height: 50px;
    border-radius: 6px 0px 0px 6px;
}
.weighMain {
    display: flex;
    align-items: center;
    gap: 5px;
}
.weigh_firstIcon {
    padding: 3.68px 4px 5.72px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 0.5px solid #E2E2E2;
    background-color: #EDEDED;
}
.weigh_secondIcon { 
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 4.68px 6px 5.72px 5px;
    border-radius: 4px;
    background: #4CAF50;
} 
.weigh_secondIcon span {
    color: #FFF;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.weigh_checkedThirdIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.68px 3.27px 3.98px 3px;
    border-radius: 4px;
    border: 0.5px solid #4CAF50;
    background-color: #4CAF50;
}
.weigh_checkedThirdIcon span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5.45px 3.87px 4.17px 5px;
    border-radius: 8.866px;
    background-color: #FFF;
}
.weigh_fourthIcon {
    padding: 1.68px 2px 3.72px 3px;
    border-radius: 4px;
    border: 0.5px solid #4CAF50;
    background-color: #4CAF50;
}
.nextButton {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 22px;
    margin-bottom: 15px;
}
.nextButton button {
    padding: 11px 39px 10px 40px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
`;
import React, { useState, useEffect } from "react";
import { IbjjfUltmScoreBoardStyle } from "./style";

import ingTimeBoardLeft from "../../../assets/icons/ic_ringTimeBoardLeft.svg";
import ingTimeBoardRight from "../../../assets/icons/ic_ringTimeBoardRight.svg";
import PlayBtn from "../../../assets/icons/ic_playBtn.svg";
import PauseBtn from "../../../assets/icons/ic_pauseBtn.svg";
import PauseIcon from "../../../assets/icons/ic_pause_ScoreBoard.svg";
import EndMatchSave from "../../../components/ScoreBoardComponents/EndMatch/EndMatch";
import SwapArrowDown from "../../../assets/icons/ic_ArrowDownScoreBoard.svg";
import SwapArrowUp from "../../../assets/icons/is_ArrowUpScoreBoard.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";

import Minus30Icon from "../../../assets/icons/scoreBoardIcons/minus30.svg";
import Minus1Icon from "../../../assets/icons/scoreBoardIcons/minus1.svg";
import PlayIcon from "../../../assets/icons/scoreBoardIcons/playIcon.svg";
import Plus1Icon from "../../../assets/icons/scoreBoardIcons/plus1.svg";
import Plus30Icon from "../../../assets/icons/scoreBoardIcons/plus30.svg";

const IbjjfUltmScoreBoard: React.FC = () => {
  const {
    teamOneScoreBoard,
    teamTwoScoreBoard,
    scoreHistory,
    playerOne,
    playerTwo,
    matchTimer,
    endGameMessage,
    endGameMessageType,
    endGameMessageB,
  } = useSelector((state: RootState) => state.ibjjfUltmScoreboard);

  // Section 1 and 2 functionality states

  const formatTime = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const remainingSeconds = timer % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return ` ${formattedMinutes}:${formattedSeconds} `;
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data === "goFullscreen") {
        document.documentElement.requestFullscreen({ navigationUI: "hide" });
      }
    });
  }, []);

  return (
    <IbjjfUltmScoreBoardStyle>
      <div id="tradScoreboard">
        <div id="tradScoreboardPlayers">
          {/* <div className="messagesBody">
            <div className="messagesShows">
              <div className={`result submission `}>
                <span className="winner-text">Won by Winner</span>
              </div>
            </div>
            <div className="playerDetails trad-player">
              <div className="trad-player-points">
                <div>
                  <div className="player-attr">
                    <div className="attr-rows ">
                      <div className="trad-header ">
                        <div className="player-name ">
                          <span className="name truncate">
                            <span className="firstname">Jennine</span>{" "}
                            <span className="lastname">Berry</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="player playerContainer playerLeft">
            <div className="boardName">
              <div className="playerControls">
                <div className="table1Left">
                  <div className="playerAttributes">
                    <div
                      className="attributeRows truncate"
                      style={{ paddingLeft: "3vh", paddingTop: "10vh" }}
                    >
                      <div className="header truncate">
                        <div className="playerName truncate">
                          <img src={playerOne.countryFlag} className="flag" />
                          <span className="name">
                            <span className="firstname">
                              {playerOne.playerFirstName}{" "}
                            </span>
                            <span className="lastname">
                              {playerOne.playerLastName}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="subheader">
                        <span className="countryName">
                          {playerOne.countryName}
                        </span>
                        <div className="clubAndTeam truncate">
                          <div className="clubAndTeamContainer truncate">
                            <div className="clubName truncate">
                              <img
                                src={playerOne.clubFlag}
                                className="clubIcon imgCircle"
                              />
                              <span>{playerOne.clubName}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="score2Controls">
              <div className="scoresContainer">
                <div className="mainScore penalty">
                  <div className="mainScorePenality mainScoreText">
                    {teamOneScoreBoard.penalty > 1 ? "penalties" : "Penalty"}
                  </div>
                  <div className="count pen points">
                    <div
                      className={` count count1 ${
                        teamOneScoreBoard.penalty <= 9
                          ? "digits-1 "
                          : teamOneScoreBoard.penalty <= 99
                          ? "digits-2"
                          : "digits-3"
                      }`}
                    >
                      {teamOneScoreBoard.penalty}
                    </div>
                  </div>
                </div>
                <div className="mainScore advantage">
                  <div className="mainScoreAdvantage mainScoreText">
                    {teamOneScoreBoard.advantage > 1
                      ? "Advantages"
                      : "Advantage"}
                  </div>
                  <div className="count adv points">
                    <div
                      className={`count1 ${
                        teamOneScoreBoard.advantage <= 9
                          ? "digits-1 "
                          : teamOneScoreBoard.advantage <= 99
                          ? "digits-2"
                          : "digits-3"
                      }`}
                    >
                      {teamOneScoreBoard.advantage}
                    </div>
                  </div>
                </div>
                <div className="mainScore points">
                  <div className="mainScorePoint mainScoreText">
                    {teamOneScoreBoard.score > 1 ? "Points" : "Point"}
                  </div>
                  <div
                    className="points count mainScore"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      className={`count count1 ${
                        teamOneScoreBoard.score <= 9
                          ? "digits-1 "
                          : teamOneScoreBoard.score <= 99
                          ? "digits-2"
                          : "digits-3"
                      }`}
                    >
                      {teamOneScoreBoard.score}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`score2`}>
                {teamOneScoreBoard.isMedicalModal && (
                  <div className={`sec1 medical`}>
                    <h2>
                      {formatTime(teamOneScoreBoard.medicalValue as number)}
                    </h2>
                    <p>Medical</p>
                  </div>
                )}
                {teamOneScoreBoard.isStallingModal && (
                  <div className={`sec1 stalling`}>
                    <div>
                      <h2>
                        {formatTime(teamOneScoreBoard.stallingValue as number)}
                      </h2>
                      <p>Stalling</p>
                    </div>
                  </div>
                )}
                {teamOneScoreBoard.isTimeoutModal && (
                  <div className={`sec1 timeout`}>
                    <h2>
                      {formatTime(teamOneScoreBoard.timeoutValue as number)}
                    </h2>
                    <p>TIMEOUT</p>
                  </div>
                )}
                {teamOneScoreBoard.isVarModal && (
                  <div className={`sec1 varAssistant`}>
                    <h2>VAR</h2>
                    <p>MODE ON</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="player playerContainer playerRight">
            <div className="boardName">
              <div className="playerControls">
                <div className="table2Right">
                  <div className="playerAttributes">
                    <div
                      className="attributeRows truncate"
                      style={{ paddingRight: "3vh", paddingTop: "10vh" }}
                    >
                      <div className="header truncate">
                        <div className="playerName truncate">
                          <span className="name">
                            <span className="firstname">
                              {playerTwo.playerFirstName}
                            </span>
                            <span className="lastname">
                              {playerTwo.playerLastName}
                            </span>
                          </span>
                          <img src={playerTwo.countryFlag} className="flag" />
                        </div>
                      </div>
                      <div className="subheader">
                        <span className="countryName">
                          {playerTwo.countryName}
                        </span>
                        <div className="clubAndTeam truncate">
                          <div className="clubAndTeamContainer truncate">
                            <div
                              className="truncate clubFlag"
                              style={{ width: "auto !important" }}
                            >
                              <img
                                src={playerTwo.clubFlag}
                                className="clubIcon imgCircle"
                              />
                            </div>
                            <div className="clubName truncate">
                              <span>{playerTwo.clubName}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="score2Controls">
              <div className="scoresContainer">
                <div className="mainScore penalty">
                  <div className="mainScorePenality mainScoreText">
                    {teamTwoScoreBoard.penalty > 1 ? "penalties" : "Penalty"}
                  </div>
                  <div className="count pen points">
                    <div
                      className={`count1 count ${
                        teamTwoScoreBoard.penalty <= 9
                          ? "digits-1 "
                          : teamTwoScoreBoard.penalty <= 99
                          ? "digits-2"
                          : "digits-3"
                      }`}
                    >
                      {teamTwoScoreBoard.penalty}
                    </div>
                  </div>
                </div>
                <div className="mainScore advantage">
                  <div className="mainScoreAdvantage mainScoreText">
                    {teamTwoScoreBoard.advantage > 1
                      ? "Advantages"
                      : "Advantage"}
                  </div>
                  <div className="count adv points">
                    <div
                      className={`count1 count ${
                        teamTwoScoreBoard.advantage <= 9
                          ? "digits-1 "
                          : teamTwoScoreBoard.advantage <= 99
                          ? "digits-2"
                          : "digits-3"
                      }`}
                    >
                      {teamTwoScoreBoard.advantage}
                    </div>
                  </div>
                </div>
                <div className="mainScore points">
                  <div className="mainScorePoint mainScoreText">
                    {teamTwoScoreBoard.score > 1 ? "Points" : "Point"}
                  </div>
                  <div
                    className="mainScore"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      className={`count1 count ${
                        teamTwoScoreBoard.score <= 9
                          ? "digits-1 "
                          : teamTwoScoreBoard.score <= 99
                          ? "digits-2"
                          : "digits-3"
                      }`}
                    >
                      {teamTwoScoreBoard.score}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`score2`}>
                {teamTwoScoreBoard.isMedicalModal && (
                  <div className={`sec1 medical swapArrow`}>
                    <h2>
                      {formatTime(teamTwoScoreBoard.medicalValue as number)}
                    </h2>
                    <p>Medical</p>
                  </div>
                )}
                {teamTwoScoreBoard.isStallingModal && (
                  <div className={`sec1 stalling swapArrow`}>
                    <div>
                      <h2>
                        {formatTime(teamTwoScoreBoard.stallingValue as number)}
                      </h2>
                      <p>Stalling</p>
                    </div>
                  </div>
                )}
                {teamTwoScoreBoard.isTimeoutModal && (
                  <div className={`sec1 timeout`}>
                    <h2>
                      {formatTime(teamTwoScoreBoard.timeoutValue as number)}
                    </h2>
                    <p>TIMEOUT</p>
                  </div>
                )}

                {teamTwoScoreBoard.isVarModal && (
                  <div className={`sec1 varAssistant`}>
                    <h2>VAR</h2>
                    <p>MODE ON</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="tradScoreboardBottom">
          <div
            style={{
              flexGrow: "1",
              width: "0px",
              zIndex: "2",
              position: "relative",
            }}
          >
            <div className="matchInfoContainer">
              <div className="matchNumber">
                <span>1-1</span>
              </div>
              <div className="matchInfo">
                <div className="matchInfoText">
                  <div className="bracketDivision">
                    <div className="scMarquee">
                      <div className="marqueeContainer animate">
                        <span className="marqueeText">
                          Boys Gi / Mighty Mite I (4 yrs) / -17,90 kg / Grey
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bracketRound">
                    <div className="scMarquee primary">
                      <div className="marqueeContainer">
                        <span className="marqueeText">Semifinals</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="clock">
            <div className="time running">
              <div
                className="count "
                style={{
                  color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                }}
              >
                <p
                  style={{
                    color: matchTimer.isMatchTimerPaying ? "#eddb2e" : "#fff",
                  }}
                >
                  {formatTime(matchTimer.matchTimerValue)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IbjjfUltmScoreBoardStyle>
  );
};
export default IbjjfUltmScoreBoard;

import { useEffect } from 'react'
import Banner from '../../components/Banner/Banner'
import Footer from "../../components/Footer/footer"
import Navbar from '../../components/Navbar/Navbar'
import { toast } from 'react-toastify'
import MessageModal from '../../components/Common/MessageModal/MessageModal'
import { useLocation } from 'react-router-dom'

const HomePage = () => {
  const location = useLocation();

  useEffect(()=>{
    const params = new URLSearchParams(location.search);
    const register = params.get("register");
    if (register === "failed") {
      const message = params.get("message")?.split("_").join(" ");

      toast(
        <MessageModal
          message={message || ""}
          type="error"
        />,
        {
          autoClose: 1000,
        }
      );
    }
  }, [location.search]);

  return (
    <div>
        <Navbar />
        <Banner />
        <Footer />
    </div>
  )
}

export default HomePage;
import styled from "styled-components";
import { fontFamilyEnnVisionsRegular, lightDark2, pureDark2, tertiaryGrey24 } from "../GlobalStyle";

export const CustomDatePickerContainer = styled.div`
    label{
        text-transform: capitalize;
        color: ${pureDark2};
        font-weight: 400;
        display: block;
        margin-bottom: 7px;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        font-family: ${fontFamilyEnnVisionsRegular};
    }



    .ant-picker-input{
        input{
            color: ${lightDark2};
            font-family: ${fontFamilyEnnVisionsRegular};
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &::placeholder{
                color: ${lightDark2};
                font-weight: 400;
                font-family: ${fontFamilyEnnVisionsRegular};
                font-size: 16px;
            }
        }
    }
    .ant-picker.customdatepicker{
        width: 100%;
        height: 50px;
        border-radius: 10px; 
        border: 1px solid ${tertiaryGrey24};
    }
        .ant-picker {
    padding: 8px 8px !important;
}
`
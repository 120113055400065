import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyRegular } from "../../components/GlobalStyle";


export const EventTicketStyle = styled.div`

.MapFrame {
    width: 100%;
    margin-top: 19px;
}
.MapFrame img {
    width: 100%;
    height: auto;
}
.FeauterdEvenetsButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    width: 88%;
    margin: 40px auto 0;
}
.headingMainL h2 {
    color: #1A4754;
    font-family: Cream Opera;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.headingMainL h2 span {
    color: #3D86AF;
    font-family: Cream Opera;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.buttonsMainR {
    display: flex;
    align-items: center;
}
.displayMap_toggle {
    width: auto;
    height: 52px;
    display: inline-flex;
    padding: 15px 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    border-radius: 8px;
    border: 1px solid #3D86AF;
    background-color: #FFF;
    margin-right: 16px;
}
.displayMap_toggle button {
    color: #232837;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.14px;
    text-transform: uppercase;
    background-color: #FFF;
}
.toggleButton {
    position: relative;
}
.toggleMove {
    position: absolute;
    top: 5px;
    right: 0px;
}
}
.pastEventsButton button{
    display: inline-flex;
    height: 52px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    background-color: #5D7081;
    color: #FFF;
    text-align: center;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-right: 14px;
}
.createEventsButton button {
    display: inline-flex;
    height: 52px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background-color:  #DEF2E2;
    color: #000;
    text-align: center;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}
.ticketListingMain {
    width: 88%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 13px 1%;
    margin: 30px auto 40px;
}
.ticketListing {
    max-width: 24%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
`;
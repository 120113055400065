import styled from "styled-components";
import { fontFamilyRegularUber, lightDark2, pureDark2, tertiaryGrey12 } from "../../components/GlobalStyle";

export const ParticipantCardStyle = styled.div`

.participateCard {
    max-width:380px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #F4F4F4;
    background-color: #FFF;
    display: flex;

    .participateImage {
        width: 17%;
        img {
            width: 65px;
            height: 94px;
        }
    }

    .participateDetailsMain{
        border-box: box-sizing;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 4px 10px;
    }
    .participateName {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            p {
                color: ${pureDark2};
                font-family: ${fontFamilyRegularUber};
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.444px;
                margin-bottom: 0;
            }
            .blueDot {
                width: 10.287px;
                height: 10.287px;
                border-radius: 30.861px;
                background-color: #0070E0; 
                margin-left: 4.23px;  
            }
        }
        span {
            color: ${lightDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.444px;
        }
    }
    .participateAddress{
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            width: 60%;
            color: ${pureDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.444px;
            text-align: left;
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        span {
            width: 50%;
            color: ${lightDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.444px;
            text-align: right;
        }
    }

    .ageCountry_Main {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .ageCountryFlag{
        p {
            color: ${pureDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.444px;
            margin-bottom: 0;
        }
    }
    span {
        color: ${lightDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.444px;
        padding: 0 11px 0 15px;
    }

    .ageCountryFlag {
        display: flex;
        align-items: center;
    }
    .ageCountryFlag img {
        width: 18px;
        height: 12px;
        margin-left: 6.01px;
    }
}



`;
import * as Yup from "yup";

export const SCREEN_LABEL_KEYS = {
  title: "title",
  subtitle: "subtitle",
  firstNameFieldTitle: "firstNameFieldTitle",
  firstNameFieldPlaceholder: "firstNameFieldPlaceholder",
  surNameFieldTitle: "surNameFieldTitle",
  surNameFieldPlaceholder: "surNameFieldPlaceholder",
  usernameFieldTitle: "usernameFieldTitle",
  usernameFieldPlaceholder: "usernameFieldPlaceholder",
  emailFieldTitle: "emailFieldTitle",
  emailFieldPlaceholder: "emailFieldPlaceholder",
  mobileFieldTitle: "mobileFieldTitle",
  mobileFieldPlaceholder: "mobileFieldPlaceholder",
  passcodeFieldTitle: "passcodeFieldTitle",
  passcodeFieldPlaceholder: "passcodeFieldPlaceholder",
  confrimPasscodeFieldTitle: "confrimPasscodeFieldTitle",
  confrimPasscodeFieldPlaceholder: "confrimPasscodeFieldPlaceholder",
  rememberMe: "rememberMe",
  registerButton: "registerButton",
  or: "or",
  google: "google",
  facebook: "facebook",
  apple: "apple",
  microsoft: "microsoft",
  discord: "discord",
  legalNote: "legalNote",
  login: "login",
  loginAccount: "loginAccount",
};

// create user initial values types
export type initialValuesType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword?: string;
};

// initial values for formik
export const initialValues: initialValuesType = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
};

export const REGISTER_YUP = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),

  email: Yup.string().email("Email in-valid").required("Email is required"),
  phoneNumber: Yup.number().test(
    "len",
    "Phone number must be 10 characters long",
    (val: any) => val?.toString().length >= 12 && val?.toString().length <= 13
  ),
  password: Yup.string()
    .min(8, "Password must be 8 characters long")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Confirm password must be same as password"
      ),
    })
    .min(8, "Confirm password must be 8 characters long")
    .required("Confirm password is required"),
});

import React from "react";
import { Routes, Route } from "react-router-dom";
import { ErrorPage404 } from "../screens/pages";
import ForgetPassword from "../screens/ForgotPassword/ForgotPasword";
import Otp from "../screens/ForgotPassword/Otp/Otp";
import CreatePassword from "../screens/ForgotPassword/CreatePassword/CreatePassword";

// import { setLanguage } from "../redux/features/selectedLanguageSlice";
import AppLayout from "../components/Layout/Layout";
// import CreateSchool from "../screens/CreateSchool/CreateSchool";
// import AddPaymentSchool from "../screens/CreateSchool/AddPaymentSchool/AddPaymentSchool";
// import EditSchool from "../screens/CreateSchool/EditSchool/EditSchool";
// import ViewSchool from "../screens/CreateSchool/ViewSchool/ViewSchool";
import TraditionalScoreBoard from "../screens/ScoreBoard/TraditionalScroreBoard/TraditionalScroreBoard";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import UltmScoreBoard from "../screens/ScoreBoard/UltmScoreBoard/UltmScoreBoard";
import IbjjfScoreBoard from "../screens/ScoreBoard/IbjjfScoreBoard/IbjjfScoreBoard";
import TraditionalAdcc from "../screens/ScoreBoard/TraditionalAdcc/TraditionalAdcc";
import AdccIbjjfScoreBoard from "../screens/ScoreBoard/AdccIbjjfScoreBoard/AdccIbjjfScoreBoard";
import AdccUltmScoreBoard from "../screens/ScoreBoard/AdccUltmScoreBoard/AdccUltmScoreBoard";
import OrganizeScoreBoardMonitor from "../screens/ScoreBoard/OrganizeScoreBoardMonitor/OrganizeScoreBoardMonitor";
// import Banner from "../components/Banner/Banner";
import IbjjfUltmScoreBoard from "../screens/ScoreBoard/IbjjfUltmScoreBoard/IbjjfUltmScoreBoard";
import HomePage from "../screens/Homepage/HomePage";
import LayoutCommon from "../components/LayoutCommon/LayoutCommon";
import EventTicket from "../screens/EventTicket/EventTicket";
import AjpTraditionalScoreBoard from "../screens/AjpScoreBoard/AjpTraditionalScoreBoard/AjpTraditionalScoreBoard";
// import AjpTraditionalScoreBoard from "../screens/AjpScoreBoard/TraditionalAdccScroreBoard/TraditionalAdccScroreBoard";
import TicketInformation from "../screens/TicketInformation/TicketInformation";
import AjpADCCScoreBoard from "../screens/AjpScoreBoard/AjpADCCScoreBoard/AjpADCCScoreBoard";
import SubOnly from "../screens/SubOnlyScoreBoard/SubOnly/SubOnly";
import SubUltm from "../screens/SubOnlyScoreBoard/SubUltm/SubUltm";
import SubUlti from "../screens/SubOnlyScoreBoard/SubUlti/SubUlti";

import ProfileBeforeLoginMainPage from "../screens/ProfileBeforeLogin/ProfileBeforeLoginMain/ProfileBeforeLoginMain";
import TestingComponents from "../components/TestingComponents/TestingComponents";
import ParticipantsProfileSingleAcademy from "../screens/ProfileBeforeLogin/ParticipantsProfileSingleAcademy/ParticipantsProfileSingleAcademy";
import { ParticipantsProfileMultiAcademies } from "../screens/ProfileBeforeLogin/ParticipantsProfileMultiAcademies/ParticipantsProfileMultiAcademies";
import AcademyUsersProfiles from "../screens/ProfileBeforeLogin/AcademyUsersProfiles/AcademyUsersProfiles";
import AcademyProfilePastEvent from "../screens/ProfileBeforeLogin/AcademyProfilePastEvent/AcademyProfilePastEvent";
import AcademyProfileUpcomingEvent from "../screens/ProfileBeforeLogin/AcademyProfileUpcomingEvent/AcademyProfileUpcomingEvent";
import AffiliationsProfile from "../screens/ProfileBeforeLogin/AffiliationsProfile/AffiliationsProfile";
import AffiliationsProfileSeeAllAssociatedAcademies from "../screens/ProfileBeforeLogin/AffiliationsProfileSeeAllAssociatedAcademies/AffiliationsProfileSeeAllAssociatedAcademies";
import AffiliationsProfilePastEvent from "../screens/ProfileBeforeLogin/AffiliationsProfilePastEvent/AffiliationsProfilePastEvent";
import AcademyProfile from "../screens/ProfileBeforeLogin/AcademyProfile/AcademyProfile";
import AffiliationsProfileUpcomingEvent from "../screens/ProfileBeforeLogin/AffiliationsProfileUpcomingEvent/AffiliationsProfileUpcomingEvent";
import AffiliationsProfileOverallAcademyAdults from "../screens/ProfileBeforeLogin/AffiliationsProfileOverallAcademyAdults/AffiliationsProfileOverallAcademyAdults";

import ProfileAfterLoginMain from "../screens/ProfileAfterLogin/ProfileAfterLoginMain/ProfileAfterLoginMain";
import AllMatTvMode from "../screens/AllMatTvMode/AllMatTvMode";
import TraditionalScoreBoardMonitor from "../screens/ScoreBoard/TraditionalScroreBoard/TraditionalScroreBoardMonitor";
import CoachProfile from "../screens/CoachProfile/CoachProfile/CoachProfile";
import OrganizerProfile from "../screens/OrganizerProfile/OrganizerProfile";
import BuyTicketsSelectUser from "../screens/BuyTickets/BuyTicketsSelectUser/BuyTicketsSelectUser";
import BuyTicketsSelectUser2 from "../screens/BuyTickets/BuyTicketsSelectUser2/BuyTicketsSelectUser2";
import BuyTicketsSelectUser3 from "../screens/BuyTickets/BuyTicketsSelectUser3/BuyTicketsSelectUser3";
import BuyTicketsSelectUser4 from "../screens/BuyTickets/BuyTicketsSelectUser4/BuyTicketsSelectUser4";
import OrganizerAllEntriesClasses from "../screens/AllEntries/OrganizerAllEntriesClasses/OrganizerAllEntriesClasses";
import OrganizerAllEntriesEditValueSettings from "../screens/AllEntries/OrganizerAllEntriesEditValueSettings/OrganizerAllEntriesEditValueSettings";
import OrganizerAllEntriesList from "../screens/AllEntries/OrganizerAllEntriesList/OrganizerAllEntriesList";
import OrganizerAllEntriesValueSettings from "../screens/AllEntries/OrganizerAllEntriesValueSettings/OrganizerAllEntriesValueSettings";
import OrganizerAllEntriesValueSettings2 from "../screens/AllEntries/OrganizerAllEntriesValueSettings2/OrganizerAllEntriesValueSettings2";
import OrganizerCreateAnEventCoupon from "../screens/AllEntries/OrganizerCreateAnEventCoupon/OrganizerCreateAnEventCoupon";
import OrganizerCreateAnEventCreateCoupon from "../screens/AllEntries/OrganizerCreateAnEventCreateCoupon/OrganizerCreateAnEventCreateCoupon";
import OrganizerCreateAnEventDiscount from "../screens/AllEntries/OrganizerCreateAnEventDiscount/OrganizerCreateAnEventDiscount";
import OrganizerCreateAnEventRules from "../screens/AllEntries/OrganizerCreateAnEventRules/OrganizerCreateAnEventRules";
import OrganizerCreateAnEventTerms from "../screens/AllEntries/OrganizerCreateAnEventTerms/OrganizerCreateAnEventTerms";

import OrganizerCreateAnEventTerms1 from "../screens/CreateEventContent/OrganizerCreateAnEventTerms/OrganizerCreateAnEventTerms";
import CreateEvent from "../screens/CreateEventContent/organizerCreateAnEventCoupon1/organizerCreateAnEventCoupon1";
import OrganizerCreateAnEventEditBrackets from "../screens/CreateEventContent/OrganizerCreateAnEventEditBrackets/OrganizerCreateAnEventEditBrackets";
import OrganizerCreateAnEventBrackets from "../screens/CreateEventContent/OrganizerCreateAnEventBrackets/OrganizerCreateAnEventBrackets";

import OrganizerCreateAnEvent from "../screens/CreateEventContent/OrganizerCreateAnEvent/OrganizerCreateAnEvent";
import OrganizerSettingBracketList from "../screens/OrganizerSettingContent/OrganizerSettingBracketList/OrganizerSettingBracketList";
import OrganizerSettingEventTopLists from "../screens/OrganizerSettingContent/OrganizerSettingEventTopLists/OrganizerSettingEventTopLists";
import OrganizerSettingStatisticsRegistration from "../screens/OrganizerSettingContent/OrganizerSetting/OrganizerSetting";
import OrganizerCreateAnEventRegistration from "../screens/CreateEventContent/OrganizerCreateAnEventRegistration/OrganizerCreateAnEventRegistration";
import OrganizerCreateAnEventEventTemplate from "../screens/CreateEventContent/OrganizerCreateAnEventEventTemplate/OrganizerCreateAnEventEventTemplate";
import UltmScoreBoardMonitor from "../screens/ScoreBoard/UltmScoreBoard/UltmScoreBoardMonitor";
import TraditionalAdccMonitor from "../screens/ScoreBoard/TraditionalAdcc/TraditionalAdccMonitor";
import IbjjfUltmScoreBoardMonitor from "../screens/ScoreBoard/IbjjfUltmScoreBoard/IbjjfUltmScoreBoardMonitor";
import AdccUltmScoreBoardMonitor from "../screens/ScoreBoard/AdccUltmScoreBoard/AdccUltmScoreBoardMonitor";
import AdccIbjjfScoreBoardMonitor from "../screens/ScoreBoard/AdccIbjjfScoreBoard/AdccIbjjfScoreBoardMonitor";
import OrganizerSettingBrackets from "../screens/OrganizerSettingContent/OrganizerSettingBrackets/OrganizerSettingBrackets";
import OrganizeSettingEditBracket from "../screens/OrganizerSettingContent/OrganizeSettingEditBracket/OrganizeSettingEditBracket";
import OrganizerSettingTiebreakerSettings from "../screens/OrganizerSettingContent/OrganizerSettingTiebreakerSettings/OrganizerSettingTiebreakerSettings";
import OrganizerSettingPlacementPoints from "../screens/OrganizerSettingContent/OrganizerSettingPlacementPoints/OrganizerSettingPlacementPoints";
import OrganizerSettingCreateNewTopList from "../screens/OrganizerSettingContent/OrganizerSettingCreateNewTopList/OrganizerSettingCreateNewTopList";
import OrganizerSettingCreateCategory from "../screens/OrganizerSettingContent/OrganizerSettingCreateCategory/OrganizerSettingCreateCategory";
import OrganizerSettingCategories from "../screens/OrganizerSettingContent/OrganizerSettingCategories/OrganizerSettingCategories";
import OrganizerSettingPlacementPointsOverRide from "../screens/OrganizerSettingContent/OrganizerSettingPlacementPointsOverRide/OrganizerSettingPlacementPointsOverRide";

import OrganizerCreateAnEventDiscounts from "../screens/CreateEventContent/OrganizerCreateAnEventDiscount/OrganizerCreateAnEventDiscount";

import OrganizerCreateAnEventRules1 from "../screens/CreateEventContent/OrganizerCreateAnEventRules/OrganizerCreateAnEventRules";
import IbjjfScoreBoardMonitor from "../screens/ScoreBoard/IbjjfScoreBoard/IbjjfScoreBoardMonitor";
import SubOnlyMonitor from "../screens/SubOnlyScoreBoard/SubOnly/SubOnlyMonitor";
import SubUltiMonitor from "../screens/SubOnlyScoreBoard/SubUlti/SubUltiMonitor";
import SubUltmMonitor from "../screens/SubOnlyScoreBoard/SubUltm/SubUltmMonitor";
import AjpADCCScoreBoardMonitor from "../screens/AjpScoreBoard/AjpADCCScoreBoard/AjpADCCScoreBoardMonitor";
import AjpTraditionalScoreBoardMonitor from "../screens/AjpScoreBoard/AjpTraditionalScoreBoard/AjpTraditionalScoreBoardMonitor";
import AcademyUpdate from "../screens/Academy/AcademyUpdate/AcademyUpdate";
import AcademyCreate from "../screens/Academy/AcademyCreate/AcademyCreate";
import AcademyList from "../screens/Academy/AcademyList/AcademyList";
import AcademyView from "../screens/Academy/AcademyView/AcademyView";
import BranchCreate from "../screens/Branch/BranchCreate/BranchCreate";
import BranchUpdate from "../screens/Branch/BranchUpdate/BranchUpdate";
import BranchList from "../screens/Branch/BranchList/BranchList";
import BranchView from "../screens/Branch/BranchView/BranchView";
import AffiliateCreate from "../screens/Affiliate/AffiliateCreate/AffiliateCreate";
import AffiliateUpdate from "../screens/Affiliate/AffiliateUpdate/AffiliateUpdate";
import AffiliateView from "../screens/Affiliate/AffiliateView/AffiliateView";
import AffiliateList from "../screens/Affiliate/AffiliateList/AffiliateList";
import CoachCreate from "../screens/Coach/CoachCreate/CoachCreate";
import CoachUpdate from "../screens/Coach/CoachUpdate/CoachUpdate";
import CoachView from "../screens/Coach/CoachView/CoachView";
import CoachList from "../screens/Coach/CoachList/CoachList";
import Layout from "../components/Layout/Layout";
import ResetPasswordConfirmation from "../screens/ResetPasswordConfirmation/ResetPasswordConfirmation";
import AcademyProfileUpcomingMatches from "../screens/ProfileBeforeLogin/AcademyProfileUpcomingMatches/AcademyProfileUpcomingMatches";
import BranchListCards from "../screens/Branch/BranchList/BranchCards";
import AcademyListCards from "../screens/Academy/AcademyList/AcademyCards";
import AffiliateListCards from "../screens/Affiliate/AffiliateList/AffiliateCards";
import AcademyForAffiliation from "../screens/Academy/AcademyForAffiliation/AcademyForAffiliation";
import StudentsAllCards from "../screens/ProfileBeforeLogin/StudentsAll/StudentsAll";
import StudentStatus from "../screens/ProfileBeforeLogin/StudentStatus/StudentStatus/StudentStatus";
import StudentActive from "../screens/ProfileBeforeLogin/StudentStatus/StudentActive/StudentActive";
import StudentPending from "../screens/ProfileBeforeLogin/StudentStatus/StudentPending/StudentPending";
import AffiliateAllStatus from "../screens/AffiliateStatus/AffiliateAllStatus/AffiliateAllStatus";
import AffiliatePending from "../screens/AffiliateStatus/AffiliatePending/AffiliatePending";
import AffiliateActive from "../screens/AffiliateStatus/AffilaiteActive/AffiliateActive";


function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<HomePage />} />

        <Route
          path={"/reset-password-confirmation"}
          element={
            <LayoutCommon>
              <ResetPasswordConfirmation />
            </LayoutCommon>
          }
        />

        {/* Academy Routing */}
        <Route
          path={"/academy/create"}
          element={
            <LayoutCommon>
              <AcademyCreate />
            </LayoutCommon>
          }
        />
        <Route
          path={"/academy/update/:academyId"}
          element={
            <LayoutCommon>
              <AcademyUpdate />
            </LayoutCommon>
          }
        />
        <Route
          path={"/academy/list"}
          element={
            <LayoutCommon>
              <AcademyListCards />
            </LayoutCommon>
          }
        />
        <Route
          path={"/academy/view/:academyId"}
          element={
            <LayoutCommon>
              <AcademyView />
            </LayoutCommon>
          }
        />
        <Route
          path={"/academy/for-affiliation"}
          element={
            <LayoutCommon>
              <AcademyForAffiliation />
            </LayoutCommon>
          }
        />
        <Route
          path="/academy/my-profile"
          element={
            <LayoutCommon>
              <AcademyProfile />
            </LayoutCommon>
          }
        />
           <Route
          path="/academy/my-profile/upcoming-event"
          element={
            <LayoutCommon>
              <AcademyProfileUpcomingEvent />
            </LayoutCommon>
          }
        />
         <Route
          path="/academy/my-profile/past-event"
          element={
            <LayoutCommon>
              <AcademyProfilePastEvent />
            </LayoutCommon>
          }
        />
         <Route
          path="/academy/my-profile/students"
          element={
            <LayoutCommon>
              <StudentsAllCards />
            </LayoutCommon>
          }
        />
         <Route
          path="/academy/my-profile/students/affiliation"
          element={
            <LayoutCommon>
              <StudentStatus />
            </LayoutCommon>
          }
        />
         <Route
          path="/academy/my-profile/students/affiliation/active"
          element={
            <LayoutCommon>
              <StudentActive />
            </LayoutCommon>
          }
        />
         <Route
          path="/academy/my-profile/students/affiliation/pending"
          element={
            <LayoutCommon>
              <StudentPending />
            </LayoutCommon>
          }
        />
        <Route
          path="/academy/my-profile/affiliate-status"
          element={
            <LayoutCommon>
              <AffiliateAllStatus />
            </LayoutCommon>
          }
        />
        <Route
          path="/academy/my-profile/affiliate-status/active"
          element={
            <LayoutCommon>
              <AffiliateActive />
            </LayoutCommon>
          }
        />
        <Route
          path="/academy/my-profile/affiliate-status/pending"
          element={
            <LayoutCommon>
              <AffiliatePending />
            </LayoutCommon>
          }
        />

        {/* Branch Routing */}
        <Route
          path={"/branch/create"}
          element={
            <LayoutCommon>
              <BranchCreate />
            </LayoutCommon>
          }
        />
        <Route
          path={"/branch/update/:branchId"}
          element={
            <LayoutCommon>
              <BranchUpdate />
            </LayoutCommon>
          }
        />
        <Route
          path={"/branch/list"}
          element={
            <LayoutCommon>
              <BranchListCards />
            </LayoutCommon>
          }
        />
        <Route
          path={"/branch/view/:branchId"}
          element={
            <LayoutCommon>
              <BranchView />
            </LayoutCommon>
          }
        />

        {/* Affiliate Routing */}
        <Route
          path={"/affiliate/create"}
          element={
            <LayoutCommon>
              <AffiliateCreate />
            </LayoutCommon>
          }
        />
        <Route
          path={"/affiliate/update/:affiliateId"}
          element={
            <LayoutCommon>
              <AffiliateUpdate />
            </LayoutCommon>
          }
        />
        <Route
          path={"/affiliate/view/:affiliateId"}
          element={
            <LayoutCommon>
              <AffiliateView />
            </LayoutCommon>
          }
        />
        <Route
          path={"/affiliate/list"}
          element={
            <LayoutCommon>
              <AffiliateListCards />
            </LayoutCommon>
          }
        />

        {/* Coach Routing */}
        <Route
          path={"/coach/create"}
          element={
            <LayoutCommon>
              <CoachCreate />
            </LayoutCommon>
          }
        />
        <Route
          path={"/coach/update/:coachId"}
          element={
            <LayoutCommon>
              <CoachUpdate />
            </LayoutCommon>
          }
        />
        <Route
          path={"/coach/view/:coachId"}
          element={
            <LayoutCommon>
              <CoachView />
            </LayoutCommon>
          }
        />
        <Route
          path={"/coach/list"}
          element={
            <LayoutCommon>
              <CoachList />
            </LayoutCommon>
          }
        />

        <Route
          path="/score-board/traditional-score-board"
          element={<TraditionalScoreBoard />}
        />
        <Route
          path="/score-board/traditional-score-board-monitor"
          element={<TraditionalScoreBoardMonitor />}
        />
        {/*  */}
        <Route
          path="/score-board/ultm-score-board"
          element={<UltmScoreBoard />}
        />
        <Route
          path="/score-board/ultm-score-board-monitor"
          element={<UltmScoreBoardMonitor />}
        />
        {/*  */}
        <Route
          path="/score-board/ibjjf-score-board"
          element={<IbjjfScoreBoard />}
        />
        <Route
          path="/score-board/ibjjf-score-board-monitor"
          element={<IbjjfScoreBoardMonitor />}
        />
        {/*  */}
        <Route
          path="/score-board/traditional-adcc-score-board"
          element={<TraditionalAdcc />}
        />
        <Route
          path="/score-board/traditional-adcc-score-board-monitor"
          element={<TraditionalAdccMonitor />}
        />
        {/*  */}
        <Route
          path="/score-board/adcc-ibjjf-score-board"
          element={<AdccIbjjfScoreBoard />}
        />
        <Route
          path="/score-board/adcc-ibjjf-score-board-monitor"
          element={<AdccIbjjfScoreBoardMonitor />}
        />
        {/*  */}
        <Route
          path="/score-board/adcc-ultm-score-board"
          element={<AdccUltmScoreBoard />}
        />
        <Route
          path="/score-board/adcc-ultm-score-board-monitor"
          element={<AdccUltmScoreBoardMonitor />}
        />
        {/*  */}
        <Route
          path="/score-board/ibjjf-ultm-score-board"
          element={<IbjjfUltmScoreBoard />}
        />
        <Route
          path="/score-board/ibjjf-ultm-score-board-monitor"
          element={<IbjjfUltmScoreBoardMonitor />}
        />
        {/* AJP Traditional */}
        <Route
          path="/ajp-board/ajp-traditional-score-board"
          element={<AjpTraditionalScoreBoard />}
        />
        <Route
          path="/ajp-board/ajp-traditional-score-board-monitor"
          element={<AjpTraditionalScoreBoardMonitor />}
        />
        {/* AJP Adcc */}
        <Route
          path="/ajp-board/ajp-adcc-score-board"
          element={<AjpADCCScoreBoard />}
        />
        <Route
          path="/ajp-board/ajp-adcc-score-board-monitor"
          element={<AjpADCCScoreBoardMonitor />}
        />
        {/* Sub Only */}
        <Route path="/sub-only/sub-only" element={<SubOnly />} />
        <Route path="/sub-only/sub-only-monitor" element={<SubOnlyMonitor />} />
        {/* Sub Ultm */}
        <Route path="/sub-only/sub-ultm" element={<SubUltm />} />
        <Route path="/sub-only/sub-ultm-monitor" element={<SubUltmMonitor />} />
        {/* Sub Ulti */}
        <Route path="/sub-only/sub-ulti" element={<SubUlti />} />
        <Route path="/sub-only/sub-ulti-monitor" element={<SubUltiMonitor />} />
        {/* Event Ticket */}
        <Route
          path="/event-ticket"
          element={
            <LayoutCommon>
              <EventTicket />
            </LayoutCommon>
          }
        />
        <Route
          path="/ticket-information"
          element={
            <LayoutCommon>
              <TicketInformation />
            </LayoutCommon>
          }
        />
        <Route
          path="/all-mat-tv-mode"
          element={
            <LayoutCommon>
              <AllMatTvMode />
            </LayoutCommon>
          }
        />
        <Route
          path="/profile-before-login"
          element={
            <LayoutCommon>
              <ProfileBeforeLoginMainPage />
            </LayoutCommon>
          }
        />
        <Route
          path="/testing-components"
          element={
            <LayoutCommon>
              <TestingComponents />
            </LayoutCommon>
          }
        />
        <Route
          path="/profile-before-login/participants-profile-multi-academies"
          element={
            <LayoutCommon>
              <ParticipantsProfileMultiAcademies />
            </LayoutCommon>
          }
        />
        <Route
          path="/profile-before-login/participants-profile-single-academy"
          element={
            <LayoutCommon>
              <ParticipantsProfileSingleAcademy />
            </LayoutCommon>
          }
        />


        <Route
          path="/profile-before-login/academy-users-profiles"
          element={
            <LayoutCommon>
              <AcademyUsersProfiles />
            </LayoutCommon>
          }
        />
       
     
        <Route
          path="/profile-before-login/academy-profile-upcoming-matches"
          element={
            <LayoutCommon>
              <AcademyProfileUpcomingMatches />
            </LayoutCommon>
          }
        />
        <Route
          path="/profile-before-login/affiliations-profile"
          element={
            <LayoutCommon>
              <AffiliationsProfile />
            </LayoutCommon>
          }
        />
        <Route
          path="/profile-before-login/affiliations-profile-see-all-associated-academies"
          element={
            <LayoutCommon>
              <AffiliationsProfileSeeAllAssociatedAcademies />
            </LayoutCommon>
          }
        />
        <Route
          path="/profile-before-login/affiliations-profile-past-event"
          element={
            <LayoutCommon>
              <AffiliationsProfilePastEvent />
            </LayoutCommon>
          }
        />
        <Route
          path="/profile-before-login/affiliations-profile-up-coming-event"
          element={
            <LayoutCommon>
              <AffiliationsProfileUpcomingEvent />
            </LayoutCommon>
          }
        />
        <Route
          path="/profile-before-login/affiliations-profile-over-all-academy-adults"
          element={
            <LayoutCommon>
              <AffiliationsProfileOverallAcademyAdults />
            </LayoutCommon>
          }
        />
        <Route
          path="/profile-after-login"
          element={
            <LayoutCommon>
              <ProfileAfterLoginMain />
            </LayoutCommon>
          }
        />
        {/* Coach Profile */}
        <Route
          path="/coach-profile"
          element={
            <LayoutCommon>
              <CoachProfile />
            </LayoutCommon>
          }
        />
        {/* organizer Profile */}
        <Route
          path="/organizer-profile"
          element={
            <LayoutCommon>
              <OrganizerProfile />
            </LayoutCommon>
          }
        />
        {/* BuyTickets */}
        <Route
          path="/buy-tickets/buy-tickets-select-user"
          element={
            <LayoutCommon>
              <BuyTicketsSelectUser />
            </LayoutCommon>
          }
        />
        <Route
          path="/buy-tickets/buy-tickets-select-user2"
          element={
            <LayoutCommon>
              <BuyTicketsSelectUser2 />
            </LayoutCommon>
          }
        />
        <Route
          path="/buy-tickets/buy-tickets-select-user3"
          element={
            <LayoutCommon>
              <BuyTicketsSelectUser3 />
            </LayoutCommon>
          }
        />
        <Route
          path="/buy-tickets/buy-tickets-select-user4"
          element={
            <LayoutCommon>
              <BuyTicketsSelectUser4 />
            </LayoutCommon>
          }
        />
        {/* All Enteries */}
        <Route
          path="/all-enteries/organizer-all-entries-classes"
          element={
            <LayoutCommon>
              <OrganizerAllEntriesClasses />
            </LayoutCommon>
          }
        />
        <Route
          path="/all-enteries/organizer-all-entries-edit-value-settings"
          element={
            <LayoutCommon>
              <OrganizerAllEntriesEditValueSettings />
            </LayoutCommon>
          }
        />
        <Route
          path="/all-enteries/organizer-all-entries-list"
          element={
            <LayoutCommon>
              <OrganizerAllEntriesList />
            </LayoutCommon>
          }
        />
        <Route
          path="/all-enteries/organizer-all-entries-value-settings"
          element={
            <LayoutCommon>
              <OrganizerAllEntriesValueSettings />
            </LayoutCommon>
          }
        />
        <Route
          path="/all-enteries/organizer-all-entries-value-settings2"
          element={
            <LayoutCommon>
              <OrganizerAllEntriesValueSettings2 />
            </LayoutCommon>
          }
        />
        <Route
          path="/all-enteries/organizer-create-an-event-coupon"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventCoupon />
            </LayoutCommon>
          }
        />
        <Route
          path="/all-enteries/organizer-create-an-create-coupon"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventCreateCoupon />
            </LayoutCommon>
          }
        />
        <Route
          path="/all-enteries/organizer-create-an-event-discount"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventDiscount />
            </LayoutCommon>
          }
        />
        <Route
          path="/all-enteries/organizer-create-an-event-rules"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventRules />
            </LayoutCommon>
          }
        />
        <Route
          path="/all-enteries/organizer-create-an-event-terms"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventTerms />
            </LayoutCommon>
          }
        />
        {/* 
        <Route
          path="/organizer-create-an-event-registration"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventRegistration />
            </LayoutCommon>
          }
        /> */}
        {/* <Route
          path="/organizer-create-an-event-coupon"
          element={
            <LayoutCommon>
              <CreateEvent />
            </LayoutCommon>
          }
        /> */}

        {/* Create Event */}
        <Route
          path="/create-event/organizer-create-an-event"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEvent />
            </LayoutCommon>
          }
        />
        <Route
          path="/create-event/organizer-create-an-event-template"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventEventTemplate />
            </LayoutCommon>
          }
        />
        <Route
          path="/create-event/organizer-create-an-event-coupon"
          element={
            <LayoutCommon>
              <CreateEvent />
            </LayoutCommon>
          }
        />
        <Route
          path="/create-event/organizer-create-an-event-registration"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventRegistration />
            </LayoutCommon>
          }
        />
        <Route
          path="/create-event/organizer-create-an-event-discount"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventDiscounts />
            </LayoutCommon>
          }
        />
        <Route
          path="/create-event/organizer-create-an-event-edit-brackets"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventEditBrackets />
            </LayoutCommon>
          }
        />
        <Route
          path="/create-event/organizer-create-an-event-brackets"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventBrackets />
            </LayoutCommon>
          }
        />
        <Route
          path="/create-event/organizer-create-an-event-terms"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventTerms1 />
            </LayoutCommon>
          }
        />

        <Route
          path="/create-event/organizer-create-an-event-rules"
          element={
            <LayoutCommon>
              <OrganizerCreateAnEventRules1 />
            </LayoutCommon>
          }
        />

        {/* Organizer Setting */}
        <Route
          path="/organizer-setting/organizer-setting-brackets"
          element={
            <LayoutCommon>
              <OrganizerSettingBrackets />
            </LayoutCommon>
          }
        />
        <Route
          path="/organizer-setting/organizer-setting-bracket-list"
          element={
            <LayoutCommon>
              <OrganizerSettingBracketList />
            </LayoutCommon>
          }
        />
        <Route
          path="/organizer-setting/organizer-setting-edit-bracket"
          element={
            <LayoutCommon>
              <OrganizeSettingEditBracket />
            </LayoutCommon>
          }
        />
        <Route
          path="/organizer-setting/organizer-setting-tiebreaker-setting"
          element={
            <LayoutCommon>
              <OrganizerSettingTiebreakerSettings />
            </LayoutCommon>
          }
        />
        <Route
          path="/organizer-setting/organizer-setting-event-top-lists"
          element={
            <LayoutCommon>
              <OrganizerSettingEventTopLists />
            </LayoutCommon>
          }
        />
        <Route
          path="/organizer-setting/organizer-setting-placement-points"
          element={
            <LayoutCommon>
              <OrganizerSettingPlacementPoints />
            </LayoutCommon>
          }
        />
        <Route
          path="/organizer-setting/organizer-setting-placement-points-over-ride"
          element={
            <LayoutCommon>
              <OrganizerSettingPlacementPointsOverRide />
            </LayoutCommon>
          }
        />
        <Route
          path="/organizer-setting/organizer-setting-create-new-top-list"
          element={
            <LayoutCommon>
              <OrganizerSettingCreateNewTopList />
            </LayoutCommon>
          }
        />
        <Route
          path="/organizer-setting/organizer-setting-create-category"
          element={
            <LayoutCommon>
              <OrganizerSettingCreateCategory />
            </LayoutCommon>
          }
        />
        <Route
          path="/organizer-setting/organizer-setting-categories"
          element={
            <LayoutCommon>
              <OrganizerSettingCategories />
            </LayoutCommon>
          }
        />
        <Route
          path="/organizer-setting/organizer-setting-statistics-registration"
          element={
            <LayoutCommon>
              <OrganizerSettingStatisticsRegistration />
            </LayoutCommon>
          }
        />

        {/* error page */}
        <Route path="*" element={<ErrorPage404 />} />
      </Routes>
    </>
  );
}

export default AppRoutes;

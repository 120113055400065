import React from "react";
import {
  FormFieldStyling,
  OrganizerCreateAnEventCreateCouponStyle,
} from "./style";

import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";
import FormControl from "../../../components/FormControl";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import {
  fontFamilyEnnVisionsMedium,
  fontFamilyEnnVisionsRegular,
  fontFamilyRegular,
  mediumPurple,
  whiteColor,
} from "../../../components/GlobalStyle";
import GapCurrencyIcon from "../../../assets/icons/is_GBP.svg";
import DeleteIcon from "../../../assets/icons/ic_deleteDark.svg";
import CustomButton from "../../../components/CustomButton/CustomButton";

const { Content } = Layout;

const OrganizerCreateAnEventCreateCoupon = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };

  const handleCreateSubmit = () => {};

  return (
    <div>
      <OrganizerCreateAnEventCreateCouponStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="creatEvent-left">
                <CreatEventLeftSide />
              </Content>
              <Content className="creatEvent-right">
                <FormFieldStyling>
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={handleCreateSubmit}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className="formWrapper">
                            <h3 className="title">Add New Coupom</h3>
                            <div className="tablelist">
                              <div className="organizerSettingForm">
                                <Row>
                                  <Col md="4" className="mb-20">
                                    <FormControl
                                      control="dateTime"
                                      type="dateTime"
                                      name="max-use-ammount1"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Valid from"
                                      padding="10px"
                                      placeholder="01/12/2023 .  12:00am"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="4" className="mb-20">
                                    <FormControl
                                      control="dateTime"
                                      type="dateTime"
                                      name="max-use-ammount2"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Valid until"
                                      padding="10px"
                                      placeholder="31/12/2023 .  11:59 pm"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="4" className="mb-20">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="max-use-ammount3"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Timezone"
                                      padding="10px"
                                      placeholder="United Kingdom"
                                      suffix={
                                        <img src={GapCurrencyIcon} alt="" />
                                      }
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  {/*  */}
                                  <Col md="4" className="mb-20">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="discount-type"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Discount Type"
                                      padding="10px"
                                      placeholder="Fixed"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="4" className="mb-20">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="discount-type2"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Discount Amount"
                                      padding="10px"
                                      placeholder="20"
                                      suffix={
                                        <img src={GapCurrencyIcon} alt="" />
                                      }
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="4" className="mb-20">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="currency" // Unique name for currency field
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Currency"
                                      padding="10px"
                                      placeholder="GBP"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  {/*  */}
                                  <Col md="4" className="mb-20">
                                    <FormControl
                                      control="select"
                                      type="select"
                                      name="maxUseAmount" // Unique name for maximum use amount field
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Maximum Use Amount"
                                      padding="10px"
                                      placeholder="10"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="4" className="mb-20">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="totalCouponsAvailable" // Unique name for total coupons available field
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Total Coupons Available"
                                      padding="10px"
                                      placeholder="30,000"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="4" className="mb-20">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="daily-limit"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Daily Limit per User"
                                      padding="10px"
                                      placeholder="Christmas2023"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  {/*  */}
                                  <Col md="8" className="mb-20">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="max-ammount2"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Maximum Use Amount"
                                      padding="10px"
                                      placeholder="Christmas 2023 Coupon Discount"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                  <Col md="4" className="mb-20">
                                    <FormControl
                                      control="input"
                                      type="text"
                                      name="coupon-code"
                                      fontFamily={fontFamilyEnnVisionsRegular}
                                      label="Coupon Code"
                                      padding="10px"
                                      placeholder="Christmas2023"
                                      className={
                                        formik.errors.detail &&
                                        formik.touched.detail
                                          ? "is-invalid"
                                          : "customInput"
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>

                          <div className="mt-20 d-flex justify-content-end">
                            <CustomButton
                              bgcolor={mediumPurple}
                              textTransform="Captilize"
                              color={whiteColor}
                              padding="11px 40.50px"
                              fontFamily={`${fontFamilyEnnVisionsMedium}`}
                              width="fit-content"
                              type="submit"
                              title="Next"
                              fontSize="18px"
                              loading={false}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </FormFieldStyling>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizerCreateAnEventCreateCouponStyle>
    </div>
  );
};

export default OrganizerCreateAnEventCreateCoupon;

import styled from "styled-components";
import { fontFamilyMedium } from "../../../components/GlobalStyle";

export const OrganizerAllEntriesValueSettings2Style = styled.div`
  .ant-layout > .banner + .ant-layout {
    flex-direction: row;
    gap: 10px;
  }
  .title {
    margin-top: 
  }
  .title h3 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
  }
`;

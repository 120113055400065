import Head from "../../../components/Head/Head"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
// import useAcademy from "../Hooks/useAcademy"
import defaultPic from '../../../assets/images/create_school_user_profile.svg'
import { List, Rate } from "antd";
import placeHolderImage from "../../../assets/images/custom_card_placeholder.png";
import CardsStyle from "../AcademyList/CardsStyle"
import FormControl from "../../../components/FormControl";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { Black, RobinEggBlue, fontFamilyRegularUber } from "../../../components/GlobalStyle";
import axios from "axios";
import { base_url } from "../../../utils/api_urls";
import { AcademyDataType } from "../constant";
import api from "../../../api";


const AcademyForAffiliation = () => {
    const desc = ["terrible", "bad", "normal", "good", "wonderful"];
    const [value, setValue] = useState(3);
    const [academies, setAcademies] = useState<AcademyDataType[]|undefined>();

    useEffect(() => {
        fetchAcademies()
    }, [])

    const fetchAcademies = async () => {
        try {
            const response = await api("post", "/api/academy/findall");

            if (response.data.status === "success") {
                setAcademies(response.data.result);
            } else {
                console.error("Failed to fetch academies:", response.data.message);
            }
        } catch (error) {
            console.error("Failed to fetch academies:", error);
        }
    };

    return (
        <>
            <Head title="ULTM8 - Academy List" />

            <CardsStyle>
                <div className="header">
                    <h3>Academy List</h3>
                </div>
                <div className="cardsMainContainer">
                    {academies?.map((item, i) => {
                        return (
                            <div className="custom_card" key={i}>
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<img src={defaultPic} alt="profile" width={40} height={40} />}
                                        title={item.businessName}
                                        description={item.address}
                                    />
                                    <span>
                                        <FormControl
                                            control="checkbox"
                                            type="checkbox"
                                            id="terms"
                                            name="terms"
                                        />
                                    </span>
                                </List.Item>
                                <div className="custom_card_placeholder_img" id="cardImg">
                                    <img src={placeHolderImage} alt="CardImg" id="cardImg" />
                                </div>
                                <div className="custom_card_para">
                                    {`${item?.description.slice(0, 120)}...`}
                                </div>
                                <div className="custom_card_footer">
                                    <div className="custom_card_footer_rating">
                                        <Rate tooltips={desc} onChange={setValue} value={value} />
                                        {value ? (
                                            <span className="ant-rate-text">
                                                {/* {desc[value - 1]} */}
                                                <span>4.6</span>
                                                <span> (780+ )</span>
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <Link to={"#"} className="custom_card_footer_link">
                                        Learn More
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </div>
                    <div className="mt-20 d-flex justify-content-end">
                        <CustomButton
                            bgcolor={RobinEggBlue}
                            textTransform="uppercase"
                            color={Black}
                            padding="11px 40.50px"
                            fontFamily={fontFamilyRegularUber}
                            width="fit-content"
                            type="submit"
                            title="Submit"
                            fontSize="14px"
                            loading={false}
                        />
                    </div>
            </CardsStyle>

        </>
    )
}


export default AcademyForAffiliation
import React from "react";
import { EventScheduleStyle } from "./style";
import DropDownImage from "../../assets/images/Down_Arrow_3_.svg";
import Tvimgage from "../../assets/images/Smart TV.svg";
import BeltImage from "../../assets/images/progressbar.svg";
import ParticipantImg from "../../assets/images/ali-hassan.png";
import WarrenImg from "../../assets/images/Warren.svg";
import Engflag from "../../assets/images/EnglandFlag.svg";
import chinaflag from "../../assets/images/china.svg";
import sandglass from "../../assets/images/sandglass.svg";
import Playicon from "../../assets/images/play.svg";
import Signalicon from "../../assets/images/signals.svg";
import tickimg from "../../assets/images/tick_img.svg";
import ToggleTrack from "../../assets/icons/ToggleTrack.png";
import ToggleButton from "../../assets/icons/Toggle.png";
import ScoreBoardImage from "../../assets/icons/ULTM8_ScoreBoard (DARK).svg";
export const EventSchedule = () => {
  return (
    <div>
      <EventScheduleStyle>
        <div className="wrapper">
          <div className="scheduleHeading">
            <h4>Result</h4>
          </div>

          <div className="EventSchedule_cardsMain">
            <div className="matchScheduleCard_Left">
              <div className="schedule-container_LeftCard d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Match1_Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>

                <div className="section-2-container">
                  <div className="section-2 d-flex align-items-center">
                    <img src={Tvimgage} alt="" className="tv-img" />
                    <p className="tv-mode">TV Mode</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">
                <div className="scheduleExpendCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Match_Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={50}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpendCard-container expendCard1 d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Match_Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={50}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpendCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Match_Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={50}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpendCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Match_Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={50}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpendCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Match_Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={50}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpendCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Match_Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={50}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpendCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Match_Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={50}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpendCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Match_Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={50}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpendCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Match_Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={50}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpendCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Match_Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={50}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="matchScheduleCard_Mid">
              <div className="schedulecontainer_midCard d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <p className="uncomingMatches">Upcoming Matches</p>
                </div>

                <div className="completeMatches_section2">
                  <div className="innerSection d-flex align-items-center">
                    <p className="completeMatches">Complete Matches</p>
                    <div className="toggleContainer">
                      <img src={ToggleTrack} alt="ToggleTrack" />
                      <img
                        src={ToggleButton}
                        alt="ToggleButton"
                        className="ToggleButton"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="uncomingMatchesCards">
                <div className="schedule-container  align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                  </div>

                  <div className="d-flex  align-items-center justify-content-between">
                    <div className="card d-flex position-relative">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={ParticipantImg}
                            alt=""
                            className="participant-img"
                          />
                          <div className="participate-img d-flex flex-column">
                            <div className="d-flex align-items-center ms-2">
                              {" "}
                              <p className="para m-0">Ali Hassan </p>{" "}
                              <span className="dot ms-1"></span>
                            </div>
                            <p className="para-text m-0 ms-2">
                              Carlson Gracie Slo...
                            </p>
                          </div>
                        </div>
                        <div className="country-flag">
                          <img src={Engflag} alt="" />
                        </div>
                      </div>

                      <hr />
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={WarrenImg}
                            alt=""
                            className="participant-img"
                          />
                          <div className="participate-img d-flex flex-column">
                            <div className="d-flex align-items-center ms-2">
                              {" "}
                              <p className="para m-0">Warren Harding</p>{" "}
                              <span className="dot ms-1"></span>
                            </div>
                            <p className=" para-text m-0 ms-2">
                              {" "}
                              St Albans Sub...
                            </p>
                          </div>
                        </div>
                        <div className="country-flag">
                          <img src={chinaflag} alt="" />
                        </div>
                      </div>

                      <div className="vs position-absolute">
                        <p className="vs-text">VS</p>
                      </div>
                    </div>

                    <div className="">
                      <div className="section-2 ">
                        <div className="sectionTtime">
                          <p>Eta:14.10pm</p>
                        </div>
                      </div>
                      <div className="tick-section">
                        <div className="tick-content d-flex">
                          <img src={tickimg} alt="" className="tick-img" />
                          <p className="tick-text">Finals</p>
                        </div>
                      </div>

                      <div className="sandbox-section  ">
                        <div className="sandbox-content d-flex">
                          <img src={sandglass} alt="" className="sandbox-img" />
                          <p className="sandbox-text">1-12</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="schedule-container uncomingSecondMatch align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                  </div>

                  <div className="d-flex  align-items-center justify-content-between">
                    <div className="card d-flex position-relative">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={ParticipantImg}
                            alt=""
                            className="participant-img"
                          />
                          <div className="participate-img d-flex flex-column">
                            <div className="d-flex align-items-center">
                              {" "}
                              <p className="para m-0 ms-2">Ali Hassan </p>{" "}
                              <span className="dot ms-1"></span>
                            </div>
                            <p className="para-text m-0 ms-2">
                              Carlson Gracie Slo...
                            </p>
                          </div>
                        </div>
                        <div className="country-flag">
                          <img src={Engflag} alt="" />
                        </div>
                      </div>

                      <hr />
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={WarrenImg}
                            alt=""
                            className="participant-img"
                          />
                          <div className="participate-img d-flex flex-column">
                            <div className="d-flex align-items-center">
                              {" "}
                              <p className="para m-0 ms-2">
                                Warren Harding
                              </p>{" "}
                              <span className="dot ms-1"></span>
                            </div>
                            <p className=" para-text m-0 ms-2">
                              {" "}
                              St Albans Sub...
                            </p>
                          </div>
                        </div>
                        <div className="country-flag">
                          <img src={chinaflag} alt="" />
                        </div>
                      </div>

                      <div className="vs position-absolute">
                        <p className="vs-text">VS</p>
                      </div>
                    </div>

                    <div className="">
                      <div className="section-2 ">
                        <div className="sectionTtime">
                          <p>Eta:14.10pm</p>
                        </div>
                      </div>
                      <div className="tick-section">
                        <div className="tick-content d-flex">
                          <img src={tickimg} alt="" className="tick-img" />
                          <p className="tick-text">Finals</p>
                        </div>
                      </div>

                      <div className="sandbox-section  ">
                        <div className="sandbox-content d-flex">
                          <img src={Playicon} alt="" className="sandbox-img" />
                          <p className="sandbox-text">1-12</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="schedule-container align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                  </div>

                  <div className="d-flex  align-items-center justify-content-between">
                    <div className="card d-flex position-relative">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={ParticipantImg}
                            alt=""
                            className="participant-img"
                          />
                          <div className="participate-img d-flex flex-column">
                            <div className="d-flex align-items-center">
                              {" "}
                              <p className="para m-0 ms-2">Ali Hassan </p>{" "}
                              <span className="dot ms-1"></span>
                            </div>
                            <p className="para-text m-0 ms-2">
                              Carlson Gracie Slo...
                            </p>
                          </div>
                        </div>
                        <div className="country-flag">
                          <img src={Engflag} alt="" />
                        </div>
                      </div>

                      <hr />
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={WarrenImg}
                            alt=""
                            className="participant-img"
                          />
                          <div className="participate-img d-flex flex-column">
                            <div className="d-flex align-items-center">
                              {" "}
                              <p className="para m-0 ms-2">
                                Warren Harding
                              </p>{" "}
                              <span className="dot ms-1"></span>
                            </div>
                            <p className=" para-text m-0 ms-2">
                              {" "}
                              St Albans Sub...
                            </p>
                          </div>
                        </div>
                        <div className="country-flag">
                          <img src={chinaflag} alt="" />
                        </div>
                      </div>

                      <div className="vs position-absolute">
                        <p className="vs-text">VS</p>
                      </div>
                    </div>

                    <div className="">
                      <div className="section-2 ">
                        <div className="sectionTtime">
                          <p>Eta:14.10pm</p>
                        </div>
                      </div>
                      <div className="tick-section">
                        <div className="tick-content d-flex">
                          <img src={tickimg} alt="" className="tick-img" />
                          <p className="tick-text">Finals</p>
                        </div>
                      </div>

                      <div className="sandbox-section  ">
                        <div className="sandbox-content d-flex">
                          <img
                            src={Signalicon}
                            alt=""
                            className="sandbox-img"
                          />
                          <p className="sandbox-text">1-12</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="schedule-container align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                  </div>

                  <div className="d-flex  align-items-center justify-content-between">
                    <div className="card d-flex position-relative">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={ParticipantImg}
                            alt=""
                            className="participant-img"
                          />
                          <div className="participate-img d-flex flex-column">
                            <div className="d-flex align-items-center">
                              {" "}
                              <p className="para m-0 ms-2">Ali Hassan </p>{" "}
                              <span className="dot ms-1"></span>
                            </div>
                            <p className="para-text m-0 ms-2">
                              Carlson Gracie Slo...
                            </p>
                          </div>
                        </div>
                        <div className="country-flag">
                          <img src={Engflag} alt="" />
                        </div>
                      </div>

                      <hr />
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={WarrenImg}
                            alt=""
                            className="participant-img"
                          />
                          <div className="participate-img d-flex flex-column">
                            <div className="d-flex align-items-center">
                              {" "}
                              <p className="para m-0 ms-2">
                                Warren Harding
                              </p>{" "}
                              <span className="dot ms-1"></span>
                            </div>
                            <p className=" para-text m-0 ms-2">
                              {" "}
                              St Albans Sub...
                            </p>
                          </div>
                        </div>
                        <div className="country-flag">
                          <img src={chinaflag} alt="" />
                        </div>
                      </div>

                      <div className="vs position-absolute">
                        <p className="vs-text">VS</p>
                      </div>
                    </div>

                    <div className="">
                      <div className="section-2 ">
                        <div className="sectionTtime">
                          <p>Eta:14.10pm</p>
                        </div>
                      </div>
                      <div className="tick-section">
                        <div className="tick-content d-flex">
                          <img src={tickimg} alt="" className="tick-img" />
                          <p className="tick-text">Finals</p>
                        </div>
                      </div>

                      <div className="sandbox-section  ">
                        <div className="sandbox-content d-flex">
                          <img
                            src={Signalicon}
                            alt=""
                            className="sandbox-img"
                          />
                          <p className="sandbox-text">1-12</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="matchScheduleCard_Right">
              <div className="liveMatchHeading">
                <h4>Live Match</h4>
              </div>
              <div className="scoreBoard">
                <img src={ScoreBoardImage} alt="ScoreBoardImage" />
              </div>
            </div>
          </div>
        </div>
      </EventScheduleStyle>
    </div>
  );
};
export default EventSchedule;

import React from 'react'
import {OrganizerMessengerCenterStyle} from "./style"
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import StatusActiveError from "../../../assets/images/activeBtnError.svg";

const OrganizerMessengerCenter = () => {
  return (
    <div>
        <OrganizerMessengerCenterStyle>
        <div className="title_AffiliationButton">
          <h4 className="title">Messenger Center</h4>
          <button className="newAffiliationButton">Send new Messages</button>
        </div>

        <div className='tablelist'>
          <table>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Activity</th>
              <th>Event</th>
              <th>Sender</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            {Array.from({ length: 10 }, (_, index) =>
            <tr>
              <td>
              1
              </td>
              <td>2023-08-14</td>
              <td>Jiu Jitsu, Karate</td>
              <td>United League K1 & Muay Thai Autumn Championship...</td>
              <td>School Owner</td>
              <td><label className="Active">Active</label><img src={StatusActiveError} alt="StatusActiveError" /></td>
              <td className='actions'>
              <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
              </td>
            </tr>
            )}
          </table>
          </div>
        </OrganizerMessengerCenterStyle>
    </div>
  )
}

export default OrganizerMessengerCenter
import React, { useState } from "react";
import { ProfileBeforeLoginStyle } from "./style";
import { GlobalContainer } from "../../Home/style";
import { Layout } from "antd";
import ProfileBeforeLoginTabs from "../../../components/ProfileBeforeLoginTabs/ProfileBeforeLoginTabs";
import TabNavItem from "../../../components/TabNavItem/TabNavItem";
import TabContent from "../../../components/TabContent/tabContent";
import AcademyProfile from "../AcademyProfile/AcademyProfile";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";

const { Content } = Layout;

const ProfileBeforeLoginMain = ({ props }: any) => {
  console.log("ProfileBeforeLoginMain", props);
  const [activeTab, setActiveTab] = useState("tab1");
  return (
    <ProfileBeforeLoginStyle>
      <GlobalContainer>
        <Layout>
          <div className="banner">
            <img
              src={AcademyProfileBannerImage}
              alt="AcademyProfileBannerImage"
            />
          </div>
          <Content className="contentBeforeLogin-left">{props}</Content>
          <Content className="contentBeforeLogin-right">
            <ProfileBeforeLoginTabs />
            
          </Content>
        </Layout>
      </GlobalContainer>
    </ProfileBeforeLoginStyle>
  );
};

export default ProfileBeforeLoginMain;

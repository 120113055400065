import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useGlobalContext } from "../context/context";
import { base_url, generate_otp_url, useCaseForgetPassowrd } from "../utils/api_urls";
import { forgetPasswordInitialTypes } from "../screens/ForgetPassword/ForgetPasword";

interface IUseGenerator {
  loading: boolean;
  handleSubmit: (
    values: forgetPasswordInitialTypes
  ) => Promise<{ message: string }>;
  error: string;
}

const useGenerateOtp = (): IUseGenerator => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const toastId = useRef<any>(null);
  const { setUserPhoneNumber } = useGlobalContext();
  // get Validations from redux appData
  // const {
  //   countryName: {
  //     results: { countryCode },
  //   },
  //   validations,
  // } = useAppSelector((state) => state.appData.data);

  // // user validations
  // const phoneNumber = validations[countryCode === "92" ? 23 : 4];
  // // user regExpressions
  // const phoneNumberReg = new RegExp(phoneNumber.pattern);

  // // initial values
  // const initialValues: forgetPasswordInitialTypes = {
  //   phoneNumber: "",
  // };
  // // validation schema
  // const validationSchema = Yup.object({
  //   phoneNumber: Yup.string()
  //     .required(phoneNumber.notBlankMsgEn)
  //     .matches(phoneNumberReg, phoneNumber.pattern),
  // });

  // register phone handler
  const handleSubmit = async (
    values: forgetPasswordInitialTypes
  ): Promise<{ message: string }> => {
    setUserPhoneNumber(values.phoneNumber.toString());
    console.log({ phoneNumber: values.phoneNumber });
    const phoneData = {
      phoneNumber: values.phoneNumber.toString(),
    };

    try {
      setError("");
      // setLoading(true);
      const { data } = await axios.post(base_url + generate_otp_url, phoneData);
      if (!!data?.message) {
        toast("Sent the OTP on Phone Number", {
          type: "success",
          autoClose: 2000,
        });
        setLoading(false);
        return { message: "success" };
      } else {
        toast("Failed to Send OTP", {
          type: "error",
          autoClose: 2000,
        });
        setLoading(false);
        return { message: "failed" };
      }
    } catch (error) {
      toast("Something went wrong", {
        type: "error",
        autoClose: 2000,
      });
      setLoading(false);
      return { message: "failed" };
    }
    //   if (data.responseCode === "422") {
    //     toast(data.responseMessage, {
    //       type: "error",
    //       autoClose: 1000,
    //     });
    //     setLoading(false);
    //     return;
    //   }
    //   toastId.current = toast(data.responseMessage, {
    //     type: "success",
    //     autoClose: 1000,
    //   });
    //   setLoading(false);
    //   navigate("/register/verify-otp");
    //   console.log({ data });
    // } catch (error2: any) {
    //   console.log({ error: error2 });
    //   setLoading(false);
    //   setError(error2.response.data.responseMessage);
    //   setTimeout(() => {
    //     setError("");
    //   }, 2000);
    //   toastId.current = toast(error2.response.data.responseMessage, {
    //     type: "error",
    //     autoClose: 1000,
    //   });
    // }
  };
  return {
    loading,
    handleSubmit,
    error,
  };
};

export default useGenerateOtp;

import React from "react";
import { CoachUpdateProfileEventRegistrationEventStyle } from "./style";
import CoachProfileBanner from "../../../assets/icons/CoachProfileImage.svg";
import MartialLogo from "../../../assets/images/ic_school_profile_placeholder.png";
import CameraImage from "../../../assets/images/DSLR_Camera.png";
export const CoachUpdateProfileEventRegistrationEvent = () => {
  return (
    <div>
      <CoachUpdateProfileEventRegistrationEventStyle>
        <div className="bodyWrapper_RightSide">
          <div className="title">
            <h4>Update Coach Profile</h4>
          </div>
          <div className="coachProfileBanner">
            <div className="bannerImageMain">
              <img src={CoachProfileBanner} alt="CoachProfileBanner" />
            </div>
            <div className="martialSystemMain">
              <div className="martialLogo">
                <img src={MartialLogo} alt="MartialLogo" />
              </div>
              <div className="camImgae">
                <img
                  src={CameraImage}
                  alt="CameraImage"
                  className="CameraImage"
                />
              </div>
            </div>
          </div>

          <div className="coachInformationContainer">
            <div className="innerTitle">
                <h5>Coach Information</h5>
            </div>
            <form className="namePhoneEmailContainer">
                <div className="enterYourName labelTypography">
                    <label htmlFor="Name">Name</label><br />
                    <input type="text" id="text" placeholder="O’Neil McLean"/>
                </div>

                <div className="enterYourNumber labelTypography">
                    <label htmlFor="Phone">Phone</label><br />
                    <input type="text" id="text" placeholder="O’Neil McLean"/>
                </div>

                <div className="enterYourEmail labelTypography">
                    <label htmlFor="Email">Email</label><br />
                    <input type="email" id="email" placeholder="omclean@kaimeramedia.com"/>
                </div>
            </form>

            <form className="personInchargeWebsite_AboutMainContainer">
                <div className="personInchargeWebsite">

                <div className="personInCharge labelTypography">
                    <label htmlFor="Website">Person in charge</label><br />
                    <input type="text" id="text" placeholder="O’Neil McLean"/>
                </div>
                <div className="website labelTypography">
                    <label htmlFor="PersonInCharge">Website</label><br />
                    <input type="text" id="text" placeholder="ttps://www.bmjja.co.u"/>
                </div>
                </div>
                <div className="about">
                  <label htmlFor="About">About</label>
                  <textarea />
                </div>
            </form>
          </div>
        </div>
      </CoachUpdateProfileEventRegistrationEventStyle>
    </div>
  );
};

export default CoachUpdateProfileEventRegistrationEvent;

import styled from "styled-components";
import { JapaneseIndigo, fontFamilyEnnVisionsRegular, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber, lightColor1, lightDark2, mainColor, mediaDeviceMax } from "../GlobalStyle";
import {
    fontFamilyMedium,
    pureDark2,
    whiteColor,
  } from "../GlobalStyle"
  import AboutBanner from "../../assets/images/about_ULTM8.png";

export const BannerStyle = styled.div`

.banner {
  position: relative;
  background-image: url(assets/images/banner_img.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 410px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 40px 20px 0;
  box-sizing: border-box;
  padding-bottom: 20px;
  &::before{
    content: '';
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 50%;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
  .wrapWidth{
    display: block;
    z-index: 10;
  h2 {
      font-size: 74px;
      font-family: ${fontFamilyMedium};
      color: #fff;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
  }
  p{
    color: #FFF;
    text-align: center;
    font-family: ${fontFamilyRegularUber};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    margin-top: 6px;
  }
}

}

.navFields{
  max-width: 995px;
  width: 100%;
  margin-top: 30px;
  z-index: 10;
}
.headerSearchField {
  width: 100%;
}



.custom-input {
  height: 52px;
  background-color: ${lightColor1};
  max-width: 460px;
  width: 100%;
}

.left-bar{
height: 52px;
border-radius: 8px 0 0 8px;
border-right: 0.5px solid rgba(53, 87, 98, 0.7);
input{
  background-color: ${lightColor1};
  padding: 10px;
  color: ${lightDark2};
  font-family: ${fontFamilyRegularUber};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ::placeholder{
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
}

.right-bar{
height: 52px;
border-radius: 0 8px 8px 0;
border-left: 0.5px solid rgba(53, 87, 98, 0.7);
input{
  background-color: ${lightColor1};
  padding: 10px;
  color: ${lightDark2};
  font-family: ${fontFamilyRegularUber};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ::placeholder{
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
}

.filterIcon  button{
height: 52px;
width: 52px;
}


.Feature-Event{
background-color: #F5F5F5;
padding: 48px;
h3{
  text-align: center;
  color: ${JapaneseIndigo};
  font-family: ${fontFamilyMedium};
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  z-index: 1;
  &:after{
    content: "FEATURED EVENTS";
    text-align: center;
    font-family: OperaBold;
    font-size: 105px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #f5f5f5;
    text-shadow: #eee -1px -1px 0px, #eee 1px -1px 0px, #eee -1px 1px 0px, #eee 1px 1px 0px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: -30px;
    z-index: -1;
  }
span{
  color: ${mainColor};
  font-family: ${fontFamilyMedium};
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
}
p{
  color: ${mainColor};
  font-family: ${fontFamilyMediumUber};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}


.Feature-Event{

}
.Feature-Event .ant-list-item .ant-list-item-meta-content h4{
  color: #061229;
  font-family: "EnnVisionsMedium";
  font-size: 18.58px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 0;
}
.Feature-Event .ant-list-item .ant-list-item-meta-content .ant-list-item-meta-description{
  color: #061229;
  font-family: "EnnVisions";
  font-size: 18.58px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Feature-Event .custom_card_para{
  color: #4F4F4F;
  font-family: "EnnVisions";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.802px; 
}
.Feature-Event span:first-child{
  color: #1C394B;
  font-family: EnnVisions;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.802px; 
}
.Feature-Event span:last-child{
  color: #1F3340;
  font-family: EnnVisions;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.802px;
}
.Feature-Event .custom_card_footer_link{
  color: #B3B3B3;
  font-family: EnnVisions;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.802px; /* 117.509% */
  text-decoration-line: underline;
}
.Feature-Event  .ant-avatar {
  width: 80px;
  height: 80px;
}





// custom card

.custom_card_list{
  gap: 20px;
  width: 88%;
  margin: 60px auto 0;
}
.custom_card {
  width: 333px;
  background-color: #fff;
  height: 320px;
  border: 0.6px solid #EAEAEA;
  border-radius: 5px;

  .ant-list-item {
    padding: 16px 12px;
    display: flex;
    align-items: center;
    &-meta{
      align-items: center;
    }
    &-meta-title {
      font-family: EnnVisionsMedium;
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: ${pureDark2};
    }
    &-meta-description {
      font-family: EnnVisions;
      font-size: 12px;
      font-weigt: 400;
      color: ${lightDark2};
    }
  }

  &_placeholder_img {
    width: 100%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &_para {
    font-size: 11.89px;
    font-family: EnnVisions;
    color: ${lightDark2};
    font-weight: 400;
    padding: 22px 25px;
    line-height: 14px;
  }

  &_footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 0 25px;
   
    &_rating {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      .ant-rate {
        font-size: 16px;
        line-height: normal;
        &-star {
          margin-right: 2px;
          svg{
            width: 12.64px;
            height: 12.64px;
          }
        }
      }
      .ant-rate-text span:first-child{
        color: #1C394B;
        font-family: EnnVisionsMedium;
        font-size: 11.895px;
        font-style: normal;
        font-weight: 500;
        line-height: 13.977px;
      }
      .ant-rate-text span:nth-child(2){
        color: #1F3340;
        font-family: EnnVisions;
        font-size: 11.895px;
        font-style: normal;
        font-weight: 400;
        line-height: 13.977px;
      }
    }

    &_link {
      font-family: EnnVisionsMedium;
      color: #b3b3b3;
      font-size: 11.895px;
      font-style: normal;
      font-weight: 500;
      line-height: 13.977px;
    }
  }
}

.horizentalBreakLine{
  width: 30%;
  margin: 30px auto 0;
  border-top: 1px solid #AAAFB6;
}

.exploreMoreText{
  color: #415060;
  text-align: center;
  font-family: ${fontFamilyMedium};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
  text-transform: uppercase;

}









    

.navbar-select {
  width: 100%;
  max-width: 150px;
  display: inline-block;

  @media screen and ${mediaDeviceMax.mobileBS} {
    display: none;
  }
  .ant-select-selector {
    height: 50px !important;
    align-items: center;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
}
}




  @media screen and ${mediaDeviceMax.tabletL} {
    height: 50px;
  }

  @media screen and ${mediaDeviceMax.tabletS} {
    display: none;
  }
}

.top-side .navbarSearchField > .ant-input::-webkit-input-placeholder ,
.top-side .navbarSearchField > .ant-input::placeholder ,
 input:placeholder-shown, 
 #root .ant-input::-webkit-input-placeholder  {
  color: ${lightDark2} !important;
  opacity: 1 !important;
}
input[type="text"]{
  background-color: #f5f5f5 !important;
}


  //Featured card

.Featured_card_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
 }
 .Featured_card_list .Featured_card{
  max-width: 33%;
  box-sizing: border-box;
  padding: 25px;
  margin: 0;
  width: auto;
  height: auto;
  border: 1px solid #AAAFB6;
  border-radius: 0
}
.Featured_card_list .Featured_card:first-child{
  border-left: none;
  border-top: none;
}
.Featured_card_list .Featured_card:nth-child(2){
  border-top: none;
}
.Featured_card_list .Featured_card:nth-child(3){
  border-top: none;
  border-right: 0;
}
.Featured_card_list .Featured_card:nth-child(4){
  border-bottom: none;
  border-left: 0;
}
.Featured_card_list .Featured_card:nth-child(5){
  border-bottom: none;
}
.Featured_card_list .Featured_card:nth-child(6){
  border-bottom: none;
  border-right: 0;
}
.Featured_card_list .Featured_card .ant-list-item {
  position: relative;
}
.Featured_card_list .Featured_card .ant-list-item .ant-list-item-meta-avatar span{
  background-color: #3D86AF;
  position: absolute;
  top: 2px;
  left: -1px;
  z-index: -1;
}
.Featured_card_list .Featured_card .ant-list-item .ant-list-item-meta-avatar{
  margin-right: 0;
}
.Featured_card_list .Featured_card .ant-list-item .ant-list-item-meta{
  // position: absolute;
}
.Feature-Card .ant-list-item img{
  position: relative;
  width: 65px;
  height: 37px;
}




.Explore{
  font-family: "OperaMedium";
  color: #415060;
  text-decoration: none;
  border-top: 1px solid #AAAFB6;
  font-size: 18px;
  display: flex;
  width: 385px;
  height: 62px;
  align-items: center;
  gap: 20px;
  margin: 50px auto 22px;
  padding: 20px 0px;
  justify-content: center;
  }


  .Featured_card {
border-radius: 5px;
 margin-top:80px;
width: 323px;
height: 128px;
}

    .ant-list-item {
      padding: 12px;
    //   display: flex;
      align-items: center;
      &-meta{
        align-items: center;
      }
      &-meta-title {
        font-family: ${fontFamilyMedium};
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 100;
        font-size: 18px;
        font-style: normal;
        line-height: normal;
        color: ${pureDark2};
        margin-left:15px;
      }
    }

    }

    &_para {
      font-size: 11.89px;
      font-family: ${fontFamilyRegular};
      color: ${lightDark2};
      font-weight: 100;
      padding: 22px 25px;
      line-height: 14px;
    //   margin-left:40px;
    }

    .Featured_card_para > h2{
        font-size:14px;
  }

  // About Section

.About-main{
  padding-bottom: 90px;
  background-color: #f5f5f5;
}
.bg-banner{
    background-image:  linear-gradient(270deg, #3D86AF 50%, rgba(41, 169, 225, 0.00) 147.84%), url(${AboutBanner});
    height: 438px;
    width:100%;
    font-family: Arial, sans-serif;
    // text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // background: linear-gradient(270deg, #3D86AF -2.5%, rgba(41, 169, 225, 0.00) 147.84%);
    position: relative;
}
.bg-banner:before{
    content: '';
    background: linear-gradient(270deg, #3D86AF -2.5%, rgba(41, 169, 225, 0.00) 147.84%);
}
.Organizer-banner{
  width: 100%;
  padding: 61px 0 0 0 !important;
  color: #FFFFFF;
}
.Organizer-banner h1{
  color: #fff;
  font-family: "OperaMedium";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  z-index: 1;  
}
.Organizer-banner h1:before{
  content: "What Our Organization Said";
  text-align: center;
  font-family: OperaBold;
  font-size: 105px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: rgb(61, 134, 175);
  text-shadow: #327CA6 -1px -1px 0px, #327CA6 1px -1px 0px, #327CA6 -1px 1px 0px, #327CA6 1px 1px 0px;
  position: absolute;
  left: 0px;
  right: 0;
  top: -30px;
  z-index: -1;
}
.Organizer-banner p{
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color:white;
}
// .Organizer-content {
//     width:500px;
// }
.Organizer-banner-right{
  display: flex;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: center;
  align-items: flex-end;
}
.aboutHeading{
  display: flex;
  flex-direction: column;
}
.aboutHeading h1{
  font-family: "OperaRegular";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 67.92px;
  color:#FFF;
  padding-top: 50px;
  margin: 0;
  position: relative;
  z-index: 1;
}
.aboutHeading h1::before {
  content: "ABOUT ULTM8";
  text-align: center;
  font-family: OperaBold;
  font-size: 105px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  // color: rgb(61 134 175);
  // text-shadow: rgb(95 151 182) -1px -1px 0px, rgb(95 151 182) 1px -1px 0px, rgb(95 151 182) -1px 1px 0px, rgb(95 151 182) 1px 1px 0px;  
  color: rgb(61, 134, 175);
  text-shadow: #327CA6 -1px -1px 0px, #327CA6 1px -1px 0px, #327CA6 -1px 1px 0px, #327CA6 1px 1px 0px;
  position: absolute;
  left: -102px;
  top: 16px;
  z-index: -1;
}
.aboutHeading .Organizer-banner-right_inner {
  padding-left: 102px;
}
.Organizer-banner-right span{
  font-family: "EnnVisionsMedium";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #3D86AF;
}
.about-text{
    color:#FFF;
    font-family: "UberMove-Medium";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 13px;
    margin-bottom: 0;
}
.about-para {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}
.about-para p{
  font-family: "UberMove-Medium";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.18px;
  color:#FFF;
  margin-bottom: 0;
  width: 100%;
  max-width: 470px;
}
.about-para span {
  font-family: "UberMove-Medium";
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.18px;
  color:#FFF;                                            
}















.aboutWrapper{
  width: 100%;
  max-width: 1060px;
  margin: auto;
}
.Banner-view{
    width: 100%;
    // height: 150px;
    // display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    // padding: 10px;
    box-sizing: border-box;
    // flex-direction: column;
    flex-direction: column;
}
.Banner-img{
    background-position: center;
    max-width: 1198px;
    height: 608px; 
    width: 100%;
    margin: 54px auto 54px;
    display: block;
}
.Banner-view p{
  color: #1A4754;
  text-align: center;
  font-family: "UberMove-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; 
  max-width: 710px;
  width: 100%;
  margin: auto;
  margin-top: 32px;
}
.mobaileAplic_heading {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.mobaileAplic_heading h2 {
  color: #1A4754;
  text-align: center;
  font-family: "OperaMedium";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 67.92px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}
.mobaileAplic_heading h2:before {
  content: "MOBILE APPLICATION";
  text-align: center;
  font-family: OperaBold;
  font-size: 105px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: rgb(255, 255, 255);
  text-shadow: rgb(233, 233, 233) -1px -1px 0px, rgb(233, 233, 233) 1px -1px 0px, rgb(233, 233, 233) -1px 1px 0px, rgb(233, 233, 233) 1px 1px 0px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: -30px;
  z-index: -1;
}
.mobaileAplic_heading h2 span {
    font-family: "OperaMedium";
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #3D86AF;
}
.mobaileAplic_heading p{
    color: #3D86AF;
    text-align: center;
    font-family: "UberMove-Medium";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.48px;
    text-transform: capitalize;
    margin-top: 13px;
    margin-bottom: 0;
}

.Organizer-main{
  padding-bottom: 105px;
    font-family: Arial, sans-serif;
    text-align: center;
    background-color: #3D86AF;
    // background-color: red;
}

.Organizer-main .Organizer-banner + div{
  max-width: 1588px;
  width: 100%;
  margin: auto;
}


.Organizer-banner{
    width: 100%;
    padding: 50px 0;
    color: #FFFFFF;
}
.Organizer-banner h1{
  color: #FFF;
  text-align: center;
  font-family: "OperaMedium";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 67.92px;
  margin-bottom: 0;
    
}
.Organizer-banner p{
  color: #DEF2E2;
  text-align: center;
  font-family: "UberMove-Medium";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.48px;
  margin-top: 13px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.MClean_main {
  margin-top: 31px;
  max-width: 1588px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 89px;
  margin: 50px auto 0;
}
.Organizer-content {
    width: 1080px;
  }

.MClean-img  img{

}
.MClean-text  h6 {
  color: #FFF;
  font-family: "OperaMedium";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}
.MClean-text p{
  color: #EFEFEF;
  font-family: "UberMove-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}
.Organizer-content p {
  width: 92%;
  color: #FFF;
  font-family: "UberMove-Medium";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  width: 100%;
  text-align: left;
  margin-bottom: 0;
}
.organizeArrowBtns {
  display: flex;
  justify-content: flex-start;
  margin-top: 11px;
}
.leftIcon img,
.rightIcon img {
  width: 17px;
  height: 7px;
  filter: invert(100%) sepia(98%) saturate(0%) hue-rotate(344deg) brightness(104%) contrast(103%);
}
.rightIcon{
  margin-left: 9px;
}

.scoreboard{
  position: relative;
}
.scoreboard:before{
  content: "";
  border-radius: 12px 0px 0px 12px;
  background: rgba(0, 0, 0, 0.05);
  height: 242px;
  width: 930px;
  position: absolute;
  right: 0px;
  top: 31%;
}
.scoreboard_wrapper{
  //  position: relative;
  //   width:80%;
  //   max-width:1000px;
  //   display:grid;
  //   grid-template-columns:repeat(2,1fr);
  //   grid-gap: 50px 30px;

  overflow: hidden;
  display: flex;
  max-width: 1593px;
  margin: auto;
  padding: 80px 20px 76px;
  align-items: center;
}


// .scoreboard{
//   overflow: hidden;
//   display: flex;
//   max-width: 1593px;
//   margin: auto;
//   padding: 80px 20px 76px;
//   align-items: center;
// }
.Scoreboard-right{
    max-width: 50%;
    width: 100%;
}

.Scoreborad-left{
    max-width: 50%;
    width: 100%;
    padding-left: 70px;
    position: relative;
}

.Scoreborad-left h1{
  color: #1A4754;
  font-family: "OperaMedium";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Scoreborad-left h1:before{
  content: "Ultm8 Score Boards";
  text-align: center;
  font-family: "OperaBold";
  font-size: 105px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  text-shadow: rgb(233, 233, 233) -1px -1px 0px, rgb(233, 233, 233) 1px -1px 0px, rgb(233, 233, 233) -1px 1px 0px, rgb(233, 233, 233) 1px 1px 0px;  position: absolute;
  left: 0px;
  right: 0px;
  top: -30px;
  z-index: -1;
  letter-spacing: 2px;
}
.Scoreborad-left span{
  font-family: "OperaMedium"; 
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #3D86AF;
}
.scoreboard-text{
    color:#3D86AF;
    font-family: "UberMove-Medium";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 50px;
}


.Scoreboard-right img{
  width: 863px;
  height: 495px;
  object-fit: unset;
}
.Scoreborad-left .scoreboard-para {
  color: #1A4754;
  font-family: "UberMove-Medium";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  max-width: 477px;
  width: 100%;
}

.payment{

    font-family: Arial, sans-serif;
    text-align: center;
    background-color: #F5F5F5;
    padding: 41px 0 64px;
    // background-color: red;
}
.payment-content{
    width: 100%;
    color: #fff;

}
.payment-content h1{
  text-align: center;
  font-family: "OperaMedium";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #1A4754;
  position: relative;
  z-index: 1;
}
.payment-content h1:before{
  content: "Various Payment Methods";
  text-align: center;
  font-family: OperaBold;
  font-size: 105px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: rgb(245, 245, 245);
  text-shadow: rgb(233, 233, 233) -1px -1px 0px, rgb(233, 233, 233) 1px -1px 0px, rgb(233, 233, 233) -1px 1px 0px, rgb(233, 233, 233) 1px 1px 0px;  position: absolute;
  left: 0px;
  right: 0px;
  top: -30px;
  z-index: -1;
}
.payment-content span{
  font-family: "OperaMedium";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    color: #3D86AF;
}

.payment-content h4{
    color:#3D86AF;
    text-align: center;
    font-family: "UberMove-Medium";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.payment-content p{
  color: #333;
  text-align: center;
  font-family: "EnnVisions";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 154.545% */
  max-width: 991px;
  width: 100%;
  margin: 45px auto 51px;
}
.payment-gateway{
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment-gateway div{
  height: 39px;
  margin: 0px 38px;
}
.payment-gateway div > img{
  object-fit: cover;
  vertical-align: middle;
}

.Feature-Card{
  position: relative;
  margin-top: 58px;
}
.Feature-Card .FeatureCardWrapper{
  max-width: 1242px;
  width: 100%;
  margin: 0 auto;
}

.Feature-Card h3{
  color: #1A4754;
  text-align: center;
  font-family: "OperaMedium";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 2;
  position: relative;
}
.Feature-Card .FeatureCardWrapper h3:before{
  content: "Our Features";
  text-align: center;
  font-family: OperaBold;
  font-size: 105px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: rgb(255, 255, 255);
  text-shadow: rgb(233, 233, 233) -1px -1px 0px, rgb(233, 233, 233) 1px -1px 0px, rgb(233, 233, 233) -1px 1px 0px, rgb(233, 233, 233) 1px 1px 0px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: -30px;
  z-index: -1;
}
.Feature-Card h3:before{
  content: "FEATURED EVENT";
  text-align: center;
  font-family: OperaBold;
  font-size: 105px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: rgba(250, 250, 250, 2);
  text-shadow: rgb(238, 238, 238) -1px -1px 0px, rgb(238, 238, 238) 1px -1px 0px, rgb(238, 238, 238) -1px 1px 0px, rgb(238, 238, 238) 1px 1px 0px;  position: absolute;
  left: 0px;
  right: 0px;
  top: -30px;
  z-index: -1;
}
.Feature-Card h3 span{
  color: #3D86AF;
  font-family: "OperaMedium";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Feature-Card p {
  color: #525A66;
  text-align: center;
  font-family: "UberMove-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  // margin-bottom: 70px;
}

.Feature-Card .Featured_card_list h2{
  color: #16374E;
  font-family: "OperaMedium";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Feature-Card .Featured_card_para p{
  font-family: "EnnVisions";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 172.222% */
  text-align: left;
}

`


import styled from "styled-components";
import { fontFamilyEnnVisionsBold, fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegular, fontFamilyRegularUber } from "../../../components/GlobalStyle";
export const ParticipantsProfileSingleAcademyStyle = styled.div`
.wrapper {
    width: 100%;
    display: flex;
}
.bodyLeftSideMain {
    width: 25%;
    margin-right: 34px;
}
.blackBeltMain {
    max-width: 359px;
    width: 100%;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding: 16px 9px 7px 15px;
    margin-top: 20px;
}
.blackBeltTitle {
    color: #061229;
    font-family: ${fontFamilyMediumUber};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 13.217px;
    letter-spacing: 0.4px;
    margin-bottom: 0;
}
.blackBelt_ImagePara {
    display: flex;
    justify-content: space-between;
    margin-top: 19px;
}
.blackBelt_AchivedPromoted{
    display: flex;
    flex-direction: column;
}
.blackBelt_AchivedPromoted p {
    color: #000;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 0;
}
.blackBelt_AchivedPromoted span {
    color: #4F4F4F;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}
.blackBeltImage img {
    width: 113px;
    height: 13.96px;
}
.winningsMain {
    max-width: 359px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background-color: #FFF;
    margin-top: 19px;
}
.winning10Logo {
    padding: 14px 0 14.19px 14.3px;
}
.winningTypesMain {
    display: flex;
    flex-direction: column;
    padding-left: 20.96px;
    padding-right: 9.69px;
}
.winningByPoints {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
}
.byPoints, .bySubmission, .byWalkover {
    display: flex;
    align-items: center;
    width: 219px;
}
.byPoints span {
    width: 6.804px;
    height: 13.953px;
    border-radius: 3.721px;
    background-color: #78BC7B;
}
.byPoints p {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 0;
    padding-left: 8.62px;
}
.winningCount span {
    color: #061229;
    text-align: right;
    font-family: ${fontFamilyEnnVisionsBold};
    font-size: 14.884px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}
.winningBySubmission {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}
.bySubmission span {
    width: 6.804px;
    height: 13.953px;
    border-radius: 3.721px;
    background-color: #CD6767;    
}
.bySubmission p {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 0;
    padding-left: 8.62px;
}
.winningByWalkover {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}
.byWalkover span {
    width: 6.804px;
    height: 13.953px;
    border-radius: 3.721px;
    background-color: #06F;
}
.byWalkover p {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 0;
    padding-left: 8.62px;
}
.introductionParagraph {
    max-width: 359px;
    width: 100%;
    border-radius: 5px;
    border: 0.93px solid #E0E0E0;
    background: #FFF;
    padding: 14px 12px 10px 17px;
    margin-top: 19px;
}
.introductionParagraph h6 {
    color: #000;
    text-align: justify;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.introductionParagraph p {
    color: #4F4F4F;
    text-align: justify;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 5px;
    margin-bottom: 0;
}
.mapFrame {
    max-width: 357px;
    width: 100%;
    border-radius: 0px 0px 9px 9px;
    border: 0px solid #E0E0E0;
    margin-top: 19px;
}
.bodyRightSideMain {
    width: 75%;
}
.academiesAffiliationsMain {
    display: flex; 
    gap: 20px;
    margin-top: 29px;
}
.academies {
    display: flex;
    flex-direction: column;
}
.blockHeadings {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    padding: 0px 0 0 12px;
    margin-bottom: 0;
}
.dynamoFightsCard {
    margin: 13px 0 7px 2px;
}
.uncomingEventsBlock {
    margin-top: 24px;
}
.unComingEventsCard {
    display: flex;
    align-items: center;
    gap: 19px;
    margin-top: 13.77px;
    margin-left: 3.28px;
}
.passEventsBlock {
    margin: 25.1px 0px 58px 0px;
}
.eventHeadingSeeAll {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.eventHeadingSeeAll h4 {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    padding-left: 7px;
    margin-bottom: 0;
}
.eventHeadingSeeAll span {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.217px;
    letter-spacing: 0.32px;
    cursor: pointer
}
.pastEventHeading h4 {
    padding-left: 7px !important;
}
.passEventsCard {
    display: flex;
    align-items: center;
    gap: 19px;
    margin-top: 15px;
    margin-left: 2px;
}
`;
import styled from "styled-components";
import {
  fontFamilyEnnVisionsMedium,
  fontFamilyEnnVisionsRegular,
  fontFamilyMedium,
  pureDark,
} from "../../../components/GlobalStyle";

export const OrganizerSettingEventTopListsStyle = styled.div`
.banner + .ant-layout{
    flex-direction: row;
}
.contentBeforeLogin-right{
    margin-top: 28px;
}
.contentBeforeLogin-right h4.title{
    color: ${pureDark};
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
}

.tablelist{
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    margin-top: 18px;
    margin-bottom: 21px;
}
.tablelist table{
    -top: 17px;
    width: 100%;
    border-collapas: collapas; 

}
.tablelist table tr:nth-child(odd){

}
.tablelist table tr:nth-child(even){
    background-color: #FAFAFA;
}
.tablelist table tr th{
    color: #061229
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
    padding: 17px 20px;
}
.tablelist tr td{
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 17px 20px;
    position: relative;
}
table tr td:nth-child(4) p{
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
table tr td:nth-child(4) span{
    color: #0070E0;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 7px;
}
table tr td:nth-child(4) label{
    color: #fff;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 10px;
    border-radius: 4px;
    position: relative;
}
table tr td:nth-child(5) label{
    color: #fff;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 10px;
    border-radius: 4px;
    position: relative;
    width: 110px;
    text-align: center;
}

table tr td:nth-child(6) label{
    color: #fff;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 26px;
    border-radius: 4px;
    position: relative;
    width: 110px;
    text-align: center;
}
table tr td:nth-child(4) img, table tr td:nth-child(5) img, table tr td:nth-child(6) img{
    position: absolute;
    top: 8px;
    margin-left: -10px;
}
.Default{
    background: #16374E;
}
.Optional{
    color: #A7A6A6 !important;
    background: #DADADA;
}
.Live{
    background: #4CAF50;
}
.Update-scheduled{
    background-color: #4CAF50;
}
.Sent{
    background-color: #4CAF50;
}
.Published{
    background-color: #4CAF50;
}
.Unpublished{
    background-color: #AF0026;
}
.actions {
    text-align: center;
}
.Add{
    color: #333 !important;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    background: #DEF2E2;
    padding: 4px 32px !important;
}



`;

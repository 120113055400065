import React from "react";
import { AffiliationsProfileUpcomingEventStyle } from "./style";
import UpComingEventsCards from "../../AjpScoreBoard/TicketListing/TicketListing";
import PaginationComponent from "../../../components/ProfilesBeforeLoginPagination/ProfilesBeforeLoginPagination";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import MapFrame from "../../../assets/images/AcademyProfileMapImage-p3.png";
import IntroductionComponent from "../../../components/ProfileBeforeLoginIntroduction/ProfileBeforeLoginIntroduction";
export const AffiliationsProfileUpcomingEvent = () => {
  return (
    <div>
      <AffiliationsProfileUpcomingEventStyle>
        {/* <div className="banner">
          <img
            src={AcademyProfileBannerImage}
            alt="AcademyProfileBannerImage"
          />
        </div> */}
        <div className="wrapper">
          <div className="bodyLeftSideMain">
            <div className="introduction">
              <IntroductionComponent />
            </div>

            <div className="introductionParagraph">
              <h6>Introduction</h6>
              <p>
                I have been on so many different mats all over the world. That
                amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                began training in Jiu-Jitsu at just four years of age. His
                father, who was a brown belt, started his journey and took him
                to classes at João Alberto Barreto’s academy. As a teenager,
                Mauricio began training with the legendary Rolls Gracie. Under
                Rolls' guidance, he became a highly successful competitor and
                won the absolute division of the Rio de Janeiro State
                Championships in 1981. ....
              </p>
            </div>

            <div className="mapFrame">
              <img src={MapFrame} alt="" />
            </div>
          </div>
          <div className="bodyRightSideMain">
            <div className="upcomingEvents_ContainerMain">
              <div className="upcomingEventsHeading">
                <h4>Upcoming Events</h4>
              </div>
              <div className="upComingEvents_CardContainer">
                <UpComingEventsCards />
                <UpComingEventsCards />
                <UpComingEventsCards />
                <UpComingEventsCards />
                <UpComingEventsCards />
                <UpComingEventsCards />
                <UpComingEventsCards />
                <UpComingEventsCards />
                <UpComingEventsCards />
                <UpComingEventsCards />
                <UpComingEventsCards />
                <UpComingEventsCards />
              </div>
            </div>
            <div className="paginationContainer">
              <PaginationComponent />
            </div>
          </div>
        </div>
      </AffiliationsProfileUpcomingEventStyle>
    </div>
  );
};

export default AffiliationsProfileUpcomingEvent;

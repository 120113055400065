import React from 'react'
import {OrganizerSettingStyle} from "./style"
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import StatusActiveError from "../../../assets/images/activeBtnError.svg";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { GlobalContainer } from "../../Home/style";
import { Layout } from "antd";
import OrganizerSettingStatisticsRegistrationTabs from '../../../components/OrganizerSettingStatisticsRegistrationTabs/OrganizerSettingStatisticsRegistrationTabs';
import OrganizerSettingLeft from '../../../components/OrganizerSettingLeft/OrganizerSettingLeft/OrganizerSettingLeft';
const { Content } = Layout;

const OrganizerSetting = () => {
  return (
    <div>
        <OrganizerSettingStyle>

        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="contentBeforeLogin-left">
                <OrganizerSettingLeft />
              </Content>
              <Content className="contentBeforeLogin-right">
                <OrganizerSettingStatisticsRegistrationTabs />
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>



        </OrganizerSettingStyle>
    </div>
  )
}

export default OrganizerSetting
import React, {useState} from 'react'
import {CoachProfileEventRegistrationEvents2Style} from "./style"
import PaginationComponent from "../../../components/ProfilesBeforeLoginPagination/ProfilesBeforeLoginPagination";
import { Link } from "react-router-dom";
import { Avatar, Dropdown, List, Rate } from "antd";
import placeHolderImage from "../../../assets/images/custom_card_placeholder.png";
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import ImageAvatar from "../../../assets/images/ImageAvatar.png"

const CoachProfileEventRegistrationEvents2 = () => {
    const [value, setValue] = useState(3);
    const imageLs = [1, 2, 3, 4, 5, 6, 7, 8];
    const desc = ["terrible", "bad", "normal", "good", "wonderful"];
    const imageAvatar = <img src={ImageAvatar} alt="a" />
    return (
      <div>
        <CoachProfileEventRegistrationEvents2Style>
          <div className="bodyWrapper_RightSide">
            <div className="title_AffiliationButton">
              {/* <h4 className="title">Affiliation Memberships</h4> */}
              <h4 className="title">Federations</h4>
              <button className="newAffiliationButton">Apply Now</button>
            </div>
            <div className="affiliationMemberships_CardContainer">
              <div className="custom_card_list d-flex align-items-center">
                {imageLs.map((item) => {
                  return (
                    <div className="custom_card" key={item}>
                      <List.Item>
                        <List.Item.Meta
                          avatar={imageAvatar}
                          title={"Apex Martial Arts Academy"}
                          description="Hutton, United Kingdom"
                        />
                        <img src={actionMenuTogglerIcon} alt="" />
                      </List.Item>
                      <div className="custom_card_placeholder_img" id="cardImg">
                        <label className="Approved">Approved</label>
                        <img src={placeHolderImage} alt="CardImg" id="cardImg" />
                      </div>
                      <div className="custom_card_para">
                        Norem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc vulputate libero et velit interdum, ac aliquet odio
                        mattis.
                      </div>
                      <div className="custom_card_footer">
                        <div className="custom_card_footer_rating">
                          <Rate
                            tooltips={desc}
                            onChange={setValue}
                            value={value}
                          />
                          {value ? (
                            <span className="ant-rate-text">
                              {/* {desc[value - 1]} */}
                              <span>4.6</span>
                              <span> (780+ )</span>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <Link to={"#"} className="custom_card_footer_link">
                          Learn More
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="pagination_Container">
              <PaginationComponent />
            </div>
          </div>
        </CoachProfileEventRegistrationEvents2Style>
      </div>
    );
  };

export default CoachProfileEventRegistrationEvents2
import { configureStore } from "@reduxjs/toolkit";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import locationSlice from "./features/location/locationSlice";
import appDataSlice from "./features/appDataSlice";
import loginDataSlice from "./features/loginDataSlice";
import userDetailsSlice from "./features/admin/user/userDetailsSlice";
import updateUserStatusSlice from "./features/admin/user/updateUserStatusSlice";
import updateUserSlice from "./features/admin/user/updateUserSlice";
import userProfileSlice from "./features/admin/user/userProfileDetailsSlice";
import screenTranslationSlice from "./features/screenTranslationSlice";
import selectedLanguageSlice from "./features/selectedLanguageSlice";
import dashboardDataSlice from "./features/dashboard/dashboardDataSlice";
import branchSlice from "./features/branch/branchSlice";
import timeTableSlice from "./features/TimeTable/TimeTableSlice";
import instructorSlice from "./features/instructor/instructorSlice";
import MembershipSlice from "./features/Membership/MembershipSlice";
import ClassSlice from "./features/CLasses/ClassSlice";
import RoomSlice from "./features/Room/RoomSlice";
import traditionalScoreBoardReducer from './reducer/scoreBoard/traditionalScoreboardSlice'
import stopWatchReducer from './reducer/scoreBoard/stopWatchSlice'
import ultmScoreboardSlice from "./reducer/scoreBoard/UltmScoreboardSlice";
import traditionalAdccScoreboardSlice from "./reducer/scoreBoard/traditionalAdccScoreboardSlice";
import ibjjfUltmScoreboardSlice from "./reducer/scoreBoard/ibjjfUltmScoreboardSlice";
import ibjjfScoreboardSlice from "./reducer/scoreBoard/ibjjfScoreboardSlice";
import AdccUltmScoreboardSlice from "./reducer/scoreBoard/AdccUltmScoreboardSlice";
import AdccIbjjfScoreBoardSlice from "./reducer/scoreBoard/AdccIbjjfScoreBoardSlice";
import AjpTraditionalScoreBoardSlice from "./reducer/scoreBoard/AjpTraditionalScoreBoardSlice";
import ajpAdccScoreboardSlice from "./reducer/scoreBoard/ajpAdccScoreboardSlice";
import SubOnlySlice from "./reducer/scoreBoard/SubOnlySlice";
import SubUltiSlice from "./reducer/scoreBoard/SubUltiSlice";
import SubUltmSlice from "./reducer/scoreBoard/SubUltmSlice";
import franchiseSlice from "./features/franchise/franchiseSlice";
import UserSlice from './features/User/UserSlice'

const store = configureStore({
  reducer: {
    userLocation: locationSlice,
    appData: appDataSlice,
    loginData: loginDataSlice,
    userDetails: userDetailsSlice,
    userProfileDetails: userProfileSlice,
    updateUserStatus: updateUserStatusSlice,
    updateUser: updateUserSlice,
    translations: screenTranslationSlice,
    selectedLanguage: selectedLanguageSlice,
    dashboardData: dashboardDataSlice,
    branchData: branchSlice,
    timeTableData: timeTableSlice,
    instructorData: instructorSlice,
    MembershipData: MembershipSlice,
    ClassData: ClassSlice,
    franchiseData: franchiseSlice,
    RoomData: RoomSlice,
    UserData: UserSlice,
    stopWatch: stopWatchReducer,
    traditionalScoreBoard: traditionalScoreBoardReducer,
    ultmScoreboard: ultmScoreboardSlice,
    traditionalAdccScoreboard: traditionalAdccScoreboardSlice,
    ibjjfUltmScoreboard: ibjjfUltmScoreboardSlice,
    ibjjfScoreboard: ibjjfScoreboardSlice,
    AdccUltmScoreboard: AdccUltmScoreboardSlice,
    AdccIbjjfScoreBoard: AdccIbjjfScoreBoardSlice,
    AjpTraditionalScoreBoard: AjpTraditionalScoreBoardSlice,
    AjpAdccScoreBoard: ajpAdccScoreboardSlice,
    SubOnly: SubOnlySlice,
    SubUlti: SubUltiSlice,
    SubUltm: SubUltmSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(createStateSyncMiddleware({})),
});
initMessageListener(store)

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

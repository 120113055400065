import styled from "styled-components";
import { fontFamilyMedium } from "../../../components/GlobalStyle";

export const OrganizerSettingCreateCategoryStyle = styled.div`
  .ant-layout > .banner + .ant-layout {
    flex-direction: row;
    gap: 10px;
  }
  .OrganizerSettingCreateCategory-left {
    max-width: 15%;
    width: 100%;
  }
  .wrapper {
    max-width: 705px;
    width: 100%;
  }
  .title {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-top: 37px;
    margin-bottom: 0;
  }
  .form {
    padding: 13px 23px 23px 23px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-top: 11px;
  }
  .mt-11 {
    margin-top: 11px;
  }
`;

import { Dropdown, Menu } from "antd"
import Head from "../../../components/Head/Head"
import { Link, useNavigate } from "react-router-dom"
import actionMenuTogglerIcon from '../../../assets/icons/ic_action_menu_toggler.svg'
import CustomButton from "../../../components/CustomButton/CustomButton"
import { Black, RobinEggBlue, fontFamilyRegularUber } from "../../../components/GlobalStyle"
import { useEffect, useState } from "react"
// import useaffiliate from "../Hooks/useaffiliate"
import defaultPic from '../../../assets/images/create_school_user_profile.svg'
import { List, Rate } from "antd";
import placeHolderImage from "../../../assets/images/custom_card_placeholder.png";
import CardsStyle from "../../Academy/AcademyList/CardsStyle"


const menuProps = (items:any)=>(
    <Menu>
        {items.map((item: any) => {
            if (item.type === 'divider') {
                return <Menu.Divider key={item.key} />
            }

            return (
                <Menu.Item
                    key={item.key}
                    onClick={() => item.onClick()}
                >
                    {item.label}
                </Menu.Item>
            )
        })}
    </Menu>
)

const AffiliateListCards = () => {
    const navigate = useNavigate()
    const imageLs = [{ id: 1, affiliateId: 10 }, { id: 2, affiliateId: 11 }, { id: 3, affiliateId: 12 }, { id: 4, affiliateId: 10 }];
    const desc = ["terrible", "bad", "normal", "good", "wonderful"];
    const [value, setValue] = useState(3);
    const [redirectTo, setRedirectTo] = useState('')
    const [affiliateId, setaffiliateId] = useState(-1)

    useEffect(() => {
        // console.log("first", affiliateId)
        if (redirectTo !== '' && affiliateId !== -1) 
            navigate(`/affiliate/${redirectTo}/${affiliateId}`);
    }, [redirectTo])

    const items:any = [
        {
            key: '1',
            label: 'View',
            onClick: ()=> setRedirectTo('view')
        },
        {
            key: '2',
            label: 'Update',
            onClick: ()=> setRedirectTo('update')

        },
        {
            key: '4',
            label: 'Delete',
            onClick: ()=> setRedirectTo('delete')

        },
        {
            key: 'divider1',
            type: 'divider',
            onClick: ()=> setRedirectTo('')

        },
        {
            key: '5',
            label: 'Reports',
            onClick: ()=> setRedirectTo('reports')

        },
    ]


    return (
        <>
            <Head title="ULTM8 - Affiliate List" />

            <CardsStyle>
                <div className="header">
                    <h3>Affiliate List</h3>
                    <CustomButton
                        bgcolor={RobinEggBlue}
                        textTransform="uppercase"
                        color={Black}
                        padding="8px 20.50px"
                        fontFamily={fontFamilyRegularUber}
                        width="fit-content"
                        type="submit"
                        title="Create"
                        fontSize="14px"
                        loading={false}
                        clicked={() => {
                            navigate(`/affiliate/create`)
                        }}
                    />
                </div>
                <div className="cardsMainContainer">
                    {imageLs.map((item) => {
                        return (
                            <div className="custom_card" key={item.id}>
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<img src={defaultPic} alt="profile" width={40} height={40} />}
                                        title={"Apex Martial Arts Affiliate"}
                                        description="Hutton, United Kingdom"
                                    />
                                    {/* <Space size="middle"> */}
                                    <Dropdown trigger={['click']} overlay={menuProps(items)}>
                                        <img
                                            src={actionMenuTogglerIcon}
                                            alt="action menu"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setaffiliateId(item.affiliateId)
                                            }}
                                        />
                                    </Dropdown>
                                    {/* </Space> */}
                                </List.Item>
                                <div className="custom_card_placeholder_img" id="cardImg">
                                    <img src={placeHolderImage} alt="CardImg" id="cardImg" />
                                </div>
                                <div className="custom_card_para">
                                    Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                                    vulputate libero et velit interdum, ac aliquet odio mattis.
                                </div>
                                <div className="custom_card_footer">
                                    <div className="custom_card_footer_rating">
                                        <Rate tooltips={desc} onChange={setValue} value={value} />
                                        {value ? (
                                            <span className="ant-rate-text">
                                                {/* {desc[value - 1]} */}
                                                <span>4.6</span>
                                                <span> (780+ )</span>
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <Link to={"#"} className="custom_card_footer_link">
                                        Learn More
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </CardsStyle>

        </>
    )
}


export default AffiliateListCards
import { Dropdown, Menu } from "antd"
import Head from "../../../components/Head/Head"
import { Link, useNavigate } from "react-router-dom"
import actionMenuTogglerIcon from '../../../assets/icons/ic_action_menu_toggler.svg'
import CustomButton from "../../../components/CustomButton/CustomButton"
import { Black, RobinEggBlue, fontFamilyRegularUber } from "../../../components/GlobalStyle"
import { useEffect, useState } from "react"
import defaultPic from '../../../assets/images/create_school_user_profile.svg'
import { List, Rate } from "antd";
import placeHolderImage from "../../../assets/images/custom_card_placeholder.png";
import CardsStyle from "./CardsStyle"
import { AcademyDataType } from "../constant"
import useAcademy from "../Hooks/useAcademy"
import api from "../../../api"

const desc = ["terrible", "bad", "normal", "good", "wonderful"];

const menuProps = (items: any) => (
    <Menu>
        {items.map((item: any) => {
            if (item.type === 'divider') {
                return <Menu.Divider key={item.key} />
            }

            return (
                <Menu.Item
                    key={item.key}
                    onClick={() => item.onClick()}
                >
                    {item.label}
                </Menu.Item>
            )
        })}
    </Menu>
)

const AcademyListCards = () => {
    const navigate = useNavigate()
    const {  setIsShowModal, SuccessModal, WarningModal, deleteConfirmation } = useAcademy();

    const [value, setValue] = useState(3);
    const [redirectTo, setRedirectTo] = useState('')
    const [academyId, setAcademyId] = useState(-1)
    const [id, setId] = useState(0)
    const [academy, setAcademy] = useState([]);

    const items: any = [
        {
            key: '1',
            label: 'View',
            onClick: () => setRedirectTo('view')
        },
        {
            key: '2',
            label: 'Update',
            onClick: () => setRedirectTo('update')

        },
        {
            key: '4',
            label: 'Delete',
            onClick: () => {
                setId(academyId)
                setIsShowModal(true)
            },

        },
        {
            key: 'divider1',
            type: 'divider',
            onClick: () => setRedirectTo('')

        },
        {
            key: '5',
            label: 'Reports',
            onClick: () => setRedirectTo('reports')

        },
    ]

    useEffect(() => {
        if (redirectTo !== '' && academyId !== -1)
            navigate(`/academy/${redirectTo}/${academyId}`);
    }, [redirectTo])

    useEffect(()=> {
        fetchAcademies();
    }, [])

    const fetchAcademies = async () => {
        try {
            const response = await api("post", "/api/academy/findall");

            if (response.data.status === "success") {
                setAcademy(response.data.result);
            } else {
                console.error("Failed to fetch academies:", response.data.message);
            }
        } catch (error) {
            console.error("Failed to fetch academies:", error);
        }
    };


    return (
        <>
            {SuccessModal().modalComponent}
            {WarningModal().modalComponent}
            {deleteConfirmation(Number(id), setAcademy).modalComponent}
            <Head title="ULTM8 - Academy List" />

            <CardsStyle>
                <div className="header">
                    <h3>Academy List</h3>
                    <div className="header-buttons">
                        <CustomButton
                            bgcolor="#A3B18A"
                            textTransform="uppercase"
                            color={Black}
                            padding="8px 20.50px"
                            fontFamily={fontFamilyRegularUber}
                            width="fit-content"
                            type="submit"
                            title="Affiliate"
                            fontSize="14px"
                            loading={false}
                            clicked={() => {
                                navigate(`/academy/for-affiliation`)
                            }}
                        />
                        <CustomButton
                            bgcolor="#95ADAD"
                            textTransform="uppercase"
                            color={Black}
                            padding="8px 20.50px"
                            fontFamily={fontFamilyRegularUber}
                            width="fit-content"
                            type="submit"
                            title="My Profile"
                            fontSize="14px"
                            loading={false}
                            clicked={() => {
                                navigate(`/academy/my-profile`)
                            }}
                        />
                        <CustomButton
                            bgcolor={RobinEggBlue}
                            textTransform="uppercase"
                            color={Black}
                            padding="8px 20.50px"
                            fontFamily={fontFamilyRegularUber}
                            width="fit-content"
                            type="submit"
                            title="Create"
                            fontSize="14px"
                            loading={false}
                            clicked={() => {
                                navigate(`/academy/create`)
                            }}
                        />
                    </div>
                </div>
                <div className="cardsMainContainer">
                    {academy?.map((item: AcademyDataType, i) => {
                        return (
                            <div className="custom_card" key={i}>
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<img src={defaultPic} alt="profile" width={40} height={40} />}
                                        title={item?.businessName}
                                        description={item?.address}
                                    />
                                    {/* <Space size="middle"> */}
                                    <Dropdown trigger={['click']} overlay={menuProps(items)}>
                                        <img
                                            src={actionMenuTogglerIcon}
                                            alt="action menu"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setAcademyId(item?.academyId)
                                            }}
                                        />
                                    </Dropdown>
                                    {/* </Space> */}
                                </List.Item>
                                <div className="custom_card_placeholder_img" id="cardImg">
                                    <img src={placeHolderImage} alt="CardImg" id="cardImg" />
                                </div>
                                <div className="custom_card_para">
                                    {`${item?.description.slice(0, 120)}...`}
                                </div>
                                <div className="custom_card_footer">
                                    <div className="custom_card_footer_rating">
                                        <Rate tooltips={desc} onChange={setValue} value={value} />
                                        {value ? (
                                            <span className="ant-rate-text">
                                                <span>4.6</span>
                                                <span> (780+ )</span>
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <Link to={"#"} className="custom_card_footer_link">
                                        Learn More
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </CardsStyle>

        </>
    )
}


export default AcademyListCards
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useGlobalContext } from "../context/context";
import { reset_password_url } from "../utils/api_urls";
import { createNewPasswordValuesType } from "../screens/ForgetPassword/CreatePassword/CreatePassword";

const useCreateNewPassword = (): any => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { userPhoneNumber } = useGlobalContext();

  console.log(location, "location");
  const handleSubmit = async (
    values: createNewPasswordValuesType
  ): Promise<void> => {
    console.log(values, "reset password ");
    const payload = {
      phoneNumber: userPhoneNumber,
      password: values.password,
      resetPasswordToken:localStorage.getItem("resetPasswordToken")
    };
    try {
      setError("");
      setLoading(true);
      const { data } = await axios.post(reset_password_url, payload);
      if (!!data?.message) {
        toast(data.message, {
          type: "success",
          autoClose: 2000,
        });
        setLoading(false);
        return;
      } else {
        toast("Failed Change Password", {
          type: "error",
          autoClose: 2000,
        });
        setLoading(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      toast("Something went Wrong", {
        type: "error",
        autoClose: 2000,
      });
    }
  };
  return {
    loading,
    handleSubmit,
    error,
  };
};

export default useCreateNewPassword;

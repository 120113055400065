import React from "react";
import { OrganizeSettingEditBracketStyle } from "./style";
import { GlobalContainer } from "../../Home/style";
import { Form, Layout } from "antd";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { CreatEventLeftSide } from "../../../components/CreatEventLeftSide/CreatEventLeftSide";
import { Content } from "antd/lib/layout/layout";
import { Formik } from "formik";
import { Row, Col } from "react-bootstrap";
import FormControl from "../../../components/FormControl";
import DeleteBtnIcon from "../../../assets/icons/DeleteBtnIcon.svg";
import CustomButton from "../../../components/CustomButton/CustomButton";
import {
  fontFamilyMediumUber,
  lightBlue4,
  mediumPurple,
  whiteColor,
} from "../../../components/GlobalStyle";
export {};
const OrganizeSettingEditBracket = () => {
  const initialValues = {
    startDate: "",
    endDate: "",
    timeZone: "",
    country: "",
    address: "",
    shortIntroduction: "",
    detail: "",
  };
  const handleCreateSubmit = (): void => {};
  return (
    <div>
      <OrganizeSettingEditBracketStyle>
        <GlobalContainer>
          <Layout>
            <div className="banner">
              <img
                src={AcademyProfileBannerImage}
                alt="AcademyProfileBannerImage"
              />
            </div>
            <Layout>
              <Content className="organizeSettingEditBracket-left">
                <CreatEventLeftSide />
              </Content>
              <Content className="organizeSettingEditBracket-right">
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={handleCreateSubmit}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <h3 className="title">Edit Bracket Packages</h3>
                        <div className="bg-white form">
                          <Row className="align-items-end">
                            <Col md="3">
                              <FormControl
                                control="input"
                                type="text"
                                name="Name"
                                label="Name"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                height="50px"
                                margin="0"
                                max={6}
                                placeholder="Jiu Jitsu"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="2">
                              <FormControl
                                control="select"
                                type="select"
                                name="amountOfAthletes"
                                label="Amount of Athletes"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                max={6}
                                placeholder="1"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="3">
                              <FormControl
                                control="select"
                                type="select"
                                name="manualBrackets"
                                label="Manual Brackets"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                max={6}
                                placeholder="Single Elimination"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="3">
                              <FormControl
                                control="select"
                                type="select"
                                name="bracketsType"
                                label="Brackets Type"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                max={6}
                                placeholder="Bronze match"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="1">
                              <div className="deleteBtn">
                                <img src={DeleteBtnIcon} alt="DeleteBtnIcon" />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-end mt-2">
                            <Col md="3">
                              <FormControl
                                control="input"
                                type="text"
                                name="Name2"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                height="50px"
                                margin="0"
                                max={6}
                                placeholder="Jiu Jitsu"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="2">
                              <FormControl
                                control="select"
                                type="select"
                                name="amountOfAthletes2"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                max={6}
                                placeholder="1"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="3">
                              <FormControl
                                control="select"
                                type="select"
                                name="manualBrackets2"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                max={6}
                                placeholder="Single Elimination"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="3">
                              <FormControl
                                control="select"
                                type="select"
                                name="bracketsType2"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                max={6}
                                placeholder="Bronze match"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="1">
                              <div className="deleteBtn">
                                <img src={DeleteBtnIcon} alt="DeleteBtnIcon" />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-end mt-2">
                            <Col md="3">
                              <FormControl
                                control="input"
                                type="text"
                                name="Name3"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                height="50px"
                                margin="0"
                                max={6}
                                placeholder="Jiu Jitsu"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="2">
                              <FormControl
                                control="select"
                                type="select"
                                name="amountOfAthletes3"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                max={6}
                                placeholder="1"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="3">
                              <FormControl
                                control="select"
                                type="select"
                                name="manualBrackets3"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                max={6}
                                placeholder="Single Elimination"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="3">
                              <FormControl
                                control="select"
                                type="select"
                                name="bracketsType3"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                max={6}
                                placeholder="Bronze match"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="1">
                              <div className="deleteBtn">
                                <img src={DeleteBtnIcon} alt="DeleteBtnIcon" />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-end mt-2">
                            <Col md="3">
                              <FormControl
                                control="input"
                                type="text"
                                name="Name4"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                height="50px"
                                margin="0"
                                max={6}
                                placeholder="Jiu Jitsu"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="2">
                              <FormControl
                                control="select"
                                type="select"
                                name="amountOfAthletes4"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                max={6}
                                placeholder="1"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="3">
                              <FormControl
                                control="select"
                                type="select"
                                name="manualBrackets4"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                max={6}
                                placeholder="Single Elimination"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="3">
                              <FormControl
                                control="select"
                                type="select"
                                name="bracketsType4"
                                padding="10px"
                                fontSize="16px"
                                placeholderFont="16px"
                                placeholderFamily="fontFamilyRegularUber"
                                max={6}
                                placeholder="Bronze match"
                                className={
                                  formik.errors.timeZone &&
                                  formik.touched.timeZone
                                    ? "is-invalid"
                                    : "customInput"
                                }
                              />
                            </Col>
                            <Col md="1">
                              <div className="deleteBtn">
                                <img src={DeleteBtnIcon} alt="DeleteBtnIcon" />
                              </div>
                            </Col>
                          </Row>
                          <div className="addOpt">
                            <span>Add More Options</span>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Content>
            </Layout>
          </Layout>
        </GlobalContainer>
      </OrganizeSettingEditBracketStyle>
    </div>
  );
};

export default OrganizeSettingEditBracket;

import { Field, ErrorMessage } from "formik";
import ErrorMsg from "../ErrorMessage";
import { Input } from "antd";
import CustomPasswordInputStyle from "./style";
import show_password_icon from "../../assets/icons/ic_show_passcode.svg";
import hide_password_icon from "../../assets/icons/ic_hidePassword.svg";


import {
  fontFamilyEnnVisionsMedium,
  fontFamilyEnnVisionsRegular,
  fontFamilyMedium,
  fontFamilyRegular,
  fontFamilyRegularUber,
  tertiaryGrewish,
} from "../GlobalStyle";
import useScreenTranslation from "../../hooks/useScreenTranslation";

type CustomPasswordInputProps = {
  label: string;
  className: string;
  placeholder: string;
  name: string;
  fontFamily?: string;
  border?: string;
  showErrorMessage?: boolean;
  padding?: string;
  placeholderFamily?: "UberMove-Regular" | "UberMove-Medium" | "UberMove-Bold" | "OperaThin" | "OperaLight" | "OperaRegular" | "OperaMedium" | "OperaBold" | "OperaBlack" | "EnnVisions" | "EnnVisionsMedium" | "EnnVisionsBold" | "FuturaCondensed-Regular" | "FuturaCondensed-Bold";
  placeholderFont?: string;
  fontSize?: string;
  labelFont?: string;
  labelFamily?: string;
  labelMarginBottom?: string;
};

const CustomPasswordInput = (props: CustomPasswordInputProps) => {
  const {
    label,
    className,
    placeholder,
    name,
    labelFont = "14px",
    labelFamily = fontFamilyRegularUber,
    labelMarginBottom = "7px",
    fontFamily = fontFamilyRegularUber,
    border = `1px solid ${tertiaryGrewish}`,
    showErrorMessage = true,
    padding = "10px",
    placeholderFamily = fontFamilyRegularUber,
    placeholderFont = "14px",
    fontSize = "14px",
    ...rest
  } = props;
  const { getLabelByKey } = useScreenTranslation("loginScreen");
  return (
    <CustomPasswordInputStyle
      fontFamily={fontFamily}
      border={border}
      padding={padding}
      placeholderFamily={placeholderFamily}
      placeholderFont={placeholderFont}
      fontSize={fontSize}
      labelFamily={labelFamily}
      labelFont={labelFont}
      labelMarginBottom={labelMarginBottom}
    >
      <label htmlFor={name}>{label}</label>
      <Field name={name} id={name}>
        {({ field, form, meta }: any) => (
          <Input.Password
            className={className}
            {...rest}
            placeholder={placeholder}
            {...field}
            iconRender={(visible) =>
              visible ? (
                <span className="paswordIconLabel">
                  {/* {getLabelByKey("hide")} */}
                  <img src={hide_password_icon} alt="" />
                </span>
              ) : (
                <span className="paswordIconLabel">
                  {/* {getLabelByKey("show")} */}
                  <img src={show_password_icon} alt="" />
                </span>
              )
            }
          />
        )}
      </Field>
      {showErrorMessage && <ErrorMessage name={name} component={ErrorMsg} />}
    </CustomPasswordInputStyle>
  );
};

export default CustomPasswordInput;

import React from 'react'
import { OrganizerEventListStyle } from "./style"
import UpComingEventsCards from "../../AjpScoreBoard/TicketListing/TicketListing";

const OrganizerEventList = () => {
    const itemsArray = Array.from({ length: 6 }, (_, index) => index + 1);
    return (
        <div>
            <OrganizerEventListStyle>
                <div className="title_AffiliationButton">
                    <h4 className="title">Events</h4>
                    <button className="newAffiliationButton">New Event</button>
                </div>

                <div className='eventLists'>
                    {itemsArray.map((item, index) => (
                        <UpComingEventsCards key={index} />
                    ))}
                </div>

            </OrganizerEventListStyle>
        </div>
    )
}

export default OrganizerEventList
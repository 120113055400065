import React from "react";
import { BuyTicketsSelectUser2Style } from "./style";
import ProfileImage from "../../../assets/images/SelectProfileIMage.png";
import EngFlag from "../../../assets/images/EnglandFlag.svg";
import MoreOptionsSvg from "../../../assets/icons/Status-ThreeDotSVG.svg";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import { Progress } from "flowbite-react";
import DropdownIcon from "../../../assets/icons/ic_drop_down.svg";
const BuyTicketsSelectUser2 = () => {
  return (
    <div>
      <BuyTicketsSelectUser2Style>
        <div className="banner">
          <img
            src={AcademyProfileBannerImage}
            alt="AcademyProfileBannerImage"
          />
        </div>

        <div className="bodyWrapper">
          <div className="mainContainer">
            <div className="title">
              <h4>Entries</h4>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Entries</th>
                  <th>Fee</th>
                  <th>Belt</th>
                  <th>Age</th>
                  <th>Weight</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className="entries">
                    <span>Boys GI</span>
                  </td>
                  <td className="fee">
                    <span>£ 900.00 GBP</span>
                  </td>
                  <td className="belt">
                    <div className="beltMaine">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </div>
                  </td>
                  <td className="age">
                    <div>
                      <div className="ageButton">
                        <select>
                          <option>MASTER 3 (42 year olds)</option>
                          <option>MASTER 4 (45 year olds)</option>
                          <option>MASTER 5 (48 year olds)</option>
                          <option>MASTER 6 (54 year olds)</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="weight">
                    <div className="weightButtn">
                      <select>
                        <option>57.9</option>
                        <option>57.6</option>
                        <option>59.8</option>
                        <option>65.3</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <button className="registerButton">Register</button>
                  </td>
                  <td className="threeDotSvg">
                    <img src={MoreOptionsSvg} alt="MoreOptionsSvg" />
                  </td>
                </tr>

                <tr>
                  <td className="entries">
                    <span>Boys GI</span>
                  </td>
                  <td className="fee">
                    <span>£ 900.00 GBP</span>
                  </td>
                  <td className="belt">
                    <div className="beltMaine">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </div>
                  </td>
                  <td className="age">
                    <div>
                      <div className="ageButton">
                        <select>
                          <option>MASTER 3 (42 year olds)</option>
                          <option>MASTER 4 (45 year olds)</option>
                          <option>MASTER 5 (48 year olds)</option>
                          <option>MASTER 6 (54 year olds)</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="weight">
                    <div className="weightButtn">
                      <select>
                        <option>57.9</option>
                        <option>57.6</option>
                        <option>59.8</option>
                        <option>65.3</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <button className="registerButton">Register</button>
                  </td>
                  <td className="threeDotSvg">
                    <img src={MoreOptionsSvg} alt="MoreOptionsSvg" />
                  </td>
                </tr>

                <tr>
                  <td className="entries">
                    <span>Boys GI</span>
                  </td>
                  <td className="fee">
                    <span>£ 900.00 GBP</span>
                  </td>
                  <td className="belt">
                    <div className="beltMaine">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </div>
                  </td>
                  <td className="age">
                    <div>
                      <div className="ageButton">
                        <select>
                          <option>MASTER 3 (42 year olds)</option>
                          <option>MASTER 4 (45 year olds)</option>
                          <option>MASTER 5 (48 year olds)</option>
                          <option>MASTER 6 (54 year olds)</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="weight">
                    <div className="weightButtn">
                      <select>
                        <option>57.9</option>
                        <option>57.6</option>
                        <option>59.8</option>
                        <option>65.3</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <button className="registerButton">Register</button>
                  </td>
                  <td className="threeDotSvg">
                    <img src={MoreOptionsSvg} alt="MoreOptionsSvg" />
                  </td>
                </tr>

                <tr>
                  <td className="entries">
                    <span>Boys GI</span>
                  </td>
                  <td className="fee">
                    <span>£ 900.00 GBP</span>
                  </td>
                  <td className="belt">
                    <div className="beltMaine">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </div>
                  </td>
                  <td className="age">
                    <div>
                      <div className="ageButton">
                        <select>
                          <option>MASTER 3 (42 year olds)</option>
                          <option>MASTER 4 (45 year olds)</option>
                          <option>MASTER 5 (48 year olds)</option>
                          <option>MASTER 6 (54 year olds)</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="weight">
                    <div className="weightButtn">
                      <select>
                        <option>57.9</option>
                        <option>57.6</option>
                        <option>59.8</option>
                        <option>65.3</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <button className="registerButton">Register</button>
                  </td>
                  <td className="threeDotSvg">
                    <img src={MoreOptionsSvg} alt="MoreOptionsSvg" />
                  </td>
                </tr>

                <tr>
                  <td className="entries">
                    <span>Boys GI</span>
                  </td>
                  <td className="fee">
                    <span>£ 900.00 GBP</span>
                  </td>
                  <td className="belt">
                    <div className="beltMaine">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </div>
                  </td>
                  <td className="age">
                    <div>
                      <div className="ageButton">
                        <select>
                          <option>MASTER 3 (42 year olds)</option>
                          <option>MASTER 4 (45 year olds)</option>
                          <option>MASTER 5 (48 year olds)</option>
                          <option>MASTER 6 (54 year olds)</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="weight">
                    <div className="weightButtn">
                      <select>
                        <option>57.9</option>
                        <option>57.6</option>
                        <option>59.8</option>
                        <option>65.3</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <button className="registerButton">Register</button>
                  </td>
                  <td className="threeDotSvg">
                    <img src={MoreOptionsSvg} alt="MoreOptionsSvg" />
                  </td>
                </tr>

                <tr>
                  <td className="entries">
                    <span>Boys GI</span>
                  </td>
                  <td className="fee">
                    <span>£ 900.00 GBP</span>
                  </td>
                  <td className="belt">
                    <div className="beltMaine">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </div>
                  </td>
                  <td className="age">
                    <div>
                      <div className="ageButton">
                        <select>
                          <option>MASTER 3 (42 year olds)</option>
                          <option>MASTER 4 (45 year olds)</option>
                          <option>MASTER 5 (48 year olds)</option>
                          <option>MASTER 6 (54 year olds)</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="weight">
                    <div className="weightButtn">
                      <select>
                        <option>57.9</option>
                        <option>57.6</option>
                        <option>59.8</option>
                        <option>65.3</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <button className="registerButton">Register</button>
                  </td>
                  <td className="threeDotSvg">
                    <img src={MoreOptionsSvg} alt="MoreOptionsSvg" />
                  </td>
                </tr>

                <tr>
                  <td className="entries">
                    <span>Boys GI</span>
                  </td>
                  <td className="fee">
                    <span>£ 900.00 GBP</span>
                  </td>
                  <td className="belt">
                    <div className="beltMaine">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </div>
                  </td>
                  <td className="age">
                    <div>
                      <div className="ageButton">
                        <select>
                          <option>MASTER 3 (42 year olds)</option>
                          <option>MASTER 4 (45 year olds)</option>
                          <option>MASTER 5 (48 year olds)</option>
                          <option>MASTER 6 (54 year olds)</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="weight">
                    <div className="weightButtn">
                      <select>
                        <option>57.9</option>
                        <option>57.6</option>
                        <option>59.8</option>
                        <option>65.3</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <button className="registerButton">Register</button>
                  </td>
                  <td className="threeDotSvg">
                    <img src={MoreOptionsSvg} alt="MoreOptionsSvg" />
                  </td>
                </tr>

                <tr>
                  <td className="entries">
                    <span>Boys GI</span>
                  </td>
                  <td className="fee">
                    <span>£ 900.00 GBP</span>
                  </td>
                  <td className="belt">
                    <div className="beltMaine">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </div>
                  </td>
                  <td className="age">
                    <div>
                      <div className="ageButton">
                        <select>
                          <option>MASTER 3 (42 year olds)</option>
                          <option>MASTER 4 (45 year olds)</option>
                          <option>MASTER 5 (48 year olds)</option>
                          <option>MASTER 6 (54 year olds)</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="weight">
                    <div className="weightButtn">
                      <select>
                        <option>57.9</option>
                        <option>57.6</option>
                        <option>59.8</option>
                        <option>65.3</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <button className="registerButton">Register</button>
                  </td>
                  <td className="threeDotSvg">
                    <img src={MoreOptionsSvg} alt="MoreOptionsSvg" />
                  </td>
                </tr>

                <tr>
                  <td className="entries">
                    <span>Boys GI</span>
                  </td>
                  <td className="fee">
                    <span>£ 900.00 GBP</span>
                  </td>
                  <td className="belt">
                    <div className="beltMaine">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </div>
                  </td>
                  <td className="age">
                    <div>
                      <div className="ageButton">
                        <select>
                          <option>MASTER 3 (42 year olds)</option>
                          <option>MASTER 4 (45 year olds)</option>
                          <option>MASTER 5 (48 year olds)</option>
                          <option>MASTER 6 (54 year olds)</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="weight">
                    <div className="weightButtn">
                      <select>
                        <option>57.9</option>
                        <option>57.6</option>
                        <option>59.8</option>
                        <option>65.3</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <button className="registerButton">Register</button>
                  </td>
                  <td className="threeDotSvg">
                    <img src={MoreOptionsSvg} alt="MoreOptionsSvg" />
                  </td>
                </tr>

                <tr>
                  <td className="entries">
                    <span>Boys GI</span>
                  </td>
                  <td className="fee">
                    <span>£ 900.00 GBP</span>
                  </td>
                  <td className="belt">
                    <div className="beltMaine">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </div>
                  </td>
                  <td className="age">
                    <div>
                      <div className="ageButton">
                        <select>
                          <option>MASTER 3 (42 year olds)</option>
                          <option>MASTER 4 (45 year olds)</option>
                          <option>MASTER 5 (48 year olds)</option>
                          <option>MASTER 6 (54 year olds)</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="weight">
                    <div className="weightButtn">
                      <select>
                        <option>57.9</option>
                        <option>57.6</option>
                        <option>59.8</option>
                        <option>65.3</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <button className="registerButton">Register</button>
                  </td>
                  <td className="threeDotSvg">
                    <img src={MoreOptionsSvg} alt="MoreOptionsSvg" />
                  </td>
                </tr>

                <tr>
                  <td className="entries">
                    <span>Boys GI</span>
                  </td>
                  <td className="fee">
                    <span>£ 900.00 GBP</span>
                  </td>
                  <td className="belt">
                    <div className="beltMaine">
                      <div className="flex flex-col gap-2">
                        <Progress progress={40} size="md" color="#000" />
                      </div>
                    </div>
                  </td>
                  <td className="age">
                    <div>
                      <div className="ageButton">
                        <select>
                          <option>MASTER 3 (42 year olds)</option>
                          <option>MASTER 4 (45 year olds)</option>
                          <option>MASTER 5 (48 year olds)</option>
                          <option>MASTER 6 (54 year olds)</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="weight">
                    <div className="weightButtn">
                      <select>
                        <option>57.9</option>
                        <option>57.6</option>
                        <option>59.8</option>
                        <option>65.3</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <button className="registerButton">Register</button>
                  </td>
                  <td className="threeDotSvg">
                    <img src={MoreOptionsSvg} alt="MoreOptionsSvg" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </BuyTicketsSelectUser2Style>
    </div>
  );
};

export default BuyTicketsSelectUser2;

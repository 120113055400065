import styled from "styled-components";
import { fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegularUber, lightDark2, pureDark2, whiteColor } from "../../../components/GlobalStyle";

export const CoachProfileEventRegistrationEvents2Style =  styled.div`
.bodyWrapper_RightSide {
    width: 100%;
    margin-top: 12px;
}
.title_AffiliationButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 13px;
}
.title {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.newAffiliationButton {
    padding: 11px 10px 10px 15px;
    border-radius: 4px;
    border: 0.6px solid #BBE6C3;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);
    color: ${whiteColor};
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
}
.affiliationMemberships_CardContainer {
    margin-top: 11px;
    padding-left: 13px;
    display: flex;
    flex-wrap: wrap;
    gap: 14.4px 18px;
}

.custom_card_placeholder_img{
  position: relative;
  img{
    position: relative;
  }
  label{
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 10.688px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.559px;
    border-radius: 3px;
    background: #7BB037;
    padding: 3px 6px;
    position: absolute;
    top: 6px;
    z-index: 9;
    left: 6px;
  }
}


.custom_card_list{
    gap: 14.4px 18px;
    flex-wrap: wrap;
}
  .custom_card {
    max-width: 32.1%;
    width: 100%;
    background-color: #fff;
    border: 0.6px solid #EAEAEA;
    border-radius: 5px;
  
    .ant-list-item {
      padding: 16px 12px;
      display: flex;
      align-items: center;
      &-meta{
        align-items: center;
      }
      &-meta-title {
        font-family: EnnVisionsMedium;
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: ${pureDark2};
      }
      &-meta-description {
        font-family: EnnVisions;
        font-size: 12px;
        font-weigt: 400;
        color: ${lightDark2};
      }
    }
  
    &_placeholder_img {
      width: 100%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  
    &_para {
        color: ${lightDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 10.688px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        padding: 20.04px 22.05px 0 22.71px;
    }
  
    &_footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-end;
      padding: 16.78px 13.47px 19.06px 22.71px;
     
      &_rating {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        .ant-rate {
          font-size: 16px;
          line-height: normal;
          &-star {
            margin-right: 2px;
            svg{
              width: 12.64px;
              height: 12.64px;
            }
          }
        }
        .ant-rate-text span:first-child{
          color: #1C394B;
          font-family: EnnVisionsMedium;
          font-size: 11.895px;
          font-style: normal;
          font-weight: 500;
          line-height: 13.977px;
        }
        .ant-rate-text span:nth-child(2){
          color: #1F3340;
          font-family: EnnVisions;
          font-size: 11.895px;
          font-style: normal;
          font-weight: 400;
          line-height: 13.977px;
        }
      }
  
      &_link {
        font-family: EnnVisionsMedium;
        color: #b3b3b3;
        font-size: 11.895px;
        font-style: normal;
        font-weight: 500;
        line-height: 13.977px;
      }
    }
  }


.dostSvg {
    display: flex;
    flex-direction: column;
}

.pagination_Container {
    margin: 87.4px 0 24px;
    padding-left: 18px;
}
`;
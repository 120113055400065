import { ScoreBoardTableStyle } from "./style";
interface IScoreBoardTable {
  currentTeam: number,
  handleScoreBoardScore: (_currentTeam: number, _score: number) => void,
  handleScoreBoardAdvantage: (_currentTeam: number, _score: number) => void,
  handleScoreBoardPenalty: (_currentTeam: number, _score: number) => void,
  showStallingModalHandler: () => void,
  showMedicalModalHandler: () => void,
  showTimeoutModalHandler: () => void,
  showVarModalHandler: () => void,
}

const ScoreBoardTable = ({
  currentTeam,
  handleScoreBoardScore,
  handleScoreBoardAdvantage,
  handleScoreBoardPenalty,
  showStallingModalHandler,
  showMedicalModalHandler,
  showTimeoutModalHandler,
  showVarModalHandler
}: IScoreBoardTable) => {
  
  const handleTableClick = (event: any) => {
    event.stopPropagation();
  };

  return (
    <ScoreBoardTableStyle className="scoreboard-table">
      <div className="boardScoreAdd" onClick={handleTableClick}>

          <table width="100%">
            <tr>
              <td colSpan={6}>
                <table width="100%">
                  <tr>
                    {[1,2,3,4].map((score, index) =>  
                    <td key={index} onClick={() => handleScoreBoardScore(currentTeam, score)}>+{score}</td>
                    )}
                    <td onClick={() =>handleScoreBoardAdvantage(currentTeam, 1)}>+A</td>
                    <td onClick={() =>handleScoreBoardPenalty(currentTeam, 1)}>+P</td>
                    <td onClick={showStallingModalHandler}>STALLING</td>
                    <td onClick={showMedicalModalHandler}>
                      MEDICAL
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <table width="100%">
                  <tr>
                  {[1,2,3,4].map((score, index) =>  
                    <td key={index} onClick={() =>handleScoreBoardScore(currentTeam, -score)}>-{score}</td>
                  )}
                    <td onClick={() =>handleScoreBoardAdvantage(currentTeam, -1)}>-A</td>
                    <td onClick={() => handleScoreBoardPenalty(currentTeam, -1)}>-P</td>
                    <td onClick={showTimeoutModalHandler}>TIMEOUT</td>
                    <td onClick={showVarModalHandler} >VAR</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        
      </div>
    </ScoreBoardTableStyle>
  );
};

export default ScoreBoardTable;

import React, { useState } from "react";
import { ProfileAfterLoginTabsStyle } from "./style";
import TabNavItem from "../TabNavItem/TabNavItem";
import TabContent from "../TabContent/tabContent";
import UserProfileAcadmyEventAffiliation from "../../screens/ProfilesAfterLoginContent/UserProfileAcadmyEventAffiliation/UserProfileAcadmyEventAffiliation";
import AffiliationsProfileOverallAcademyResult from "../../screens/ProfilesAfterLoginContent/AffiliationsProfileOverallAcademyResult/AffiliationsProfileOverallAcademyResult";
import AffiliationsProfileOverallAcademyAdultsAfterLog from "../../screens/ProfilesAfterLoginContent/AffiliationsProfileOverallAcademyAdultsAfterLog/AffiliationsProfileOverallAcademyAdults";
const ProfileAfterLoginTabs = () => {
  const [activeTab, setActiveTab] = useState<string>("userProfile");
  const propsToPass = {
    message: "none",
  };
  return (
    <div>
      <ProfileAfterLoginTabsStyle>
        <div className="Tabs">
          <ul className="nav">
            <TabNavItem
              title="User Profile"
              id="userProfile"
              setActiveTab={() => setActiveTab("userProfile")}
              activeTab={activeTab}
            />

            <TabNavItem
              title="Coach Manager"
              id="coachManager"
              setActiveTab={() => setActiveTab("coachManager")}
              activeTab={activeTab}
            />

            <TabNavItem
              title="Organizer Manager"
              id="organizerManager"
              setActiveTab={() => setActiveTab("organizerManager")}
              activeTab={activeTab}
            />

            <TabNavItem
              title="Affiliation Manager"
              id="affiliationManager"
              setActiveTab={() => setActiveTab("affiliationManager")}
              activeTab={activeTab}
            />
          </ul>

          <div>
            <TabContent id="userProfile" activeTab={activeTab}>
              <UserProfileAcadmyEventAffiliation />
            </TabContent>

            <TabContent id="coachManager" activeTab={activeTab}>
              <AffiliationsProfileOverallAcademyResult />
            </TabContent>

            <TabContent id="organizerManager" activeTab={activeTab}>
              <AffiliationsProfileOverallAcademyAdultsAfterLog/>
            </TabContent>
          </div>
        </div>
      </ProfileAfterLoginTabsStyle>
    </div>
  );
};

export default ProfileAfterLoginTabs;

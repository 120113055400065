import React from "react";
import { AllMatTvModeStyle } from "./style";
import StarRating from "../../assets/icons/star-rating.svg";
import BeltImage from "../../assets/images/progressbar.svg";
import DropDownImage from "../../assets/images/Down_Arrow_3_.svg";

export const AllMatTvMode = () => {
  return (
    <div>
      <AllMatTvModeStyle>
        <div className="wrapper">
          <div className="headingMain">
            <h2>London Jiu Jitsu Open Gi and NOGI championship</h2>
          </div>
          <div className="dateTime_main">
            <div className="DateTime">
              <h6>Monday, 17th October 2023.</h6>
            </div>
            <div className="RatingPoints">
              <img src={StarRating} alt="" className="starImg" />
              <p className="rating">
                4.6<span> (780+ )</span>
              </p>
            </div>
          </div>

          <div className="resultSchedule_Main">
            <div className="resultScheduleCard_Left">
              <div className="schedule-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Mat1-Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">
                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container scheduleExpand_Row2 d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="resultScheduleCard_mid">
              <div className="schedule-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Mat1-Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">
                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container scheduleExpand_Row2 d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="resultScheduleCard_right">
              <div className="schedule-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Mat1-Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">
                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container scheduleExpand_Row2 d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="resultSchedule_Main resultSchedule_MainBlock2">
            <div className="resultScheduleCard_Left">
              <div className="schedule-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Mat1-Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">
                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container scheduleExpand_Row2 d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="resultScheduleCard_mid">
              <div className="schedule-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Mat1-Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">
                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container scheduleExpand_Row2 d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="resultScheduleCard_right">
              <div className="schedule-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Mat1-Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">
                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container scheduleExpand_Row2 d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>

                <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="Schedule d-flex">
                      <p className="Mat-Schedule">
                        Male Gi / White / Adult / -76 kg (Light)
                      </p>
                    </div>
                    <div className="progress-bar">
                      <progress
                        value={100}
                        max={100}
                        className="progress-background"
                      ></progress>
                    </div>
                  </div>

                  <div className="section-2-sceduleCardExpand">
                    <div className="section2-ExpandCard d-flex align-items-center">
                      <div className="section-time">
                        <p>Eta:14.10pm</p>
                      </div>

                      <div className="matches">
                        <p>8 Matches</p>
                      </div>
                    </div>

                    <div className="progbar-img">
                      <img src={BeltImage} alt="BeltImage" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AllMatTvModeStyle>
    </div>
  );
};

export default AllMatTvMode;

import React, { useState } from "react";
import { ResetPasswordConfirmationStyle, OtpInputsStyled } from "./style";
import { Link } from "react-router-dom";
import useScreenTranslation from "../../hooks/useScreenTranslation";
import { useGlobalContext } from "../../context/context";
import * as Yup from "yup";
import { Form, Input } from "antd";
import useVerifyOtp from "../../hooks/useVerifyOtp";
import { Field, FieldProps, Formik } from "formik";
import CustomButton from "../../components/CustomButton/CustomButton";
import { fontFamilyRegularUber, pureDark } from "../../components/GlobalStyle";

export interface OtpPropValues {
  input0: string;
  input1: string;
  input2: string;
  input3: string;
}
interface OtpProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreatePassModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResetPasswordConfirmation = () => {
  const initialValues: { code: string } = {
    code: "",
  };
  const { getLabelByKey } = useScreenTranslation("veriﬁcationPin");
  const { userPhoneNumber } = useGlobalContext();

  const validationSchema = Yup.object().shape({
    code: Yup.string().required("OTP Code is Required"),
  });

  const initialTimer = { minutes: 2, seconds: 0 };
  const [timer, setTimer] = useState(initialTimer);

  const { handleSubmit, loading } = useVerifyOtp();

  const formatTimer = () => {
    const { minutes, seconds } = timer;
    let timerText = "";
    if (minutes > 0) {
      timerText += `The OTP will expire in ${minutes} minute${
        minutes > 1 ? "s" : ""
      }`;
    }
    if (seconds > 0) {
      if (minutes > 0) {
        timerText += " and ";
      }
      timerText += `${seconds} second${seconds > 1 ? "s" : ""}`;
    }
    return timerText;
  };

  function setIsModalVisible(arg0: boolean) {
    throw new Error("Function not implemented.");
  }

  function setIsCreatePassModalVisible(arg0: boolean) {
    throw new Error("Function not implemented.");
  }

  return (
    <div>
      <ResetPasswordConfirmationStyle>
        <h2>Verify OTP</h2>
        <div className="messageBodyBG">
          <p>
            Verify your phone number to receive an OTP for resetting your
            password. Note that this OTP will expire within 5 minutes.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, errors, touched, values }) => (
              <Form onFinish={handleSubmit}>
                <OtpInputsStyled>
                  {[0, 1, 2, 3, 4, 5].map((index) => {
                    return (
                      <>
                        <Field name={`input${index}`} key={index}>
                          {({ field, meta }: FieldProps<string>) => (
                            <Input
                              {...field}
                              className="customInput otp-input"
                              placeholder="-"
                              maxLength={1}
                              onKeyUp={(e) => {
                                if (e.key.match(/^\d$/)) {
                                  const nextIndex =
                                    index < 3 ? index + 1 : index;
                                  if (
                                    (values as any)[`input${nextIndex}`] === ""
                                  ) {
                                    const nextField =
                                      document.getElementsByName(
                                        `input${nextIndex}`
                                      )[0];
                                    nextField?.focus();
                                  }
                                }
                              }}
                            />
                          )}
                        </Field>
                      </>
                    );
                  })}
                </OtpInputsStyled>

                <div className="mt-20">
                  <CustomButton
                    bgcolor="#DEF2E2"
                    textTransform="Capitalize"
                    color={pureDark}
                    padding="13.5px"
                    fontFamily={`${fontFamilyRegularUber}`}
                    width="100%"
                    type="submit"
                    title="Submit"
                    fontSize="16px"
                    loading={loading}
                    clicked={() => {
                      setIsModalVisible(false);
                      setIsCreatePassModalVisible(true);
                    }}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ResetPasswordConfirmationStyle>
    </div>
  );
};

export default ResetPasswordConfirmation;

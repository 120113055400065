import React, { useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton/CustomButton'
import { Black, RobinEggBlue, fontFamilyEnnVisionsRegular, fontFamilyRegularUber } from '../../../components/GlobalStyle'
import { Col, Row } from 'react-bootstrap'
import { Form } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import { FormPageStyle } from '../../Academy/AcademyCreate/style'
import FormControl from '../../../components/FormControl'
import CheckboxesSelect from '../../../components/CustomCheckbox/CheckboxesSelect'
import PlacesAutoCompleteInput from '../../../maps/PlacesAutocomplete'
import CustomPhoneInput from '../../../components/CustomPhoneInput/CustomPhoneInput'
import { DataTypesWithIdAndMultipleLangLabel } from '../../../app/features/types'
import { CoachDataType, CoachInitialValues } from '../constant'
import Head from '../../../components/Head/Head'
import { useParams } from 'react-router-dom'
import useCoach from '../Hooks/useCoach'
import { RootState } from "../../../app/store"
import { useSelector } from "react-redux"

const CoachUpdate = () => {
    const { coachId } = useParams()
    const [coachData, setCoachData] = useState<CoachDataType>()
    const { handleCoachUpdate, getCoachView, SuccessModal, WarningModal } = useCoach()
    const { data } = useSelector((state: RootState) => state.loginData);

    const updateCoach = async (value: any): Promise<void> => {
        await handleCoachUpdate(Number(coachId), value)
    }

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const response: any = await getCoachView(Number(coachId))
                if (response) {
                    setCoachData(response)
                }
            } catch {
            }
        }
        fetchData()
    }, [])

    const initialValues: CoachInitialValues = {
        userId: data.user.id,
        instructorName: coachData ? coachData.instructorName : '--',
        emailAddress: coachData ? coachData.emailAddress : '--',
        phoneNumber: coachData ? coachData.phoneNumber : '--',
        address: coachData?.address,
        experience: coachData ? coachData.experience : 2,
        rankId: coachData ? coachData.rankId : 2,
        certificationURL: coachData ? coachData.certificationURL : '--',
        defaultLanguageId: coachData ? coachData.defaultLanguageId : 0,
        activities: coachData ? coachData.activities : [],
        specializations: coachData ? coachData.specializations : [],
        description: coachData ? coachData.description : '--',
    }

    const activitiesDummyList: DataTypesWithIdAndMultipleLangLabel[] = [
        { id: "1", en: "Football", es: "Fútbol", pt: "Futebol", value: "Football", label: "Football", ar: "", ur: "", imageUrl: "" },
        { id: "2", en: "Basketball", es: "Baloncesto", pt: "Basquetebol", value: "Basketball", label: "Basketball", ar: "", ur: "", imageUrl: "" },
        { id: "3", en: "Swimming", es: "Natación", pt: "Natação", value: "Swimming", label: "Swimming", ar: "", ur: "", imageUrl: "" },
        // Add more activities as needed
    ];

    const specializationDummyList: DataTypesWithIdAndMultipleLangLabel[] = [
        { id: "1", en: "Gym", es: "Gimnasio", pt: "Academia", value: "Gym", label: "Gym", ar: "", ur: "", imageUrl: "" },
        { id: "2", en: "Library", es: "Biblioteca", pt: "Biblioteca", value: "Library", label: "Library", ar: "", ur: "", imageUrl: "" },
        { id: "3", en: "Computer Lab", es: "Laboratorio de Computación", pt: "Laboratório de Informática", value: "Computer Lab", label: "Computer Lab", ar: "", ur: "", imageUrl: "" },
        // Add more facilities as needed
    ];

    const onsubmit = () => { }

    return (
        <FormPageStyle>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={onsubmit}
            >
                {(formik) => {
                    return (
                        <>
                            {SuccessModal().modalComponent}
                            {WarningModal().modalComponent}
                            <Head title='ULTM8 - Coach Update' />
                            <Form
                                name="basic"
                                onFinish={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <div className="bg-white form mt-20">
                                    <h3>Coach Update</h3>
                                    <Row>
                                        <Col md="4" className="mt-20">
                                            <FormControl
                                                control="input"
                                                type="text"
                                                name="instructorName"
                                                label="Coach Name"
                                                fontSize="16px"
                                                max={6}
                                                placeholder="Enter Coach Name"
                                                className={
                                                    formik.errors
                                                        .instructorName &&
                                                        formik.touched.instructorName
                                                        ? 'is-invalid'
                                                        : 'customInput'
                                                }
                                            />
                                        </Col>
                                        <Col md="4" className="mt-20">
                                            <FormControl
                                                control="input"
                                                type="email"
                                                name="emailAddress"
                                                label="Coach Email"
                                                fontSize="16px"
                                                max={6}
                                                placeholder="Enter Coach Email"
                                                className={
                                                    formik.errors
                                                        .emailAddress &&
                                                        formik.touched.emailAddress
                                                        ? 'is-invalid'
                                                        : 'customInput'
                                                }
                                            />
                                        </Col>
                                        <Col md="4" className="mt-20">
                                            <CustomPhoneInput
                                                label="Phone Number"
                                                name="phoneNumber"
                                                value={
                                                    formik.values
                                                        .phoneNumber
                                                }
                                                placeholder="Enter Phone Number"
                                                limitMaxLength={true}
                                                handleOnChange={(e: string) => {
                                                    formik.setFieldValue(
                                                        'phoneNumber',
                                                        e
                                                    )
                                                }}
                                            />
                                            <ErrorMessage
                                                name={'phoneNumber'}
                                            >
                                                {(msg) => (
                                                    <div
                                                        className="error-message is-invalid"
                                                        style={{
                                                            color: 'red',
                                                            textAlign: 'right',
                                                            marginLeft: '3px',
                                                            fontSize: '12px',
                                                            letterSpacing:
                                                                '1px',
                                                            fontFamily:
                                                                fontFamilyEnnVisionsRegular,
                                                        }}
                                                    >
                                                        {msg}
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </Col>
                                        <Col md="6" className="mt-20">
                                            <PlacesAutoCompleteInput
                                                label="Coach Address"
                                                placeholder="Enter Coach Address"
                                                handleChange={(val) => {
                                                    formik.setFieldValue(
                                                        'address',
                                                        val
                                                    )
                                                }}
                                                className={
                                                    formik.errors.address &&
                                                        formik.touched.address
                                                        ? 'is-invalid'
                                                        : 'customInput'
                                                }
                                                formik={formik}
                                                name="address"
                                                value={formik.values.address}
                                            />
                                        </Col>
                                        <Col md="6" className="mt-20">
                                            <FormControl
                                                control="select"
                                                type="text"
                                                name="defaultLanguageId"
                                                fontFamily={
                                                    fontFamilyEnnVisionsRegular
                                                }
                                                label="Default Language"
                                                placeholder="Select Default Language"
                                                className={
                                                    formik.errors
                                                        .defaultLanguageId &&
                                                        formik.touched
                                                            .defaultLanguageId
                                                        ? 'is-invalid'
                                                        : 'customInput'
                                                }
                                                options={[
                                                    { value: 1, label: "English" },
                                                    { value: 2, label: "Urdu" },
                                                    { value: 3, label: "Punjabi" },
                                                ]}
                                            />
                                        </Col>
                                        <Col md="6" className="">
                                            <CheckboxesSelect
                                                list={specializationDummyList}
                                                name="specializations"
                                                label="Specialization"
                                                showErrorMsgInList={false}
                                                placeholder="Select Specialization"
                                            />
                                        </Col>
                                        <Col md="6" className="">
                                            <CheckboxesSelect
                                                list={activitiesDummyList}
                                                name="activities"
                                                label="Activity"
                                                showErrorMsgInList={false}
                                                placeholder="Select Activity"
                                            />
                                        </Col>
                                        <Col className="mt-20">
                                            <FormControl
                                                control="textarea"
                                                type="text"
                                                name="description"
                                                fontFamily={fontFamilyEnnVisionsRegular}
                                                label="Description"
                                                padding="10px"
                                                placeholder="Enter Description"
                                                className={
                                                    formik.errors.description &&
                                                        formik.touched.description
                                                        ? 'is-invalid'
                                                        : 'customInput'
                                                }
                                                height="200px"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-20 d-flex justify-content-end">
                                    <CustomButton
                                        bgcolor={RobinEggBlue}
                                        textTransform="uppercase"
                                        color={Black}
                                        padding="11px 40.50px"
                                        fontFamily={fontFamilyRegularUber}
                                        width="fit-content"
                                        type="submit"
                                        title="Update"
                                        fontSize="14px"
                                        loading={false}
                                        clicked={() => { updateCoach(formik.values) }}
                                    />
                                </div>
                            </Form>
                        </>
                    )
                }}
            </Formik>
        </FormPageStyle>
    )
}

export default CoachUpdate
import styled from "styled-components";
import { fontFamilyRegularUber, lightDark2, pureDark2 } from "../../../components/GlobalStyle"; 
export const TicketListingstyle = styled.div`


.custom_card {
  max-width: 310px;
  width: 100%;
  background: #fff;
  width: 100%;
  border: 0.6px solid #EAEAEA;
  border-radius: 5px;
  &-content{
    padding: 10px 14px;

    
    h2 {
      color: ${pureDark2};
      font-family: ${fontFamilyRegularUber};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .countryImg{
      display: flex;
      align-items: center;
      gap: 10px;
      .uk-text {
        color: ${pureDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .star-rating img {
      vertical-align: inherit;
    }
    .flagImg {
      width: 19.89px;
      height: 13.48px;
      margin-left: 9px;
    }
  }
  &_para {
    margin-top: 5px;
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
  }

  .ant-rate-text {
    display: flex; 
    justify-content: center;
    align-items: center;
    gap: 5px;

  .ratingNumber {
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
  }

  span {
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
  }

}

.learnMore_dateTime {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .custom_card_footer_link {
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.698px;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }

  .date-time {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}





  
.ant-list-item {
  padding: 10px 16px 5px 16px;
  display: flex;
  align-items: center;
  &-meta{
    align-items: center;
  }
}


`
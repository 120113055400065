import styled from "styled-components";
import {
  fontFamilyEnnVisionsRegular,
  fontFamilyMedium,
  fontFamilyMediumUber,
  fontFamilyRegular,
  fontFamilyRegularUber,
} from "../../../components/GlobalStyle";

export const OrganizerSettingCreateNewTopListStyle = styled.div`
  .ant-layout > .banner + .ant-layout {
    flex-direction: row;
    gap: 10px;
  }
  .title {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-top: 34px;
    margin-bottom: 0;
  }
  .OrganizerSettingCreateNewTopList-left {
    max-width: 15%;
    width: 100%;
  }
  .formWrapper {
    margin-bottom: 31px;
  }
  .topListForm {
    padding: 15px 26px 25px 23px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-top: 8px;
  }
  .title_showPublicToggle {
    margin-top: 19px;
  }
  .toggleBtn {
    max-width: 328px;
    width: 100%;
    height: 42px;
    padding: 10px;
    border: 0.6px solid #c0d4dc;
    border-radius: 10px;
  }
  .toggleBtn p {
    color: #232837;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
    margin-bottom: 0;
  }

  .showApproved_toggleButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    margin-right: 19.5px;
  }
  .showApproved_toggleButton span {
    color: #000;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
    margin-right: 15px;
  }
  .showApproved_toggleButton button {
    width: 32px;
    background-color: #c8ccd0 !important;
    height: 7px;
  }
  .showApproved_toggleButton span {
    color: #000;
    font-family: ${fontFamilyRegularUber};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.14px;
    text-transform: none;
    margin-right: 15px;
  }
  .showApproved_toggleButton .ant-switch-handle::before {
    background-color: #005b50;
  }
  .showApproved_toggleButton .ant-switch-handle {
    top: -5px;
  }
  .tiebreakersForm {
    padding: 15px 14px 16px 15px;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #e0e0e0;
    margin-top: 13px;
  }
  .addOpt {
    max-width: 25%;
    width: 100%;
    margin-top: 13px;
  }
  .addOpt span {
    color: #0070e0;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
  .title_Sec2 {
    margin-top: 0;
  }
  .title_Sec3 {
    margin-top: 35px;
  }
  .filterRegistrationsForm {
    padding: 16px 28px 20px 14px;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    margin-top: 15px;
  }
  .orCondition {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    p {
      border-radius: 10px;
      border: 1px solid #dcdcdc;
      background: #f6f6f6;
      color: #061229;
      font-family: ${fontFamilyEnnVisionsRegular};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0;
      text-align: center;
      padding: 15px 30px;
    }
    img {
      border-radius: 10px;
      border: 1px solid #ebbdc7;
      background: #f5d5dc;
      padding: 10px 10px;
    }
  }
  .mt-17, .registrationAddOpt {
    margin-top: 17px;
  }
`;

import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular } from "../../../components/GlobalStyle";

export const BuyTicketsSelectUser4Style = styled.div`
.wrapper {
    width: 100%;
}
.mainContainer {
    width: 83.379%;
    display: flex;
    gap: 21px;
    margin: 40px auto;
}
.card_mainContainer {
    max-width: 610px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #E0E0E0;
    background-color: #FFF;
    padding-bottom: 182px;

}
.cardTtile {
    padding: 23px 21px 0 26px;
}
.cardTtile p, .cardTtile p span {
    color: #1A1F36;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.72px;
    margin-bottom: 0;
}
.cardTtile p span {
    padding-left: 8px;
}
.maleGi_mainContainer {
    padding: 0 21px 10px 30px;
}
.maleGi {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 13px;
    padding-bottom: 14px;
    border-bottom: 1px solid #E0E0E0;
}
.maleGi_spacing {
    margin-top: 20px;
}
.maleGi_leftSide, .maleGi_rightSide {
    display: flex;
    flex-direction: column
}
.maleGi_rightSide {
    align-items: flex-end;
}
.maleGi_leftSide p {
    color: #000;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0;
}
.maleGi_leftSide span {
    color: var(--text-text-gray, #697386);
    font-variant-numeric: lining-nums tabular-nums;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.maleGi_rightSide p {
    color: #000;
    text-align: right;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0;
}
.deleteOption {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(0deg, #EAC1CA 0%, #EAC1CA 100%), rgba(0, 0, 0, 0.47);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 11px;
    margin-right: 6px;
    cursor: pointer;
}



















.payCard_mainContainer {
    max-width: 610px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #E0E0E0;
    background-color: #FFF;
}
.payLogo {
    width: 100%
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    background-color: #000;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 2px 5px 0px rgba(0, 0, 0, 0.12), 0px -1px 1px 0px rgba(0, 0, 0, 0.12);
    padding: 12px 48px;
    margin: 27px 22px 0 23px;
}
.payLogo img {
    width: 58.2px;
    height: 24px;
}
.CardbodyContainer {
    width: 100%;
    padding: 0 29px 0 21px;
    margin-top: 32px;
}
.payWithCard {
    display: flex;
    align-items: center;
    gap: 18px;
}
.payWithCard p {
    color: var(--icon-icon-gray, #8792A2);
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
}
.line {
    width: 204px;
    border-bottom: 1px solid #3C42571F;
}
.email, .nameOnCard, .countryRegion, .payOption  {
    margin-top: 32px;
    width: 100%;
}
.email label {
    color: var(--text-text-gray, #697386);
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.07px;
}
.email input {
    width: 100%;
    padding: 9px 12px;
    border-radius: 8px;
    border: 1px solid rgba(60, 66, 87, 0.12);
    background-color: var(--white, #FFF);
    margin-top: 8px;
}
.nameOnCard label {
    color: #697386;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.07px;
}
.nameOnCard input {
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(60, 66, 87, 0.12);
    background-color: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
    padding: 9px 12px;
    margin-top: 8px;
}
.countryRegion span {
    color: #697386;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.07px;
}
.country {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 12px;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid rgba(60, 66, 87, 0.12);
    background-color: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
    margin-top: 8px;
}
.country h6 {
    color: var(--text-text-dark, #1A1F36);
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.08px;
    margin-bottom: 0;
}
.country img {
    width: 14px;
    height: 9.8px;
    margin-left: 8px;
    cursor: pointer;
}
.zip {
    padding: 9px 12px;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid rgba(60, 66, 87, 0.12);
    background-color:  #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
}
.zip span {
    color: #8792A2;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.08px;
}
.payOption {
    padding: 12px 48px;
    border-radius: 4px;
    text-align: center;
    background: #3C4257;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 2px 5px 0px rgba(0, 0, 0, 0.12), 0px -1px 1px 0px rgba(0, 0, 0, 0.12);
}
.payOption span {
    color: #8792A2;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    line-height: 24px;
}
`;
import styled from "styled-components";
import { fontFamilyMediumUber, fontFamilyRegularUber, pureDark2 } from "../GlobalStyle";

export const FooterStyle = styled.div`
  // position: fixed;
  // bottom: 0;
  z-index: 999;
  width: 100%;
  .footer {
    background-color: #d0d9e0;
  }
  .section_padding {
    padding: 30px 3rem 10px;
  }
  .sb_footer {
    display: flex;
    flex-direction: column;
  }

  sb_footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-star;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
  }

  .sb_footer-links-div {
    width: 150px;
    margin: 0px 1rem;
    display: flex;
    justify-content: flex-star;
    flex_direction: column;
    color: #0a142f;
  }
  a {
    color: #0a142f;
    text-decoration: none;
    font-family: ${fontFamilyRegularUber};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 29.6px; /* 164.444% */
  }

  .sb_footer-links-div h4 {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
  }

  .sb_footer-links-div p {
    color: #0a142f;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: 31.767px; /* 223.542% */
    margin-bottom: 0;
  }

  .sb_footer-below {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
  }

  .sb_footer-below-links {
    display: flex;
    align-items: center;
    // flex-direction: row-reverse;
  }

  .sb_footer-below-links p {
    font-size: 14px;
    line-height: 15px;
    margin-left: 25px;
    color: #0a142f;
    font-weight: 600;
    font-style: normal;
    font-weight: 400;
    font-family: ${fontFamilyRegularUber};
    margin-bottom: 0;
  }
  .sub_footer-below {
    display: flex;
    justify-content: space-between;
  }

  hr {
    color: #b79cc1;
    width: 100%;
    margin: 10px 0px 10px;
  }
  .sb_footer-copyright p {
    color: #0a142f;
    font-family: ${fontFamilyRegularUber};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.133px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 850px) {
    .sb_footer-heading h1 {
      font-size: 44px;
      line-height: 50px;
    }
  }
  @media screen and (max-width: 550px) {
    .sb_footer-heading h1 {
      font-size: 34px;
      line-height: 42px;
    }
    .sb_footer-links div {
      margin: 1rem 0;
    }
    .sb_footer-btn p {
      font-size: 14px;
      line-height: 20px;
    }

    .sb_footer-below {
      flex-direction: column;
      justify-content: left;
    }

    .sb_footer-below-links {
      flex-direction: column;
    }
    .sb_footer-below-links p {
      margin-left: 0rem;
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 400px) {
    .sb_footer_heading h1 {
      font-size: 27px;
      line-height: 38px;
    }
  }
  form {
    margin-top: 28px;
    position: relative;
    max-width: 416px;
    width: 100%;
  }

  form input[type="email"] {
    background: transparent;
    outline: none;
    padding-bottom: 15px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid rgb(10, 20, 47);
    width: 100%;
    font-size: 14px;
  }
  .sb_newsletter {
    // margin-top: 40px;
    // margin-bottom: 30px;
    width: 100%;
  }

  .newsletter-h2 {
    color: #0a142f;
    font-family: ${fontFamilyRegularUber};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.22px;
  }

  .sb_newsletter input[type="submit"] {
    background: none;
    border: none;
    background-image: url(assets/images/arrowSubmitIcon.svg);
    background-size: cover;
    position: absolute;
    width: 15px;
    height: 15px;
    background-size: contain;
    font-size: 0;
    top: 10px;
    right: 0;
    background-repeat: no-repeat;
  }

  .sb_footer-links {
    display: flex;
    justify-content: space-between;
  }
  .sb_footer-links > div:first-child {
    max-width: 55%;
    width: 100%;
    display: block;
    // margin-top: 57px;
  }
  .sb_footer-links > div:nth-child(2),
  .sb_footer-links > div:nth-child(3),
  .sb_footer-links > div:nth-child(4) {
    max-width: 15%;
    width: 100%;
    display: block;
    // margin-top: 45px;
  }
  .sb_footer-links > div h3 {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 29.6px; /* 164.444% */
  }
  .sb_footer-links > div:nth-child(2) ul,
  .sb_footer-links > div:nth-child(3) ul,
  .sb_footer-links > div:nth-child(4) ul {
    margin: 0;
    padding: 0;
    margin-top: 0px;
  }
  .sb_footer-links > div:nth-child(2) ul li,
  .sb_footer-links > div:nth-child(3) ul li,
  .sb_footer-links > div:nth-child(4) ul li {
    list-style: none;
  }
  .sb_footer-links > div:nth-child(2) ul li a,
  .sb_footer-links > div:nth-child(3) ul li a,
  .sb_footer-links > div:nth-child(4) ul li a {
    color: #0a142f;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 35.767px; /* 223.542% */
    list-style: none;
  }
  .sb_footer-below-links ul li {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: ${fontFamilyMediumUber};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 35.767px;
    padding: 0 10px 0px 0px;
  }
  .sb_footer-below-links ul li a {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 29.6px; /* 185% */
  }
  .sb_footer-below-links ul {
    display: flex;
    font-family: ${fontFamilyMediumUber};
    margin-top: 15px;
    padding-left: 0;
  }

  .logo {
    // font-size: 44px;
    // line-height: normal;
  }
`;

import React from 'react'
import { UserProfileAcadmyEventAffiliationStyle } from "./style";
import UpComingEventCard from "../../AjpScoreBoard/TicketListing/TicketListing";
import UpComingMatchesCard from "../../../components/ProfilesAfterLoginAllComponents/YourUpComingMatches/YourUpComingMatches";
import RegistrationsCard from "../../../components/ProfilesAfterLoginAllComponents/YourRegistrations/YourRegistrations";
import PassEventsCard from '../../../components/PassEventsCard/PassEventsCard';
import { Link } from 'react-router-dom';


export const UserProfileAcadmyEventAffiliation = () => {
  const Cards = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },

  ]
  return (
    <div>
      <UserProfileAcadmyEventAffiliationStyle>
        <div className='bodyWrapper_RightSide'>
          <div className="rightSideMain">
          <div className="introductionParagraph">
              <h6>Introduction</h6>
              <div className="introBox">
                <p>
                  I have been on so many different mats all over the world. That
                  amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                  began training in Jiu-Jitsu at just four years of age. His
                  father, who was a brown belt, started his journey and took him
                  to classes at João Alberto Barreto’s academy. As a teenager,
                  Mauricio began training with the legendary Rolls Gracie. Under
                  Rolls' guidance, he became a highly successful competitor and
                  won the absolute division of the Rio de Janeiro State
                  Championships in 1981. I have been on so many different mats all over the world. That
                  amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                  began training in Jiu-Jitsu at just four years of age. His
                  father, who was a brown belt, started his journey and took him
                  to classes at João Alberto Barreto’s academy. As a teenager,
                  Mauricio began training with the legendary Rolls Gracie. Under
                  Rolls' guidance, he became a highly successful competitor and
                  won the absolute division of the Rio de Janeiro State
                  Championships in 1981. I have been on so many different mats all over the world. That
                  amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                  began training in Jiu-Jitsu at just four years of age. His
                  father, who was a brown belt, started his journey and took him
                  to classes at João Alberto Barreto’s academy. As a teenager,
                  Mauricio began training with the legendary Rolls Gracie. Under
                  Rolls' guidance, he became a highly successful competitor and
                  won the absolute division of the Rio de Janeiro State
                  Championships in 1981.   ....
                </p>
              </div>
            </div>
            <div className="EventsTitle">
              <h4>Upcoming Events</h4>
              <span>
                <Link to="/past-event">See All</Link>
                </span>
            </div>
            <div className="upComingEvents_cardContainer mt-10">
              {Cards.map(card => (
                <UpComingEventCard key={card.id} />
              ))}
            </div>
            <div className="EventsTitle">
              <h4>Pass Events</h4>
              <span><Link to='/profile-before-login/academy-profile-past-event'>See All</Link></span>
            </div>
            <div className="passEvents_cardContainer mt-10">
              {Cards.map(card => (
                <PassEventsCard key={card.id} />
              ))}
            </div>
            <div className="EventsTitle">
              <h4>Your upcoming matches</h4>
              <span>See All</span>
            </div>
            <div className="subTittle">
              <p>BJJ247 Brighton Autumn Open</p>
            </div>
            <div className="upComingMatch_cardContainer">
              <UpComingMatchesCard />
              <UpComingMatchesCard />
            </div>
            <div className="EventsTitle">
              <h4>Your registrations</h4>
              <span>See All</span>
            </div>
            <div className="yourRegistrations_cardContainer mt-10">
              <RegistrationsCard />
              <RegistrationsCard />
            </div>
            <div className="EventsTitle orderHistory">
              <h4>Order History </h4>
              <span>See All</span>
            </div>
            <table className='mt-10'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Confrimcation</th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <td>3576034</td>
                  <td>2023-10-10 13:14</td>
                  <td><p>San Jose International Open Jiu Jitsu  Gi and NoGi championship</p></td>
                  <td className="amountMain">
                    <p><span>Refund/Cancel</span>£‎ 300.00</p>
                  </td>
                  <td className="confirmationButton">
                    <button>Confirmation</button>
                  </td>
                </tr>
                <tr>
                  <td>3576034</td>
                  <td>2023-10-10 13:14</td>
                  <td><p>San Jose International Open Jiu Jitsu  Gi and NoGi championship</p></td>
                  <td className="amountMain">
                    <p><span>Refund/Cancel</span>£‎ 300.00</p>
                  </td>
                  <td className="confirmationButton">
                    <button>Confrimation</button>
                  </td>
                </tr>
                <tr>
                  <td>3576034</td>
                  <td>2023-10-10 13:14</td>
                  <td><p>San Jose International Open Jiu Jitsu  Gi and NoGi championship</p></td>
                  <td className="amountMain">
                    <p><span>Refund/Cancel</span>£‎ 300.00</p>
                  </td>
                  <td className="confirmationButton">
                    <button>Confrimation</button>
                  </td>
                </tr>
                <tr>
                  <td>3576034</td>
                  <td>2023-10-10 13:14</td>
                  <td><p>San Jose International Open Jiu Jitsu  Gi and NoGi championship</p></td>
                  <td className="amountMain">
                    <p><span>Refund/Cancel</span>£‎ 300.00</p>
                  </td>
                  <td className="confirmationButton">
                    <button>Confrimation</button>
                  </td>
                </tr>
                <tr>
                  <td>3576034</td>
                  <td>2023-10-10 13:14</td>
                  <td><p>San Jose International Open Jiu Jitsu  Gi and NoGi championship</p></td>
                  <td className="amountMain">
                    <p><span>Refund/Cancel</span>£‎ 300.00</p>
                  </td>
                  <td className="confirmationButton">
                    <button>Confrimation</button>
                  </td>
                </tr>
                <tr>
                  <td>3576034</td>
                  <td>2023-10-10 13:14</td>
                  <td><p>San Jose International Open Jiu Jitsu  Gi and NoGi championship</p></td>
                  <td className="amountMain">
                    <p><span>Refund/Cancel</span>£‎ 300.00</p>
                  </td>
                  <td className="confirmationButton">
                    <button>Confrimation</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </UserProfileAcadmyEventAffiliationStyle>
    </div>
  )
}

export default UserProfileAcadmyEventAffiliation;
import React, { useState } from 'react'
import { OrganizerProfileTabsStyle } from "./style"
import TabNavItem from "../TabNavItem/TabNavItem";
import TabContent from "../TabContent/tabContent";
import AffiliationsProfileSeeAllAssociatedAcademies from '../../screens/ProfileBeforeLogin/AffiliationsProfileSeeAllAssociatedAcademies/AffiliationsProfileSeeAllAssociatedAcademies';
import AcademyProfileUpcomingEvent from '../../screens/ProfileBeforeLogin/AcademyProfileUpcomingEvent/AcademyProfileUpcomingEvent';
import AffiliationsProfile from '../../screens/ProfileBeforeLogin/AffiliationsProfile/AffiliationsProfile';

import OrganizerEventList from '../../screens/OrganizerProfileContent/OrganizerEventList/OrganizerEventList';
import OrganizerCreditHistory from '../../screens/OrganizerProfileContent/OrganizerCreditHistory/OrganizerCreditHistory';
import OrganizerMessengerCenter from '../../screens/OrganizerProfileContent/OrganizerMessengerCenter/OrganizerMessengerCenter';
import OrganizerCoupon from '../../screens/OrganizerProfileContent/OrganizerCoupon/OrganizerCoupon';
import OrganizerSettings from '../../screens/OrganizerProfileContent/OrganizerSettings/OrganizerSettings';
import { OrganizerMessengerCenterParticipants } from '../../screens/OrganizerProfileContent/OrganizerMessengerCenterParticipants/OrganizerMessengerCenterParticipants';
import { OrganizerMessengerCenterParticipants2 } from '../../screens/OrganizerProfileContent/OrganizerMessengerCenterParticipants2/OrganizerMessengerCenterParticipants2';
import { OrganizerMessengerCenterParticipants3 } from '../../screens/OrganizerProfileContent/OrganizerMessengerCenterParticipants3/OrganizerMessengerCenterParticipants3';
import OrganizerCreditHistoryAddBalance from '../../screens/OrganizerProfileContent/OrganizerCreditHistoryAddBalance/OrganizerCreditHistoryAddBalance';
import OrganizerBillingInfo from '../../screens/OrganizerProfileContent/OrganizerBillingInfo/OrganizerBillingInfo';
import OrganizerCouponCodesParticipants from '../../screens/OrganizerProfileContent/OrganizerCouponCodesParticipants/OrganizerCouponCodesParticipants';

const OrganizerProfileTabs = () => {
    const [activeTab, setActiveTab] = useState<string>("tab1");
    const propsToPass={
        message: "none"
    }
    return (
        <div>
            <OrganizerProfileTabsStyle>

                <div className="Tabs">
                    <ul className="nav">
                        <TabNavItem
                            title="Events"
                            id="tab1"
                            setActiveTab={() => setActiveTab("tab1")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="Settings"
                            id="tab2"
                            setActiveTab={() => setActiveTab("tab2")}
                            activeTab={activeTab}
                        />
                        {/* <TabNavItem
                            title=" Billing info"
                            id="tab3"
                            setActiveTab={() => setActiveTab("tab3")}
                            activeTab={activeTab}
                        /> */}
                        <TabNavItem
                            title="Credit history"
                            id="tab4"
                            setActiveTab={() => setActiveTab("tab4")}
                            activeTab={activeTab}
                        />
                         <TabNavItem
                            title="Message center"
                            id="tab5"
                            setActiveTab={() => setActiveTab("tab5")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="Coupon codes"
                            id="tab6"
                            setActiveTab={() => setActiveTab("tab6")}
                            activeTab={activeTab}
                        />
                        {/* <TabNavItem
                            title="Federations"
                            id="tab7"
                            setActiveTab={() => setActiveTab("tab7")}
                            activeTab={activeTab}
                        /> */}
                        {/* <TabNavItem
                            title="Admins & Staff"
                            id="tab8"
                            setActiveTab={() => setActiveTab("tab8")}
                            activeTab={activeTab}
                        /> */}

                        {/* <TabNavItem
                            title="Events 2"
                            id="tab9"
                            setActiveTab={() => setActiveTab("tab9")}
                            activeTab={activeTab}
                        /> */}

                        <TabNavItem
                            title="Events 3"
                            id="tab10"
                            setActiveTab={() => setActiveTab("tab10")}
                            activeTab={activeTab}
                        />
                          <TabNavItem
                            title="Events 4"
                            id="tab11"
                            setActiveTab={() => setActiveTab("tab11")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="Event5"
                            id="tab12"
                            setActiveTab={() => setActiveTab("tab12")}
                            activeTab={activeTab}
                        />
                        <TabNavItem
                            title="Event6"
                            id="tab13"
                            setActiveTab={() => setActiveTab("tab13")}
                            activeTab={activeTab}
                        />

                        <TabNavItem
                            title="Events7"
                            id="tab14"
                            setActiveTab={() => setActiveTab("tab14")}
                            activeTab={activeTab}
                        />
                    </ul>

                    <div className="outlet">
                        <TabContent id="tab1" activeTab={activeTab}>
                        {/* <ParticipantsProfileMultiAcademies /> */}
                        <OrganizerEventList />
                           
                        </TabContent>

                        <TabContent id="tab2" activeTab={activeTab}>
                        {/* <ParticipantsProfileSingleAcademy /> */}
                            <OrganizerSettings />
                        
                        </TabContent>

                        {/* <TabContent id="tab3" activeTab={activeTab}>
                            text here 4
                        </TabContent> */}

                        <TabContent id="tab4" activeTab={activeTab}>
                            {/* <AcademyUsersProfiles /> */}
                            <OrganizerCreditHistory />
                        </TabContent>

                        <TabContent id="tab5" activeTab={activeTab}>
                        <OrganizerMessengerCenter />
                        </TabContent>

                        <TabContent id="tab6" activeTab={activeTab}>
                            <OrganizerCoupon />
                        </TabContent>

                        {/* <TabContent id="tab7" activeTab={activeTab}>
                            testing tabs
                        </TabContent> */}

                        {/* <TabContent id="tab8" activeTab={activeTab}>
                            testing tabs
                        </TabContent> */}
                        {/* <TabContent id="tab9" activeTab={activeTab}>
                            <OrganizerMessengerCenterParticipants />
                        </TabContent> */}
                        <TabContent id="tab10" activeTab={activeTab}>
                            <OrganizerMessengerCenterParticipants2 />
                        </TabContent>
                        <TabContent id="tab11" activeTab={activeTab}>
                            <OrganizerMessengerCenterParticipants3 />
                        </TabContent>
                        <TabContent id="tab12" activeTab={activeTab}>
                            <OrganizerCreditHistoryAddBalance />
                        </TabContent>
                        <TabContent id="tab13" activeTab={activeTab}>
                            <OrganizerBillingInfo />
                        </TabContent>

                        <TabContent id="tab14" activeTab={activeTab}>
                            <OrganizerCouponCodesParticipants />
                        </TabContent>
                    </div>
                </div>

            </OrganizerProfileTabsStyle>
        </div>
    )
}

export default OrganizerProfileTabs
import styled from "styled-components";
import { fontFamilyEnnVisionsRegular, fontFamilyMedium, lightDark2, mediaDeviceMax, pureDark2 } from "../../../components/GlobalStyle";

export const OrganizerCreateAnEventCreateCouponStyle = styled.div`
.creatEvent-left{
    width: 15%;
}
.creatEvent-right{
    width: 85%;
}

.ant-layout > .banner + .ant-layout{
    flex-direction: row;
    gap: 10px;
}
.title {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin: 20px 0px;
    }
   
    .tablelist{
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #E0E0E0;
        background: #FFF;
        padding: 20px;
    }    
`;

export const FormFieldStyling = styled.div`
    margin-bottom: 20px;
    h3 {
        font-weight: 500;
        font-size: 18px;
        font-family: ${fontFamilyMedium};
        color: ${pureDark2};
        margin-bottom: 2px;
    }
    .form {
        padding: 20px;
        border: 1px solid #E0E0E0;
        border-radius: 10px;
        @media screen and ${mediaDeviceMax.mobileBS} {
            padding: 20px 5px;
        }
    }
    @media screen and ${mediaDeviceMax.mobileBS} {
        .bVkyTH {
            width: 100% !important;
        }
    }
    .mt-20 {
        .row {
            @media screen and ${mediaDeviceMax.tablet} {
                gap: 20px;
            }
        }
    }
    .ant-form label {
        @media screen and (${mediaDeviceMax.laptop}) {
            font-size: 14px !important;
        }
    }
    .ant-input {
        input::placeholder {
            color: ${lightDark2} !important;
            font-family: ${fontFamilyEnnVisionsRegular};
        }

        @media screen and (${mediaDeviceMax.laptop}) {
            padding: 10px !important;
        }
    }

    .ant-input-affix-wrapper {
        height: 50px;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 10px;
        input {
            height: 48px;
            background-color: transparent;
        }
    }
    .ant-input-affix-wrapper {
        .uploadICon {
            position: absolute;
            right: 14px;
        }
        input[type='file'] {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }
    }
    div > label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        span {
            color: #4f4f4f;
            font-family: ${fontFamilyEnnVisionsRegular};
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .orCondition {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        p{
            border-radius: 10px;
            border: 1px solid #DCDCDC;
            background: #F6F6F6;
            color: #061229;
            font-family: ${fontFamilyEnnVisionsRegular};
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0;
            text-align: center;
            padding: 15px 30px;
        }
        img{
            border-radius: 10px;
            border: 1px solid #EBBDC7;
            background: #F5D5DC;
            padding: 10px 10px;
        }
    }
    .mt-20{
        margin-top: 20px;
    }
    .mb-20{
        margin-bottom: 20px;
    }
`;
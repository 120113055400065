import styled from "styled-components";
import {
  fontFamilyEnnVisionsBold,
  fontFamilyEnnVisionsMedium,
  fontFamilyEnnVisionsRegular,
  fontFamilyMedium,
} from "../GlobalStyle";

export const ModelScoreBoardVarStyle = styled.div`
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    left: 0;
    right: 0;
    top: 0;
    z-index: 999999;
  }
  .modelScoreBoard {
    width: 76vh;
    height: 28vh;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    inset: 0px;
    background-color: #1f2025;
    margin: auto;
    padding: 3.5vh 2vh 7vh;
    position: fixed;
    z-index: 99999;
    border-radius: 2vh;
    z-index: 999999;
  }
  .crossBtns {
    position: absolute;
    top: 1vh;
    right: 1vh;
  }
  .modelConetnt {
    text-align: center;
  }
  .modelConetnt img {
    width: 6.5vh;
    height: 6.5vh;
    object-fit: cover;
    margin-bottom: 1.3vh;
  }
  .modelConetnt h2 {
    color: #fff;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 3.5vh;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1.5vh;
  }
  .modelConetnt p {
    color: #dce5e5;
    text-align: center;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 2vh;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    line-height: 3vh;
  }
  .modelConetntBtns {
    margin-top: 2vh;
  }
  .modelConetntBtns button.btn {
    color: #fff;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 2.4vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 1vh 1.6vh;
    border-radius: 1vh;
    margin: 1vh 1vh;
    text-transform: uppercase;
  }
  .modelConetntBtns button.nextmatch {
    background-color: #3d86af;
  }
  .modelConetntBtns button.backBracket {
    background-color: #3d86af;
  }
  .modelConetntBtns button.backFightorder {
    background-color: #3d86af;
  }
  .modelConetntBtns button.cancelbtn {
    background-color: #8d9799;
  }

  .swal2-icon {
    margin: 1vh auto 1vh;
    padding: 0;
    position: relative;
    box-sizing: content-box;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  // .swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  //     border-radius: 50%;
  //     position: absolute;
  //     width: 60px;
  //     height: 120px;
  //     -webkit-transform: rotate(45deg);
  //     transform: rotate(45deg);
  // }
  // .swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  //     border-radius: 120px 0 0 120px;
  //     top: -7px;
  //     left: -33px;
  //     -webkit-transform: rotate(-45deg);
  //     transform: rotate(-45deg);
  //     -webkit-transform-origin: 60px 60px;
  //     transform-origin: 60px 60px;
  // }
  .swal2-icon.swal2-success [class^="swal2-success-line"][class$="long"] {
    width: 47px;
    right: 8px;
    top: 38px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .swal2-icon.swal2-success .swal2-success-ring {
    width: 80px;
    height: 80px;
    border: 4px solid hsla(98, 55%, 69%, 0.2);
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2;
  }
  .swal2-icon.swal2-success .swal2-success-fix {
    width: 5px;
    height: 25px;
    position: absolute;
    left: 21px;
    top: 34px;
    z-index: 1;
    transform: rotate(-45deg);
    background: #a5dc86;
  }
  .swal2-icon.swal2-success
    [class^="swal2-success-circular-line"][class$="right"] {
    border-radius: 0 120px 120px 0;
    top: -11px;
    left: 30px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 60px;
    transform-origin: 0 60px;
  }
  .swal2-icon.swal2-success {
    border-color: #a5dc86;
  }
  .swal2-success.swal2-animate-success-icon .swal2-success-circular-line-right {
    -webkit-animation: rotatePlaceholder 4.25s ease-in;
    animation: rotatePlaceholder 4.25s ease-in;
  }
  .swal2-icon.swal2-success [class^="swal2-success-line"] {
    height: 5px;
    background-color: #a5dc86;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
  }
  .swal2-animate-success-line-long {
    -webkit-animation: animate-success-long 0.75s;
    animation: animate-success-long 0.75s;
  }

  @keyframes rotatePlaceholder {
    0% {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    5% {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    12% {
      -webkit-transform: rotate(-405deg);
      transform: rotate(-405deg);
    }

    100% {
      -webkit-transform: rotate(-405deg);
      transform: rotate(-405deg);
    }
  }
  @keyframes rotatePlaceholder {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
`;

import styled from "styled-components";
import { fontFamilyRegularUber, lightDark2, pureDark2 } from "../../../components/GlobalStyle";

export const YourRegistrationsStyle = styled.div`

.cardContainer {
    width: 570px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    background-color: #FFF; 

    .titleDate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px 5px 0px 0px;
        background-color: #EFEFEF;
        padding: 10px 14px;
       
        h5 {
           color: ${pureDark2};
           font-family: ${fontFamilyRegularUber};
           font-size: 14px;
           font-style: normal;
           font-weight: 400;
           line-height: normal;
           letter-spacing: 0.32px;
           margin-bottom: 0;
       }
       
       span {
          color: ${lightDark2};
          font-family: ${fontFamilyRegularUber};
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 129.5%;
      }
    }
    
}
.innerCard {
    padding: 10px 14px;
    h5 {
       color: ${pureDark2};
       font-family: ${fontFamilyRegularUber};
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
       line-height: normal;
       margin-bottom: 0;
   }
}
.beltAge_WeightMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .beltAge {
        display: flex;
        align-items: center;
        gap: 50px;
    }

}
.belt {
    display: flex;
    align-items: center;
    gap: 6px;
    p {
       color: ${pureDark2};
       font-family: ${fontFamilyRegularUber};
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
       line-height: normal;
       margin-bottom: 0;
   }
   img {
       width: 96.079px;
       height: 9.57px;
   }
}
.age {
    display: flex;
    align-items: center;
    gap: 6px;
    p {
        color: ${pureDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
   }
   span {
      color: ${lightDark2}};
      font-family: ${fontFamilyRegularUber};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
   }
}
.weight {
    display: flex;
    align-items: center;
    gap: 6px; 
    p {
        color: ${pureDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
    }
    span {
        color: ${lightDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.lastChance_EventsButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.lastChanceToEdit p {
    color: ${pureDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    span {
       color: ${lightDark2};
       font-family: ${fontFamilyRegularUber};
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
       line-height: normal;
   }
}
.GoTo_EventsButton {
    display: flex;
    align-items: center;
    gap: 10px;
}
.GoToEventsButton {
    padding: 7px 10px;
    border-radius: 4px;
    background: linear-gradient(0deg, #5C6F80 0%, #5C6F80 100%), rgba(0, 0, 0, 0.47);

    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.cancelButton {
    padding: 7px 10px;
    border-radius: 4px;
    background: linear-gradient(0deg, #E51D35 0%, #E51D35 100%), rgba(0, 0, 0, 0.47);

    color: #FFF;
    font-family: ${fontFamilyRegularUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
`;
import styled from "styled-components";
import { fontFamilyMedium, fontFamilyMediumUber, fontFamilyRegularUber, lightDark2, pureDark2 } from "../../components/GlobalStyle";

export const DynamoFightStyle = styled.div`

.card1 {
    max-width: 354.91px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1.087px solid #F0F0F0;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.cardLogoLeftSide {
    padding: 8.18px 0 12.06px 10.51px;
}  
.avatar1 {
    width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: 44.387px;
}
.info1 {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    padding: 9.34px 17px 10px 0;
}  
.name1 {
    color: ${pureDark2};
    font-family: ${fontFamilyMediumUber};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.activity1 {
    letter-spacing: -.5px;
    margin: 0;
    padding-top: 14px;
    color: ${lightDark2};
    font-family: ${fontFamilyRegularUber};
    font-size: 16.353px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}
.blocks {
    display: flex;
    align-items: center;
    padding-top: 5.45px;
    gap: 8px;
}
.block1 {
    padding: 2px 7px 2px 8px;
    background-color: #CDAF49;
    border-radius: 5px;
}
.block2 {
    padding: 2px 8px 2px 9px;
    background-color: #A8A9AD;
    border-radius: 5px;
}
.block3 {
    padding: 2px 4px 0px 8px;
    background-color: #BC6B50;
    border-radius: 5px;
}
.block1 span, .block2 span, .block3 span {
    color: #FFF;
    font-family: ${fontFamilyMediumUber};
    font-size: 18.689px;
    font-style: normal;
    font-weight: 500;
    line-height: 23.362px;
}
`;
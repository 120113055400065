import React from "react";
import { AffiliationsProfileOverallAcademyAdultsAfterLogStyle } from "./style";
import ProfileAcademyAdultsComponent from "../../../components/ProfileAcademyAdults/ProfileAcademyAdults";
export const AffiliationsProfileOverallAcademyAdultsAfterLog = () => {
  return (
    <div>
      <AffiliationsProfileOverallAcademyAdultsAfterLogStyle>
        <div className="bodyWrapper_RightSide">
          <div className="rankingOverAll_Title">
            <p>Ranking</p>
            <span className="dot">.</span>
            <h6>Overall Academy – Adults</h6>
            <span className="dot">.</span>
            <span className="lastCalculated">Last calculated 3 hours ago </span>
          </div>

          <div className="rankingAdultsContainer">
            <div className="affiliationsTeamsOverAll_MainContainer">
              <div className="bestAffiliationsOverallMain">
                {" "}
                {/*left side */}
                <div className="block1">
                  <div className="blockTitle">
                    <h5>Global</h5>
                  </div>
                  <div className="bestAffiliationsCardsMain">
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                  </div>
                  <div className="seeAll">
                    <span>See All</span>
                  </div>
                </div>
                <div className="block2_toLast">
                  <div className="blockTitle">
                    <h5>Europe</h5>
                  </div>
                  <div className="bestAffiliationsCardsMain">
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                  </div>
                  <div className="seeAll">
                    <span>See All</span>
                  </div>
                </div>
                <div className="block2_toLast">
                  <div className="blockTitle">
                    <h5>Europe</h5>
                  </div>
                  <div className="bestAffiliationsCardsMain">
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                  </div>
                  <div className="seeAll">
                    <span>See All</span>
                  </div>
                </div>
              </div>

              <div className="bestTeamsOverallMain">
                {" "}
                {/*right side */}
                <div className="block1">
                  <div className="blockTitle">
                    <h5>Asia</h5>
                  </div>
                  <div className="bestAffiliationsCardsMain">
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                  </div>
                  <div className="seeAll">
                    <span>See All</span>
                  </div>
                </div>
                <div className="block2_toLast">
                  <div className="blockTitle">
                    <h5>North America</h5>
                  </div>
                  <div className="bestAffiliationsCardsMain">
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                  </div>
                  <div className="seeAll">
                    <span>See All</span>
                  </div>
                </div>
                <div className="block2_toLast">
                  <div className="blockTitle">
                    <h5>South America</h5>
                  </div>
                  <div className="bestAffiliationsCardsMain">
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                    <ProfileAcademyAdultsComponent />
                  </div>
                  <div className="seeAll">
                    <span>See All</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AffiliationsProfileOverallAcademyAdultsAfterLogStyle>
    </div>
  );
};

export default AffiliationsProfileOverallAcademyAdultsAfterLog;

import React from "react";
import { EventTicketStyle } from "./style";
import HeaderInnerPages from "../../components/HeaderInnerPages/HeaderInnerPages";
import Mapimage from "../../assets/icons/MapFrame.svg";
import ToggleTrack from "../../assets/icons/ToggleTrack.png";
import Toggle from "../../assets/icons/Toggle.png";
import TicketListingstyle from "../../screens/AjpScoreBoard/TicketListing/TicketListing";

const EventTicket = () => {
  const TicketListing = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  return (
    <EventTicketStyle>
      <div className="MapFrame">
        <img src={Mapimage} alt="Mapimage" id="Mapimage" />
      </div>
      <div className="FeauterdEvenetsButtons">
        <div className="headingMainL">
          <h2>
            Featured <span>Events</span>
          </h2>
        </div>
        <div className="buttonsMainR">
          <div className="displayMap_toggle">
            <button className="displayMapButton">Display Map</button>
            <div className="toggleButton">
              <img src={ToggleTrack} alt="ToggleTrack" id="ToggleTrack" />
              <img
                src={Toggle}
                alt="Toggle"
                id="Toggle"
                className="toggleMove"
              />
            </div>
          </div>
          <div className="pastEventsButton">
            <button>Past Events</button>
          </div>
          <div className="createEventsButton">
            <button>Create New Events</button>
          </div>
        </div>
      </div>
      <div className="ticketListingMain">
        {TicketListing.map((item2) => {
          return (
          <div className="ticketListing" key={item2}>
            <TicketListingstyle />
          </div>)
        })}
      </div>
    </EventTicketStyle>
  );
};

export default EventTicket;

import React from "react";
import { ScheduleScoreBoardStyle } from "./style";
import ScoreBoardXsvg from "../../assets/icons/ScoreBoard-Xsvg.svg";
import PlayerOneImage from "../../assets/images/ScoreBoard-Baptiste.png";
import PlayerTwoImage from "../../assets/images/ScoreBoard-Lucas.png";
export const ScheduleScoreBoard = () => {
  return (
    <div>
      <ScheduleScoreBoardStyle>
        <div className="scoreBoard_container">
          <div className="title_or_X">
            <div className="title">
              <h4>London Jiu Jitsu Open Gi and NOGI championship</h4>
            </div>
            <div className="Xsvg">
              <img src={ScoreBoardXsvg} alt="ScoreBoardXsvg" />
            </div>
          </div>

          <div className="matchMain">
            <div className="player1_imageName">
              <img src={PlayerOneImage} alt="PlayerOneImage" />
              <p>Baptiste Jegoux</p>
              <span>Gracie Allegiance...</span>
            </div>
            <div className="matchCancelled">
              <h5>00:00</h5>
              <span>Match cancelled due to No Show</span>
            </div>
            <div className="player2_imageName">
              <img src={PlayerTwoImage} alt="PlayerTwoImage" />
              <p>Lucas dos Santos</p>
              <span>Gracie Barra Fulham...</span>
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>Score Board</th>
                <th>Baptiste Jegoux</th>
                <th>Lucas dos Santos</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={3} className="">
                  <table width="100%" className="innerTable">
                    <tr>
                      <td>Age</td>
                      <td>24</td>
                      <td className="_abc">24</td>
                    </tr>

                    <tr>
                      <td>Country</td>
                      <td>United Kingdom</td>
                      <td>United State</td>
                    </tr>

                    <tr>
                      <td>Affiliation</td>
                      <td>Renzo Gracie NY</td>
                      <td>--</td>
                    </tr>

                    <tr>
                      <td>Club</td>
                      <td>--</td>
                      <td>--</td>
                    </tr>
                  </table>
                </td>
              </tr>
              {/* <tr>
                            <td>Age</td>
                            <td>24</td>
                            <td>24</td>
                        </tr>

                        <tr>
                            <td>Country</td>
                            <td>United Kingdom</td>
                            <td>United State</td>
                        </tr>

                        <tr>
                            <td>Affiliation</td>
                            <td>Renzo Gracie NY</td>
                            <td>--</td>
                        </tr>

                        <tr>
                            <td>Club</td>
                            <td>--</td>
                            <td>--</td>
                        </tr> */}
            </tbody>
          </table>
        </div>
      </ScheduleScoreBoardStyle>
    </div>
  );
};

export default ScheduleScoreBoard;

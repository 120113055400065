import React from "react";
import { CreatEventLeftSideStyle } from "./style";
export const CreatEventLeftSide = () => {
  return (
    <div>
      <CreatEventLeftSideStyle>
        <div className="bodyLeftSideContainer">
          <div className="leftSide">
            <ul className="generalMain">
              <li>
                <a href="#">Gerenal</a>
              </li>
              <li>
                <a href="#">Settings</a>
              </li>
              <li>
                <a href="#">Information</a>
              </li>
              <li>
                <a href="#">Pages</a>
              </li>
            </ul>
            <ul className="registrationMain">
              <li>
                <a href="#">Registrations</a>
              </li>
              <li>
                <a href="#">Registrations</a>
              </li>
              <li>
                <a href="#">Entries</a>
              </li>
              <li>
                <a href="#">Discounts</a>
              </li>
              <li>
                <a href="#">Coupon codes</a>
              </li>
              <li>
                <a href="#">Capacity rules</a>
              </li>
              <li>
                <a href="#">Terms of serives</a>
              </li>
            </ul>
            <ul className="tournamentMain">
              <li>
                <a href="#">Tournament</a>
              </li>
              <li>
                <a href="#">Scoreboard</a>
              </li>
              <li>
                <a href="#">Mat settings</a>
              </li>
              <li>
                <a href="#">Schedule settings</a>
              </li>
              <li>
                <a href="#">Livestreams</a>
              </li>
            </ul>
            <ul className="bracketsMain">
              <li>
                <a href="#">Brackets</a>
              </li>
              <li>
                <a href="#">Settings</a>
              </li>
            </ul>
          </div>
        </div>
      </CreatEventLeftSideStyle>
    </div>
  );
};

import React from 'react'
import { OrganizerCouponCodesParticipantsStyle } from "./style"
import UpComingEventsCards from "../../AjpScoreBoard/TicketListing/TicketListing";

const OrganizerCouponCodesParticipants = () => {
    const itemsArray = Array.from({ length: 6 }, (_, index) => index + 1);
    return (
        <div>
            <OrganizerCouponCodesParticipantsStyle>
                <div className="title_AffiliationButton">
                    <h4 className="title">Coupon codes</h4>
                    <button className="newAffiliationButton">Next</button>
                </div>

                <div className='tablelist'>
          <div className='organizerSettingForm'>
            <form>
              <div className='form-group'>
                <label>Name</label>
                <input type="text" placeholder="East Jiu Jitsu" />
              </div>

              <div className='form-group'>
                <label>Currency</label>
                <select>
                  <option>omclean@kaimeramedia.com</option>
                </select>

              </div>
              <div className='form-group'>
                <label>Language</label>
                <select>
                  <option>omclean@kaimeramedia.com</option>
                </select>
              </div>
            </form>

          </div>
        </div>

                <h4 className='title2'>Created Events</h4>
                <div className='eventLists'>
                    {itemsArray.map((item, index) => (
                        <UpComingEventsCards key={index} />
                    ))}
                </div>

            </OrganizerCouponCodesParticipantsStyle>
        </div>
    )
}

export default OrganizerCouponCodesParticipants
import React from "react";
import { EventRegistrationCardStyle } from "./style";
import ParticipantImage from "../../assets/images/ali-hassan.png";
import EnglandFlag from "../../assets/images/EnglandFlag.svg";
import FormControl from "../FormControl";
export const EventRegistrationCard = () => {
  return (
    <div>
      <EventRegistrationCardStyle>
        <div className="participateCard">
          <div className="participateImage ">
            <img src={ParticipantImage} alt="ParticipantImage" />
          </div>
          <div className="participateDetailsMain">
            <div className="partcipateName">
              <div className="name">
                <p>Ali Hassan</p>
                <div className="blueDot"></div>
              </div>
              <div className="weight_Checkbox">
                <p>-30.20 kg</p>
                <span className="">
                  <FormControl
                    control="checkbox"
                    type="checkbox"
                    id="terms"
                    name="terms"
                  />
                </span>
              </div>
            </div>
            <div className="partcipateAddress">
              <p>MNBJJ Glasgow Brazilian Jiu-jitsu</p>
              <span>Pee Wee lll (9yrs)</span>
            </div>
            <div className="ageCountry_Main">
              <div className="ageCountryFlag">
                <p>2012 . 11 years old</p>
                <img src={EnglandFlag} alt="EnglandFlag" />
              </div>
              <span>White</span>
            </div>
          </div>
        </div>
      </EventRegistrationCardStyle>
    </div>
  );
};

export default EventRegistrationCard;

import styled from "styled-components";
import { fontFamilyBlack, fontFamilyBold, fontFamilyMedium, fontFamilyRegular, whiteColor } from "../../GlobalStyle";
export const  DoubleWostyle = styled.div`

.double-show{
    border-radius: 8px;
    background: rgb(0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.55) 0px 0px 16px 0px;
    z-index: 9999;
    padding: 2vh 4vh;
}
.double-text{
    border-radius: 1vh;
    background: rgb(0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.55) 0px 0px 16px 0px;
    z-index: 9999;
    margin: 0vh 0.4vh; 
    display: inline-block;
    border-radius: 1vh;
}
.double-text p{
    color: ${whiteColor};
    font-family: ${fontFamilyBold};
    font-size: 3.1vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.31px;
    text-transform: uppercase;
    padding: 0.6vh 1.5vh;
}

`

import styled from 'styled-components'
import {
    fontFamilyMedium,
    fontFamilyRegularUber,
    pureDark2,
    whiteColor,
} from '../GlobalStyle'

const CustomModalStyle = styled.div`
background-color: #fff;
border-radius: 8px;
position: relative;

  .close-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      border-radius: 10px;
    }
  }
`

export default CustomModalStyle

export const LoadingOverlayStyle = styled.div`
    .message {
        color: ${whiteColor};
        font-family: ${fontFamilyMedium};
        text-align: center;
        font-size: 22px;
        margin-bottom: 0;
    }
`

export const CustomMessageModalStyle = styled.div`
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    .close-icon {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
        border-radius: 10px;
    }
}
.mainContainer{
    &-header {
        border-radius: 8px 8px 0px 0px;
        padding: 6px;
        display: flex;
        align-items: center;
        background-color: #292F3F1A;
        img {
            height: 25px;
            width: 25px;
        }

        &-heading {
            margin: 0px;
            width: 90%;
            text-align: center;
            color: ${pureDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    &-body{
        padding: 8px 14px;

        &-subText {
            text-align: center;
            margin: 0px;
            color: ${pureDark2};
            font-family: ${fontFamilyRegularUber};
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}
`

import React, { useState } from "react";
import { OrganizerCreateAnEventBracketsStyle } from "./style";
import BeltImage from "../../../assets/images/progressbar.svg";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import yahya_img from "../../../assets/images/yahya_ahmad.svg";
import SpainFlag from "../../../assets/icons/DragMatches-image1.svg";
import Nico_img from "../../../assets/images/Nico_porter.svg";
import japanFlag from "../../../assets/icons/DragMatches-image2.svg";
import YahyaImage from "../../../assets/images/YahyaAhmad-SingleCard.png";

const OrganizerCreateAnEventBrackets = () => {
  const [fileInfo, setFileInfo] = useState({ name: "", type: "", size: 0 });
  const [isMultipleFiles, setIsMultipleFiles] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const handleFileChange = (event: any) => {
    const files = event.target.files;

    if (files.length > 1) {
      console.log(files, 1);
      setIsMultipleFiles(true);
      return;
    }

    const file = files[0];
    setIsMultipleFiles(false);

    setFileInfo({
      name: file.name,
      type: file.type,
      size: Math.round(file.size / 1024),
    });
  };

  const handleFocus = () => {
    setIsFocus(true);
  };

  const handleBlur = () => {
    setIsFocus(false);
  };

  return (
    <div>
      <OrganizerCreateAnEventBracketsStyle>
        <div className="banner">
          <img
            src={AcademyProfileBannerImage}
            alt="AcademyProfileBannerImage"
          />
        </div>
        <div className="bodyWrapper">
          <table>
            <tbody>
              <tr>
                <td className="belt">
                  <img src={BeltImage} alt="BeltImage" />
                </td>
                <td className="maleGi">
                  <p>Male Gi / White / Adult / -76 kg (Light)</p>
                </td>
                <td className="title">
                  <span>Area</span>
                  <p>Mat 1</p>
                </td>
                <td className="title">
                  <span>Bracket type</span>
                  <p>Single Elimination ShortCut Bronze </p>
                </td>
                <td className="title">
                  <span>Participants</span>
                  <p>64</p>
                </td>
                <td className="title">
                  <span>Nr of matches</span>
                  <p>63</p>
                </td>
                <td className="title">
                  <span>Time per match</span>
                  <p>5 Min</p>
                </td>
                <td className="editButton">
                  <button>Edit</button>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="dragDrop">
            <form
              method="post"
              action="/upload"
              style={{ width: "100%", height: "100%" }}
            >
              <label
                className={`file${isFocus ? " focus" : ""}${
                  isMultipleFiles ? " error" : ""
                }`}
                style={{
                  position: "relative",
                  display: "block",
                  width: "182.6px",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#333",
                  fontStyle: "normal",
                  textAlign: "center",
                  fontFamily: "fontFamilyMediumUber",
                  lineHeight: "21.818px",
                  letterSpacing: "-0.132px",
                  border: "1px dashed #c3c3c3",
                  borderRadius: "10px",
                  margin: "14px 0 0 0",
                  padding: "12px 30px 11px 31px",
                  cursor: "pointer",
                  background: "#F0F0F0",
                }}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={handleFocus}
                onDragLeave={handleBlur}
                onDrop={handleFileChange}
              >
                {isMultipleFiles ? (
                  "Sorry, multiple files are not allowed"
                ) : (
                  <>
                    {!fileInfo.name ? (
                      "Drag to select matches"
                    ) : (
                      <>
                        {fileInfo.name} <br />
                        <span className="sml">
                          type: {fileInfo.type}, {fileInfo.size} KB
                        </span>
                      </>
                    )}
                  </>
                )}
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    outline: 0,
                    border: "1px solid red",
                  }}
                />
              </label>
            </form>
          </div>

          <div className="cardsContainer">
            <div>
              <div className="schedule-container  align-items-center justify-content-between">
                <div className="d-flex  align-items-center justify-content-between">
                  <div className="card d-flex position-relative">
                    <div className="d-flex justify-content-between position-relative">
                      <div className="d-flex align-items-center">
                        <img
                          src={yahya_img}
                          alt=""
                          className="participant-img"
                        />
                        <div className="participate-img d-flex flex-column">
                          <div className="d-flex align-items-center ms-2">
                            {" "}
                            <p className="para m-0">Yahya Ahmad</p>{" "}
                          </div>
                          <p className="para-text m-0 ms-2"> New Wave Aca...</p>
                        </div>
                      </div>
                      <div className="country-flag">
                        <img src={SpainFlag} alt="" />
                      </div>

                      <div className="position-absolute dot"> </div>
                    </div>

                    <hr />
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          src={Nico_img}
                          alt=""
                          className="participant-img"
                        />
                        <div className="participate-img d-flex flex-column">
                          <div className="d-flex align-items-center ms-1">
                            {" "}
                            <p className="para m-0">Nico Porter</p>
                          </div>
                          <p className=" para-text m-0 ms-1">Impact Gym...</p>
                        </div>
                      </div>
                      <div className="country-flag">
                        <img src={japanFlag} alt="" />
                      </div>
                    </div>

                    <div className="vs position-absolute">
                      <p className="vs-text">1-1</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="schedule-container  align-items-center justify-content-between">
                <div className="d-flex  align-items-center justify-content-between">
                  <div className="card d-flex position-relative">
                    <div className="d-flex justify-content-between position-relative">
                      <div className="d-flex align-items-center">
                        <img
                          src={yahya_img}
                          alt=""
                          className="participant-img"
                        />
                        <div className="participate-img d-flex flex-column">
                          <div className="d-flex align-items-center ms-2">
                            {" "}
                            <p className="para m-0">Yahya Ahmad</p>{" "}
                          </div>
                          <p className="para-text m-0 ms-2"> New Wave Aca...</p>
                        </div>
                      </div>
                      <div className="country-flag">
                        <img src={SpainFlag} alt="" />
                      </div>

                      <div className="position-absolute dot"> </div>
                    </div>

                    <hr />
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          src={Nico_img}
                          alt=""
                          className="participant-img"
                        />
                        <div className="participate-img d-flex flex-column">
                          <div className="d-flex align-items-center ms-1">
                            {" "}
                            <p className="para m-0">Nico Porter</p>
                          </div>
                          <p className=" para-text m-0 ms-1">Impact Gym...</p>
                        </div>
                      </div>
                      <div className="country-flag">
                        <img src={japanFlag} alt="" />
                      </div>
                    </div>

                    <div className="vs position-absolute">
                      <p className="vs-text">1-2</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="singleCard">
              <div className="personImage">
                <img src={YahyaImage} alt="YahyaImage" />
              </div>
              <div className="playerDetails">
                <div className="playerName">
                  <p>Yahya Ahmad</p>
                  <span>New Wave Aca...</span>
                </div>
                <div className="countryFlag">
                  <img src={SpainFlag} alt="SpainFlag" />
                </div>
              </div>
              <div className="score">
                <span>1-3</span>
              </div>
              <div className="greenDot">
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </OrganizerCreateAnEventBracketsStyle>
    </div>
  );
};

export default OrganizerCreateAnEventBrackets;

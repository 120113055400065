import React from 'react'
import FormControl from '../FormControl'
import { fontFamilyRegularUber } from '../GlobalStyle'
import CustomButton from '../CustomButton/CustomButton'
import ParticipantImage from "../../assets/images/ali-hassan.png";
import EnglandFlag from "../../assets/images/EnglandFlag.svg";
import ic_plus from "../../assets/icons/ic_plus.svg";
import blackBelt from "../../assets/images/blackBelt.svg";
import { StudentListCardStyle } from './style'


const StudentListCard = () => {
    return (
        <StudentListCardStyle>
            <div className="studentCard mt-10 mb-20">
                <img src={ParticipantImage} alt="ParticipantImage" />
                <div className="insideContent">
                    <div className="studentInfo">
                        <div className="studentName">
                            <h3>Ali Hassan</h3>
                            <p>-30,20kg</p>
                        </div>
                        <div className="addressYears">
                            <div className="flagAddress">
                                <img src={EnglandFlag} alt="EnglandFlag" />
                                <p>MNBJJ Glasgow Brazilian Jiu-jitsu</p>
                            </div>
                            <p>Pee Wee lll (9yrs)</p>
                        </div>
                    </div>
                    <div className="yearsBelt">
                        <h5>2012  .  11 years old</h5>
                        <div className="beltPlus">
                            <img src={blackBelt} alt="blackBelt" />
                            <span className="plusIcon">
                                <img src={ic_plus} alt="ic_plus" width={10} height={10} />
                            </span>
                        </div>
                    </div>
                    <div className="buttons">
                        <CustomButton
                            bgcolor="#5C6F80"
                            textTransform="uppercase"
                            color="#fff"
                            padding="8px 20.50px"
                            fontFamily={fontFamilyRegularUber}
                            width="fit-content"
                            type="submit"
                            title="View Profile"
                            fontSize="14px"
                            loading={false}
                        />
                        <select name="batch" id="batch" className='batchSelect'>
                            <option value="member">Member</option>
                            <option value="manager">Manager</option>
                            <option value="coach">Coach</option>
                            <option value="affiliate">Affiliate</option>
                        </select>
                        <FormControl
                            control="checkbox"
                            type="checkbox"
                            id="terms"
                            name="terms"
                        />
                    </div>
                </div>
            </div>
        </StudentListCardStyle>
    )
}

export default StudentListCard
import React from "react";
import { RegistrationsAgeTableStyle } from "./style";
import actionMenuTogglerIcon from "../../../../assets/icons/ic_action_menu_toggler.svg";
import HamBurger from "../../../../assets/icons/HamBurger.svg";
const RegistrationsAgeTable = () => {
  return (
    <div>
      <RegistrationsAgeTableStyle>
        <div className="mainContainer registrationAge">
          <div className="sectionTitles">
            <h4>Registrations by AGE</h4>
          </div>
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Total</th>
                  <th>Adult</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src={HamBurger}
                      alt="HamBurger"
                      className="hamBurger"
                    />
                  </td>
                  <td>Name</td>
                  <td>30</td>
                  <td>2</td>
                  <td className="status">
                    <span>Sent</span>
                  </td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      src={HamBurger}
                      alt="HamBurger"
                      className="hamBurger"
                    />
                  </td>
                  <td>No affiliation</td>
                  <td>30</td>
                  <td>2</td>
                  <td className="status">
                    <span>Sent</span>
                  </td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      src={HamBurger}
                      alt="HamBurger"
                      className="hamBurger"
                    />
                  </td>
                  <td>Checkmat</td>
                  <td>30</td>
                  <td>2</td>
                  <td className="status">
                    <span>Sent</span>
                  </td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      src={HamBurger}
                      alt="HamBurger"
                      className="hamBurger"
                    />
                  </td>
                  <td>82 JIU JITSU</td>
                  <td>30</td>
                  <td>2</td>
                  <td className="status">
                    <span>Sent</span>
                  </td>
                  <td className="actions">
                    <img
                      src={actionMenuTogglerIcon}
                      alt="actionMenuTogglerIcon"
                    />
                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      </RegistrationsAgeTableStyle>
    </div>
  );
};

export default RegistrationsAgeTable;

import React from "react";
import { OrganizerSettingStatisticsMatch2Style } from "./style";
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
const OrganizerSettingStatisticsMatch2 = () => {
  return (
    <div>
      <OrganizerSettingStatisticsMatch2Style>
        <div className="bodyWrapper_RightSide">
        <div className="mainContainer registrationBelt">
          <div className="sectionTitles">
            <h4>Registrations by BELT</h4>
          </div>
          <div className="tableContainer">
          <table>
            <thead>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Payment made by</th>
                <th>User</th>
                <th>Type</th>
                <th>Payment method</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="order">3332844</td>
                <td>2023-08-12 12:21:49</td>
                <td>Jack Hall</td>
                <td>Pablo Medina</td>
                <td className="type">Payment</td>
                <td className="paymentMethod">Payment status changed</td>
                <td className="amount">£ 60 GBP</td>
                <td className="status">
                  <span>Active</span>
                </td>
                <td className="actions">
                  <img
                    src={actionMenuTogglerIcon}
                    alt="actionMenuTogglerIcon"
                  />
                </td>
              </tr>

              <tr>
                <td className="order">3332844</td>
                <td>2023-08-12 12:21:49</td>
                <td>Jack Hall</td>
                <td>Pablo Medina</td>
                <td className="type">Payment</td>
                <td className="paymentMethod">Payment status changed</td>
                <td className="amount">£ 60 GBP</td>
                <td className="status">
                  <span>Active</span>
                </td>
                <td className="actions">
                  <img
                    src={actionMenuTogglerIcon}
                    alt="actionMenuTogglerIcon"
                  />
                </td>
              </tr>

              <tr>
                <td className="order">3332844</td>
                <td>2023-08-12 12:21:49</td>
                <td>Jack Hall</td>
                <td>Pablo Medina</td>
                <td className="type">Payment</td>
                <td className="paymentMethod">Payment status changed</td>
                <td className="amount">£ 60 GBP</td>
                <td className="status">
                  <span>Active</span>
                </td>
                <td className="actions">
                  <img
                    src={actionMenuTogglerIcon}
                    alt="actionMenuTogglerIcon"
                  />
                </td>
              </tr>

              <tr>
                <td className="order">3332844</td>
                <td>2023-08-12 12:21:49</td>
                <td>Jack Hall</td>
                <td>Pablo Medina</td>
                <td className="type">Payment</td>
                <td className="paymentMethod">Payment status changed</td>
                <td className="amount">£ 60 GBP</td>
                <td className="status">
                  <span>Active</span>
                </td>
                <td className="actions">
                  <img
                    src={actionMenuTogglerIcon}
                    alt="actionMenuTogglerIcon"
                  />
                </td>
              </tr>

              <tr>
                <td className="order">3332844</td>
                <td>2023-08-12 12:21:49</td>
                <td>Jack Hall</td>
                <td>Pablo Medina</td>
                <td className="type">Payment</td>
                <td className="paymentMethod">Payment status changed</td>
                <td className="amount">£ 60 GBP</td>
                <td className="status">
                  <span>Active</span>
                </td>
                <td className="actions">
                  <img
                    src={actionMenuTogglerIcon}
                    alt="actionMenuTogglerIcon"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          </div>
        </div>

        </div>
      </OrganizerSettingStatisticsMatch2Style>
    </div>
  );
};

export default OrganizerSettingStatisticsMatch2;

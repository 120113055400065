import { Card } from "antd"
import { Col, Row } from "react-bootstrap"
import Head from "../../../components/Head/Head"
import { ViewPageStyle } from "./style"
import OverlayImages from "../../Home/OverlayImages/OverlayImages"
import useAcademy from "../Hooks/useAcademy"
import { AcademyDataType } from "../constant"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { RootState } from "../../../app/store"
import { useSelector } from "react-redux"

const AcademyView = () => {
    const {getAcademyView} = useAcademy();
    const [academyData, setAcademyData] = useState<AcademyDataType>()
    const { academyId } = useParams()
    const { data } = useSelector((state: RootState) => state.loginData);
    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const response: any = await getAcademyView(Number(academyId))
                if (response) {
                    setAcademyData(response)
                }
            } catch (error: any) {
                console.log("error", error)
            }
        }
        fetchData()
    }, [])


  return (
    <>
    <Head title="ULTM8 - Academy View" />
    <ViewPageStyle>
        <OverlayImages
            backgroundImg={''}
            overlayImg={''}
            isEditable={true}
        />
        <h3>Owner Information</h3>
        <Card>
            <Row>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                            Owner First Name
                        </div>
                        <div className="list-item-value">
                            {data.user.firstName || "--"}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                            Owner Last Name
                        </div>
                        <div className="list-item-value">
                        {data.user.lastName || "--"}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">Email</div>
                        <div className="list-item-value">
                        {data.user.email || "--"}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item ">
                        <div className="list-item-title">
                            Phone Number
                        </div>
                        <div className="list-item-value">
                        {data.user.phoneNumber || "--"}
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
        <h3>Academy View</h3>
        <Card>
            <Row>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                            Academy Name
                        </div>
                        <div className="list-item-value">
                        {academyData?.businessName || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                           Academy Type
                        </div>
                        <div className="list-item-value">
                        {academyData?.businessType || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                          Phone Number
                        </div>
                        <div className="list-item-value">
                        {academyData?.phoneNumber || '--'}
                        </div>
                    </div>
                </Col>

                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                        Academy Address

                        </div>
                        <div className="list-item-value">
                        {academyData?.address || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="3">
                    <div className="list-item">
                        <div className="list-item-title">
                           Default Language
                        </div>

                        <div className="list-item-value">
                        {academyData?.defaultLanguageId || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="3">
                    <div className="list-item">
                        <div className="list-item-title">
                           Default Currency
                        </div>
                        <div className="list-item-value">
                        {academyData?.defaultLanguageId || '--'}
                        </div>
                    </div>
                </Col>

                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                           Activity
                        </div>
                        <div className="list-item-value">
                        {academyData?.activities || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                           Facility
                        </div>
                        <div className="list-item-value">
                        {academyData?.facilities || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="12">
                    <div className="list-item mb-0">
                        <div className="list-item-title">
                           Description
                        </div>
                        <div className="list-item-value">
                        {academyData?.description || '--'}
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
    </ViewPageStyle>
    </>
  )
}

export default AcademyView
import React from "react";
import { EventResultStyle } from "./style";
import CurrentlyFightingIcon from "../../assets/icons/CurrentlyFightingIcon.svg";
import BeReadyGroupIcon from "../../assets/icons/BeReadyGroupIcon.svg";
import DropDownImage from "../../assets/images/Down_Arrow_3_.svg";
import Tvimgage from "../../assets/images/Smart TV.svg";
import BeltImage from "../../assets/images/progressbar.svg";
import GoToBullPenIcon from "../../assets/icons/GoToBullPen-Icon.svg";
import FighterReadyClock from  "../../assets/icons/FighterReady-Clock.svg";
export const EventResult = () => {
  return (
    <div>
      <EventResultStyle>
        <div className="wrapper">
          <div className="scheduleHeading">
            <h4>Schedule</h4>
          </div>

          <div className="resultSchedule_Main">

            <div className="resultScheduleCard_Left">
              <div className="currentlyFighting_beReadyBullPen">
                <div className="currentlyFighting">
                  <img
                    src={CurrentlyFightingIcon}
                    alt="CurrentlyFightingIcon"
                    className="CurrentlyFightingIcon"
                  />
                  <span>Currently fighting</span>
                </div>
                <div className="beReadyBullPen">
                  <img src={BeReadyGroupIcon} alt="BeReadyGroupIcon" className="BeReadyGroupIcon" />
                  <span>Be ready in bull pen</span>
                </div>
              </div>

              <div className="schedule-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Mat-Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>

                <div className="section-2-container">
                  <div className="section-2 d-flex align-items-center">
                    <img src={Tvimgage} alt="" className="tv-img" />
                    <p className="tv-mode">TV Mode</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container scheduleRow2 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>
              </div>

              <div className="expendForSeeMore">
                <h5>Expend for See more</h5>
              </div>

            </div>

            <div className="resultScheduleCard_mid">
              <div className="currentlyFighting_beReadyBullPen">
                <div className="currentlyFighting">
                  <img
                    src={GoToBullPenIcon}
                    alt="GoToBullPenIcon"
                    className="GoToBullPenIcon"
                  />
                  <span>Go to bull pen</span>
                </div>
                <div className="beReadyBullPen">
                  <img src={FighterReadyClock} alt="FighterReadyClock" className="FighterReadyClock"/>
                  <span>Fighter ready to go</span>
                </div>
              </div>

              <div className="schedule-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Mat-Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>

                <div className="section-2-container">
                  <div className="section-2 d-flex align-items-center">
                    <img src={Tvimgage} alt="" className="tv-img" />
                    <p className="tv-mode">TV Mode</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container scheduleRow2 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>
              </div>

              <div className="expendForSeeMore">
                <h5>Expend for See more</h5>
              </div>

            </div>

            <div className="resultScheduleCard_right">
              <div className="currentlyFighting_beReadyBullPen">
                <div className="currentlyFighting currentlyFightingLastCard">
                  <img
                    src={FighterReadyClock}
                    alt="FighterReadyClock"
                    className="FighterReadyClock_LastCard"
                  />
                  <span>Fighter not weighed in</span>
                </div>
                <div className="beReadyBullPen beReadyBullPenLastCard">
                  <span className="dateTime_lastCard">Monday 31, November, 2023</span>
                  <img src={DropDownImage} alt="DropDownImage" className="dropDowndIcon_lastCard"/>
                </div>
              </div>

              <div className="schedule-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Mat-Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>

                <div className="section-2-container">
                  <div className="section-2 d-flex align-items-center">
                    <img src={Tvimgage} alt="" className="tv-img" />
                    <p className="tv-mode">TV Mode</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container scheduleRow2 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>
              </div>

              <div className="expendForSeeMore">
                <h5>Expend for See more</h5>
              </div>

            </div>
          </div>

          <div className="resultSchedule_Main resultSchedule_MainBlock2">
            <div className="resultScheduleCard_Left">
              <div className="schedule-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Mat-Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>

                <div className="section-2-container">
                  <div className="section-2 d-flex align-items-center">
                    <img src={Tvimgage} alt="" className="tv-img" />
                    <p className="tv-mode">TV Mode</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container scheduleRow2 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>
              </div>

              <div className="expendForSeeMore">
                <h5>Expend for See more</h5>
              </div>

            </div>

            <div className="resultScheduleCard_mid">
              <div className="schedule-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Mat-Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>

                <div className="section-2-container">
                  <div className="section-2 d-flex align-items-center">
                    <img src={Tvimgage} alt="" className="tv-img" />
                    <p className="tv-mode">TV Mode</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container scheduleRow2 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>
              </div>

              <div className="expendForSeeMore">
                <h5>Expend for See more</h5>
              </div>

            </div>

            <div className="resultScheduleCard_right">
              <div className="schedule-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex align-items-center">
                    <p className="Mat-Schedule">MAT 1 - Schedule</p>
                    <img src={DropDownImage} alt="" className="DropDown-Img" />
                  </div>
                  <div className="date-time">
                    <p className="date-time-text">Aug 08:30am</p>
                  </div>
                </div>

                <div className="section-2-container">
                  <div className="section-2 d-flex align-items-center">
                    <img src={Tvimgage} alt="" className="tv-img" />
                    <p className="tv-mode">TV Mode</p>
                  </div>
                </div>
              </div>

              <div className="sceduleCardsMain">

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container scheduleRow2 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>

              <div className="scheduleExpandCard-container d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="Schedule d-flex">
                    <p className="Mat-Schedule">
                      Male Gi / White / Adult / -76 kg (Light)
                    </p>
                  </div>
                  <div className="progress-bar">
                    <progress
                      value={50}
                      max={100}
                      className="progress-background"
                    ></progress>
                  </div>
                </div>

                <div className="section-2-sceduleCardExpand">
                  <div className="section2-ExpandCard d-flex align-items-center">
                    <div className="section-time">
                      <p>Eta:14.10pm</p>
                    </div>

                    <div className="matches">
                      <p>8 Matches</p>
                    </div>
                  </div>

                  <div className="progbar-img">
                    <img src={BeltImage} alt="" />
                  </div>
                </div>
              </div>
              </div>

              <div className="expendForSeeMore">
                <h5>Expend for See more</h5>
              </div>

            </div>
          </div>
        </div>
      </EventResultStyle>
    </div>
  );
};

export default EventResult;

import React from 'react'
import {YourUpComingMatchesStyle} from "./style";
import BaptisteJegouxImage from "../../../assets/images/Baptiste-Jegoux.png"
import LucasdosSantosImage from "../../../assets/images/Lucas-dos-Santos.png";
import NorwayFlag from "../../../assets/icons/NorwayFlag.svg";
import UnitedKingdomFlag from "../../../assets/icons/UnitedKingdomeFlag.svg";

export const YourUpComingMatches = () => {
  return (
    <div>
        <YourUpComingMatchesStyle>
        <div className="cardContainer">
          <div className="innerCardMain">
          <div className="totalMatches">
            <p>1-28</p>
            </div>
          <div className="PlayersMain">
            <div className="firstPlayer">
              <div className="imageTitleMain">
                <div className="PlayerImage">
                  <img src={BaptisteJegouxImage} alt="BaptisteJegouxImage" />
                </div>
                <div className="TitleMain">
                  <div className="firstPlayerName">
                    <h6>Baptiste Jegoux</h6>
                    <div className="blueDot"></div>
                  </div>
                  <span>Gracie Allegiance...</span>
                </div>
              </div>
              <div className="countryFlag">
                <img src={UnitedKingdomFlag} alt="UnitedKingdomFlag" />
              </div>
            </div>

            <div className="secondPlayer">
              <div className="imageTitleMain">
                
              <div className="countryFlag">
                <img src={NorwayFlag} alt="UnitedKingdomFlag" />
              </div>
                <div className="TitleMain">
                  <div className="firstPlayerName">
                    <div className="blueDot"></div>
                    <h6>Lucas dos Santos</h6>
                  </div>
                  <span>Gracie Barra Fulham...</span>
                </div>
              </div>
                <div className="PlayerImage2">
                  <img src={LucasdosSantosImage} alt="BaptisteJegouxImage" />
                </div>
            </div>
            <div className="vs">VS</div>
          </div>
          </div>
          <div className="playersName_MatchDateTime">
            <div className="bracketsPlayersName">
              <div className="brackets">Bracket</div>
              <div className="playerName1">Baptiste Jegoux</div>
              <div className="playerName2">Lucas dos Santos</div>
            </div>
            <div className="matchDateTime">11/19/2023 -  12:56 pm</div>
          </div>
   
      </div>
        </YourUpComingMatchesStyle>
    </div>
  )
}

export default YourUpComingMatches;
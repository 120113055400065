import React from 'react'
import {OrganizerCouponStyle} from "./style"
import actionMenuTogglerIcon from "../../../assets/icons/ic_action_menu_toggler.svg";
import StatusActiveError from "../../../assets/images/activeBtnError.svg";

const OrganizerCoupon = () => {
  return (
    <div>
        <OrganizerCouponStyle>
        <div className="title_AffiliationButton">
          <h4 className="title">Coupon Info</h4>
          <button className="newAffiliationButton">Send new Messages</button>
        </div>

        
        <div className='tablelist'>
          <table>
            <tr>
              <th>ID</th>
              <th>Code</th>
              <th>Validity</th>
              <th>Timezone</th>
              <th>Limit</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Event</th>
              <th>Created  by</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            {Array.from({ length: 10 }, (_, index) =>
            <tr>
              <td>
              1
              </td>
              <td>12345</td>
              <td>2023-08-14</td>
              <td>12:00</td>
              <td>United League K1 & Muay Thai Autumn Championship...</td>
              <td>Jiu Jitsu, Karate</td>
              <td>$10</td>
              <td>Event</td>
              <td>2023-08-14</td>
              <td><label className="Active">Active</label><img src={StatusActiveError} alt="StatusActiveError" /></td>
              <td className='actions'>
              <img src={actionMenuTogglerIcon} alt="actionMenuTogglerIcon" />
              </td>
            </tr>
            )}
          </table>
          </div>
        </OrganizerCouponStyle>
    </div>
  )
}

export default OrganizerCoupon
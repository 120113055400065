import React from 'react'
import {ProfilesBeforeLoginPaginationStyle} from "./style";
export const ProfilesBeforeLoginPagination = () => {
  return (
    <div>
        <ProfilesBeforeLoginPaginationStyle>
        <div className="paginationMain">
            <div className="totalPages">
              <ul>
                <li>
                  Page<span>1</span>of 10
                </li>
              </ul>
            </div>
            <div className="pagination">
              <ul>
                <li className="prevPage disabled">
                  <span>Prev</span>
                </li>
                <li className="page1">
                  <span>1</span>
                </li>
                <li className="page2">
                  <span>2</span>
                </li>
                <li className="page3 active">
                  <span>3</span>
                </li>
                <li className="page4_9">
                  <span>...</span>
                </li>
                <li className="page10">
                  <span>10</span>
                </li>
                <li className="nextPage">
                  <span>Next</span>
                </li>
              </ul>
            </div>
                </div>

        </ProfilesBeforeLoginPaginationStyle>
    </div>
  )
}

export default ProfilesBeforeLoginPagination;
import { Card, Form } from "antd"
import Head from "../../../components/Head/Head"
import { FormPageStyle, StudentViewStyling } from "../AcademyCreate/style"
import { Col, Row } from "react-bootstrap"
import { ErrorMessage, Formik } from "formik"
import FormControl from "../../../components/FormControl"
import { AcademyDataType, AcademyInitialValues } from "../constant"
import CustomPhoneInput from "../../../components/CustomPhoneInput/CustomPhoneInput"
import { Black, RobinEggBlue, fontFamilyEnnVisionsRegular, fontFamilyRegularUber } from "../../../components/GlobalStyle"
import PlacesAutoCompleteInput from "../../../maps/PlacesAutocomplete"
import CheckboxesSelect from "../../../components/CustomCheckbox/CheckboxesSelect"
import { DataTypesWithIdAndMultipleLangLabel } from "../../../app/features/types"
import CustomButton from "../../../components/CustomButton/CustomButton"
import { useEffect, useState } from "react"
import useAcademy from "../Hooks/useAcademy"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../../../app/store"
import OverlayImages from "../../Home/OverlayImages/OverlayImages"

const AcademyUpdate = () => {
  const [academyData, setAcademyData] = useState<AcademyDataType>()
  const { handleAcademyUpdate, getAcademyView, SuccessModal, WarningModal } = useAcademy();
  const { academyId } = useParams()
  const { data } = useSelector((state: RootState) => state.loginData);



  const updateAcademy = async (value: any): Promise<void> => {
    await handleAcademyUpdate(Number(academyId), value)
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response: any = await getAcademyView(Number(academyId))
        if (response) {
          setAcademyData(response)
        }
      } catch {
      }
    }
    fetchData()
  }, [])

  const initialValues: AcademyInitialValues = {
    userId: data.user.id,
    businessName: academyData ? academyData.businessName : '--',
    businessType: academyData ? academyData?.businessType : '',
    address: academyData?.address,
    phoneNumber: academyData ? academyData?.phoneNumber : '--',
    rank: true,
    defaultLanguageId: academyData ? academyData?.defaultLanguageId : 0,
    defaultCurrencyId: academyData ? academyData.defaultCurrencyId : 0,
    activities: academyData ? academyData.activities : [],
    facilities: academyData ? academyData.facilities : [],
    description: academyData ? academyData.description : '--',
  }


  const activitiesDummyList: DataTypesWithIdAndMultipleLangLabel[] = [
    { id: "1", en: "Football", es: "Fútbol", pt: "Futebol", value: "Football", label: "Football", ar: "", ur: "", imageUrl: "" },
    { id: "2", en: "Basketball", es: "Baloncesto", pt: "Basquetebol", value: "Basketball", label: "Basketball", ar: "", ur: "", imageUrl: "" },
    { id: "3", en: "Swimming", es: "Natación", pt: "Natação", value: "Swimming", label: "Swimming", ar: "", ur: "", imageUrl: "" },
    // Add more activities as needed
  ];

  const facilitiesDummyList: DataTypesWithIdAndMultipleLangLabel[] = [
    { id: "1", en: "Gym", es: "Gimnasio", pt: "Academia", value: "Gym", label: "Gym", ar: "", ur: "", imageUrl: "" },
    { id: "2", en: "Library", es: "Biblioteca", pt: "Biblioteca", value: "Library", label: "Library", ar: "", ur: "", imageUrl: "" },
    { id: "3", en: "Computer Lab", es: "Laboratorio de Computación", pt: "Laboratório de Informática", value: "Computer Lab", label: "Computer Lab", ar: "", ur: "", imageUrl: "" },
    // Add more facilities as needed
  ];
  const onsubmit = () => { }
  return (
    <>
      {SuccessModal().modalComponent}
      {WarningModal().modalComponent}
      <Head title="ULTM8 - Academy Update" />
      <StudentViewStyling>
        <OverlayImages
          backgroundImg={''}
          overlayImg={''}
          isEditable={true}
        />
        <Card>
          <h3>Owner Information</h3>
          <Row className="mt-20">
            <Col md="6">
              <div className="list-item">
                <div className="list-item-title">
                  Owner First Name
                </div>
                <div className="list-item-value">
                  {data.user.firstName || "--"}
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="list-item">
                <div className="list-item-title">
                  Owner Last Name
                </div>
                <div className="list-item-value">
                  {data.user.lastName || "--"}
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="list-item">
                <div className="list-item-title">Email</div>
                <div className="list-item-value">
                  {data.user.email || "--"}
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="list-item">
                <div className="list-item-title">
                  Phone Number
                </div>
                <div className="list-item-value">
                  {data.user.phoneNumber || "--"}
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </StudentViewStyling>
      <FormPageStyle>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={onsubmit}
        >
          {(formik) => {
            return (
              <Form
                name="basic"
                onFinish={formik.handleSubmit}
                autoComplete="off"
              >
                <div className="bg-white form">
                  <h3>Academy Update</h3>
                  <Row>
                    <Col md="4" className="mt-20">
                      <FormControl
                        control="input"
                        type="text"
                        name="businessName"
                        label="Academy Name"
                        fontSize="16px"
                        max={6}
                        placeholder="Enter Academy Name"
                        className={
                          formik.errors
                            .businessName &&
                            formik.touched.businessName
                            ? 'is-invalid'
                            : 'customInput'
                        }
                      />
                    </Col>
                    <Col md="4" className="mt-20">
                      <FormControl
                        control="select"
                        type="text"
                        name="businessType"
                        label="Academy Type"
                        placeholder={formik.values.businessType || "Select Academy Type"}
                        className={
                          formik.errors.businessType
                            ? 'is-invalid'
                            : 'customInput'
                        }
                        options={[
                          { value: "School", label: "School" },
                          { value: "Academy", label: "Academy" },
                          { value: "Coach", label: "Coach" },
                        ]}
                      />

                    </Col>
                    <Col md="4" className="mt-20">
                      <CustomPhoneInput
                        label="Phone Number"
                        name="phoneNumber"
                        value={formik.values.phoneNumber}
                        placeholder={formik.values.phoneNumber || "Enter Phone Number"}
                        limitMaxLength={true}
                        handleOnChange={(e: string) => {
                          formik.setFieldValue('phoneNumber', e)
                        }}
                      />
                      <ErrorMessage
                        name={'phoneNumber'}
                      >
                        {(msg) => (
                          <div
                            className="error-message is-invalid"
                            style={{
                              color: 'red',
                              textAlign: 'right',
                              marginLeft: '3px',
                              fontSize: '12px',
                              letterSpacing: '1px',
                              fontFamily: fontFamilyEnnVisionsRegular,
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col md="4" className="mt-20">
                      <PlacesAutoCompleteInput
                        label="Academy Address"
                        placeholder={formik.values.address || "Enter Academy Address"}
                        handleChange={(val: any) => {
                          formik.setFieldValue('address', val)
                        }}
                        className={
                          formik.errors.address &&
                            formik.touched.address
                            ? 'is-invalid'
                            : 'customInput'
                        }
                        formik={formik}
                        name="address"
                        value={formik.values.address}
                      />
                    </Col>
                    <Col md="4" className="mt-20">
                      <FormControl
                        control="select"
                        type="text"
                        name="defaultLanguageId"
                        fontFamily={fontFamilyEnnVisionsRegular}
                        label="Default Language"
                        placeholder={formik.values.defaultLanguageId || "Select Default Language"}
                        className={
                          formik.errors.defaultLanguageId &&
                            formik.touched.defaultLanguageId
                            ? 'is-invalid'
                            : 'customInput'
                        }
                        options={[
                          { value: 1, label: "English" },
                          { value: 2, label: "Urdu" },
                          { value: 3, label: "Punjabi" },
                        ]}
                      />
                    </Col>
                    <Col md="4" className="mt-20">
                      <FormControl
                        control="select"
                        type="text"
                        name="defaultCurrencyId"
                        fontFamily={fontFamilyEnnVisionsRegular}
                        label="Default Currency"
                        placeholder={formik.values.defaultCurrencyId || "Select Default Currency"}
                        className={
                          formik.errors.defaultCurrencyId &&
                            formik.touched.defaultCurrencyId
                            ? 'is-invalid'
                            : 'customInput'
                        }
                        options={[
                          { value: 1, label: "USD" },
                          { value: 2, label: "Euro" },
                          { value: 3, label: "Rupees" },
                        ]}
                      />
                    </Col>
                    <Col md="6" className="">
                      <CheckboxesSelect
                        list={activitiesDummyList.map(item => ({
                          ...item,
                          checked: formik.values.activities.includes(item.value)
                        }))}
                        name="activities"
                        label="Activity"
                        showErrorMsgInList={false}
                        placeholder="Select Activity"
                      />
                    </Col>
                    <Col md="6" className="">
                      <CheckboxesSelect
                        list={facilitiesDummyList.map(item => ({
                          ...item,
                          checked: formik.values.facilities.includes(item.value)
                        }))}
                        name="facilities"
                        label="Facilities"
                        showErrorMsgInList={false}
                        placeholder="Select Facilities"
                      />
                    </Col>
                    <Col className="mt-20">
                      <FormControl
                        control="textarea"
                        type="text"
                        name="description"
                        fontFamily={fontFamilyEnnVisionsRegular}
                        label="Description"
                        padding="10px"
                        placeholder={formik.values.description || "Enter Description"}
                        className={
                          formik.errors.description &&
                            formik.touched.description
                            ? 'is-invalid'
                            : 'customInput'
                        }
                        height="200px"
                      />
                    </Col>
                  </Row>
                </div>
                <div className="mt-20 d-flex justify-content-end">
                  <CustomButton
                    bgcolor={RobinEggBlue}
                    textTransform="uppercase"
                    color={Black}
                    padding="11px 40.50px"
                    fontFamily={fontFamilyRegularUber}
                    width="fit-content"
                    type="submit"
                    title="Update"
                    fontSize="14px"
                    loading={false}
                    clicked={() => { updateAcademy(formik.values) }}
                  />
                </div>
              </Form>
            )
          }}
        </Formik>

      </FormPageStyle>
    </>
  )
}

export default AcademyUpdate

import React, { useState } from "react";
import { TicketInformationStyle } from "./style";
import TabNavItem from "../../components/TabNavItem/TabNavItem";
import TabContent from "../../components/TabContent/tabContent";
import HeaderInnerPages from "../../components/HeaderInnerPages/HeaderInnerPages";
import StarRating from "../../assets/icons/star-rating.svg";
import TicketInformationBanner from "../../components/TicketInformationBanner/TicketInformationBanner";
import InformationData from "../InformationData/InformationData";
import ParticipantData from "../ParticipantData/ParticipantData";
import EventLocation from "../EventLocation/EventLocation";
import EventResult from "../EventResult/EventResult";
import EventSchedule from "../EventSchedule/EventSchedule";
import EventBrackets from "../EventBrackets/EventBrackets";

import ToggleTrack from "../../assets/icons/ToggleTrack.png";
import ToggleButton from "../../assets/icons/Toggle.png";
import { Link } from "react-router-dom";
import { Switch } from 'antd';
import EventSingleMatchSchedule from "../EventSingleMatchSchedule/EventSingleMatchSchedule";
import SelectDate from "../SelectDate/SelectDate";
import ScheduleScoreBoard from "../ScheduleScoreBoard/ScheduleScoreBoard";

const TicketInformation: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("tab1");
const [changeComponent,setChangeComponent]=useState(false)
  return (
    <div>
      <TicketInformationStyle>
        <div className="wrapper">
          {/* <HeaderInnerPages /> */}
          <div className="headinMain">
            <h2>London Jiu Jitsu Open Gi and NOGI championship</h2>
          </div>
          <div className="dateTime_main">
            <div className="DateTime">
              <h6>Monday, 17th October 2023.</h6>
            </div>
            <div className="RatingPoints">
              <img src={StarRating} alt="" className="starImg" />
              <p className="rating">
                4.6<span> (780+ )</span>
              </p>
            </div>
          </div>
          <div className="bannerMain">
            <TicketInformationBanner />
          </div>
          <div className="">
            <div className="Tabs">
              <ul className="nav">
                <TabNavItem
                  title="Information"
                  id="tab1"
                  setActiveTab={() => setActiveTab("tab1")}
                  activeTab={activeTab}
                />
                <TabNavItem
                  title="Participants"
                  id="tab2"
                  setActiveTab={() => setActiveTab("tab2")}
                  activeTab={activeTab}
                />
                <TabNavItem
                  title="Brackets"
                  id="tab3"
                  setActiveTab={() => setActiveTab("tab3")}
                  activeTab={activeTab}
                />
                <TabNavItem
                  title="Schedule"
                  id="tab4"
                  setActiveTab={() => setActiveTab("tab4")}
                  activeTab={activeTab}
                />
                <TabNavItem
                  title="Results"
                  id="tab5"
                  setActiveTab={() => setActiveTab("tab5")}
                  activeTab={activeTab}
                />
                <TabNavItem
                  title="Location"
                  id="tab6"
                  setActiveTab={() => setActiveTab("tab6")}
                  activeTab={activeTab}
                />
                <TabNavItem
                  title="Weigh-In"
                  id="tab7"
                  setActiveTab={() => setActiveTab("tab7")}
                  activeTab={activeTab}
                />
                <TabNavItem
                  title="Rules"
                  id="tab8"
                  setActiveTab={() => setActiveTab("tab8")}
                  activeTab={activeTab}
                />
                <TabNavItem
                  title="Under 16’s"
                  id="tab9"
                  setActiveTab={() => setActiveTab("tab9")}
                  activeTab={activeTab}
                />
                <TabNavItem
                  title="FAQ"
                  id="tab10"
                  setActiveTab={() => setActiveTab("tab10")}
                  activeTab={activeTab}
                />

              <div className="showApproved_toggleButton">
                <span>Change View</span>
                <Switch className='' onClick={()=>setChangeComponent(!changeComponent)} />
              </div>
              
                {/* <div className='showApproved_toggleButton' onClick={()=>setChangeComponent(!changeComponent)}>
                    <span>Change View</span>
                    <img src={ToggleTrack} alt="ToggleTrack" />
                    <img src={ToggleButton} alt="ToggleButton" className='toggleButton' />
                </div> */}

              </ul>

              <div className="outlet">
                <TabContent id="tab1" activeTab={activeTab}>
                  <InformationData />
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                  {changeComponent ? <EventLocation />:<ParticipantData />}
                  
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                  <EventBrackets />
                </TabContent>

                <TabContent id="tab4" activeTab={activeTab}>
                  <EventResult />
                </TabContent>

                <TabContent id="tab5" activeTab={activeTab}>
                  <EventSchedule />
                </TabContent>

                <TabContent id="tab6" activeTab={activeTab}>
                  <EventSingleMatchSchedule />
                </TabContent>

                <TabContent id="tab7" activeTab={activeTab}>
                  <SelectDate />
                </TabContent>

                <TabContent id="tab8" activeTab={activeTab}>
                  <ScheduleScoreBoard /> 
                </TabContent>

                <TabContent id="tab9" activeTab={activeTab}>
                  <p>Tab 3 works!</p>
                </TabContent>
              </div>
            </div>
          </div>
          <div className=""></div>
        </div>
      </TicketInformationStyle>
    </div>
  );
};

export default TicketInformation;


import styled from "styled-components";
import { IguanaGreen, VenetianRed, fontFamilyRegular, whiteColor } from "../../GlobalStyle";

export const ScoreBoardTableStyle = styled.div`

.boardScoreAdd{
    position: absolute;
    left: 0vh;
    top: 14vh;
    width: 71vh;
        table{
            max-width: 100vh;
            border-radius: 8px;
            background: #000;
            border-collapse: separate;
            text-align: center;
            width: 72vh;
            position: relative;
            z-index: 99999;
            tr{
                td{
                    table{
                        border: none;
                        border-spacing: 2vh 0.6vh;
                        tr{
                            td{
                                padding: 0.5vh 1vh;
                                color: ${IguanaGreen};
                                font-family: ${fontFamilyRegular};
                                padding: 0.5vh 1vh;
                                font-size: 3vh;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-transform: uppercase;
                                text-align: center;
                                border-radius: 0.4vh;
                                // border: 1px solid #525252;
                                &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6){
                                    width: 4vh;
                                }
                                &:nth-child(7), &:nth-child(8){
                                    width: 10vh;
                                }
                            }
                        }
                    }
                
                }
            }
            tr:nth-child(2){
                td{
                    table{
                        tr{
                            td{
                                color: ${VenetianRed};
                            }
                        }
                    }
                    
                }
            }
            tr:last-child{
                td{
                    table{
                        tr{
                            td{
                                color: ${whiteColor};
                            }
                        }
                    }
                    
                }
            }
            
        }
    
}

`;

import styled from "styled-components";

import { fontFamilyEnnVisionsMedium, fontFamilyRegularUber, lightColor1, lightDark2, mediaDeviceMax, pureDark2 } from "../GlobalStyle";

export const HeaderStyle = styled.div`
  .Navbar_Main {
    width: 100%;
    height: 76px;
    background: #FFF;
    display: flex;
  }
  .navBar_wrapper {
    width: 98%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navBarL {
    display: flex;
    align-items: center;
  }
  .hamBurgerIcon {
    width: 30px;
    height: 22px;
    cursor: pointer;
  }
  .navBarLogo {
    width: 137px;
    height: 64px;
    margin-left: 34px;
  }
  .inputsFilterButton {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
  }
  .profile-area{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    max-width: 185px;
    width: 100%;
    margin-left: 10px;
  }

  .navBarR {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 75%;
  }

  .navFields {
      max-width: 490px;
      width: 100%;
      margin-right: 13px;
  }
  .headerSearchField {
      width: 100%;
  }


  .left-bar {
    height: 52px;
    border-radius: 8px;
    background-color: ${lightColor1};
    input {
      width: 100%;
      background-color: ${lightColor1};
      padding: 10px;
      color: ${lightDark2};
      font-family: ${fontFamilyRegularUber};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      ::placeholder {
        color: ${lightDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .right-bar{
    height: 52px;
    border-radius: 0 8px 8px 0;
    border-left: 0.5px solid rgba(53, 87, 98, 0.7);
    background-color: ${lightColor1};
    input{
      width: 100%;
      background-color: ${lightColor1};
      padding: 10px;
      color: ${lightDark2};
      font-family: ${fontFamilyRegularUber};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      ::placeholder{
        color: ${lightDark2};
        font-family: ${fontFamilyRegularUber};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    }
    .filterIcon button {
    height: 52px;
    width: 52px;
  }

  .loginRegister_Buttons {
    display: flex;
    align-items: center;
  }

    .dealsButton {
        display: flex;
        width: 127px;
        height: 52px;
        padding: 0px 40px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #3D86AF;
        margin: 0 14px;

        color: #3D86AF;
        text-align: center;
        font-family: ${fontFamilyEnnVisionsMedium};
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
    }

    .loginButton {
        display: flex;
        width: 125px;
        height: 52px;
        padding: 0px 40px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 8px;
        background-color:  #5D7081;
        margin-right: 14px;

        color: #FFF;
        text-align: center;
        font-family: ${fontFamilyEnnVisionsMedium};
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
    }
    .signUpButton {
        display: flex;
        width: 136px;
        height: 52px;
        padding: 0px 40px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        background-color: #DEF2E2;

        color: #000;
        text-align: center;
        font-family: ${fontFamilyEnnVisionsMedium};
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
    }
    .profileName {
      display: inline-block;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      margin-left: 10px;
      margin-bottom: 0;
      font-family: ${fontFamilyRegularUber};
      color: ${pureDark2};
      @media screen and ${mediaDeviceMax.mobileM} {
          width: 100%;
          margin-left: 6px;
      }
  }
`;

export const DropDownStyling = styled.div`
    .ant-btn-icon-only {
        width: 24px;
        height: 32px;
    }`;


import styled from "styled-components";
import { fontFamilyEnnVisionsMedium, fontFamilyEnnVisionsRegular, fontFamilyMedium } from "../../../components/GlobalStyle";

export const OrganizerSettingStatisticsOverviewStyle = styled.div`

.bodyWrapper_RightSide {
    width: 100%;
    padding-left: 50px;
}
.maineContainer {
    width: 100%;
    margin-top: 23px;
}
.cardsMainContainerSec1 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding-right: 25px;
}
.cardsMainContainerSec2 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding-right: 25px;
    margin-top: 20px;
    margin-bottom: 25px;
}
.dataCards {
    width: 32.05%;
}
.titles {
    color: #000;
    font-family: ${fontFamilyMedium};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 0;
}
.innerrCard {
    width: 100%;
    height: 216px;
    background-color: #FFF;
    border: 1px solid #E0E0E0;
    padding: 18px 10px 0 16px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 4px;
}
.innerrCard p {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.innerrCard p span {
    color: #061229;
    font-family: ${fontFamilyEnnVisionsRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.details {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 12px;
    padding-right: 7px;
}
.details a {
    color: #0070E0;
    font-family: ${fontFamilyEnnVisionsMedium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}
`;
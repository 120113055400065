import React from "react";

interface TabNavItemProps {
  id: any;
  title: string;
  activeTab: any;
  setActiveTab: (id: any) => void;
}

const TabNavItem: React.FC<TabNavItemProps> = ({ id, title, activeTab, setActiveTab }) => {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <li onClick={handleClick} className={activeTab === id ? "active" : ""}>
      {title}
    </li>
  );
};

export default TabNavItem;

import styled from "styled-components";

export const EndMatchScoreBoardTradStyle = styled.div`
.container-match-1{
    border-radius: 1vh;
    // border: 1px solid #525252;
    background: #000;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.55);
    max-width: 85%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1.2vh 1.2vh;
    margin: 0px 0px;
}
.match-container-1{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    width: 100%;
    max-width: 75%;
    flex-wrap: wrap;
}
.match-container-2{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    place-content: center flex-end;
    flex-direction: row;
    width: 100%;
    max-width: 25%;
    -webkit-box-pack: end;
    justify-content: center;
}
.match-container-1 .match-1 {
    border-radius: 4px;
    // border: 1px solid #525252;
    max-width: 48%;
    width: 100%;
    height: 50%;
    text-align: center;
    margin: 0.5vh 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    bo-sizing: border-box;
}
.match-container-1 .match-1 h5{
    color: #FFF;
    font-family: "OperaMedium";
    font-size: 2.75vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.25px;
    text-transform: uppercase;
    margin: 0;
}
.match-container-2 .endgame {
    border-radius: 1vh;
    // border: 1px solid rgb(82, 82, 82);
    // border: 1px solid rgba(255, 0, 0, 0.15);
    background: rgba(255, 255, 255, 0.09);
    max-width: 100vw;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5vh;
}
.match-container-2 .endgame h5{
    color: #FFF;
    font-family: "OperaRegular";
    font-size: 2.75vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.25px;
    text-transform: uppercase;
}
`;
import React from "react";
import { AffiliationsProfileStyle } from "./style";
import DynamoFightComponent from "../../../components/DynamoFight/DynamoFight";
import UncomingEventsCards from "../../AjpScoreBoard/TicketListing/TicketListing";
import PassEvenetCard from "../../../components/PassEventsCard/PassEventsCard";
import AcademyProfileBannerImage from "../../../assets/images/AcademyProfileBanner.png";
import MapFrame from "../../../assets/images/AcademyProfileMapImage-p3.png";
import IntroductionComponent from "../../../components/ProfileBeforeLoginIntroduction/ProfileBeforeLoginIntroduction";
export const AffiliationsProfile = () => {
  return (
    <div>
      <AffiliationsProfileStyle>
        {/* <div className="banner">
          <img
            src={AcademyProfileBannerImage}
            alt="AcademyProfileBannerImage"
          />
        </div> */}
        <div className="wrapper">
          <div className="bodyLeftSideMain">
            <div className="introduction">
              <IntroductionComponent />
            </div>

            <div className="introductionParagraph">
              <h6>Introduction</h6>
              <p>
                I have been on so many different mats all over the world. That
                amazes me sometimes looking back.” – Mauricio Gomes Mauricio
                began training in Jiu-Jitsu at just four years of age. His
                father, who was a brown belt, started his journey and took him
                to classes at João Alberto Barreto’s academy. As a teenager,
                Mauricio began training with the legendary Rolls Gracie. Under
                Rolls' guidance, he became a highly successful competitor and
                won the absolute division of the Rio de Janeiro State
                Championships in 1981. ....
              </p>
            </div>

            <div className="mapFrame">
              <img src={MapFrame} alt="" />
            </div>
          </div>
          <div className="bodyRightSideMain">
            <div className="blockContainerMain">
              <div className="Headings_SeeAll">
                <h4>Associated Academies</h4>
                <span>See All</span>
              </div>
              <div className="CardsComponentsMain">
                <DynamoFightComponent />
                <DynamoFightComponent />
                <DynamoFightComponent />
                <DynamoFightComponent />
                <DynamoFightComponent />
                <DynamoFightComponent />
                <DynamoFightComponent />
                <DynamoFightComponent />
              </div>
            </div>

            <div className="blockContainerMain block2_container">
              <div className="Headings_SeeAll">
                <h4>Upcoming EventS</h4>
                <span>See All</span>
              </div>
              <div className="CardsComponentsMain block2_CardsComponent">
                <UncomingEventsCards />
                <UncomingEventsCards />
                <UncomingEventsCards />
              </div>
            </div>

            <div className="blockContainerMain block3_container">
              <div className="Headings_SeeAll">
                <h4>Pass Events</h4>
                <span>See All</span>
              </div>
              <div className="CardsComponentsMain block3_CardsComponent">
                <PassEvenetCard />
                <PassEvenetCard />
                <PassEvenetCard />
              </div>
            </div>
          </div>
        </div>
      </AffiliationsProfileStyle>
    </div>
  );
};

export default AffiliationsProfile;

import { Card } from "antd"
import { Col, Row } from "react-bootstrap"
import Head from "../../../components/Head/Head"
import OverlayImages from "../../Home/OverlayImages/OverlayImages"
import { ViewPageStyle } from "../../Academy/AcademyView/style"
import useCoach from "../Hooks/useCoach"
import { useEffect, useState } from "react"
import { CoachDataType } from "../constant"
import { useParams } from "react-router-dom"

const CoachView = () => {
    const {getCoachView} = useCoach();
    const [coachData, setCoachData] = useState<CoachDataType>()
    const { coachId } = useParams()

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const response: any = await getCoachView(Number(coachId))
                if (response) {
                    setCoachData(response)
                }
            } catch (error) {
                console.log("error", error)
            }
        }
        fetchData()
    }, [])
  return (
    <>
    <Head title="ULTM8 - Coach View" />
    <ViewPageStyle>
        <OverlayImages
            backgroundImg={''}
            overlayImg={''}
            isEditable={true}
        />
        <h3>Coach View</h3>
        <Card>
            <Row>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                        Coach Name
                        </div>
                        <div className="list-item-value">
                        {coachData?.instructorName || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                        Coach Email
                        </div>
                        <div className="list-item-value">
                        {coachData?.emailAddress || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="4">
                    <div className="list-item">
                        <div className="list-item-title">
                          Phone Number
                        </div>
                        <div className="list-item-value">
                        {coachData?.phoneNumber || '--'}
                        </div>
                    </div>
                </Col>

                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                        Coach Address

                        </div>
                        <div className="list-item-value">
                        {coachData?.address || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                           Default Language
                        </div>

                        <div className="list-item-value">
                        {coachData?.defaultLanguageId || '--'}
                        </div>
                    </div>
                </Col>

                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                           Activity
                        </div>
                        <div className="list-item-value">
                        {coachData?.activities || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="list-item">
                        <div className="list-item-title">
                           Specialization
                        </div>
                        <div className="list-item-value">
                        {coachData?.specializations || '--'}
                        </div>
                    </div>
                </Col>
                <Col md="12">
                    <div className="list-item mb-0">
                        <div className="list-item-title">
                           Description
                        </div>
                        <div className="list-item-value">
                        {coachData?.description || '--'}
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
    </ViewPageStyle>
    </>
  )
}

export default CoachView
import styled from "styled-components";
import { fontFamilyRegularUber, lightDark2, pureDark2 } from "../GlobalStyle";


export const StudentListCardStyle = styled.div`
.studentCard{
    background-color: white;
    border-radius: 8px;
    display: flex;
    
    .insideContent{
        width: 100%;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .studentInfo{

            .studentName{
                display: flex;
                align-items: center;
                justify-content: space-between;
                h3{
                    color: ${pureDark2};
                    font-family: ${fontFamilyRegularUber};
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.56px;
                    margin-bottom: 0;
                }
                p{
                    color: ${lightDark2};
                    font-family: ${fontFamilyRegularUber};
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.56px;
                    margin-bottom: 0;
                }
            }
            .addressYears{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                margin-top: 10px;

                .flagAddress{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    img{
                        width: 27px;
                        height: 27px;
                    }
                    p{
                        color: ${lightDark2};
                        font-family: ${fontFamilyRegularUber};
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.56px;
                        margin-bottom: 0;
                    }
                }
                p{
                    color: ${lightDark2};
                    font-family: ${fontFamilyRegularUber};
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.56px;
                    margin-bottom: 0;
                }
            }
        }
        .yearsBelt{
            h5{
                color: ${pureDark2};
                font-family: ${fontFamilyRegularUber};
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.56px;
                margin-bottom: 10px;
            }
        }
        .beltPlus{
            display: flex;
            align-items: center;
            gap: 5px;
            .plusIcon{
                cursor: pointer;
                padding: 0px 4px;
                border-radius: 4px;
                background-color: #5C6F80;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 10px;
                    height: 10px;
                }
            }
        }
        .buttons{
            display: flex;
            align-items: center;
            gap: 6px;
            
            .batchSelect{
                border: 1px solid #959595;
                height: 38px;
                border-radius: 4px;
                padding: 0px 4px;
                background-color: #EFEFEF;
            }
        }
    }


}
`